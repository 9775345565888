const STATUS_REQUEST = { ERROR: 'Ошибка запроса. Нет ответа от сервера.' };

const DEFAULT = {
  success: false,
  isDisabled: false,
  resultMsg: '',
  provider: 'None',
  pnr: '',
};

let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.AIRLINE_CANCEL.REQUEST_ERROR: {
      return {
        ...state,
        success: false,
        error: STATUS_REQUEST.ERROR,
        isDisabled: DEFAULT.isDisabled,
        provider: DEFAULT.provider,
        pnr: DEFAULT.pnr,
      };
    }

    case ACTIONS.AIRLINE_CANCEL.CANCEL_AIR_ITEM: {
      return {
        ...state,
        success: action.payload.success,
        resultMsg: action.payload.error,
        isDisabled: DEFAULT.isDisabled,
      };
    }

    case ACTIONS.AIRLINE_CANCEL.RESET: {
      return {
        ...state,
        success: DEFAULT.success,
        isDisabled: DEFAULT.isDisabled,
        resultMsg: DEFAULT.resultMsg,
        provider: DEFAULT.provider,
        pnr: DEFAULT.pnr,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('AirlineCancel', reducer, DEFAULT);
}

