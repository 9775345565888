import renderComponents from './components.jsx';

const NAME = 'sw-header';

class HeaderModule {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.name = name;
    this.context = context;

    const filterService = context.getService('Filter');
    const appService = context.getService('App');
    const searchUsersService = context.getService('SearchUsers');
    const hotelsService = context.getService('Hotels');
    const regionsService = context.getService('Regions');

    this.page = renderComponents(this.box, {
      filterService,
      appService,
      searchUsersService,
      hotelsService,
      regionsService,
      silenceChangeUrlQuery: this.silenceChangeUrlQuery,
    });
  }

  silenceChangeUrlQuery = (params) => {
    const url = `${window.location.pathname}${params ? `?${params}` : ''}`;
    this.context.silenceMove(url);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, HeaderModule);
}
