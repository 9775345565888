import debounce from 'es6-promise-debounce';

import ACTIONS from '../../actions';
import Api from '../../api';

import { INVALID_LINK, NOT_FOUND } from '../../constants/unloading1c';

let store = null;

const DEBOUNCE_TIME = 200;
const autocompletePrincipalsDebounce = debounce(Api.Unloading1c.getPrincipals, DEBOUNCE_TIME);

export default class Unloading1cService {
  constructor() {
    store = this.getStore('unloading1c');
  }

  get = () => store.getState();

  getPackageDocuments = (paramsPackageDocuments) => Api.Unloading1c.getPackageDocuments(paramsPackageDocuments);

  getInfoAmountsSupplier = (paramsPackageDocuments) => Api.Unloading1c.getInfoAmountsSupplier(paramsPackageDocuments)
    .then((res) => store.dispatch({
      type: ACTIONS.UNLOADING1C.INFO_AMOUNTS_SUPPLIER,
      listCompany: res,
    }));

  getFileXml = (guid) => Api.Unloading1c.getFileXml(guid);

  resetStore = () => {
    store.dispatch({ type: ACTIONS.UNLOADING1C.RESET });
  };

  setError = (error) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_ERROR,
      payload: error,
    });
  };

  sendSettings = (settings) => Api.Unloading1c.sendSettings(settings);

  getWorkSheets = async (link) => {
    try {
      const { Data: { Worksheets } } = await Api.Unloading1c.getWorkSheets(link);
      store.dispatch({
        type: ACTIONS.UNLOADING1C.SET_WORKSHEETS,
        payload: Worksheets,
      });
    } catch (error) {
      if (!error.body) {
        return this.setError(INVALID_LINK);
      }

      const { Error: { Comment: message } } = error.body;

      this.setError(message);
    }
  };

  getNomenclatures = async (body) => {
    try {
      const data = await Api.Unloading1c.getNomenclatures(body);
      const nomenclatures = data.map(({ Id, Code }) => ({ value: Id, label: Code }));

      store.dispatch({
        type: ACTIONS.UNLOADING1C.SET_NOMENCLATURES,
        payload: nomenclatures,
      });
    } catch (error) {
      this.setError(NOT_FOUND);
    }
  };

  setStartDate = (startDate) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_START_DATE,
      payload: startDate,
    });
  };

  setEndDate = (endDate) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_END_DATE,
      payload: endDate,
    });
  };

  setCompany = (company) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_COMPANY,
      payload: company,
    });
  };

  setCounteragent = (counteragent) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_COUNTERAGENT,
      payload: counteragent,
    });
  };

  setTypeImplementation = (typeImplementation) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_TYPE_IMPLEMENTATION,
      payload: typeImplementation,
    });
  };

  setDisabledMonth = (disabledMonth) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_DISABLED_MONTH,
      payload: disabledMonth,
    });
  };

  setStep = (step) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_STEP,
      payload: step,
    });
  };

  setGroupingType = (groupingType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_GROUPING_TYPE,
      payload: groupingType,
    });
  };

  setDayType = (dayType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_DAY_TYPE,
      payload: dayType,
    });
  };

  setAmountType = (amountType) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_AMOUNT_TYPE,
      payload: amountType,
    });
  };

  setNomenclature = (nomenclatureVatRate) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_NOMENCLATURE,
      payload: nomenclatureVatRate,
    });
  };

  setComment = (comment) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_COMMENT,
      payload: comment,
    });
  };

  setProvider = (provider) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_PROVIDER,
      payload: provider,
    });
  };

  getPrincipals = async (body) => {
    if (!body.query) return [];

    try {
      return await autocompletePrincipalsDebounce(body);
    } catch (error) {
      return [];
    }
  };

  setPrincipals = (principals) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_PRINCIPALS,
      payload: principals,
    });
  };

  setPrincipal = (principal) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_PRINCIPAL,
      payload: principal,
    });
  };

  setFileLink = (fileLink) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_FILELINK,
      payload: fileLink,
    });
  };

  setSheet = (sheet) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_SHEET,
      payload: sheet,
    });
  };

  setTripItemColumnName = (tripItemColumnName) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_TRIP_ITEM_COLUMN_NAME,
      payload: tripItemColumnName,
    });
  };

  setEmail = (email) => {
    store.dispatch({
      type: ACTIONS.UNLOADING1C.SET_EMAIL,
      payload: email,
    });
  };
}
