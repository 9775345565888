import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  Loading,
  COMPONENTS,
} from 'sw-ui';

import { FlatButton } from '../button';
import { FormDownloadFlightCertificate } from './components/FormDownloadFlightCertificate';

import {
  FLIGHT_CERTIFICATE_STATUS_TEXT,
  FLIGHT_CERTIFICATE_STATUS_TYPES,
} from '../../bi/constants/trips';

import styles from './styles/downloadFlightCertificateButton.module.css';

const { LOADING: { SIZE: { MAIN } } } = COMPONENTS;

const LABELS = { OPEN: 'Cкачать справку' };

const WIDTH_DIALOG = '600px';

class DownloadFlightCertificateButton extends Component {
  static propTypes = {
    airItemId: PropTypes.number.isRequired,
    onDownload: PropTypes.func.isRequired,
    onGetStatus: PropTypes.func.isRequired,
  };

  state = {
    showModal: false,
    isLoading: false,
    status: '',
  };

  handelOpenDialog = async () => {
    const { airItemId, onGetStatus } = this.props;

    this.setState({
      showModal: true,
      isLoading: true,
    });

    const status = await onGetStatus(airItemId);

    this.setState({
      status,
      isLoading: false,
    });
  };

  renderContentModal = () => {
    const { airItemId, onDownload } = this.props;
    const { isLoading, status } = this.state;

    if (isLoading) {
      return <Loading size={ MAIN } />;
    }

    if (status !== FLIGHT_CERTIFICATE_STATUS_TYPES.CAN_DOWNLOAD) {
      return (
        <p className={ styles.error }>
          { FLIGHT_CERTIFICATE_STATUS_TEXT[status] }
        </p>
      );
    }

    return (
      <FormDownloadFlightCertificate
        airItemId={ airItemId }
        onDownload={ onDownload }
        onClickClose={ () => this.setState({ showModal: false }) }
      />
    );
  };

  render() {
    const { showModal } = this.state;

    const modalHtml = showModal && (
      <Dialog
        width={ WIDTH_DIALOG }
        show={ showModal }
        showCloseButton
        onClick={ () => this.setState({ showModal: false }) }
      >
        <div className={ styles.wrapper_content }>
          { this.renderContentModal() }
        </div>
      </Dialog>
    );

    return (
      <div>
        <FlatButton
          label={ LABELS.OPEN }
          onClick={ this.handelOpenDialog }
        />
        { modalHtml }
      </div>
    );
  }
}

export { DownloadFlightCertificateButton };
