import React from 'react';
import PropTypes from 'prop-types';

import styles from '../styles/penalty.module.css';

const TicketWarnings = ({ text, tickets }) => {
  const renderAllTicketNumbers = (ticketsArr) => ticketsArr
    .map((ticket) => (
      <div
        key={ ticket.TicketId }
      >
        { ticket.TicketId }
      </div>
    ));

  return (
    <div className={ styles.notification }>
      { text }
      { renderAllTicketNumbers(tickets) }
    </div>
  );
};

TicketWarnings.propTypes = {
  text: PropTypes.string.isRequired,
  tickets: PropTypes.array.isRequired,
};

export { TicketWarnings };
