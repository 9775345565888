import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';

import { FlatButton } from '../../../../components/button';
import Input from '../../../../components/input';

import COMPONENTS from '../../../../bi/constants/components';
import { DEFAULTDATE, LOCALERU } from '../../../../constants/time';

import styles from '../../styles/form.module.scss';

const FIELDS = {
  NIGHTS_DATE: 'Date',
  NIGHTS_PRICE: 'Price',
};

const BUTTONS = {
  ADD: '+ добавить дату',
  REMOVE: '- удалить дату',
  RECALCULATE: 'Пересчитать',
};

const LABELS = {
  TITLE: 'Разбивка стоимости по ночам:',
  DATE: 'Дата',
  PRICE: 'Стоимость',
};

class Nights extends Component {
  static propTypes = {
    nights: PropTypes.array.isRequired,
    onAddNight: PropTypes.func.isRequired,
    onRemoveNight: PropTypes.func.isRequired,
    onChangeNight: PropTypes.func.isRequired,
  };

  renderDeleteRow = (index) => {
    const { onRemoveNight } = this.props;

    return (
      <div className={ styles['col-1-5'] }>
        <div className={ styles['add-conditions'] }>
          <FlatButton
            label={ BUTTONS.REMOVE }
            onClick={ () => onRemoveNight(index) }
          />
        </div>
      </div>
    );
  };

  renderNights = () => {
    const { nights = [], onChangeNight } = this.props;

    return nights.map(({ Date, Price }, index) => (
      <div className={ `${styles.row} ${styles.row_nights}` } key={ `${styles.row}-${index}` }>
        <div className={ styles['col-1-5'] }>
          <DatePicker
            dateFormat={ DEFAULTDATE }
            timeFormat=''
            locale={ LOCALERU }
            value={ Date }
            onChange={ (value) => onChangeNight(FIELDS.NIGHTS_DATE, value, index) }
            className='componentHook'
          />
          <label>{ LABELS.DATE }</label>
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field={ FIELDS.NIGHTS_PRICE }
            label={ LABELS.PRICE }
            value={ Price }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            isNewProps
            onChange={ (e, field, value) => onChangeNight(field, value, index) }
          />
        </div>
        { this.renderDeleteRow(index) }
      </div>
    ));
  };

  renderAddButton = () => {
    const { onAddNight } = this.props;

    return (
      <div className={ styles['add-conditions'] }>
        <FlatButton
          label={ BUTTONS.ADD }
          onClick={ onAddNight }
        />
      </div>
    );
  };

  render() {
    return (
      <div>
        <h4>{ LABELS.TITLE }</h4>
        { this.renderNights() }
        { this.renderAddButton() }
      </div>
    );
  }
}

export { Nights };
