import renderComponents from './components.jsx';

const NAME = 'sw-users';

class Users {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const userService = context.getService('User');
    const appService = context.getService('App');

    this.page = renderComponents(this.box, {
      userService,
      appService,
      toCompany: this.toCompany,
    });
  }

  toCompany = (accountId, companyId) => {
    const path = `/account/${accountId}/company/${companyId}/account/profile`;

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, Users);
}
