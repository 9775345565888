import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { FlatButton } from '../button';
import { ContentDialogButtonInAir } from './components/ContentDialogButtonInAir';

import styles from './styles/flightCertificateButtonInAir.module.css';

const LABELS = { OPEN: 'СПРАВКА О ПЕРЕЛЕТЕ' };

const WIDTH_DIALOG = '600px';

class FlightCertificateButtonInAir extends Component {
  static propTypes = {
    airItemId: PropTypes.number.isRequired,
    onDownload: PropTypes.func.isRequired,
    onBook: PropTypes.func.isRequired,
    onGetStatus: PropTypes.func.isRequired,
    isFreeFlightCertificate: PropTypes.bool.isRequired,
    isRemoveFlightCertificate: PropTypes.bool.isRequired,
  };

  state = {
    isLoading: false,
    showModal: false,
    showDownloadForm: false,
    showOrderConfirmation: false,
    bookError: false,
    status: '',
  };

  handelOpenDialog = async () => {
    const { airItemId, onGetStatus } = this.props;

    this.setState({
      showModal: true,
      isLoading: true,
    });

    const status = await onGetStatus(airItemId);

    this.setState({
      status,
      isLoading: false,
    });
  };

  handelCloseDialog = () => this.setState({
    showModal: false,
    showDownloadForm: false,
    showOrderConfirmation: false,
    bookError: false,
  });

  handelBook = async () => {
    const { airItemId, onBook } = this.props;

    this.setState({ isLoading: true });

    const status = await onBook(airItemId);

    if (status) {
      return this.setState({
        isLoading: false,
        showModal: false,
      });
    }

    return this.setState({
      isLoading: false,
      bookError: !status,
    });
  };

  render() {
    const {
      airItemId,
      isFreeFlightCertificate,
      isRemoveFlightCertificate,
      onDownload,
    } = this.props;
    const {
      showModal,
      showDownloadForm,
      showOrderConfirmation,
      isLoading,
      status,
      bookError,
    } = this.state;

    const modalHtml = showModal && (
      <Dialog
        show={ showModal }
        width={ WIDTH_DIALOG }
        showCloseButton
        onClick={ this.handelCloseDialog }
      >
        <div className={ styles.wrapper_content }>
          <ContentDialogButtonInAir
            isFreeFlightCertificate={ isFreeFlightCertificate }
            isRemoveFlightCertificate={ isRemoveFlightCertificate }
            showDownloadForm={ showDownloadForm }
            showOrderConfirmation={ showOrderConfirmation }
            isLoading={ isLoading }
            bookError={ bookError }
            airItemId={ airItemId }
            status={ status }
            onDownload={ onDownload }
            onBook={ this.handelBook }
            onShowModalClose={ () => this.setState({ showModal: true }) }
            onShowOrderConfirmationOpen={ () => this.setState({ showOrderConfirmation: true }) }
            onShowOrderConfirmationClose={ () => this.setState({ showOrderConfirmation: false }) }
            onShowDownloadFormOpen={ () => this.setState({ showDownloadForm: true }) }
            onShowDownloadFormClose={ () => this.setState({ showDownloadForm: false }) }
          />
        </div>
      </Dialog>
    );

    return (
      <div>
        <FlatButton
          label={ LABELS.OPEN }
          onClick={ this.handelOpenDialog }
        />
        { modalHtml }
      </div>
    );
  }
}

export { FlightCertificateButtonInAir };
