import {
  NUMBERS,
  EXCEL,
  SOURCE_TYPE,
  PRINCIPALS_SOURCE_TYPE,
  TYPE_SERVICE,
} from '../../constants/unloading1c';

let ACTIONS;

const DEFAULT = {
  listCompany: [],
  settings: {
    startDate: null,
    endDate: null,
    groupingType: null,
    itemKind: null,
    dateDivisionType: null,
    realizationSettings: {
      organizationId: null,
      contragentId: 0,
      comment: '',
    },
    fileImportSettings: {
      fileLink: '',
      excelSettings: {
        columnNamesNomenclaturesIds: {},
        sheetTitles: [],
        tripItemColumnName: SOURCE_TYPE.TRIP_ITEM_ID,
        tripItemSourceType: SOURCE_TYPE.TRIP_ITEM_ID,
      },
    },
    fileExportSettings: { email: '' },
    principalSourceType: null,
    principalId: null,
  },
  error: null,
  disabledMonth: false,
  step: NUMBERS.ONE,
  workSheets: [],
  nomenclatures: [],
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.UNLOADING1C.INFO_AMOUNTS_SUPPLIER: {
      const listCompany = action.listCompany;

      return {
        ...state,
        listCompany,
      };
    }

    case ACTIONS.UNLOADING1C.RESET: {
      return { ...DEFAULT };
    }

    case ACTIONS.UNLOADING1C.SET_ERROR: {
      const error = action.payload;

      return {
        ...state,
        error,
      };
    }

    case ACTIONS.UNLOADING1C.SET_WORKSHEETS: {
      const workSheets = action.payload;

      return {
        ...state,
        workSheets,
      };
    }

    case ACTIONS.UNLOADING1C.SET_START_DATE: {
      const startDate = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          startDate,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_END_DATE: {
      const endDate = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          endDate,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_COMPANY: {
      const organizationId = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          realizationSettings: {
            ...state.settings.realizationSettings,
            organizationId,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_COUNTERAGENT: {
      const contragentId = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          realizationSettings: {
            ...state.settings.realizationSettings,
            contragentId,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_TYPE_IMPLEMENTATION: {
      const itemKind = action.payload;

      if (itemKind === TYPE_SERVICE[1].value) {
        return {
          ...state,
          settings: {
            ...state.settings,
            itemKind,
            principalId: null,
            principalSourceType: null,
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          itemKind,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_DISABLED_MONTH: {
      const disabledMonth = action.payload;

      return {
        ...state,
        disabledMonth,
      };
    }

    case ACTIONS.UNLOADING1C.SET_STEP: {
      const step = action.payload;

      return {
        ...state,
        step,
      };
    }

    case ACTIONS.UNLOADING1C.SET_GROUPING_TYPE: {
      const groupingType = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          groupingType,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_DAY_TYPE: {
      const dateDivisionType = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          dateDivisionType,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_AMOUNT_TYPE: {
      const amountType = action.payload;

      if (!amountType) {
        return {
          ...state,
          settings: {
            ...state.settings,
            fileImportSettings: {
              ...state.settings.fileImportSettings,
              excelSettings: {
                ...state.settings.fileImportSettings.excelSettings,
                columnNamesNomenclaturesIds: {},
              },
            },
          },
        };
      }

      const { columnNamesNomenclaturesIds } = state.settings.fileImportSettings.excelSettings;

      const selectedColumnsVatRates = amountType.reduce((acc, column) => {
        if (column in columnNamesNomenclaturesIds) {
          acc[column] = columnNamesNomenclaturesIds[column];
        } else {
          acc[column] = null;
        }

        return acc;
      }, {});

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              columnNamesNomenclaturesIds: selectedColumnsVatRates,
            },
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_NOMENCLATURE: {
      const { column, value } = action.payload;

      const updateColumnVatRates = {
        ...state.settings.fileImportSettings.excelSettings.columnNamesNomenclaturesIds,
        [column]: value,
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              columnNamesNomenclaturesIds: updateColumnVatRates,
            },
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_COMMENT: {
      const comment = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          realizationSettings: {
            ...state.settings.realizationSettings,
            comment,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_PRINCIPAL: {
      const principal = action.payload;

      if (principal === EXCEL.value) {
        return {
          ...state,
          settings: {
            ...state.settings,
            principalSourceType: PRINCIPALS_SOURCE_TYPE.EXCEL,
            principalId: null,
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          principalSourceType: PRINCIPALS_SOURCE_TYPE.REQUEST,
          principalId: principal,
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_FILELINK: {
      const fileLink = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            fileLink,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_SHEET: {
      const sheetTitles = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              sheetTitles,
            },
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_TRIP_ITEM_COLUMN_NAME: {
      const tripItemColumnName = action.payload;

      if (!tripItemColumnName) {
        return {
          ...state,
          settings: {
            ...state.settings,
            fileImportSettings: {
              ...state.settings.fileImportSettings,
              excelSettings: {
                ...state.settings.fileImportSettings.excelSettings,
                tripItemColumnName: SOURCE_TYPE.TRIP_ITEM_ID,
                tripItemSourceType: SOURCE_TYPE.TRIP_ITEM_ID,
              },
            },
          },
        };
      }

      return {
        ...state,
        settings: {
          ...state.settings,
          fileImportSettings: {
            ...state.settings.fileImportSettings,
            excelSettings: {
              ...state.settings.fileImportSettings.excelSettings,
              tripItemColumnName,
              tripItemSourceType: SOURCE_TYPE.TICKET_NUMBER,
            },
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_EMAIL: {
      const email = action.payload;

      return {
        ...state,
        settings: {
          ...state.settings,
          fileExportSettings: {
            ...state.settings.fileExportSettings,
            email,
          },
        },
      };
    }

    case ACTIONS.UNLOADING1C.SET_NOMENCLATURES: {
      const nomenclatures = action.payload;

      return {
        ...state,
        nomenclatures,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('unloading1c', reducer, DEFAULT);
}
