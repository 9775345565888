import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FLYCLASS, { TRIP_MODIFICATIONS } from '../../../../bi/constants/airline';
import { FIELDS, STATUSCODES } from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import { dash } from '../../../../bi/constants/regExp';

import {
  deletePart,
  preparedPriceDetails,
  prepareFunds,
  prepareVatDetails,
  setRoutesDurationAndTime,
  updateVatDetails,
} from '../../../../bi/utils/air';
import {
  applyAnalytics,
  removeAnalytics,
  validateAnalytics,
  validateSingleAnalytics,
} from '../../../../bi/utils/customAnalytics';
import {
  isMoment,
  momentHours,
  momentMinutes,
  momentObject,
  momentSetHoursAndMinutes,
} from '../../../../bi/utils/formatDate';
import { findDepartments } from '../../../../bi/utils/trip';
import { isMandatory } from '../../../../bi/utils/account';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import { FlatButton } from '../../../../components/button';
import Suggest from '../../../../components/Suggest';
import AlertTP from '../../../../components/tpDetails/alert';
import AnalyticsRows from '../analyticsRows';
import SelectDepartment from '../selectDepartment';
import SelectProject from '../selectProject';

import AirTickets from './tickets/AirTickets';
import PartEditTicket from './partEditItem';

import styles from '../../styles/form.module.scss';

moment.locale('ru');

const FULLTIMEFORMAT = 'YYYY-MM-DDTHH:mm:sszzZ';
const GET_INFO_ERROR = 'ERROR';

class AirlineForm extends Component {
  static propTypes = {
    tripAnalyticsValueIds: PropTypes.array,
    analytics: PropTypes.array,
    specialBillAccount: PropTypes.object,
    setTripAnalytics: PropTypes.func,
    companies: PropTypes.array,
    isAwaitingTicketing: PropTypes.bool,
    airline: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object).isRequired,
    travelPolicyService: PropTypes.object,
    tripService: PropTypes.object.isRequired,
    changeRate: PropTypes.object,
    mandatoryProject: PropTypes.array.isRequired,
    isTripPaidByPersonalFunds: PropTypes.bool,
  };

  static defaultProps = {
    isAwaitingTicketing: false,
    specialBillAccount: null,
    companies: [],
    employees: [],
    travelPolicyService: {},
    changeRate: {},
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    isTripPaidByPersonalFunds: false,
  };

  constructor(props) {
    super(props);

    this.setStateFromProps(props);
    this.waitingResponse = false;
    this.providerNameOptions = props.airService.getAirProviderNamesForSelect();
  }

  componentDidMount() {
    this.getTP(this.state.Employees[0].Id);
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);
    this.setDepartment();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.airline.OrderTripItemId !== this.state.OrderTripItemId) {
      this.setStateFromProps({ airline: newProps.airline, airService: this.props.airService });
    }
  }

  updateTripData = ({ employeesList }) => this.setState({ employeesList });

  setStateFromProps = ({ airline, airService }) => {
    const voucher = JSON.parse(airline.JsonData);
    const {
      DepartmentId, ProjectId, CompanyId, Employees, ServiceType, OrderTripItemId, UserAnalytics = [],
    } = airline;

    let JsonData;
    let newValidator;

    if (airline.Status === STATUSCODES.METADATA) {
      JsonData = airService.createAwaitingAirTickets(voucher, airline);
    } else {
      JsonData = airService.createAirTickets(voucher, airline);
      newValidator = airService.createNewValidatorByVoucher(JsonData);

      JsonData[0].Routes = setRoutesDurationAndTime(JsonData[0].Routes);
      JsonData[0].Fare = voucher.Fare;
    }

    const currentEmployeeId = airline && Employees && Employees[0] && Employees[0].Id;
    const newMandatory = isMandatory(this.props.mandatoryProject, CompanyId);

    this.state = {
      UserAnalytics,
      OrderTripItemId,
      ServiceType,
      Description: '',
      Departments: [],
      Status: 1,
      mandatoryProject: newMandatory,
      EmployeeId: currentEmployeeId,
      Employees,
      CompanyId: CompanyId || 0,
      ProjectId: ProjectId || 0,
      DepartmentId: DepartmentId || 0,
      Penalties: {
        Enabled: false,
        Description: '',
        Penalties: 0,
        Tax: 0,
      },
      ReturnFunds: {
        Enabled: false,
        Description: '',
      },
      Surcharges: {
        Enabled: true,
        Commission: 0,
        Taxes: 0,
        Fee: 0,
        Tax: 0,
        Base: 0,
        Description: '',
      },
      ChangeVat: { Enabled: false },
      JsonData,
      tpDetails: [],
      showAlertTP: false,

      validation: {
        Description: '',
        list: newValidator,
        analytics: {},
      },

      employeesList: [],
      getInfoError: '',
      suggests: TRIP_MODIFICATIONS,
    };
  };

  setDepartment = async () => {
    const { tripService: { getEmployeeDataByID } } = this.props;
    const { EmployeeId, CompanyId } = this.state;

    const currentEmployee = await getEmployeeDataByID(EmployeeId);

    this.setState({ Departments: findDepartments(currentEmployee, CompanyId) });
  };

  normalizeVoucher = (voucher) => {
    voucher.forEach((ticket) => {
      ticket.Passports = {
        EmployeeId: ticket.Travellers.Id,
        PassportId: typeof ticket.Passport === 'object' ? ticket.Passport.PassportId : ticket.Passport,
      };

      ticket.Travellers = ticket.Travellers.Id;
      ticket.Routes.forEach((route) => {
        const minutes = (isMoment(route.Duration))
          ? (route.Duration.hours() * 60) + route.Duration.minutes()
          : route.Duration;

        route.Duration = minutes;
      });

      if (this.props.isAwaitingTicketing) {
        if (ticket.Routes.length > 1) {
          ticket.Routes = ticket.Routes.filter((route) => route.HasValue);
          ticket.Routes.forEach((route) => {
            if (route.Segments.length > 1) {
              route.Segments = route.Segments.filter((segment) => segment.HasValue);
              route.Segments.forEach((segment) => {
                const departureDate = typeof segment.DepartureDate === 'string'
                  ? moment(segment.DepartureDate)
                  : segment.DepartureDate;
                const arrivalDate = typeof segment.ArrivalDate === 'string'
                  ? moment(segment.ArrivalDate)
                  : segment.ArrivalDate;


                segment.ArrivalDate = arrivalDate.format(FULLTIMEFORMAT);

                segment.DepartureDate = departureDate.format(FULLTIMEFORMAT);
              });
            } else {
              route.Segments.forEach((segment) => {
                const departureDate = typeof segment.DepartureDate === 'string'
                  ? moment(segment.DepartureDate)
                  : segment.DepartureDate;
                const arrivalDate = typeof segment.ArrivalDate === 'string'
                  ? moment(segment.ArrivalDate)
                  : segment.ArrivalDate;


                segment.ArrivalDate = arrivalDate.format(FULLTIMEFORMAT);

                segment.DepartureDate = departureDate.format(FULLTIMEFORMAT);
              });
            }
          });
        } else {
          ticket.Routes.forEach((route) => {
            if (route.Segments.length > 1) {
              route.Segments = route.Segments.filter((segment) => segment.HasValue);
              route.Segments.forEach((segment) => {
                const departureDate = typeof segment.DepartureDate === 'string'
                  ? moment(segment.DepartureDate)
                  : segment.DepartureDate;
                const arrivalDate = typeof segment.ArrivalDate === 'string'
                  ? moment(segment.ArrivalDate)
                  : segment.ArrivalDate;


                segment.ArrivalDate = arrivalDate.format(FULLTIMEFORMAT);

                segment.DepartureDate = departureDate.format(FULLTIMEFORMAT);
              });
            } else {
              route.Segments.forEach((segment) => {
                const departureDate = typeof segment.DepartureDate === 'string'
                  ? moment(segment.DepartureDate)
                  : segment.DepartureDate;
                const arrivalDate = typeof segment.ArrivalDate === 'string'
                  ? moment(segment.ArrivalDate)
                  : segment.ArrivalDate;


                segment.ArrivalDate = arrivalDate.format(FULLTIMEFORMAT);

                segment.DepartureDate = departureDate.format(FULLTIMEFORMAT);
              });
            }
          });
        }
      } else {
        ticket.Routes.forEach((route) => {
          route.Segments.forEach((segment) => {
            const departureDate = momentSetHoursAndMinutes(
              segment.DepartureDate,
              momentHours(segment.DepartureTime),
              momentMinutes(segment.DepartureTime),
            );
            const arrivalDate = momentSetHoursAndMinutes(
              segment.ArrivalDate,
              momentHours(segment.ArrivalTime),
              momentMinutes(segment.ArrivalTime),
            );

            segment.ArrivalDate = arrivalDate.format(FULLTIMEFORMAT);

            segment.DepartureDate = departureDate.format(FULLTIMEFORMAT);
          });
        });
      }
    });
  };

  handlerChangeDescription = (value) => {
    this.setState({
      Description: value,
      Surcharges: {
        ...this.state.Surcharges,
        Description: value,
      },
    });
  };

  handlerSelectDescription = ({ name }) => {
    this.setState({
      Description: name,
      Surcharges: {
        ...this.state.Surcharges,
        Description: name,
      },
    });
  };

  handleConfirm = () => {
    const {
      OrderTripItemId,
      CompanyId,
      Description,
      Surcharges,
      ReturnFunds,
      Penalties,
      ServiceType,
      Status,
      JsonData,
      ProjectId,
      DepartmentId,
      UserAnalytics,
      mandatoryProject,
    } = this.state;

    if (this.props.isAwaitingTicketing) {
      const { isValid, validation } = this.validationSubmitAwaitingTicketing({ JsonData });

      if (isValid) {
        const needMandatoryProject = mandatoryProject ? ProjectId : true;

        if (needMandatoryProject) {
          this.normalizeVoucher(JsonData);

          const updatedJsonData = prepareVatDetails(JsonData);
          const preparedJson = preparedPriceDetails(updatedJsonData, true);

          const result = [];
          preparedJson.forEach((json) => {
            const item = {
              OrderTripItemId,
              TripId: 0,
              Status,
              ServiceType,
              CompanyId: parseInt(CompanyId, 10),
              ProjectId: parseInt(ProjectId, 10),
              DepartmentId: parseInt(DepartmentId, 10),
              JsonData: JSON.stringify([json]),
              UserAnalytics,
            };

            result.push(item);
          });

          this.props.onConfirm(result);
        }
      } else {
        this.setState({ validation });
      }
    } else {
      const { isValid, validation } = this.validationSubmit({
        Description, JsonData, ProjectId,
      });

      if (isValid) {
        this.normalizeVoucher(JsonData);

        const updatedJsonData = prepareVatDetails(JsonData);
        const preparedJson = preparedPriceDetails(updatedJsonData, true);

        const item = {
          OrderTripItemId,
          OrderTripId: 0,
          CompanyId: parseInt(CompanyId, 10),
          ProjectId: parseInt(ProjectId, 10),
          DepartmentId: parseInt(DepartmentId, 10),

          Description,

          Status,
          ServiceType,

          UserAnalytics,

          JsonData: JSON.stringify(preparedJson),
        };

        item.Penalties = prepareFunds(Penalties);
        item.ReturnFunds = prepareFunds(ReturnFunds);
        item.Surcharges = prepareFunds(Surcharges);

        this.props.onConfirm(item);
      } else {
        scrollToErrMessage();
        this.setState({ validation });
      }
    }
  };

  getTP = (id) => {
    const { travelPolicyService } = this.props;
    const AIRLINE = travelPolicyService.getServiceTypeConst().AIRLINE;

    const { tpDetails } = this.state;

    travelPolicyService.getEmployeeTP(id)
      .then((res) => {
        tpDetails[0] = (travelPolicyService.getTPDetails(res, AIRLINE));

        this.setState({
          tpDetails,
          showAlertTP: !!tpDetails[0],
        });
      })
      .catch(() => {
        tpDetails[0] = null;

        this.setState({ tpDetails });
      });
  };

  closeAlertTP = () => this.setState({ showAlertTP: false });

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  };

  handleEmployeeFocus = async () => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, '');
  };

  handleSelectEmployee = async ({ ticketInd, employee: { Id } }) => {
    const {
      airService, tripService, isAwaitingTicketing,
    } = this.props;

    const currentEmployee = await tripService.getEmployeeDataByID(Id);

    const { CompanyId } = this.state;

    this.setState((prevState) => {
      const newState = { ...prevState };
      let selectedPassport = currentEmployee.Documents.find((document) => document.Selected);

      if (!selectedPassport) selectedPassport = currentEmployee.Documents[0];

      newState.Departments = findDepartments(currentEmployee, CompanyId);
      newState.EmployeeId = currentEmployee.Id;

      newState.JsonData[ticketInd].Travellers = currentEmployee;
      if (!isAwaitingTicketing) {
        newState.validation.list[ticketInd].Travellers = airService.airTripFieldValidation(
          null, null, 'Travellers', newState.JsonData[ticketInd].Travellers,
        );
      }
      newState.JsonData[ticketInd].Passport = {
        EmployeeId: Id,
        PassportId: selectedPassport ? selectedPassport.Id : null,
      };

      return newState;
    }, () => this.getTP(Id, ticketInd));
  };

  handlerRemoveEmployee = ({ ticketInd }) => {
    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.JsonData[ticketInd].Travellers = null;
      newState.Departments = [];
      newState.DepartmentId = 0;
      newState.EmployeeId = 0;
      if (!this.props.isAwaitingTicketing) {
        newState.validation.list[ticketInd].Travellers = this.props.airService.airTripFieldValidation(
          null, null, 'Travellers', newState.JsonData[ticketInd].Travellers,
        );
      }

      newState.tpDetails[0] = null;

      return newState;
    });
  };

  handlerChangeTicket = ({
    ticketInd, routeInd, segmentInd, field, value,
  }) => {
    this.setState((state) => {
      const part = field.split('.');

      let obj = state.JsonData[ticketInd];

      if (routeInd !== null && segmentInd !== null) {
        obj = obj.Routes[routeInd].Segments[segmentInd];
      } else if (routeInd !== null) {
        obj = obj.Routes[routeInd];
      }

      if (part.length === 1) {
        obj[field] = value;
      } else {
        obj[part[0]] = {
          ...obj[part[0]],
          [part[1]]: value,
        };
      }

      return state;
    });
  };

  handleChangeVatDetails = (
    ticketInd, rate, field, value,
  ) => this.setState((state) => updateVatDetails(ticketInd, rate, field, value, state));

  handleSelectProject = ({ id }) => {
    const { airService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? airService.airTripFieldValidation(null, null, FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectDepartment = ({ id }) => this.setState({ DepartmentId: id });

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handlerAddPart = (change) => {
    const {
      airline, airService, isAwaitingTicketing,
    } = this.props;
    const { JsonData } = this.state;

    this.setState((state) => {
      switch (change.type) {
        case 'ticket':
          if (!isAwaitingTicketing) {
            state.JsonData.push(airService.createNewTicket());
            state.validation.list.push(airService.createNewValidator());
          } else {
            state.JsonData.push(
              airService.createAwaitingAddAirTickets(JSON.parse(airline.JsonData), airline),
            );
          }
          break;
        case 'route':
          state.JsonData[change.ticketInd].Routes.push(airService.createNewRoute());
          if (!isAwaitingTicketing) {
            state.validation.list[change.ticketInd].Routes.push(airService.createNewRouteValidator());
          }
          break;
        case 'segment':
          state.JsonData[change.ticketInd].Routes[change.routeInd].Segments.push(
            airService.createNewSegment(),
          );
          if (!isAwaitingTicketing) {
            state.validation.list[change.ticketInd].Routes[change.routeInd].Segments.push(
              airService.createNewSegmentValidator(),
            );
          }
          break;
      }

      return state;
    }, () => this.handleChangeFlyClassAndRoutes(JsonData[0].Class));
  };

  handlerDeletePart = (change) => this.setState((state) => deletePart(state, change));

  validationSubmitAwaitingTicketing = ({ JsonData }) => {
    const { airService } = this.props;

    let resultValidation = true;
    const resultValidationObj = [];

    for (let i = 0, item = JsonData[0]; i < JsonData.length; i++, item = JsonData[i]) {
      const result = airService.validationAwaitingTicketing(item);

      resultValidation = result.isValid;
      resultValidationObj.push(result.validation);
    }

    return {
      isValid: resultValidation,
      validation: { list: resultValidationObj },
    };
  };

  validationSubmit = ({
    Description, JsonData, ProjectId,
  }) => {
    const {
      airService,
      analytics,
      tripAnalyticsValueIds: tripAnalytics,
      isAwaitingTicketing,
      projects,
      isTripPaidByPersonalFunds,
    } = this.props;
    const { UserAnalytics: serviceAnalytics, mandatoryProject } = this.state;

    let resultValidation = true;
    const resultValidationObj = [];

    const projectMassage = projects.length
      ? airService.airTripFieldValidationAir(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    for (let i = 0, item = JsonData[0]; i < JsonData.length; i++, item = JsonData[i]) {
      const result = airService.validationTicket(item);

      resultValidation = result.isValid;
      resultValidationObj.push(result.validation);

      if (!isAwaitingTicketing && result.validation.PriceDetails.Base) {
        resultValidation = true;
      }
    }
    const projectId = mandatoryProject && !isTripPaidByPersonalFunds ? projectMassage : '';
    const description = airService.airTripFieldValidationAir('Description', Description);
    if (description || projectId) {
      resultValidation = false;
    }

    const analyticsValidation = validateAnalytics(tripAnalytics, serviceAnalytics, analytics);
    if (Object.keys(analyticsValidation).some((key) => !!analyticsValidation[key])) {
      resultValidation = false;
    }

    return {
      isValid: resultValidation,
      validation: {
        Description: description,
        ProjectId: projectId,
        list: resultValidationObj,
        analytics: analyticsValidation,
      },
    };
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  handleGetInfo = async (provider, ticketNumber, pnr, surname, lastName, officeId, salePoint) => {
    const { tripService } = this.props;
    const { JsonData } = this.state;

    this.toggleWaitingResponse();
    try {
      const ticket = await tripService.getAirTripInfo(
        provider, ticketNumber, pnr, surname, lastName, officeId, salePoint,
      );

      const newRoutes = ticket.Routes.map((route, index) => {
        const segments = route.Segments.map((segment) => {
          this.handlerAddPart({
            type: 'segment', routeInd: index, ticketInd: 0,
          });
          const newSegment = segment;
          newSegment.ArrivalDate = momentObject(segment.ArrivalDate);
          newSegment.DepartureDate = momentObject(segment.DepartureDate);

          newSegment.Class = segment.Class || FLYCLASS[JsonData[0].Class];

          return newSegment;
        });
        this.handlerAddPart({ type: 'route', ticketInd: 0 });

        return {
          ...route,
          RouteIndex: index,
          Segments: segments,
        };
      });

      const newJsonData = [...this.state.JsonData];

      newJsonData[0] = {
        ...this.state.JsonData[0],
        PNR: ticket.PNR,
        PNRLocator: ticket.PNRLocator,
        PNRProvider: ticket.PNRProvider,
        Routes: setRoutesDurationAndTime(newRoutes),
        Num: ticket.TicketNumber || ticketNumber,
      };

      return this.setState({
        ...this.state,
        JsonData: newJsonData,
      }, this.toggleWaitingResponse);
    } catch (err) {
      return this.setState({ getInfoError: GET_INFO_ERROR }, this.toggleWaitingResponse);
    }
  };

  validationInput = (ticketInd, routeInd, segmentInd, field) => {
    this.setState((state) => {
      const part = field.split('.');

      let obj = state.JsonData[ticketInd];
      let resultObj = state.validation.list[ticketInd];

      if (routeInd !== null && segmentInd !== null) {
        obj = obj.Routes[routeInd].Segments[segmentInd];
        resultObj = resultObj.Routes[routeInd].Segments[segmentInd];
      } else if (routeInd !== null) {
        obj = obj.Routes[routeInd];
        resultObj = resultObj.Routes[routeInd];
      }

      let value = obj[field];
      if (part.length > 1) value = obj[part[0]][part[1]];

      const resultValidation = this.props.airService.airTripFieldValidation(routeInd, segmentInd, field, value);

      if (part.length === 1) {
        resultObj[field] = resultValidation;
      } else {
        resultObj[part[0]][part[1]] = resultValidation;
      }

      return state;
    });
  };

  handleChangeFlyClass = (ticketInd, routeInd, segmentInd, event, field, value) => {
    this.handlerChangeTicket({
      ticketInd, routeInd, segmentInd, event, field, value,
    });

    this.handleChangeFlyClassAndRoutes(value);
  };

  handleChangeFlyClassAndRoutes = (flyClass) => {
    const { JsonData } = this.state;

    const newRoutes = JsonData[0].Routes.map((route) => {
      const defaultRoute = route;

      defaultRoute.Segments = route.Segments.map((segment) => ({
        ...segment,
        Class: FLYCLASS[flyClass],
      }));

      return defaultRoute;
    });

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.JsonData[0].Routes = newRoutes;

      return newState;
    });
  };

  validationInputDescription = (e, field) => {
    const result = this.props.airService.airTripFieldValidationAir(field, this.state[field]);

    this.setState((state) => {
      state.validation[field] = result;

      return state;
    });
  };

  handlerChangeFinancesEnabled = (e, field, value) => this.setState((state) => {
    const newState = { ...state };
    const part = field.split('.');

    newState[part[0]][part[1]] = value;

    return newState;
  });

  handleChangeReturnFunds = (e, field, value) => this.setState((state) => {
    const newState = { ...state };
    newState.ReturnFunds[field] = value;

    return newState;
  });

  handlerChangeSurcharges = (e, field, value) => this.setState((state) => {
    const newState = { ...state };
    newState.Surcharges[field] = value;

    return newState;
  });

  handleSetInfoError = (error = '') => this.setState({ getInfoError: error });

  handleGetInfoTicket = async (provider, ticketNumber, pnr, surname, lastName, officeId) => {
    const { tripService, projects } = this.props;
    const {
      JsonData, mandatoryProject, ProjectId,
    } = this.state;

    this.toggleWaitingResponse();
    try {
      const thisTicket = await tripService.getAirTripInfo(provider, ticketNumber, pnr, surname, lastName, officeId);

      const requiredTickets = JsonData.map((obj) => {
        const projectMassage = projects.length
          ? tripService.hotelTripFieldValidation(FIELDS.PROJECTID, ProjectId)
          : ERRORSFORALL.NO_PROJECTS;

        const projectId = mandatoryProject ? projectMassage : '';
        const thisTicketNumber = obj.Num.replace(dash, '');
        const loadedTicketNumber = thisTicket.TicketNumber.replace(dash, '');
        const thisPnr = obj.PNR;
        const loadedPnr = thisTicket.PNR;

        const checkPnr = (thisPnr === loadedPnr) && (thisPnr !== '');
        const checkTicketNumber = (thisTicketNumber === loadedTicketNumber) && (thisTicketNumber !== '') && (projectId !== '');

        if (checkPnr || checkTicketNumber) {
          return {
            ...obj,
            PNR: thisTicket.PNR,
            PNRLocator: thisTicket.PNRLocator,
            PNRProvider: thisTicket.PNRProvider,
            PriceDetails: thisTicket.PriceDetails,
            VatDetails: thisTicket.VatDetails || [],
            Num: thisTicket.TicketNumber || ticketNumber,
            ProjectId: projectId,
          };
        }

        return obj;
      });

      return this.setState({
        ...this.state,
        JsonData: requiredTickets,
      }, this.toggleWaitingResponse);
    } catch (err) {
      return this.setState({ getInfoError: GET_INFO_ERROR }, this.toggleWaitingResponse);
    }
  };

  renderAlertTP = () => (this.state.showAlertTP ? <AlertTP onClose={ this.closeAlertTP } /> : null);

  renderProject = () => {
    const { projects, isTripPaidByPersonalFunds } = this.props;
    const {
      ProjectId, mandatoryProject, validation,
    } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        currentProjectId={ ProjectId }
        projects={ projects }
        validationProject={ validation.ProjectId }
        onSelect={ this.handleSelectProject }
        mandatoryProject={ mandatoryProject }
      />
    );
  };

  renderAnalytics = () => {
    const {
      airline,
      analytics = [],
      tripAnalyticsValueIds = [],
    } = this.props;

    const { validation: { analytics: analyticsValidation } } = this.state;

    return (
      <AnalyticsRows
        analytics={ analytics }
        tripAnalyticsValueIds={ tripAnalyticsValueIds }
        serviceAnalyticsValueIds={ airline.UserAnalytics }
        onSelect={ this.handleSelectAnalytics }
        validation={ analyticsValidation }
      />
    );
  };

  renderDepartment = () => {
    const {
      DepartmentId, Departments, EmployeeId,
    } = this.state;

    return (
      <SelectDepartment
        departments={ Departments }
        onSelect={ this.handleSelectDepartment }
        currentDepartmentId={ DepartmentId }
        currentEmployeeId={ EmployeeId }
      />
    );
  };

  render() {
    const {
      Description,
      validation,
      tpDetails,
      Surcharges,
      ChangeVat,
      ReturnFunds,
      Departments,
      EmployeeId,
      waitingResponse,
      getInfoError,
      suggests,
      JsonData,
      ProjectId,
    } = this.state;

    const {
      companies,
      airService,
      tripService,
      isAwaitingTicketing,
      projects,
      airline,
      changeRate,
      specialBillAccount,
      mandatoryProject,
    } = this.props;

    const { employeesList } = this.state;

    let airTicketHtml = null;

    if (isAwaitingTicketing) {
      airTicketHtml = (
        <PartEditTicket
          tickets={ JsonData }
          employees={ employeesList }
          providerNameOptions={ this.providerNameOptions }
          ProjectId={ ProjectId }
          onSelectEmployee={ this.handleSelectEmployee }
          onEmployeeFocus={ this.handleEmployeeFocus }
          onChangeEmployee={ this.handleChangeEmployee }
          onSelectProject={ this.handleSelectProject }
          handleChangeVatDetails={ this.handleChangeVatDetails }
          handlerRemoveEmployee={ this.handlerRemoveEmployee }
          handlerAddPart={ this.handlerAddPart }
          handlerDeletePart={ this.handlerDeletePart }
          handleGetInfo={ this.handleGetInfoTicket }
          onChange={ this.handlerChangeTicket }
          onBlur={ this.validationInput }
          handleSetInfoError={ this.handleSetInfoError }
          waitingResponse={ waitingResponse }
          validation={ validation.list }
          airService={ airService }
          tripService={ tripService }
          isAwaitingTicketing={ isAwaitingTicketing }
          companies={ companies }
          projects={ projects }
          airline={ airline }
          getInfoError={ getInfoError }
          departments={ Departments }
          employeeId={ EmployeeId }
          mandatoryProject={ mandatoryProject }
        />
      );
    } else {
      airTicketHtml = (
        <AirTickets
          isEditing
          tickets={ JsonData }
          employees={ employeesList }
          providerNameOptions={ this.providerNameOptions }
          onSelectEmployee={ this.handleSelectEmployee }
          onSelectDepartment={ this.handleSelectDepartment }
          onEmployeeFocus={ this.handleEmployeeFocus }
          onChangeEmployee={ this.handleChangeEmployee }
          handleChangeVatDetails={ this.handleChangeVatDetails }
          handlerRemoveEmployee={ this.handlerRemoveEmployee }
          handlerAddPart={ this.handlerAddPart }
          handlerDeletePart={ this.handlerDeletePart }
          onChange={ this.handlerChangeTicket }
          onBlur={ this.validationInput }
          validation={ validation.list }
          airService={ airService }
          isAwaitingTicketing={ isAwaitingTicketing }
          specialBillAccount={ specialBillAccount }
          tpDetails={ tpDetails }
          changeRate={ changeRate }
          surcharges={ Surcharges }
          changeVat={ ChangeVat }
          onChangeFinancesEnabled={ this.handlerChangeFinancesEnabled }
          onChangeSurcharges={ this.handlerChangeSurcharges }
          airline={ airline }
          onChangeReturnFunds={ this.handleChangeReturnFunds }
          returnFunds={ ReturnFunds }
          handleGetInfo={ this.handleGetInfo }
          waitingResponse={ waitingResponse }
          handleChangeFlyClass={ this.handleChangeFlyClass }
          getInfoError={ getInfoError }
          handleSetInfoError={ this.handleSetInfoError }
        />
      );
    }


    const descriptionHtml = !isAwaitingTicketing && (
      <div className={ `${styles.row} ${styles.panel}` }>
        <h4>Описание изменения</h4>
        <div className={ styles['autocomplete-input'] }>
          <Suggest
            title='Наименование изменения'
            valid={ validation.Description }
            suggests={ suggests }
            currentLabel={ Description || '' }
            onSelect={ this.handlerSelectDescription }
            onChange={ this.handlerChangeDescription }
            isTrackingChanges
          />
        </div>
      </div>
    );

    const selectProjectsHtml = !isAwaitingTicketing && this.renderProject();
    const selectDepartmentHtml = !isAwaitingTicketing && this.renderDepartment();

    const analyticsHtml = this.renderAnalytics();

    return (
      <div className={ styles.wrap }>
        { descriptionHtml }
        <div className={ `${styles.row} ${styles.panel} ${isAwaitingTicketing ? styles.panel__AwaitingTicketing : ''}` }>
          <h4>Ваучер</h4>
          <div className={ styles.row }>
            { selectDepartmentHtml }
            { selectProjectsHtml }
          </div>
          { analyticsHtml }
          { airTicketHtml }
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label='Подтвердить изменения'
            />
          </div>
        </div>
        { this.renderAlertTP() }
      </div>
    );
  }
}

export default AirlineForm;
