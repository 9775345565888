import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AjaxButton from '../../../../components/ajaxButton';
import { REPORTLABALES } from '../../../../bi/constants/reports';

import styles from '../../styles/report.module.scss';

class DebtorReportPage extends Component {
  static propTypes = { onDownload: PropTypes.func.isRequired };

  state = { waitingResponse: false };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  handleDownload = () => {
    this.toggleWaitingResponse();
    this.props.onDownload()
      .then(this.toggleWaitingResponse)
      .catch(this.toggleWaitingResponse);
  };

  render() {
    const { waitingResponse } = this.state;

    return (
      <div className={ styles.main }>
        <div className={ styles.button }>
          <AjaxButton
            label={ REPORTLABALES.DOWNLOAD }
            loading={ waitingResponse }
            onClick={ this.handleDownload }
          />
        </div>
      </div>
    );
  }
}

export default DebtorReportPage;
