import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import { Button, Dialog } from 'sw-ui';

import Input from '../../../../components/input';

import COMPONENTS from '../../../../bi/constants/components';
import { HOTEL_PROVIDER_VALUE } from '../../../../bi/constants/hotel';
import { numbersAndColon, timePattern } from '../../../../bi/constants/regExp';
import { FIELDS, TRIPSLABELS } from '../../../../bi/constants/trips';

import { getDefaultValidation } from '../../../../bi/utils/hotels';
import { setSelectMenuStyle } from '../../../../bi/utils/account';

import styles from '../../styles/form.module.scss';

const LABELS = {
  RATE_SW: 'Собственный тариф смартвэй',
  PERIOD: 'Период',
  SELECT_HOTEL: 'Выберите отель',
  ADD_HOTEL: 'Добавить отель',
  TEXT_ADD_HOTEL: 'Вы уверены, что отеля нет в выпадающем списке?',
  SAVE: 'Сохранить',
  CANCEL: 'Отменить',
};

const ERRORS = {
  INVALID_CHARACTER: 'недопустимые символы',
  INVALID_FORMAT: 'недопустимый формат',
};

class EditMainHotelFields extends Component {
  static propTypes = {
    rates: PropTypes.array,
    hotelProps: PropTypes.object.isRequired,
    onShowModal: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    tripService: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
  };

  static defaultProps = { rates: [] };

  constructor(props) {
    super(props);

    this.state = {
      Hotel: {
        Name: '',
        Address: '',
        City: '',
        RegionId: '',
        Phone: '',
        Stars: 0,
        CheckinTime: '',
        CheckoutTime: '',
        Location: '',
        CountryCode: '',
      },
      ProviderName: props.data.ProviderName || HOTEL_PROVIDER_VALUE.ostrovok,
      Contact: '',
      errors: {
        [FIELDS.HOTELCHECKINTIME]: '',
        [FIELDS.HOTELCHECKOUTTIME]: '',
      },
      locationAutocompleteValue: '',
      validation: getDefaultValidation(),
      suggestCities: [],
      suggestCountries: [],
      disableLocation: true,
    };
  }

  handleChangeJsonInput = (e, field, value) => {
    let newValue = value;

    const part = field.split('.');

    if (typeof value === 'number') {
      newValue = parseFloat(value.toFixed(2));
    }

    if (part.length === 1) {
      this.setState({ [field]: newValue });
    } else {
      this.setState({
        [part[0]]: {
          ...this.state[part[0]],
          [part[1]]: newValue,
        },
      });
    }
  };

  handleChangeProviderName = (e, field, value) => {
    const { rates, tripService } = this.props;

    if (!rates && value === HOTEL_PROVIDER_VALUE.travelline) {
      tripService.getTravellineRates(); //
    }

    this.handleChangeJsonInput(e, field, value);
  };

  handleChangeTimeInput = (e, field, value) => {
    if (value.length === 0 || numbersAndColon.test(value)) {
      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: '',
        },
      }));
      const part = field.split('.');

      this.setState({
        [part[0]]: {
          ...this.state[part[0]],
          [part[1]]: value,
        },
      });
    } else {
      this.setState((prevState) => ({
        ...prevState,
        errors: {
          ...prevState.errors,
          [field]: ERRORS.INVALID_CHARACTER,
        },
      }));
    }
  };

  handleBlurTimeInput = (e, field) => {
    const value = e.target.value;

    if (value.length === 0 || timePattern.test(value)) {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: '',
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          [field]: ERRORS.INVALID_FORMAT,
        },
      });
    }
  };

  handleConfirm = () => {
    const {
      isValid, validation, errors,
    } = this.validationConfirm();

    if (isValid) {
      const {
        Hotel,
        ProviderName,
        Contact,
      } = this.state;
      this.props.onConfirm({
        Hotel,
        ProviderName,
        Contact,
      });
    } else {
      this.setState({
        validation: { ...validation },
        errors,
      });
    }
  };

  handleChangeAutocomplete = async (value) => {
    const {
      tripService: {
        autocompleteRegion,
        hotelTripFieldValidation,
      },
    } = this.props;
    const { Hotel, validation } = this.state;
    const { Hotel: validationHotel } = validation;

    const result = hotelTripFieldValidation(FIELDS.HOTELCITY, value);

    this.setState({
      disableLocation: false,
      Hotel: {
        ...Hotel,
        RegionId: null,
        City: value,
      },
      validation: {
        ...validation,
        Hotel: {
          ...validationHotel,
          City: result,
        },
      },
    });

    const suggest = await autocompleteRegion(value);

    this.setState({ suggestCities: value ? suggest : [] });
  };

  handleChangeLocationAutocomplete = async (value) => {
    const {
      tripService: {
        hotelTripFieldValidation,
        autocompleteCountry,
      },
    } = this.props;
    const {
      Hotel, validation, disableLocation,
    } = this.state;
    const { Hotel: validationHotel } = validation;


    if (disableLocation) return;

    const result = hotelTripFieldValidation(FIELDS.HOTEL_LOCATION, '');

    this.setState({
      locationAutocompleteValue: value,
      Hotel: {
        ...Hotel,
        CountryCode: null,
        Location: '',
      },
      validation: {
        ...validation,
        Hotel: {
          ...validationHotel,
          Location: result,
        },
      },
    });

    const suggest = await autocompleteCountry(value);

    this.setState({ suggestCountries: value ? suggest : [] });
  };

  handleSelectAutocomplete = async ({ City, RegionId }) => {
    const { tripService: { getRegion, hotelTripFieldValidation } } = this.props;
    const { Hotel, validation } = this.state;
    const { Hotel: validationHotel } = validation;

    const responce = await getRegion(RegionId);

    const location = responce ? responce.CountryNameRu : '';
    const countryCode = responce ? responce.CountryCode : '';

    const result = hotelTripFieldValidation(FIELDS.HOTEL_LOCATION, location);

    this.setState({
      locationAutocompleteValue: location,
      disableLocation: true,
      Hotel: {
        ...Hotel,
        City,
        RegionId,
        Location: location,
        CountryCode: countryCode,
      },
      validation: {
        ...validation,
        Hotel: {
          ...validationHotel,
          Location: result,
        },
      },
    });
  };

  handleSelectLocationAutocomplete = ({ name, code }) => {
    const { tripService: { hotelTripFieldValidation } } = this.props;
    const { Hotel, validation } = this.state;
    const { Hotel: validationHotel } = validation;

    const result = hotelTripFieldValidation(FIELDS.HOTEL_LOCATION, name);

    this.setState({
      locationAutocompleteValue: name,
      Hotel: {
        ...Hotel,
        Location: name,
        CountryCode: code,
      },
      validation: {
        ...validation,
        Hotel: {
          ...validationHotel,
          Location: result,
        },
      },
    });
  };

  validateInput = (e, field) => {
    const { tripService: { hotelTripFieldValidation } } = this.props;
    const { validation } = this.state;

    const part = field.split('.');

    if (part.length === 1) {
      const result = hotelTripFieldValidation(field, this.state[field]);

      this.setState({
        validation: {
          ...validation,
          [field]: result,
        },
      });
    } else {
      const result = hotelTripFieldValidation(field, this.state[part[0]][part[1]]);

      this.setState({
        validation: {
          ...validation,
          [part[0]]: {
            ...validation[part[0]],
            [part[1]]: result,
          },
        },
      });
    }
  };

  validationConfirm = () => {
    const { tripService: { hotelTripFieldValidation } } = this.props;
    const {
      Hotel: {
        Name,
        Address,
        CheckinTime,
        CheckoutTime,
        City,
        Location,
      },
      disableLocation,
    } = this.state;

    const name = hotelTripFieldValidation(FIELDS.HOTELNAMEUPPER, Name);
    const address = hotelTripFieldValidation(FIELDS.HOTELADDRESS, Address);
    const city = hotelTripFieldValidation(FIELDS.HOTELCITY, City);
    const location = hotelTripFieldValidation(FIELDS.HOTEL_LOCATION, Location);
    const checkinTime = hotelTripFieldValidation(FIELDS.HOTELCHECKINTIME, CheckinTime);
    const checkoutTime = hotelTripFieldValidation(FIELDS.HOTELCHECKOUTTIME, CheckoutTime);

    const validateObj = {
      isValid: false,
      validation: {
        Hotel: {
          Name: name,
          Address: address,
          City: city,
        },
      },
      errors: {
        [FIELDS.HOTELCHECKINTIME]: checkinTime,
        [FIELDS.HOTELCHECKOUTTIME]: checkoutTime,
      },
    };

    const validateObjWithLocation = {
      ...validateObj,
      validation: {
        Hotel: {
          ...validateObj.validation.Hotel,
          Location: location,
        },
      },
    };

    const getValidateObj = () => {
      if (!disableLocation) return validateObjWithLocation;

      return validateObj;
    };

    if (name || address || city || checkinTime || checkoutTime || location) {
      return getValidateObj();
    }

    return { isValid: true };
  };

  renderAutocompleteCity = () => {
    const {
      suggestCities,
      Hotel: { City },
      validation: { Hotel: { City: validationCity } },
    } = this.state;

    const wrapperStyle = { style: styles.wrap_auto_input };
    const inputProps = {
      className: `${styles.input} ${validationCity ? styles.not_valid_input : ''}`,
      type: 'search',
    };
    const messageHtml = validationCity && <span className={ styles['error-msg'] }>{ validationCity }</span>;

    const renderItem = (item) => {
      if (!item) {
        return null;
      }

      return (
        <div className={ styles['autocomplete-item'] }>
          { `${item.City}` }
        </div>
      );
    };

    return (
      <div className={ styles['col-1-3'] }>
        <div className={ `${styles['auto-input']} ${styles['autocomplete-label']}` }>
          <label>{ TRIPSLABELS.CITY }</label>
          <Autocomplete
            menuStyle={ setSelectMenuStyle(suggestCities) }
            value={ City }
            items={ suggestCities }
            getItemValue={ (item) => item.City }
            onSelect={ (value, item) => this.handleSelectAutocomplete(item) }
            onChange={ ({ target: { value } }) => this.handleChangeAutocomplete(value) }
            inputProps={ inputProps }
            wrapperStyle={ wrapperStyle }
            renderItem={ renderItem }
          />
          { messageHtml }
        </div>
      </div>
    );
  };

  renderAutocompleteLocation = () => {
    const {
      suggestCountries,
      locationAutocompleteValue,
      validation: { Hotel: { Location: validationCountry } },
      disableLocation,
    } = this.state;

    const disableStyle = disableLocation ? 'disabled' : '';
    const wrapperStyle = { style: styles.wrap_auto_input };
    const inputProps = {
      className: `${styles.input} ${validationCountry ? styles.not_valid_input : ''}`,
      type: 'search',
    };
    const messageHtml = validationCountry && <span className={ styles['error-msg'] }>{ validationCountry }</span>;

    const items = !disableLocation ? suggestCountries : [];

    const renderItem = (item) => {
      if (!item) {
        return null;
      }

      return (
        <div className={ styles['autocomplete-item'] }>
          { `${item.name}` }
        </div>
      );
    };

    return (
      <div className={ styles['col-1-3'] }>
        <div className={ `${styles['auto-input']} ${styles['autocomplete-label']} ${styles[disableStyle]}` }>
          <label>{ TRIPSLABELS.COUNTRY }</label>
          <Autocomplete
            menuStyle={ setSelectMenuStyle(suggestCountries) }
            value={ locationAutocompleteValue }
            items={ items }
            getItemValue={ (item) => item.name }
            onSelect={ (value, item) => this.handleSelectLocationAutocomplete(item) }
            onChange={ ({ target: { value } }) => this.handleChangeLocationAutocomplete(value) }
            inputProps={ inputProps }
            wrapperStyle={ wrapperStyle }
            renderItem={ renderItem }
          />
          { messageHtml }
        </div>
      </div>
    );
  };

  renderHotelName = () => {
    const {
      Hotel: { Name },
      validation: { Hotel: { Name: validationName } },
    } = this.state;

    return (
      <div className={ styles['col-1-2'] }>
        <div className={ `${styles['auto-input']} ${styles.wrap} ${validationName ? styles['no-valid-hotel-name'] : ''}` }>
          <Input
            field={ FIELDS.HOTELNAMEUPPER }
            value={ Name }
            label={ TRIPSLABELS.HOTELNAME }
            onChange={ (e, field, value) => this.handleChangeJsonInput(e, field, value) }
            onBlur={ this.validateInput }
            valid={ validationName }
          />
        </div>
      </div>
    );
  };

  renderContent = () => {
    const {
      hotelProps: { providerNameOptions },
      onCancel,
    } = this.props;

    const {
      Hotel: {
        Address, Phone, Stars, CheckinTime, CheckoutTime,
      },
      ProviderName,
      Contact,
      errors,
      validation: { Hotel: { Address: validationAddress } },
    } = this.state;

    return (
      <div className={ styles.dialog }>
        <div>
          <div className={ styles.row }>
            { this.renderHotelName() }
            <div className={ styles['col-1-4'] }>
              <div className={ styles.select }>
                <div className={ styles.wrap }>
                  <label>{ TRIPSLABELS.HOTEL_STARS }</label>
                  <select
                    value={ Stars }
                    onChange={ (e) => this.handleChangeJsonInput(e, FIELDS.HOTELSTARS, e.target.value) }
                  >
                    <option value={ 0 }>Без звезд</option>
                    <option value={ 1 }>1</option>
                    <option value={ 2 }>2</option>
                    <option value={ 3 }>3</option>
                    <option value={ 4 }>4</option>
                    <option value={ 5 }>5</option>
                  </select>
                </div>
              </div>
            </div>
            <div className={ styles['col-1-4'] }>
              <div className={ styles.select }>
                <div className={ styles.wrap }>
                  <label>{ TRIPSLABELS.PROVIDER_NAME }</label>
                  <select
                    value={ ProviderName }
                    onChange={ (e) => this.handleChangeProviderName(e, FIELDS.PROVIDERNAME, e.target.value) }
                  >
                    { providerNameOptions.map(({ value, label }, key) => (
                      <option
                        key={ key }
                        value={ value }
                        hidden={ value === HOTEL_PROVIDER_VALUE.booking }
                      >
                        { label }
                      </option>)) }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1'] }>
              <Input
                field={ FIELDS.HOTELADDRESS }
                value={ Address }
                label={ TRIPSLABELS.ADDRESS }
                onChange={ (e, field, value) => this.handleChangeJsonInput(e, field, value) }
                onBlur={ this.validateInput }
                valid={ validationAddress }
              />
            </div>
          </div>
          <div className={ styles.row }>
            { this.renderAutocompleteCity() }
            { this.renderAutocompleteLocation() }
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.HOTELPHONE }
                value={ Phone }
                label={ TRIPSLABELS.PHONE }
                onChange={ (e, field, value) => this.handleChangeJsonInput(e, field, value) }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-2'] }>
              <Input
                field={ FIELDS.CONTACT }
                value={ Contact }
                label={ TRIPSLABELS.CONTACT }
                onChange={ (e, field, value) => this.handleChangeJsonInput(e, field, value) }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-attention'] }>
              <Input
                field={ FIELDS.HOTELCHECKINTIME }
                value={ CheckinTime }
                label={ TRIPSLABELS.HOTELCHECKINTIME }
                onChange={ this.handleChangeTimeInput }
                onBlur={ this.handleBlurTimeInput }
                valid={ errors[FIELDS.HOTELCHECKINTIME] }
              />
            </div>
            <div className={ styles['col-attention'] }>
              <Input
                field={ FIELDS.HOTELCHECKOUTTIME }
                value={ CheckoutTime }
                label={ TRIPSLABELS.HOTELCHECKOUTTIME }
                onChange={ this.handleChangeTimeInput }
                onBlur={ this.handleBlurTimeInput }
                valid={ errors[FIELDS.HOTELCHECKOUTTIME] }
              />
            </div>
          </div>
        </div>
        <div className={ styles.dialog_buttons }>
          <Button
            label={ LABELS.SAVE }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            className={ styles.save_button }
            onClick={ this.handleConfirm }
          />
          <Button
            label={ LABELS.CANCEL }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            onClick={ onCancel }
          />
        </div>
      </div>
    );
  };

  render() {
    const { onShowModal } = this.props;

    return (
      <Dialog
        onClick={ onShowModal }
        width='100%'
      >
        { this.renderContent() }
      </Dialog>
    );
  }
}

export { EditMainHotelFields };
