import { selectObj } from '../../utils/air';

import { DATE, DATE_SECOND_TIME } from '../../../constants/time';
import {
  FIELDS,
  VALID_FIELDS_RATE,
} from '../../constants/profile';
import USERS_STATUS from '../../constants/user';
import { BONUSES_TYPE } from '../../constants/bonuses';

import ACTIONS from '../../actions';
import Api from '../../api';

import {
  mappedRates,
  getArchivedEmployees,
  getMappedUserSettings,
  setMappedUserSettings,
  getUnArchivedEmployees,
} from '../../utils/company';
import {
  formatDate, isAfterDate, momentObject, dayOff, dayStart,
} from '../../utils/formatDate';
import lodashReplaces from '../../utils/lodashReplaces';
import {
  checkValidation, checkValidationDate, checkValidationPeriods,
} from '../../utils/checkValidation';
import debounceXhr from '../../utils/debounce';

import TransactionValidator from './validator/transaction';
import InfoValidator from './validator/info';
import RequisitesValidator from './validator/requisites';
import AccountingValidator from './validator/accounting';
import DocumentsValidator from './validator/documents';
import FinanceValidator from './validator/finance';
import AviaContractValidator from './validator/aviaContracts';
import { validationObjHotelContract } from './validator/hotelContract';
import { validationObjHotelContractRate } from './validator/hotelContractRate';
import { mapFilters } from '../../../utils/mapFilters';

let store = null;

const DEBOUNCE_TIME = 400;

const NO_DISCOUNT = 0;

const S7_CORP = 2;

const TRIPSERVICES = new Map([
  ['Air', 'Авиабилеты'],
  ['Hotel', 'Гостиницы'],
  ['Transfer', 'Трансфер'],
  ['Train', 'Ж/Д билеты'],
  ['Custom', 'Кастомная услуга'],
  ['AeroExpress', 'Аэроэкспресс'],
  ['TaxiVoucher', 'Такси'],
  ['VipHall', 'Vip-зал'],
  ['MICE', 'Мероприятие'],
  ['Bus', 'Автобусный билет'],
]);

const TRIPSTATUSES = new Map([
  ['Booked', 'Забронирована'],
  ['Cancelled', 'Отменена'],
  ['AwaitingConfirmation', 'Ожидает подтверждения'],
  ['Completed', 'Завершена'],
  ['AwaitingTicketing', 'Ожидает бронирования'],
]);

const finderIata = (item) => selectObj(item)
  .find(({ cost }) => cost === item.Iata);
const preparedAviaContractIata = (activeAviaContract) => (finderIata(activeAviaContract)
  ? finderIata(activeAviaContract).iata
  : activeAviaContract.Iata);

const LABEL = { ERROR_EXPENSE_REPORT: 'Не удалось изменить дату. Обратитесь в поддержку' };

class CompanyService {
  getAllTripServices = () => TRIPSERVICES;

  getAllTripStatuses = () => TRIPSTATUSES;

  constructor() {
    store = this.getStore('Company');

    this.validator = {
      transaction: new TransactionValidator(),
      info: new InfoValidator(),
      requisites: new RequisitesValidator(),
      accounting: new AccountingValidator(),
      documents: new DocumentsValidator(),
      finance: new FinanceValidator(),
      aviaContract: new AviaContractValidator(),
    };
  }

  get = () => store.getState();

  setId = (id) => store.dispatch({
    type: ACTIONS.COMPANY.SETID,
    id,
  });

  subscribe = (callback) => store.subscribe(callback);

  getVisibleItemsOnPage = (
    totalItems, itemsOnPage, currentPage,
  ) => totalItems.slice((currentPage - 1) * itemsOnPage, currentPage * itemsOnPage);

  loadIntegration = (companyId) => Api.Company.loadIntegration(companyId)
    .then((res) => store.dispatch({
      type: ACTIONS.COMPANY.LOADINTEGRATION,
      payload: res,
    }));

  saveIntegration = (companyId, date) => Api.Company.saveIntegration({ CompanyId: companyId, Date: date })
    .then(() => this.loadIntegration(companyId))
    .catch(() => this.loadIntegration(companyId));

  setErrorExpenseReportsDialog = (show) => store.dispatch({
    type: ACTIONS.COMPANY.ERROR_EXPENSE_REPORT,
    payload: show ? LABEL.ERROR_EXPENSE_REPORT : '',
  });

  changePeriodExpenseReports = async (companyId, startDate, endDate) => {
    const preparedData = {
      BeginDate: formatDate(dayStart(startDate), DATE_SECOND_TIME),
      EndDate: formatDate(dayOff(endDate), DATE_SECOND_TIME),
      CompanyId: companyId,
      Status: 1,
    };

    try {
      await Api.Company.changeUnloadingExpenseReports(preparedData);
    } catch (e) {
      this.setErrorExpenseReportsDialog(true);
    }
  };

  accountingFieldValidation = (field, value) => this.validator.accounting.isValid(field, value);

  transactionCompanyFieldValidation = (field, value) => this.validator.transaction.isValid(field, value);

  infoCompanyFieldValidation = (field, value) => this.validator.info.isValid(field, value);

  requisitesFieldValidation = (field, value) => this.validator.requisites.isValid(field, value);

  aviaContractFieldValidation = (field, value) => this.validator.aviaContract.isValid(field, value);

  documentsValidation = (field, value) => this.validator.documents.isValid(field, value);

  financeValidation = (field, value) => this.validator.finance.isValid(field, value);

  setLoadingCompany = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_COMPANY,
    loading,
  });

  loadProfile = (accountId) => {
    const companyId = this.get().id;
    this.setLoadingCompany();

    Promise.allSettled([
      Api.Company.getDetails(companyId),
      Api.Company.getSchemas(accountId),
      Api.Company.getPeriodUpd(companyId),
      Api.Company.getAviaContract(companyId),
      Api.Company.getUsers(companyId),
      Api.Company.getHotelContract(companyId),
      Api.Company.getApplications1CFF(accountId),
    ])
      .then((data) => {
        this.setLoadingCompany(false);
        store.dispatch({
          type: ACTIONS.COMPANY.LOADPROFILE,
          profile: {
            details: data[0].value,
            schemas: data[1].value,
            periodUpd: data[2].value,
            aviaContracts: data[3].value,
            users: data[4].value,
            hotelContracts: data[5].value,
          },
          applications1CFF: data[6].value.Users,
        });
      });
  };

  getComment = async (companyId, schema) => {
    try {
      const res = await Api.Company.getCommentCompany(companyId, schema);
      store.dispatch({
        type: ACTIONS.COMPANY.GET_COMMENT_COMPANY,
        commentCompany: res,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.GET_COMMENT_COMPANY,
        commentCompany: null,
      });
    }
  };

  getTaxShemasSA = async (companyId) => {
    try {
      const res = await Api.Company.getTaxShemasSA(companyId);

      store.dispatch({
        type: ACTIONS.COMPANY.GET_SHEMAS_TAX_SA,
        taxShemasSA: res.Status,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.GET_SHEMAS_TAX_SA,
        taxShemasSA: 0,
      });
    }
  };

  setTaxShemasSA = async (value) => {
    try {
      const companyId = this.get().id;
      await Api.Company.setTaxShemasSA({ companyId, status: value });

      store.dispatch({
        type: ACTIONS.COMPANY.SET_SHEMAS_TAX_SA,
        payload: value,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.SET_SHEMAS_TAX_SA,
        payload: 0,
      });
    }
  };

  getRulesFormingClosing = async (companyId, schemaName) => {
    try {
      const res = await Api.Company.getRulesFormingClosing(companyId, schemaName);

      store.dispatch({
        type: ACTIONS.COMPANY.LOAD_FIO_SCHEMAS_CLOSING,
        payload: res,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.LOAD_FIO_SCHEMAS_CLOSING,
        payload: {
          companyId: null,
          schemaName: '',
          closingsWithoutFio: false,
          disable: true,
        },
      });
    }
  };

  setRulesFormingClosing = async (companyId, schemaName, closingsWithoutFio) => {
    try {
      await Api.Company.setRulesFormingClosing({
        companyId, schemaName, closingsWithoutFio,
      });

      store.dispatch({
        type: ACTIONS.COMPANY.EDIT_FIO_SCHEMAS_CLOSING,
        value: closingsWithoutFio,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.DISABLED_FIO_SCHEMAS_CLOSING,
        disable: true,
      });
    }
  };

  getFormedReviseFlag = (companyId) => {
    Api.Company.getFormedReviseFlag(companyId).then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.FORMEDREVISEFLAG,
        formedRevise: res.FormedRevise,
      });
    });
  };

  formedReviseFlag = (companyId, value) => {
    Api.Company.formedReviseFlag(companyId, { formedRevise: value });

    store.dispatch({
      type: ACTIONS.COMPANY.FORMEDREVISEFLAG,
      formedRevise: value,
    });
  };

  loadAccounting = (accountId) => {
    const {
      id: companyId, startDate, endDate,
    } = this.get();

    Promise.all([
      Api.Company.getDetails(companyId),
      Api.Company.getSchemas(accountId),
      Api.Company.getPeriodUpd(companyId),
      Api.Company.getBalance(companyId),
      Api.Company.getFunds(companyId),
      Api.Company.getOperations(
        companyId,
        formatDate(startDate, DATE),
        formatDate(endDate, DATE),
      ),
      Api.Company.loadIntegration(companyId),
      Api.Company.getPeriodEdo(companyId),
    ])
      .then((data) => {
        this.getComment(companyId, data[0].Schema);
        this.getTaxShemasSA(companyId);
        this.getRulesFormingClosing(companyId, data[0].Schema);
        this.getFormedReviseFlag(companyId);

        store.dispatch({
          type: ACTIONS.COMPANY.LOADACCOUNTING,
          accounting: {
            details: data[0],
            schemas: data[1],
            periodUpd: data[2],
            balance: data[3],
            funds: data[4],
            operations: data[5],
            integration: data[6],
            packageEdo: data[7],
            loadingAccountins: false,
          },
        });
      });
  };

  loadTrips = (companyId, count) => Api.Company.getOrders(companyId, count)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOADTRIPS,
        trips: res,
      });
    });

  loadLimitAmount = () => Api.Company.getLimitAmount(this.get().id)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOADLIMITAMOUNT,
        limitAmount: res,
      });
    });

  saveDetails = (details) => {
    const contract = details.Contract.Number
      ? {
        ...details.Contract,
        StartDate: formatDate(details.Contract.StartDate, DATE),
      }
      : null;
    const prepareDetails = {
      ...details,
      Contract: contract,
    };

    return Api.Company.saveDetails(this.get().id, prepareDetails)
      .then((res) => {
        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEDETAILS,
          details: res,
        });

        return res;
      });
  };

  getRequisites = (companyId) => Api.Company.getRequisites(companyId).then((payload) => store.dispatch({
    type: ACTIONS.COMPANY.LOAD_REQUISITES,
    payload,
  }));

  saveRequisites = (requisites) => Api.Company.updateRequisites(requisites).then((payload) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATE_REQUISITES,
    payload,
  }));

  updateRequisitesDefault = (companyId) => Api.Company.updateRequisitesDefault(companyId);

  clearUserSettings = () => store.dispatch({ type: ACTIONS.COMPANY.CLEAR_USER_SETTINGS });

  updateApplications1CFF = async (accountId, email, valueFF, newState) => {
    const data = {
      Emails: email,
      FeatureFlag: 'Flags.1CSalary',
      Value: valueFF,
    };

    const result = await Api.Company.updateApplications1CFF(accountId, data);

    store.dispatch({
      type: ACTIONS.COMPANY.UPDATEA1CFF,
      applications1CFF: newState,
    });

    return result;
  };

  loadingAviaContract = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_SAVE_AVIA,
    loading,
  });

  getUserSettings = (accountId, userId) => Api.Company.getUserSettings(accountId, userId)
    .then((payload) => store.dispatch({
      type: ACTIONS.COMPANY.LOADING_USER_SETTINGS,
      payload: getMappedUserSettings(payload),
    }));

  getUserCompanies = (userId) => Api.Employee.getEmployeeCompanies(userId).then((payload) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_USER_COMPANIES,
    payload,
  }));

  updateUserSettings = (data) => Api.Company.updateUserSettings(setMappedUserSettings(data));

  loadCompaniesSettings = (accountId) => Api.Company.getProhibitionVouchers(accountId)
    .then((payload) => store.dispatch({
      type: ACTIONS.COMPANY.LOADING_COMPANY_SETTINGS,
      payload,
    }));

  updateLoadingCompanySettings = (value) => {
    store.dispatch({
      type: ACTIONS.COMPANY.SET_LOADING_COMPANY_SETTINGS,
      payload: value,
    });
  };

  loadCompanySettings = async (companyId) => Api.Company.getCompanySettings(companyId)
    .then((res) => store.dispatch({
      type: ACTIONS.COMPANY.LOAD_COMPANY_SETTINGS,
      payload: res,
    }));

  updateCompanySettings = (companySettings) => Api.Company.updateCompanySettings(companySettings)
    .then(debounceXhr(() => this.loadCompanySettings(companySettings.companyId), DEBOUNCE_TIME));

  loadCompanySettingsWithWaiting = async (companyId) => {
    try {
      this.updateLoadingCompanySettings(true);

      await this.loadCompanySettings(companyId);

      this.updateLoadingCompanySettings(false);
    } catch (e) {
      this.updateLoadingCompanySettings(false);
    }
  };

  getDisabledUserSettings = (userId) => {
    this.loadingUsers();

    return Api.Company.getDisabledUserSettings(userId)
      .then(() => this.updateUsers())
      .then(() => this.loadingUsers(false));
  };

  getAccountType = () => Api.Company.getAccountType()
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOAD_ACCOUNT_TYPES,
        payload: res,
      });
    });

  saveAviaContract = (aviaContract) => {
    this.loadingAviaContract();

    return Api.Company.saveAviaContract(this.get().id, aviaContract)
      .then((res) => {
        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEAVIACONTRACTS,
          showAviaContractDialog: false,
          res,
        });
      })
      .catch(() => store.dispatch({ type: ACTIONS.COMPANY.SAVE_AVIA_CONTRACT_FAIL }))
      .finally(() => this.loadingAviaContract(false));
  };

  removeAviaContract = (aviaContract) => {
    const {
      SchemeType, AccountId, Number, Type, AggregationScheme
    } = aviaContract;

    this.loadingAviaContract();

    const preparedAviaContract = {
      Contract: {
        AccountId,
        Type,
        Number,
        AggregationScheme,
        Iata: preparedAviaContractIata(aviaContract),
      },
      SchemeType,
    };

    return Api.Company.removeAviaContract(this.get().id, preparedAviaContract)
      .then(() => {
        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEAVIACONTRACTSDELETE,
          aviaContract,
        });
      })
      .catch(() => store.dispatch({ type: ACTIONS.COMPANY.DELETE_AVIA_CONTRACT_FAIL }))
      .finally(() => this.loadingAviaContract(false));
  };

  aviaContractFieldValidator = (field, aviaContract) => this.validator.aviaContract.isValid(field, aviaContract);

  addAviaContract = (res) => store.dispatch({
    type: ACTIONS.COMPANY.ADD_AVIA_CONTRACT,
    res,
  });

  showAviaContract = (res) => store.dispatch({
    type: ACTIONS.COMPANY.SHOW_AVIA_CONTRACT,
    res,
  });

  updateAviaContractData = (updatedAviaContractData) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATE_AVIA_CONTRACT_DATA,
    updatedAviaContractData,
  });

  changeFieldAviaContractData = (field, value) => {
    const { aviaContractData } = store.getState();
    const updatedState = lodashReplaces.setWith(aviaContractData, field, value);
    this.updateAviaContractData(updatedState);
  };

  changeFieldsAviaContractData = (fields) => {
    const { aviaContractData } = store.getState();
    const updatedState = fields.reduce((acc, {
      value,
      path,
    }) => lodashReplaces.setWith(acc, path, value), aviaContractData);
    this.updateAviaContractData(updatedState);
  };

  closeAviaContract = () => store.dispatch({ type: ACTIONS.COMPANY.CLOSE_AVIA_CONTRACT });

  closeErrorAviaContractDialog = () => store.dispatch({
    type: ACTIONS.COMPANY.CLOSE_ERROR_AVIA_CONTRACT,
    showErrorSaveAviaContractDialog: false,
    showErrorDeleteAviaContractDialog: false,
  });

  validateAviaContract = (aviaContract) => {
    const isDiscount = this.aviaContractFieldValidator(FIELDS.IsDiscount, aviaContract);
    const iata = this.aviaContractFieldValidator(FIELDS.Iata, aviaContract);
    const number = this.aviaContractFieldValidator(FIELDS.Number, aviaContract);

    const isValid =
      !isDiscount
      && !iata
      && !number;

    if (!isValid) {
      const fields = [
        { path: `${FIELDS.VALIDATION}.${FIELDS.IsDiscount}`, value: isDiscount },
        { path: `${FIELDS.VALIDATION}.${FIELDS.Iata}`, value: iata },
        { path: `${FIELDS.VALIDATION}.${FIELDS.Number}`, value: number },
      ];
      this.changeFieldsAviaContractData(fields);
    }

    return isValid;
  };

  preSaveAviaContract = (accountId) => {
    const { aviaContractData: { activeAviaContract } } = store.getState();
    const { Number: number, IsDiscount, AggregationScheme } = activeAviaContract;
    const contractType = (IsDiscount === NO_DISCOUNT || IsDiscount === S7_CORP)
      ? BONUSES_TYPE.MILES
      : BONUSES_TYPE.DISCOUNT;

    const validAviaContract = {
      ...activeAviaContract,
      IsDiscount: Number(IsDiscount),
    };

    if (this.validateAviaContract(validAviaContract)) {
      const preparedAviaContract = {
        AccountId: accountId,
        Type: contractType,
        Number: number,
        Iata: preparedAviaContractIata(activeAviaContract),
        AggregationScheme,
      };

      return this.saveAviaContract(preparedAviaContract);
    }

    return () => {};
  };

  saveLimitAmount = (amount) => Api.Company.saveLimitAmount(this.get().id, amount);

  saveUserComment = (userId, comment) => Api.Company.saveUserComment(userId, comment)
    .then(() => store.dispatch({
      type: ACTIONS.COMPANY.UPDATEUSERCOMMENT,
      payload: {
        userId,
        comment,
      },
    }));

  clearLimitAmount = () => {
    store.dispatch({ type: ACTIONS.COMPANY.CLEARLIMITAMOUNT });
  };

  updateOverdraft = ({
    overdraft, period, additionalOverdraft, additionalPeriod,
  }) => Api.Company.updateOverdraft(this.get().id, {
    Amount: parseFloat(overdraft),
    Period: parseInt(period, 10),
    AdditionalAmount: parseFloat(additionalOverdraft),
    AdditionalPeriod: parseInt(additionalPeriod, 10),
  })
    .then((res) => {
      const {
        Amount, Period, AdditionalAmount, AdditionalPeriod,
      } = res;
      store.dispatch({
        type: ACTIONS.COMPANY.UPDATEOVERDRAFT,
        overdraft: Amount,
        period: Period,
        additionalOverdraft: AdditionalAmount,
        additionalPeriod: AdditionalPeriod,
      });
    });

  toggleUserNotification = (updatedUsers) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATEUSERS,
    users: updatedUsers,
  });

  updateUsers = () => Api.Company.getUsers(this.get().id)
    .then((res) => this.toggleUserNotification(res));

  searchOperations = (companyId, startDate, endDate) => Api.Company.getOperations(
    companyId,
    formatDate(startDate, DATE),
    formatDate(endDate, DATE),
  )
    .then((res) => store.dispatch({
      type: ACTIONS.COMPANY.UPDATEACCOUNTING,
      payload: res,
    }));

  updateReportingPeriod = (period) => Api.Company.updateReportingPeriod(this.get().id, period)
    .then((res) => {
      if (res.Success) {
        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEPERIODUPD,
          payload: period,
        });
      }

      return res;
    });

  updatePeriodEdo = (periodEdo, edo) => Api.Company.updatePeriodEdo(this.get().id, periodEdo, edo)
    .then(() => {
      store.dispatch({
        type: ACTIONS.COMPANY.UPDATEPERIODEDO,
        payload: {
          periodEdo,
          edo,
        },
      });
    });

  updateReportingSchema = (schema) => Api.Company.updateReportingSchema(this.get().id, schema)
    .then((res) => {
      if (res.Success) {
        store.dispatch({
          type: ACTIONS.COMPANY.UPDATESCHEMAUPD,
          payload: schema,
        });
      }

      return res;
    });

  updateReportingSendingType = (sending) => Api.Company.updateReportingSendingType(this.get().id, sending)
    .then((res) => {
      if (res.Success) {
        store.dispatch({
          type: ACTIONS.COMPANY.UPDATESENDINGTYPE,
          payload: sending,
        });
      }

      return res;
    });

  downloadInvoicePaymentTotal = ({
    companyId, startDateValue = '', endDateValue = '', format = 'pdf', flagStamp = true,
  }) => Api.Company.getInvoicePaymentTotal({
    companyId, startDateValue, endDateValue, format, flagStamp,
  });

  downloadRevise = (companyId, format = 'pdf', startDate = '', endDate = '', isDetailed, companiesIds, type = 0, signature) => Api.Company.getRevise(companyId, format, startDate, endDate, isDetailed, companiesIds, type, signature);

  sendToEDO = (companyId, format = 'pdf', startDate = '', endDate = '', isDetailed, companiesIds, type = 0, signature) => Api.Company.sendToEDO(companyId, format, startDate, endDate, isDetailed, companiesIds, type, signature);

  downloadDiscrepancyFile = (companyId) => Api.Company.getDiscrepancyFile(companyId);

  downloadPrepayInvoice = (
    companyId, format, sum = 0, flagStamp,
  ) => Api.Company.getPrepayInvoice(companyId, format, sum, flagStamp);

  editTransactionDetails = (
    operationId, operationDetailsId, data,
  ) => Api.Company.editTransactionDetails(this.get().id, operationId, operationDetailsId, data);

  downloadInvoice = (
    companyId, invoiceNumber1, invoiceNumber2, format, stamp,
  ) => Api.Company.downloadInvoice(companyId, invoiceNumber1, invoiceNumber2, format, stamp);

  downloadInvoiceWithoutSlash = (
    companyId, invoiceNumber1, invoiceNumber2, format,
  ) => Api.Company.downloadInvoiceWithoutSlash(companyId, invoiceNumber1, invoiceNumber2, format);

  downloadPenalty = (companyId, operationId, format) => Api.Company.downloadPenalty(companyId, operationId, format);

  downloadCertificateByEveryFlight = (data, format = 'pdf') => Api.Company.getCertificateByEveryFlight(data, format);

  downloadCertificateBySingleFlight = (data, format) => Api.Company.getCertificateBySingleFlight(data, format);

  getInvoice = (companyId, invoiceNum) => Api.Company.getInvoice(companyId, invoiceNum);

  saveInvoice = async (companyId, invoiceNum, item) => {
    const { startDate, endDate } = this.get();

    await Api.Company.saveInvoice(companyId, invoiceNum, item);
    this.searchOperations(companyId, startDate, endDate);
  };

  downloadDocument = (
    companyId,
    packageId,
    documentId,
    format = 'pdf',
  ) => Api.Company.downloadDocument(companyId, packageId, documentId, format);

  updateTrips = () => Api.Company.getOrders(this.get().id)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.UPDATETRIPS,
        trips: res,
      });

      return res;
    });

  clearCacheByCompanyId = (companyId) => Api.Company.clearCacheByCompanyId(companyId);

  loadPackageDetails = (packageIds) => Api.Company.loadPackageDetails(packageIds)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOADPACKAGEDETAILS,
        packageDetails: res,
      });

      return res;
    });

  editCommentCompanyHandler = async (companyId, schema, text) => {
    try {
      await Api.Company.editCommentCompany({
        companyId, schema, text,
      });
      store.dispatch({
        type: ACTIONS.COMPANY.SET_COMMENT_COMPANY,
        payload: text,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.SET_COMMENT_COMPANY,
        payload: null,
      });
    }
  };

  loadPackageChanges = (packageIds) => Api.Company.loadPackageChanges(packageIds)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOADPACKAGECHANGES,
        packageChanges: res,
      });

      return res;
    });

  updatePackageDetails = (PackageId, Correction, Comment) => Api.Company.updatePackageDetails({
    PackageId,
    Correction,
    Comment,
  });

  changeDate = (field, value) => store.dispatch({
    type: ACTIONS.COMPANY.CHANGEDATE,
    field,
    value,
  });

  loadPackageDocumentsCorrection = (startDate, endDate) => {
    Api.Company.loadPackageDocumentsCorrection(
      formatDate(startDate, DATE),
      formatDate(endDate, DATE),
    ).then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOADPACKAGEDOCUMENTS,
        documentsCorrection: res,
      });

      return res;
    });
  };

  userNotificationOn = (userId, updatedUsers) => {
    Api.Company.userNotificationOn(userId);
    this.toggleUserNotification(updatedUsers);
  };

  userNotificationOff = (userId, updatedUsers) => {
    Api.Company.userNotificationOff(userId);
    this.toggleUserNotification(updatedUsers);
  };

  saveNotificationBalance = (companyId, balance) => Api.Company.saveNotificationBalance(companyId, balance)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.UPDATELOWBALANCE,
        payload: balance,
      });

      return res;
    });

  get profile() {
    return { clearQueryString: () => history.pushState(null, null, location.pathname) };
  }

  get employees() {
    return {
      loadEmployees: () => Api.Company.getEmployees(this.get().id)
        .then((res) => store.dispatch({
          type: ACTIONS.COMPANY.LOADEMPLOYEES,
          employees: getUnArchivedEmployees(res),
          archivedEmployees: getArchivedEmployees(res),
        })),

      loadEmployee: (employeeId) => Api.Company.getEmployee(employeeId),
    };
  }

  get finance() {
    return {
      loadFinance: (startDate, endDate) => Api.Company.getFinance(
        this.get().id,
        formatDate(startDate, DATE),
        formatDate(endDate, DATE),
      )
        .then((res) => {
          const newPagination = { ...this.get().finance.pagination };
          const transactionMapper = res.reduce((acc, transaction) => {
            return [...acc, { ...transaction, Description: transaction.Description }];
          }, []);

          newPagination.visibleTransactions = this.getVisibleItemsOnPage(
            transactionMapper, newPagination.itemsOnPage, newPagination.currentPage,
          );

          store.dispatch({
            type: ACTIONS.COMPANY.LOADFINANCE,
            transactions: transactionMapper,
            pagination: newPagination,
          });
        }),

      addCredit: (data) => Api.Company.addTransaction(this.get().id, data),

      addCardCredit: (data) => Api.Company.addCardTransaction(this.get().id, data),

      addDebit: (data) => Api.Company.returnTransaction(data),

      addDeductionAvia: (data) => Api.Company.addDeductionAvia(data),

      checkOperationInClosingDocuments: (operationId) => Api.Company.checkOperationInClosingDocuments(operationId),

      flagDeleteTransaction: () => {
        store.dispatch({
          type: ACTIONS.COMPANY.DELETE_TRANSACTION,
          errorFlag: false,
        });
      },

      changePage: (page) => {
        const {
          transactions, pagination, filterTransactions,
        } = this.get().finance;
        const newPagination = { ...pagination };

        newPagination.currentPage = page;

        if (!filterTransactions.length) {
          newPagination.visibleTransactions =
            this.getVisibleItemsOnPage(transactions, newPagination.itemsOnPage, newPagination.currentPage);

          return store.dispatch({
            type: ACTIONS.COMPANY.UPDATEFINANCEPAGINATION,
            pagination: newPagination,
          });
        }

        newPagination.visibleTransactions =
          this.getVisibleItemsOnPage(filterTransactions, newPagination.itemsOnPage, newPagination.currentPage);

        return store.dispatch({
          type: ACTIONS.COMPANY.UPDATEFINANCEPAGINATION,
          pagination: newPagination,
        });
      },

      setItemsOnPage: (amt) => {
        const {
          transactions, pagination, filterTransactions,
        } = this.get().finance;
        const newPagination = { ...pagination };

        newPagination.itemsOnPage = amt;
        newPagination.currentPage = 1;

        if (!filterTransactions.length) {
          newPagination.visibleTransactions =
            this.getVisibleItemsOnPage(transactions, newPagination.itemsOnPage, newPagination.currentPage);

          return store.dispatch({
            type: ACTIONS.COMPANY.UPDATEFINANCEPAGINATION,
            pagination: newPagination,
          });
        }

        newPagination.visibleTransactions =
          this.getVisibleItemsOnPage(filterTransactions, newPagination.itemsOnPage, newPagination.currentPage);

        return store.dispatch({
          type: ACTIONS.COMPANY.UPDATEFINANCEPAGINATION,
          pagination: newPagination,
        });
      },

      setItemsFilter: (filterKey, value) => {
        const {
          transactions, pagination, filterTransactions,
        } = this.get().finance;
        const newPagination = { ...pagination };

        newPagination.currentPage = 1;

        const actionTypeMap = {
          IsPlanFee: ACTIONS.COMPANY.UPDATE_FINANCE_PAGINATION_ONLY_PLAN_FEE,
          IsBankPayment: ACTIONS.COMPANY.UPDATE_FINANCE_PAGINATION_ONLY_BANK_PAYMENT,
          IsCardPaymentOrTransactionFee: ACTIONS.COMPANY.UPDATE_FINANCE_PAGINATION_ONLY_CARD_PAYMENT_OR_TRANSACTION_FEE,
        };

        const actionType = actionTypeMap[filterKey];

        const dataTransaction = transactions.filter(({ Filter }) => Filter[filterKey] === value);

        const updateFilterTransactions = value
          ? [...filterTransactions, ...dataTransaction]
          : filterTransactions.filter(({ Filter }) => Filter[filterKey] === value);

        if (!updateFilterTransactions.length) {
          newPagination.visibleTransactions =
            this.getVisibleItemsOnPage(transactions, newPagination.itemsOnPage, newPagination.currentPage);

          return store.dispatch({
            type: actionType,
            [filterKey]: value,
            filterTransactions: [],
            pagination: newPagination,
          });
        }

        newPagination.visibleTransactions =
          this.getVisibleItemsOnPage(updateFilterTransactions, newPagination.itemsOnPage, newPagination.currentPage);

        return store.dispatch({
          type: actionType,
          [filterKey]: value,
          filterTransactions: updateFilterTransactions,
          pagination: newPagination,
        });
      },

      resetFilters: () => {
        const { transactions, pagination } = this.get().finance;
        const newPagination = { ...pagination };

        newPagination.currentPage = 1;

        newPagination.visibleTransactions =
          this.getVisibleItemsOnPage(transactions, newPagination.itemsOnPage, newPagination.currentPage);

        return store.dispatch({
          type: ACTIONS.COMPANY.RESET_FILTERS,
          filterTransactions: [],
          IsPlanFee: false,
          IsBankPayment: false,
          IsCardPaymentOrTransactionFee: false,
          pagination: newPagination,
        });
      },

      reset: () => store.dispatch({ type: ACTIONS.COMPANY.RESETFINANCE }),

      setComponentState: (component, field, value) => {
        const newComponent = { ...this.get().finance[component] };

        newComponent[field] = value;

        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEFINANCECOMPONENT,
          state: newComponent,
          component,
        });
      },

      resetDebitOrCreditDialogState: () => store.dispatch({ type: ACTIONS.COMPANY.RESETDEBITORCREDITDIALOGSTATE }),

      validation: (component, field, value) => {
        const newValidation = { ...this.get().finance.validation };
        const newComponent = { ...newValidation[component] };

        newComponent[field] = this.financeValidation(field, value);
        newValidation[component] = newComponent;

        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEFINANCEVALIDATION,
          validation: newValidation,
        });

        return !newComponent[field];
      },

      resetValidation: () => store.dispatch({ type: ACTIONS.COMPANY.RESETFINANCEVALIDATION }),
    };
  }

  setLoadingPersonalTransactions = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_PERSONAL_TRANSACTIONS,
    loading,
  });

  setLoadingCompanyUsers = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_COMPANY_USERS,
    loading,
  });

  getCompanyUsers = async (companyId) => {
    try {
      this.setLoadingCompanyUsers(true);

      const users = await Api.Company.getUsers(companyId);

      store.dispatch({
        type: ACTIONS.COMPANY.SET_COMPANY_USERS,
        users,
      });

      return users;
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.setLoadingCompanyUsers(false);
    }
  };

  getPersonalTransactions = async (companyId, userIds, sortOptions, filters) => {
    try {
      this.setLoadingPersonalTransactions(true);
      const { paidByCard: { users: savedUsers } } = this.get();

      const ids = userIds.length > 0
        ? userIds
        : savedUsers.map((user) => user.Id);

      const data = await Api.Company.getPersonalTransactions(sortOptions, ids, mapFilters(filters));

      store.dispatch({
        type: ACTIONS.COMPANY.SET_PERSONAL_TRANSACTIONS,
        transactions: data.transactions,
        totalCount: data?.metadata?.totalCount,
      });
    } catch (e) {
      return Promise.reject(e);
    } finally {
      this.setLoadingPersonalTransactions(false);
    }
  };

  get documents() {
    return {
      loadDocuments: (startDate, endDate) => Api.Company.getTrimmedPackages(
        this.get().id,
        formatDate(startDate, DATE),
        formatDate(endDate, DATE),
      )
        .then((res) => {
          const newPagination = { ...this.get().documents.pagination };

          newPagination.visiblePackages =
              this.getVisibleItemsOnPage(res, newPagination.itemsOnPage, newPagination.currentPage);

          const { documentpackageDetails = [], documentpackageChanges = [] } = res.reduce((acc, item) => ({
            documentpackageDetails: [...acc.documentpackageDetails, item.DocumentPackageDetails],
            documentpackageChanges: [...acc.documentpackageChanges, item.DocumentPackageChanges],
          }), { documentpackageDetails: [], documentpackageChanges: [] });

          store.dispatch({
            type: ACTIONS.COMPANY.LOADDOCUMENTS,
            packages: res,
            documentpackageDetails,
            documentpackageChanges,
            pagination: newPagination,
          });
        })
        .catch(() => store.dispatch({
          type: ACTIONS.COMPANY.LOADDOCUMENTS,
          packages: [],
          documentpackageDetails: [],
          documentpackageChanges: [],
        })),

      getDocument: (documentId) => Api.Company.getDocument(documentId),

      deleteDocument: (
        companyId, packageId, documentId,
      ) => Api.Company.deleteDocument(companyId, packageId, documentId),

      updateDocument: (...opts) => Api.Company.updateDocument(this.get().id, ...opts),

      createPackages: (monthCount) => Api.Company.createPackages(this.get().id, monthCount),

      downloadEDM: (packagesIds) => Api.Company.downloadEDM(this.get().id, packagesIds),

      downloadPackages: (packagesIds) => Api.Company.downloadPackages(this.get().id, packagesIds),

      downloadVouchers: (packagesIds) => Api.Company.downloadVouchers(this.get().id, packagesIds),

      deletePackages: (packagesIds) => (
        Api.Company.deletePackages({ CompanyId: this.get().id, PackageIds: packagesIds })
      ),

      toggleSelectAll: () => {
        const { selectedPackages } = this.get().documents;
        const newSelectedPackages = { ...selectedPackages };

        let selectedAmt = 0;
        let unSelectedAmt = 0;
        Object.values(selectedPackages)
          .forEach((item) => {
            if (item) {
              selectedAmt++;
            } else {
              unSelectedAmt++;
            }
          });

        const value = selectedAmt < unSelectedAmt;
        Object.keys(newSelectedPackages)
          .forEach((key) => {
            newSelectedPackages[key] = value;
          });

        store.dispatch({
          type: ACTIONS.COMPANY.UPDATESELECTEDPACKAGES,
          selectedPackages: newSelectedPackages,
        });
      },

      toggleSelectedPackagesId: (id) => {
        const { selectedPackages } = this.get().documents;
        const newSelectedPackages = { ...selectedPackages };

        newSelectedPackages[id] = !newSelectedPackages[id];

        store.dispatch({
          type: ACTIONS.COMPANY.UPDATESELECTEDPACKAGES,
          selectedPackages: newSelectedPackages,
        });
      },

      changePage: (page) => {
        const { packages, pagination } = this.get().documents;
        const newPagination = { ...pagination };

        newPagination.currentPage = page;
        newPagination.visiblePackages =
          this.getVisibleItemsOnPage(packages, newPagination.itemsOnPage, newPagination.currentPage);

        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEDOCUMENTSPAGINATION,
          pagination: newPagination,
        });
      },

      setItemsOnPage: (amt) => {
        const { packages, pagination } = this.get().documents;
        const newPagination = { ...pagination };

        newPagination.itemsOnPage = amt;
        newPagination.currentPage = 1;
        newPagination.visiblePackages =
          this.getVisibleItemsOnPage(packages, newPagination.itemsOnPage, newPagination.currentPage);

        store.dispatch({
          type: ACTIONS.COMPANY.UPDATEDOCUMENTSPAGINATION,
          pagination: newPagination,
        });
      },

      reset: () => store.dispatch({ type: ACTIONS.COMPANY.RESETDOCUMENTS }),
    };
  }

  loadDebts = async (companyId) => {
    store.dispatch({ type: ACTIONS.COMPANY.DEBTSSTARTLOADING });

    try {
      const response = await Api.Company.getDebts(companyId);
      await this.loadInsurance(companyId);

      store.dispatch({
        type: ACTIONS.COMPANY.DEBTSLOAD,
        payload: response,
      });
    } catch (error) {
      store.dispatch({ type: ACTIONS.COMPANY.DEBTSERROR });
    }
  };

  loadCompanyDebt = async (companyId) => {
    try {
      const response = await Api.Company.getCompanyDebt(companyId);

      store.dispatch({
        type: ACTIONS.COMPANY.GET_COMPANY_DEBT,
        payload: response.HasDebt,
      });
    } catch (error) {
      store.dispatch({
        type: ACTIONS.COMPANY.GET_COMPANY_DEBT,
        payload: null,
      });
    }
  };

  loadExpenseReports = async (companyId) => {
    store.dispatch({ type: ACTIONS.COMPANY.EXPENSE_REPORTS_LOADING });

    try {
      const res = await Api.Company.getExpenseReports(companyId);

      store.dispatch({
        type: ACTIONS.COMPANY.EXPENSE_REPORTS_UPDATE,
        payload: res,
      });
    } catch (error) {
      store.dispatch({ type: ACTIONS.COMPANY.EXPENSE_REPORTS_ERROR });
    }
  };

  resetExpenseReports = () => {
    store.dispatch({ type: ACTIONS.COMPANY.EXPENSE_REPORTS_RESET });
  };

  deleteExpenseReportItem = async ({ id, companyId }) => {
    store.dispatch({ type: ACTIONS.COMPANY.EXPENSE_REPORTS_DELETE_ITEM_LOADING });

    try {
      await Api.Company.deleteExpenseReportItem(id)
        .then(() => this.loadExpenseReports(companyId));
    } catch (error) {
      store.dispatch({ type: ACTIONS.COMPANY.EXPENSE_REPORTS_DELETE_ITEM_LOADING });
    }
  };

  sendComment = async (companyId, comment = '') => Api.Company.sendComment(companyId, comment);

  sendDiadoc = async (documentsPackageIds) => {
    store.dispatch({ type: ACTIONS.COMPANY.SEND_DIADOC, requestDiadoc: { isRequest: true, msg: '' } });
    try {
      await Api.Company.sendDiadoc(documentsPackageIds);
      store.dispatch({
        type: ACTIONS.COMPANY.SEND_DIADOC,
        requestDiadoc: { isRequest: false, msg: 'Диадок отправлен' },
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.SEND_DIADOC,
        requestDiadoc: { isRequest: false, msg: 'Произошла ошибка' },
      });
    }
  };

  resetDebts = () => {
    store.dispatch({ type: ACTIONS.COMPANY.DEBTSRESET });
  };

  addPlanFee = (data) => Api.Company.addPlanFee(data);

  loadingPlanFeeAprove = (value = false) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_RESPONSE_FOR_APPROVE,
    payload: value,
  });

  saveTransaction = (operationId, data) => Api.Company.saveTransaction(this.get().id, operationId, data);

  savePlanFeeTransaction = async (OperationId, Amount) => {
    try {
      this.loadingPlanFeeAprove(true);

      await Api.Company.savePlanFeeTransaction({
        CompanyId: this.get().id, OperationId, Amount,
      });

      this.loadingPlanFeeAprove(false);
    } catch (e) {
      this.loadingPlanFeeAprove(true);
    }
  };

  deleteTransaction = async (operationId) => {
    try {
      this.loadingPlanFeeAprove(true);

      await Api.Company.deleteTransaction(this.get().id, operationId);

      this.loadingPlanFeeAprove(false);
    } catch (err) {
      this.loadingPlanFeeAprove(false);

      store.dispatch({
        type: ACTIONS.COMPANY.DELETE_TRANSACTION,
        errorFlag: true,
      });
    }
  };

  deletePlanFeeTransaction = async (operationId) => {
    try {
      await Api.Company.deletePlanFeeTransaction(this.get().id, operationId);
    } catch (err) {
      store.dispatch({
        type: ACTIONS.COMPANY.DELETE_TRANSACTION,
        errorFlag: true,
      });
    }
  };

  loadInsurance = (companyId) => Api.Company.getInsurance(companyId)
    .then((res) => {
      store.dispatch({
        type: ACTIONS.COMPANY.LOADINSURENCE,
        insuranceData: res,
      });
    })
    .catch(() => store.dispatch({
      type: ACTIONS.COMPANY.LOADINSURENCE,
      insuranceData: { ActiveInsurance: {}, HistoryInsurances: [] },
    }));

  saveInsurance = (data) => {
    const {
      StartDate, EndDate, reasonForCancellationOfInsurance, Id,
    } = data;

    const formatStartDate = StartDate ? formatDate(StartDate, DATE) : null;
    const formatEndDate = EndDate ? formatDate(EndDate, DATE) : null;

    const insuranceData = {
      Id,
      CompanyId: this.get().id,
      StartDate: formatStartDate,
      EndDate: formatEndDate,
      Reason: reasonForCancellationOfInsurance || null,
    };

    return Api.Company.saveInsurance(insuranceData)
      .then(() => store.dispatch({
        type: ACTIONS.COMPANY.SAVEINSURENCE,
        insuranceData: data,
      }))
      .catch(() => {
        store.dispatch({
          type: ACTIONS.COMPANY.SAVEINSURENCE,
          insuranceData: { ActiveInsurance: {}, HistoryInsurances: [] },
        });
      });
  };

  updateHotelContractData = (updatedHotelContractData) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATE_HOTEL_CONTRACT_DATA,
    updatedHotelContractData,
  });

  changeFieldDateHotelContractData = (field, value) => {
    const { hotelContractData } = store.getState();
    const updatedState = lodashReplaces.setWith(hotelContractData, field, value);

    if (field === `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.START}`
      && isAfterDate(value, hotelContractData.activeHotelContract[FIELDS.END])) {
      updatedState[FIELDS.ACTIVE_HOTEL_CONTRACT][FIELDS.END] = momentObject(value);
    }

    this.updateHotelContractData(updatedState);
  };

  changeFieldHotelContractData = (field, value) => {
    const { hotelContractData } = store.getState();
    const updatedState = lodashReplaces.setWith(hotelContractData, field, value);
    this.updateHotelContractData(updatedState);
  };

  changeFieldsHotelContractData = (fields) => {
    const { hotelContractData } = store.getState();
    const updatedState = fields.reduce((acc, {
      value,
      path,
    }) => lodashReplaces.setWith(acc, path, value), hotelContractData);
    this.updateHotelContractData(updatedState);
  };

  changeFieldHotelRate = (field, value, index, vatRate) => {
    const { hotelContractData: { activeHotelContract: { Rates } } } = store.getState();

    const newRateItem = field === FIELDS.VAT
      ? {
        ...Rates[index],
        [field]: value,
        VatRate: vatRate,
      }
      : {
        ...Rates[index],
        [field]: value,
      };
    const newRates = [
      ...Rates.slice(0, index),
      newRateItem,
      ...Rates.slice(index + 1),
    ];
    this.changeFieldHotelContractData(FIELDS.ACTIVE_HOTEL_CONTRACT_FIELDS.RATES, newRates);
  };

  changeRangeDateFieldHotelRate = (field, value, index) => {
    const { hotelContractData: { activeHotelContract: { Rates } } } = store.getState();
    const newRateItem = {
      ...Rates[index],
      [field]: value,
    };

    if (field === FIELDS.START_LIFE_TIME && isAfterDate(value, Rates[index][FIELDS.END_LIFE_TIME])) {
      newRateItem[FIELDS.END_LIFE_TIME] = momentObject(value);
    }

    const newRates = [
      ...Rates.slice(0, index),
      newRateItem,
      ...Rates.slice(index + 1),
    ];
    this.changeFieldHotelContractData(FIELDS.ACTIVE_HOTEL_CONTRACT_FIELDS.RATES, newRates);
  };

  addPerionRate = (index) => store.dispatch({
    type: ACTIONS.COMPANY.ADD_PERIOD_RATE,
    index,
  });

  deletePeriodRate = (indRate, indPeriod) => store.dispatch({
    type: ACTIONS.COMPANY.DELETE_PERIOD_RATE,
    payload: {
      indRate,
      indPeriod,
    },
  });

  changePeriodRate = (field, value, indRate, indPeriod) => {
    store.dispatch({
      type: ACTIONS.COMPANY.CHANGE_PERIOD_RATE,
      payload: {
        field,
        value,
        indRate,
        indPeriod,
      },
    });
  };

  loadingHotelContract = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_SAVE,
    loading,
  });

  addHotelContract = (res) => store.dispatch({
    type: ACTIONS.COMPANY.ADD_HOTEL_CONTRACT,
    res,
  });

  showHotelContract = (res) => store.dispatch({
    type: ACTIONS.COMPANY.SHOW_HOTEL_CONTRACT,
    res,
  });

  getHotelContract = (id) => {
    this.loadingHotelContract();

    Api.Company.getHotelContractId(id)
      .then(this.showHotelContract)
      .catch(() => store.dispatch({ type: ACTIONS.COMPANY.SHOW_HOTEL_CONTRACT_FAIL }))
      .finally(() => this.loadingHotelContract(false));
  };

  getRatesFromAanda = async (hotelId) => {
    try {
      const res = await Api.Company.getRatesFromAanda(hotelId);

      store.dispatch({
        type: ACTIONS.COMPANY.GET_RATES_AANDA,
        rates: res,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.COMPANY.GET_RATES_AANDA,
        rates: [],
      });
    }
  };

  deleteRatesFromAanda = () => store.dispatch({ type: ACTIONS.COMPANY.DELETE_RATES_AANDA });

  closeHotelContract = () => store.dispatch({ type: ACTIONS.COMPANY.CLOSE_HOTEL_CONTRACT });

  saveContractFail = () => {
    store.dispatch({
      type: ACTIONS.COMPANY.SAVE_CONTRACT_FAIL,
      showHotelContractDialog: true,
    });
  };

  save3DContract = (res, value = false) => {
    store.dispatch({
      type: ACTIONS.COMPANY.SAVE_CONTRACT,
      showHotelContractDialog: value,
      res,
    });
  };

  saveContract = (data) => {
    this.loadingHotelContract();

    Api.Company.saveHotelContract(data)
      .then((res) => this.save3DContract(res))
      .catch(() => this.saveContractFail())
      .finally(() => this.loadingHotelContract(false));
  };

  copyContract = (data) => {
    this.loadingHotelContract();

    Api.Company.saveHotelContract(data)
      .then((res) => store.dispatch({
        type: ACTIONS.COMPANY.COPY_CONTRACT,
        showHotelContractDialog: true,
        res,
      }))
      .catch(() => this.saveContractFail())
      .finally(() => this.loadingHotelContract(false));
  };

  updateContract = (data) => {
    this.loadingHotelContract();

    Api.Company.updateHotelContract(data)
      .then((res) => this.save3DContract(res))
      .catch(() => this.saveContractFail())
      .finally(() => this.loadingHotelContract(false));
  };

  closeErrorDialog = () => store.dispatch({
    type: ACTIONS.COMPANY.CLOSE_ERROR,
    showHotelContractDialog: true,
    showErrorDialog: false,
    showErrorHotelContractDialog: false,
  });

  deleteContract = (contractId) => {
    this.loadingHotelContract();

    Api.Company.deleteHotelContract(contractId)
      .then(() => store.dispatch({
        type: ACTIONS.COMPANY.DELETE_CONTRACT,
        showHotelContractDialog: false,
        contractId,
      }))
      .catch(() => this.saveContractFail())
      .finally(() => this.loadingHotelContract(false));
  };

  addRate = () => store.dispatch({ type: ACTIONS.COMPANY.ADD_RATE });

  copyRate = (index) => store.dispatch({
    type: ACTIONS.COMPANY.COPY_RATE,
    index,
  });

  hotelDeleteRate = (index) => {
    store.dispatch({
      type: ACTIONS.COMPANY.DELETE_RATE,
      index,
    });
  };

  hotelContractUpdateRates = (field, value, index) => {
    store.dispatch({
      type: ACTIONS.COMPANY.UPDATE_RATES,
      field,
      value,
      index,
    });
  };

  hotelRemoveContractRates = (field, value, index) => {
    store.dispatch({
      type: ACTIONS.COMPANY.REMOVE_RATES,
      field,
      value,
      index,
    });
  };

  uploadHotelContractFile = async (file) => {
    try {
      const res = await Api.Company.uploadHotelContractFile(file);
      store.dispatch({
        type: ACTIONS.COMPANY.UPLOAD_HOTEL_CONTRACT,
        res,
      });
    } catch (error) {
      store.dispatch({ type: ACTIONS.COMPANY.UPLOAD_HOTEL_CONTRACT_FAIL });
    }
  };

  downloadHotelContract = (FileId) => {
    this.loadingHotelContract();

    return Api.Company.downloadHotelContract(FileId)
      .finally(() => this.loadingHotelContract(false));
  };

  checkHotelBooking = (hotelId) => Api.Company.checkHotelBooking(hotelId)
    .then((res) => store.dispatch({
      type: ACTIONS.COMPANY.CHECK_BOOKING,
      res,
    }))
    .catch(() => store.dispatch({ type: ACTIONS.COMPANY.CHECK_BOOKING_FAIL }));

  validateContract = (hotelContract) => {
    const hotelName = this.hotelContractFieldValidation(FIELDS.HOTEL_NAME, hotelContract.HotelName);
    const start = this.hotelContractDateFieldValidation(FIELDS.START, hotelContract.Start);
    const hotelId = this.hotelContractFieldValidation(FIELDS.HOTEL_ID, hotelContract.BookingId);
    const commission = this.hotelContractFieldValidation(FIELDS.COMMISSION, String(hotelContract.Commission));
    const errorRates = hotelContract.Rates.map((item) => {
      const resRate = {};
      VALID_FIELDS_RATE.forEach((field) => {
        resRate[field] = this.hotelRateFieldValidation(field, item[field]);
      });
      resRate.PeriodDates = checkValidationPeriods(item.Periods);

      return resRate;
    });

    const isValidRates = errorRates
      .every((rate) => Object.keys(rate)
        .filter((key) => key !== FIELDS.PERIODS_RATE)
        .every((key) => !rate[key].length));
    const isValidPeriods = errorRates
      .every((rate) => rate.Periods.every((period) => Object.keys(period)
        .every((key) => !period[key].length)));
    const isValid = !hotelName
      && !hotelId
      && !commission
      && !start
      && isValidRates
      && isValidPeriods;

    if (!isValid) {
      const fields = [
        { path: `${FIELDS.VALIDATION}.${FIELDS.HOTEL_NAME}`, value: hotelName },
        { path: `${FIELDS.VALIDATION}.${FIELDS.HOTEL_ID}`, value: hotelId },
        { path: `${FIELDS.VALIDATION}.${FIELDS.COMMISSION}`, value: commission },
        { path: `${FIELDS.VALIDATION}.${FIELDS.START}`, value: start },
        { path: `${FIELDS.VALIDATION}.${FIELDS.RATES}`, value: errorRates },
      ];
      this.changeFieldsHotelContractData(fields);
    }

    return isValid;
  };

  preSaveContract = (companyId, accountId, IsDraft, isTravelline) => {
    const { hotelContractData: { activeHotelContract } } = store.getState();
    const percent = activeHotelContract.IsPercent || 0;

    if (this.validateContract(activeHotelContract)) {
      const {
        Start, End, HotelId, Commission, Rates,
      } = activeHotelContract;
      const hotelContract = {
        ...activeHotelContract,
        Start: formatDate(Start, DATE),
        End: formatDate(End, DATE),
        HotelId: Number(HotelId),
        Commission: Number(Commission),
        IsPercent: !!percent,
        CompanyId: companyId,
        AccountId: accountId,
        Rates: mappedRates(Rates),
        IsDraft,
        isTravelline,
      };

      if (activeHotelContract.Id === 0) {
        return this.saveContract(hotelContract);
      }

      return this.updateContract(hotelContract);
    }

    return () => {};
  };

  preCopyContract = (companyId, accountId, IsDraft) => {
    const { hotelContractData: { activeHotelContract } } = this.get();
    const percent = activeHotelContract.IsPercent || 0;

    if (this.validateContract(activeHotelContract)) {
      const {
        Start, End, HotelId, Commission, Rates,
      } = activeHotelContract;
      const hotelContract = {
        ...activeHotelContract,
        Start: formatDate(Start, DATE),
        End: formatDate(End, DATE),
        HotelId: Number(HotelId),
        Commission: Number(Commission),
        IsPercent: !!percent,
        CompanyId: companyId,
        AccountId: accountId,
        Rates: mappedRates(Rates, true),
        IsDraft,
      };

      return this.copyContract(hotelContract);
    }

    return () => {};
  };

  hotelContractFieldValidation = (field, value) => checkValidation(validationObjHotelContract, field, value);

  hotelContractDateFieldValidation = (field, value) => checkValidationDate(validationObjHotelContract, field, value);

  hotelRateFieldValidation = (field, value) => checkValidation(validationObjHotelContractRate, field, value);

  selectHotelSuggest = (value) => {
    const {
      Name, Id, FullName,
    } = value;

    const fields = [
      { path: `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.HOTEL_NAME}`, value: Name },
      { path: `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.CITY}`, value: FullName },
      { path: `${FIELDS.HOTEL_NAME_AUTOCOMPLETE.LABEL}`, value: Name },
      { path: `${FIELDS.ACTIVE_HOTEL_CONTRACT}.${FIELDS.HOTEL_ID}`, value: Id },
    ];
    this.changeFieldsHotelContractData(fields);

    return this.checkHotelBooking(Id);
  };

  getRoomType = () => Api.Company.getRoomType()
    .then((res) => store.dispatch({
      type: ACTIONS.COMPANY.GET_ROOM_TYPE,
      res,
    }));

  loadingUsers = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.LOADING_USERS,
    loading,
  });

  waitingSendUserDoc = (loading = true) => store.dispatch({
    type: ACTIONS.COMPANY.WAITING_SEND_USER_DOC,
    loading,
  });

  showUserDialog = (item) => store.dispatch({
    type: ACTIONS.COMPANY.SHOW_EDIT_USER_DIALOG,
    item,
  });

  getDataUserApproveScheme = (item) => {
    Api.Company.getUserApproveScheme(item.Id)
      .then((res) => store.dispatch({
        type: ACTIONS.COMPANY.GET_USER_APPROVE_SCHEME,
        item,
        res,
      }))
      .catch(() => this.saveErrorSetRightUser());
  };

  showUserOfRightsDialog = (item) => store.dispatch({
    type: ACTIONS.COMPANY.SHOW_USER_RIGHTS_DIALOG,
    item,
  });

  showUserNextTapDisableOfRightsDialog = (item) => store.dispatch({
    type: ACTIONS.COMPANY.SHOW_NEXT_STEP_DISABLE_OF_RIGHTS_DIALOG,
    item,
  });

  loadUserRightButton = (loading, status) => store.dispatch({
    type: ACTIONS.COMPANY.USER_RIGHT_BUTTONS,
    loading,
    status,
  });

  editSetRightUserDialog = (res) => store.dispatch({
    type: ACTIONS.COMPANY.USER_SET_RIGHTS,
    res,
  });

  closeSetRightUserDialog = () => store.dispatch({ type: ACTIONS.COMPANY.SHOW_CANCEL_OF_RIGHTS_DIALOG });

  sendUserDoc = (item, id, sendDoc) => {
    this.waitingSendUserDoc();
    const data = {
      userId: id,
      sendDoc,
    };

    return Api.Company.sendUserDocuments(data)
      .then(() => store.dispatch({
        type: ACTIONS.COMPANY.SEND_USER_DOC,
        sendDoc,
        id,
      }))
      .catch(() => store.dispatch({
        type: ACTIONS.COMPANY.SEND_USER_DOC_FAIL,
        item,
      }))
      .finally(() => this.waitingSendUserDoc());
  };

  saveErrorSetRightUser = (item) => {
    this.closeSetRightUserDialog();

    return store.dispatch({
      type: ACTIONS.COMPANY.SAVE_ERROR_SET_RIGHTS,
      item,
    });
  };

  userSetRights = (id, status) => {
    this.loadUserRightButton(true, status);
    const preData = {
      UserId: id,
      Status: status,
    };

    if (status === USERS_STATUS.APPROVED) {
      return Api.Company.editUserRightsAdmin(id)
        .then((res) => this.editSetRightUserDialog(res))
        .catch(() => this.saveErrorSetRightUser())
        .finally(() => this.loadUserRightButton(false, status));
    }

    return Api.Company.editUserRightsDisabled(preData)
      .then((res) => this.editSetRightUserDialog(res))
      .catch(() => this.saveErrorSetRightUser())
      .finally(() => this.loadUserRightButton(false, status));
  };

  showErrorUserDialog = () => store.dispatch({ type: ACTIONS.COMPANY.SAVE_USER_DATA_FAIL });

  changeFieldUserData = (updateUserData) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATE_USER_DATA,
    updateUserData,
  });

  updateDeleteDialog = (value) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATE_DELETE_DIALOG,
    value,
  });

  updateEmployeeDocumentLoading = (value) => store.dispatch({
    type: ACTIONS.COMPANY.UPDATE_EMPLOYEE_DOCUMENT_LOADING,
    payload: value,
  });

  setDeleteLoading = (value) => this.updateDeleteDialog({ loading: value });

  setDeleteDialog = (value) => this.updateDeleteDialog({ show: value });

  setDeleteErr = (value) => this.updateDeleteDialog({ error: value });

  setEmployeeDocumentLoading = (value) => this.updateEmployeeDocumentLoading(value);

  changeUsersFields = (field, value) => {
    const { userActiveData } = store.getState();
    const updatedState = lodashReplaces.setWith(userActiveData, field, value);
    this.changeFieldUserData(updatedState);
  };

  deleteCompany = (cId) => {
    this.setDeleteLoading(true);

    return Api.Company.removeCompany(cId)
      .then(() => {
        this.setDeleteDialog(false);
        this.loadProfile();
      })
      .catch(() => this.setDeleteErr(true))
      .finally(() => this.setDeleteLoading(false));
  };

  saveUserData = () => {
    this.loadingUsers();
    const {
      userActiveData: {
        RegistrationName, PhoneNumber, Id,
      },
    } = store.getState();
    const preUserData = {
      RegistrationName,
      PhoneNumber,
    };

    return Api.Company.saveUser(Id, preUserData)
      .then((res) => store.dispatch({
        type: ACTIONS.COMPANY.SAVE_USER_DATA,
        showEditUserDialog: false,
        res,
      }))
      .catch(() => store.dispatch({ type: ACTIONS.COMPANY.SAVE_USER_DATA_FAIL }))
      .finally(() => this.loadingUsers(false));
  };

  updateDisplayDateInDocuments = (show) => {
    const companyId = this.get().id;

    store.dispatch({ type: ACTIONS.COMPANY.GET_SETTING_DISPLAY_DATA, res: { ShowInDocuments: show } });

    Api.Company.updateDisplayDateInDocuments(companyId, { ShowInDocuments: show });
  };

  getSettingsDisplayDate = () => {
    const companyId = this.get().id;

    Api.Company.getSettingsDisplayDate(companyId)
      .then((res) => store.dispatch({ type: ACTIONS.COMPANY.GET_SETTING_DISPLAY_DATA, res }));
  };

  dispatchCardPaymentFee = (data) => store.dispatch({ type: ACTIONS.COMPANY.GET_CARD_PAYMENT_FEE, payload: data });

  // получение информации берется ли комиссия за оплату картой
  getCardPaymentFee = async () => {
    try {
      const companyId = this.get().id;
      const { DebitingOfCommission } = await Api.Company.getCardPaymentFee(companyId);

      this.dispatchCardPaymentFee(DebitingOfCommission);
    } catch (e) {
      this.dispatchCardPaymentFee(true);
    }
  };

  // обновление информации берется ли комиссия за оплату картой
  updateCardPaymentFee = async (cardPaymentFee) => {
    try {
      const companyId = this.get().id;
      const updateCardPaymentFee = { CompanyId: companyId, DebitingOfCommission: cardPaymentFee };
      const { DebitingOfCommission } = await Api.Company.updateCardPaymentFee(companyId, updateCardPaymentFee);

      this.dispatchCardPaymentFee(DebitingOfCommission);
    } catch (e) {
      this.dispatchCardPaymentFee(!cardPaymentFee);
    }
  };

  getXmlTickets = async (data) => {
    try {
      return await Api.Company.sendXmlTicket(data);
    } catch (e) {
      return e;
    }
  };

  downloadXmlTicket = async (guid) => {
    try {
      return await Api.Company.downloadXmlTicket(guid);
    } catch (e) {
      return e;
    }
  };

  downloadAnalytics = async (data) => {
    await Api.Company.downloadAnalytics(data);
  };

  unloadEmployees = (CompanyId) => Api.Company.unloadEmployees(CompanyId);

  sendXml = async (data) => {
    await Api.Company.sendXmlTickets(data);
  };

  uploadFile = (file) => {
    store.dispatch({
      type: ACTIONS.COMPANY.UPLOAD_FILE,
      file,
    });
  };

  uploadFileFailed = (error) => store.dispatch({
    type: ACTIONS.COMPANY.UPLOAD_FILE_FAIL,
    payload: error,
  });

  sendUploadFile = async (File, CompanyId) => {
    try {
      this.setEmployeeDocumentLoading(true);

      const res = await Api.Company.sendUploadEmployeeFile(File, CompanyId);

      store.dispatch({
        type: ACTIONS.COMPANY.SEND_UPLOAD_EMPLOYEES_FILE,
        payload: res,
      });

      this.setEmployeeDocumentLoading(false);
    } catch (e) {
      this.uploadFileFailed(e.body);

      this.setEmployeeDocumentLoading(false);
    }
  };

  sendBulkUploadFile = async (File, AccountId) => {
    try {
      this.setEmployeeDocumentLoading(true);

      const res = await Api.Company.sendBulkUploadFile(File, AccountId);

      store.dispatch({
        type: ACTIONS.COMPANY.SEND_BULK_UPLOAD_FILE,
        payload: res,
      });

      this.setEmployeeDocumentLoading(false);
    } catch (e) {
      this.uploadFileFailed(e.body);

      this.setEmployeeDocumentLoading(false);
    }
  };

  downloadRightsEmployees = async (File, AccountId, companyId) => {
    try {
      this.setEmployeeDocumentLoading(true);

      const res = await Api.Company.downloadRightsEmployees(File, AccountId, companyId);

      store.dispatch({
        type: ACTIONS.COMPANY.UPLOAD_RIGHTS_EMPLOYEE,
        payload: res,
      });
    } catch (e) {
      this.uploadFileFailed(e.body);
    } finally {
      this.setEmployeeDocumentLoading(false);
    }
  };


  clearUploadedFile = () => store.dispatch({ type: ACTIONS.COMPANY.CLEAR_UPLOADED_FILE });

  downloadEmployeesFile = (fileLink) => Api.Company.downloadXmlTicket(fileLink);

  aproveDownloadEmployees = async (CompanyId, UpdateGuid) => {
    try {
      this.setEmployeeDocumentLoading(true);

      const res = await Api.Company.aproveDownloadEmployees({
        CompanyId, UpdateGuid, Ok: true,
      });

      store.dispatch({
        type: ACTIONS.COMPANY.SECOND_PHASE_LOADING,
        payload: res,
      });

      this.setEmployeeDocumentLoading(false);
    } catch (e) {
      this.uploadFileFailed(e.body);

      this.setEmployeeDocumentLoading(false);
    }
  };

  cancellationOfProcessingDownloadEmployees = async (CompanyId, UpdateGuid) => {
    try {
      this.setEmployeeDocumentLoading(true);

      await Api.Company.aproveDownloadEmployees({
        CompanyId, UpdateGuid, Ok: false,
      });

      this.setEmployeeDocumentLoading(false);
    } catch (e) {
      this.uploadFileFailed(e.body);

      this.setEmployeeDocumentLoading(false);
    }
  };

  downloadPattern = (type) => Api.Company.downloadPattern(type);

  getPatternRights = (type, companyId) => Api.Company.downloadPatternRights(type, companyId);

  getDepartments = (accountId) => Api.Company.getDepartments(accountId);

  getProjects = (accountId) => Api.Company.getProjects(accountId);

  getTags = (accountId) => Api.Company.getTags(accountId);
}

export default CompanyService;
