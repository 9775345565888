import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import COMPONENTS from '../../bi/constants/components';
import styles from './index.module.css';

class AjaxButton extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    theme: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    loading: false,
    theme: 'second',
    disabled: false,
    className: '',
  };

  constructor(props) {
    super(props);

    this.setButtonWrapRef = (element) => {
      this.buttonWrap = element;
    };

    this.state = { width: 0 };
  }

  componentDidMount() {
    const rect = this.buttonWrap.querySelector('button').getBoundingClientRect();

    // eslint-disable-next-line
    this.setState({
      width: rect.width });
  }

  componentDidUpdate() {
    if (this.state.width !== 0) {
      this.buttonWrap.querySelector('button').style.width = `${this.state.width}px`;
    }
  }

  render() {
    const {
      label, loading, onClick, theme, disabled, className,
    } = this.props;

    return (
      <div ref={ this.setButtonWrapRef } className={ styles.wrapper } >
        <Button
          label={ label }
          className={ theme === COMPONENTS.BUTTON.THEME.SECOND ? styles.button : className }
          theme={ theme }
          disabled={ disabled || loading }
          loading={ loading }
          onClick={ disabled ? () => {} : onClick }
        />
      </div>
    );
  }
}


export default AjaxButton;
