import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'sw-ui';

import { formatDate } from '../../../../utils/date';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';

import EditAccountNameDialog from './dialogs/newAccount';

import styles from './styles/index.module.css';

class AccountName extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    accountAutocomplete: PropTypes.func.isRequired,
    addCompanyToGroup: PropTypes.func.isRequired,
    removeAccountFromCompaniesGroup: PropTypes.func.isRequired,
    companiesGroup: PropTypes.object.isRequired,
    getAccountSupports: PropTypes.func.isRequired,
  };

  static defaultProps = {
    name: '',
    createDate: '',
    soldBy: '',
    supportedBy: '',
  };

  constructor(props) {
    super(props);

    this.state = { showEditAccountNameDialog: false };
  }

  toggleEditing = () => {
    this.setState({ showEditAccountNameDialog: !this.state.showEditAccountNameDialog });
  };

  renderShowEditAccountNameDialog = () => {
    const {
      onSave,
      accountAutocomplete,
      addCompanyToGroup,
      removeAccountFromCompaniesGroup,
      getAccountSupports,
      account,
      companiesGroup,
    } = this.props;
    const { showEditAccountNameDialog } = this.state;

    return showEditAccountNameDialog
      ? (
        <EditAccountNameDialog
          onClose={ this.toggleEditing }
          onSave={ onSave }
          account={ account }
          accountAutocomplete={ accountAutocomplete }
          addCompanyToGroup={ addCompanyToGroup }
          removeAccountFromCompaniesGroup={ removeAccountFromCompaniesGroup }
          companiesGroup={ companiesGroup }
          getAccountSupports={ getAccountSupports }
        />
      )
      : null;
  };

  render() {
    const {
      account,
      companiesGroup,
    } = this.props;
    const {
      Name, CreateDate, SoldBy, SupportedBy, SoldByMICE,
    } = account;

    const createDate = CreateDate ? formatDate(CreateDate) : '';

    return (
      <div className={ styles.col }>
        <div className={ styles.row }>
          <span className={ styles.name }>
            Аккаунт -
            <b>{ Name || ACCOUNTTEXT.NOINDICATED }</b>
          </span>
          <span
            className={ styles.name }
          >
            Группа компаний -
            <b>{ companiesGroup.GroupName || ACCOUNTTEXT.NOINDICATED }</b>
          </span>
          <span className={ styles.name }>
            Дата создания -
            <b>{ createDate || ACCOUNTTEXT.NOINDICATEDDATE }</b>
          </span>
        </div>
        <div className={ styles.row }>
          <span className={ styles.name }>
            Продавец -
            <b>{ SoldBy || '' }</b>
          </span>
          <span className={ styles.name }>
            Сопровожденец -
            <b>{ SupportedBy || '' }</b>
          </span>
          <span className={ `${styles.name} ${styles.row}` }>
            Продавец MICE -
            <b>{ SoldByMICE || '' }</b>
            <div className='sw-tooltip-wrapper'>
              <Button
                className={ styles.edit }
                label={ ACCOUNTTEXT.EDIT }
                theme={ COMPONENTS.BUTTON.THEME.FLAT }
                onClick={ this.toggleEditing }
              />
              <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }>
                Редактирование аккаунта
              </Tooltip>
            </div>
          </span>
        </div>
        { this.renderShowEditAccountNameDialog() }
      </div>
    );
  }
}

export default AccountName;
