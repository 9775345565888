import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';
import { onlyNumbers } from '../../../../bi/constants/regExp';

import styles from './styles/aggregation.module.css';

class Aggregation extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    aggregator: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
    onUpdateAggregation: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.object.isRequired,
    withNoSelected: PropTypes.bool,
  };

  static defaultProps = { withNoSelected: false };

  handleUpdateAggregation = ({ value }) => this.props.onUpdateAggregation(value);

  render() {
    const {
      loading, disabled, aggregator, label, items, withNoSelected,
    } = this.props;

    const defaultItems = [{ value: 0, label: 'Не выбран' }];
    let aggregationItems = Object.entries(items).map(([value, labelItem]) => ({
      value: onlyNumbers.test(value) ? Number.parseInt(value, 10) : value,
      label: labelItem,
    }));

    aggregationItems = withNoSelected ? [...aggregationItems] : [...defaultItems, ...aggregationItems];

    return (
      <div className={ styles.aggregation }>
        <Select
          disabled={ disabled || loading }
          label={ label }
          items={ aggregationItems }
          value={ aggregator }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleUpdateAggregation }
        />
      </div>
    );
  }
}

export default Aggregation;
