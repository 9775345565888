import React from 'react';
import PropTypes from 'prop-types';

import {
  getDate,
  getMoment,
  isBeforeDateParameter,
} from '../../../../../../bi/utils/formatDate';

import { FIELDS } from '../../../../../../bi/constants/trips';

import Input from '../../../../../../components/input';
import { DepartureAirportFields } from './components/DepartureAirportFields';
import { ArrivalAirportFields } from './components/ArrivalAirportFields';

import style from '../../../../styles/form.module.scss';

const LABELS = {
  TERMINAL: 'Терминал',
  NUMBER: 'Номер рейса',
  SEGMENT: 'Сегмент №',
};

const AirportsFields = ({
  onChangeInput,
  onSelectSuggestAirport,
  onChangeSuggestAirport,
  renderErrorSegments,
  segments,
  validation,
  validationSegments,
}) => {
  const renderDay = (prop, currentDate) => {
    const todayDate = getMoment();
    const currentDateFormat = getDate(currentDate);

    if (isBeforeDateParameter(currentDate, todayDate)) {
      return (
        <td
          { ...prop }
          className={ `${prop.className} ${style['date-before']}` }
        >
          { currentDateFormat }
        </td>
      );
    }

    return (
      <td { ...prop }>{ currentDateFormat }</td>
    );
  };

  const renderSegments = () => segments.map((
    {
      CheckinDate, CheckinTime, DepartureAirport, CheckoutDate, CheckoutTime, ArrivalAirport, Terminal, Number,
    }, index,
  ) => (
    <div key={ index }>
      <h4>
        { LABELS.SEGMENT }
        { index + 1 }
      </h4>
      <DepartureAirportFields
        index={ index }
        onChangeInput={ onChangeInput }
        validationSegments={ validationSegments }
        onSelectSuggestAirport={ onSelectSuggestAirport }
        onChangeSuggestAirport={ onChangeSuggestAirport }
        renderDay={ renderDay }
        renderErrorSegments={ renderErrorSegments }
        checkinDate={ CheckinDate }
        checkinTime={ CheckinTime }
        departureAirport={ DepartureAirport }
        validation={ validation }
      />
      <ArrivalAirportFields
        index={ index }
        onChangeInput={ onChangeInput }
        validationSegments={ validationSegments }
        onSelectSuggestAirport={ onSelectSuggestAirport }
        onChangeSuggestAirport={ onChangeSuggestAirport }
        renderDay={ renderDay }
        renderErrorSegments={ renderErrorSegments }
        checkoutDate={ CheckoutDate }
        checkoutTime={ CheckoutTime }
        arrivalAirport={ ArrivalAirport }
        validation={ validation }
      />
      <div className={ `${style.row} ${style.panel}` }>
        <div className={ style['col-1-3'] }>
          <Input
            field={ FIELDS.TERMINAL }
            value={ Terminal }
            label={ LABELS.TERMINAL }
            onChange={ (value) => onChangeInput(null, FIELDS.TERMINAL, value.target.value, index) }
            onBlur={ (value) => validationSegments(value, FIELDS.TERMINAL, index) }
            valid={ validation.Segments[index].Terminal }
          />
        </div>
        <div className={ style['col-1-3'] }>
          <Input
            field={ FIELDS.NUMBER }
            value={ Number }
            label={ LABELS.NUMBER }
            onChange={ (value) => onChangeInput(null, FIELDS.NUMBER, value.target.value, index) }
            onBlur={ (value) => validationSegments(value, FIELDS.NUMBER, index) }
            valid={ validation.Segments[index].Number }
          />
        </div>
      </div>
    </div>
  ));

  return (
    <div className={ style.airports }>
      { renderSegments() }
    </div>
  );
};

AirportsFields.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
  segments: PropTypes.array.isRequired,
  onSelectSuggestAirport: PropTypes.func.isRequired,
  onChangeSuggestAirport: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  renderErrorSegments: PropTypes.func.isRequired,
  validationSegments: PropTypes.func.isRequired,
};

export { AirportsFields };
