const TRIP = {
  INIT: 'init',
  UPDATE: 'update',
  LOADTRIPLIST: 'loadTripList',
  STARTEDIT: 'startEdit',
  FULL_EVENTS: 'fullEventsApplication',
  DELETE_EVENT: 'deleteEvent',
  CANCEL_EVENT: 'cancelEvent',
  SORTING_EVENTS: 'sortingEvent',
  EVENTS: 'eventsApplication',
  EVENT: 'eventApplication',
  CANCELEDIT: 'cancelEdit',
  ADDHISTORYITEM: 'addHistoryItem',
  LOADCOMPANIES: 'loadCompanies',
  lOADUSER: 'loadUser',
  REFRESHTRIP: 'refreshTrip',
  LOADPROJECTS: 'loadProjects',
  LOADSUMAIRLINE: 'loadSumAirline',
  LOADSUMAEROEXPRESS: 'loadSumAeroExpress',
  LOADTICKETSTATUS: 'loadTicketStatus',
  LOADSUMAIRLINEFAIL: 'loadSumAirlineFail',
  TICKETSTATUSEFAIL: 'ticketStatusFail',
  SETSUMAIRLINE: 'setSumAirline',
  SETSUMAIRLINEFAIL: 'setSumAirlineFail',
  SETAEROEXPRESSFAIL: 'setAeroExpressFail',
  LOADCHANGECOMMISSIONAIR: 'loadChangeCommissionAir',
  LOADCHANGECOMMISSIONTRAIN: 'loadChangeCommissionTrain',
  LOAD_CHANGE_COMMISSION_BUS: 'loadChangeCommissionBus',
  LOADINTEGRATION: 'loadIntegration',
  LOADINTEGRATIONVAERSIONS: 'loadIntegrationVersions',
  LOADMANDATORYROJECT: 'loadMandatoryProject',
  UPDATEVERSIONBUTTONS: 'updateVersionButtons',
  UPDATETICKETSTATUS: 'updateTicketStatus',
  UPDATESTATETICKET: 'updateStateTicket',
  UPDATELOADING: 'updateLoading',
  UPDATE_DIALOG_LOADING: 'updateDialogLoading',
  SET_MERGING_LOADING: 'setMergingLoading',
  SET_TRIP_TO_MERGE: 'setTripToMerge',
  CLEARSTORE: 'clearStore',
  AUTOCOMPLETE_EMPLOYEES: 'autocompleteEmployees',
  LOADING_AUTOCOMPLETE_EMPLOYEES: 'LOADING_AUTOCOMPLETE_EMPLOYEES',
  LOADANALYTICS: 'loadAnalytics',
  SETANALYTICS: 'setAnalytics',
  UPDATE_TRIP_COMMENTS: 'updateTripComments',
  SAVE_OPERATION_AIRLINE: 'saveOperationAirline',
  ADD_VOUCHERS: 'addVouchers',

  ADD_CUSTOM_VOUCHER: 'addCustomVoucher',
  UPLOAD_CUSTOM_VOUCHER: 'uploadCustomVoucher',
  FILED_CUSTOM_VOUCHER: 'failedCustomVoucher',
  RESET_CUSTOM_VOUCHER: 'resetCustomVoucher',

  ADD_EVENT_VOUCHER: 'addEventVoucher',
  UPLOAD_EVENT_VOUCHER: 'uploadEventVoucher',
  FILED_EVENT_VOUCHER: 'failedEventVoucher',
  RESET_EVENT_VOUCHER: 'resetEventVoucher',

  ADD_BUS_VOUCHER: 'addBusVoucher',
  ADD_BUS_VOUCHERS: 'addBusVouchers',
  UPLOAD_BUS_VOUCHER: 'uploadBusVoucher',
  FILED_BUS_VOUCHER: 'failedBusVoucher',
  RESET_BUS_VOUCHER: 'resetBusVoucher',

  SET_WAIT_PRELOADER: 'setWaitPreloader',
  SET_LEGAL_DIALOG: 'setLegalDialog',
  SET_LEGAL_LIST: 'setLegalList',
  SET_ERROR_LEGAL_ENTITY: 'setErrorLegalEntity',
  GET_LEGAL_STATUS: 'getLegalStatus',
  SET_LEGAL_VALUE: 'setLegalValue',
  SET_REFUND_DIALOG: 'SET_REFUND_DIALOG',
  SET_GIFT_VOUCHER_DIALOG: 'SET_GIFT_VOUCHER_DIALOG',
  SET_GIFT_VOUCHER_COMPANY_ID: 'SET_GIFT_VOUCHER_COMPANY_ID',
  SET_GIFT_ERROR_GET_EMPLOYEES_LIST: 'SET_GIFT_ERROR_GET_EMPLOYEES_LIST',
  SET_GIFT_VOUCHER_EMPLOYEE: 'SET_GIFT_VOUCHER_EMPLOYEE',
  SET_GIFT_VOUCHER_VALUE: 'SET_GIFT_VOUCHER_VALUE',
  SET_VALIDATION_GIFT_VOUCHER: 'SET_VALIDATION_GIFT_VOUCHER',
  SET_GIFT_VOUCHER_RESULT: 'SET_GIFT_VOUCHER_RESULT',
  SET_APROOVE_DIALOG: 'SET_APROOVE_DIALOG',
  SET_RESULT_DIALOG: 'SET_RESULT_DIALOG',
  SET_LOADING_DIALOG: 'SET_LOADING_DIALOG',
  CHANGE_CANCEL_VOUCHER_INPUT: 'CHANGE_CANCEL_VOUCHER_INPUT',
  SAVE_TRIP_VERSIONS_COUNT: 'SAVE_TRIP_VERSIONS_COUNT',
  DEFAULT_CUSTOM_FILE: 'DEFAULT_CUSTOM_FILE',
  DEFAULT_EVENT_FILE: 'DEFAULT_EVENT_FILE',
  DEFAULT_BUS_FILE: 'DEFAULT_BUS_FILE',
  GET_TICKET_FIELD_ID: 'GET_TICKET_FIELD_ID',
  SET_AERO_LOADING: 'SET_AERO_LOADING',
  SET_ERROR_REQ_FILE: 'SET_ERROR_REQ_FILE',
  RESET_ERROR_AERO: 'RESET_ERROR_AERO',

  ITEMS_INSURANCE: 'itemsInsurance',
  SET_RATES: 'setRates',
  SET_PROVIDER_INSURANCE: 'setProviderInsurance',
  SELECT_ITEM_INSURANCE: 'selectItemInsurance',
  SELECT_RATE_INSURANCE: 'selectRateInsurance',
  CHANGE_PRICE_INSURANCE: 'changePriceInsurance',
  SELECT_ALL_ITEMS_INSURANCE: 'selectAllItemsInsurance',
  DELETE_ALL_SELECTED_ITEMS_INSURANCE: 'deleteAllItemsInsurance',
  SET_VALIDATION_INSURANCE: 'setValidationInsurance',
  SELECT_COMPANY_ID: 'selectCompanyId',
  SET_EDITED_INSURANCE: 'setEditedInsurance',
  SET_DESCRIPTION_INSURANCE: 'setDescriptionInsurance',
  RESET_INSURANCE: 'resetInsurance',
  CHANGE_DATA_SERVICE_INSURANCE: 'changeDataServiceInsurance',
  CHANGE_EDITED_SERVICE_INSURANCE: 'changeEditedServiceInsurance',

  GET_ESTIMATE: 'getEstimate',
  SET_ESTIMATE: 'setEstimate',
  ERROR_ESTIMATE: 'errorEstimate',

  CHANGE_FIELD_GROUP_BOOKING: 'changeFieldGroupBooking',
  SET_VALIDATION_GROUP_HOTEL: 'setValidationGroupHotel',
  RESET_HOTEL_PROVIDER_DATA: 'resetHotelProviderData',
  RESET_MAIN_HOTEL_FIELDS: 'resetMainHotelFields',
  CHANGE_JSON_INPUT_GROUP_HOTEL: 'changeJsonInputGroupHotel',
  SET_GROUP_HOTEL_NAME: 'setGroupHotelName',
  SET_GROUP_HOTEL_NAME_SUGGEST: 'setGroupHotelNameSuggest',
  SET_GROUP_BOOKING_HOTEL_DETAILS: 'setGroupBookingHotelDetails',
  SET_CONTRACT_CONNECT_COMPARE_GROUP_BOOKING: 'setContractConnectCompareGroupBooking',
  SET_RATES_GROUP_BOOKING: 'setRatesGroupBooking',
};

export default TRIP;
