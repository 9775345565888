import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Loading, MultiSelect,
} from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import DecisionButtons from '../../../../../components/DecisionButtons';
import { TYPE_ICON } from '../../../../../components/AddButton/consts';
import { AddButton } from '../../../../../components/AddButton';

import { setSelectMenuStyle } from '../../../../../bi/utils/account';

import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../styles/dialog.module.css';

const WIDTH = '600px';

const FIELDS = {
  HIDE_HOTELS: 'Hotels',
  EXCEPT_HOTELS: 'ExceptHotels',
  HOTEL_NAME: 'hotelName',
  LOWER_EXCEPT_HOTEL: 'exceptHotel',
};

const LABELS = {
  LABEL: 'Ограничения гостиниц',
  TYPES: 'Тип отеля',
  TYPES_PLACEHOLDER: 'Название типа',
  SELECT_HOTEL: 'Название отеля, который нужно скрыть',
  DELETE: 'Удалить из списка',
  LIST_HOTELS: 'Скрытые отели',
  EXCEPT_HOTELS: 'Название отеля, который нужно показать',
  EXCEPT_LIST_HOTELS: 'Исключения',
};

class EditPlacementIssuance extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    accountService: PropTypes.object.isRequired,
    isHotelChange: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const {
      loadingHotelAggregation, hotelAggregation, listHideHotelTypes,
    } = props.accountService.get();

    this.state = {
      loading: loadingHotelAggregation,
      hotelAggregation,
      listHideHotelTypes,
      hotelName: {
        label: '',
        selected: {},
        suggests: [],
      },
      exceptHotel: {
        label: '',
        selected: {},
        suggests: [],
      },
    };
  }

  componentDidMount() {
    const { accountId, accountService } = this.props;

    this.unsubscribe = accountService.subscribe(this.update);
    accountService.loadSettingAggregation(accountId);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({
    hotelAggregation,
    listHideHotelTypes,
    loadingHotelAggregation,
  }) => this.setState({
    hotelAggregation,
    listHideHotelTypes,
    loading: loadingHotelAggregation,
  });

  handelChangeTypes = (value) => {
    this.props.accountService.updateSettingAggregation(value);
  };

  handleChangeSuggest = (field, value) => {
    this.setState({
      [field]: {
        ...this.state[field],
        label: value,
      },
    }, () => {
      if (this.state[field].label) {
        this.props.accountService.autocompleteHotel(value).then((res) => {
          const resFilter = res ? res.filter((item) => !item.IsRegion) : [];
          this.setState({
            ...this.state,
            [field]: {
              ...this.state[field],
              suggests: resFilter,
            },
          });
        });
      }
    });
  };

  handleSelectSuggest = (field, value, hotel) => {
    this.props.accountService.addHotels(field, { Id: hotel.Id, HotelName: hotel.Name });
  };

  handleDeleteHotel = (field, id) => this.props.accountService.deleteHotels(field, id);

  handleSave = () => this.props.accountService.updateHotelAggregation();

  renderLoading = () => (
    <Dialog onClick={ this.props.onClose } width={ WIDTH }>
      <div className={ styles.loading }>
        <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
      </div>
    </Dialog>
  );

  renderHotel = (id, hotelName, field) => (
    <div key={ id } className={ styles.hotel }>
      <div className={ styles.hotel_name }>
        { hotelName }
      </div>
      <AddButton
        icon={ TYPE_ICON.CLEAR }
        text={ LABELS.DELETE }
        onClick={ () => this.handleDeleteHotel(field, id) }
      />
    </div>
  );

  renderHotels = (title, hotels, field) => {
    if (!hotels.length) {
      return null;
    }

    return (
      <div className={ styles.hotels }>
        <h4>{ title }</h4>
        { hotels.map(({ Id, HotelName }) => this.renderHotel(Id, HotelName, field)) }
      </div>
    );
  };

  renderItem = ({
    Id, Name, FullName,
  }) => (
    <div key={ Id } className={ styles.autocomplete_item }>
      <div className={ styles.autocomplete_item_text }>{ Name }</div>
      <div className={ styles.autocomplete_item_label }>{ FullName }</div>
    </div>
  );

  render() {
    const { onClose, isHotelChange } = this.props;
    const {
      loading,
      hotelAggregation: {
        HotelTypes,
        Hotels,
        ExceptHotels,
      },
      listHideHotelTypes,
      hotelName,
      exceptHotel,
    } = this.state;

    if (loading) {
      return this.renderLoading();
    }

    const items = listHideHotelTypes.length
      ? listHideHotelTypes.map((item) => ({ label: item, value: item }))
      : [];

    const hideHotelsHtml = this.renderHotels(LABELS.LIST_HOTELS, Hotels, FIELDS.HIDE_HOTELS);
    const exceptHotelsHtml = this.renderHotels(LABELS.EXCEPT_LIST_HOTELS, ExceptHotels, FIELDS.EXCEPT_HOTELS);

    return (
      <Dialog onClick={ onClose } width={ WIDTH } >
        <div className={ styles.dialog }>
          <h3>{ LABELS.LABEL }</h3>
          <div className={ styles.select }>
            <MultiSelect
              label={ LABELS.TYPES }
              items={ items }
              values={ HotelTypes }
              search
              searchPlaceholder={ LABELS.TYPES_PLACEHOLDER }
              onChange={ (value) => this.handelChangeTypes(value) }
            />
          </div>
          <div className={ styles.select }>
            <label>{ LABELS.SELECT_HOTEL }</label>
            <div className={ `${styles['auto-input']} ${styles.wrap}` }>
              <Autocomplete
                menuStyle={ setSelectMenuStyle(hotelName.suggests) }
                value={ hotelName.label }
                items={ hotelName.suggests }
                getItemValue={ (item) => item.Name }
                onSelect={ (value, hotel) => this.handleSelectSuggest(FIELDS.HIDE_HOTELS, value, hotel) }
                onChange={ (e, value) => this.handleChangeSuggest(FIELDS.HOTEL_NAME, value) }
                renderItem={ this.renderItem }
              />
            </div>
          </div>
          <div className={ styles.select }>
            <label>{ LABELS.EXCEPT_HOTELS }</label>
            <div className={ `${styles['auto-input']} ${styles.wrap}` }>
              <Autocomplete
                menuStyle={ setSelectMenuStyle(exceptHotel.suggests) }
                value={ exceptHotel.label }
                items={ exceptHotel.suggests }
                getItemValue={ (item) => item.Name }
                onSelect={ (value, hotel) => this.handleSelectSuggest(FIELDS.EXCEPT_HOTELS, value, hotel) }
                onChange={ (e, value) => this.handleChangeSuggest(FIELDS.LOWER_EXCEPT_HOTEL, value) }
                renderItem={ this.renderItem }
              />
            </div>
          </div>
          { hideHotelsHtml }
          { exceptHotelsHtml }
          <DecisionButtons
            loadingIncluded
            onCancel={ onClose }
            onSave={ this.handleSave }
            disabled={ !isHotelChange }
          />
        </div>
      </Dialog>
    );
  }
}

export default EditPlacementIssuance;
