import {
  Route, BrowserRouter, Routes,
} from 'react-router-dom';

import { BaseContainer } from './components/BaseContainer';

import { ROUTE_LIST } from './pages';

export const AppV2 = () => (
  <BrowserRouter>
    <BaseContainer>
      <Routes>
        { ...ROUTE_LIST.map((r) => <Route { ...r } key={ r.path } />) }
      </Routes>
    </BaseContainer>
  </BrowserRouter>
);
