import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import styles from './styles/alert.module.css';

const LABELS = {
  MAIN: 'Убедитесь, что услуга удовлетворяет примененным travel политикам',
  APPROVE: ', либо согласована с администратором аккаунта.',
};

const AlertTP = ({ onClose, isItemCart }) => {
  const label = isItemCart
    ? LABELS.MAIN
    : `${LABELS.MAIN}${LABELS.APPROVE}`;

  return (
    <Dialog onClick={ onClose } width='360px'>
      <div className={ styles.dialog }>
        <b>{ label }</b>
        <div className={ styles.actions }>
          <Button
            label='Ok'
            theme='flat'
            onClick={ onClose }
          />
        </div>
      </div>
    </Dialog>
  );
};

AlertTP.propTypes = {
  onClose: PropTypes.func.isRequired,
  isItemCart: PropTypes.bool,
};

AlertTP.defaultProps = { isItemCart: false };

export default AlertTP;
