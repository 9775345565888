import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import { FULLPATTERN, MONTHPATTERN } from '../../../../constants/time';

import { getYear } from '../../../../bi/utils/formatDate';
import {
  citiesForAir, getNumberVoucher, infoEmployee,
} from '../../../../bi/utils/trip';
import { getCurrentYear, getDateObjFromStr } from '../../../../utils/date';

import styles from '../../styles/dialog.module.css';

const LABELS = {
  HEADER: 'Аннуляция заказа',
  INFO: (cities, dates, num, employee) => (
    `Авиабилет ${cities}, ${dates}, #${num}, ${employee} будет аннулирован бесплатно.`
  ),
  // eslint-disable-next-line @stylistic/max-len
  REFUND: (price) => `Полная стоимость в размере ${price} ₽ вместе со всеми сборами сразу же вернется на счет в личном кабинете Smartway.`,
  NOTIFICATION: 'Внимание операция ручная, необходимо дополнительно войдировать авиабилет в ГДС!',
  CONFIRM: 'Вы подтверждаете аннуляцию билета?',
};

const DIALOG_WIDTH = '500px';

class VoidingDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    tripId: PropTypes.number.isRequired,
    reloadPage: PropTypes.func.isRequired,
    additionalMessage: PropTypes.string.isRequired,
  };

  handleConfirm = () => {
    const {
      tripService, accountId, tripId, item: { TripItemId }, reloadPage,
    } = this.props;

    return tripService.sendVoidingInfo(accountId, tripId, TripItemId)
      .then(() => setTimeout(() => reloadPage()));
  };

  infoDate = ({ CheckinDate, CheckoutDate }) => {
    const currentYear = getCurrentYear();
    const mStart = getDateObjFromStr(CheckinDate);
    const mEnd = getDateObjFromStr(CheckoutDate);

    let startDateFormat = MONTHPATTERN;
    let endDateFormat = MONTHPATTERN;

    const mStartYear = getYear(mStart);
    const mEndYear = getYear(mEnd);

    if (mStartYear !== mEndYear && mStartYear === currentYear) {
      endDateFormat = FULLPATTERN;
    }

    if (mStartYear !== mEndYear && mStartYear !== currentYear) {
      startDateFormat = FULLPATTERN;
      endDateFormat = FULLPATTERN;
    }

    const rStart = mStart.format(startDateFormat);
    const rEnd = mEnd.format(endDateFormat);

    const isDatesSame = mStart.clone().startOf('day')
      .isSame(mEnd.clone().startOf('day'));

    if (isDatesSame) {
      return rEnd;
    }

    return `${rEnd} - ${rStart}`;
  };

  renderAdditionalMessage = () => {
    const { additionalMessage } = this.props;

    if (!additionalMessage) return null;

    return (
      <div className={ `${styles.row} ${styles.info} ${styles.notification}` }>
        { additionalMessage }
      </div>
    );
  };

  render() {
    const {
      onClose, item: {
        JsonData, CheckinDate, CheckoutData, Price,
      },
    } = this.props;

    const data = JSON.parse(JsonData);
    const {
      Routes, TicketsExtended, AdditionalServices,
    } = data;
    const cities = citiesForAir(Routes);
    const dates = this.infoDate({ CheckinDate, CheckoutData });
    const num = getNumberVoucher(TicketsExtended);
    const employee = infoEmployee(data);
    const newAdditionalServices = AdditionalServices.map((additionalService) => {
      const newAdditionalService = additionalService;
      if (newAdditionalService.Service.Comission === undefined) {
        newAdditionalService.Service.Comission = 0;
      }

      return newAdditionalService;
    });
    const additionalServicesPrice = newAdditionalServices
      .reduce((acc, { Service: { Price: ServicePrice, Comission } }) => acc + ServicePrice + Comission, 0);

    const totalPrice = Price - additionalServicesPrice;

    return (
      <Dialog onClick={ onClose } width={ DIALOG_WIDTH }>
        <div className={ styles.dialog }>
          <div className={ `${styles.row} ${styles.wrap}` }>
            { LABELS.HEADER }
          </div>
          <div className={ `${styles.row} ${styles.info}` }>
            { LABELS.INFO(cities, dates, num, employee) }
          </div>
          <div className={ `${styles.row} ${styles.info}` }>
            { LABELS.REFUND(totalPrice) }
          </div>
          <div className={ `${styles.row} ${styles.info} ${styles.notification}` }>
            { LABELS.NOTIFICATION }
          </div>
          { this.renderAdditionalMessage() }
          <div className={ `${styles.row} ${styles.wrap} ${styles.confirm}` }>
            { LABELS.CONFIRM }
          </div>
          <div className={ styles.actions }>
            <DecisionButtons
              onSave={ this.handleConfirm }
              onCancel={ onClose }
              labelSave={ DECISIONBUTTONS.LABELS.CONFIRM_CANCELLATION }
              labelCancel={ DECISIONBUTTONS.LABELS.CANCEL_DIALOG }
              loadingIncluded
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { VoidingDialog };
