import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import FLYCLASS from '../../../../bi/constants/airline';
import { FIELDS } from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';

import {
  clearVatDetails,
  deletePart,
  preparedPriceDetails,
  prepareVatDetails,
  setRoutesDurationAndTime,
  updateVatDetails,
} from '../../../../bi/utils/air';

import {
  applyAnalytics,
  removeAnalytics,
  validateAnalytics,
  validateSingleAnalytics,
} from '../../../../bi/utils/customAnalytics';

import { FULLTIME } from '../../../../constants/time';

import {
  isMoment,
  momentHours,
  momentMinutes,
  momentSetHoursAndMinutes,
  dateParseZone,
} from '../../../../bi/utils/formatDate';
import { findDepartments } from '../../../../bi/utils/trip';
import { isMandatory } from '../../../../bi/utils/account';
import scrollToErrMessage from '../../../../utils/scrollToErrMessage';

import { FlatButton } from '../../../../components/button';
import AlertTP from '../../../../components/tpDetails/alert';

import styles from '../../styles/form.module.scss';
import AnalyticsRows from '../analyticsRows';
import SelectCompany from '../selectCompany';
import SelectDepartment from '../selectDepartment';

import SelectProject from '../selectProject';
import AirTickets from './tickets/AirTickets';

moment.locale('ru');

const GET_INFO_ERROR = 'ERROR';

class NewAirlineForm extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    onConfirm: PropTypes.func,
    airService: PropTypes.object.isRequired,
    airline: PropTypes.object.isRequired,
    specialBillAccount: PropTypes.object.isRequired,
    travelPolicyService: PropTypes.object.isRequired,
    projects: PropTypes.array,
    mandatoryProject: PropTypes.array,
    tripService: PropTypes.object.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    addTrip: PropTypes.func.isRequired,
    guidService: PropTypes.object.isRequired,
    changeRate: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onConfirm: () => {},
    employees: [],
    projects: [],
    mandatoryProject: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
  };

  constructor(props) {
    super(props);

    this.setStateFromProps(props);
    this.waitingResponse = false;
    this.providerNameOptions = props.airService.getAirProviderNamesForSelect();
  }


  componentDidMount() {
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);
  }


  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.airline.OrderTripItemId !== this.state.OrderTripItemId) {
      this.setStateFromProps({ airline: newProps.airline, airService: this.props.airService });
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ employeesList, operationAirline }) => this.setState({ employeesList, operationAirline });

  setStateFromProps = ({
    airline, airService, tripService,
  }) => {
    const {
      isCopy,
      isEdit,
      JsonData: airlineJsonData,
      Employees,
      OrderTripItemId,
      ServiceType,
    } = airline;
    const { operationAirline } = tripService.get();

    if (isEdit) {
      this.state = airline;

      const newRoutes = setRoutesDurationAndTime(airline.JsonData[0].Routes);

      this.state.JsonData[0].Routes = newRoutes;
    } else if (isCopy) {
      const voucher = airlineJsonData;
      const JsonData = airService.createAirTickets(voucher, airline);

      const newRoutes = setRoutesDurationAndTime(JsonData[0].Routes);

      JsonData[0].Routes = newRoutes;

      this.state = {
        OrderTripItemId,
        ServiceType,
        Description: '',
        Status: 0,
        Employees,
        EmployeeId: 0,
        CompanyId: 0,
        Departments: [],
        Penalties: {},
        ReturnFunds: {},
        Surcharges: {},
        ProjectId: 0,
        DepartmentId: 0,
        JsonData,
        tpDetails: [],
        showAlertTP: false,
        employeesList: [],
        operationAirline,
        UserAnalytics: [],
        mandatoryProject: [],

        validation: {
          CompanyId: '',
          ProjectId: '',
          DepartmentId: '',
          list: airService.createNewValidatorByVoucher(JsonData),
        },
      };
    } else {
      const JsonData = [airService.createNewTicket()];
      const defaultClass = JsonData[0].Class;

      JsonData[0].Routes = JsonData[0].Routes.map((route) => {
        const defaultRoute = route;

        defaultRoute.Segments = route.Segments.map((segment) => ({
          ...segment,
          Class: FLYCLASS[defaultClass],
        }));

        return defaultRoute;
      });

      this.state = {
        OrderTripItemId,
        ServiceType,
        Description: '',
        CompanyId: 0,
        Departments: [],
        UserAnalytics: [],
        DepartmentId: 0,
        Status: 0,
        Employees,
        Penalties: {},
        ReturnFunds: {},
        Surcharges: {},
        ProjectId: 0,
        JsonData,
        tpDetails: [],
        employeesList: [],
        operationAirline,
        mandatoryProject: [],

        validation: {
          CompanyId: '',
          DepartmentId: '',
          list: [airService.createNewValidator()],
        },
        getInfoError: '',
      };
    }
  };

  normalizeVoucher = (voucher) => {
    voucher.forEach((ticket) => {
      ticket.Passports = {
        EmployeeId: ticket.Travellers.Id,
        PassportId: typeof ticket.Passport === 'object' ? ticket.Passport.PassportId : ticket.Passport,
      };

      ticket.Travellers = ticket.Travellers.Id;

      ticket.Routes.forEach((route) => {
        const minutes = (isMoment(route.Duration))
          ? (route.Duration.hours() * 60) + route.Duration.minutes()
          : route.Duration;

        route.Duration = minutes;
        route.Segments.forEach((segment) => {
          const departureDate = momentSetHoursAndMinutes(
            segment.DepartureDate, momentHours(segment.DepartureTime), momentMinutes(segment.DepartureTime),
          );
          const arrivalDate = momentSetHoursAndMinutes(
            segment.ArrivalDate, momentHours(segment.ArrivalTime), momentMinutes(segment.ArrivalTime),
          );


          segment.ArrivalDate = arrivalDate.format(FULLTIME);

          segment.DepartureDate = departureDate.format(FULLTIME);
        });
      });
    });
  };

  handleConfirm = () => {
    const {
      OrderTripItemId,
      CompanyId,
      Description,
      Surcharges,
      ReturnFunds,
      Penalties,
      ServiceType,
      Status,
      JsonData,
      ProjectId,
      DepartmentId,
      UserAnalytics,
    } = this.state;
    const {
      addTrip, guidService, onConfirm, airline,
    } = this.props;

    const stateToSave = JSON.parse(JSON.stringify(this.state));
    const Guid = guidService.generate();

    stateToSave.Guid = Guid;

    const { isValid, validation } = this.validationSubmit({
      JsonData, CompanyId, ProjectId, DepartmentId,
    });

    if (isValid) {
      this.normalizeVoucher(JsonData);

      const clearJsonData = clearVatDetails(JsonData);
      const updatedJsonData = prepareVatDetails(clearJsonData);
      const preparedJson = preparedPriceDetails(updatedJsonData, true);

      const item = {
        OrderTripItemId,
        OrderTripId: 0,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),

        Description,

        Penalties: [Penalties],
        ReturnFunds: [ReturnFunds],
        Surcharges: [Surcharges],

        UserAnalytics,

        Status,
        ServiceType,
        Guid,
        indexEdited: airline.indexEdited,

        JsonData: JSON.stringify(preparedJson),
      };

      stateToSave.JsonData[0].Routes = this.state.JsonData[0].Routes;
      addTrip(stateToSave);

      onConfirm(item);
    } else {
      scrollToErrMessage();
      this.setState({ validation });
    }
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  handleGetInfo = async (provider, ticketNumber, pnr, surname, lastName, officeId, salePoint) => {
    const { tripService } = this.props;
    const { JsonData } = this.state;

    this.toggleWaitingResponse();

    try {
      const ticket = await tripService.getAirTripInfo(
        provider, ticketNumber, pnr, surname, lastName, officeId, salePoint,
      );

      const newRoutes = ticket.Routes.map((route, index) => {
        const segments = route.Segments.map((segment) => {
          this.handlerAddPart({
            type: 'segment', routeInd: index, ticketInd: 0,
          });
          const newSegment = segment;
          newSegment.ArrivalDate = dateParseZone(segment.ArrivalDate);
          newSegment.DepartureDate = dateParseZone(segment.DepartureDate);

          newSegment.Class = segment.Class || FLYCLASS[JsonData[0].Class];

          return newSegment;
        });
        this.handlerAddPart({ type: 'route', ticketInd: 0 });

        return {
          ...route,
          RouteIndex: index,
          Segments: segments,
        };
      });

      const newJsonData = [...this.state.JsonData];

      newJsonData[0] = {
        ...this.state.JsonData[0],
        PNR: ticket.PNR,
        PNRLocator: ticket.PNRLocator,
        PNRProvider: ticket.PNRProvider,
        Routes: setRoutesDurationAndTime(newRoutes),
        PriceDetails: ticket.PriceDetails,
        VatDetails: ticket.VatDetails || [],
        Num: ticket.TicketNumber || ticketNumber,
      };

      return this.setState({
        ...this.state,
        JsonData: newJsonData,
      }, this.toggleWaitingResponse);
    } catch (err) {
      return this.setState({ getInfoError: GET_INFO_ERROR }, this.toggleWaitingResponse);
    }
  };

  handleChangeEmployee = async (value) => {
    const { CompanyId } = this.state;
    await this.props.tripService.autocompleteEmployees(CompanyId, value);
  };

  handleSelectEmployee = async ({ ticketInd, employee: { Id } }) => {
    const {
      airService, travelPolicyService, tripService,
    } = this.props;

    const currentEmployee = await tripService.getEmployeeDataByID(Id);

    const { CompanyId } = this.state;
    const AIR = travelPolicyService.getServiceTypeConst().AIR;

    this.setState({
      Departments: findDepartments(currentEmployee, CompanyId),
      EmployeeId: Id,
    });

    const getTP = async () => {
      const { tpDetails } = this.state;

      try {
        const travelPolicy = await travelPolicyService.getEmployeeTP(Id);
        const { Value: rate } = await travelPolicyService.getRateForType(travelPolicy.AirlineRule);

        tpDetails[ticketInd] = travelPolicyService.getTPDetails(travelPolicy, AIR, rate);

        this.setState({
          tpDetails,
          showAlertTP: tpDetails[ticketInd],
        });
      } catch (error) {
        tpDetails.push(null);

        this.setState({ tpDetails });
      }
    };

    if (currentEmployee.Documents.length) {
      this.setState((state) => {
        const newState = { ...state };
        newState.JsonData[ticketInd].Travellers = currentEmployee;
        newState.validation.list[ticketInd].Travellers = airService.airTripFieldValidation(
          null, null, 'Travellers', newState.JsonData[ticketInd].Travellers,
        );
        newState.JsonData[ticketInd].Passport = {
          EmployeeId: Id,
          PassportId: currentEmployee.Documents[0].Id,
        };

        return newState;
      }, getTP);
    } else {
      alert('Нельзя выбрать сотрудника без паспорта');
    }
  };

  handlerRemoveEmployee = ({ ticketInd }) => {
    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.JsonData[ticketInd].Travellers = null;
      newState.Departments = [];
      newState.EmployeeId = 0;
      newState.DepartmentId = 0;
      newState.validation.list[ticketInd].Travellers = this.props.airService.airTripFieldValidation(
        null, null, 'Travellers', newState.JsonData[ticketInd].Travellers,
      );

      newState.tpDetails[ticketInd] = null;

      return newState;
    });
  };

  handlerChangeTicket = ({
    ticketInd, routeInd, segmentInd, field, value,
  }) => {
    this.setState((state) => {
      const part = field.split('.');

      let obj = state.JsonData[ticketInd];

      if (routeInd !== null && segmentInd !== null) {
        obj = obj.Routes[routeInd].Segments[segmentInd];
      } else if (routeInd !== null) {
        obj = obj.Routes[routeInd];
      }

      if (part.length === 1) {
        obj[field] = value;
      } else {
        obj[part[0]][part[1]] = value;
      }

      return state;
    });
  };

  handleChangeFlyClass = (ticketInd, routeInd, segmentInd, event, field, value) => {
    this.handlerChangeTicket({
      ticketInd, routeInd, segmentInd, event, field, value,
    });

    this.handleChangeFlyClassAndRoutes(value);
  };

  handleChangeFlyClassAndRoutes = (flyClass) => {
    const { JsonData } = this.state;

    const newRoutes = JsonData[0].Routes.map((route) => {
      const defaultRoute = route;

      defaultRoute.Segments = route.Segments.map((segment) => ({
        ...segment,
        Class: FLYCLASS[flyClass],
      }));

      return defaultRoute;
    });

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.JsonData[0].Routes = newRoutes;

      return newState;
    });
  };

  handleChangeVatDetails = (
    ticketInd, rate, field, value,
  ) => this.setState((state) => updateVatDetails(ticketInd, rate, field, value, state));

  handlerSelectCompany = ({ id }) => {
    const { airService, mandatoryProject } = this.props;
    const newMandatory = isMandatory(mandatoryProject, id);
    const result = airService.airTripFieldValidationAir(FIELDS.COMPANYID, id);
    const projectResult = mandatoryProject ? airService.airTripFieldValidation(null, null, FIELDS.PROJECTID, null) : '';

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.CompanyId = id;
      newState.mandatoryProject = newMandatory;
      newState.validation.ProjectId = projectResult;
      newState.validation.CompanyId = result;
      newState.validation.list.forEach((item, i) => {
        newState.validation.list[i].Travellers = '';
      });
      newState.JsonData.forEach((item, i) => {
        newState.JsonData[i].Travellers = null;
      });

      return newState;
    });
  };

  handleSelectProject = ({ id }) => {
    const { airService } = this.props;
    const { mandatoryProject } = this.state;
    const result = mandatoryProject ? airService.airTripFieldValidation(null, null, FIELDS.PROJECTID, id) : '';

    this.setState({
      ProjectId: id,
      validation: {
        ...this.state.validation,
        ProjectId: result,
      },
    });
  };

  handleSelectAnalytics = ({ analytics: currentAnalytics = {}, analyticsValueId = [] }) => {
    const { tripAnalyticsValueIds: selectedTripAnalytics, setTripAnalytics } = this.props;
    const { UserAnalytics: selectedServiceAnalytics = [], validation } = this.state;

    const { ApplyToTrip } = currentAnalytics;
    const analyticsValues = ApplyToTrip ? selectedTripAnalytics : selectedServiceAnalytics;

    const updatedAnalyticsValues = !analyticsValueId
      ? removeAnalytics(analyticsValues, currentAnalytics)
      : applyAnalytics(analyticsValueId, analyticsValues, currentAnalytics);

    const analyticsValidation = validateSingleAnalytics(updatedAnalyticsValues, currentAnalytics);

    if (ApplyToTrip) {
      setTripAnalytics(updatedAnalyticsValues);
    }

    const updatedServiceAnalytics = ApplyToTrip
      ? {}
      : { UserAnalytics: updatedAnalyticsValues };

    this.setState({
      ...updatedServiceAnalytics,
      validation: {
        ...validation,
        analytics: {
          ...validation.analytics,
          ...analyticsValidation,
        },
      },
    });
  };

  handleSelectDepartment = ({ id }) => {
    const { airService } = this.props;
    const { Departments } = this.state;
    const result = Departments.length ? airService.airTripFieldValidation(null, null, FIELDS.DEPARTMENTID, id) : '';
    this.setState({
      DepartmentId: id,
      validation: {
        ...this.state.validation,
        DepartmentId: result,
      },
    });
  };

  handlerAddPart = (change) => {
    const { airService } = this.props;
    const { JsonData } = this.state;

    this.setState((state) => {
      switch (change.type) {
        case 'ticket':
          state.JsonData.push(airService.createNewTicket());
          state.validation.list.push(airService.createNewValidator());
          break;
        case 'route':
          state.JsonData[change.ticketInd].Routes.push(airService.createNewRoute());
          state.validation.list[change.ticketInd].Routes.push(airService.createNewRouteValidator());
          break;
        case 'segment':
          state.JsonData[change.ticketInd].Routes[change.routeInd].Segments.push(
            airService.createNewSegment(),
          );
          state.validation.list[change.ticketInd].Routes[change.routeInd].Segments.push(
            airService.createNewSegmentValidator(),
          );
          break;
      }

      return state;
    }, () => this.handleChangeFlyClassAndRoutes(JsonData[0].Class));
  };

  handlerDeletePart = (change) => this.setState((state) => deletePart(state, change));

  handleEmployeeFocus = async ({ ticketInd }) => {
    const { tripService } = this.props;
    const { CompanyId } = this.state;

    await tripService.autocompleteEmployees(CompanyId, '');

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.validation.list[ticketInd].Travellers = +CompanyId ? '' : ERRORSFORALL.FIRSTSELECTCOMPANY;

      return newState;
    });
  };

  handleSetInfoError = (error) => this.setState({ getInfoError: error });

  validationSubmit = ({
    JsonData, CompanyId, ProjectId, DepartmentId,
  }) => {
    const {
      airService, analytics, tripAnalyticsValueIds: tripAnalytics, projects,
    } = this.props;
    const {
      Departments, mandatoryProject, UserAnalytics: serviceAnalytics, operationAirline,
    } = this.state;

    let resultValidation = true;
    const resultValidationObj = [];

    const projectMassage = projects.length
      ? airService.airTripFieldValidationAir(FIELDS.PROJECTID, ProjectId)
      : ERRORSFORALL.NO_PROJECTS;

    for (let i = 0, item = JsonData[0]; i < JsonData.length; i++, item = JsonData[i]) {
      const result = airService.validationTicket(item, operationAirline);

      resultValidation = result.isValid;
      resultValidationObj.push(result.validation);
    }

    const companyId = airService.airTripFieldValidationAir(FIELDS.COMPANYID, CompanyId);
    const projectId = mandatoryProject ? projectMassage : '';
    const departmentId = Departments.length > 0 ? airService.airTripFieldValidationAir(FIELDS.DEPARTMENTID, DepartmentId) : '';
    if (companyId || projectId || departmentId) {
      resultValidation = false;
    }

    const analyticsValidation = validateAnalytics(tripAnalytics, serviceAnalytics, analytics);
    if (Object.keys(analyticsValidation).some((key) => !!analyticsValidation[key])) {
      resultValidation = false;
    }

    return {
      isValid: resultValidation,
      validation: {
        CompanyId: companyId,
        ProjectId: projectId,
        DepartmentId: departmentId,
        MandatoryProject: mandatoryProject,
        list: resultValidationObj,
        analytics: analyticsValidation,
      },
    };
  };

  validationInput = (ticketInd, routeInd, segmentInd, field, operationAir = '') => {
    const { tripService, airService } = this.props;
    const { operationAirline } = this.state;

    const operationAirlineCurrent = operationAir || operationAirline;

    this.setState((state) => {
      const part = field.split('.');

      let obj = state.JsonData[ticketInd];
      let resultObj = state.validation.list[ticketInd];

      if (routeInd !== null && segmentInd !== null) {
        obj = obj.Routes[routeInd].Segments[segmentInd];
        resultObj = resultObj.Routes[routeInd].Segments[segmentInd];
      } else if (routeInd !== null) {
        obj = obj.Routes[routeInd];
        resultObj = resultObj.Routes[routeInd];
      }

      let value = obj[field];
      if (part.length > 1) value = obj[part[0]][part[1]];

      const resultValidation = airService.airTripFieldValidation(
        routeInd, segmentInd, field, value, operationAirlineCurrent,
      );

      if (operationAir) tripService.saveOperationAirline(operationAir);

      if (part.length === 1) {
        resultObj[field] = resultValidation;
      } else {
        resultObj[part[0]][part[1]] = resultValidation;
      }

      return state;
    });
  };

  closeAlertTP = () => this.setState({ showAlertTP: false });

  renderAlertTP = () => (this.state.showAlertTP ? <AlertTP onClose={ this.closeAlertTP } /> : null);

  renderProject = () => {
    const { projects, mandatoryProject } = this.props;
    const {
      ProjectId, validation, CompanyId,
    } = this.state;

    return (
      <SelectProject
        disabled={ !CompanyId }
        currentProjectId={ ProjectId }
        validationProject={ validation.ProjectId }
        mandatoryProject={ mandatoryProject }
        projects={ projects }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  renderAnalytics = () => {
    const { analytics, tripAnalyticsValueIds } = this.props;
    const { validation, UserAnalytics } = this.state;

    return (
      <AnalyticsRows
        analytics={ analytics }
        serviceAnalyticsValueIds={ UserAnalytics }
        tripAnalyticsValueIds={ tripAnalyticsValueIds }
        onSelect={ this.handleSelectAnalytics }
        validation={ validation.analytics }
      />
    );
  };

  renderCompany = () => {
    const { companies } = this.props;
    const { CompanyId, validation } = this.state;

    return (
      <SelectCompany
        currentCompanyId={ CompanyId }
        companies={ companies }
        onSelect={ this.handlerSelectCompany }
        validationCompany={ validation.CompanyId }
      />
    );
  };

  render() {
    const {
      airService,
      specialBillAccount,
      changeRate,
      tripService,
    } = this.props;
    const {
      EmployeeId,
      Departments,
      DepartmentId,
      tpDetails,
      validation,
      employeesList,
      CompanyId,
      waitingResponse,
      getInfoError,
    } = this.state;

    const data = this.state.JsonData;
    const selectProjectsHtml = this.renderProject();
    const selectCompanyHtml = this.renderCompany();
    const analyticsRows = this.renderAnalytics();

    return (
      <div className={ styles.wrap }>
        <div className={ styles.row }>
          <h4>Ваучер</h4>
          <div className={ styles.row }>
            { selectCompanyHtml }
            <SelectDepartment
              departments={ Departments }
              onSelect={ this.handleSelectDepartment }
              validationDepartment={ validation.DepartmentId }
              currentDepartmentId={ DepartmentId }
              currentEmployeeId={ EmployeeId }
            />
            { selectProjectsHtml }
          </div>
          { analyticsRows }
          <AirTickets
            saveOperationAirline={ tripService.saveOperationAirline }
            tickets={ data }
            employees={ employeesList }
            specialBillAccount={ specialBillAccount }
            providerNameOptions={ this.providerNameOptions }
            onSelectEmployee={ this.handleSelectEmployee }
            handleChangeVatDetails={ this.handleChangeVatDetails }
            handlerRemoveEmployee={ this.handlerRemoveEmployee }
            handlerAddPart={ this.handlerAddPart }
            handlerDeletePart={ this.handlerDeletePart }
            onChange={ this.handlerChangeTicket }
            onBlur={ this.validationInput }
            validation={ validation.list }
            airService={ airService }
            tpDetails={ tpDetails }
            onEmployeeFocus={ this.handleEmployeeFocus }
            onChangeEmployee={ this.handleChangeEmployee }
            disablePickEployee={ !CompanyId }
            handleGetInfo={ this.handleGetInfo }
            waitingResponse={ waitingResponse }
            handleChangeFlyClass={ this.handleChangeFlyClass }
            getInfoError={ getInfoError }
            handleSetInfoError={ this.handleSetInfoError }
            changeRate={ changeRate }
          />
        </div>

        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label='Подтвердить изменения'
            />
          </div>
        </div>

        { this.renderAlertTP() }
      </div>
    );
  }
}

export default NewAirlineForm;
