import airlineCancel from '../routers/bundles/airlineCancel';
import amoCrm from '../routers/bundles/amoCrm';
import autoSendingEmails from '../routers/bundles/autoSendingEmails';
import bookTrip from '../routers/bundles/bookTrip';
import calculator from '../routers/bundles/calculator';
import calls from '../routers/bundles/calls';
import cartEdit from '../routers/bundles/cartEdit';
import cartNew from '../routers/bundles/cartNew';
import cartsAndNotes from '../routers/bundles/cartsAndNotes';
import cartToTrip from '../routers/bundles/cartToTrip';
import clientSession from '../routers/bundles/clientSession';
import companies from '../routers/bundles/companies';
import employeePage from '../routers/bundles/employeePage';
import employeesPage from '../routers/bundles/employeesPage';
import hotels from '../routers/bundles/hotels';
import login from '../routers/bundles/login';
import mice from '../routers/bundles/mice';
import packagesCorrection from '../routers/bundles/packagesCorrection';
import paymentHotels from '../routers/bundles/paymentHotels';
import railway from '../routers/bundles/railway';
import regions from '../routers/bundles/regions';
import report from '../routers/bundles/report';
import reportTaxi from '../routers/bundles/reportTaxi';
import reviseAct from '../routers/bundles/reviseAct';
import searchTaxi from '../routers/bundles/searchTaxi';
import searchTrips from '../routers/bundles/searchTrips';
import searchUsers from '../routers/bundles/searchUsers';
import sendingDiadoc from '../routers/bundles/sendingDiadoc';
import settings from '../routers/bundles/settings';
import unloading1c from '../routers/bundles/unloading1c';
import users from '../routers/bundles/users';
import vacation from '../routers/bundles/vacation';
import vacationAccount from '../routers/bundles/vacationAccount';
import vipHallCancel from '../routers/bundles/vipHallCancel';

const bundles = {
  airlineCancel,
  amoCrm,
  autoSendingEmails,
  bookTrip,
  calculator,
  calls,
  cartEdit,
  cartNew,
  cartsAndNotes,
  cartToTrip,
  clientSession,
  companies,
  employeePage,
  employeesPage,
  hotels,
  login,
  mice,
  packagesCorrection,
  paymentHotels,
  railway,
  regions,
  report,
  reportTaxi,
  reviseAct,
  searchTaxi,
  searchTrips,
  searchUsers,
  sendingDiadoc,
  settings,
  unloading1c,
  users,
  vacation,
  vacationAccount,
  vipHallCancel,
};

const loadingParts = {};

const beforeOnRouteChange = (request) => new Promise(async (resolve, reject) => {
  const bundlesName = request.opts.bundles;

  if (loadingParts[bundlesName]) {
    resolve();
  } else {
    let handler;

    try {
      handler = bundles[bundlesName];
      loadingParts[bundlesName] = true;
      handler();
      setTimeout(resolve, 200);
    } catch (e) {
      console.error('@{moduleBundle} not found');
      reject();
    }
  }
});

export default beforeOnRouteChange;
