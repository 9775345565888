import React from 'react';
import PropTypes from 'prop-types';

import { LABELFIELDSRU } from '../../../bi/constants/trips';

import Suggest from '../../../components/Suggest';

import styles from '../styles/form.module.scss';

const NOPROJECT = 'Центров затрат нет';

const foundProjectName = (currentProjectId, projects) => (
  currentProjectId ? projects.find(({ id }) => id === currentProjectId).name : ''
);

const SelectProject = ({
  disabled,
  projects,
  mandatoryProject,
  currentProjectId,
  validationProject,
  onSelect,
}) => {
  const title = LABELFIELDSRU.PROJECT;
  const validation = disabled ? '' : validationProject;

  if ((Array.isArray(mandatoryProject) && mandatoryProject.length) || mandatoryProject || projects.length > 0) {
    return (
      <div className={ styles['col-1-3'] }>
        <Suggest
          title={ title }
          disabled={ disabled }
          valid={ validation }
          suggests={ projects }
          currentLabel={ foundProjectName(currentProjectId, projects) }
          onSelect={ onSelect }
        />
      </div>
    );
  }

  return (
    <div className={ styles['col-1-3'] }>
      <div className={ styles.select }>
        <div className={ styles.wrap }>
          <label>{ title }</label>
          <div className={ styles['projects-absence'] }><span>{ NOPROJECT }</span></div>
        </div>
      </div>
    </div>
  );
};

SelectProject.propTypes = {
  disabled: PropTypes.bool,
  projects: PropTypes.array.isRequired,
  mandatoryProject: PropTypes.oneOfType([PropTypes.array, PropTypes.bool]).isRequired,
  currentProjectId: PropTypes.number.isRequired,
  validationProject: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
};

SelectProject.defaultProps = {
  validationProject: '',
  disabled: false,
};

export default SelectProject;
