import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FIELDS,
  FUNDSFIELDS,
  PRICEFIELDSSHORT,
  PRICELABELS,
  TRIPSLABELS,
  TRIP_TYPE_FORMAT,
  TRIP_TYPE_FORMAT_RU,
  FLIGHT_TYPE_FORMAT,
  FLIGHT_TYPE_FORMAT_RU,
  LABELS_VAT,
  VAT_VALUE,
} from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';
import { DEFAULTTIME } from '../../../../../../constants/time';

import { findDepartments } from '../../../../../../bi/utils/trip';
import { formatDate } from '../../../../../../bi/utils/formatDate';

import Input from '../../../../../../components/input';
import Radio from '../../../../../../components/radio';
import { FlatButton } from '../../../../../../components/button';

import SelectProject from '../../../selectProject';
import SelectDepartment from '../../../selectDepartment';

import style from '../../../../styles/form.module.scss';

class PartVipHallForm extends Component {
  static propTypes = {
    vipHall: PropTypes.object,
    onConfirm: PropTypes.func,
    projects: PropTypes.arrayOf(PropTypes.object),
    tripService: PropTypes.object.isRequired,
    isTripPaidByPersonalFunds: PropTypes.bool,
  };

  static defaultProps = {
    vipHall: null,
    onConfirm: () => {},
    projects: [],
    employees: [],
    isTripPaidByPersonalFunds: false,
  };

  constructor(props) {
    super();

    const vipHall = props.vipHall;
    const currentEmployeeId = vipHall && vipHall.Employees && vipHall.Employees[0] && vipHall.Employees[0].Id;

    this.state = {
      OrderTripItemId: vipHall && vipHall.OrderTripItemId,
      ServiceType: vipHall && vipHall.ServiceType,
      Status: 3,
      JsonData: vipHall ? JSON.parse(vipHall.JsonData) : '',
      PriceDetails: vipHall
        ? JSON.parse(vipHall.JsonData).PriceDetails
        : {
          Base: 0,
          Commission: 0,
          HasVAT: false,
        },
      Employees: vipHall && vipHall.Employees,
      CompanyId: vipHall ? vipHall.CompanyId : 0,
      ProjectId: vipHall ? vipHall.ProjectId : 0,
      EmployeeId: currentEmployeeId,
      DepartmentId: vipHall ? vipHall.DepartmentId : 0,
      providerName: vipHall && vipHall.ProviderName,
      departments: [],
      validation: {
        ProviderOrderId: '',
        Base: '',
      },
    };
  }

  componentDidMount() {
    const { EmployeeId, CompanyId } = this.state;

    this.props.tripService.getEmployeeDataByID(EmployeeId)
      .then(((res) => this.setState({ departments: findDepartments(res, CompanyId) })))
      .catch(() => this.setState({ departments: [] }));
  }

  prepareJsonData = () => {
    const { JsonData, Employees } = this.state;
    const {
      ProviderOrderId,
      ProviderName,
      ServiceType,
      City,
      ReservationDate,
      CheckinDate,
      CheckoutDate,
      CancellationDate,
      CancellationTime,
      Name,
      TripType,
      Flight,
      AdditionalInformation,
      AdditionalServices,
      Comment,
    } = JsonData;

    return {
      ProviderOrderId,
      ProviderName,
      EmployeeIds: Employees.map((item) => item.Id),
      ServiceType,
      City,
      ReservationDate,
      CheckinDate,
      CheckoutDate,
      CheckinTime: 0,
      CheckoutTime: 0,
      CancellationDate,
      CancellationTime,
      Name,
      TripType,
      Flight,
      AdditionalInfo: AdditionalInformation.join('\n'),
      AdditionalServices,
      Comment,
    };
  };

  handleConfirm = () => {
    const {
      OrderTripItemId,
      ServiceType,
      Status,
      PriceDetails,
      CompanyId,
      ProjectId,
      DepartmentId,
      JsonData,
    } = this.state;

    const {
      Base, Commission, HasVAT,
    } = PriceDetails;

    const { isValid, validation } = this.validationSubmit({ JsonData });

    if (isValid) {
      const priceDetailsData = {
        Base: parseFloat(Base, 10) || 0,
        Commission: parseFloat(Commission, 10) || 0,
        HasVat: HasVAT,
        VAT: 0,
      };

      const item = {
        OrderTripItemId,
        TripId: 0,
        Status,
        ServiceType,
        CompanyId: parseInt(CompanyId, 10),
        ProjectId: parseInt(ProjectId, 10),
        DepartmentId: parseInt(DepartmentId, 10),
        JsonData: JSON.stringify(this.prepareJsonData()),
        PriceDetails: priceDetailsData,
      };

      this.props.onConfirm(item);
    } else {
      this.setState({ validation: { ...validation } });
    }
  };

  handleChangeInput = (e, field, value) => {
    this.setState((state) => {
      const newState = { ...state };
      const part = field.split('.');

      if (part.length === 1) {
        newState.JsonData[field] = value;
      } else {
        newState.JsonData[part[0]][part[1]] = value;
      }

      return newState;
    });
  };

  handleChangePriceInput = (e, field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      [field]: value,
    },
  });

  handleSelectProject = ({ id }) => this.setState({ ProjectId: id });

  handleSelectDepartment = ({ id }) => this.setState({ DepartmentId: id });

  handleChangeVat = (field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      HasVAT: value === 'true',
    },
  });

  validationPrice = (e, field) => {
    const result = this.props.tripService.vipHallTripFieldValidation(field, this.state.PriceDetails[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationProvider = (e, field) => {
    const result = this.props.tripService.vipHallTripFieldValidation(field, this.state.JsonData[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  validationSubmit = ({ JsonData }) => {
    const { tripService } = this.props;
    const { PriceDetails } = this.state;
    const { ProviderOrderId } = JsonData;

    const price = tripService.vipHallTripFieldValidation(FUNDSFIELDS.BASE, PriceDetails.Base);
    const providerId = tripService.vipHallTripFieldValidation(FIELDS.PROVIDERORDERID, ProviderOrderId);

    if (price || providerId) {
      return {
        isValid: false,
        validation: {
          ProviderOrderId: providerId,
          Base: price,
        },
      };
    }

    return { isValid: true };
  };

  renderAdditionals = () => {
    const { JsonData: { AdditionalServices } } = this.state;

    return AdditionalServices.map(({ Name, Price }) => (
      <div>
        <span>
          { ' ' }
          -
          { Name }
          { ' ' }
          -
          { Price }
          { TRIPSLABELS.RUB }
        </span>
      </div>
    ));
  };

  renderIncludedServices = () => {
    const { JsonData: { AdditionalInformation } } = this.state;

    return AdditionalInformation.map((item) => (
      <div>
        <span>
          { ' ' }
          -
          { item }
        </span>
      </div>
    ));
  };

  renderSelectProject = () => {
    const { projects, isTripPaidByPersonalFunds } = this.props;
    const { ProjectId } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        currentProjectId={ ProjectId }
        projects={ projects }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  render() {
    const {
      PriceDetails,
      Employees,
      DepartmentId,
      departments,
      validation,
      JsonData: {
        Flight: {
          DepartureAirport,
          ArrivalAirport,
          Type,
        },
        CheckinDate,
        CheckoutDate,
        TripType,
        ProviderOrderId,
      },
    } = this.state;

    const employee = Employees.map((item) => `${item.Surname} ${item.Name} ${item.Patronymic ? item.Patronymic : ''}`);
    const employeeId = Employees.map((item) => item.Id);
    const tripTypeHtml = TripType === TRIP_TYPE_FORMAT.INTERNATIONAL
      ? TRIP_TYPE_FORMAT_RU.INTERNATIONAL
      : TRIP_TYPE_FORMAT.DOMESTIC;
    const flightTypeHtml = Type === FLIGHT_TYPE_FORMAT.ARRIVAL
      ? FLIGHT_TYPE_FORMAT_RU.ARRIVAL
      : FLIGHT_TYPE_FORMAT_RU.DEPARTURE;

    return (
      <div className={ style.wrap }>
        <div className={ `${style.row} ${style.panel}` }>
          <h4>Данные ваучера</h4>
          <div className={ style.row }>
            <div className={ style.info }>
              <span>
                { TRIPSLABELS.EMPLOYEE }
                { ' ' }
                { employee }
              </span>
              <span>
                { TRIPSLABELS.START_PLACE }
                { ' ' }
                { DepartureAirport.Name }
              </span>
              <span>
                { TRIPSLABELS.END_PLACE }
                { ' ' }
                { ArrivalAirport.Name }
              </span>
              <span>
                { TRIPSLABELS.CHECKIN_DATE }
                { ' ' }
                { formatDate(CheckinDate) }
              </span>
              <span>
                { TRIPSLABELS.CHECKIN_TIME }
                { ' ' }
                { formatDate(CheckinDate, DEFAULTTIME) }
              </span>
              <span>
                { TRIPSLABELS.CHECKOUT_DATE }
                { ' ' }
                { formatDate(CheckoutDate) }
              </span>
              <span>
                { TRIPSLABELS.CHECKOUT_TIME }
                { ' ' }
                { formatDate(CheckoutDate, DEFAULTTIME) }
              </span>
              <span>
                { TRIPSLABELS.TRIP_TYPE }
                { ' ' }
                { tripTypeHtml }
              </span>
              <span>
                { TRIPSLABELS.FLIGHT_TYPE }
                { ' ' }
                { flightTypeHtml }
              </span>
              <div className={ style.row }>
                <span>{ TRIPSLABELS.INCLUDED_SERVICES }</span>
                <div className={ style['col-1-2'] }>
                  { this.renderIncludedServices() }
                </div>
              </div>
              <div className={ style.row }>
                <span>{ TRIPSLABELS.ADDITIONALS_SERVICES }</span>
                <div className={ style['col-1-2'] }>
                  { this.renderAdditionals() }
                </div>
              </div>
            </div>
          </div>
          <div className={ style.row }>
            { this.renderSelectProject() }
            <SelectDepartment
              departments={ departments }
              onSelect={ this.handleSelectDepartment }
              currentDepartmentId={ DepartmentId }
              currentEmployeeId={ employeeId[0] }
            />
          </div>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <Input
                field={ FIELDS.PROVIDERORDERID }
                value={ ProviderOrderId }
                label={ TRIPSLABELS.PROVIDERORDERID }
                onChange={ this.handleChangeInput }
                onBlur={ this.validationProvider }
                valid={ validation.ProviderOrderId }
              />
            </div>
          </div>
          <div className={ style.row }>
            <div className={ style['col-1-3'] }>
              <Input
                field={ PRICEFIELDSSHORT.BASE }
                value={ PriceDetails.Base }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                label={ PRICELABELS.BASEPRICE }
                onChange={ this.handleChangePriceInput }
                onBlur={ this.validationPrice }
                valid={ validation.Base }
              />
            </div>
            <div className={ style['col-1-3'] }>
              <Input
                field={ PRICEFIELDSSHORT.COMMISSION }
                value={ PriceDetails.Commission }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                label={ PRICELABELS.TAXPENALTIES }
                onChange={ this.handleChangePriceInput }
              />
            </div>
            <div className={ `${style['col-1-4']} ${style['vat-action']}` }>
              <Radio
                label={ LABELS_VAT.WITH }
                field={ FIELDS.HASVAT }
                checked={ PriceDetails.HasVAT === VAT_VALUE.WITH }
                value={ VAT_VALUE.WITH }
                onChange={ (field, value) => this.handleChangeVat(field, value) }
              />
              <Radio
                label={ LABELS_VAT.WITHOUT }
                field={ FIELDS.HASVAT }
                checked={ PriceDetails.HasVAT === VAT_VALUE.WITHOUT }
                value={ VAT_VALUE.WITHOUT }
                onChange={ (field, value) => this.handleChangeVat(field, value) }
              />
            </div>
          </div>
        </div>
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ TRIPSLABELS.CONFIRM }
            />
          </div>
        </div>
      </div>
    );
  }
}

export { PartVipHallForm };
