const STATUSCODES = {
  CREATE: 0,
  BOOK: 1,
  CANCEL: 2,
  METADATA: 3,
  HIDDEN: 4,
};

const PriceDetails = {
  Base: 0,
  Tax: 0,
  Taxes: 0,
  Fee: 0,
  Commission: 0,
  TotalPrice: 0,
  VAT: 0,
};

const VatDetails = [
  {
    Rate: 10,
    BaseVat: 0,
    Amount: 0,
  },
  {
    Rate: 20,
    BaseVat: 0,
    Amount: 0,
  },
];

const VatDetails0 = [
  {
    Rate: 0,
    BaseVat: 0,
    Amount: 0,
  },
];

const MODELS = {
  PriceDetails,
  VatDetails,
  VatDetails0,
};

const FIELDS = {
  FROM: 'from',
  TO: 'to',
  DEPARTURE: 'Departure',
  DESTINATION: 'Destination',
  ARRIVAL_COUNTRY: 'ArrivalCountry',
  DEPARTURE_COUNTRY: 'DepartureCountry',
  ARRIVAL_CITY: 'ArrivalCity',
  DEPARTURE_CITY: 'DepartureCity',
  STARTPLACE: 'StartPlace',
  ENDPLACE: 'EndPlace',
  EMPLOYEELIST: 'EmployeesList',
  ENABLED: 'Enabled',
  COMPANYID: 'CompanyId',
  PROJECTID: 'ProjectId',
  PROVIDERS: 'Providers',
  DEPARTMENTID: 'DepartmentId',
  DESCRIPTION: 'Description',
  CHECKIN_DATE: 'CheckinDate',
  CHECKIN_DATE_EDIT: 'CheckinDateEdit',
  CHECKOUT_DATE_EDIT: 'CheckoutDateEdit',
  CHECKINTIME: 'CheckinTime',
  CHECKOUT_DATE: 'CheckoutDate',
  CHECKOUTDATELESS: 'CheckoutDateLess',
  CHECKOUTTIME: 'CheckoutTime',
  LOCATIONADDRESS: 'LocationAddress',
  DESTINATIONADDRESS: 'DestinationAddress',
  ARRIVALNUMBER: 'ArrivalNumber',
  DEPARTURENUMBER: 'DepartureNumber',
  CARRIAGENUMBER: 'CarriageNumber',
  CARMODEL: 'CarModel',
  CANCELLATION_DATE: 'CancellationDate',
  CANCELLATION_TIME: 'CancellationTime',
  CAPACITY: 'Capacity',
  BASEPRICE: 'Base',
  COMMISSION: 'commission',
  COMISSION_EDIT: 'Commission',
  PARTNERORDERID: 'PartnerOrderId',
  PROVIDERORDERID: 'ProviderOrderId',
  NOTE: 'Note',
  TABLESIGNATURE: 'TableSignature',
  PROVIDERNAME: 'ProviderName',
  PROVIDER_INFO: {
    OFFICE_ID: 'ProviderInfo.OfficeId',
    SALE_POINT: 'ProviderInfo.SalePoint',
  },
  CARCLASS: 'CarClass',
  EMPLOYEES: 'Employees',
  NAME: 'Name',
  RESERVATIONNUMBER: 'ReservationNumber',
  HOTEL: 'Hotel',
  HOTEL_ORDER_ID: 'HotelOrderId',
  RESERVATIONDATE: 'ReservationDate',
  GUESTCOUNT: 'GuestCount',
  HASVAT: 'HasVAT',
  HAS_TRAIN: 'HasTrain',
  JSON_DATA: 'JsonData',
  VALIDATION: 'validation',
  STATIONS: 'stations',
  LABEL: 'label',
  SUGGESTS: 'suggests',
  ORDER_ID: 'OrderId',
  SALE_ORDER_ID: 'SaleOrderId',
  TICKET_ID: 'TicketId',
  PASSPORT_ID: 'PassportId',
  GUID: 'guid',
  PROVIDER_COMMISSION: 'ProviderComission',
  RESERVATIONTODAY: 'ReservationToday',
  RESERVATIONYESTERDAY: 'ReservationYesterday',
  DISABLE_LOCATION: 'disableLocation',
  HOTELNAME: 'hotelName',
  CUSTOM_HOTEL_NAME: 'CustomHotelName',
  HOTELNAMEUPPER: 'Hotel.Name',
  HOTELSTARS: 'Hotel.Stars',
  HOTELADDRESS: 'Hotel.Address',
  HOTEL_EMAIL: 'Hotel.Email',
  HOTELCITY: 'Hotel.City',
  HOTEL_LOCATION: 'Hotel.Location',
  HOTEL_COUNTRY_CODE: 'Hotel.CountryCode',
  HOTEL_REGION_ID: 'Hotel.RegionId',
  HOTELPHONE: 'Hotel.Phone',
  HOTELCHECKINTIME: 'Hotel.CheckinTime',
  HOTELCHECKOUTTIME: 'Hotel.CheckoutTime',
  HOTEL_CHECKOUT_TIME_LESS: 'Hotel.CheckoutTimeLess',
  CUSTOMCHECKINDATE: 'CustomCheckInDate',
  EARLYCHECKIN: 'EarlyCheckIn',
  DATE: 'Date',
  CUSTOMCHECKOUT: 'CustomCheckOut',
  CUSTOMCHECKIN: 'CustomCheckIn',
  CUSTOMCHECKOUTDATE: 'CustomCheckOutDate',
  LATECHECKOUT: 'LateCheckOut',
  ROOMCATEGORY: 'Room.Category',
  BREAKFASTNAME: 'BreakfastName',
  ROOMFREECANCELLATION: 'Room.FreeCancellation',
  FREE_CANCELLATION: 'FreeCancellation',
  TOTAL_PENALTY: 'Total',
  BASE_PENALTY: 'Base',
  BASE_CURRENCY_PENALTY: 'BaseCurrency',
  MF_CURRENCY_PENALTY: 'MFInCurrency',
  MF_RUB_PENALTY: 'MFRubPenalties',
  INCLUSIVE_CURRENCY_PENALTY: 'ProviderPrice',
  BASE_PENALTY_FULL: 'Penalty.Base',
  ADDITIONAL: 'Additional',
  PROVIDER_INN: 'ProviderInn',
  REPORT_AMOUNT: 'ReportAmount',
  FROM_PENALTY: 'From',
  BREAKFAST: 'Breakfast',
  CONTACT: 'Contact',
  PENALTY_FROM: 'PenaltyFrom',
  PENALTY_TOTAL: 'PenaltyTotal',
  AIRPORT_FROM: 'AirportFrom',
  AIRPORT_TO: 'AirportTo',
  TRAIN_FROM: 'TrainFrom',
  TRAIN_TO: 'TrainTo',
  AIRPORT: 'Airport',
  ADDRESS: 'Address',
  INCLUDETABLET: 'IncludeTablet',
  TRAIN: 'Train',
  CLASS: 'Class',
  BAGGAGE: 'Baggage',
  AIRCRAFT_NAME: 'AircraftName',
  ARRIVAL_TIME: 'ArrivalTime',
  ARRIVAL_DATE: 'ArrivalDate',
  ARRIVAL_TERMINAL: 'ArrivalTerminal',
  ARRIVAL_AIRPORT_CODE: 'ArrivalAirport.Code',
  DEPARTURE_TIME: 'DepartureTime',
  DEPARTURE_DATE: 'DepartureDate',
  DEPARTURE_TERMINAL: 'DepartureTerminal',
  FLIGHT_NUMBER: 'FlightNumber',
  OPERATING_AIRLINE: 'operatingAirline',
  MARKETING_AIRLINE: 'marketingAirline',
  NIGHTS_COUNT: 'NightsCount',
  NIGHTS_PRICE: 'NightsPrice',
  DURATION: 'Duration',
  ADDITIONAL_INFO: 'AdditionalInfo',
  FLIGHT_TYPE: 'Flight.Type',
  TRIP_TYPE: 'TripType',
  DEPARTURE_AIRPORT: 'DepartureAirport',
  ARRIVAL_AIRPORT: 'ArrivalAirport',
  TERMINAL: 'Terminal',
  ADDITIONAL_SERVICES_NAME: 'Name',
  ADDITIONAL_SERVICES_PRTICE: 'Price',
  COMMENT: 'Comment',
  PROVIDER_ORDER_ID: 'ProviderOrderId',
  NUMBER: 'Number',
  PRICE: 'Price',
  TRANSACTION_ID: 'TransactionId',
  TICKET_NUMBER: 'TicketNumber',
  RESERVATION_DATE: 'ReservationDate',
  NAME_LATIN: 'NameLatin',
  NAME_CYRILLIC: 'NameCyrillic',
  CODE_COMPANY: 'CodeCompany',
  AIRLINE_INN: 'AirlineINN',
  AIRLINE_KPP: 'AirlineKPP',
  REASON: 'Reason',
  VIEWERS: 'Viewers',
  VAT_AMOUNT: 'VatAmount',
  CURRENCY_NAME: 'CurrencyName',
  PROVIDER: 'Provider',
  MF_IN_CURRENCY: 'CurrencyMFInCurrency',
  CURRENCY_INCLUSIVE: 'CurrencyInclusive',
  CURRENCY_BASE: 'CurrencyBase',
  CURRENCY_COMMISSION: 'CurrencyCommission',
  DIRECTION: 'Direction',
  AMOUNT: 'Amount',
  AIRLINE_ROUTES_DATES: 'AirlineRoutesDates',
  SELECTED_ITEMS: 'SelectedItems',
  SELECTED_RATE: 'SelectedRate',
  ITEMS: 'Items',
  TICKETS_NUMBER: 'TicketsNumber',
  SEAT_NUMBER: 'SeatNumber',
  LUGGAGE: 'Luggage',
  VALID_FILE: 'ValidFile',
  MEAL: 'Meal',
  SHOW_MODAL: 'showModal',
  SHOW_MODAL_EDIT_MAIN_HOTEL_FIELDS: 'showModalEditMainHotelFields',
  DISABLE_MAIN_HOTEL_FIELDS: 'disableMainHotelFields',
  TRANSIT_DOCUMENT_TYPE: 'TransitDocumentType',
};

const TRAIN_PRICING = {
  NAME: 'TrainPricing',
  TARIFF_PRICE: 'TrainPricing.TariffPrice',
  FARE: 'TrainPricing.Fare',
  COST: 'TrainPricing.Cost',
  SERVICE_TVA: 'TrainPricing.ServiceTva',
  SERVICE_TVA_RATE: 'TrainPricing.ServiceTvaRate',
};

const PRICE_DETAILS = {
  NAME: 'PriceDetails',
  TARIFF_PRICE: 'PriceDetails.TariffPrice',
  COMMISSION: 'PriceDetails.Commission',
};

const CARRIER_DETAILS = {
  NAME: 'CarrierDetails',
  INN: 'CarrierDetails.CarrierInn',
  CARRIER: 'CarrierDetails.Carrier',
  FULL_NAME: 'CarrierDetails.CarrierFull',
};

const ROUTE = {
  NAME: 'Route',
  TRAIN_NUMBER: 'Route.TrainNumber',
  CAR_NUMBER: 'Route.CarNumber',
  PLACE_NUMBER: 'Route.PlaceNumber',
  DATE_DEPARTURE_LOCAL: 'Route.DateDepartureLocal',
  TIME_DEPARTURE_LOCAL: 'Route.TimeDepartureLocal',
  DATE_ARRIVE_LOCAL: 'Route.DateArriveLocal',
  TIME_ARRIVE_LOCAL: 'Route.TimeArriveLocal',
  DATE_DEPARTURE: 'Route.DateDeparture',
  TIME_DEPARTURE: 'Route.TimeDeparture',
  DATE_ARRIVE: 'Route.DateArrive',
  TIME_ARRIVE: 'Route.TimeArrive',
  STATION_DEPART: 'Route.StationDepart',
  STATION_ARRIVE: 'Route.StationArrive',
  CAR_TYPE: 'Route.CarType',
  CLASS_SERVICE: 'Route.ClassService',
  CAR_TYPE_DESCRIPTION: 'Route.CarTypeDescription',
  SEAT_TYPE: 'Route.SeatType',
};

const FUNDSFIELDS = {
  PENALTIES: 'Penalties.Enabled',
  RETURNFUNDS: 'ReturnFunds.Enabled',
  SURCHARGES: 'Surcharges.Enabled',
  CHANGEVAT: 'ChangeVat.Enabled',
  DESCRIPTION: 'Description',
  BASE: 'Base',
  TAX: 'Tax',
  COMMISSION: 'Commission',
  TAXES: 'Taxes',
  FEE: 'Fee',
  INCLUSIVE: 'Inclusive',
  MF: 'MF',
  MF_CURRENCY: 'MFInCurrency',
  TOTAL_RUB: 'RequiredTotalInRub',
};

const VAT = {
  BASE: 'BaseVat',
  AMOUNT: 'Amount',
};

const VATFIELDS = {
  BASE: 'VatDetails.BaseVat',
  AMOUNT: 'VatDetails.Amount',
};

const FUNDSFIELDSTYPES = {
  PENALTIES: 'Penalties',
  RETURNFUNDS: 'ReturnFunds',
  SURCHARGES: 'Surcharges',
};

const FUNDSLABELS = {
  PENALTIES: 'Добавить штраф',
  RETURNFUNDS: 'Добавить возврат',
  SURCHARGES: 'Добавить требование доплаты',
  SURCHARGESAIR: 'Создать финансовые операции',
  CHANGEVAT: 'Добавить изменение НДС',
  SURCHARGES_CUSTOM: 'Добавить доплату',
  WARNING: 'Проверьте новые условия штрафов',
};

const PRICEDETAILS = {
  BASE: 'PriceDetails.Base',
  COMMISSION: 'PriceDetails.Commission',
  TAXES: 'PriceDetails.Taxes',
  TAXES_CLUE: 'PriceDetails.TaxesClue',
  FEE: 'PriceDetails.Fee',
  TAX: 'PriceDetails.Tax',
};

const PRICEFIELDSSHORT = {
  BASE: 'Base',
  COMMISSION: 'Commission',
  TAXES: 'Taxes',
  FEE: 'Fee',
  TAX: 'Tax',
};

const PRICELABELS = {
  AVIABASE: 'Тариф',
  BASE: 'Цена (базовое значение)',
  BASEPRICE: 'Цена поставщика',
  COMMISSION: 'Сбор Smartway',
  COMMISSIONSMARTWAY: 'Наценка Smartway',
  FEE: 'Плата (fee)',
  AVIAFEE: 'Добор сбора',
  TAX: 'Налог',
  AVIATAX: 'Таксы авиакомпании',
  AVIATAXES: 'Доп наценка',
  TAXES: 'Taxes',
  DESCRIPTION: 'Описание изменения для отчетности',
  BASESURCHARGES: 'Доплата поставщику',
  PENALTIES: 'Штраф поставщика',
  PRICE: 'Стоимость',
  TAXPENALTIES: 'Наценка Smartway',
  TAXES_SMARTWAY: 'Сбор при оформлении билета (валютный или за группу)',
  TAXES_SMARTWAY_GB: 'Сбор за групповое бронирование',
  TAXES_SMARTWAY_CLUE: (price) => `Рекомендованный валютный сбор ${price}`,
  CURRENCY_BASE: (currencyName) => `Цена в ${currencyName}`,
  CURRENCY_COMMISSION: (currencyName) => `Наценка Smartway в ${currencyName}`,
  MARKETING_FEE: 'Marketing Fee (вознаграждение Смартвей)',
  MARKETING_FEE_RUB: 'Marketing Fee (в рублях)',
  INCLUSIVE: 'Сумма inclusive (от поставщика)',
  TOTAL_RUB: 'Сумма Total (в рублях)',
  CALCULATE_CURRENCY: 'Рассчитать суммы',
};

const CANCELAIRFIELDS = {
  ALL: 'All',
  SEGMENTS: 'Segments',
};

const TRIPSLABELS = {
  MOVE: 'ПЕРЕНЕСТИ ПОЕЗДКИ',
  CACHE: 'ОБНОВИТЬ ПОЕЗДКИ В КЭШЕ',
  TRIP: 'ДОБАВИТЬ ПОЕЗДКУ',
  FEE: 'ДОБАВИТЬ ПЕРВУЮ АБОНПЛАТУ',
  CONFIRM: 'Подтвердить изменения',
  ADD_DATA_VAT: 'Внести НДС',
  NOT_DATA_VAT: 'Нет данных НДС',
  TOOLTIP: (field) => `Заполните обязательные поля: ${field}`,
  PLANFEE: 'выберите абонплату',
  RESERVATIONNUMBER: 'Номер бронирования',
  HOTEL_ORDER_ID: 'Внутренний номер брони в отеле',
  HASVAT: 'с НДС',
  NOTVAT: 'без НДС',
  PROVIDERORDERID: 'Номер заказа провайдера',
  PARTNERORDERID: 'Номер заказа',
  ORDER_NUMBER: 'Номер чата/заказа',
  DESCRIPTION_OF_CHANGES: 'Описание изменения',
  DESCRIPTION: 'Наименование изменения',
  INCREASE: 'Увеличить',
  DECREASE: 'Уменьшить',
  GUESTCOUNT: 'Количество гостей',
  HOTELNAME: 'Наименование',
  ADDRESS: 'Адрес',
  HOTEL_STARS: 'Кол-во звезд',
  PROVIDER_NAME: 'Провайдер',
  CITY: 'Город',
  COUNTRY: 'Страна',
  PHONE: 'Телефон',
  HOTELCHECKINTIME: 'Время заселения (ЧЧ:мм)',
  HOTELCHECKOUTTIME: 'Время выселения (ЧЧ:мм)',
  CUSTOMCHECKINDATE: 'Ранний заезд',
  EARLYCHECKIN: 'Цена за ранний заезд',
  CUSTOMCHECKOUTDATE: 'Поздний выезд',
  LATECHECKOUT: 'Цена за поздний выезд',
  ROOMCATEGORY: 'Категория комнаты',
  BREAKFASTNAME: 'Питание',
  BREAKFAST: 'Завтрак включен',
  CONTACT: 'Email отеля',
  SUM: 'Сумма (для клиента)',
  SUM_BASE: 'Сумма (для Smartway)',
  SUM_BASE_CURRENCY: 'Сумма в валюте (для Smartway)',
  MF_CURRENCY_PENALTIES: 'Сумма в валюте MF штрафа',
  MF_RUB_PENALTIES: 'Сумма MF штрафа',
  USER_FEE_FOR_ORDER: 'У пользователя есть сбор за выписку равный',
  ADDITIONAL: 'Не видит клиент',
  PENALTY_FROM: 'C',
  GET_DATA: 'Запросить данные',
  NIGHTS_COUNT: 'Количество ночей',
  NIGHTS_PRICE: 'Цена за 1 ночь (цена поставщика)',
  ADDITIONAL_INFO: 'Информация о бронировании',
  EMPLOYEE: 'Сотрудник:',
  START_PLACE: 'Пункт отправления:',
  END_PLACE: 'Пункт прибытия:',
  RESERVATION_DATE: 'Дата бронирования',
  TIME_OF_DEPARTURE: 'Дата и время отправления',
  TIME_OF_ARRIVAL: 'Дата и время прибытия',
  CHECKIN_DATE: 'Дата вылета:',
  CHECKIN_TIME: 'Время вылета:',
  CHECKOUT_DATE: 'Дата прилета:',
  CHECKOUT_TIME: 'Время прилета:',
  TODAY: 'Сегодня',
  YESTERDAY: 'Вчера',
  TRIP_TYPE: 'Тип вылета:',
  FLIGHT_TYPE: 'Тип рейса:',
  ADDITIONALS_SERVICES: 'Дополнительные услуги:',
  RUB: 'руб.',
  INCLUDED_SERVICES: 'Доступные опции:',
  IN_EXCESS: 'Оставить превышение на выбранном ваучере',
  ADD_NEW_VOUCHER: 'Добавить новый ваучер',
  COMPANY: 'Компания',
  SELECT_HOTEL: 'Выберите отель',
  COMMENT: 'Комментарий',
  REASON_TP: 'Причина нарушения ТП',
  INN: 'ИНН',
  REPORT_AMOUNT: 'Сумма по счету',
  ADD_INCLUSIVE: 'Заполните сумму Inclusive',
  ADD_MF: 'Заполните Marketing Fee',
  ADD_PENALTIES: 'Заполните штрафы',
  NAME_PROVIDER: 'Название провайдера',
  NAME_SERVICE: 'Название',
  DIRECTION_SERVICE: 'Направление',
  AMOUNT: 'Цена Total',
  PENALTY_FOR_CANCELLATION: 'Штраф за отмену',
  LUGGAGE_TYPE: 'Тип багажа',
  SEAT_NUMBER: 'Номер места в автобусе',
  UNTIL: 'до',
  ADD_ORDER: 'Добавить заказ',
  MEAL_PRICE: 'Цена питания',
  ERROR_GIFT_VOUCHER_COMPANY: 'Компания не найдена или у нее отсутствуют сотрудники',
};

const LABELFIELDSRU = {
  PROJECT: 'Центр затрат',
  DEPARTMENT: 'Отдел',
  ORGANIZATION: 'Организация',
  PROVIDERS: 'Поставщик услуги',
};

const TRIPBUTTONS = {
  DELETEORDERS: 'УДАЛИТЬ ЗАКАЗЫ',
  REMOVEORDERS: 'ПЕРЕНЕСТИ ЗАКАЗЫ',
  DELETETRIP: 'УДАЛИТЬ ПОЕЗДКУ',
  MOVE_TAXI_TRIP: 'ПЕРЕНЕСТИ ПОЕЗДКУ ТАКСИ',
  MOVE_FULL_VOUCHER: 'ПЕРЕНЕСТИ ВЕСЬ ВАУЧЕР',
  DELETE_OPERATION_TAXI: 'УДАЛИТЬ ПОЕЗДКУ ТАКСИ',
  REMOVETRIP: 'ПЕРЕНЕСТИ ПОЕЗДКУ',
  CONFIRMTRIP: 'ПОДТВЕРДИТЬ',
  CANCELTRIP: 'ОТМЕНИТЬ',
  BOOKTRIP: 'ЗАБРОНИРОВАТЬ',
  CHANGESTATUSTRIP: 'ИЗМЕНИТЬ СТАТУС',
  CONFIRM_DIALOG: 'Подтвердить',
  CANCEL_DIALOG: 'Отмена',
  CLOSE: 'Закрыть',
  VIEW: 'Просмотр',
  CANCELLATION: 'Запросить аннуляцию',
  CHANGETICKETSTATUS: 'Статус авиабилета',
  MANUAL_CANCELLATION: 'РУЧНАЯ ОТМЕНА',
  ADD_SERVICE: 'Добавить услугу',
  VOIDING: 'Отразить войдирование',
  ADD_TAXI_TRIP: 'Добавить поездку',
  GET_INFO: 'Добавить',
};

const TRIPSTATUS = {
  AWAITINGCONFIRMATION: 'AwaitingConfirmation',
  AWAITINGTICKETING: 'AwaitingTicketing',
  WAITRESCANCELEDIT: 'waitResCancelEdit',
  WAITRESSAVETRIP: 'waitResSaveTrip',
  CANCELLED: 'Cancelled',
};

const DIALOGFIELD = {
  CANCEL_DIALOG: 'cancelDialog',
  CANCEL_ITEM: 'cancelItem',
  CANCEL_TRAIN_DIALOG: 'cancelTrainDialog',
  CANCEL_TRAIN_ITEM: 'cancelTrainItem',
  CANCEL_AIR_DIALOG: 'cancelAirDialog',
  CANCEL_AIR_ITEM: 'cancelAirItem',
  CANCEL_AEROEXPRESS_DIALOG: 'cancelAeroExpressDialog',
  CANCEL_AEROEXPRESS_ITEM: 'cancelAeroExpressItem',
  CHANGE_TICKET_DIALOG: 'changeTicketDialog',
  CHANGE_TICKET_ITEM: 'changeTicketItem',
};

const DIALOGSTITLES = {
  SENDVOUCHERS: 'Введите email для отправки ваучеров:',
  SENDINVOICE: 'Отправить счёт на email:',
  VOUCHERSSEND: 'Ваучеры успешно отправлены',
  CONFIRMRETURNS: 'Заказ успешно отменен',
  RETURNSSENDERR: 'Ошибка аннуляции заказа',
  VOUCHERSENDERR: 'Ошибка отправки ваучера',
  INVOICESEND: 'Счет успешно отправлен',
  INVOICESENDERR: 'Ошибка при отправке счета',
  CONFIRM_DELETE_SEGMENT: 'Подтвердить удаление сегмента?',
  CONFIRM_DELETE_ROUTE: 'Подтвердить удаление маршрута?',
  CONFIRM: {
    ORDERS: 'Вы действительно хотите удалить заказы?',
    VERSION: 'Вы действительно хотите удалить последнюю версию заказа?',
    OPERATIONS: 'Вы уверены что хотите удалить эти поездки',
    ATTENTION_DOCUMENTS: 'Обратите внимание, поездка по ваучеру такси может быть учтена в закрывающих документах! Ошибочное удаление поездки может привести к некорректным данным!',
    ATTENTION_CANCEL: 'Внимание! Отменить данную операцию будет невозможно!',
  },
  MOVE_VOUCHER_OPERATIONS: 'Перенос поездок по ваучеру',
  DELETE_NOT_POSSIBLE: {
    TRIP: 'Удаление поездки невозможно. Обратитесь в SD.',
    ORDERS: 'Удаление заказов невозможно. Обратитесь в SD.',
    VERSION: 'Удаление последней версии заказа невозможно. Обратитесь в SD.',
    BECOUSE_DOCS: {
      TRIP: 'Удаление поездки невозможно. Поездка учтена в закрывающих документах:',
      ORDERS: 'Удаление заказов невозможно. Заказы учтены в закрывающих документах:',
      VERSION: 'Удаление последней версии заказа невозможено. Она учтена в закрывающих документах:',
    },
  },
  DELETE: {
    TRIP: 'Поездка удалена.',
    ORDERS: 'Заказы удалены.',
    LAST_VERSION_ORDERS: 'Последняя версия заказа удалена.',
    REALLY_DELETE: 'Вы действительно хотите удалить поездку?',
    SURE_DELETE: 'ВЫ ТОЧНО ХОТИТЕ УДАЛИТЬ ПОЕЗДКУ И ВСЕ УСЛУГИ В НЕЙ?',
  },
  CHANGE_NOT_POSSIBLE: 'Изменение даты и времени в версии заказа невозможно. Она учтена в закрывающих документах:',
  ERROR_CHECK_CLOSE_DOCUMENTS: 'Произошла ошибка при проверке закрывающих документов, попробуйте позже.',
  MOVE_NOT_POSSIBLE: {
    TRIP: 'Перенос поездки невозможен. Поездка учтена в закрывающих документах:',
    ORDERS: 'Перенос заказа невозможен. Поездка учтена в закрывающих документах:',
    TRIPS: 'Перенос поездок невозможен. Одна или несколько поездок уже учтены в закрывающих документах:',
  },
  TRIPATTENTION1C: 'Внимание! Поездка передана по интеграции в 1С!',
  ATTENTION: { OTHER_SERVICES_ON_TRIP: 'ВНИМАНИЕ! В ПОЕЗДКЕ ЕСТЬ ДРУГИЕ УСЛУГИ!' },
  TAXI_INFO: {
    ID: 'Id',
    FROM: 'Откуда',
    TO: 'Куда',
    DATE: 'Дата',
  },
  SUCCESS_ADD_TAXI: 'Поездка успешно добавлена',
  ERROR_MOVE_VOUCHER: 'Ошибка при переносе поездок',
  AGREE_WITH_CONDITIONS_BY_ALL_VOUCHER: 'Подтверждаю правильность и необходимость переноса заказов такси. Отменить операцию невозможно.',
  AGREE_WITH_CONDITIONS: 'Операция позволит разделить ваучер на 2 компании и перенести часть поездок в другой личный кабинет.',
  ATTENTION_HAVE_DOCUMENTS: 'Обратите внимание! Поездка учтена в закрывающих документах',
  CANT_CANCEL: 'Отменить операцию будет невозможно.',
  FULL_SUM_TAXI: 'Общая сумма переноса:',
  TICKET_CANCELLATION_ERROR: 'Произошла ошибка. Не удалось отменить заказ автоматически',
  MOVE_TRIPS: 'Поездки перенесены',
  MOVE_TRIPS_WARNING: 'Поездки перенесены. В целевой компании уже созданы пакеты закрывающих документов за период, в который входит одна или несколько перенесенных поездок:',
};

const DIALOGS = {
  DIALOGWIHTINTEGRATION: 'dialogWithIntegration',
  DANGEROUS_DELETE: {
    TRIP_DIALOG: 'dangerousDeleteTripDialog',
    ORDERS_DIALOG: 'dangerousDeleteOrdersDialog',
    ORDERS_DIALOG_WITH_INTEGRATION: 'dangerousDeleteOrdersDialogWithIntegration',
    ORDERS_DIALOG_ONLY_INTEGRATION: 'dangerousDeleteOrdersDialogOnlyIntegration',
    ACTUAL_VERSION_DIALOG: 'dangerousDeleteActualVersionDialog',
    ACTUAL_VERSION_WITH_INTEGRATION: 'dangerousActualVersionWithIntegration',
    ACTUAL_VERSION_ONLY_INTEGRATION: 'dangerousActualVersionOnlyIntegration',
    TRIP_DIALOG_ONLY_INTEGRATION: 'dangerousDeleteTripDialogOnlyIntegration',
    TRIP_DIALOG_WITH_INTEGRATION: 'dangerousDeleteTripDialogWithIntegration',
    CONFIRM_DELETE_TRIP_DIALOG: 'confirmDeleteTripDialog',
  },

  DANGEROUS_MOVE: {
    TRIP_DIALOG: 'dangerousMoveTripDialog',
    TRIP_ORDERS: 'dangerousMoveOrdersDialog',
    TRIP_DIALOG_ONLY_INTEGRATION: 'dangerousMoveTripDialogOnlyIntegration',
    TRIP_DIALOG_WITH_INTEGRATION: 'dangerousMoveTripDialogWithIntegration',
    ORDERS_DIALOG_ONLY_INTEGRATION: 'dangerousMoveOrdersDialogOnlyIntegration',
    ORDERS_DIALOG_WITH_INTEGRATION: 'dangerousMoveOrdersDialogWithIntegration',
  },

  CONFIRM_OPERATIONS_DELETE: 'confirmDeleteOperationsDialog',
  CONFIRM_MOVE_OPERATIONS: 'confirmMoveVouchersDialog',

  CANNOT_MOVE_VOUCHERS_DIALOG: 'cannotMoveVouchersDialog',

  AGREE_WITH_CONDITIONS: 'agreeWithConditionsDialog',
};

const STATUS_TRIP = {
  O: 'не использован',
  F: 'использован',
  V: 'отменен',
  E: 'обменян',
  H: 'частично использован',
};

const STATUSRU = {
  Created: 'Создан',
  Changed: 'Изменен',
  Canceled: 'Отменен',
  Metadata: 'Созданы метаданные',
};

const FLIGHT_TYPE_FORMAT = {
  DEPARTURE: 'Departure',
  ARRIVAL: 'Arrival',
  TRANSIT: 'Transit',
};

const FLIGHT_TYPE_FORMAT_RU = {
  DEPARTURE: 'Вылет',
  ARRIVAL: 'Прилет',
  TRANSIT: 'Транзит',
};

const FLIGHT_TYPE_FORMAT_SAVE_RU = {
  Departure: 'Вылет',
  Arrival: 'Прилет',
  Transit: 'Транзит',
};

const FLIGHT_TYPE = [
  {
    value: FLIGHT_TYPE_FORMAT.DEPARTURE,
    label: FLIGHT_TYPE_FORMAT_RU.DEPARTURE,
  },
  {
    value: FLIGHT_TYPE_FORMAT.ARRIVAL,
    label: FLIGHT_TYPE_FORMAT_RU.ARRIVAL,
  },
  {
    value: FLIGHT_TYPE_FORMAT.TRANSIT,
    label: FLIGHT_TYPE_FORMAT_RU.TRANSIT,
  },
];

const TRIP_TYPE_FORMAT = {
  DOMESTIC: 'Domestic',
  INTERNATIONAL: 'International',
  ANY: 'Any',
};

const TRIP_TYPE_FORMAT_RU = {
  DOMESTIC: 'Внутренний',
  INTERNATIONAL: 'Международный',
  ANY: 'Любой',
};

const TRIP_TYPE = [
  {
    value: TRIP_TYPE_FORMAT.DOMESTIC,
    label: TRIP_TYPE_FORMAT_RU.DOMESTIC,
  },
  {
    value: TRIP_TYPE_FORMAT.INTERNATIONAL,
    label: TRIP_TYPE_FORMAT_RU.INTERNATIONAL,
  },
  {
    value: TRIP_TYPE_FORMAT.ANY,
    label: TRIP_TYPE_FORMAT_RU.ANY,
  },
];

const VIP_HALL_TYPES = {
  FLIGHT_TYPE: 'FLIGHT_TYPE',
  TRIP_TYPE: 'TRIP_TYPE',
};

const TARIFF_TEXT = {
  TITLE: 'Тариф',
  TARIFF_PRICE: 'Тариф (tariff price)',
  FARE: 'Тариф (fare)',
  TOTAL: 'Цена',
  PRICE: 'Цена (price)',
  VAT_BASE: 'База НДС %',
  VAT_AMOUNT: 'Сумма НДС',
  RZD_FEE: 'Сборы РЖД',
  SMARTWAY_FEE: 'Сбор Smartway',
  INCLUDING_VAT: 'В сбор включен НДС по ставке 20%',
  CARRIER_INN: 'ИНН перевозчика',
  CARRIER: 'Перевозчик',
  PICK_CARRIER: 'Выберите перевозчика',
};

const ROUTE_TEXT = {
  TITLE: 'Маршрут',
  TRAIN: 'Поезд',
  CAR: 'Вагон(номер)',
  PLACE: 'Место(номер)',
  DATE: 'Дата',
  TIME: 'Время',
  FROM: 'От',
  TO: 'До',
  CLASS_SERVICE: 'Класс обслуживания',
  PICK_CLASS: 'Выберите класс вагона',
  CAR_TYPE: 'Тип вагона',
  CAR_DESCRIPTION: 'Вагон описание',
  PLACE_TYPE: 'Тип места',
};

const VOUCHER_TEXT = {
  TITLE: 'Данные ваучера',
  HAS_NOT_PASSPORT: 'У сотрудника нет паспорта',
  PICK_COMPANY_FIRST: 'Сначала выберите компанию',
  COMPANY: 'Организация',
  PICK_COMPANY: 'Выберите компанию',
  TICKET: 'Номер билета',
  ORDER_ID: 'Номер позиции в заказе',
  SALE_ORDER_ID: 'Номер заказа',
  PASSPORT: 'Паспорт',
  PROVIDER: 'Провайдер',
};

const CUSTOM_TYPES = { GROUP_ACCOMODATION: 'Групповое проживание' };

const LIMITS_INTPUT = {
  TWO: 2,
  THREE: 3,
  FIVE: 5,
  SIX: 6,
  TEN: 10,
  FOURTEEN: 14,
};

const UPLOAD_FORM = {
  DESCRIPTION: 'Чтобы начать загрузку, перетащите файл сюда\nили',
  INVALID_EXTENSION: 'Некорректное расширение файла. Доступные для загрузки:',
  INVALID_SIZE: 'Некорректный файл. Загрузите файл объемом до 10 мегабайт в формате',
  CHOOSE_FILE: 'Выберите файл',
};

const REQUIRED_EXTENSIONS = ['pdf'];

const FLIGHT_CERTIFICATE_STATUS_TYPES = {
  NEED_PURCHASE: 'NeedPurchase',
  NOT_CONFIRMED: 'NotConfirmed',
  CAN_DOWNLOAD: 'CanDownload',
  UNAVAILABLE: 'Unavailable',
  WAITING: 'Waiting',
  FAILED: 'Failed',
  ERROR: 'Error',
};

const FLIGHT_CERTIFICATE_STATUS_TEXT = {
  [FLIGHT_CERTIFICATE_STATUS_TYPES.FAILED]: 'Нет информации по статусу перелета',
  [FLIGHT_CERTIFICATE_STATUS_TYPES.WAITING]: 'Справку можно скачать только после совершения полета',
  [FLIGHT_CERTIFICATE_STATUS_TYPES.ERROR]: 'Произошла ошибка при получение статуса перелета',
};

const VAT_VALUE = {
  WITH: true,
  WITHOUT: false,
};

const LABELS_VAT = {
  WITH: 'С НДС',
  WITHOUT: 'Без НДС',
};

const UNDEF_VALUE_SEAT_SELECT = 'Undef';

const STATUSES_EXPLANATIONS = { CANCELED: 'Canceled' };

const LEGAL_ENTITY_PROVIDER = 'aanda';

const DIALOG_RESULT_MSG = {
  TaxiVoucher: {
    SUCCESS: 'Ваучер успешно отменён',
    CANCEL_ERROR: 'Не удалось автоматически отменить ваучер. Поставьте задачу',
    REFUND_ERROR: 'Ошибка, возможно в этой поездке нет данной услуги или ваучер был закрыт',
  },
  VipHall: {
    SUCCESS: 'Бронирование успешно отменено',
    CANCEL_ERROR: 'Не удалось автоматически отменить бронирование. Поставьте задачу',
    REFUND_ERROR: 'Ошибка, возможно в этой поездке нет данной услуги или бронирование было отменено',
  },
};

const PAYMENT_TYPES_CUSTOM_VALUES = {
  CARD: 'Card',
  ACCOUNT: 'Account',
};

const PAYMENT_TYPES_CUSTOM = [
  {
    value: PAYMENT_TYPES_CUSTOM_VALUES.CARD,
    label: 'Оплата с корпоративной карты',
  },
  {
    value: PAYMENT_TYPES_CUSTOM_VALUES.ACCOUNT,
    label: 'Оплата с расчетного счета',
  },
];

const INN_LENGHTS = {
  MAX: 13,
  TWELVE: 12,
  TEN: 10,
};

const STATUSES_INSURANCE = {
  NotInsured: 'NotInsured',
  PartiallyInsured: 'PartiallyInsured',
  Insured: 'Insured',
};

const STATUSES_ERROR_CURRENCY = {
  OK: 'Ok',
  NOT_EXISTS: 'NotExists',
  CANCELLED: 'Cancelled',
};

const TEXT_ERROR_CURRENCY = {
  [STATUSES_ERROR_CURRENCY.NOT_EXISTS]: 'К сожалению отменить бронирование невозможно, у рейта нет валютных операций. Поставьте, пожалуйста, задачу на разработку',
  [STATUSES_ERROR_CURRENCY.CANCELLED]: 'К сожалению отменить бронирование невозможно по внутренней ошибке. Попробуйте совершить отмену позже, при сохранении проблемы поставьте, пожалуйста, задачу на разработку',
};

const KEYS_NOT_NUMBER = {
  PLUS: '+',
  MINUS: '-',
  E: 'e',
};

const TYPES_EDIT = {
  EDIT_ITEM: 'editItem',
  EDIT_PART_ITEM: 'editPartItem',
};

const FIELDS_GIFT_VOUCHER = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  LIMIT: 'limit',
  CLASSES: 'classes',
  COMPANY_ID: 'companyId',
  IS_SECOND_STEP: 'isSecondStep',
  SELECTED_EMPLOYEE: 'selectedEmployee',
};

const VALIDATE_FIELDS_GIFT_VOUCHER = [
  FIELDS_GIFT_VOUCHER.SELECTED_EMPLOYEE,
  FIELDS_GIFT_VOUCHER.START_DATE,
  FIELDS_GIFT_VOUCHER.END_DATE,
  FIELDS_GIFT_VOUCHER.CLASSES,
  FIELDS_GIFT_VOUCHER.LIMIT,
];

export {
  STATUSCODES,
  MODELS,
  FIELDS,
  FUNDSFIELDS,
  FUNDSFIELDSTYPES,
  FUNDSLABELS,
  LABELS_VAT,
  VAT,
  VATFIELDS,
  VAT_VALUE,
  PRICEDETAILS,
  PRICELABELS,
  PRICEFIELDSSHORT,
  CANCELAIRFIELDS,
  TRIPSLABELS,
  LABELFIELDSRU,
  TRIPBUTTONS,
  TRIPSTATUS,
  DIALOGSTITLES,
  DIALOGFIELD,
  STATUSRU,
  STATUS_TRIP,
  FLIGHT_TYPE,
  TRIP_TYPE,
  LIMITS_INTPUT,
  TRAIN_PRICING,
  PRICE_DETAILS,
  ROUTE,
  CARRIER_DETAILS,
  TARIFF_TEXT,
  ROUTE_TEXT,
  VOUCHER_TEXT,
  CUSTOM_TYPES,
  UPLOAD_FORM,
  REQUIRED_EXTENSIONS,
  DIALOGS,
  FLIGHT_TYPE_FORMAT,
  FLIGHT_TYPE_FORMAT_RU,
  FLIGHT_TYPE_FORMAT_SAVE_RU,
  VIP_HALL_TYPES,
  FLIGHT_CERTIFICATE_STATUS_TYPES,
  FLIGHT_CERTIFICATE_STATUS_TEXT,
  TRIP_TYPE_FORMAT,
  TRIP_TYPE_FORMAT_RU,
  UNDEF_VALUE_SEAT_SELECT,
  STATUSES_EXPLANATIONS,
  LEGAL_ENTITY_PROVIDER,
  DIALOG_RESULT_MSG,
  PAYMENT_TYPES_CUSTOM_VALUES,
  PAYMENT_TYPES_CUSTOM,
  INN_LENGHTS,
  STATUSES_INSURANCE,
  STATUSES_ERROR_CURRENCY,
  TEXT_ERROR_CURRENCY,
  KEYS_NOT_NUMBER,
  TYPES_EDIT,
  FIELDS_GIFT_VOUCHER,
  VALIDATE_FIELDS_GIFT_VOUCHER,
};
