import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import CommentComponent from '../../../../components/Comment';

import {
  ACCOUNTTEXT, ACCOUNTFIELDS, COMMENTS,
} from '../../../../bi/constants/account';
import COMPONENTS from '../../../../bi/constants/components';

import styles from './styles/index.module.css';

const Comment = ({
  comment = '', field = ACCOUNTFIELDS.COMMENT, onSave,
}) => {
  const [newComment, setNewComment] = useState(comment);
  const [waitingResponse, setWaitingResponse] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setNewComment(comment);
  }, [comment]);

  const toggleEditing = () => {
    setNewComment(comment);
    setWaitingResponse(false);
    setEditing(!editing);
  };

  const handleChange = (value) => {
    const maxValue = COMMENTS[field].MAXCOMMENTLENGTH;

    if (!maxValue || value.length < maxValue) {
      setNewComment(value);
    }
  };

  const handleSave = async () => {
    setWaitingResponse(true);
    await onSave(field, newComment);
    toggleEditing();
  };

  const renderComment = () => {
    if (!newComment) return COMMENTS[field].NOCOMMENTTEXT;

    const mappedText = newComment.split('\n').map((string, index) => <p key={ index } className={ styles['comment-text'] }>{ string }</p>);

    return (
      <div className={ styles.value }>
        <b>{ mappedText }</b>
      </div>
    );
  };

  const renderEditText = () => (
    <CommentComponent
      value={ newComment }
      placeholder={ COMMENTS[field].INPUTCOMMENT }
      limit={ COMMENTS[field].MAXCOMMENTLENGTH }
      waitingResponse={ waitingResponse }
      onChange={ handleChange }
      onClose={ toggleEditing }
      onSave={ () => handleSave() }
    />
  );

  const renderText = () => (
    <div className={ styles.row }>
      <span className={ styles.name }>
        { COMMENTS[field].LABEL }
        :
      </span>
      { renderComment() }
      <Button
        label={ ACCOUNTTEXT.EDIT }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ toggleEditing }
        className={ styles['edit-button'] }
      />
    </div>
  );

  return editing ? renderEditText() : renderText();
};

Comment.propTypes = {
  comment: PropTypes.string,
  field: PropTypes.string,
  onSave: PropTypes.func.isRequired,
};

export default Comment;
