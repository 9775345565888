import React from 'react';
import PropTypes from 'prop-types';
import SelectAnalytics from './selectAnalytics';

import lodashReplaces from '../../../bi/utils/lodashReplaces';

import styles from '../styles/form.module.scss';

const AnalyticsRows = ({
  analytics, tripAnalyticsValueIds, serviceAnalyticsValueIds, onSelect, validation,
}) => {
  const arrOfAnalyticsSlices = lodashReplaces.chunk(analytics, 3);

  const rows = arrOfAnalyticsSlices.map((arr, i) => {
    const analyticsSlicesHtml = arr.map((item) => {
      const selectedAnalyticsValueIds = item.ApplyToTrip ? tripAnalyticsValueIds : serviceAnalyticsValueIds;
      const value = item.Values.find(({ Id }) => selectedAnalyticsValueIds.includes(Id)) || { Id: null };
      const currentValidation = validation[item.Id] || '';

      return (
        <SelectAnalytics
          key={ item.Id }
          analytics={ item }
          onSelect={ ({ id }) => onSelect({ analytics: item, analyticsValueId: id }) }
          valueId={ value.Id }
          validation={ currentValidation }
        />
      );
    });

    return (
      <div key={ i } className={ styles.row }>
        { analyticsSlicesHtml }
      </div>
    );
  });

  return (
    <div className={ styles.rows_analytics }>
      { rows }
    </div>
  );
};

AnalyticsRows.propTypes = {
  analytics: PropTypes.array,
  serviceAnalyticsValueIds: PropTypes.array,
  tripAnalyticsValueIds: PropTypes.array,
  onSelect: PropTypes.func,
  validation: PropTypes.object,
};

AnalyticsRows.defaultProps = {
  analytics: [],
  serviceAnalyticsValueIds: [],
  tripAnalyticsValueIds: [],
  onSelect: () => {},
  validation: {},
};

export default AnalyticsRows;
