const NO_EMPTY = 'Поле не должно быть пустым';
const NO_SELECT_GROUP_TYPE = 'Выберите категорию номера';

const require = (value) => value !== '';

const pickValue = (value) => value !== '0' && value !== 0;

const staticRatesValidStorage = {
  RateName: {
    fn: require,
    msg: NO_EMPTY,
  },
  GroupTypeId: {
    fn: pickValue,
    msg: NO_SELECT_GROUP_TYPE,
  },
  RefundTimeLimit: {
    fn: require,
    msg: NO_EMPTY,
  },
  ChargeValue: {
    fn: require,
    msg: NO_EMPTY,
  },
};

class RatesValidator {
  validateRates = (field, value) => {
    if (staticRatesValidStorage[field]) {
      const { fn, msg } = staticRatesValidStorage[field];

      if (!fn(value)) {
        return msg;
      }
    }

    return '';
  };

  isValid = (ratesValidation, rates) => ratesValidation.every((rate) => Object.keys(rate).every((key) => !rate[key]))
    && rates.every((item) => Object.keys(item).every((key) => {
      if (staticRatesValidStorage[key]) {
        return staticRatesValidStorage[key].fn(item[key]);
      }

      return true;
    }));
}

export { RatesValidator };
