import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NoResult from '../../../../components/NoResult';
import AjaxButton from '../../../../components/ajaxButton';
import UploadForm from '../../../../components/UploadForm';

import { REPORTLABALES } from '../../../../bi/constants/reports';
import { REQUIREDEXTENSIONS, UPLOADFORM } from '../../../../bi/constants/amo';
import styles from '../../styles/amoReport.module.css';

export default class AmoReportPage extends Component {
  static propTypes = { amoCRMService: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    const {
      uploaded,
      failed,
      error,
      guid,
    } = props.amoCRMService.get();

    this.state = {
      uploaded,
      failed,
      error,
      guid,
      waitingResponse: false,
    };
  }

  componentDidMount() {
    const { amoCRMService } = this.props;
    this.unsubscribe = amoCRMService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = ({
    uploaded,
    failed,
    error,
    guid,
  }) => {
    this.setState({
      uploaded,
      failed,
      error,
      guid,
    });
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  resetError = () => this.props.amoCRMService.resetError();

  handleDownloadAmoReport = (guid) => this.props.amoCRMService.downloadAmoReport(guid);

  handleDownload = () => {
    const { guid } = this.state;
    this.toggleWaitingResponse();

    this.handleDownloadAmoReport(guid)
      .then(this.toggleWaitingResponse)
      .catch(this.toggleWaitingResponse);
  };

  handleFileAdded = async (file) => {
    await this.props.amoCRMService.uploadFile(file);
  };

  handleOnRemove = () => this.props.amoCRMService.resetStore();

  renderErrorDialog = () => {
    const { failed, error } = this.state;

    return failed && (
      <NoResult
        onClose={ this.resetError }
        message={ error }
      />
    );
  };

  render() {
    const { waitingResponse, uploaded } = this.state;

    return (
      <div className={ styles.main }>
        <div className={ styles.upload }>
          <div className={ styles.text }>
            Чтобы скачать отчет сначала загрузите файл, а затем нажмите кнопку скачать
          </div>
          <UploadForm
            extensions={ REQUIREDEXTENSIONS }
            onFileAdded={ this.handleFileAdded }
            onFileRemoved={ this.handleOnRemove }
            uploaded={ uploaded }
            chooseFileLabel={ UPLOADFORM.CHOOSEFILE }
            description={ UPLOADFORM.DESCRIPTION }
            invalidExtension={ UPLOADFORM.INVALIDEXTENSION }
          />
        </div>
        <div className={ styles.button }>
          <AjaxButton
            label={ REPORTLABALES.DOWNLOAD }
            loading={ waitingResponse }
            onClick={ this.handleDownload }
          />
        </div>
        { this.renderErrorDialog() }
      </div>
    );
  }
}
