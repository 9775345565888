import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './listItem.module.scss';

class List extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired,
    itemComponent: PropTypes.func.isRequired,
    itemComponentProps: PropTypes.object.isRequired,
  };

  renderItem = () => <div className={ styles.item } />;

  renderEmpty() {
    return (
      <div className='empty-list'>
        Нет результатов
      </div>
    );
  }

  render() {
    const {
      items, itemComponent, itemComponentProps,
    } = this.props;
    let itemsHtml = null;

    if (items && items.length) {
      const component = itemComponent || this.renderItem();

      itemsHtml = items.map((item, ind) => {
        const itemProps = {
          ...itemComponentProps,
          item,
        };

        const html = React.createElement(component, itemProps);

        return (
          <div className={ styles.item } key={ ind }>
            { html }
          </div>
        );
      });
    } else {
      itemsHtml = this.renderEmpty();
    }

    return (
      <div className={ styles.inbox }>
        { itemsHtml }
      </div>
    );
  }
}

export default List;
