import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  Checkbox,
  COMPONENTS,
  Dialog,
  Select,
  MultiSelect,
  Loading,
} from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { LABEL_USER_SETTINGS_FIELDS, USER_SETTINGS_FIELDS } from '../../../../../bi/constants/userSettings';

import styles from '../../styles/account.module.scss';

const LABELS = {
  TITLE: 'Настройки пользователя',
  SAVE: 'Сохранить',
  DELETE_MAIL: 'Освободить почту',
  UNACTIVE_USER: 'Настройки доступны только активным пользователям',
  NO_SEND_INFO: 'не отправлять',
  ALL_TRIPS: 'по всем поездкам',
  ACQUIRED_TRIPS: 'по приобретенным поездкам',
  SELECT_COMPANY: 'выберите компании',
};

const SELECTED_FIELDS_VOUCHER = [
  { value: 'DisableVouchers', label: LABELS.NO_SEND_INFO },
  { value: 'AccountVouchers', label: LABELS.ALL_TRIPS },
  { value: 'PersonalVouchers', label: LABELS.ACQUIRED_TRIPS },
];

const SELECTED_FIELDS_INVOICES = [
  { value: 'DisableInvoices', label: LABELS.NO_SEND_INFO },
  { value: 'AccountInvoices', label: LABELS.ALL_TRIPS },
  { value: 'PersonalInvoices', label: LABELS.ACQUIRED_TRIPS },
];

const MULTI_SELECT_THEME = 'light';

const UserSettingsDialog = ({
  item,
  onSave,
  loading,
  onClose,
  onChange,
  companies,
  isActiveUser,
  userSettings,
  userCompanies,
  onChangeDisableUser,
  accessSettingsChange,
  onChangeArraySettings,
}) => {
  const {
    RegistrationName, IsPrimaryAccount, Id, Email,
  } = item;
  const checkEmail = Email.indexOf('-deleted') > 0;
  const adminSection = (sectionName) => sectionName === USER_SETTINGS_FIELDS.ADMIN_SETTINGS;
  const needLable = (value) => (value.length ? '' : LABELS.SELECT_COMPANY);

  const notificationMapper = companies.length > 1 && companies.reduce((acc, {
    ShortCompanyName, CompanyName, CompanyId,
  }) => {
    const newObject = ({ label: ShortCompanyName || CompanyName, value: CompanyId });

    if (userCompanies.includes(CompanyId) || item.IsPrimaryAccount) return ([...acc, newObject]);

    return acc;
  }, []);

  const companyNotificationHtml = (index, name, value, section) => (
    <div className={ `${styles.user_settings_row} ${styles.settings_company_notification}` } key={ index + name }>
      <div className={ `${styles.settings_name} ${styles.notification_row}` }>
        { LABEL_USER_SETTINGS_FIELDS[name] }
        <div className={ styles.settings_multi_select }>
          <MultiSelect
            label={ needLable(value) }
            items={ notificationMapper }
            values={ value }
            theme={ MULTI_SELECT_THEME }
            onChange={ (items) => onChangeArraySettings(section, name, items) }
          />
        </div>
      </div>
    </div>
  );

  const renderUserSettings = ([name, value], section, index) => {
    const invisibleAdminSettings = name === USER_SETTINGS_FIELDS.NO_CHANGE_OR_RETURN_TRIP
      || name === USER_SETTINGS_FIELDS.DEPARTMENT_ONLY;

    if (IsPrimaryAccount && invisibleAdminSettings) {
      return null;
    }

    if (name === USER_SETTINGS_FIELDS.USER_COMPANY_NOTIFICATION) {
      return value && companyNotificationHtml(index, name, value, section);
    }

    return LABEL_USER_SETTINGS_FIELDS[name] && (
      <div className={ styles.user_settings_row } key={ index + name }>
        <div>
          <Checkbox
            onChange={ () => onChange(section, name) }
            className={ styles.user_settings_check_box }
            disabled={ !accessSettingsChange }
            value={ value }
          />
        </div>
        <div className={ styles.settings_name }>{ LABEL_USER_SETTINGS_FIELDS[name] }</div>
      </div>
    );
  };

  const renderAdminSettings = ([name, value], section, index) => {
    const settingName = (name === USER_SETTINGS_FIELDS.INVOICE_SEND_TYPE && SELECTED_FIELDS_INVOICES)
      || (name === USER_SETTINGS_FIELDS.VOUCHER_SEND_TYPE && SELECTED_FIELDS_VOUCHER);

    return LABEL_USER_SETTINGS_FIELDS[name] && (
      <div className={ styles.admin_settings } key={ index + value }>
        { LABEL_USER_SETTINGS_FIELDS[name] }
        <Select
          items={ settingName }
          value={ userSettings[section][name] }
          theme='search'
          disabled={ !accessSettingsChange }
          className={ styles.admin_select }
          onChange={ (change) => onChangeArraySettings(section, name, change.value) }
        />
      </div>
    );
  };

  const deleteEmail = async () => {
    await onChangeDisableUser(Id);
    await onClose();
  };

  const isNotActiveUserHtml = (
    <div className={ styles.not_active_user }>
      { LABELS.UNACTIVE_USER }
    </div>
  );

  const deleteEmailHtml = !isActiveUser && (
    <Button
      label={ LABELS.DELETE_MAIL }
      className={ styles.disable_user_button }
      theme={ COMPONENTS.BUTTON.THEME.FLAT }
      disabled={ loading || checkEmail || !accessSettingsChange }
      onClick={ () => deleteEmail() }
    />
  );

  const mapperUserSettings = (value) => Object.entries(value);
  const mapperUserSettingsSection = (value) => Object.entries(value).slice(1);
  const checkItem = (settingsItems) => mapperUserSettings(settingsItems[1])
    .map((setting, index) => (adminSection(settingsItems[0])
      ? renderAdminSettings(setting, settingsItems[0], index)
      : renderUserSettings(setting, settingsItems[0], index)));

  const renderHtmlSection = (section) => (IsPrimaryAccount || !adminSection(section[0])) && (
    <div key={ section[0] }>
      <div>
        { ' ' }
        { LABELS.HIDE_SECTIONS }
        { ' ' }
      </div>
      <div className={ styles.user_settings_container }>
        { LABEL_USER_SETTINGS_FIELDS[section[0]] }
        { checkItem(section) }
      </div>
    </div>
  );

  const isNotification = (el) => (el[0] === USER_SETTINGS_FIELDS.NOTIFICATION ? companies.length > 1 : true);
  const emptyUserSettings = Object.keys(userSettings).length !== 0;
  const mapList = () => mapperUserSettingsSection(userSettings)
    .map((el) => isNotification(el) && renderHtmlSection(el));
  const checkUserSettings = emptyUserSettings ? mapList() : <Loading />;
  const checkActiveUser = isActiveUser ? checkUserSettings : isNotActiveUserHtml;

  return (
    <Dialog
      width='600px'
      onClick={ () => onClose() }
      style={ { padding: '20px', marginBottom: '80px' } }
    >
      <div className={ styles.user_settings_title }>
        { ' ' }
        { LABELS.TITLE }
        <b>
          { ` ${RegistrationName}` }
        </b>
      </div>
      <div className={ styles.user_settings }>
        { checkActiveUser }
      </div>
      <div className={ styles.dialog_buttons }>
        <div>
          { deleteEmailHtml }
        </div>
        <DecisionButtons
          className={ styles.user_settings_dialog_buttons }
          onSave={ () => onSave() }
          onCancel={ () => onClose() }
          labelSave={ LABELS.SAVE }
        />
      </div>
    </Dialog>
  );
};

UserSettingsDialog.propTypes = {
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  userSettings: PropTypes.object.isRequired,
  userCompanies: PropTypes.array.isRequired,
  isActiveUser: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
  accessSettingsChange: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeDisableUser: PropTypes.func.isRequired,
  onChangeArraySettings: PropTypes.func.isRequired,
};

export default UserSettingsDialog;
