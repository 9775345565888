const AGGREGATORS = {
  Petrolkard: 'Петролкард',
  ContourTravel: 'Контур Тревел',
  CKR: 'ЦКР',
  RusGidro: 'РусГидро',
  TempRosteh: 'ТЕМП-Ростех',
};

const AGGREGATORS_NAME = {
  19202: AGGREGATORS.Petrolkard,
  20431: AGGREGATORS.ContourTravel,
  35748: AGGREGATORS.CKR,
  38980: AGGREGATORS.RusGidro,
  40048: AGGREGATORS.TempRosteh,
};

export { AGGREGATORS_NAME, AGGREGATORS };
