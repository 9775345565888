import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../../../components/button';

import { TRIPSLABELS } from '../../../../../../bi/constants/trips';

import style from '../../../../styles/form.module.scss';

const LABELS = { COUNT_EMPLOYEES: 'Число сотрудников' };

const CountEmployeesFields = ({
  employeesListForPick,
  onIncreaseCountEmployees,
  onDecreaseCountEmployees,
}) => (
  <div className={ style['employee-panel'] }>
    <div className={ style['employee-panel-title'] }>
      { LABELS.COUNT_EMPLOYEES }
      { ' ' }
      :
      { employeesListForPick.length }
    </div>
    <FlatButton
      label={ TRIPSLABELS.INCREASE }
      disabled={ employeesListForPick.length > 4 }
      onClick={ () => onIncreaseCountEmployees(employeesListForPick.length) }
    />
    <FlatButton
      label={ TRIPSLABELS.DECREASE }
      disabled={ employeesListForPick.length < 2 }
      onClick={ onDecreaseCountEmployees }
    />
  </div>
);

CountEmployeesFields.propTypes = {
  employeesListForPick: PropTypes.array.isRequired,
  onIncreaseCountEmployees: PropTypes.func.isRequired,
  onDecreaseCountEmployees: PropTypes.func.isRequired,
};

export { CountEmployeesFields };
