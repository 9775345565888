import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Tooltip } from 'sw-ui';

import SearchReport from '../../../../components/SearchReport';
import NoResult from '../../../../components/NoResult';

import { formatDate } from '../../../../bi/utils/formatDate';

import { FORMATDATETIME, DEFAULTDATE } from '../../../../constants/time';
import COMPONENTS from '../../../../bi/constants/components';
import { FIELDLABELS, REPORTNAME } from '../../../../bi/constants/reports';

import styles from './index.module.scss';

const LABELS = {
  ABOARD_BTN: 'Сформировать',
  NOT_FOUND: 'Ничего не найдено',
  LINK: 'ссылка',
  DATE_SERVICE: 'Дата и время формирования услуги бронирования отеля',
  CHECKIN_DATE_GUEST: 'Дата и время предполагаемого заезда гостя',
  CHECKOUT_DATE_GUEST: 'Дата и время выезда гостя',
  PROVIDER_NAME: 'Название поставщика',
  TRIP_LINK: 'Ссылка на поездку',
  HOTEL_NOT_OK: 'Отель не ок, запрос поставщику',
  APPROVED: 'Подтверждено',
  NOT_APPROVED: 'Поставщик не подтвердил',
  RESERVATION_NUMBER: 'Номер брони поставщика',
  NOT_OK_TOOLTIP: 'Если отель не ответил на звонок, не смог дать нужную нам информацию, не подтвердил бронь, то пишем поставщику запрос на перепроверку и отмечаем бронь здесь',
  APPROVED_TOOLTIP: 'Если отель по звонку или поставщик подтвердил бронь, то отмечаем бронь здесь',
  NOT_APPROVED_TOOLTIP: 'Если поставщик не подтвердил бронь в переписке, то нужно отметить бронь здесь и создать чат с клиентом',
  SERVER_ERROR: 'Произошла ошибка',
};

class AboardReportPage extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
  };

  state = {
    list: [],
    title: '',
    error: false,
    errorMessage: '',
  };

  componentWillUnmount() {
    this.toggleShownReport([], true);
  }

  toggleError = (message) => this.setState({
    error: !this.state.error,
    errorMessage: message || LABELS.SERVER_ERROR,
  });

  toggleShownReport = (list, value = false) => this.setState({
    list,
    title: !list.length && !value ? LABELS.NOT_FOUND : '',
  });

  handleDownload = async (startDate, endDate) => {
    try {
      const {
        Success, Error, ReportData,
      } = await this.props.onDownload(startDate, endDate);

      if (!Success) {
        return this.toggleError(Error);
      }

      return this.toggleShownReport(ReportData);
    } catch (err) {
      this.toggleError();
    }

    return null;
  };

  handleChangeCheckBoxRequest = async (id, checkboxes) => {
    const { list } = this.state;
    const { onSend } = this.props;

    try {
      const newList = await onSend(id, checkboxes, list);

      if (!newList) return this.toggleError();

      this.setState({ list: newList });
    } catch (err) {
      this.toggleError();
    }

    return null;
  };

  handleChangeCheckbox = (field, fieldToDisable, id, value) => {
    const itemTrip = this.state.list.find((el) => el.id === id);

    const {
      id: tripItemId, isNotHotelOk, approved, notApproved,
    } = itemTrip;

    const checkboxes = {
      tripItemId,
      isNotHotelOk,
      approved,
      notApproved,
      [fieldToDisable]: false,
      [field]: value,
    };

    return this.handleChangeCheckBoxRequest(id, checkboxes);
  };

  renderItemHotel = ({
    id,
    date,
    checkinDate,
    checkoutDate,
    providerName,
    tripLink,
    approved,
    notApproved,
    isNotHotelOk,
    customCheckinDate,
    customCheckoutDate,
    reservationNumber,
  }, index) => {
    const checkIn = !customCheckinDate ? formatDate(checkinDate, DEFAULTDATE) : formatDate(checkinDate, FORMATDATETIME);
    const checkOut = !customCheckoutDate
      ? formatDate(checkoutDate, DEFAULTDATE)
      : formatDate(checkoutDate, FORMATDATETIME);

    return (
      <div className={ styles.row_item } key={ `collection_${id}_${index}` }>
        <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ formatDate(date, FORMATDATETIME) }</div>
        <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ checkIn }</div>
        <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ checkOut }</div>
        <div className={ `${styles.text_center} ${styles['col-1-13']}` }>{ reservationNumber }</div>
        <div className={ `${styles.text_center} ${styles['col-1-13']}` }>{ providerName }</div>
        <div className={ `${styles.text_center} ${styles['col-1-13']}` }>
          <a
            target='_blank'
            href={ tripLink }
            rel='noreferrer'
          >
            { LABELS.LINK }
          </a>
        </div>
        <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
          <Checkbox
            value={ isNotHotelOk }
            onChange={ (value) => (
              this.handleChangeCheckbox(FIELDLABELS.IS_NOT_HOTEL_OK, FIELDLABELS.IS_NOT_HOTEL_OK, id, value)) }
          />
        </div>
        <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
          <Checkbox
            value={ approved }
            onChange={ (value) => this.handleChangeCheckbox(FIELDLABELS.APPROVED, FIELDLABELS.NOT_APPROVED, id, value) }
          />
        </div>
        <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
          <Checkbox
            value={ notApproved }
            onChange={ (value) => this.handleChangeCheckbox(FIELDLABELS.NOT_APPROVED, FIELDLABELS.APPROVED, id, value) }
          />
        </div>
      </div>
    );
  };

  renderReport = () => {
    const { list } = this.state;

    if (!list.length) return null;

    const itemsHotel = list.map((item, index) => this.renderItemHotel(item, index));

    return (
      <div className={ styles.report_wrapper }>
        <div className={ styles.row }>
          <div className={ styles['col-1-10'] }>{ LABELS.DATE_SERVICE }</div>
          <div className={ styles['col-1-10'] }>{ LABELS.CHECKIN_DATE_GUEST }</div>
          <div className={ styles['col-1-10'] }>{ LABELS.CHECKOUT_DATE_GUEST }</div>
          <div className={ styles['col-1-13'] }>{ LABELS.RESERVATION_NUMBER }</div>
          <div className={ styles['col-1-13'] }>{ LABELS.PROVIDER_NAME }</div>
          <div className={ styles['col-1-13'] }>{ LABELS.TRIP_LINK }</div>
          <div className={ `sw-tooltip-wrapper ${styles['col-1-13']} ${styles.tooltip}` }>
            <span>{ LABELS.HOTEL_NOT_OK }</span>
            <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.TOP } styles={ { display: 'flex' } }>
              { LABELS.NOT_OK_TOOLTIP }
            </Tooltip>
          </div>
          <div className={ `sw-tooltip-wrapper ${styles['col-1-13']} ${styles.tooltip}` }>
            <span>{ LABELS.APPROVED }</span>
            <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.TOP } styles={ { display: 'flex' } }>
              { LABELS.APPROVED_TOOLTIP }
            </Tooltip>
          </div>
          <div className={ `sw-tooltip-wrapper ${styles['col-1-13']} ${styles.tooltip}` }>
            <span>{ LABELS.NOT_APPROVED }</span>
            <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.TOP } styles={ { display: 'flex' } }>
              { LABELS.NOT_APPROVED_TOOLTIP }
            </Tooltip>
          </div>
        </div>
        <div className={ styles.col }>
          { itemsHotel }
        </div>
      </div>
    );
  };

  renderErrorDialog = () => {
    const { error } = this.state;

    if (!error) return null;

    return (
      <NoResult
        message={ this.state.errorMessage }
        onClose={ this.toggleError }
      />
    );
  };

  render() {
    return (
      <div className={ styles.wrapper }>
        <SearchReport
          description={ REPORTNAME.ABOARD }
          labelButton={ LABELS.ABOARD_BTN }
          disabledButton
          onDownload={ this.handleDownload }
          renderOption={ this.renderReport() }
          nameList={ this.state.title }
        />
        { this.renderErrorDialog() }
      </div>
    );
  }
}

export { AboardReportPage };
