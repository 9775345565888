import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../bi/utils/formatDate';
import { FlatButton } from '../../../components/button';
import CircularLoaders from '../../../components/loaders';
import { TRAIN_TARIFFS } from '../../../bi/constants/train';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import { TicketWarnings } from './TicketWarnings';

import styles from '../styles/penalty.module.css';
import { FORMATDATETIME } from '../../../constants/time';

const LABELS = { WARNING: 'Обратите внимание, что при возврате будут отменены билеты на все места, купленные по спецтарифу:' };

class TrainCancelForm extends Component {
  static propTypes = {
    item: PropTypes.object,
    tripService: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    tickets: PropTypes.array,
    cancelLoading: PropTypes.bool,
  };

  static defaultProps = {
    item: null,
    tickets: [],
    cancelLoading: false,
  };

  constructor() {
    super();

    this.state = { loading: false };

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount = () => {
    const {
      CompanyId, TripItemId, ServiceType,
    } = this.props.item;
    const { tripService } = this.props;

    if (ServiceType === SERVICETYPE.TRAIN) {
      tripService.getCancelTripInfo(CompanyId, TripItemId);
    }
  };

  handleConfirm() {
    const { companyId, item } = this.props;
    const fn = (msg) => {
      this.handleCancel();
      this.props.onChange(msg);
    };

    this.setState({ loading: true }, () => {
      this.props.tripService.cancelTrainItem(companyId, item.OrderTripItemId).then((res) => {
        if (res.Result) {
          fn(`Заказ успешно отменен. Сумма возврата ${res.RefundSum}`);
        } else {
          fn(res.Error || 'Билет не был отменен');
        }
      })
        .catch(() => {
          fn('Во время операции произошла ошибка');
        });
    });
  }

  handleCancel() {
    this.props.onClose();
  }

  renderCoupeWarning = () => {
    const { item, tickets } = this.props;
    const data = JSON.parse(item.JsonData);

    if (
      item.ServiceType !== SERVICETYPE.TRAIN
      || tickets.length <= 1
      || (data.Tariff
        && data.Tariff !== TRAIN_TARIFFS.KUPEK)
    ) {
      return null;
    }

    return (
      <TicketWarnings
        text={ LABELS.WARNING }
        item={ item }
        tickets={ tickets }
      />
    );
  };

  renderLoading() {
    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <CircularLoaders />
      </div>
    );
  }

  render() {
    const { loading } = this.state;
    const { item, cancelLoading } = this.props;

    if (!item) return null;
    if (loading || cancelLoading) return this.renderLoading();

    const data = JSON.parse(item.JsonData);

    const employees = item.Employees.map((employee) => employee.GetShortRuName).join(', ');
    const info =
      `${data.StationDepart} - ${data.StationArrive} ${employees} ${formatDate(data.DateArrive, FORMATDATETIME)}`;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          Отмена ж/д билета
        </div>
        <div className={ styles.row }>
          Отменить ж/д билет
          { ' ' }
          { info }
          ?
        </div>
        { this.renderCoupeWarning() }
        <div className={ `${styles.row} ${styles.action}` }>
          <FlatButton label='Подтвердить' onClick={ this.handleConfirm } />
          <FlatButton label='Отмена' onClick={ this.handleCancel } />
        </div>
      </div>
    );
  }
}

export default TrainCancelForm;
