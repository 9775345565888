import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip } from 'sw-ui';

import styles from './styles/itemPanel.module.scss';

const ItemPanel = ({
  index,
  className,
  favorite,
  disabled,
  children,
  errors,
}) => {
  let disabledHtml = null;
  let disabledClass = '';

  if (disabled) {
    const errorsHtml = errors.map((error, ind) => (
      <div key={ `error_${ind}` } className={ styles.tooltip_item }>
        { error.charAt(0).toUpperCase() + error.slice(1) }
      </div>
    ));

    const errorsLength = errors.join().length > 101 && index === 0 ? 'left' : 'top';

    disabledHtml = (
      <Tooltip position={ errorsLength }>
        <div className={ styles.tooltip }>{ errorsHtml }</div>
      </Tooltip>
    );
    disabledClass = styles['disabled--sw-tooltip-wrapper'];
  }

  return (
    <div
      className={ `${styles.wrap} ${className} ${disabledClass}` }
    >
      { disabledHtml }
      <div className={ styles.wrap_item }>
        <div className={ `${styles.children} ${favorite ? styles.favorite : ''}` }>
          { children }
        </div>
      </div>
    </div>
  );
};

ItemPanel.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  favorite: PropTypes.bool,
  disabled: PropTypes.bool,
  errors: PropTypes.array,
};

ItemPanel.defaultProps = {
  className: '',
  index: null,
  favorite: false,
  disabled: false,
  children: null,
  errors: [],
};

export default ItemPanel;
