import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'sw-ui';

import RadioButtonsDialog from '../../../../../components/RadioButtonsDialog/index';
import { ACCOUNTTYPES, ACCOUNTTEXT } from '../../../../../bi/constants/account';
import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../styles/index.module.css';

const HEADER = 'Тип аккаунта:';
const WIDTH = '400px';

class AccountTypeDialog extends Component {
  static propTypes = {
    account: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  state = { showEditAccountTypeDialog: false };

  toggleEditAccountTypeDialog = () => (
    this.setState({ showEditAccountTypeDialog: !this.state.showEditAccountTypeDialog })
  );

  accountTypesName = () => {
    const { account: { AccountType } } = this.props;

    return (
      ACCOUNTTYPES.find(({ value }) => value === AccountType).label
    );
  };

  render() {
    const { account: { AccountType }, onSave } = this.props;
    const { showEditAccountTypeDialog } = this.state;

    const accountHtml = showEditAccountTypeDialog && (
      <RadioButtonsDialog
        buttonValue={ AccountType }
        header={ HEADER }
        width={ WIDTH }
        onClose={ this.toggleEditAccountTypeDialog }
        onSave={ onSave }
        list={ ACCOUNTTYPES }
      />
    );

    return (
      <div className={ styles.row }>
        <span className={ styles.name }>
          { ' ' }
          { HEADER }
          { ' ' }
        </span>
        <b className={ styles.value }>{ this.accountTypesName() }</b>
        <Button
          label={ ACCOUNTTEXT.EDIT }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.toggleEditAccountTypeDialog }
        />
        { accountHtml }
      </div>
    );
  }
}

export default AccountTypeDialog;
