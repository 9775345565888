import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  COMPONENTS, DatePicker, Dialog, Input,
} from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';
import AjaxButton from '../../../../../components/ajaxButton';

import { getEndDayOfYear } from '../../../../../bi/utils/formatDate';
import { changeCommaToThePoint } from '../../../../../bi/utils/trip';
import { formatDate } from '../../../../../utils/date';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import { FULLTIME } from '../../../../../constants/time';

import styles from '../styles/dialog.module.css';

const COMPONENTNAME = 'editTransactionDialogState';
const COMPONENTVALIDATIONNAME = 'editTransactionDialog';

const LABELS = {
  CHANGE: 'Изменение поступления',
  CHANGE_PLAN_FEE: 'Изменение списания абон. платы',
  DATE: 'Дата',
  SUMM: 'Сумма',
  DESCRIPTION: 'Описание',
};

class EditTransactionDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onSaveTransaction: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    validation: PropTypes.object.isRequired,
    transaction: PropTypes.object.isRequired,
    state: PropTypes.object.isRequired,
    onOpenApproveDialog: PropTypes.func.isRequired,
    onCheckClosingDocumentsForDeletion: PropTypes.func.isRequired,
    incomingOperationChange: PropTypes.bool.isRequired,
    waitingResponse: PropTypes.bool.isRequired,
    onOpenErrorTransaction: PropTypes.func.isRequired,
  };

  componentWillUnmount() {
    this.props.companyService.finance.resetValidation();
  }

  validateDate = (date) => this.props.companyService.finance.validation(COMPONENTVALIDATIONNAME, 'date', date);

  validateSumm = () => {
    const {
      transaction: { Filter: { IsPlanFee } },
      state: { amount, credit },
    } = this.props;

    const summ = IsPlanFee ? credit : amount;
    const field = IsPlanFee ? 'credit' : 'amount';

    return this.props.companyService.finance.validation(COMPONENTVALIDATIONNAME, field, summ);
  };

  validateDescription = (description) => this.props.companyService.finance.validation(COMPONENTVALIDATIONNAME, 'description', description);

  handleChangeDate = ({ value }) => {
    if (!this.props.state.date.isSame(value)) {
      this.props.companyService.finance.setComponentState(COMPONENTNAME, 'date', value);
    }
  };

  handleChangeAmount = (value) => this.props.companyService.finance.setComponentState(COMPONENTNAME, 'amount', changeCommaToThePoint(value));

  handleChangeCredit = (value) => this.props.companyService.finance.setComponentState(COMPONENTNAME, 'credit', changeCommaToThePoint(value));

  handleChangeDescription = (value) => this.props.companyService.finance.setComponentState(COMPONENTNAME, 'description', value);

  handleConfirm = () => {
    const {
      onSaveTransaction,
      transaction: { Filter: { IsPlanFee } },
      state: {
        date, amount, credit, description,
      },
      onOpenErrorTransaction,
    } = this.props;

    const summ = IsPlanFee ? credit : amount;

    const validSumm = this.validateSumm();
    const validDescr = this.validateDescription(description);
    const validDate = this.validateDate(date);

    if (validSumm && validDescr && validDate) {
      onSaveTransaction(date, summ, description, true)
        .catch((res) => {
          if (res.statusCode === 403) {
            onOpenErrorTransaction();
          }
        });
    }
  };

  renderBtnDelete = () => {
    const {
      incomingOperationChange,
      transaction: { Filter: { IsPlanFee } },
      onOpenApproveDialog,
      onCheckClosingDocumentsForDeletion,
      state: { type },
    } = this.props;

    if ((!type && incomingOperationChange) || IsPlanFee) {
      const deleteFunc = IsPlanFee
        ? onCheckClosingDocumentsForDeletion
        : onOpenApproveDialog;

      return (
        <div className={ styles.btn_delete }>
          <AjaxButton
            theme={ COMPONENTS.BUTTON.THEME.RED }
            label={ DECISIONBUTTONS.LABELS.DELETE }
            onClick={ deleteFunc }
          />
        </div>
      );
    }

    return null;
  };

  render() {
    const {
      onClose,
      validation,
      incomingOperationChange,
      transaction: { Filter: { IsPlanFee } },
      state: {
        date,
        amount,
        credit,
        description,
        type,
      },
      waitingResponse,
    } = this.props;

    const choiseValidation = IsPlanFee ? validation.credit : validation.amount;
    const choiseTitle = IsPlanFee ? LABELS.CHANGE_PLAN_FEE : LABELS.CHANGE;
    const choiseSumm = IsPlanFee ? credit : amount;

    const choiseChange = (value) => {
      if (IsPlanFee) return this.handleChangeCredit(value);

      return this.handleChangeAmount(value);
    };

    const disableIfOldData = () => {
      const {
        transaction: {
          Credit, Debit, Description, EventDate,
        },
      } = this.props;

      if (Credit === +credit
        && Debit === +amount
        && description === Description
        && formatDate(EventDate, FULLTIME) === formatDate(date, FULLTIME)
      ) return true;

      return false;
    };

    const disableSave = disableIfOldData();

    return (
      <Dialog onClick={ onClose } width='400px'>
        <div className={ styles.dialog }>
          <div className={ styles.header }>
            { choiseTitle }
          </div>
          <div className={ styles.row }>
            <div className={ styles.label }>{ LABELS.DATE }</div>
            <DatePicker
              type={ COMPONENTS.DATEPICKER.TYPE.DATE }
              value={ date }
              onChange={ (value) => this.handleChangeDate(value) }
              onBlur={ () => this.validateDate(date) }
              maxDate={ getEndDayOfYear() }
              disabled={ IsPlanFee }
            />
          </div>
          <div className={ styles.row }>
            <div className={ styles.label }>{ LABELS.SUMM }</div>
            <Input
              value={ choiseSumm }
              onChange={ choiseChange }
              onBlur={ this.validateSumm }
              error={ choiseValidation }
            />
          </div>
          <div className={ styles.row }>
            <div className={ styles.label }>{ LABELS.DESCRIPTION }</div>
            <Input
              value={ description }
              onChange={ this.handleChangeDescription }
              onBlur={ () => this.validateDescription(description) }
              error={ validation.description }
              readOnly={ (!!type || !incomingOperationChange) }
            />
          </div>
          <div className={ styles.actions }>
            { this.renderBtnDelete() }
            <DecisionButtons
              waitingResponse={ waitingResponse }
              onSave={ this.handleConfirm }
              onCancel={ onClose }
              disabled={ disableSave }
              labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export default EditTransactionDialog;
