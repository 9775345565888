import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MoneyFormat from '../../../bi/utils/money';
import { formatDate } from '../../../bi/utils/formatDate';

import { DEFAULTDATE } from '../../../constants/time';

import styles from '../index.module.css';

class TaxiResult extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    handleOpenCompany: PropTypes.func.isRequired,
  };

  handleOpenCompany = (e) => {
    const { item, handleOpenCompany } = this.props;

    e.stopPropagation();
    e.preventDefault();

    handleOpenCompany(item);
  };

  render() {
    const {
      item,
      onClick,
    } = this.props;

    const {
      tripId,
      employee,
      checkinDate,
      checkoutDate,
      company: { name },
      amount,
    } = item;

    return (
      <div className={ `${styles.row} ${styles.click}` } onClick={ () => onClick(item) }>
        <div className={ styles['col-1-16'] }>
          { tripId }
        </div>
        <div className={ styles['col-3-16'] }>
          <a className={ styles.link } onClick={ this.handleOpenCompany }>{ name }</a>
        </div>
        <div className={ styles['col-3-16'] }>
          { employee }
        </div>
        <div className={ styles['col-3-16'] }>
          { formatDate(checkinDate, DEFAULTDATE) }
          { ' ' }
          -
          { formatDate(checkoutDate, DEFAULTDATE) }
        </div>
        <div className={ styles['col-3-16'] }>
          { MoneyFormat.symbolWithMoneyWithDecimal(amount) }
        </div>
      </div>
    );
  }
}

export default TaxiResult;
