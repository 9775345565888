import renderComponents from './components';

const NAME = 'sw-cart-new';

class CartNewModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const appService = this.context.getService('App');
    const cartsAndNotesService = this.context.getService('CartsAndNotes');
    const companyService = this.context.getService('Company');
    const tripService = this.context.getService('Trip');
    const guidService = this.context.getService('Guid');
    const travelPolicyService = this.context.getService('TravelPolicy');

    this.page = renderComponents(this.box, {
      appService,
      cartsAndNotesService,
      companyService,
      tripService,
      guidService,
      travelPolicyService,
      moveToCart: this.moveToCart,
    });
  }

  goBack = () => {
    history.back();
  };

  moveToCart = () => {
    const {
      accountId, companyId, user,
    } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/user/${user}`;

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, CartNewModule);
}
