import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.css';

const Collapsible = ({
  children,
  title,
  wrapperClassname,
  childrenWrapperClassname,
  headerWrapperClassname,
  open = false,
}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const handleFilterOpening = () => setIsOpen((prev) => !prev);

  const renderChildren = isOpen ? children : null;

  const renderArrow = () => {
    const openClassName = isOpen ? styles.down : styles.up;

    return (
      <i className={ `material-icons ${styles.arrow} ${openClassName}` }>
        keyboard_arrow_down
      </i>
    );
  };

  const wrapperClassnames = [styles.wrapper];
  const childrenWrapperClassnames = [styles['children-wrapper']];
  const headerWrapperClassnames = [styles['header-wrapper']];

  if (wrapperClassname) {
    wrapperClassnames.push(wrapperClassname);
  }

  if (childrenWrapperClassname) {
    childrenWrapperClassnames.push(childrenWrapperClassname);
  }

  if (headerWrapperClassname) {
    headerWrapperClassnames.push(headerWrapperClassname);
  }

  return (
    <div className={ wrapperClassnames.join(' ') }>
      <div onClick={ handleFilterOpening } className={ headerWrapperClassnames.join(' ') }>
        { renderArrow() }
        { title }
      </div>
      <div className={ childrenWrapperClassnames.join(' ') }>
        <div>
          { renderChildren }
        </div>
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node.isRequired,
  wrapperClassname: PropTypes.string,
  childrenWrapperClassname: PropTypes.string,
  headerWrapperClassname: PropTypes.string,
  open: PropTypes.bool,
};

Collapsible.defaultProps = {
  children: null,
  wrapperClassname: '',
  childrenWrapperClassname: '',
  headerWrapperClassname: '',
  open: false,
};

export { Collapsible };
