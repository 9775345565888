const requiredField = (value) => Boolean(value.length);

const requiredNumber = (value) => value;

const validationObj = {
  Name: {
    fn: requiredField,
    msg: 'Введите имя',
  },
  Email: {
    fn: requiredField,
    msg: 'Введите Email',
  },
  Phone: {
    fn: requiredNumber,
    msg: 'Введите телефон',
  },
};

class UserValidator {
  isValid(field, value) {
    const validator = validationObj[field];
    const validationFn = validator.fn;
    let result = '';

    if (!validationFn(value)) {
      result = validator.msg;
    }

    return result;
  }
}

export default UserValidator;
