import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../components/button';
import CircularLoaders from '../../../components/loaders';

import styles from '../styles/penalty.module.css';

const LABELS = {
  CANCEL: (info) => `Отменить #${info} ?`,
  PENALTIES: (totalPrice) => `Общая сумма штрафа за ануляцию составит: ${totalPrice} руб.`,
  CONFIRM: 'Подтвердить',
  CANCELLATION: 'Отмена',
  ERROR: 'Возврат билета нельзя провести автоматически, следует обратится к поставщику',
};

class AeroexpressCancelForm extends Component {
  static propTypes = {
    item: PropTypes.object,
    tripService: PropTypes.object.isRequired,
    renderConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    accountId: PropTypes.number.isRequired,
  };

  static defaultProps = { item: null };

  constructor(props) {
    super();
    const {
      aeroExpressRefundSum: {
        refundSum,
        result,
        error,
      },
      aeroExpressFail,
    } = props.tripService.get();

    this.state = {
      loading: true,
      tripItemId: props.item.OrderTripItemId,
      aeroExpressRefundSum: {
        result,
        refundSum,
        error,
      },
      aeroExpressFail,
    };
  }

  componentDidMount() {
    const { tripService, accountId } = this.props;
    const { tripItemId } = this.state;

    this.unsubscribe = tripService.subscribe(this.updateState);

    tripService.getRefundSumAeroExpress(accountId, tripItemId);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = (state) => this.setState({ ...state });

  resultMessage = () => {
    const { renderConfirm } = this.props;
    const { aeroExpressRefundSum: { result }, aeroExpressFail } = this.state;

    if (result && !aeroExpressFail) renderConfirm();
  };

  handleConfirm = () => {
    const { accountId, tripService } = this.props;
    const { tripItemId } = this.state;

    this.setState({ loading: true }, () => {
      tripService.setCancellationAeroExpress(accountId, tripItemId).then(this.resultMessage);
    });
  };

  renderLoading = () => (
    <div className={ `${styles.form} ${styles.dialog}` }>
      <CircularLoaders />
    </div>
  );

  renderError = () => {
    const { aeroExpressRefundSum: { error } } = this.state;

    const errorMsg = error.length
      ? error
      : LABELS.ERROR;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row_error}` }>
          { errorMsg }
        </div>
      </div>
    );
  };

  render() {
    const {
      aeroExpressRefundSum: {
        result,
        refundSum,
      },
      loading,
      tripItemId,
      aeroExpressFail,
    } = this.state;
    const { onClose } = this.props;

    if (loading) return this.renderLoading();
    if (!result || aeroExpressFail) return this.renderError();

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ styles.row }>
          { LABELS.CANCEL(tripItemId) }
        </div>
        <div className={ styles.row }>
          { LABELS.PENALTIES(refundSum) }
        </div>
        <div className={ `${styles.row} ${styles.action}` }>
          <FlatButton label={ LABELS.CONFIRM } onClick={ this.handleConfirm } />
          <FlatButton label={ LABELS.CANCELLATION } onClick={ onClose } />
        </div>
      </div>
    );
  }
}

export default AeroexpressCancelForm;
