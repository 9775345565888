import {
  useState,
  useRef,
  useEffect,
} from 'react';
import { DotLoading, Input } from 'sw-ui';

import { LABELS } from '../consts';

import styles from '../styles.module.scss';

const LIMIT = 200;

interface IInputNameProps {
  value: string;
  onChange: (value: string) => void;
  loading?: boolean;
}

export const InputName = ({
  value, onChange, loading = false,
}: IInputNameProps) => {
  const [isEdit, setIsEdit] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const [errorMessage, setErrorMessage] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    }
  }, [isEdit]);

  const setNewValue = () => {
    if (!localValue) {
      return setErrorMessage(LABELS.ERRORS.EMPTY_NAME);
    }

    if (localValue.length > LIMIT) {
      return setErrorMessage(LABELS.ERRORS.SYMBOLS_LIMIT);
    }

    onChange(localValue);
    setIsEdit(false);
    setErrorMessage('');
  };

  const renderContent = () => (isEdit
    ? (
      <>
        <Input
          placeholder='file name'
          value={ localValue }
          onChange={ setLocalValue }
          className={ styles.inputNameInput }
          error={ errorMessage }
          ref={ inputRef }
        />
        <button
          className={ styles.btnIcon }
          onClick={ setNewValue }
        >
          <i className='material-icons'>check</i>
        </button>
        <button
          className={ styles.btnIcon }
          onClick={ () => setIsEdit(false) }
        >
          <i className='material-icons'>close_small</i>
        </button>
      </>
    )
    : (
      <>
        <div>
          { value }
        </div>
        <button
          className={ styles.btnIcon }
          onClick={ () => setIsEdit(true) }
        >
          <i className='material-icons'>mode_edit</i>
        </button>
      </>
    ));

  const renderWrap = loading ? () => <DotLoading /> : renderContent;

  return (
    <div className={ styles.inputName }>
      { renderWrap() }
    </div>
  );
};
