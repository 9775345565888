import React from 'react';
import PropTypes from 'prop-types';
import { Button, COMPONENTS } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';
import UploadForm from '../../../../../components/UploadForm';

import {
  REQUIR_EXTENSION_XLSX,
  UPLOAD_FORM,
} from '../../../../../bi/constants/profile';

import styles from '../styles/dialog.module.css';

const LABELS = {
  CANCEL: 'Отменить',
  UPLOAD: 'Обработать',
  DOWNLOAD_PATTERN: 'Скачать шаблон',
  PATTERN_INFO: 'Для загрузки необходимо использовать шаблон установленного образца',
  LOAD_FILE_TITLE: 'Выберите файл для загрузки сотрудников в компанию',
};

const LoadFile = ({
  title,
  saveLabel,
  file,
  uploaded,
  failed,
  error,
  disableButtons,
  messageHtml,
  downloadPattern,
  onFileAdded,
  onFileRemove,
  onCloseFile,
  onSaveFunc,
}) => {
  const uploadErrorMessage = failed && messageHtml(error);

  return (
    <div>
      <h3 className={ styles.title }>
        { title }
      </h3>
      <div className={ styles.pattern_msg }>
        { LABELS.PATTERN_INFO }
      </div>
      <UploadForm
        extensions={ REQUIR_EXTENSION_XLSX }
        onFileAdded={ onFileAdded }
        onFileRemoved={ onFileRemove }
        chooseFileLabel={ UPLOAD_FORM.CHOOSE_FILE }
        invalidExtension={ UPLOAD_FORM.INVALID_EXTENSION }
        description={ UPLOAD_FORM.DESCRIPTION }
        uploaded={ uploaded }
        file={ file }
        validationFunc={ onFileRemove }
        className={ styles.form }
      />
      { uploadErrorMessage }
      <div className={ styles.buttons }>
        <Button
          onClick={ downloadPattern }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          className={ styles.pattern_button }
        >
          { LABELS.DOWNLOAD_PATTERN }
        </Button>
        <DecisionButtons
          disabled={ disableButtons }
          onCancel={ onCloseFile }
          onSave={ onSaveFunc }
          labelCancel={ LABELS.CANCEL }
          labelSave={ saveLabel }
        />
      </div>
    </div>
  );
};

LoadFile.propTypes = {
  title: PropTypes.string.isRequired,
  saveLabel: PropTypes.string.isRequired,
  file: PropTypes.object,
  uploaded: PropTypes.bool.isRequired,
  failed: PropTypes.bool.isRequired,
  error: PropTypes.string,
  disableButtons: PropTypes.bool.isRequired,
  messageHtml: PropTypes.func.isRequired,
  downloadPattern: PropTypes.func.isRequired,
  onFileAdded: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  onCloseFile: PropTypes.func.isRequired,
  onSaveFunc: PropTypes.func.isRequired,
};

LoadFile.defaultProps = {
  file: undefined,
  error: null,
};

export default LoadFile;
