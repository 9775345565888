import Api from '../../api';

class Approval {
  getApprovalScheme = async (employeeId) => {
    try {
      const approvalScheme = await Api.Approval.getApprovalScheme(employeeId);

      return approvalScheme;
    } catch (e) {
      return null;
    }
  };

  getApproversForUser = async (employeeId) => {
    try {
      return await Api.Approval.getApproversForUser(employeeId);
    } catch (e) {
      return [];
    }
  };

  getAllApproversForUser = async (employeeId) => {
    try {
      const approvers = await Api.Approval.getAllApproversForUser(employeeId);

      return approvers;
    } catch (e) {
      return [];
    }
  };

  getEmployeesWithRight = async (employeeId) => {
    try {
      return await Api.Approval.getEmployeesWithRight(employeeId);
    } catch (e) {
      return [];
    }
  };

  getCreateReportsApproval = async (employeeId) => {
    try {
      return await Api.Approval.getCreateReportsApproval(employeeId);
    } catch (e) {
      return [];
    }
  };

  getApprovalSchemaER = async (userId) => {
    try {
      return await Api.Approval.getApprovalSchemaER(userId);
    } catch (e) {
      return [];
    }
  };
}

export default Approval;
