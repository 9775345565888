const CAR_TYPES_DESCRIPTION = [
  { name: 'Купе-переговорная', id: 0 },
  { name: 'Cидячий', id: 1 },
  { name: 'Плацкартный', id: 2 },
  { name: 'Семейный', id: 3 },
  { name: 'Второй класс', id: 4 },
  { name: 'Первый класс', id: 5 },
  { name: 'Сидячий', id: 6 },
  { name: 'СВ', id: 7 },
  { name: 'Эконом+', id: 8 },
  { name: 'Общий', id: 9 },
  { name: 'Бизнес класс', id: 10 },
  { name: 'Вагон-бистро', id: 11 },
  { name: 'бюджет', id: 12 },
  { name: 'Третий класс', id: 13 },
  { name: 'комфорт', id: 14 },
  { name: 'Базовый', id: 15 },
  { name: 'стандарт', id: 16 },
  { name: 'Эконом', id: 17 },
  { name: 'Купе', id: 18 },
  { name: 'Люкс', id: 19 },
];

const VALID_SEAT_TYPES_CAR = [
  { name: 'Купе', id: 18 },
  { name: 'Плацкартный', id: 2 },
];

const CLASSES_SERVICE = [
  { name: '2К', id: 0 },
  { name: '3Д', id: 1 },
  { name: '2Ю', id: 2 },
  { name: '1P', id: 3 },
  { name: '2Э', id: 4 },
  { name: '2C', id: 5 },
  { name: '2Т', id: 6 },
  { name: '2Х', id: 7 },
  { name: '1И', id: 8 },
  { name: '2Д', id: 9 },
  { name: '2С', id: 10 },
  { name: '1Э', id: 11 },
  { name: '2М', id: 12 },
  { name: '1А', id: 13 },
  { name: '1Р', id: 14 },
  { name: '3В', id: 15 },
  { name: '2E', id: 16 },
  { name: '1Н', id: 17 },
  { name: '1Т', id: 18 },
  { name: '2Л', id: 19 },
  { name: '1Д', id: 20 },
  { name: '1С', id: 21 },
  { name: '3П', id: 22 },
  { name: '3Б', id: 23 },
  { name: '3Ж', id: 24 },
  { name: '1Е', id: 25 },
  { name: '2У', id: 26 },
  { name: '3У', id: 27 },
  { name: '3О', id: 28 },
  { name: '2Ф', id: 29 },
  { name: '1Х', id: 30 },
  { name: '3Л', id: 31 },
  { name: '2Ж', id: 32 },
  { name: '1Ж', id: 33 },
  { name: '2Е', id: 34 },
  { name: '1У', id: 35 },
  { name: '1М', id: 36 },
  { name: '1Ф', id: 37 },
  { name: '1Г', id: 38 },
  { name: '1Л', id: 39 },
  { name: '3Э', id: 40 },
  { name: '3С', id: 41 },
  { name: '1П', id: 42 },
  { name: '1Б', id: 43 },
  { name: '3Т', id: 44 },
  { name: '2Р', id: 45 },
  { name: '1В', id: 46 },
  { name: '2Б', id: 47 },
  { name: '2В', id: 48 },
  { name: '2П', id: 49 },
  { name: '2А', id: 50 },
  { name: '2Ц', id: 51 },
  { name: '3Р', id: 52 },
  { name: '2Ш', id: 53 },
];

const CAR_TYPES = [
  { name: 'Премиум', id: 0 },
  { name: 'СВ', id: 1 },
  { name: 'Бизнес', id: 2 },
  { name: 'Эконом', id: 3 },
  { name: 'Купе', id: 4 },
  { name: 'Плацкарт', id: 5 },
  { name: 'Бизнес сидячий', id: 6 },
  { name: 'Купе-переговорная', id: 7 },
  { name: 'Эконом сидячий', id: 8 },
  { name: 'С-КОМФ Б/П', id: 9 },
  { name: 'Общий', id: 10 },
  { name: 'Салон', id: 11 },
  { name: 'Премьер', id: 12 },
  { name: 'СВ-КОМФ Б/П', id: 13 },
  { name: 'КУП-КОМФ Б/П', id: 14 },
  { name: 'ПЛАЦКАРТ', id: 15 },
  { name: 'Гр люкс', id: 16 },
  { name: '1 класс', id: 17 },
  { name: 'Купе Б / УКВ', id: 18 },
  { name: 'Стандарт', id: 19 },
  { name: 'Купе УКВ', id: 20 },
  { name: 'Плацкарт УКВ', id: 21 },
  { name: 'СНДБ / УКВ', id: 22 },
];

const TRAIN_ITEMS = [
  { value: 'None', label: 'Не выбрано' },
  { value: 'TeleTrain', label: 'Телетрейн (TeleTrain)' },
];

const SEAT_TYPES = [
  {
    label: 'Верхнее', value: 'Up', id: 0,
  },
  {
    label: 'Нижнее', value: 'Down', id: 1,
  },
  {
    label: 'Прочее', value: 'Undef', id: 2,
  },
];

export {
  CAR_TYPES_DESCRIPTION,
  CLASSES_SERVICE,
  CAR_TYPES,
  TRAIN_ITEMS,
  SEAT_TYPES,
  VALID_SEAT_TYPES_CAR,
};
