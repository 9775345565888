import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { formatDate } from '../../../../../bi/utils/formatDate';

import { InputId } from './InputId';

import { LOADING_STATUS } from '../../../../../bi/stores/trip';

import styles from './styles.module.css';

export const LABELS = {
  TITLE: 'Объединение поездок',
  DESCRIPTION: 'Введите id поездки которую хотите объединить с текущей:',
  INPUT: 'Укажите номер поездки',
  DATE_CREATED: (date) => `Дата создания: ${formatDate(date)}`,
  CANCEL: 'Отменить',
  CONFIRM: 'Подтвердить',
  MERGE_SUCCESS: 'Поездки успешно объединены',
  MERGE_ERROR: 'Ошибка. Проверьте возможность объединения поездок',
  MERGE_TRIPS_WARNING: 'Необходимо проверить общий бюджет командировки (при наличии) по каждой поездке!',
  ERROR: (tripId) => `Поездка с id ${tripId} не найдена в этой компании`,
};


export const MergeTrips = ({
  tripService = {},
  onClose = () => {},
  value = false,
  accountId,
  sourceTripId,
}) => {
  const [tripId, setTripId] = useState('');

  const state = tripService.store.getState();
  const {
    mergeDialogLoading, mergeTripsLoading, tripToMerge,
  } = state;

  useEffect(() => {
    if (tripId && tripId !== '') {
      tripService.findTripById(tripId);
    }
  }, [tripId]);

  useEffect(() => {
    if (mergeTripsLoading === LOADING_STATUS.LOADED) {
      tripService.updateTrip(accountId, sourceTripId);
    }
  }, [mergeTripsLoading]);

  const handleCloseModal = () => {
    onClose();
    setTripId('');
    tripService.clearMergeTripsStatuses();
  };

  if (!value) return null;

  return (
    <Dialog onClick={ handleCloseModal } showCloseButton>
      <div className={ styles.dialog }>
        <InputId
          setTripId={ setTripId }
          tripId={ tripId }
          loading={ mergeTripsLoading }
          fTripProps={ {
            tripId,
            loading: mergeDialogLoading,
            trip: tripToMerge,
            onClose,
            tripService,
          } }
        />
        <div className={ styles.warning }>
          { LABELS.MERGE_TRIPS_WARNING }
        </div>
      </div>
    </Dialog>
  );
};

MergeTrips.propTypes = {
  tripService: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  accountId: PropTypes.number.isRequired,
  sourceTripId: PropTypes.number.isRequired,
};
