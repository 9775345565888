import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Tooltip, RadioButton,
} from 'sw-ui';

import { FlatButton } from '../../../../components/button';
import { HotelItem } from '../HotelItem';
import { AllMatchesDialog } from '../AllMatchesDialog';

import COMPONENTS from '../../../../bi/constants/components';
import { FIELDS, PROVIDERS_VALUE } from '../../../../bi/constants/hotels';

import styles from './index.module.css';
import stylesSearch from '../../styles/search.module.scss';

const { TOOLTIP: { POSITION: { TOP } } } = COMPONENTS;

const LABELS = {
  CHECK_MATCHES: 'Проверить сопоставления',
  SHOW_ALL_MATCHES: 'Посмотреть все сопоставления',
  OPEN_DIALOG: 'Посмотреть найденные дубли',
  ADD_COUNTRY_CODE: 'Введите код страны',
  FOUND_MATCHES: 'Найдены дубли',
  BEFORE_SAVE: 'Перед сохранением ознакомьтесь и решите какой отель оставить.',
  SELECT_GIATA_ID: 'Выберите IdGiata',
  GIATA_ID: 'IdGiata:',
  NEED_COMPARE: 'Нужно проверить на сопоставление',
  ETC: 'и т.д.',
};

const GiataBlock = ({
  hotelsService,
  comparesAllMatches: {
    Exists,
    Hotels,
    NeedGiataId,
    GiataCompares,
  },
  idGiata,
  isChangeMatches,
  isHotelWithCompare,
  countryCode,
  showComparesDialog,
  mappingGiata,
}) => {
  const [showAllMatches, setShowAllMatches] = useState(false);

  const handleCheckMatches = async () => {
    await hotelsService.comparesCheck();
  };

  const handleSetChecksDialog = (value) => hotelsService.setComparesDialog(value);

  const handleShowAllMatches = (value) => {
    setShowAllMatches(value);
  };

  const handleGetAllMatchesById = async () => {
    await hotelsService.getHotelsByGiataIds([Number(idGiata)]).then(() => {
      handleShowAllMatches(true);
    });
  };

  const handleSelectGiataId = (value) => (
    hotelsService.changeField(value, `${FIELDS.MATCHING}.${PROVIDERS_VALUE.ID_GIATA}.${FIELDS.VALUE}`)
  );

  const renderButtonShowAllMatches = () => {
    if (!Number(idGiata)) return null;

    return (
      <FlatButton
        label={ LABELS.SHOW_ALL_MATCHES }
        onClick={ handleGetAllMatchesById }
      />
    );
  };

  const renderButtonCheckMatches = () => {
    if (!isChangeMatches) return null;

    const disabledCheck = !countryCode.value;

    const tooltipHtml = disabledCheck
      ? (
        <Tooltip position={ TOP }>
          { LABELS.ADD_COUNTRY_CODE }
        </Tooltip>
      )
      : null;

    const errorHtml = !isHotelWithCompare
      ? <div className={ styles.error }>{ LABELS.NEED_COMPARE }</div>
      : null;

    return (
      <div className='sw-tooltip-wrapper'>
        { tooltipHtml }
        <FlatButton
          label={ LABELS.CHECK_MATCHES }
          onClick={ handleCheckMatches }
          disabled={ disabledCheck }
        />
        { errorHtml }
      </div>
    );
  };

  const renderButtonShowFoundMatches = () => {
    if (!Exists) return null;

    return (
      <FlatButton
        label={ LABELS.OPEN_DIALOG }
        onClick={ () => handleSetChecksDialog(true) }
      />
    );
  };

  const renderSelectGiataId = () => {
    if (!NeedGiataId
      || (GiataCompares && Object.keys(GiataCompares).length < 2)) return null;

    const giataHtml = Object.keys(GiataCompares).map((giataId, idxGiata) => {
      const infoGiata = Object.keys(GiataCompares[giataId]).map((providerCode, idxCompare) => {
        const provider = GiataCompares[giataId][providerCode];

        const allIdsHtml = provider.slice(0, 3).map(({ Code, Inactive }, idxProvider) => {
          const styleByStatus = Inactive ? styles.inactive_id : styles.active_id;
          const separator = idxProvider !== provider.length - 1 ? ', ' : '';

          return (
            <span key={ `${Code}_${idxProvider}` } className={ styleByStatus }>
              { Code }
              { separator }
              { ' ' }

            </span>
          );
        });

        const etc = provider.length > 3 ? LABELS.ETC : '';

        return (
          <div key={ idxCompare } className={ styles.provider_info }>
            <b>
              { providerCode }
              :
              { ' ' }
            </b>
            { allIdsHtml }
            { ' ' }
            { etc }
          </div>
        );
      });

      return (
        <div key={ `${giataId}_${idxGiata}` } className={ styles.select_giata }>
          <RadioButton
            value={ giataId }
            checked={ idGiata === giataId }
            onChange={ () => handleSelectGiataId(giataId) }
          >
            { LABELS.GIATA_ID }
            { ' ' }
            { giataId }
          </RadioButton>
          { infoGiata }
        </div>
      );
    });

    return (
      <div className={ styles.block_giata_id }>
        <h4>{ LABELS.SELECT_GIATA_ID }</h4>
        <div className={ styles.giata_ids_select }>{ giataHtml }</div>
      </div>
    );
  };

  const renderDialogMatchesHotels = () => {
    if (!showComparesDialog || !Exists) return null;

    const hotelsHtml = Hotels.map((item) => (
      <HotelItem
        item={ item }
        hotelsService={ hotelsService }
        mappingGiata={ mappingGiata }
      />
    ));

    return (
      <Dialog
        onClick={ () => handleSetChecksDialog(false) }
      >
        <div className={ styles.dialog }>
          <ul className={ stylesSearch.list }>
            <h3>{ LABELS.FOUND_MATCHES }</h3>
            <span>{ LABELS.BEFORE_SAVE }</span>
            { hotelsHtml }
          </ul>
        </div>
      </Dialog>
    );
  };

  const renderDialogAllMatchesIds = () => {
    if (!showAllMatches || !mappingGiata) return null;

    return (
      <AllMatchesDialog
        onClick={ () => handleShowAllMatches(false) }
        mappingGiata={ mappingGiata }
      />
    );
  };

  return (
    <div className={ styles.wrap }>
      { renderSelectGiataId() }
      <div className={ styles.buttons }>
        { renderButtonCheckMatches() }
        { renderButtonShowFoundMatches() }
        { renderButtonShowAllMatches() }
      </div>
      { renderDialogMatchesHotels() }
      { renderDialogAllMatchesIds() }
    </div>
  );
};

GiataBlock.propTypes = {
  hotelsService: PropTypes.object.isRequired,
  comparesAllMatches: PropTypes.object.isRequired,
  idGiata: PropTypes.string.isRequired,
  isChangeMatches: PropTypes.bool.isRequired,
  isHotelWithCompare: PropTypes.bool.isRequired,
  countryCode: PropTypes.object.isRequired,
  showComparesDialog: PropTypes.bool.isRequired,
  mappingGiata: PropTypes.object,
};

GiataBlock.defaultProps = { mappingGiata: null };

export { GiataBlock };
