import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';
import DatePicker from 'react-datetime';
import { Checkbox } from 'sw-ui';

import { dateParseZone } from '../../../../../../bi/utils/formatDate';

import {
  DEFAULTDATE,
  DEFAULTTIME,
} from '../../../../../../constants/time';
import { TRIPSLABELS, FIELDS } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';

import Input from '../../../../../../components/input';
import { FlatButton } from '../../../../../../components/button';

import styles from '../../../../styles/form.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 40,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, .15)',
};

const LABELS = {
  PERIOD: 'Период',
  CANCELLATION_CONDITION: 'Условия отмены',
  DATE_CANCELLATION: 'Дата снятия брони без потерь',
  ADD: '+ добавить условие по штрафам',
  REMOVE: '- удалить условие',
};

const CANCELLATION_PENALTIES = { FROM: 'From' };

const GroupAccomodation = ({
  hotelName: {
    label,
    suggests,
  },
  address,
  comment,
  cancellationPenalties,
  freeCancellation,
  onChangeInput,
  onSelectSuggest,
  onChangeSuggest,
  onAddCancellation,
  onRemoveCancellation,
  onChangePenaltyInput,
}) => {
  const renderCancellationPenalties = (penalty, i) => {
    const {
      From, Total, Base, Additional,
    } = penalty;

    if (!freeCancellation) return null;

    const deleteButton = i !== 0
      ? (
        <div className={ styles['col-1-3'] }>
          <div className={ styles['add-conditions'] }>
            <FlatButton
              label={ LABELS.REMOVE }
              onClick={ () => onRemoveCancellation(i) }
            />
          </div>
        </div>
      )
      : null;

    const labelClassName = Additional ? styles.select_label : '';
    const valueAdditional = !!Additional || false;

    return (
      <div className={ styles['cancellation-penalties'] } key={ `cancellation-penalties_${i}` }>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-3'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              value={ From }
              onChange={ (value) => onChangePenaltyInput(CANCELLATION_PENALTIES.FROM, value, i, freeCancellation) }
              className='componentHook'
            />
            <label>С</label>
          </div>
          <div className={ styles['col-attention'] }>
            <Checkbox
              value={ valueAdditional }
              onChange={ (value) => onChangePenaltyInput(FIELDS.ADDITIONAL, value, i) }
            >
              <div className={ labelClassName }>{ TRIPSLABELS.ADDITIONAL }</div>
            </Checkbox>
          </div>
          { deleteButton }
        </div>
        <div className={ styles['cancellation-penalties-row'] }>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.TOTAL_PENALTY }
              value={ Total }
              label={ TRIPSLABELS.SUM }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, i) }
            />
          </div>
          <div className={ styles['col-1-3'] }>
            <Input
              field={ FIELDS.BASE_PENALTY }
              value={ Base }
              label={ TRIPSLABELS.SUM_BASE }
              type={ COMPONENTS.INPUT.TYPE.NUMBER }
              onChange={ (e, field, val) => onChangePenaltyInput(field, val, i) }
            />
          </div>
        </div>
      </div>
    );
  };

  const renderAddPenaltiesButton = () => {
    if (!freeCancellation) return null;

    return (
      <div className={ styles['add-conditions'] }>
        <FlatButton
          label={ LABELS.ADD }
          onClick={ onAddCancellation }
        />
      </div>
    );
  };

  const renderItem = ({
    Id, Name, FullName,
  }) => (
    <div key={ Id } className={ styles.autocomplete_item }>
      <div className={ styles.autocomplete_item_text }>{ Name }</div>
      <div className={ styles.autocomplete_item_label }>{ FullName }</div>
    </div>
  );

  const cancellationPenaltiesList = cancellationPenalties
    ? cancellationPenalties.map((penalty, i) => (
      renderCancellationPenalties(penalty, i)
    ))
    : onAddCancellation();

  const valueFreeCancellation = freeCancellation ? dateParseZone(freeCancellation) : freeCancellation;

  return (
    <div className={ styles.row }>
      <div className={ styles['col-1-4'] }>
        <label>{ TRIPSLABELS.SELECT_HOTEL }</label>
        <div className={ `${styles['auto-input']} ${styles.wrap}` }>
          <Autocomplete
            menuStyle={ MENUSTYLE }
            value={ label }
            items={ suggests }
            getItemValue={ (item) => item.Name }
            onSelect={ (value, hotel) => onSelectSuggest(hotel) }
            onChange={ (e, value) => onChangeSuggest(value) }
            renderItem={ (item) => renderItem(item) }
          />
        </div>
      </div>
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.ADDRESS }
          value={ address }
          label={ TRIPSLABELS.ADDRESS }
          onChange={ (e, field, value) => onChangeInput(FIELDS.ADDRESS, value) }
        />
      </div>
      <div className={ styles['col-1-4'] }>
        <Input
          field={ FIELDS.COMMENT }
          value={ comment }
          label={ TRIPSLABELS.COMMENT }
          onChange={ (e, field, value) => onChangeInput(FIELDS.COMMENT, value) }
        />
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <h4>{ LABELS.CANCELLATION_CONDITION }</h4>
        </div>
        <div className={ styles['col-1-3'] }>
          <h4>{ LABELS.PERIOD }</h4>
        </div>
      </div>
      <div className={ `${styles.row} ${styles.panel}` }>
        <div className={ styles['col-attention'] }>
          <div className={ styles.select }>
            <label>{ LABELS.DATE_CANCELLATION }</label>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              value={ valueFreeCancellation }
              onChange={ (value) => onChangeInput(FIELDS.FREE_CANCELLATION, value) }
              className='componentHook'
            />
          </div>
        </div>
        <div className={ styles['col-1-2'] }>{ cancellationPenaltiesList }</div>
        { renderAddPenaltiesButton() }
      </div>
    </div>
  );
};

GroupAccomodation.propTypes = {
  hotelName: PropTypes.object.isRequired,
  address: PropTypes.string.isRequired,
  comment: PropTypes.string.isRequired,
  cancellationPenalties: PropTypes.array.isRequired,
  freeCancellation: PropTypes.string.isRequired,
  onSelectSuggest: PropTypes.func.isRequired,
  onChangeSuggest: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onAddCancellation: PropTypes.func.isRequired,
  onRemoveCancellation: PropTypes.func.isRequired,
  onChangePenaltyInput: PropTypes.func.isRequired,
};

export { GroupAccomodation };
