import moment from 'moment';

import { ERRORSFORALL, ERRORSFORTRANSFER } from '../../../../bi/constants/tripValidator';
import { FIELDS } from '../../../../bi/constants/trips';
import { DEFAULTTIME } from '../../../../constants/time';

import {
  getMoment,
  isBeforeDateParameter,
  compareDateWithToday,
  isValidMomentObject,
  formatDataValue,
  isSameDateBySecond,
  isMoment,
} from '../../../../bi/utils/formatDate';
import { pickValue } from '../../../utils/trip';

const DATE_TIME_FIELDS = [
  FIELDS.CHECKIN_DATE,
  FIELDS.CHECKOUT_DATE,
  FIELDS.CHECKIN_DATE_EDIT,
  FIELDS.CHECKOUT_DATE_EDIT,
];

const DEFAULT_TIME = '00:00';

const requiredDate = (value) => moment.isMoment(value) && value.isValid();

const requiredField = (value) => Boolean(value.length);

const employees = (employee) => Boolean(employee.Id);

const employeesList = (list) => list.length > 0;

const checkinDateIsLessThanCheckoutDate = (value) => {
  const {
    CheckoutDate,
    CheckinDate,
    CheckinTime,
    CheckoutTime,
  } = value;

  if (isMoment(CheckinTime) && isMoment(CheckoutTime)) {
    const checkinDate = `${formatDataValue(CheckinDate)} ${isValidMomentObject(CheckinTime)
      ? formatDataValue(CheckinTime, DEFAULTTIME)
      : DEFAULT_TIME}`;
    const checkoutDate = `${formatDataValue(CheckoutDate)} ${isValidMomentObject(CheckoutTime)
      ? formatDataValue(CheckoutTime, DEFAULTTIME)
      : DEFAULT_TIME}`;
    const compareDatesWithToday = compareDateWithToday(checkinDate) >= compareDateWithToday(checkoutDate)
      || isSameDateBySecond(checkinDate, checkoutDate);

    return compareDatesWithToday;
  }

  return false;
};

const checkingDateLessThanToday = (value) => !isBeforeDateParameter(value.CheckinDate, getMoment());

const checkoutDateLessThanToday = (value) => !isBeforeDateParameter(value.CheckoutDate, getMoment());

const requiredProviderName = (value) => value && value.length > 0;

const requiredProvider = (value) => value && Object.keys(value).length > 0;

const requireNumber = (value) => value !== '';
const requireValue = (value) => !!value;

const validationObj = {
  CompanyId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  ProjectId: {
    fn: pickValue,
    msg: ERRORSFORALL.NOPROJECT,
  },
  DepartmentId: {
    fn: pickValue,
    msg: ERRORSFORALL.NODEPARTMENT,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  Employees: {
    fn: employees,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
  EmployeesList: {
    fn: employeesList,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
  CheckinDate: {
    fn: requiredDate,
    fn2: checkingDateLessThanToday,
    msg: ERRORSFORTRANSFER.NOCHECKINDATE,
    msg2: ERRORSFORTRANSFER.DATELESSTODAY,
  },
  CheckinDateEdit: {
    fn: requiredDate,
    fn2: checkingDateLessThanToday,
    msg: ERRORSFORTRANSFER.NOCHECKINDATE,
    msg2: ERRORSFORTRANSFER.DATELESSTODAYEDIT,
  },
  CheckinTime: {
    fn: requiredDate,
    msg: ERRORSFORTRANSFER.NOCHECKINTIME,
  },
  CancellationDate: {
    fn: requiredDate,
    msg: ERRORSFORTRANSFER.NOCANCELLATIONDATE,
  },
  CancellationTime: {
    fn: requiredDate,
    msg: ERRORSFORTRANSFER.NOCANCELLATIONTIME,
  },
  CheckoutDate: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORSFORTRANSFER.NOCHECKOUTDATE,
    msg2: ERRORSFORTRANSFER.DATELESSTODAY,
  },
  CheckoutDateLess: {
    fn: checkinDateIsLessThanCheckoutDate,
    msg: ERRORSFORTRANSFER.NOCHECKINDATEISLESS,
  },
  CheckoutDateEdit: {
    fn: requiredDate,
    fn2: checkoutDateLessThanToday,
    msg: ERRORSFORTRANSFER.NOCHECKOUTDATE,
    msg2: ERRORSFORTRANSFER.DATELESSTODAYEDIT,
  },
  CheckoutTime: {
    fn: requiredDate,
    msg: ERRORSFORTRANSFER.NOCHECKOUTTIME,
  },
  StartPlace: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NOSTARTPLACE,
  },
  EndPlace: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NOENDPLACE,
  },
  TrainFrom: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_TRAIN,
  },
  TrainTo: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_TRAIN,
  },
  AirportFrom: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_AIRPORT,
  },
  AirportTo: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_AIRPORT,
  },
  ArrivalCountry: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_ARRIVAL_COUNTRY,
  },
  ArrivalCity: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_ARRIVAL_CITY,
  },
  DepartureCountry: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_DESTINATION_COUNTRY,
  },
  DepartureCity: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_DESTINATION_CITY,
  },
  LocationAddress: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NOSTREETSTARTPLACE,
  },
  DestinationAddress: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NOSTREETENDPLACE,
  },
  DepartureNumber: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_DEPARTURE,
  },
  ArrivalNumber: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_ARRIVAL,
  },
  Base: {
    fn: requireNumber,
    msg: ERRORSFORTRANSFER.NO_EMPTY_FIELD,
  },
  Fee: {
    fn: requireNumber,
    msg: ERRORSFORTRANSFER.NO_EMPTY_FIELD,
  },
  Tax: {
    fn: requireNumber,
    msg: ERRORSFORTRANSFER.NO_EMPTY_FIELD,
  },
  Commission: {
    fn: requireNumber,
    msg: ERRORSFORTRANSFER.NO_EMPTY_FIELD,
  },
  Providers: {
    fn: requiredProvider,
    msg: ERRORSFORTRANSFER.NOPROVIDER,
  },
  ProviderName: {
    fn: requiredProviderName,
    msg: ERRORSFORTRANSFER.NOPROVIDER,
  },
  CarriageNumber: {
    fn: requireValue,
    msg: ERRORSFORTRANSFER.NO_CARRIAGE_NUMABER,
  },
};

class TransferValidator {
  isValid(field, value) {
    const part = field.split('.');
    let result = '';

    if (part.length === 1) {
      let validator;
      let validationFn;

      if (DATE_TIME_FIELDS.includes(field)
        && isValidMomentObject(value)
        && value !== '') {
        validator = validationObj[field];
        validationFn = validator.fn2;

        if (!validationFn(value)) {
          result = validator.msg2;
        }
      } else {
        validator = validationObj[field];
        validationFn = validator.fn;

        if (!validationFn(value)) {
          result = validator.msg;
        }
      }
    } else {
      const validator = validationObj[part[0]][part[1]];
      const validationFn = validator.fn;

      if (!validationFn(value)) {
        result = validator.msg;
      }
    }

    return result;
  }
}

export default TransferValidator;
