import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, Select, Button,
} from 'sw-ui';
import DatePicker from 'react-datetime';

import { updateCompaniesPlanFee } from '../../../../../bi/utils/account';

import COMPONENTS from '../../../../../bi/constants/components';
import { MONTHYEARPATTERN, LOCALERU } from '../../../../../constants/time';
import {
  defaultCompanyArrForPlanFee,
  FIELDS_PLAN_FEE_SETTINGS,
  REGULARITY_PAY,
  ACCOUNTTEXT,
} from '../../../../../bi/constants/account';

import Input from '../../../../../components/input';
import SelectWithInput from '../../../../../components/SelectWithInput';
import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../styles/index.module.css';

const WIDTH = '1200px';

const LABELS = {
  ENTITY: 'Юрлицо',
  SUBSCRIPTION_FEE: 'Абонплата',
  REGULARITY_OF_WRITEOFF: 'Регулярность списания',
  MONTH_OF_START: 'Месяц начала списания',
  UPDATED_AP: 'Обновленная ап',
  SELECT_ENTITY_SUM: 'Выберите юрлица и суммы абонплат',
};

class PlanFeeSettings extends Component {
  static propTypes = {
    companies: PropTypes.array.isRequired,
    planFeeSettings: PropTypes.array.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemovePlanFee: PropTypes.func.isRequired,
    onAddPlanFee: PropTypes.func.isRequired,
    tariffFlag: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super();

    const { companies } = props;

    const preparedCompanies = companies.map(({
      CompanyId, CompanyName, ShortCompanyName,
    }) => ({
      value: CompanyId,
      label: ShortCompanyName || CompanyName,
    }));

    this.state = {
      disabled: true,
      preparedCompanies,
    };
  }

  handleChangeField = ({ value }, field, index) => {
    this.props.onChange(value, field, index);

    this.setState({ disabled: false });
  };

  handleChangeDateUpdatedPlanFee = (field, value, index) => {
    this.props.onChange(value, field, index);
  };

  handleChangeUpdatedRate = (event, field, value, index) => {
    this.props.onChange(value, field, index);
  };

  handleChangeRate = (value, field, index) => {
    this.props.onChange(value, field, index);

    this.setState({ disabled: false });
  };

  renderAddButton = () => {
    const {
      planFeeSettings, companies, tariffFlag,
    } = this.props;

    return planFeeSettings.length !== companies.length && (
      <Button
        label={ ACCOUNTTEXT.ADD }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        disabled={ !tariffFlag }
        onClick={ this.props.onAddPlanFee }
        className={ `${styles.button_add_plan_fee} ${styles.button_plan_fee}` }
      />
    );
  };

  renderCompany = (currentCompanyId, index) => {
    const { preparedCompanies } = this.state;
    const { planFeeSettings, tariffFlag } = this.props;

    const selectItems = updateCompaniesPlanFee(preparedCompanies, planFeeSettings, currentCompanyId);
    selectItems.unshift(defaultCompanyArrForPlanFee());

    return (
      <Select
        className={ styles.plan_fee_select }
        disabled={ !tariffFlag }
        items={ selectItems }
        value={ currentCompanyId }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ (value) => this.handleChangeField(value, FIELDS_PLAN_FEE_SETTINGS.COMPANY_ID, index) }
      />
    );
  };

  renderSelectSubscriptionFee = (rate, rates, index) => {
    const { tariffFlag } = this.props;

    if (!tariffFlag) {
      return (
        <div
          className={ styles.tariff_text }
        >
          { rate.label }
        </div>
      );
    }

    return (
      <SelectWithInput
        items={ rates }
        currentItem={ rate }
        onChange={ (value) => this.handleChangeRate(value, FIELDS_PLAN_FEE_SETTINGS.CURRENT_RATE, index) }
      />
    );
  };

  renderSelectDateSubscriptionFee = (dateUpdated, index) => {
    const { tariffFlag } = this.props;

    if (!tariffFlag) {
      return (
        <Input
          disabled
          className={ styles['no-padding'] }
        />
      );
    }

    return (
      <div className={ styles.plan_fee_select }>
        <DatePicker
          dateFormat={ MONTHYEARPATTERN }
          timeFormat=''
          locale={ LOCALERU }
          value={ dateUpdated }
          onChange={ (value) => (
            this.handleChangeDateUpdatedPlanFee(FIELDS_PLAN_FEE_SETTINGS.DATE_UPDATED_PLAN_FEE, value, index)) }
          className={ styles['start-date'] }
        />
      </div>
    );
  };

  renderPlanFee = ({
    companyId, rates, currentRate, regularityPay, updatedRate, dateUpdatedPlanfee,
  }, index) => {
    const { onRemovePlanFee, tariffFlag } = this.props;
    const companies = this.renderCompany(companyId, index);
    const allowUpdateRate = !dateUpdatedPlanfee;

    return (
      <div className={ styles.plan_fee_wrap } key={ index }>
        <div className={ styles.plan_fee_col }>
          <div className={ styles.plan_fee_name }>{ LABELS.ENTITY }</div>
          <div>{ companies }</div>
        </div>
        <div className={ styles.plan_fee_col }>
          <div className={ styles.plan_fee_name }>{ LABELS.SUBSCRIPTION_FEE }</div>
          { this.renderSelectSubscriptionFee(currentRate, rates, index) }
        </div>
        <div className={ styles.plan_fee_col }>
          <div className={ styles.plan_fee_name }>
            { ' ' }
            { LABELS.REGULARITY_OF_WRITEOFF }
            { ' ' }
          </div>
          <div className={ styles.plan_fee_select }>
            <Select
              items={ REGULARITY_PAY }
              disabled={ !tariffFlag }
              value={ regularityPay }
              theme={ COMPONENTS.SELECT.THEME.BORDER }
              onChange={ (value) => this.handleChangeField(value, FIELDS_PLAN_FEE_SETTINGS.REGULARITY_PAY, index) }
            />
          </div>
        </div>
        <div className={ styles.plan_fee_col }>
          <div className={ styles.plan_fee_name }>
            { ' ' }
            { LABELS.MONTH_OF_START }
            { ' ' }
          </div>
          { this.renderSelectDateSubscriptionFee(dateUpdatedPlanfee, index) }
        </div>
        <div className={ styles.plan_fee_col }>
          <div className={ styles.plan_fee_name }>{ LABELS.UPDATED_AP }</div>
          <div className={ styles.plan_fee_select }>
            <Input
              field={ FIELDS_PLAN_FEE_SETTINGS.UPDATED_RATE }
              value={ updatedRate }
              onChange={ (event, field, value) => this.handleChangeUpdatedRate(event, field, value, index) }
              className={ styles['no-padding'] }
              disabled={ allowUpdateRate || !tariffFlag }
            />
          </div>
        </div>
        <Button
          label={ ACCOUNTTEXT.DELETE }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          disabled={ !tariffFlag }
          onClick={ () => onRemovePlanFee(index) }
          className={ `${styles.button_del_plan_fee} ${styles.button_plan_fee}` }
        />
      </div>
    );
  };

  render() {
    const {
      onSave, onClose, planFeeSettings, tariffFlag,
    } = this.props;

    return (
      <Dialog
        width={ WIDTH }
        onClick={ onClose }
      >
        <div className={ styles.dialog_plan_fee }>
          <div className={ styles.plan_fee_header }>
            <h3>
              { ' ' }
              { LABELS.SELECT_ENTITY_SUM }
              { ' ' }
            </h3>
          </div>
          { planFeeSettings.map(this.renderPlanFee) }
          { this.renderAddButton() }
          <DecisionButtons
            disabled={ !tariffFlag }
            loadingIncluded
            onSave={ () => onSave(planFeeSettings) }
            onCancel={ onClose }
          />
        </div>
      </Dialog>
    );
  }
}

export default PlanFeeSettings;
