import { useMemo } from 'react';

import { formatDate } from '../../../../../utils/date';


const SEX_EMOJI = {
  Man: '👨',
  Woman: '👩',
  DEFAULT: '🧑',
};

const LABELS = { NO_NOTES: 'Нет заметок' };

const useDisplayingProfileData = (profile = PROFILE, citizenshipEnums) => {
  const {
    fullName, dateOfBirth, citizenship, sex, email, phone, notes,
  } = profile;

  const humanCitizenship = useMemo(() => {
    const foundItem = citizenshipEnums.find(({ Code }) => Code === citizenship);

    return foundItem ? foundItem.Name : '';
  }, [citizenship, citizenshipEnums]);

  return {
    fullName,
    dateOfBirth: formatDate(dateOfBirth),
    sex: SEX_EMOJI[sex] || SEX_EMOJI.DEFAULT,
    citizenship: humanCitizenship,
    email,
    phone,
    notes: notes || LABELS.NO_NOTES,
  };
};

export default useDisplayingProfileData;
