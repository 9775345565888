import React from 'react';
import PropTypes from 'prop-types';

import Radio from '../../../../../../components/radio';

import MoneyFormat from '../../../../../../bi/utils/money';

import { LABELS_RATES, DESCRIPTIONS_RATES } from '../../../../../../bi/constants/insurance';

import styles from '../../../../styles/form.module.scss';

const LABELS = { SELECT_RATE: 'Выберите тариф:' };

const Rates = ({
  rates, selectedRate, onSelectRate, disabled,
}) => {
  const renderRates = () => rates.map(({ Rate, Price }, idx) => {
    const upperRateName = Rate.toUpperCase();
    const isChecked = selectedRate === Rate;
    const price = MoneyFormat.moneyWithDecimal(Price, true);

    return (
      <div className={ styles.rate } key={ idx }>
        <div className={ styles.rate_item }>
          <Radio
            label={ LABELS_RATES[upperRateName] }
            field={ Rate }
            checked={ isChecked }
            value={ Rate }
            onChange={ (field, value) => onSelectRate(value, Price) }
            disabled={ disabled }
          />
          <div className={ styles.price_rate }>{ price }</div>
        </div>
        <p>{ DESCRIPTIONS_RATES[upperRateName] }</p>
      </div>
    );
  });

  return (
    <div className={ `${styles.row} ${styles.rates}` }>
      <h4>{ LABELS.SELECT_RATE }</h4>
      <div className={ styles.rates_wrap }>
        { renderRates() }
      </div>
    </div>
  );
};

Rates.propTypes = {
  rates: PropTypes.array.isRequired,
  selectedRate: PropTypes.string.isRequired,
  onSelectRate: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

Rates.defaultProps = { disabled: false };

export { Rates };
