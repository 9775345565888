import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Circle from '../../../../components/Circle';
import { TypesServices } from './TypesServices';

import { combineOperations } from '../../../../bi/utils/documentsConstructor';

import styles from '../../styles/index.module.css';

const LABELS = {
  ACTS: 'Виды услуг в актах',
  INVOICES: 'Виды услуг с НДС 20% в счет-фактурах',
  ADDED: 'Добавлена',
  NOT_ADDED: 'Не добавлена',
};

const Services = ({
  documentsConstructorService,
  id,
}) => {
  const { Settings, NotAddedOperations } = documentsConstructorService.get()[id];
  const [settings, setSettings] = useState(Settings);

  const addedOperations = settings.flatMap(({ ServiceOperations }) => ServiceOperations);
  const serviceOperations = combineOperations(addedOperations, NotAddedOperations);

  const update = (data) => setSettings(data[id].Settings);

  useEffect(() => {
    const unSubscribe = documentsConstructorService.subscribe(update);

    return () => {
      if (unSubscribe) unSubscribe();
    };
  }, [documentsConstructorService]);

  const title = LABELS[id.toUpperCase()];

  return (
    <div className={ styles.types_services_wrap }>
      <div className={ styles.types_services }>
        <div className={ styles.title }>
          { title }
        </div>
        <div className={ styles.block }>
          <div className={ styles.icon }>
            <Circle added />
            { LABELS.ADDED }
          </div>
          <div className={ styles.icon }>
            <Circle added={ false } />
            { LABELS.NOT_ADDED }
          </div>
        </div>
      </div>
      <TypesServices serviceOperations={ serviceOperations } />
    </div>
  );
};

Services.propTypes = {
  documentsConstructorService: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export { Services };
