import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Loading } from 'sw-ui';

import EmployeesItem from './employeesItem';

import { ARCHIVED } from '../../../../bi/constants/company';

import styles from './styles/index.module.css';

const LABELS = {
  NON_STAFF: 'Нет сотрудников',
  ACTIVE_STAFF: 'Активные сотрудники:',
  ARCHIVE_STAFF: 'Архивные сотрудники:',
};

const AllEmployees = ({ employeeService, params }) => {
  const [allEmployee, setAllEmployee] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    employeeService.getAllEmployee(params.accountId)
      .then((response) => {
        setAllEmployee(response.res);
        setLoading(false);
      });
    const unsubscribeEmployeeServiceCb = employeeService.subscribe(() => {
      setAllEmployee(allEmployee);
      setLoading(false);
    });

    return () => {
      if (unsubscribeEmployeeServiceCb) unsubscribeEmployeeServiceCb();
    };
  }, [params.accountId, employeeService]);

  const employeesActive = allEmployee.filter(
    (item) => item.Status !== ARCHIVED,
  );
  const employeesArchived = allEmployee.filter(
    (item) => item.Status === ARCHIVED,
  );

  const renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  const renderEmptyList = () => (allEmployee.length === 0
    ? (
      <div className={ `empty-list ${styles.empty}` }>{ LABELS.NON_STAFF }</div>
    )
    : null);

  if (loading) {
    return (
      <div className={ styles.wrap_loader }>
        { renderLoading() }
      </div>
    );
  }

  return (
    <div className={ styles.wrap }>
      { renderEmptyList() }
      <EmployeesItem list={ employeesActive } labelStaff={ LABELS.ACTIVE_STAFF } />
      <EmployeesItem list={ employeesArchived } labelStaff={ LABELS.ARCHIVE_STAFF } />
    </div>
  );
};

AllEmployees.propTypes = {
  employeeService: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
};

export default AllEmployees;
