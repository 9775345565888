import {
  RATES,
  TYPERATRATES,
  NONERATE,
  OPTIONALRATE,
  MENU_STYLE,
  SCROLL_STYLE,
  ITEMS_VISIBLE_LENGTH,
} from '../constants/account';

const rates = RATES.map((item) => ({
  id: `${TYPERATRATES.ORIGINAL}_${item}`,
  type: TYPERATRATES.ORIGINAL,
  value: item,
  label: `${item}`,
}));

const preparedRates = () => {
  const copyRates = [...rates];
  copyRates.push(OPTIONALRATE);

  return copyRates;
};

const preparedRatesWithNoRate = () => {
  const copyRates = [...preparedRates()];
  copyRates.unshift(NONERATE);

  return copyRates;
};

const getDocumentsPackagesId = (packages, selectedPackages) => packages
  .filter(({ Id }) => selectedPackages[Id])
  .reduce((acc, { Documents }) => (Documents.length ? [...acc, Documents[0].DocumentPackageId] : acc), []);

const sortingAlphabetically = (value) => value.sort((prev, next) => {
  if (prev.label < next.label) return -1;
  if (prev.label > next.label) return 1;

  return 0;
});

const isMandatory = (mandatoryProject, id) => mandatoryProject.some((projectItem) => projectItem === id);

const updateCompaniesPlanFee = (preparedCompanies, planFeeSettings, currentCompanyId) => [
  ...preparedCompanies.filter(({ value }) => planFeeSettings.every(({ companyId }) => companyId !== value)),
  ...preparedCompanies.filter(({ value }) => value === currentCompanyId),
];

const getMappedAccountSettings = (data) => {
  const settings = {
    ...data,
    displayPersonalData: {
      ...data.displayPersonalData,
      ...data.displayPersonalData.displayTripsEmployee,
    },
    hideSections: {
      ...data.hideSections,
      ...data.hideSections.searchSettings,
    },
    settings: { ...data.settings },
    employeeRestriction: {
      ...data.employeeRestriction,
      ...data.accountEmployeeRestriction,
    },

  };

  delete settings.displayPersonalData.displayTripsEmployee;
  delete settings.hideSections.searchSettings;

  return settings;
};

const setMappedAccountSettings = (data) => {
  const {
    accountId,
    chatType,
    displayPersonalData: {
      cartEmployeeBirthday,
      cyrillicForeignerPassport,
      birthday,
      documents,
      fullName,
    },
    employeeRestriction: {
      departmentOnly,
      forceTravelPolicy,
      noChangeOrReturnTrip,
      hideTagsInCart,
      forceTripApproveComment,
      forceEmployeeNumber,
      requestsViewEqualsTrips,
      noPersonalBonusCards,
      deleteProject,
      hidePerDiem,
      hideAdditionalOrders,
      acquiringAccess,
    },
    settings: {
      lowestFareInformation,
      sendDebtNotificationInFiveDays,
      sendClosingsWithoutArchive,
      remindExpenseReport,
      sendSms,
    },
    hideSections: {
      noAnalytics,
      noBookingTaxi,
      aeroExpress,
      conference,
      noMice,
      groupCheckin,
      transfer,
      vipHall,
      noExpenseReport,
    },
    tripsSettings,
    settings1C,
  } = data;

  return {
    accountId,
    chatType,
    displayPersonalData: {
      cartEmployeeBirthday,
      cyrillicForeignerPassport,
      displayTripsEmployee: {
        birthday,
        documents,
        fullName,
      },
    },
    employeeRestriction: {
      departmentOnly,
      noChangeOrReturnTrip,
      hideTagsInCart,
      noPersonalBonusCards,
      deleteProject,
      hidePerDiem,
      hideAdditionalOrders,
      acquiringAccess,
    },
    hideSections: {
      noAnalytics,
      noBookingTaxi,
      searchSettings: {
        aeroExpress,
        conference,
        groupCheckin,
        transfer,
        vipHall,
      },
      noMice,
      noExpenseReport,
    },
    settings: {
      lowestFareInformation,
      sendDebtNotificationInFiveDays,
      sendClosingsWithoutArchive,
      remindExpenseReport,
      sendSms,
    },
    settings1C: {
      ...deleteProject,
      ...settings1C,
    },
    accountEmployeeRestriction: {
      forceTravelPolicy,
      forceTripApproveComment,
      forceEmployeeNumber,
      requestsViewEqualsTrips,
    },
    tripsSettings,
  };
};

const setSelectMenuStyle = (selectItems) => {
  if (selectItems.length >= ITEMS_VISIBLE_LENGTH) {
    return { ...MENU_STYLE, ...SCROLL_STYLE };
  }

  return MENU_STYLE;
};

export {
  preparedRates,
  preparedRatesWithNoRate,
  getDocumentsPackagesId,
  sortingAlphabetically,
  updateCompaniesPlanFee,
  getMappedAccountSettings,
  setMappedAccountSettings,
  isMandatory,
  setSelectMenuStyle,
};
