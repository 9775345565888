const FIELDS = {
  FORM_DATA: 'formData',
  VALIDATION: 'validation',
  IS_VALID_FORM: 'isValidForm',
  IS_NO_EDIT: 'isNoEdit',
  AMENITIES: 'formData.Amenities',
  AMENITIES_EN: 'formData.AmenitiesEn',
  DESCRIPTION: 'formData.Description',
  DESCRIPTION_EN: 'formData.DescriptionEn',
  COUNTRY_CODE: 'CountryCode',
  COUNTRY: 'Country',
  LATITUDE: 'Latitude',
  LONGITUDE: 'Longitude',
  TYPE: 'Type',
  TYPE_COHORT_AANDA: 'TypeCohortAanda',
  SMART_HOTEL: 'IsSmartHotel',
  THUMBNAIL: 'Thumbnail',
  SHORT_DESCRIPTION: 'ShortDescription',
  SHORT_DESCRIPTION_EN: 'ShortDescriptionEn',
  DISTANCE_FROM_CENTER: 'DistanceFromCenter',
  REGION_NAME: 'RegionName',
  REGION_ID: 'RegionId',
  CITY: 'City',
  CITY_EN: 'CityEn',
  STARS: 'Stars',
  RATING: 'Rating',
  VALUES: 'Values',
  NAME: 'Name',
  PRICE_GUARANTEE: 'PriceGuaranteeAanda',
  AANDA_GUARANTEE: 'AandaGuaranteeDisabled',
  SIMPLE_FIELDS: 'formData.simpleFields',
  REQUISITES: 'formData.Requisites',
  MATCHING: 'formData.matching',
  IMAGES: 'formData.Images',
  REQUEST_PAYLOAD: 'requestHotelsPayload',
  REGION_SUGGEST: 'regionSuggest',
  VALUE: 'value',
  SEARCH: {
    VALUE: 'search.value',
    SUGGEST: 'search.suggest',
  },
  ROOM_GROUPS: 'formData.RoomGroups',
  ROOM_GROUPS_FIELDS: {
    AMENITIES: 'Amenities',
    DESCRIPTION: 'Description',
    IMAGES: 'Images',
  },
  RATES: 'Rates',
  RATES_FIELDS: {
    RATE_NAME: 'RateName',
    GROUP_TYPE_ID: 'GroupTypeId',
    IMAGE_URL: 'ImageUrl',
    DESCRIPTION: 'Description',
    REFUND_TIME_LIMIT: 'RefundTimeLimit',
    CHARGE_VALUE: 'ChargeValue',
    CHARGE_VALUE_UNIT: 'ChargeValueUnit',
    CHARGE_TYPE: 'ChargeType',
  },
  PLANS: 'Plans',
  WORKS_WITH_VAT: 'WorksWithVat',
  PRICE_INCL_VAT: 'PriceInclVat',
  DISCOUNT: 'discount',
  EMAIL: 'Email',
  ADD_HOTEL: { HOTEL_ID: 'hoteiId' },
  HOTEL_CONTRACT: {
    DISCOUNT: 'Discount',
    EMAIL: 'Email',
  },
  EC_LC_CONDITIONS_FIELDS: {
    CHARGE_VALUE: 'ChargeValue',
    CHARGE_TYPE: 'ChargeType',
    CHARGE_TYPE_UNIT: 'ChargeTypeUnit',
    TIME_BORDER: 'TimeBorder',
  },
};

const TEXT = {
  NUMBER_OPTION: 'Опция № ',
  NUMBER_BLOCK: 'Заголовок № ',
  DELETE_OPTION: 'Удалить опцию',
  DELETE_BLOCK: 'Удалить блок',
  DELETE_ROOM: 'Удалить комнату',
  DELETE_PLAN: 'Удалить тариф',
  ADD_OPTION: 'Добавить опцию',
  ADD_BLOCK: 'Добавить блок',
  ADD_ROOM: 'Добавить комнату',
  ADD_RATE: 'Добавить рейт',
  ADD_PLAN: 'Добавить тариф',
  SELECT_PLAN: 'Выберите тариф',
  TITLE_AMENITIES: 'Amenities',
  TITLE_AMENITIES_EN: 'Amenities на английском',
  TITLE_DESCRIPTION: 'Описание',
  TITLE_DESCRIPTION_EN: 'Описание на английском',
  TITLE_ROOM_GROUPS: 'Комнаты',
  TITLE_TRAVELLINE: 'Travelline',
  HOTEL_CONTRACT: {
    TITLE: 'Прямой контракт',
    EMAIL: 'Email',
    DISCOUNT: 'Скидка по договору',
    ADD_CONTRACT: 'Добавить контракт',
    DELETE_CONTRACT: 'Удалить контракт',
    SUB_WORKS_WITH_VAT: 'Отель работает с НДС',
  },
  EC_LC_CONDITIONS: {
    TITLE: 'РЗПВ',
    EC: 'РЗ',
    LC: 'ПВ',
    TIME_BORDER_EC: 'Время РЗ',
    TIME_BORDER_LC: 'Время ПВ',
    ADD_CONDITIONS: 'Добавить РЗПВ',
    DELETE_CONDITIONS: 'Удалить РЗПВ',
  },
  SUB_TITLE_RATES: 'Категории номеров',
  SUB_TITLE_PLANS: 'Тарифы',
  SUB_WORKS_WITH_VAT: 'Отель работает с НДС',
  SUB_PRICE_INCL_VAT: 'Цены отеля включают НДС',
  DISCOUNT_UNDER_CONTRACT: 'Скидка по договору',
  BAR: 'Отель присылает BAR',
  RATE: {
    RATE_NAME: 'Название рейта',
    GROUP_TYPE_ID: 'Категория номера',
    DESCRIPTION: 'Описание номера',
    REFUND_TIME_LIMIT: 'Дата бесплатной отмены',
    CHARGE_VALUE: 'Далее штраф',
  },
  NAME_ROOM: 'Имя комнаты',
  DESCRIPTION: 'Описание',
  TITLE_SIMPLE_BLOCK: 'Основная Информация',
  TITLE_REQUISITES: ' Платежные реквизиты',
  INPUT_URL_VALID: 'Введите валидный Url',
  INPUT_URL_IMG: 'Введите Url изображения',
  ADD_IMG: 'Добавить Изображение',
  TITLE_IMAGES: 'Изображения',
  TITLE_MATCHING: 'Matching',
  EDIT_INFO: 'Для редактирования нажмите соответствующую кнопку внизу страницы',
  BUTTONS: {
    DELETE: 'Удалить из дампа',
    RESTORE: 'Восстановить',
    EDIT: 'Редактировать',
    SAVE: 'Сохранить',
    ADD: 'Добавить отель',
    YES: 'Да',
    LOAD: 'Загрузить данные отеля А&A',
  },
  TRAVELLINE_ACTIVE_TITLE: 'Travelline активирован',
  ACTIVATED: 'Отель подключен к Travelline',
  HIDE_RATES: 'Отключить выдачу',
};

const ANCHORS = {
  SIMPLE_BLOCK: 'StaticSimpleFields',
  REQUISITES: 'Requisites',
  MATCHING: 'Matching',
  AMENITIES: 'Amenities',
  AMENITIES_EN: 'AmenitiesEn',
  DESCRIPTION: 'Description',
  DESCRIPTION_EN: 'DescriptionEn',
  IMAGES: 'Images',
  ROOM_GROUPS: 'roomGroups',
  TRAVELLINE: 'Travelline',
  CONTRACT: 'Contract',
  EC_LC_CONDITIONS: 'EcLcConditions',
};

const HOTEL_TYPES = [
  { name: 'Mini-hotel', id: 0 },
  { name: 'Villas_and_bungalows', id: 1 },
  { name: 'Sanatorium', id: 2 },
  { name: 'Camping', id: 3 },
  { name: 'Resort-Hotel', id: 4 },
  { name: 'Boutique_and_design', id: 5 },
  { name: 'Apartment', id: 6 },
  { name: 'Aparthotel', id: 7 },
  { name: 'Guesthouse', id: 8 },
  { name: 'Hostel', id: 9 },
  { name: 'Cottages_and_houses', id: 10 },
  { name: 'Farm', id: 11 },
  { name: 'Bed_and_breakfast', id: 12 },
  { name: 'Castle', id: 13 },
  { name: 'Bnb', id: 14 },
  { name: 'Hotel', id: 15 },
];

const CHARGE_VALUE_UNITS = [
  { name: 'руб.', id: 1 },
  { name: '%', id: 2 },
];

const CHARGE_TYPES = [
  { name: 'от стоимости 1 ночи', id: 1 },
  { name: 'от стоимости бронирования', id: 2 },
];

const CHARGE_VALUE_UNITS_EC_LC_CONDITIONS = [
  { name: 'руб.', id: 0 },
  { name: '% от стоимости ночи', id: 1 },
];

const CHARGE_TYPES_EC_LC_CONDITIONS = [
  { name: 'за час', id: 0 },
  { name: 'за всю услугу', id: 1 },
];

const VAT_VALUE = {
  YES: 'Да',
  NO: 'Нет',
};

const LOCALE = {
  RU: 'ru',
  EN: 'en',
};

const DEFAULT_EC_LC_CONDITIONS = {
  0: {
    Type: 0,
    TimeBorder: null,
    ChargeValue: '',
    ChargeType: 0,
    ChargeTypeUnit: 0,
  },
  1: {
    Type: 1,
    TimeBorder: null,
    ChargeValue: '',
    ChargeType: 0,
    ChargeTypeUnit: 0,
  },
};

const DEFAULT_CONDITIONS_VALIDATION = {
  0: {
    ChargeValue: '',
    TimeBorder: '',
  },
  1: {
    ChargeValue: '',
    TimeBorder: '',
  },
};

const FIELDS_TYPES = {
  INPUT: 'input',
  SELECT: 'select',
  IMG: 'img',
  CLOCK: 'clock',
  CHECKBOX: 'checkbox',
  AUTOCOMPLETE: 'autocomplete',
  TEXT_AREA: 'textArea',
};

const DEFAULT_PLANS = [
  {
    id: 1,
    name: 'Невозвратный без завтрака',
  },
  {
    id: 2,
    name: 'Невозвратный с завтраком',
  },
  {
    id: 3,
    name: 'Возвратный без завтрака',
  },
  {
    id: 4,
    name: 'Возвратный с завтраком',
  },
];

const PROVIDERS = [
  { label: 'Островок', value: 'ostrovok' },
  { label: 'Броневик', value: 'bronevik' },
  { label: 'Броневик онлайн', value: 'bronevik-online' },
  { label: 'Академсервис', value: 'academ' },
  { label: 'Expedia', value: 'expedia' },
  { label: 'Direct', value: 'direct' },
  { label: 'A&A', value: 'aanda' },
  { label: 'hotelbook', value: 'hotelbook' },
  { label: 'Booking.com', value: 'booking' },
  { label: 'Альпака', value: 'alpaka' },
  { label: 'TravelLine', value: 'travelline' },
  { label: 'Contract', value: 'contract' },
  { label: 'Sawady3D', value: 'sawady3d' },
  { label: 'Alpaka3D', value: 'alpaka3d' },
  { label: 'Verdeho3D', value: 'verdeho3d' },
  { label: 'Verdeho', value: 'verdeho' },
  { label: 'Satguru', value: 'satguru' },
  { label: 'Alvist', value: 'alvist' },
  { label: 'Мой майс', value: 'MyMice' },
  { label: 'Мой майс АГЕНТ', value: 'MyMiceAGENT' },
];

const VATVALUE = {
  WITH: 'with',
  WITHOUT: 'without',
  NO_DATA: 'noData',
};

const VAT_VALUE_BY_VAT_BLOCK = {
  VAT20: '20',
  VAT7: '7',
  VAT5: '5',
  VAT0: '0',
  WITHOUT: 'without',
};

const MILLISECONDS_IN_DAY = 86400000;

const VAT_ERROR_STATUS = {
  SUCCESS: 'Success',
  VERSION_NOT_FOUND: 'VersionNotFound',
  OPERATION_NOT_FOUND: 'OperationNotFound',
  VERIFICATION_FAILED: 'VerificationFailed',
  ALREADY_EXISTS: 'AlreadyExists',
  NO_PREVIOUS_VAT: 'NoPreviousVat',
  SAVING_FAILED: 'SavingFailed',
  UNHANDLED_CASE: 'UnhandledCase',
};

const VAT_ERROR_STATUS_TEXT = {
  [VAT_ERROR_STATUS.SUCCESS]: 'НДС успешно обновлен',
  [VAT_ERROR_STATUS.ALREADY_EXISTS]: 'Для этой версии уже есть НДС',
  [VAT_ERROR_STATUS.VERSION_NOT_FOUND]: 'Не найдены версии услуги',
  [VAT_ERROR_STATUS.OPERATION_NOT_FOUND]: 'Не найдены фин. операции по услуге/версии',
  [VAT_ERROR_STATUS.VERIFICATION_FAILED]: 'Указанная сумма не прошла проверку. Возможно сумма НДС больше максимально возможного значения',
  [VAT_ERROR_STATUS.NO_PREVIOUS_VAT]: 'У предыдущих версий не указан НДС. Сначала необходимо указать НДС в предыдущих версиях заказа',
  [VAT_ERROR_STATUS.SAVING_FAILED]: 'Ошибка сохранения НДС',
  [VAT_ERROR_STATUS.UNHANDLED_CASE]: 'Невозможно внести НДС в версию возврата после штрафа',
  DEFAULT: 'НДС не удалось сохранить',
  FAILED: 'Не удалось выполнить операцию',
};

const MEAL_FIELDS = [
  {
    label: 'Завтрак включен',
    value: 1,
  },
  {
    label: 'Обед включен',
    value: 6,
  },
  {
    label: 'Ужин включен',
    value: 8,
  },
  {
    label: 'Обед или ужин включен',
    value: 4,
  },
];

const MEAL_LIST_TYPE = [
  {
    label: 'завтрак',
    list: [1],
    value: 1,
  },
  {
    label: 'завтрак и ужин',
    list: [1, 8],
    value: 2,
  },
  {
    label: 'завтрак и обед',
    list: [1, 6],
    value: 3,
  },
  {
    label: 'завтрак + обед или ужин',
    list: [1, 4],
    value: 4,
  },
  {
    label: 'завтрак, обед и ужин',
    list: [
      1,
      6,
      8,
    ],
    value: 5,
  },
  {
    label: 'обед',
    list: [6],
    value: 6,
  },
  {
    label: 'обед и ужин',
    list: [6, 8],
    value: 7,
  },
  {
    label: 'ужин',
    list: [8],
    value: 8,
  },
  {
    label: 'без питания',
    list: [],
    value: 0,
  },
];

const NO_MATCHES = 'Заполните хотя бы одно поле';

const DEFAULT_MATCH_HOTEL_IDS = {
  IdOstrovok: '',
  IdBronevik: 0,
  IdAanda: 0,
  IdAcadem: 0,
  IdExpedia: '',
  IdTripcom: 0,
};

const DEFAULT_MATCHES_HOTELS = {
  Exists: false,
  Hotels: [],
};

const PROVIDERS_VALUE = { ID_GIATA: 'IdGiata' };

export {
  FIELDS,
  TEXT,
  ANCHORS,
  HOTEL_TYPES,
  CHARGE_VALUE_UNITS,
  CHARGE_TYPES,
  VAT_VALUE,
  LOCALE,
  CHARGE_VALUE_UNITS_EC_LC_CONDITIONS,
  CHARGE_TYPES_EC_LC_CONDITIONS,
  DEFAULT_EC_LC_CONDITIONS,
  DEFAULT_CONDITIONS_VALIDATION,
  FIELDS_TYPES,
  DEFAULT_PLANS,
  PROVIDERS,
  VATVALUE,
  MILLISECONDS_IN_DAY,
  VAT_ERROR_STATUS,
  VAT_ERROR_STATUS_TEXT,
  VAT_VALUE_BY_VAT_BLOCK,
  MEAL_FIELDS,
  MEAL_LIST_TYPE,
  NO_MATCHES,
  DEFAULT_MATCH_HOTEL_IDS,
  DEFAULT_MATCHES_HOTELS,
  PROVIDERS_VALUE,
};
