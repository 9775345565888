import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './auth.module.css';

import ModalDialog from '../dialog';
import CircularLoaders from '../loaders';

class Signout extends Component {
  static propTypes = { signout: PropTypes.func.isRequired };

  state = { loading: false };

  handleSignout = (e) => {
    this.setState({ loading: true });
    this.props.signout(e);
  };

  render() {
    const { loading } = this.state;

    return (
      <div className={ styles.signout }>
        <a onClick={ this.handleSignout }>
          <div className={ styles.icon }>
            <i className='material-icons'>power_settings_new</i>
          </div>
          <div className={ styles.text }>
            Выйти
          </div>
        </a>
        <ModalDialog show={ loading }>
          <CircularLoaders />
        </ModalDialog>
      </div>
    );
  }
}

export default Signout;
