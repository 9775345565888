import Store from '../../store/store';
import { getDefaultMoment } from '../../utils/formatDate';
import lodashReplaces from '../../utils/lodashReplaces';

let ACTIONS;

const FILE_UPLOAD_FAILED = 'Не удалось загрузить файл.';

const defaultStore = {
  loading: false,
  tickets: [],
  JsonData: {
    EmployeeIds: [],
    ServiceType: 'Train',
    TicketId: '',
    OrderId: '',
    SaleOrderId: '',
    PassportId: '',
    ProviderComission: 0,
    ProviderName: '',
    Route: {
      StationDepart: '',
      StationDepartCode: '',
      StationArrive: '',
      StationArriveCode: '',
      DateDepartureLocal: getDefaultMoment(),
      TimeDepartureLocal: getDefaultMoment(),
      DateArriveLocal: getDefaultMoment(),
      TimeArriveLocal: getDefaultMoment(),
      DateDeparture: getDefaultMoment(),
      TimeDeparture: getDefaultMoment(),
      DateArrive: getDefaultMoment(),
      TimeArrive: getDefaultMoment(),
      TrainNumber: '',
      CarNumber: '',
      PlaceNumber: '',
      CarTypeDescription: '',
      CarType: '',
      ClassService: '',
      SeatType: '',
    },

    TrainPricing: {
      TariffPrice: 0,
      Fare: 0,
      Cost: 0,
      ServiceTva: 0,
      ServiceTvaRate: 20,
    },

    PriceDetails: {
      Commission: 0,
      TariffPrice: 0,
    },

    CarrierDetails: {
      Carrier: '',
      CarrierFull: '',
      CarrierInn: '',
    },

    guid: '',

    TransitDocumentType: '',
  },

  stations: {
    from: {
      label: '',
      suggests: [],
    },
    to: {
      label: '',
      suggests: [],
    },
  },

  Description: '',

  validation: {
    Employees: '',
    CompanyId: '',
    ProjectId: '',
    TicketId: '',
    DepartmentId: '',
    ProviderName: '',
    ProviderComission: '',
    Route: {
      DateDepartureLocal: '',
      TimeDepartureLocal: '',
      DateArriveLocal: '',
      TimeArriveLocal: '',
      StationDepart: '',
      StationDepartCode: '',
      StationArrive: '',
      StationArriveCode: '',
      DateDeparture: '',
      TimeDeparture: '',
      DateArrive: '',
      TimeArrive: '',
    },

    TrainPricing: {
      TariffPrice: '',
      Fare: '',
      Cost: '',
      ServiceTva: '',
      ServiceTvaRate: '',
    },

    PriceDetails: { Commission: '' },

    CarrierDetails: {
      Carrier: '',
      CarrierFull: '',
      CarrierInn: '',
    },

    guid: '',
    Description: '',

    TransitDocumentType: '',
  },

  file: {
    uploaded: false,
    failed: false,
    error: '',
  },
  carriers: [],
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.TRIP_TRAIN.CHANGE_FIELD:
      return { ...action.updatedState };

    case ACTIONS.TRIP_TRAIN.UPLOAD_FILE: {
      return {
        ...state,
        file: {
          ...state.file,
          File: action.file,
          uploaded: true,
          failed: false,
        },
        JsonData: {
          ...state.JsonData,
          guid: action.guid,
        },
      };
    }

    case ACTIONS.TRIP_TRAIN.UPDATE_CANCEL_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case ACTIONS.TRIP_TRAIN.GET_CANCEL_TRIP_INFO_FAIL: {
      return {
        ...state,
        tickets: state.tickets,
      };
    }

    case ACTIONS.TRIP_TRAIN.GET_CANCEL_TRIP_INFO: {
      const { tickets } = action;

      return {
        ...state,
        tickets,
      };
    }

    case ACTIONS.TRIP_TRAIN.UPLOAD_FILE_FAIL: {
      return {
        ...state,
        file: {
          uploaded: false,
          failed: true,
          error: FILE_UPLOAD_FAILED,
        },
        JsonData: {
          ...state.JsonData,
          guid: '',
        },
      };
    }

    case ACTIONS.TRIP_TRAIN.UPLOAD_FILE_RESET: {
      return {
        ...state,
        file: {
          uploaded: false,
          failed: false,
          error: '',
        },
      };
    }

    case ACTIONS.TRIP_TRAIN.RESET_ERROR: {
      return {
        ...state,
        file: {
          ...state.file,
          failed: false,
          error: '',
        },
        JsonData: {
          ...state.JsonData,
          guid: '',
        },
      };
    }

    case ACTIONS.TRIP_TRAIN.GET_TRAIN_CARRIERS:
    case ACTIONS.TRIP_TRAIN.GET_TRAIN_CARRIERS_FAIL: {
      return {
        ...state,
        carriers: action.carriers,
      };
    }

    case ACTIONS.TRIP_TRAIN.RESET_TRAIN_STORE: {
      return lodashReplaces.cloneDeep(defaultStore);
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('TripTrain', reducer, lodashReplaces.cloneDeep(defaultStore));

  const createStore = () => new Store('TripTrain', reducer, lodashReplaces.cloneDeep(defaultStore));

  return createStore();
}
