import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Select, Tooltip,
} from 'sw-ui';

import Cart from './cart';
import Input from '../../../components/input';
import { ApproveDialog } from './ApproveDialog';

import { getMoment, dateInterval } from '../../../bi/utils/formatDate';
import MoneyFormat from '../../../bi/utils/money';

import { SERVICETYPE } from '../../../bi/constants/serviceType';
import COMPONENTS from '../../../bi/constants/components';
import {
  APPROVE_STATUS, VALIDATION_ERRORS, CARTSTATUS, ERRORS_KEYS, TIMEOUT,
} from '../../../bi/constants/cart';
import { BUYTRIPSPERSONALRIGHT } from '../../../bi/constants/rights';
import { HOTEL_PROVIDER_VALUE } from '../../../bi/constants/hotel';

import styles from '../styles/index.module.scss';

const LABELS = {
  CART: 'Корзина',
  NO_CART: 'Корзины отсутствуют',
  RUB: 'р.',
  IS_OFFLINE: 'Эта корзина создана по запросу пользователя в чат',
  ADD_ITEM: 'Добавить услугу',
  DELETE_CART: 'Удалить корзину',
  CREATE_BOOKING_REQUEST: 'Создать заявку на бронирование',
  MOVE_TO_BOOKING_REQUEST: 'Перейти к заявке на бронирование',
  SEND_TO_APPROVE: 'Отправить на согласование',
  ITEM_NEED_APPROVE: 'Услуга требует согласования',
  CREATE_TRIP: 'Создать поездку',
  NOT_SELECTED_ANALYTIC: 'Не выбрано',
  INPUT_INTERCOM_LINK: 'Ссылка на чат',
  SAVE_INTERCOM: 'Сохранить ссылку',
  WITHOUT_APPROVE: 'Поездка не требует согласования',
  TOO_MUCH_ITEMS: 'Заявка может содержать только один отель',
  BOOKING_ERROR: 'Не удалось создать заявку',
};

const CartList = (props) => {
  const {
    userId,
    userEmail,
    carts,
    companies,
    projects,
    cartsAndNotesService,
    cartsAndNotesService: {
      getRequestIdByCartId,
      createBookingRequest,
    },
    rightsService,
    appService,
    moveToEditCart,
    moveToCreateTrip,
    canCreateCart,
    showApprovalsDialog,
    loadingApprovals,
    approvalsList,
    approvalName,
    selectedApprovers,
    commentForApprove,
    errorApprove,
    employeeId,
    analytics,
    link,
    errorLink,
  } = props;

  const [showTooltip, setShowTooltip] = useState(false);
  const [currentCurt, setCurrentCurt] = useState(null);
  const [currentApproveCurt, setCurrentApproveCurt] = useState(null);
  const [existingRequests, setExistingRequests] = useState({});
  const [bookingButtonsLoading, setBookingButtonsLoading] = useState({});
  const [bookingReqError, setBookingReqError] = useState(false);

  useEffect(() => {
    const getRequestsByCartIds = async () => {
      const allExistingRequests = await Promise.all(
        carts.map(async ({ Id }) => {
          const data = await getRequestIdByCartId(Id);
          const requestId = data.length > 0 ? data[0].id : null;

          return {
            cartId: Id,
            exists: data.some((item) => item.cartId === Id),
            requestId,
          };
        }),
      );

      const mappedExistingRequests = allExistingRequests.reduce((acc, {
        cartId, exists, requestId,
      }) => {
        acc[cartId] = { exists, requestId };

        return acc;
      }, {});

      setExistingRequests(mappedExistingRequests);
    };

    getRequestsByCartIds();
  }, [carts]);

  const accountId = appService.get().params.accountId;
  const companyId = appService.get().params.companyId;

  const handleOpenFormCreate = (cartId) => {
    moveToEditCart(userId, cartId);
  };

  const handleDeleteFormCreate = async (id) => {
    await cartsAndNotesService.deleteCart(id);
    cartsAndNotesService.loadCartsAndNotes(userId, accountId, companyId);
  };

  const handleCloseDialog = () => {
    cartsAndNotesService.closeApprovers();
  };

  const handleOpenFormApprove = async (id) => {
    const { BuyTripPersonal } = await rightsService.getEmployeeRights(accountId, employeeId);

    setCurrentApproveCurt(id);

    if (BuyTripPersonal === BUYTRIPSPERSONALRIGHT.ApprovalScheme) {
      await cartsAndNotesService.getSchemeApprovers(id);
      await cartsAndNotesService.getApprovalName();
    } else if (BuyTripPersonal === BUYTRIPSPERSONALRIGHT.OnlyAfterApprove
      || BuyTripPersonal === BUYTRIPSPERSONALRIGHT.TravelPolicy) {
      await cartsAndNotesService.getApprovers();
    }
  };

  const handleOpenFormCreateTrip = (cartId) => {
    moveToCreateTrip(userId, cartId);
  };

  const handleChangeSelect = async (selectedAnalytic, cartId, analyticId) => {
    if (selectedAnalytic.value) {
      await cartsAndNotesService.selectAnalytic(cartId, selectedAnalytic);
    } else {
      await cartsAndNotesService.deleteAnalytic(cartId, analyticId);
    }
    cartsAndNotesService.loadCartsAndNotes(userId, accountId, companyId);
  };

  const handleChangeIntercomLinkInput = (value, index) => {
    cartsAndNotesService.changeIntercomLink(value, index);
  };

  const handleSendIntercomLink = async (cartId, index) => {
    await cartsAndNotesService.saveIntercomLink(cartId, index, userId, accountId, companyId);
  };

  const handleCreateBookingRequest = async (cart) => {
    setBookingReqError(false);

    const bookRequest = await createBookingRequest(cart, accountId, userEmail);

    if (!bookRequest) {
      return setBookingReqError(true);
    }

    const { cartId, id } = bookRequest;

    setExistingRequests((prevState) => ({
      ...prevState,
      [cartId]: {
        exists: true,
        requestId: id,
      },
    }));
  };

  const handleMoveToExistingBookingRequest = (requestId) => {
    const path = `/account/${accountId}/company/${companyId}/account/bookRequest?requestId=${requestId}`;

    window.open(path, '_blank');
  };

  const getMinMaxDate = ({
    checkin, checkout, minDate, maxDate,
  }) => {
    const newMinDate = minDate === null ? checkin : minDate;

    return {
      min: (checkin.isBefore(newMinDate)) ? checkin : newMinDate,
      max: (checkout.isAfter(maxDate)) ? checkout : maxDate,
    };
  };

  const renderDatesInterval = (cartItems) => {
    let minDate = null;
    let maxDate = getMoment();

    cartItems.forEach((item) => {
      switch (item.ServiceType) {
        case SERVICETYPE.AIR: {
          const data = JSON.parse(item.Data);

          const firstRoute = data.Routes[0];
          const lastRoute = data.Routes[data.Routes.length - 1];

          const firstSegment = firstRoute.Segments[0];
          const lastSegment = lastRoute.Segments[lastRoute.Segments.length - 1];

          const checkin = getMoment(firstSegment.DepartureTime_DateTime).utcOffset('+3:00'); // TODO: fucking timzone
          const checkout = getMoment(lastSegment.ArrivalTime_DateTime).utcOffset('+3:00'); // TODO: fucking timzone

          const { min, max } = getMinMaxDate({
            checkin, checkout, minDate, maxDate,
          });

          minDate = min;
          maxDate = max;
          break;
        }
        case SERVICETYPE.HOTEL: {
          const meta = JSON.parse(item.Data);

          const checkin = getMoment(meta.checkin);
          const checkout = getMoment(meta.checkout);

          const { min, max } = getMinMaxDate({
            checkin, checkout, minDate, maxDate,
          });

          minDate = min;
          maxDate = max;
          break;
        }
        case SERVICETYPE.TRANSFER: {
          const meta = JSON.parse(item.Data);

          const checkin = getMoment(meta.DateArrival);

          const { min, max } = getMinMaxDate({
            checkin, checkout: checkin, minDate, maxDate,
          });

          minDate = min;
          maxDate = max;
          break;
        }
        case SERVICETYPE.TRAIN: {
          const data = JSON.parse(item.Data);

          const checkin = getMoment(data.DateDeparture);
          const checkout = getMoment(data.DateArrive);

          const { min, max } = getMinMaxDate({
            checkin, checkout, minDate, maxDate,
          });

          minDate = min;
          maxDate = max;
          break;
        }
      }
    });

    return (minDate && maxDate) ? dateInterval(minDate, maxDate) : '';
  };

  const renderTotalPrice = (cartItems) => {
    const totalPrice = cartItems.reduce((sum, item) => (sum + item.Price), 0);

    return MoneyFormat.moneyWithDecimal(totalPrice);
  };

  const renderStatusApprove = ({ Status }) => {
    switch (Status) {
      case CARTSTATUS.WAITINGAPPROVE: {
        return <div className={ styles.wait }>{ APPROVE_STATUS.WAITING_APPROVE }</div>;
      }

      case CARTSTATUS.APPROVED: {
        return <div className={ styles.approve }>{ APPROVE_STATUS.APPROVED }</div>;
      }

      case CARTSTATUS.DECLINED: {
        return <div className={ styles.offline }>{ APPROVE_STATUS.DECLINED }</div>;
      }

      default: return null;
    }
  };

  const renderEmpty = () => (
    <div className={ `${styles.panel} ${styles.cart} ${styles.empty}` }>
      <div className={ styles['cart-name__empty'] }>{ LABELS.NO_CART }</div>
    </div>
  );

  const renderApproveDialog = (cartId) => {
    if (!showApprovalsDialog || !approvalsList || currentApproveCurt !== cartId) return null;

    return (
      <ApproveDialog
        items={ approvalsList }
        accountId={ accountId }
        companyId={ companyId }
        userId={ userId }
        cartId={ cartId }
        approvalName={ approvalName }
        selectedApprovers={ selectedApprovers }
        cartsAndNotesService={ cartsAndNotesService }
        commentForApprove={ commentForApprove }
        errorApprove={ errorApprove }
        onClick={ handleCloseDialog }
      />
    );
  };

  const renderSelectAnalytics = (cart) => {
    if (!cart.IsOffline || !analytics.length || !canCreateCart) return null;

    return analytics.map(({
      ApplyToTrip, Values, Name, Required, Id: analyticId,
    }) => {
      if (!ApplyToTrip) return null;

      const userAnalyticsFindValue = Values.find(({ Id }) => cart.UserAnalytics.includes(Id));
      const selectedValue = userAnalyticsFindValue ? userAnalyticsFindValue.Id : null;

      const values = Values.map(({ Name: valueName, Id }) => ({
        label: valueName,
        value: Id,
      }));
      const valuesWithRequired = !Required
        ? [
          {
            label: LABELS.NOT_SELECTED_ANALYTIC,
            value: null,
          },
          ...values,
        ]
        : values;

      return (
        <div className={ styles.col_1_4 }>
          <div className={ styles.select }>
            <label>{ Name }</label>
            <Select
              items={ valuesWithRequired }
              value={ selectedValue }
              theme={ COMPONENTS.SELECT.THEME.BORDER }
              onChange={ (item) => handleChangeSelect(item, cart.Id, analyticId) }
              className={ styles.select_analytic }
            />
          </div>
        </div>
      );
    });
  };

  const renderIntercomLink = (cart, index) => {
    if (!cart.IsOffline) return null;

    const disabledEditLink = cart.Status !== CARTSTATUS.NORMAL;

    return (
      <div className={ `${styles.buttons_offline} ${styles.col_1_2}` }>
        <Input
          value={ link[index] }
          label={ LABELS.INPUT_INTERCOM_LINK }
          onChange={ (e, field, value) => handleChangeIntercomLinkInput(value, index) }
          valid={ errorLink }
          disabled={ disabledEditLink }
        />
        <Button
          label={ LABELS.SAVE_INTERCOM }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => handleSendIntercomLink(cart.Id, index) }
          className={ styles['button-offline'] }
          disabled={ disabledEditLink }
        />
      </div>
    );
  };

  const renderTooltipErrors = (cart) => {
    const {
      CartValidationResult, Items, CanCreateTrip, Status,
    } = cart;

    const validationItems = Items.reduce((acc, { ValidationResult }) => (
      [
        ...acc,
        ...ValidationResult.Errors.map((item) => {
          if (item === ERRORS_KEYS.NOT_ENOUGH_REQUIRED_USER_ANALYTICS
            && ValidationResult.MissedUserAnalytics
            && ValidationResult.MissedUserAnalytics.length) {
            const nameAnalyticsError = ValidationResult.MissedUserAnalytics.join(', ');

            return `${VALIDATION_ERRORS[item]}: ${nameAnalyticsError}.`;
          }

          return `${VALIDATION_ERRORS[item]}.`;
        }),
      ]
    ), []).join(' ');

    const cartValidation = CartValidationResult.Errors.map((item) => {
      const labelError = VALIDATION_ERRORS[item];

      if (item === ERRORS_KEYS.NOT_ENOUGH_REQUIRED_USER_ANALYTICS
        && CartValidationResult.MissedUserAnalytics
        && CartValidationResult.MissedUserAnalytics.length) {
        const nameAnalyticsError = CartValidationResult.MissedUserAnalytics.join(', ');

        return `${labelError}: ${nameAnalyticsError}`;
      }

      return labelError;
    }).join('. ');

    const withoutApproveItem = CanCreateTrip && Status === CARTSTATUS.NORMAL
      ? LABELS.WITHOUT_APPROVE
      : '';

    if (cart.Id !== currentCurt
      || !showTooltip
      || (!validationItems && !cartValidation && !withoutApproveItem)) return null;

    const textTooltip = !withoutApproveItem ? `${validationItems} ${cartValidation}` : withoutApproveItem;

    return (
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.TOP }
      >
        { textTooltip }
      </Tooltip>
    );
  };

  const renderBookingRequestTooltip = ({ Items }) => {
    const tooltipText = Items.length > 1 ? LABELS.TOO_MUCH_ITEMS : '';

    if (!tooltipText) return null;

    return (
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.TOP }
      >
        { tooltipText }
      </Tooltip>
    );
  };

  const handleBookingButtonClick = (cart, exists, requestId) => {
    const { Id } = cart;

    setBookingButtonsLoading((prev) => ({ ...prev, [Id]: true }));

    const action = exists
      ? () => handleMoveToExistingBookingRequest(requestId)
      : () => handleCreateBookingRequest(cart);

    if (exists) {
      action();

      return setTimeout(() => {
        setBookingButtonsLoading((prev) => ({ ...prev, [Id]: false }));
      }, TIMEOUT);
    }

    action().finally(() => {
      setBookingButtonsLoading((prev) => ({ ...prev, [Id]: false }));
    });
  };

  const renderBookingButton = (cart) => {
    const {
      Id, Status, Items, CanCreateTrip,
    } = cart;
    const { exists, requestId } = existingRequests[Id] || { exists: false, requestId: null };
    const provider = Items[0].ProviderName;

    if (!((Status === CARTSTATUS.NORMAL || Status === CARTSTATUS.APPROVED)
      && (provider === HOTEL_PROVIDER_VALUE.direct
        || provider === HOTEL_PROVIDER_VALUE.verdeho
        || provider === HOTEL_PROVIDER_VALUE.verdeho3d
        || provider === HOTEL_PROVIDER_VALUE.sawady3d
      ))) {
      return null;
    }

    const label = exists ? LABELS.MOVE_TO_BOOKING_REQUEST : LABELS.CREATE_BOOKING_REQUEST;

    return (
      <div className='sw-tooltip-wrapper'>
        <div
          className={ styles['button-offline'] }
          onMouseEnter={ () => {
            setShowTooltip(true);
            setCurrentCurt(Id);
          } }
          onMouseLeave={ () => {
            setShowTooltip(false);
            setCurrentCurt(null);
          } }
        >
          <Button
            label={ label }
            theme={ COMPONENTS.BUTTON.THEME.WHITE }
            onClick={ () => handleBookingButtonClick(cart, exists, requestId) }
            onlyBorder
            loading={ bookingButtonsLoading[Id] }
            disabled={ Items.length > 1 || !CanCreateTrip }
            className={ styles.button_create_request }
          />
        </div>
        { renderBookingRequestTooltip(cart) }
      </div>
    );
  };

  const renderOfflineButtons = (cart) => {
    if (!cart.IsOffline) return null;

    const labelNeedApprove = cart.CanSendToApprove ? LABELS.ITEM_NEED_APPROVE : '';
    const bookingError = bookingReqError ? LABELS.BOOKING_ERROR : '';
    const errorText = labelNeedApprove || bookingError;

    return (
      <div className={ styles.buttons_offline }>
        <div className={ styles.offline }>{ errorText }</div>
        { renderBookingButton(cart) }
        <div className='sw-tooltip-wrapper'>
          <div
            className={ styles['button-offline'] }
            onMouseEnter={ () => {
              setShowTooltip(true);
              setCurrentCurt(cart.Id);
            } }
            onMouseLeave={ () => {
              setShowTooltip(false);
              setCurrentCurt(null);
            } }
          >
            <Button
              label={ LABELS.SEND_TO_APPROVE }
              theme={ COMPONENTS.BUTTON.THEME.WHITE }
              onClick={ () => handleOpenFormApprove(cart.Id) }
              onlyBorder
              loading={ loadingApprovals }
              disabled={ !cart.CanSendToApprove }
            />
          </div>
          { renderTooltipErrors(cart) }
        </div>
        <div className={ styles['button-offline'] }>
          <Button
            label={ LABELS.ADD_ITEM }
            theme={ COMPONENTS.BUTTON.THEME.WHITE }
            onClick={ () => handleOpenFormCreate(cart.Id) }
            disabled={ !cart.CanEditCart }
          />
        </div>
        <div className={ styles['button-offline'] }>
          <Button
            label={ LABELS.DELETE_CART }
            theme={ COMPONENTS.BUTTON.THEME.WHITE }
            onClick={ () => handleDeleteFormCreate(cart.Id) }
            disabled={ !cart.CanDeleteCart }
          />
        </div>
      </div>
    );
  };

  const renderButtonCreateTrip = (cart) => {
    if (!cart.IsOffline) return null;

    return (
      <div className={ styles['button-buy'] }>
        <Button
          label={ LABELS.CREATE_TRIP }
          theme={ COMPONENTS.BUTTON.THEME.WHITE }
          onClick={ () => handleOpenFormCreateTrip(cart.Id) }
          disabled={ !cart.CanCreateTrip }
        />
      </div>
    );
  };

  const renderHtml = () => {
    let normalCartsNumber = 0;
    const normalCarts = carts
      .filter((cart) => cart.Status === CARTSTATUS.NORMAL || cart.IsOffline);

    const html = normalCarts.length
      ? normalCarts.map((cart, index) => {
        normalCartsNumber++;

        const labelOfflineCart = cart.IsOffline ? LABELS.IS_OFFLINE : '';

        return (
          <div key={ cart.Id } className={ `${styles.panel} ${styles.cart}` }>
            <div className={ styles.row }>
              <div className={ styles['cart-name__field'] }>{ LABELS.CART }</div>
              { renderOfflineButtons(cart) }
            </div>
            <div className={ styles.row }>
              <div className={ styles['cart-name'] }>{ cart.Name }</div>
              <div className={ styles['date-interval'] }>
                { renderDatesInterval(cart.Items) }
              </div>
            </div>
            <div className={ styles.buttons_offline }>
              { renderSelectAnalytics(cart) }
            </div>
            <div className={ styles.buttons_offline }>
              { renderIntercomLink(cart, index) }
            </div>
            <Cart
              cart={ cart }
              companies={ companies }
              projects={ projects }
              cartsAndNotesService={ cartsAndNotesService }
              appService={ appService }
              loadingApprovals={ loadingApprovals }
              moveToEditCart={ handleOpenFormCreate }
            />
            <div className={ styles.row }>
              <div className={ styles['actions-price'] }>
                <div className={ styles.offline }>{ labelOfflineCart }</div>
                <div className={ styles['actions-price-main'] }>
                  { renderStatusApprove(cart) }
                  <div className={ styles.price }>
                    { renderTotalPrice(cart.Items) }
                    { ' ' }
                    { LABELS.RUB }
                  </div>
                  { renderButtonCreateTrip(cart) }
                </div>
              </div>
            </div>
            { renderApproveDialog(cart.Id) }
          </div>);
      })
      : renderEmpty();

    if (normalCartsNumber > 1 && process.env.NODE_ENV === 'production') {
      cartsAndNotesService.sendToSlack({
        text: `У пользователя с _id_ \`${userId}\` больше одной корзины в статусе *Normal*`,
        iconEmoji: ':fb-wow:',
        channel: '#srv-prod-errors',
      });
    }

    return html;
  };

  return (
    <div className={ styles['carts-container'] }>
      { renderHtml() }
    </div>
  );
};

CartList.propTypes = {
  carts: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  rightsService: PropTypes.object.isRequired,
  appService: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  moveToEditCart: PropTypes.func.isRequired,
  moveToCreateTrip: PropTypes.func.isRequired,
  canCreateCart: PropTypes.bool.isRequired,
  showApprovalsDialog: PropTypes.bool.isRequired,
  loadingApprovals: PropTypes.bool.isRequired,
  approvalsList: PropTypes.object.isRequired,
  approvalName: PropTypes.string.isRequired,
  selectedApprovers: PropTypes.array.isRequired,
  commentForApprove: PropTypes.string.isRequired,
  errorApprove: PropTypes.string.isRequired,
  employeeId: PropTypes.number.isRequired,
  analytics: PropTypes.array.isRequired,
  link: PropTypes.string.isRequired,
  errorLink: PropTypes.string.isRequired,
};

export default CartList;
