import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ApproverComponents } from './components/Approvers';

import styles from './styles/index.module.css';

const LABELS = {
  RECTANGLE_TITLE: 'Расположите в нужном порядке карточки внутри поля',
  DROP_CART: 'Отпустите карточку',
};

const DropZone = ({
  draggableItem,
  droppedItems,
  onItemDropped,
  onRemoveItem,
  renderInput,
}) => {
  const [canBeDropped, setCanBeDropped] = useState(false);

  const handleOnDrop = (e) => {
    e.preventDefault();
    setCanBeDropped(false);

    if (draggableItem) {
      onItemDropped(draggableItem);
    }
  };

  const canBeDroppedWithName = canBeDropped && draggableItem;
  const wrapperClassNames = [styles.drop_wrap];

  if (draggableItem) {
    wrapperClassNames.push(styles.highlighted);
  }

  if (canBeDroppedWithName) {
    wrapperClassNames.push(styles.droppable);
  }

  const onDragProps = canBeDroppedWithName
    ? {}
    : {
      onDragEnter: () => setCanBeDropped(true),
      onDragOver: (e) => e.preventDefault(),
      onDrop: handleOnDrop,
    };

  const renderDroppable = () => (
    <div
      className={ styles.droppable_panel }
      onDragLeave={ () => setCanBeDropped(false) }
      onDragOver={ (e) => e.preventDefault() }
      onDrop={ handleOnDrop }
    >
      <div>{ LABELS.DROP_CART }</div>
    </div>
  );

  const droppablePanel = canBeDroppedWithName && renderDroppable();

  return (
    <div className={ wrapperClassNames.join(' ') } { ...onDragProps }>
      <div className={ styles.common }>
        <div className={ styles.header }>{ LABELS.RECTANGLE_TITLE }</div>
        <ApproverComponents
          droppedItems={ droppedItems }
          onRemoveItem={ onRemoveItem }
          renderInput={ renderInput }
        />
      </div>
      { droppablePanel }
    </div>
  );
};

DropZone.propTypes = {
  draggableItem: PropTypes.string,
  droppedItems: PropTypes.array.isRequired,
  onItemDropped: PropTypes.func.isRequired,
  onRemoveItem: PropTypes.func.isRequired,
  renderInput: PropTypes.func,
};

DropZone.defaultProps = {
  draggableItem: null,
  renderInput: null,
};

export { DropZone };
