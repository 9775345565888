import bs from '../config/api/api.config.bs.json';
import f1 from '../config/api/api.config.f1.json';
import f2 from '../config/api/api.config.f2.json';
import f3 from '../config/api/api.config.f3.json';
import f4 from '../config/api/api.config.f4.json';
import f5 from '../config/api/api.config.f5.json';
import f6 from '../config/api/api.config.f6.json';
import f7 from '../config/api/api.config.f7.json';
import f8 from '../config/api/api.config.f8.json';
import f9 from '../config/api/api.config.f9.json';
import f10 from '../config/api/api.config.f10.json';
import f12 from '../config/api/api.config.f12.json';
import f13 from '../config/api/api.config.f13.json';
import f14 from '../config/api/api.config.f14.json';
import f15 from '../config/api/api.config.f15.json';
import f16 from '../config/api/api.config.f16.json';
import f17 from '../config/api/api.config.f17.json';
import f18 from '../config/api/api.config.f18.json';
import f19 from '../config/api/api.config.f19.json';
import f20 from '../config/api/api.config.f20.json';
import f21 from '../config/api/api.config.f21.json';
import hf from '../config/api/api.config.hf.json';
import local from '../config/api/api.config.local.json';
import production from '../config/api/api.config.production.json';
import productionNew from '../config/api/api.config.production-new.json';
import rc from '../config/api/api.config.rc.json';
import sandbox from '../config/api/api.config.sandbox.json';
import test from '../config/api/api.config.test.json';
import yc from '../config/api/api.config.yc.json';

const configs = {
  bs,
  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  f7,
  f8,
  f9,
  f10,
  f12,
  f13,
  f14,
  f15,
  f16,
  f17,
  f18,
  f19,
  f20,
  f21,
  hf,
  local,
  production,
  rc,
  sandbox,
  test,
  yc,
  'production-new': productionNew,
};

export default configs[process.env.SW_ENV || 'rc'];
