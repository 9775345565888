import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  startOfMonth, getMoment, formatDate, isAfterDate,
} from '../../../../bi/utils/formatDate';
import Radio from '../../../../components/radio';
import AjaxButton from '../../../../components/ajaxButton';
import RangeDatePicker from '../../../../components/RangeDatePicker';

import FILEFORMATS from '../../../../constants/fileFormats';
import { DATE } from '../../../../constants/time';
import {
  ACCOUNTTEXT, CERTIFICATE, CERTIFICATE_TYPES, SIGNATURE_TYPES, SIGNATURE_CERTIFICATE,
} from '../../../../bi/constants/account';
import SEARCH from '../../../../bi/constants/search';

import styles from '../../styles/company.module.scss';

const TYPES = {
  TYPE_CERTIFICATE: 'typeCertificate',
  TYPE_FILE: 'typeFile',
};

class CertificateForm extends Component {
  static propTypes = {
    title: PropTypes.string,
    onDownload: PropTypes.func.isRequired,
  };

  static defaultProps = { title: '' };

  state = {
    startDate: startOfMonth(),
    endDate: getMoment(),
    signatureType: SIGNATURE_TYPES.FULL_SIGNATURE,
    [TYPES.TYPE_CERTIFICATE]: CERTIFICATE_TYPES.EVERY_FLIGHT,
    [TYPES.TYPE_FILE]: FILEFORMATS.PDF,
    waitingResponse: false,
  };

  handleChangeSignature = (value) => this.setState({ signatureType: value });

  handleChangeDate = (field, value) => {
    const { endDate } = this.state;

    if (field === SEARCH.STARTDATE && isAfterDate(value, endDate)) {
      this.setState({
        [field]: value,
        endDate: value,
      });
    }

    this.setState({ [field]: value });
  };

  handleChangeType = (type, value) => {
    if (value === CERTIFICATE_TYPES.SINGLE_FLIGHT) {
      this.setState({ typeFile: FILEFORMATS.PDF });
    }

    this.setState({ [type]: value });
  };

  handleDownload = () => {
    const {
      startDate, endDate, typeFile, typeCertificate, signatureType,
    } = this.state;
    const { onDownload } = this.props;

    this.setState({ waitingResponse: true });

    onDownload(formatDate(startDate, DATE), formatDate(endDate, DATE), typeFile, typeCertificate, signatureType);
  };

  render() {
    const {
      startDate, endDate, typeCertificate, typeFile, waitingResponse, signatureType,
    } = this.state;
    const { title } = this.props;

    const zipRadioHtml = typeCertificate === CERTIFICATE_TYPES.SINGLE_FLIGHT
      && (
        <div className={ styles.format_form_item }>
          <Radio
            label={ FILEFORMATS.ZIP }
            checked={ typeFile === FILEFORMATS.ZIP }
            field={ FILEFORMATS.ZIP }
            value={ FILEFORMATS.ZIP }
            onChange={ (field, value) => this.handleChangeType(TYPES.TYPE_FILE, value) }
          />
        </div>
      );

    const pdfRadioHtml = typeCertificate === CERTIFICATE_TYPES.EVERY_FLIGHT
      && (
        <div className={ styles.format_form_item }>
          <Radio
            label={ FILEFORMATS.XLS }
            checked={ typeFile === FILEFORMATS.XLS }
            field={ FILEFORMATS.XLS }
            value={ FILEFORMATS.XLS }
            onChange={ (field, value) => this.handleChangeType(TYPES.TYPE_FILE, value) }
          />
        </div>
      );

    return (
      <div className={ styles.revise__wrap }>
        <div className={ styles.revise__title }>{ title }</div>
        <div className={ `${styles.revise__row}` }>
          <RangeDatePicker
            startDate={ startDate }
            endDate={ endDate }
            onChange={ this.handleChangeDate }
            placeholderNameFrom={ ACCOUNTTEXT.FROM }
            placeholderNameTo={ ACCOUNTTEXT.TO }
          />
        </div>
        <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
          <Radio
            className={ styles.revise__radiobutton }
            label={ CERTIFICATE.EVERY_FLIGHT }
            checked={ typeCertificate === CERTIFICATE_TYPES.EVERY_FLIGHT }
            field={ CERTIFICATE_TYPES.EVERY_FLIGHT }
            value={ CERTIFICATE_TYPES.EVERY_FLIGHT }
            onChange={ (field, value) => this.handleChangeType(TYPES.TYPE_CERTIFICATE, value) }
          />
        </div>
        <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
          <Radio
            className={ styles.revise__radiobutton }
            label={ CERTIFICATE.SINGLE_FLIGHT }
            checked={ typeCertificate === CERTIFICATE_TYPES.SINGLE_FLIGHT }
            field={ CERTIFICATE_TYPES.SINGLE_FLIGHT }
            value={ CERTIFICATE_TYPES.SINGLE_FLIGHT }
            onChange={ (field, value) => this.handleChangeType(TYPES.TYPE_CERTIFICATE, value) }
          />
        </div>
        <div className={ styles.signature }>
          <h2 className={ styles.signature_title }>Выберите тип подписи</h2>
          <form>
            <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ SIGNATURE_CERTIFICATE.FULL_SIGNATURE }
                checked={ signatureType === SIGNATURE_TYPES.FULL_SIGNATURE }
                field={ SIGNATURE_TYPES.FULL_SIGNATURE }
                value={ SIGNATURE_TYPES.FULL_SIGNATURE }
                onChange={ (field, value) => this.handleChangeSignature(value) }
              />
            </div>
            <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ SIGNATURE_CERTIFICATE.LIGHT_SIGNATURE }
                checked={ signatureType === SIGNATURE_TYPES.LIGHT_SIGNATURE }
                field={ SIGNATURE_TYPES.LIGHT_SIGNATURE }
                value={ SIGNATURE_TYPES.LIGHT_SIGNATURE }
                onChange={ (field, value) => this.handleChangeSignature(value) }
              />
            </div>
            <div className={ `${styles.revise__row} ${styles.revise__radio}` }>
              <Radio
                className={ styles.revise__radiobutton }
                label={ SIGNATURE_CERTIFICATE.WITHOUT_SIGNATURE }
                checked={ signatureType === SIGNATURE_TYPES.WITHOUT_SIGNATURE }
                field={ SIGNATURE_TYPES.WITHOUT_SIGNATURE }
                value={ SIGNATURE_TYPES.WITHOUT_SIGNATURE }
                onChange={ (field, value) => this.handleChangeSignature(value) }
              />
            </div>
          </form>
        </div>
        <div className={ styles.footer }>
          <form className={ styles.format_form }>
            <div className={ styles.format_form_item }>
              <Radio
                label={ FILEFORMATS.PDF }
                checked={ typeFile === FILEFORMATS.PDF }
                field={ typeFile }
                value={ FILEFORMATS.PDF }
                onChange={ (field, value) => this.handleChangeType(TYPES.TYPE_FILE, value) }
              />
            </div>
            { pdfRadioHtml }
            { zipRadioHtml }
          </form>
          <div className={ styles.format_form }>
            <AjaxButton
              label={ ACCOUNTTEXT.DOWNLOAD }
              loading={ waitingResponse }
              onClick={ this.handleDownload }
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CertificateForm;
