import renderComponents from './components.jsx';

const NAME = 'sw-company';

class AccountModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const appService = context.getService('App');
    const userService = context.getService('User');
    const accountService = context.getService('Account');
    const companyService = context.getService('Company');
    const tripService = context.getService('Trip');
    const notificationsService = context.getService('Notifications');
    const airService = context.getService('Air');
    const hotelsService = context.getService('Hotels');
    const featureFlagsService = this.context.getService('FeatureFlags');
    const staticsService = context.getService('Statics');
    const employeeService = context.getService('Employee');
    const documentsConstructorService = context.getService('DocumentsConstructor');

    const params = {
      ...appService.get().params,
      accountId: Number(appService.get().params.accountId),
      companyId: Number(appService.get().params.companyId),
      tabId: appService.get().params.tabId,
    };

    this.page = renderComponents(this.box, {
      toNewTrip: this.moveToNewTrip,
      toUserCartsAndNote: this.toUserCartsAndNote,
      toAnotherTab: this.toAnotherTab,
      toAnotherCompany: this.toAnotherCompany,
      toDocumentsConstructor: this.toDocumentsConstructor,
      params,
      appService,
      userService,
      accountService,
      companyService,
      tripService,
      notificationsService,
      airService,
      hotelsService,
      featureFlagsService,
      staticsService,
      employeeService,
      documentsConstructorService,
    });
  }

  toUserCartsAndNote = (user) => {
    const { companyId, accountId } = this.context.getService('App').get().params;

    const path = `/account/${accountId}/company/${companyId}/user/${user.Id}`;

    this.context.navigate(path);
  };

  toAnotherTab = ({
    mainTabId = 'account', tabId, query,
  }) => {
    const { companyId, accountId } = this.context.getService('App').get().params;
    const queryStr = query ? `?${query}` : '';

    const path = `/account/${accountId}/company/${companyId}/${mainTabId}/${tabId}${queryStr}`;

    this.context.navigate(path);
  };

  toAnotherCompany = (companyId) => {
    const {
      accountId, tabId, mainTabId,
    } = this.context.getService('App').get().params;

    const path = `/account/${accountId}/company/${companyId}/${mainTabId}/${tabId}`;

    this.context.navigate(path);
  };

  moveToNewTrip = (companyId) => {
    const { accountId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/trip/new`;

    this.context.navigate(path);
  };

  toDocumentsConstructor = () => {
    const { accountId, companyId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/constructor/acts`;

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, AccountModule);
}
