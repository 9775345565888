import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { WorkplaceClientSession } from '../../components/ClientSession';
import { SmartagentClientSession } from '../../components/ClientSession/Smartagent';
import { PassGeneratorForClientSession } from '../../components/ClientSession/PassGeneratorForClientSession/';

const ClientSession = ({
  onWorkplaceSession, onSmartagentSession, onGeneratePass, onCopyNotification,
}) => {
  const [passStatus, setPassStatus] = useState(false);

  const updatePassStatus = () => {
    setPassStatus(true);
  };

  const resetPassStatus = () => {
    setPassStatus(false);
  };

  const handleStartWorkplaceSession = (email, time) => onWorkplaceSession(email, time);

  const handleSmartagentSession = (email, time) => onSmartagentSession(email, time);

  const handleGeneratePass = (email, time) => onGeneratePass(email, time);

  const handleCopyNotification = () => onCopyNotification();

  return (
    <div>
      <WorkplaceClientSession onSessionStart={ handleStartWorkplaceSession } resetPassStatus={ resetPassStatus } />
      <SmartagentClientSession onSessionStart={ handleSmartagentSession } resetPassStatus={ resetPassStatus } />
      <PassGeneratorForClientSession
        onGeneratePass={ handleGeneratePass }
        updatePassStatus={ updatePassStatus }
        passStatus={ passStatus }
        onNotification={ handleCopyNotification }
      />
    </div>
  );
};

ClientSession.propTypes = {
  onGeneratePass: PropTypes.func.isRequired,
  onWorkplaceSession: PropTypes.func.isRequired,
  onSmartagentSession: PropTypes.func.isRequired,
  onCopyNotification: PropTypes.func.isRequired,
};

export { ClientSession };
