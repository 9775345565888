import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../styles/buttons.module.css';

const LABELS = { EDIT: 'Редактировать' };

const EditButton = ({ onMoveToEditDocument }) => (
  <div>
    <a
      className={ styles.edit_button }
      onClick={ onMoveToEditDocument }
    >
      { LABELS.EDIT }
    </a>
  </div>
);

EditButton.propTypes = { onMoveToEditDocument: PropTypes.func.isRequired };

export { EditButton };
