import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-autocomplete';

import AjaxButton from '../ajaxButton';

import styles from './index.module.css';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

export default class AutocompleteWithButton extends Component {
  static propTypes = {
    inputValue: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
    items: PropTypes.array.isRequired,
    disableButton: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
    valid: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      companyItems: [],
      disableButton: false,
    };
  }

  renderSuggestItems = (item) => (
    <div className={ styles['suggest-item'] } key={ item.AccountId }>
      { `${item.AccountName}` }
    </div>
  );

  render() {
    const {
      onSelect, onClick, onInputChange, inputValue, items, disableButton, valid,
    } = this.props;
    let validHtml = null;

    if (valid) {
      validHtml = (
        <span>{ valid }</span>
      );
    }

    return (
      <div className={ styles['autocomplete-container'] }>
        <div className={ styles['suggest-input'] }>
          <label> Группа компаний </label>
          <Autocomplete
            menuStyle={ MENUSTYLE }
            value={ inputValue }
            items={ items }
            getItemValue={ (item) => item.AccountName }
            onSelect={ onSelect }
            onChange={ onInputChange }
            renderItem={ this.renderSuggestItems }
          />
          { validHtml }
        </div>
        <div className={ styles['submit-button'] }>
          <AjaxButton
            label='Связь с мультиаккаунтом'
            onClick={ onClick }
            disabled={ disableButton }
          />
        </div>
      </div>
    );
  }
}
