const LABELS_RATES = {
  BASE: 'Базовый',
  OPTIMAL: 'Оптимальный',
  BUSINESS: 'Бизнес',
};

const DESCRIPTIONS_RATES = {
  BASE: 'Вернется 80% стоимости поездки',
  OPTIMAL: 'Вернется 90% стоимости поездки',
  BUSINESS: 'Вернется 100% стоимости поездки',
};

const PROVIDERS_NAMES = { VSK: 'VSK' };

const PROVIDERS_INSURANCE = [{ label: 'ВСК', value: PROVIDERS_NAMES.VSK }];

export {
  LABELS_RATES, DESCRIPTIONS_RATES, PROVIDERS_NAMES, PROVIDERS_INSURANCE,
};
