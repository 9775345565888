import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Select, Button,
} from 'sw-ui';

import DecisionButtons from '../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/trip.module.scss';

const LABELS = {
  SELECT_LEGAL_ENTITY: 'Укажите юридическое лицо A&A',
  ERROR_SEND_LEGAL: 'Произошла ошибка при отправке запроса, попробуйте позже',
  CONTINUE_BUTTON: 'OK',
};

const DIALOG_WIDTH = '650px';

const LegalEntityDialog = ({
  setLegalDialog,
  legalEntity: { list, error },
  waitPreloader,
  sendLegalEntity,
  tripItemId,
  setLegalValue,
  legalSelectValue,
}) => {
  const handleCalcelDialog = () => setLegalDialog(false);

  const handleSelect = (item) => setLegalValue(item, tripItemId);

  const handleSaveDialog = () => {
    if (!legalSelectValue) return;

    const data = {
      TripItemId: tripItemId,
      ContractorId: legalSelectValue.id,
    };

    sendLegalEntity(data);
  };

  const legalDialogButtons = () => {
    if (!error) {
      return (
        <DecisionButtons
          waitingResponse={ waitPreloader }
          disabled={ waitPreloader }
          onCancel={ handleCalcelDialog }
          onSave={ handleSaveDialog }
          labelSave={ DECISIONBUTTONS.LABELS.SAVE }
        />
      );
    }

    return (
      <Button
        label={ LABELS.CONTINUE_BUTTON }
        theme={ COMPONENTS.BUTTON.THEME.SECOND }
        onClick={ handleCalcelDialog }
      />
    );
  };

  const renderSelect = () => {
    if (error || !list) return null;

    return (
      <Select
        items={ list }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        value={ legalSelectValue ? legalSelectValue.value : null }
        onChange={ (item) => handleSelect(item) }
      />
    );
  };

  return (
    <Dialog width={ DIALOG_WIDTH } onClick={ handleCalcelDialog }>
      <div className={ styles['user-dialog'] }>
        <div className={ styles['closing-list'] }>
          { error ? LABELS.ERROR_SEND_LEGAL : LABELS.SELECT_LEGAL_ENTITY }
        </div>
        { renderSelect() }
        <div className={ `${styles.row} ${styles.right}` }>
          { legalDialogButtons() }
        </div>
      </div>
    </Dialog>
  );
};

LegalEntityDialog.propTypes = {
  tripItemId: PropTypes.number.isRequired,
  waitPreloader: PropTypes.bool.isRequired,
  setLegalDialog: PropTypes.func.isRequired,
  setLegalValue: PropTypes.func.isRequired,
  sendLegalEntity: PropTypes.func.isRequired,
  legalEntity: PropTypes.object.isRequired,
  legalSelectValue: PropTypes.object,
};

LegalEntityDialog.defaultProps = { legalSelectValue: null };

export { LegalEntityDialog };
