import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Button } from 'sw-ui';

import { FlatButton } from '../../components/button';
import { FormNewTrip } from '../trip/components/formNewTrip';
import { renderNew } from '../trip/components/editPanel/editPanel';

import { prepareDataCreate, prepareDataEdit } from '../../bi/utils/cart';

import { SERVICETYPE, SERVICETYPERU } from '../../bi/constants/serviceType';
import { LABELS_EDIT } from '../../bi/constants/cart';

import styles from '../trip/styles/trip.module.scss';

const LABELS = {
  NEW_TRIP: 'Создание новой поездки',
  SAVE: 'Сохранить изменения',
};

const CartEdit = ({
  appService,
  cartsAndNotesService,
  companyService,
  tripService,
  guidService,
  travelPolicyService,
  moveToCart,
}) => {
  const [store, setStore] = useState({
    carts: [],
    users: [],
    companies: [],
    analytics: [],
    userAnalytics: [],
    mandatoryProject: [],
    itemCart: null,
    loading: false,
  });
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const submenu = useRef();

  const cartId = appService.get().params.cartId;
  const accountId = appService.get().params.accountId;
  const user = appService.get().params.user;
  const companyId = appService.get().params.companyId;

  const {
    carts,
    users,
    companies,
    projects,
    analytics,
    mandatoryProject,
    itemCart,
  } = store;

  const updateState = (newStore) => {
    const selectedCart = newStore.carts.find(({ Id }) => Number(cartId) === Id);
    const companiesList = newStore.companies.map(({
      CompanyId, CompanyName, ShortCompanyName,
    }) => ({
      id: CompanyId,
      name: ShortCompanyName || `${CompanyName} (${CompanyId})`,
    }));
    const projectsEmployee = newStore.projects.length
      ? newStore.projects.map(({
        Id, IsArchival, Name,
      }) => ({
        id: Id,
        name: Name,
        isArchival: IsArchival,
      }))
      : [];
    const analyticsList = newStore.analytics.filter(({ ApplyToTrip }) => !ApplyToTrip);

    setStore({
      ...newStore,
      carts: selectedCart,
      companies: companiesList,
      projects: projectsEmployee,
      analytics: analyticsList,
    });
  };

  const unsubscribe = () => cartsAndNotesService.subscribe(updateState);

  const handleTouchOutside = (event) => {
    if (submenu.current && !submenu.current.contains(event.target)) {
      setOpenSubMenu(false);
    }
  };

  useEffect(() => {
    unsubscribe();

    cartsAndNotesService.loadCartsAndNotes(user, accountId, companyId);
    companyService.setId(companyId);
    companyService.loadProfile(accountId);

    document.addEventListener('click', handleTouchOutside);

    return () => {
      document.removeEventListener('click', handleTouchOutside);
    };
  }, []);

  const getDescriptions = (item) => {
    const data = JSON.parse(item.Data);

    switch (item.ServiceType) {
      case SERVICETYPE.HOTEL:
        return data.hotel.Name;

      default:
        return LABELS_EDIT.DEFAULT_DESCRIPTION;
    }
  };

  const handleCancelNew = () => cartsAndNotesService.setItemCart(null);

  const handleMoveToCart = () => {
    moveToCart();
    handleCancelNew();
  };

  const handleEditItem = async (item) => {
    handleCancelNew();

    let departments = [];

    if (item.Employees.length) {
      departments = await cartsAndNotesService.getDepartments(item.Employees[0].Employee.Id, companyId);
    }

    cartsAndNotesService.setItemCart(prepareDataEdit(item, departments));
  };

  const handleConfirmNew = async (item) => {
    if (item.isEdit) {
      await cartsAndNotesService.editItem(cartId, item.Id, prepareDataCreate(item));
    } else {
      await cartsAndNotesService.createItem(cartId, prepareDataCreate(item));
    }

    cartsAndNotesService.loadCartsAndNotes(user, accountId, companyId);
    handleCancelNew();
  };

  const handleAddOrderItem = () => setOpenSubMenu(true);

  const handleNewItem = (type) => {
    const item = tripService.addNewItem(type);

    setOpenSubMenu(false);
    cartsAndNotesService.setItemCart(item);
  };

  const setTripAnalytics = (analyticsValues = []) => cartsAndNotesService.setAnalytics(analyticsValues);

  const dataVersions = carts && carts.Items
    ? carts.Items.map((item) => ({
      ...item,
      Description: getDescriptions(item),
      Status: 4,
    }))
    : [];

  const renderOrderSubMenu = () => (
    <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
      <div className={ styles['menu-item'] } onClick={ () => handleNewItem(SERVICETYPE.HOTEL) }>
        { SERVICETYPERU[SERVICETYPE.HOTEL] }
      </div>
    </div>
  );

  const renderAddOrderButton = () => (
    <Button theme='flat' label={ LABELS_EDIT.ADD_ORDER } onClick={ handleAddOrderItem } />
  );

  return (
    <div className={ styles.wrap }>
      <div className={ styles['button-trip__left'] }>
        <FlatButton onClick={ handleMoveToCart }>
          <i className='material-icons'>keyboard_backspace</i>
          { ' ' }
          { LABELS_EDIT.TO_CART }
        </FlatButton>
      </div>
      <FormNewTrip
        users={ users }
        versions={ dataVersions }
        submenu={ submenu }
        label={ LABELS.NEW_TRIP }
        isEditCart
        onEditItem={ handleEditItem }
        onConfirmNew={ handleConfirmNew }
        addOrderButtonHtml={ renderAddOrderButton }
        renderOrderSubMenu={ renderOrderSubMenu }
      />
      <div
        className={ styles.row }
      >
        <div className={ styles.col }>
          { renderNew({
            companies,
            mandatoryProject,
            projectsForNewTrip: projects,
            tripService,
            travelPolicyService,
            accountId,
            item: itemCart,
            onConfirm: handleConfirmNew,
            onCancel: handleCancelNew,
            analytics,
            tripAnalyticsValueIds: [],
            setTripAnalytics,
            addTrip: () => {},
            guidService,
            isItemCart: true,
            labelButton: LABELS.SAVE,
          }) }
        </div>
      </div>
    </div>
  );
};

CartEdit.propTypes = {
  appService: PropTypes.object.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  companyService: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  guidService: PropTypes.object.isRequired,
  travelPolicyService: PropTypes.object.isRequired,
  moveToCart: PropTypes.func.isRequired,
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  const page = (
    <CartEdit
      appService={ opts.appService }
      cartsAndNotesService={ opts.cartsAndNotesService }
      companyService={ opts.companyService }
      tripService={ opts.tripService }
      guidService={ opts.guidService }
      travelPolicyService={ opts.travelPolicyService }
      moveToCart={ opts.moveToCart }
    />
  );

  root.render(page);

  return root;
};

export default renderComponents;
