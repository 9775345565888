import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { formatDateWithMinutes } from '../../../../bi/utils/formatDate';

import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/tripCommentsDialog.module.css';

const { DIALOG: { WIDTH: { MIDDLE } } } = COMPONENTS;

const LABELS = { TITLE: 'Комментарии к поездке' };

const TripCommentsDialog = ({
  comments,
  onClose,
}) => {
  const renderItem = (item) => {
    const {
      Id, DateCreated, Text, Email,
    } = item;

    return (
      <div key={ `trip_comment_${Id}` } className={ styles.item }>
        <div className={ styles.name }>
          <span>{ formatDateWithMinutes(DateCreated) }</span>
          ,
          { Email }
        </div>
        <div>
          { Text }
        </div>
      </div>
    );
  };

  return (
    <Dialog
      showCloseButton
      width={ MIDDLE }
      onClick={ () => onClose(false) }
    >
      <div className={ styles.wrapper }>
        <div className={ styles.title }>{ LABELS.TITLE }</div>
        <div className={ styles.list }>
          { comments.map(renderItem) }
        </div>
      </div>
    </Dialog>
  );
};

TripCommentsDialog.propTypes = {
  comments: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { TripCommentsDialog };
