import { ITransaction } from '../../types.ts';

import LinkToNewTab from '../../../../../../components/LinkToNewTab';

import { formatDate } from '../../../../../../utils/date';
import MoneyFormat from '../../../../../../bi/utils/money';

import { TransactionsStatusMap } from '../../constants.ts';

import styles from '../../styles/table.module.scss';

interface ITableRowProps {
  transaction: ITransaction;
  companyId: number;
  accountId: number;
}

export const TableRow = ({
  transaction,
  companyId,
  accountId,
}: ITableRowProps) => {
  const {
    amount,
    fee,
    description,
    eventDate,
    tripId,
  } = transaction;

  const getEntrance = () => {
    if (amount && amount > 0) return MoneyFormat.moneyWithDecimal(amount, true);

    return null;
  };

  const getWriteDowns = () => {
    if (amount && amount < 0) return MoneyFormat.moneyWithDecimal(amount, true);

    return null;
  };

  const getFee = () => {
    if (fee && fee > 0) return MoneyFormat.moneyWithDecimal(fee, true);

    return null;
  };

  const getStatus = () => {
    return TransactionsStatusMap[transaction.status] || '';
  };

  return (
    <tr className={ styles.tableRow }>
      <td className={ styles.tableCell }>{ formatDate(eventDate) }</td>
      <td className={ styles.tableCell }>{ description }</td>
      <td className={ styles.tableCell }>
        { tripId && (
          <LinkToNewTab to={ `/account/${accountId}/company/${companyId}/trip/${tripId}` }>
            <span className={ styles.link }>
              { tripId }
            </span>
          </LinkToNewTab>
        ) }
      </td>
      <td className={ styles.tableCell }>{ getEntrance() }</td>
      <td className={ styles.tableCell }>{ getWriteDowns() }</td>
      <td className={ styles.tableCell }>{ getFee() }</td>
      <td className={ styles.tableCell }>{ getStatus() }</td>
    </tr>
  );
};
