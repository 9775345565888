// import Api from '../../api';
import ACTIONS from '../../actions';

let store = null;

class DocumentsConstructorService {
  constructor(InitStore) {
    store = InitStore || this.getStore('DocumentsConstructor');
  }

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);

  setOPZ = (value) => {
    store.dispatch({
      type: ACTIONS.DOCUMENTS_CONSTRUCTOR.SET_OPZ,
      payload: value,
    });
  };

  setDocumentDivideTypes = (value) => {
    store.dispatch({
      type: ACTIONS.DOCUMENTS_CONSTRUCTOR.SET_DOCUMENT_DIVIDE_TYPES,
      payload: value,
    });
  };

  removeDocument = (id, tabId) => {
    store.dispatch({
      type: ACTIONS.DOCUMENTS_CONSTRUCTOR.REMOVE_DOCUMENT,
      payload: { id, tabId },
    });
  };

  saveDocument = (value) => {
    store.dispatch({
      type: ACTIONS.DOCUMENTS_CONSTRUCTOR.SAVE_DOCUMENT,
      payload: value,
    });
  };

  setDivideInvoices = (value) => {
    store.dispatch({
      type: ACTIONS.DOCUMENTS_CONSTRUCTOR.SET_DIVIDE_INVOICES,
      payload: value,
    });
  };
}

export default DocumentsConstructorService;
