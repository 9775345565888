const DEFAULT = {
  isLoading: false,
  listTemplateWarning: [],
  type: 'transfer',
  isEditing: false,
  textNewTemplateWarning: '',
  isNewTemplateWarning: false,
  isError: false,
};

let ACTIONS;

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.WARNING.GET_LIST_TEMPLATE_WARNING: {
      return {
        ...state,
        listTemplateWarning: action.payload,
      };
    }

    case ACTIONS.WARNING.CHANGE_FIELD_TEMPLATE_WARNING: {
      const { widgetId, isActive } = action.payload;

      const changedList = state.listTemplateWarning.map((list) => ({
        ...list,
        isActive: list.id === widgetId ? isActive : false,
      }));

      return {
        ...state,
        listTemplateWarning: [...changedList],
        isError: false,
      };
    }

    case ACTIONS.WARNING.DELETE_FIELD_TEMPLATE_WARNING: {
      return {
        ...state,
        listTemplateWarning: state.listTemplateWarning.filter((list) => list.id !== action.payload),
        isError: false,
      };
    }

    case ACTIONS.WARNING.SET_IS_EDDITING: {
      return {
        ...state,
        isEditing: action.payload,
      };
    }

    case ACTIONS.WARNING.SET_TEXT_TEPMLATE_WARNING: {
      return {
        ...state,
        textNewTemplateWarning: action.payload,
      };
    }

    case ACTIONS.WARNING.SET_ADDITION_FIELD_WARNING: {
      return {
        ...state,
        isNewTemplateWarning: action.payload,
      };
    }

    case ACTIONS.WARNING.SET_SERVER_ERROR: {
      return {
        ...state,
        isError: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('TransferWarning', reducer, DEFAULT);
}
