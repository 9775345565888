import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ExtendedInfoDialog } from '../../../account/components/account/dialogs/extendedInfoDialog';

import styles from './styles/index.module.css';

const LABELS = { EXTENDED_INFORMATION: 'Расширенная информация по юридическим лицам' };

class ExtendedInfo extends Component {
  static propTypes = {
    onSave: PropTypes.func.isRequired,
    getInfo: PropTypes.func.isRequired,
  };

  state = { showDialog: false };

  toggleDialog = () => this.setState({ showDialog: !this.state.showDialog });

  render() {
    const { onSave, getInfo } = this.props;
    const { showDialog } = this.state;

    const extendedDialogHtml = showDialog && (
      <ExtendedInfoDialog
        closeDialog={ this.toggleDialog }
        onSave={ onSave }
        getInfo={ getInfo }
      />
    );

    return (
      <div className={ styles.row }>
        <span className={ `${styles.name} ${styles.extended_info}` } onClick={ this.toggleDialog }>
          { ' ' }
          { LABELS.EXTENDED_INFORMATION }
          { ' ' }
        </span>
        { extendedDialogHtml }
      </div>
    );
  }
}

export { ExtendedInfo };
