import renderComponents from './components';

const NAME = 'sw-taxi-search';

class SearchTaxiModule {
  constructor(name, context, opts) {
    this.box = opts.box;
    this.context = context;

    const filterService = context.getService('Filter');
    const tripService = context.getService('Trip');
    const featureFlagsService = context.getService('FeatureFlags');

    filterService.searchOrdersFilter();

    this.page = renderComponents(this.box, {
      filterService,
      tripService,
      featureFlagsService,
      toTrip: this.moveToTrip,
      moveToCompany: this.moveToCompany,
    });
  }

  moveToTrip = (accountId, companyId, tripId) => {
    const path = `/account/${accountId}/company/${companyId}/trip/${tripId}`;
    window.open(path, '_blank');
  };

  moveToCompany = (trip) => {
    const url = `/account/${trip.accountId}/company/${trip.company.id}/account/profile`;
    window.open(url, '_blank');
  };

  destroy() {
    this.page.unmount();

    const filterService = this.context.getService('Filter');
    filterService.cancelSearchFilter();
  }
}

export default function (core) {
  core.addModule(NAME, SearchTaxiModule);
}
