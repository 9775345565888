import { rootService } from '../../../../core/business';

import styles from '../../styles.module.scss';

interface ISignOutProps { isOpen: boolean, }

export const SignOut = ({ isOpen }: ISignOutProps) => {
  const renderText = () => (!isOpen
    ? null
    : (
      <div>
        Выйти
      </div>
    ));

  return (
    <div
      className={ styles.signOut }
      onClick={ rootService.services.workspace.logout }
    >
      <i className='material-icons'>power_settings_new</i>
      { renderText() }
    </div>
  );
};
