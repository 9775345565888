import React, { useState, useEffect } from 'react';
import PropTypes, { string } from 'prop-types';

import { WarningForm } from '../../../../components/WarningForm';

const Warning = ({
  warningService,
  showWarningTemplate,
  onShowWarningTemplate,
}) => {
  const [warningStore, setWarningStore] = useState({
    listTemplateWarning: [],
    isLoading: false,
    isError: false,
    isEditing: false,
    isNewTemplateWarning: false,
    textNewTemplateWarning: '',
    type: string,
  });
  const {
    listTemplateWarning,
    isLoading,
    isError,
    isEditing,
    isNewTemplateWarning,
    textNewTemplateWarning,
    type,
  } = warningStore;

  const updateState = (store) => {
    setWarningStore(store);
  };

  useEffect(() => {
    const unsubscribe = warningService.subscribe(updateState);

    return () => {
      unsubscribe();
    };
  }, []);

  const addFieldTemplateWarning = (isActive) => {
    const params = {
      type, isActive, content: { text: textNewTemplateWarning },
    };

    warningService.addFieldTemplateWarning(params);
    warningService.setAdditionTemplateWarning(false);
    warningService.setTextTemplateWarning('');
  };

  const handleTextAreaChange = (value) => warningService.setTextTemplateWarning(value);

  const handleTemplateWarning = (value) => warningService.setAdditionTemplateWarning(value);

  const deleteFieldTemplateWarning = (id) => warningService.deleteFieldTemplateWarning(id);

  const handleEditTemplate = (value) => warningService.setIsEditing(value);

  const changeFieldTemplateWarning = ({
    id, isActive, content,
  }) => warningService.changeFieldTemplateWarning({
    widgetId: id,
    content: { text: content },
    isActive,
  });

  if (!showWarningTemplate) return null;

  return (
    <WarningForm
      onClose={ () => onShowWarningTemplate(false) }
      listTemplateWarning={ listTemplateWarning }
      isLoading={ isLoading }
      isError={ isError }
      isEditing={ isEditing }
      isNewTemplateWarning={ isNewTemplateWarning }
      textNewTemplateWarning={ textNewTemplateWarning }
      getListTemplateWarning={ warningService.getListTemplateWarning }
      addFieldTemplateWarning={ addFieldTemplateWarning }
      handleTextAreaChange={ handleTextAreaChange }
      deleteFieldTemplateWarning={ deleteFieldTemplateWarning }
      handleTemplateWarning={ handleTemplateWarning }
      handleEditTemplate={ handleEditTemplate }
      changeFieldTemplateWarning={ changeFieldTemplateWarning }
    />
  );
};

Warning.propTypes = {
  warningService: PropTypes.object.isRequired,
  showWarningTemplate: PropTypes.bool.isRequired,
  onShowWarningTemplate: PropTypes.func.isRequired,
};

export { Warning };
