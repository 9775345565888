import React, { Component } from 'react';
import PropTypes from 'prop-types';

import CircularLoaders from '../../../components/loaders';
import Button from '../../../components/button/Button';
import NoResult from '../../../components/NoResult';
import Input from '../../../components/input';
import Suggest from '../../../components/Suggest';

import { makeDefaultForm } from '../../../bi/utils/regions';
import lodashReplaces from '../../../bi/utils/lodashReplaces';

import { FIELDS, TEXT } from '../../../bi/constants/regions';
import { float } from '../../../bi/constants/regExp';
import { ENUMS } from '../../../bi/constants/employee';

import styles from '../styles/index.module.scss';

const MODAL_TYPES = {
  ERROR: 'error',
  SAVE: 'save',
  DELETE: 'delete',
  RESTORE: 'restore',
};

const LABELS = {
  TITLE: 'Основная информация',
  NO_REGION: 'Что то пошло не так',
  SAVE_REGION: 'Регион успешно сохранен',
  WAIT: 'подожди подожди',
};

const RELATED_FIELDS = { [FIELDS.COUNTRY_NAME_RU]: FIELDS.COUNTRY_CODE };

const isRelatedFields = (field) => Object.values(RELATED_FIELDS)
  .flat()
  .includes(field);

const isCantInput = (field, value) => {
  if (field === FIELDS.LATITUDE || field === FIELDS.LONGITUDE || field === FIELDS.RADIUS) {
    const isInputInvalid = !float.test(value);

    return isInputInvalid;
  }

  return false;
};

class Region extends Component {
  static propTypes = {
    regionsService: PropTypes.object.isRequired,
    workplaceService: PropTypes.object.isRequired,
    regionId: PropTypes.string,
    isAdd: PropTypes.bool,
    toRegion: PropTypes.func,
  };

  static defaultProps = {
    isAdd: false,
    regionId: null,
    toRegion: () => {},
  };

  constructor(props) {
    super(props);
    const { regions, isRequest } = props.regionsService.get();
    const { enums } = props.workplaceService.get();
    this.state = {
      enums,
      regions,
      isRequest,
      modalType: null,
    };
  }

  async componentDidMount() {
    const {
      regionsService, workplaceService, regionId, isAdd,
    } = this.props;
    this.unsubscribeRegions = regionsService.subscribe(this.updateRegions);
    this.unsubscribeWp = workplaceService.subscribe(this.updateWp);

    regionsService.reset();
    await this.props.workplaceService.getEnums([ENUMS.CITIZENSHIP]);

    if (isAdd) {
      this.loadDefaultRegion();
    } else {
      await regionsService.getRegion(regionId);
    }
  }

  loadDefaultRegion = async () => {
    const { regionsService } = this.props;

    await regionsService.changeField(makeDefaultForm(), FIELDS.FORM_DATA);
    await regionsService.changeField(false, FIELDS.IS_NO_EDIT);

    this.setValidation();
  };

  componentWillUnmount() {
    this.unsubscribeRegions();
  }

  updateRegions = (state) => this.setState({ ...state });

  updateWp = ({ enums }) => this.setState({ enums });

  setValidation = () => {
    const { formData } = this.state;
    const { regionsService } = this.props;
    const { isValid, validation } = regionsService.getValidation(formData);

    const paths = [
      { value: isValid, path: FIELDS.IS_VALID_FORM },
      { value: validation, path: FIELDS.VALIDATION },
    ];

    regionsService.changeFields(paths);
  };

  renderErrorModal = () => (
    <NoResult
      message={ LABELS.NO_REGION }
      onClose={ this.handleShowModal(null) }
    />
  );

  renderSaveModal = () => {
    const onClose = () => {
      this.setState({ modalType: null }, () => {
        if (this.props.isAdd) {
          this.props.toRegion(this.state.addedRegionId);
          location.reload();
        } else {
          location.reload();
        }
      });
    };

    return (
      <NoResult
        message={ LABELS.SAVE_REGION }
        onClose={ onClose }
      />
    );
  };

  handleShowModal = (modalType) => () => this.setState({ modalType });

  modalTypesFn = {
    [MODAL_TYPES.ERROR]: this.renderErrorModal,
    [MODAL_TYPES.SAVE]: this.renderSaveModal,
  };

  getSelectList = (field) => {
    const { enums } = this.state;

    if (enums && field === FIELDS.COUNTRY_NAME_RU) {
      return enums.citizenship.map((el, i) => ({
        ...el, name: el.Name, id: i,
      }));
    }

    return [];
  };

  renderDialog = (type) => {
    if (!type) {
      return null;
    }
    const renderFn = this.modalTypesFn[type];

    return renderFn ? renderFn() : null;
  };

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderEmptyList = () => (
    <div className='empty-list'>
      { LABELS.NO_REGION }
    </div>
  );

  handleConfirm = () => {
    const {
      regionsService: {
        normalizeRegion, updateRegion, addRegion,
      }, isAdd,
    } = this.props;
    const { formData } = this.state;
    const normalizedFormData = normalizeRegion(formData);
    const fnReq = isAdd ? addRegion : updateRegion;

    fnReq(normalizedFormData)
      .then(() => {
        this.setState({ modalType: MODAL_TYPES.SAVE });
      })
      .catch(() => {
        this.setState({ modalType: MODAL_TYPES.ERROR });
      });
  };

  handleChangeEdit = (isNoEdit) => () => {
    this.props.regionsService.changeField(isNoEdit, FIELDS.IS_NO_EDIT);
  };

  handleChangeInput = async (e, field) => {
    const { target: { value } } = e;
    if (isCantInput(field, value)) {
      e.preventDefault();

      return;
    }

    const { regionsService: { doValidation, changeField } } = this.props;
    await changeField(value, `${FIELDS.FORM_DATA}.${field}.${FIELDS.VALUE}`);

    doValidation(value, field);
  };

  handleSelect = async (item, field) => {
    const { regionsService: { doValidation, changeField } } = this.props;

    await changeField(item.name, `${FIELDS.FORM_DATA}.${field}.${FIELDS.VALUE}`);

    const relatedField = RELATED_FIELDS[field];

    if (relatedField) {
      await changeField(item.Code, `${FIELDS.FORM_DATA}.${relatedField}.${FIELDS.VALUE}`);
    }

    doValidation(item.Name, field);
  };

  renderInput = ({
    label, path, value,
  }) => {
    const { validation, isNoEdit } = this.state;
    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';

    return (
      <div>
        <Input
          value={ value }
          field={ path }
          label={ label }
          onChange={ isRelatedFields(path) ? null : (e, field) => this.handleChangeInput(e, field) }
          disabled={ isNoEdit }
          valid={ valid }
        />
      </div>
    );
  };

  types = {
    input: ({
      label, path, value,
    }) => this.renderInput({
      label, path, value,
    }),
    select: ({
      label, path, value,
    }) => this.renderSelect({
      label, path, value,
    }),
  };

  renderElement = ({
    label, field, type, value,
  }) => (
    <div key={ field } className={ styles.item }>
      { this.types[type]({
        label, path: field, value,
      }) }
    </div>
  );

  renderSelect = ({
    label, path, value: valueData,
  }) => {
    const currentLabel = valueData;
    const { isNoEdit, validation } = this.state;
    const valid = validation ? lodashReplaces.getValueInObjByPath(validation, path) : '';
    const list = this.getSelectList(path);

    if (list.length === 0) {
      return LABELS.WAIT;
    }

    return (
      <Suggest
        title={ label }
        valid={ valid }
        suggests={ list }
        currentLabel={ currentLabel }
        onSelect={ (value) => this.handleSelect(value, path) }
        withScroll
        disabled={ isNoEdit }
      />
    );
  };

  renderButtonsBlock = () => {
    const {
      isNoEdit,
      isValidForm,
    } = this.state;
    const { isAdd } = this.props;

    const editBtnHtml = !isAdd && (
      <Button
        label={ TEXT.BUTTONS.EDIT }
        className={ styles.btn_main_item }
        disabled={ !isNoEdit }
        onClick={ this.handleChangeEdit(false) }
      />
    );

    return (
      <div className={ styles.btn_main_wrap }>
        <Button
          onClick={ this.handleConfirm }
          label={ TEXT.BUTTONS.SAVE }
          disabled={ isNoEdit || !isValidForm }
          className={ styles.btn_main_item }
        />
        { editBtnHtml }
      </div>
    );
  };

  renderElements = () => {
    const { formData } = this.state;
    const keys = Object.keys(formData).filter((key) => key !== 'Id');

    return (
      <div className={ styles.list }>
        { keys.map((key) => this.renderElement(formData[key])) }
      </div>
    );
  };

  render() {
    const { isAdd } = this.props;
    const {
      modalType,
      formData,
      regionLoad,
      btnRequest,
      enums,
    } = this.state;

    const isEditRegionLoad = !isAdd && regionLoad;

    if (isEditRegionLoad || btnRequest || !enums) {
      return this.renderLoading();
    }

    if (!formData && !isEditRegionLoad) {
      return this.renderEmptyList();
    }

    return (
      <div className={ styles.wrap }>
        <div className={ styles.content }>
          <h2>{ LABELS.TITLE }</h2>
          <div className={ styles.list }>
            { this.renderElements() }
          </div>
        </div>
        { this.renderButtonsBlock() }
        { this.renderDialog(modalType) }
      </div>
    );
  }
}

export default Region;
