import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'sw-ui';

import HotelContractForm from './hotelContractForm';
import NoResult from '../../../../../components/NoResult';
import { formatDate } from '../../../../../bi/utils/formatDate';

import COMPONENTS from '../../../../../bi/constants/components';
import {
  LABELS_HOTEL_CONTRACT,
  ERROR_MESSAGE,
} from '../../../../../bi/constants/profile';

import styles from '../../styles/account.module.scss';

const {
  TOOLTIP: { POSITION: { RIGHT } },
  BUTTON: { THEME: { FLAT } },
} = COMPONENTS;

export default class HotelContract extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    displayRates: PropTypes.bool.isRequired,
    isSmartAgent: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);
    const { profile, hotelContractData } = props.companyService.get();
    this.state = {
      profile,
      hotelContractData,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.companyService.subscribe(this.updateState);

    this.props.companyService.getRoomType();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = (state) => this.setState({ ...state });

  handleCloseErrorDialog = () => this.props.companyService.closeErrorDialog();

  renderErrorDialog = (showError, message) => (
    showError
    && <NoResult
      message={ message }
      onClose={ this.handleCloseErrorDialog }
    />
  );

  handleAddHotelContract = () => this.props.companyService.addHotelContract();

  handleCloseHotelContract = () => this.props.companyService.closeHotelContract();

  handleShowHotelContract = (id = null) => this.props.companyService.getHotelContract(id);

  renderItem = (item) => {
    const {
      HotelName, City, Start, End, IsDraft, Id,
    } = item;
    const selectNameButton = IsDraft
      ? LABELS_HOTEL_CONTRACT.DRAFT_CONTRACT
      : LABELS_HOTEL_CONTRACT.SHOW_CONTRACT;

    return item && (
      <div
        key={ Id }
        className={ `${styles.row} ${styles['button-change']}` }
      >
        <div className={ `${styles.hotel_info} ${styles['col-1-3']}` }>
          <div className={ `${styles.hotel_info__item} ${styles['col-1-2']}` }>
            <div>
              { HotelName }
              ,
              { ' ' }
              { City }
            </div>
          </div>
          <div className={ styles.hotel_info__item }>
            <div>
              { formatDate(Start) }
              { ' ' }
              -
              { ' ' }
              { formatDate(End) }
            </div>
          </div>
        </div>
        <Button
          label={ selectNameButton }
          theme={ FLAT }
          onClick={ () => this.handleShowHotelContract(item.Id) }
          className={ styles.button }
        />
      </div>
    );
  };

  renderShowHotelCodeForm = () => {
    const {
      hotelContractData, profile: {
        details: {
          CompanyName, CompanyId, AccountId,
        },
      },
    } = this.state;
    const {
      companyService, accountService, hotelsService, displayRates,
    } = this.props;

    const companyData = {
      companyName: CompanyName,
      companyId: CompanyId,
      accountId: AccountId,
    };

    return (
      <HotelContractForm
        companyService={ companyService }
        accountService={ accountService }
        hotelsService={ hotelsService }
        onClose={ this.handleCloseHotelContract }
        hotelContractData={ hotelContractData }
        companyData={ companyData }
        displayRates={ displayRates }
      />
    );
  };

  renderTooltip = () => (this.props.isSmartAgent
    ? (
      <Tooltip
        position={ RIGHT }
      >
        { ERROR_MESSAGE.TOOLTIP_ERROR }
      </Tooltip>
    )
    : null);

  render() {
    const { isSmartAgent } = this.props;
    const {
      hotelContractData: {
        showHotelContractDialog, showErrorDialog, showErrorHotelContractDialog,
      }, profile: { hotelContracts },
    } = this.state;
    const hotelCodeFormHtml = showHotelContractDialog && this.renderShowHotelCodeForm();

    return (
      <div className={ styles.hotel_corporate }>
        <div className={ styles.form }>
          <label>Корпоративные договоры с отелями</label>
          { hotelContracts.map(this.renderItem) }
          <div className={ styles.row }>
            <div className='sw-tooltip-wrapper'>
              <Button
                label={ LABELS_HOTEL_CONTRACT.ADD_CONTRACT }
                theme={ FLAT }
                onClick={ () => this.handleAddHotelContract() }
                className={ styles.button }
                disabled={ isSmartAgent }
              />
              { this.renderTooltip() }
            </div>
          </div>
        </div>
        { hotelCodeFormHtml }
        { this.renderErrorDialog(showErrorDialog, ERROR_MESSAGE.SAVE) }
        { this.renderErrorDialog(showErrorHotelContractDialog, ERROR_MESSAGE.GET) }
      </div>
    );
  }
}
