import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles/employee.module.scss';
import { preparedCardText } from '../../bi/utils/employee';
import { ANCHORS } from '../../bi/constants/employee';

class EmployeeBonusCards extends React.Component {
  static propTypes = {
    bonuses: PropTypes.array.isRequired,
    bonusCodes: PropTypes.object.isRequired,
    setRef: PropTypes.func.isRequired,
  };

  renderTrainCard = () => {
    const { bonuses, bonusCodes } = this.props;
    const { Train } = bonusCodes;
    const trainCompanyCard = preparedCardText(bonuses, Train, 'Train');
    if (!trainCompanyCard.length) {
      return null;
    }
    const { name, value } = trainCompanyCard[0];

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1-2'] }>
          <label>{ `РЖД бонусная карта ${name}:` }</label>
        </div>
        <div className={ styles['col-1-3'] }>
          <span>{ value }</span>
        </div>
      </div>
    );
  };

  renderAirCards = () => {
    const { bonuses, bonusCodes } = this.props;
    const { Air } = bonusCodes;
    const airCompanyCards = preparedCardText(bonuses, Air, 'Air');
    if (!airCompanyCards.length) {
      return null;
    }

    return (
      <div>
        { airCompanyCards.map(({
          id, value, name,
        }) => (
          <div className={ styles.row } key={ id }>
            <div className={ styles['col-1-2'] }>
              <label>Бонусная карта авиакомпании</label>
            </div>
            <div className={ styles['col-1-3'] }>
              <span>
                Авиакомпания
                { name }
                { ' ' }
                номер бонуса
                { value }
              </span>
            </div>
          </div>
        )) }
      </div>
    );
  };

  render() {
    const { setRef } = this.props;

    return (
      <div className={ styles.col1 } ref={ (ref) => setRef(ANCHORS.BONUS_CARDS, ref) }>
        <div className={ styles.title }>
          БОНУСНЫЕ КАРТЫ
        </div>
        { this.renderTrainCard() }
        { this.renderAirCards() }
      </div>

    );
  }
}

export default EmployeeBonusCards;
