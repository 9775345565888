import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import styles from './styles/index.module.css';

const TPDetails = ({ details }) => {
  const renderMessages = (messages, exceptionName) => {
    const mainTitle = !exceptionName && (
      <p>
        <b>
          Убедитесь, что услуга удовлетворяет примененным travel политикам, либо согласована с администратором аккаунта.
        </b>
      </p>
    );

    const titleBlock = exceptionName || 'Разрешено:';

    const titleForEmptyMessage = !messages.length && !exceptionName && <p><b>Ограничений нет</b></p>;

    const renderMessagesList = messages.map((message, i) => (
      <div key={ i } className={ styles['messages-item'] }>
        <b>
          { message }
        </b>
      </div>
    ));

    return (
      <div className={ styles.messages }>
        { mainTitle }
        <p>
          { titleBlock }
        </p>
        { titleForEmptyMessage }
        { renderMessagesList }
      </div>
    );
  };

  const renderExeption = (exceptions) => {
    if (!exceptions.length) {
      return null;
    }

    return (
      <div>
        <span >Исключения:</span>
        { exceptions.map(({ name, rules }) => (
          <div key={ `${name}--${rules}` }>
            { renderMessages(rules, name) }
          </div>
        )) }
      </div>
    );
  };

  return details && details.Messages.length
    ? (
      <div className={ `sw-tooltip-wrapper ${styles.wrapper}` }>
        <div className={ styles.info }>↑ Travel политика</div>
        <Tooltip
          position='right'
        >
          <div className={ styles.name }>{ details.Name }</div>
          <div className={ styles.description }>{ details.Description }</div>
          { renderMessages(details.Messages.rules) }
          { renderExeption(details.Messages.exception) }
        </Tooltip>
      </div>
    )
    : null;
};

TPDetails.propTypes = { details: PropTypes.object };

TPDetails.defaultProps = { details: null };

export default TPDetails;
