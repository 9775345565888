import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tabs, TabsItem } from 'sw-ui';

import Account from './account';
import { Statistic } from './statistic';

import AllEmployees from './allEmployees/allEmployees';

import { THEMEACTION } from '../../../bi/constants/rights';

import styles from '../styles/company.module.scss';

const LABELS = {
  ALL_EMPLOYEES: 'allEmployees',
  ALL_EMPLOYEES_RU: 'Все сотрудники',
  STATISTIC: 'statistic',
  ACCOUNT: 'account',
  PROFILE: 'profile',
  ACCOUNT_PROFILE: 'Профиль аккаунта',
  PRODUCT: 'Продукт',
};

class MainNavigation extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    toAnotherTab: PropTypes.func.isRequired,
    toAnotherCompany: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    userService: PropTypes.object.isRequired,
    staticsService: PropTypes.object.isRequired,
    employeeService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
  };

  toAnotherTab = (mainTabId = LABELS.ACCOUNT, title) => {
    const { params: { tabId = LABELS.PROFILE }, toAnotherTab } = this.props;

    return (
      <div
        className={ styles['tab-link'] }
        onClick={ () => toAnotherTab({ mainTabId, tabId }) }
      >
        { title }
      </div>
    );
  };

  render() {
    const {
      companyService,
      notificationsService,
      accountService,
      params,
      featureFlagsService,
      userService,
      staticsService,
      toAnotherCompany,
      employeeService,
      tripService,
    } = this.props;

    const {
      companyId, mainTabId, accountId,
    } = params;

    return (
      <div>
        <Tabs theme={ THEMEACTION.DEFAULT } activeTabId={ mainTabId } >
          <TabsItem
            id={ LABELS.ACCOUNT }
            title={ () => this.toAnotherTab(LABELS.ACCOUNT, LABELS.ACCOUNT_PROFILE) }
          >
            <Account
              accountId={ accountId }
              companyId={ companyId }
              userService={ userService }
              accountService={ accountService }
              companyService={ companyService }
              notificationsService={ notificationsService }
              toAnotherCompany={ toAnotherCompany }
              featureFlagsService={ featureFlagsService }
              tripService={ tripService }
            />
          </TabsItem>
          <TabsItem
            id={ LABELS.STATISTIC }
            title={ () => this.toAnotherTab(LABELS.STATISTIC, LABELS.PRODUCT) }
          >
            <Statistic
              accountId={ accountId }
              staticsService={ staticsService }
            />
          </TabsItem>
          <TabsItem
            id={ LABELS.ALL_EMPLOYEES }
            title={ () => this.toAnotherTab(LABELS.ALL_EMPLOYEES, LABELS.ALL_EMPLOYEES_RU) }
          >
            <AllEmployees
              employeeService={ employeeService }
              params={ params }
            />
          </TabsItem>
        </Tabs>
      </div>
    );
  }
}

export default MainNavigation;
