import ACTIONS from '../../actions';
import Api from '../../api';

let store = null;

class WorkplaceService {
  constructor(InitStore) {
    store = InitStore || this.getStore('Workspace');
  }

  check = () => new Promise((res) => {
    let isAuth = false;
    const user = Api.currentUser;

    if (user && user.access_token) {
      isAuth = true;

      store.dispatch({
        type: ACTIONS.WORKSPACE.INIT,
        user,
      });
    }

    res({ isAuth });
  });

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);

  login = ({ UserName, Password }) => Api.login(UserName, Password).then((res) => {
    if (res === 200) {
      const user = {
        name: UserName,
        ...Api.currentUser,
      };

      store.dispatch({
        type: ACTIONS.WORKSPACE.INIT,
        user,
      });
    }
  });

  getUserName = () => Api.UserName.getUserName().then((payload) => {
    store.dispatch({
      type: ACTIONS.WORKSPACE.GETUSERNAME,
      payload,
    });
  })
    .catch((e) => {
    // eslint-disable-next-line
    console.error(e)
    });

  getEnums = async (data) => {
    try {
      const enums = await Api.Enums.getEnums(data);
      store.dispatch({
        type: ACTIONS.WORKSPACE.GET_ENUMS,
        payload: { enums },
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.WORKSPACE.GET_ENUMS,
        payload: { enums: null },
      });
    }
  };

  logout = () => new Promise((resolve) => {
    Api.logout();
    store.dispatch({
      type: ACTIONS.WORKSPACE.UPDATE,
      user: {},
    });

    resolve();
  });
}

export default WorkplaceService;
