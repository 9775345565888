import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { ACCOUNTTEXT } from '../../bi/constants/account';
import COMPONENTS from '../../bi/constants/components';

import TextArea from '../textarea';
import AjaxButton from '../ajaxButton';

import styles from './styles/index.module.css';

const CommentComponent = ({
  placeholder,
  position,
  waitingResponse,
  height,
  value,
  onChange,
  limit,
  onClose,
  onSave,
}) => {
  const [comment, setComment] = useState(value || '');

  const handleChange = (newComment) => setComment(newComment, onChange(newComment));

  const disabled = limit && limit < comment.length;

  return (
    <div className={ `${styles.wrap} ${styles[position]}` }>
      <TextArea
        className={ styles.textarea }
        value={ comment }
        placeholder={ placeholder }
        height={ height }
        onChange={ handleChange }
      />
      <div className={ `${styles.actions} ${styles[position]}` }>
        <div>
          <Button
            label={ ACCOUNTTEXT.CANCEL }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ onClose }
          />
        </div>
        <div className={ styles.action }>
          <AjaxButton
            disabled={ !!disabled }
            label={ ACCOUNTTEXT.SAVE }
            loading={ waitingResponse }
            onClick={ disabled ? () => {} : () => onSave(comment) }
          />
        </div>
      </div>
    </div>
  );
};

CommentComponent.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  position: PropTypes.string,
  waitingResponse: PropTypes.bool.isRequired,
  limit: PropTypes.number,
  height: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

CommentComponent.defaultProps = {
  value: '',
  height: 100,
  limit: 0,
  position: COMPONENTS.COMMENTCOMPONENT.POSITION.RIGHT,
  onChange: () => {},
};

export default CommentComponent;
