import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Button } from 'sw-ui';

import { FlatButton } from '../../components/button';
import { FormNewTrip } from '../trip/components/formNewTrip';
import { renderNew } from '../trip/components/editPanel/editPanel';

import {
  prepareUsavedItem, prepareDataCreate, validateIntercomLink,
} from '../../bi/utils/cart';

import { SERVICETYPE, SERVICETYPERU } from '../../bi/constants/serviceType';
import { LABELS_EDIT, VALIDATE_INTERCOM_LINK } from '../../bi/constants/cart';

import styles from '../trip/styles/trip.module.scss';

const LABELS = { NEW_CART: 'Создание новой корзины' };

const CartNew = ({
  appService,
  cartsAndNotesService,
  companyService,
  tripService,
  guidService,
  travelPolicyService,
  moveToCart,
}) => {
  const [store, setStore] = useState({
    users: [],
    companies: [],
    analytics: [],
    userAnalytics: [],
    mandatoryProject: [],
    itemCart: null,
    newCartLink: '',
  });
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [versions, setVersions] = useState([]);
  const [validation, setValidation] = useState({ IntercomLink: '' });

  const submenu = useRef();

  const accountId = appService.get().params.accountId;
  const user = appService.get().params.user;
  const companyId = appService.get().params.companyId;

  const {
    users,
    companies,
    projects,
    analytics,
    mandatoryProject,
    userAnalytics,
    itemCart,
    newCartLink,
  } = store;

  const updateState = (newStore) => {
    const companiesList = newStore.companies.map(({
      CompanyId, CompanyName, ShortCompanyName,
    }) => ({
      id: CompanyId,
      name: ShortCompanyName || `${CompanyName} (${CompanyId})`,
    }));
    const projectsEmployee = newStore.projects.length
      ? newStore.projects.map(({
        Id, IsArchival, Name,
      }) => ({
        id: Id,
        name: Name,
        isArchival: IsArchival,
      }))
      : [];
    const analyticsList = newStore.analytics.filter(({ ApplyToTrip }) => !ApplyToTrip);

    setStore({
      ...newStore,
      companies: companiesList,
      projects: projectsEmployee,
      analytics: analyticsList,
    });
  };

  const unsubscribe = () => cartsAndNotesService.subscribe(updateState);

  const handleTouchOutside = (event) => {
    if (submenu.current && !submenu.current.contains(event.target)) {
      setOpenSubMenu(false);
    }
  };

  useEffect(() => {
    unsubscribe();

    cartsAndNotesService.loadCartsAndNotes(user, accountId, companyId);
    companyService.setId(companyId);
    companyService.loadProfile(accountId);

    document.addEventListener('click', handleTouchOutside);

    return () => {
      document.removeEventListener('click', handleTouchOutside);
    };
  }, []);

  const handleCancelNew = () => cartsAndNotesService.setItemCart(null);

  const handleMoveToCart = () => {
    moveToCart();
    handleCancelNew();
  };

  const handleEditItem = async (item) => {
    const tripToEdit = versions.find((version) => version.Guid === item.Guid);
    const newVersions = versions.filter((version, index) => {
      if (version.Guid === item.Guid) {
        tripToEdit.indexEdited = index;
      }

      return version.Guid !== item.Guid;
    });

    setVersions(newVersions);

    let departments = [];

    if (item.EmployeesList.length) {
      departments = await cartsAndNotesService.getDepartments(item.EmployeesList[0].Id, companyId);
    }

    cartsAndNotesService.setItemCart(prepareUsavedItem(item, tripToEdit, departments));
  };

  const handleChangeInput = (e, field, value) => cartsAndNotesService.setIntercomLink(value);

  const handleValidateInput = () => {
    if (!newCartLink || !validateIntercomLink(newCartLink)) {
      setValidation((pre) => ({ ...pre, IntercomLink: VALIDATE_INTERCOM_LINK }));

      return false;
    }

    setValidation((pre) => ({ ...pre, IntercomLink: '' }));

    return true;
  };

  const handleCopyItem = async (item) => {
    const tripToEdit = versions.find((version) => version.Guid === item.Guid);
    const copyTripToEdit = {
      ...tripToEdit,
      CompanyId: null,
      EmployeesList: [{}],
      ProjectId: null,
      UserAnalytics: [],
    };

    cartsAndNotesService.setItemCart(prepareUsavedItem(item, copyTripToEdit, []));
  };

  const handleConfirmNew = async (item) => {
    handleCancelNew();

    const modifiedItem = item;

    modifiedItem.OrderTripId = 0;
    modifiedItem.Guid = item.Guid;

    const newState = versions;
    const itemIndex = newState.findIndex((version) => version.Guid === modifiedItem.Guid);

    if (item.indexEdited !== null) {
      newState.splice(modifiedItem.indexEdited, 0, modifiedItem);

      return setVersions(newState);
    }

    if (itemIndex >= 0) {
      newState.versions[itemIndex] = modifiedItem;
    } else {
      newState.versions.push(modifiedItem);
    }

    return setVersions(newState);
  };

  const handleCreate = async () => {
    if (!handleValidateInput()) return;

    const data = {
      UserId: user,
      UserAnalytics: userAnalytics,
      IntercomLink: newCartLink,
      Items: versions.map((version) => prepareDataCreate(version)),
    };

    await cartsAndNotesService.createCart(data);
    handleMoveToCart();
  };

  const handleAddOrderItem = () => setOpenSubMenu(true);

  const handleNewItem = (type) => {
    const item = tripService.addNewItem(type);

    setOpenSubMenu(false);
    cartsAndNotesService.setItemCart(item);
  };

  const setTripAnalytics = (analyticsValues = []) => cartsAndNotesService.setAnalytics(analyticsValues);

  const renderOrderSubMenu = () => (
    <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
      <div className={ styles['menu-item'] } onClick={ () => handleNewItem(SERVICETYPE.HOTEL) }>
        { SERVICETYPERU[SERVICETYPE.HOTEL] }
      </div>
    </div>
  );

  const renderAddOrderButton = () => (
    <Button theme='flat' label={ LABELS_EDIT.ADD_ORDER } onClick={ handleAddOrderItem } />
  );

  return (
    <div className={ styles.wrap }>
      <div className={ styles['button-trip__left'] }>
        <FlatButton onClick={ handleMoveToCart }>
          <i className='material-icons'>keyboard_backspace</i>
          { ' ' }
          { LABELS_EDIT.TO_CART }
        </FlatButton>
      </div>
      <FormNewTrip
        users={ users }
        versions={ versions }
        submenu={ submenu }
        label={ LABELS.NEW_CART }
        isCreateCart
        intercomLink={ newCartLink }
        onEditItem={ handleEditItem }
        onChangeInput={ handleChangeInput }
        onValidationInput={ handleValidateInput }
        validation={ validation }
        onCopyItem={ handleCopyItem }
        onConfirmNew={ handleCreate }
        addOrderButtonHtml={ renderAddOrderButton }
        renderOrderSubMenu={ renderOrderSubMenu }
      />
      <div
        className={ styles.row }
      >
        <div className={ styles.col }>
          { renderNew({
            companies,
            mandatoryProject,
            projectsForNewTrip: projects,
            tripService,
            travelPolicyService,
            accountId,
            item: itemCart,
            onConfirm: handleConfirmNew,
            onCancel: handleCancelNew,
            analytics,
            tripAnalyticsValueIds: [],
            setTripAnalytics,
            addTrip: () => {},
            guidService,
            isItemCart: true,
          }) }
        </div>
      </div>
    </div>
  );
};

CartNew.propTypes = {
  appService: PropTypes.object.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  companyService: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  guidService: PropTypes.object.isRequired,
  travelPolicyService: PropTypes.object.isRequired,
  moveToCart: PropTypes.func.isRequired,
};

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  const page = (
    <CartNew
      appService={ opts.appService }
      cartsAndNotesService={ opts.cartsAndNotesService }
      companyService={ opts.companyService }
      tripService={ opts.tripService }
      guidService={ opts.guidService }
      travelPolicyService={ opts.travelPolicyService }
      moveToCart={ opts.moveToCart }
    />
  );

  root.render(page);

  return root;
};

export default renderComponents;
