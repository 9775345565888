import React from 'react';
import PropTypes from 'prop-types';

import EmployeeSuggest from '../../../../../../components/employee';

import style from '../../../../styles/form.module.scss';

const EmployeeFields = ({
  employeesListForPick,
  employeesList,
  companyId,
  onRemoveEmployeeSuggest,
  onSelectEmployee,
  onEmployeeFocus,
  onChangeEmployee,
  validation,
}) => {
  const renderEmployeeSuggest = (el, index) => {
    const col = employeesListForPick.length > 3 ? employeesListForPick.length : 3;
    const valid = index === 0 ? validation : null;

    return (
      <div
        className={ `${style[`col-1-${col}`]} ${style['employee-item-suggest']}` }
        key={ `employee_suggest_${index}` }
      >
        <EmployeeSuggest
          items={ employeesList }
          selected={ employeesListForPick[index] }
          placeholder={ `Сотрудник ${index + 1}` }
          onRemove={ () => onRemoveEmployeeSuggest(index) }
          onSelect={ (item) => onSelectEmployee(item, index) }
          onFocus={ onEmployeeFocus }
          onChange={ onChangeEmployee }
          disablePick={ !companyId }
          valid={ valid }
        />
      </div>
    );
  };

  return (
    <div className={ style.row }>
      <div className={ style['employees-list'] }>
        { employeesListForPick.map(renderEmployeeSuggest) }
      </div>
    </div>
  );
};

EmployeeFields.propTypes = {
  employeesListForPick: PropTypes.array.isRequired,
  employeesList: PropTypes.array.isRequired,
  companyId: PropTypes.number.isRequired,
  onRemoveEmployeeSuggest: PropTypes.func.isRequired,
  onSelectEmployee: PropTypes.func.isRequired,
  onEmployeeFocus: PropTypes.func.isRequired,
  onChangeEmployee: PropTypes.func.isRequired,
  validation: PropTypes.string.isRequired,
};

export { EmployeeFields };
