const NUMBERS = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
  SIX: 6,
  SEVEN: 7,
  EIGHT: 8,
};

const INVALID_LINK = 'Невалидная ссылка';
const NOT_FOUND = 'Не удалось получить список номенклатур';

const SOURCE_TYPE = {
  TRIP_ITEM_ID: 'TripItemId',
  TICKET_NUMBER: 'TicketNumber',
};

const ORGANIZATION = [
  { label: 'Smartway', value: 1 },
  { label: 'Савади', value: 2 },
];

const COUNTERAGENT = [
  { label: 'Smartway', value: 1 },
  { label: 'Клиент', value: null },
];

const TYPE_SERVICE = [
  { label: 'Агентские', value: 'Agency' },
  { label: 'Собственные', value: 'Own' },
];

const GROUPING_TYPE = [
  { label: 'Одна реализация на все заказы', value: 'One' },
  { label: 'Отдельные реализации на каждый заказ', value: 'ByOrder' },
  { label: 'Объединить по клиентам', value: 'ByClient' },
];

const DAY_TYPE = [
  { label: 'Дата реализации из запроса', value: 'FromRequest' },
  { label: 'По дате окончания услуги', value: 'FromCheckoutDate' },
  { label: 'Из названия вкладки excel', value: 'FromSheetTitle' },
];

const EXCEL = { label: 'Из файла excel', value: 'Excel' };

const PRINCIPALS_SOURCE_TYPE = {
  EXCEL: 'FromExcel',
  REQUEST: 'FromRequest',
};

export {
  NUMBERS,
  INVALID_LINK,
  NOT_FOUND,
  EXCEL,
  SOURCE_TYPE,
  ORGANIZATION,
  COUNTERAGENT,
  TYPE_SERVICE,
  GROUPING_TYPE,
  DAY_TYPE,
  PRINCIPALS_SOURCE_TYPE,
};
