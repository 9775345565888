import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles/providerName.module.css';

const ProviderName = ({ providerName }) => {
  if (!providerName.length) return null;

  return (
    <div className={ styles.provider }>
      <span className={ styles['provider-title'] }>Провайдер: </span>
      <span className={ styles['provider-name'] }>{ providerName }</span>
    </div>
  );
};

ProviderName.propTypes = { providerName: PropTypes.string };

ProviderName.defaultProps = { providerName: '' };

export default ProviderName;
