import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';

import { Dialog, Button } from 'sw-ui';

import { FormNewTrip } from './formNewTrip';

import DialogForm from './form/emailDialogForm';
import SelectHotelKnownOrder from './hotel/selectKnownOrder';
import SelectTrainKnownOrder from './train/selectKnownOrder';
import { SelectKnownOrder as SelectTransferKnownOrder } from './transfer/components/SelectKnownOrder/index';
import {
  renderNew, renderCopy, renderCacheItem,
} from './editPanel/editPanel';

import { SERVICETYPE, SERVICETYPERU } from '../../../bi/constants/serviceType';

import lodashReplaces from '../../../bi/utils/lodashReplaces';

import styles from '../styles/trip.module.scss';

const GUID = 'Guid';
const MAX_LENGTH_TRIP_NAME = 196;

class NewTrip extends Component {
  static propTypes = {
    specialBillAccount: PropTypes.object,
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    projectsForNewTrip: PropTypes.arrayOf(PropTypes.object),
    mandatoryProject: PropTypes.array.isRequired,
    users: PropTypes.arrayOf(PropTypes.object),
    tripService: PropTypes.object.isRequired,
    guidService: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    aeroexpressService: PropTypes.object.isRequired,
    travelPolicyService: PropTypes.object.isRequired,
    moveToTrip: PropTypes.func,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    changeRate: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.submenu = createRef();
    this.state = {
      openSubMenu: false,
      openDialog: false,
      newItem: null,
      copyItem: null,
      cacheItem: null,
      name: '',
      versions: [],
      cacheTrips: [],
      sendVouchersDialog: false,
      sendResultVouchers: false,
      resultVouchersText: false,
      tripResult: null,
      companyIdTrip: null,
      userId: '',
      email: '',
      waitResConfirm: false,
      showSelectHotelKnownOrder: false,
      showSelectTrainKnownOrder: false,
      showSelectTransferKnownOrder: false,
      validation: { name: '' },
    };
  }

  scrollDown() {
    window.scrollTo(0, this.editPanel.offsetTop - 100);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleTouchOutside);
  }

  componentDidUpdate() {
    window.onbeforeunload = this.state.versions.length
      ? (e) => {
        e.preventDefault();

        return '';
      }
      : undefined;
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleTouchOutside);

    window.onbeforeunload = undefined;
  }

  handlerChangeInput = (e, field, value) => {
    if (value.length < MAX_LENGTH_TRIP_NAME) {
      this.setState({ [field]: value });
    }
  };

  handleTouchOutside = (event) => {
    const { openSubMenu } = this.state;

    if (this.submenu.current && !this.submenu.current.contains(event.target) && openSubMenu !== false) {
      this.setState({ openSubMenu: false });
    }
  };

  showSendVouchersDialog = () => {
    this.setState({ sendVouchersDialog: !this.state.sendVouchersDialog });
  };

  closeResultVouchersDialog = () => {
    const { tripResult, companyIdTrip } = this.state;
    this.setState({ sendResultVouchers: !this.state.sendResultVouchers });

    if (tripResult) {
      const manualBooking = true;
      this.props.moveToTrip(companyIdTrip, tripResult.tripId, manualBooking);
    }
  };

  onEmailChange = (value) => {
    this.setState({ email: value });
  };

  addTrip = (editTrip) => this.setState({ cacheTrips: [...this.state.cacheTrips, editTrip] });

  addVersions(item) {
    const { guidService } = this.props;
    const { saveTripVersionsCount } = this.props.tripService;

    const modifiedItem = { ...item };

    modifiedItem.OrderTripId = 0;
    modifiedItem.Guid = item.Guid || guidService.generate();

    this.setState((prevState) => {
      const newState = { ...prevState };
      const itemIndex = newState.versions.findIndex((version) => version.Guid === modifiedItem.Guid);

      if (item.indexEdited !== null) {
        newState.versions.splice(modifiedItem.indexEdited, 0, modifiedItem);

        saveTripVersionsCount(newState.versions.length);

        return newState;
      }

      if (itemIndex >= 0) {
        newState.versions[itemIndex] = modifiedItem;
      } else {
        newState.versions.push(modifiedItem);
      }

      saveTripVersionsCount(newState.versions.length);

      return newState;
    });
  }

  updateReservationNumbers = (versions) => {
    if (!versions || versions.length === 0) {
      return versions;
    }

    const firstReservationNumber = JSON.parse(versions[0].JsonData).ReservationNumber || 0;

    return versions.map((version, index) => {
      const versionData = lodashReplaces.cloneDeep(version);
      const jsonData = JSON.parse(versionData.JsonData);

      if (index === 0) {
        jsonData.ReservationNumber = firstReservationNumber;
      }

      if (index >= 1) {
        jsonData.ReservationNumber = `${firstReservationNumber}/${index + 1}`;
      }

      versionData.JsonData = JSON.stringify(jsonData);

      return versionData;
    });
  };

  saveNewTrip = (users) => {
    this.setState({ waitResConfirm: true });

    const {
      companyId,
      tripService,
      tripAnalyticsValueIds,
    } = this.props;

    const {
      userId,
      name,
      versions,
      email,
    } = this.state;

    const versionsWithUpdatedReservationNumbers = this.updateReservationNumbers(versions);

    const orderTrip = {
      UserId: userId,
      Name: name,
      Users: users,
      Versions: versionsWithUpdatedReservationNumbers.map((version) => lodashReplaces.omit(version, GUID)),
      UserAnalytics: tripAnalyticsValueIds,
    };

    const create = () => {
      tripService.create(companyId, orderTrip, email)
        .then((res) => {
          this.setState({
            sendVouchersDialog: false,
            versions: [],
            email: '',
            waitResConfirm: false,
            sendResultVouchers: true,
            resultVouchersText: res.result,
            tripResult: res,
            companyIdTrip: companyId,
          });
        })
        .catch(() => {
          this.setState({
            sendVouchersDialog: false,
            versions: [],
            email: '',
            waitResConfirm: false,
            sendResultVouchers: true,
            resultVouchersText: 'Не удалось выполнить операцию: Внутренняя ошибка сервиса',
          });
        });
    };

    this.setState({ waitResConfirm: true }, create);
  };

  handlerAddOrderItem = () => {
    this.setState({ openSubMenu: true });
  };

  handleNewItem = (type) => {
    const { tripService } = this.props;

    if (type === SERVICETYPE.HOTEL) {
      return this.setState({
        showSelectHotelKnownOrder: true,
        openSubMenu: false,
      });
    }

    if (type === SERVICETYPE.TRAIN) {
      return this.setState({
        showSelectTrainKnownOrder: true,
        openSubMenu: false,
      });
    }

    if (type === SERVICETYPE.TRANSFER) {
      return this.setState({
        showSelectTransferKnownOrder: true,
        openSubMenu: false,
      });
    }

    const newItem = tripService.addNewItem(type);

    return this.setState({
      openSubMenu: false,
      newItem,
    }, this.scrollDown);
  };

  handleCopyItem = (item) => {
    const { tripService } = this.props;

    const copyItem = tripService.copyItem(item);

    this.setState({ copyItem }, this.scrollDown);
  };

  handleEditItem = (item) => {
    const { tripService } = this.props;
    const { versions, cacheTrips } = this.state;

    const tripToEdit = cacheTrips.find((trip) => trip.Guid === item.Guid);
    const newVersions = versions.filter((version, index) => {
      if (version.Guid === item.Guid) {
        tripToEdit.indexEdited = index;
      }

      return version.Guid !== item.Guid;
    });
    const newCacheTrips = cacheTrips.filter((trip) => trip.Guid !== item.Guid);

    const cacheItem = tripService.editItem(tripToEdit);

    this.setState({
      versions: newVersions,
      cacheTrips: newCacheTrips,
      cacheItem,
    }, this.scrollDown);
  };

  handlerConfirmNew = () => {
    const {
      name,
      versions,
      users,
    } = this.state;

    const {
      isValid,
      validation,
    } = this.validationSubmit({ name });

    if (isValid) {
      if (versions.length) {
        this.saveNewTrip(users);
      }
    } else {
      this.setState({ validation: { ...validation } });
    }

    return null;
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handlerConfirmUsers = (users) => (users.length ? this.saveNewTrip(users) : null);

  handleCloseDialog = () => {
    if (this.state.openDialog) {
      this.setState({ openDialog: false });
    }
  };

  handleConfirmNew = (item) => {
    this.addVersions(item);
    this.handleCancelNew();
  };

  handleConfirmCopy = (item) => {
    this.addVersions(item);
    this.handleCancelCopy();
  };

  handleConfirmEdit = (item) => {
    this.addVersions(item);
    this.handleCancelEdit();
  };

  handleCancelNew = () => this.setState({ newItem: null });

  handleCancelCopy = () => this.setState({ copyItem: null });

  handleCancelEdit = () => {
    this.setState({ cacheItem: null });
  };

  handlerSelectUser = (value) => {
    const { accountId, tripService } = this.props;
    tripService.loadMandatoryProject(accountId);

    this.setState({ userId: value });
  };

  handleFetchInfoHotel = (newItem) => this.setState({
    showSelectHotelKnownOrder: false,
    newItem,
  }, this.scrollDown);

  handleFetchInfoTrain = (newItem) => this.setState({
    showSelectTrainKnownOrder: false,
    newItem,
  }, this.scrollDown);

  handleFetchInfoTransfer = (newItem) => {
    this.setState({
      showSelectTransferKnownOrder: false,
      newItem,
    }, this.scrollDown);
  };

  validationSubmit = ({ name }) => {
    const nameTrip = this.props.tripService.tripFieldValidation('name', name);

    if (nameTrip) {
      return {
        isValid: false,
        validation: { name: nameTrip },
      };
    }

    return { isValid: true };
  };

  validationInput = (e, field) => {
    const result = this.props.tripService.tripFieldValidation(field, this.state[field]);

    this.setState((prevState) => {
      const newState = { ...prevState };

      newState.validation[field] = result;

      return newState;
    });
  };

  renderSelectHotelKnownOrder() {
    const { tripService } = this.props;
    const { showSelectHotelKnownOrder } = this.state;

    return showSelectHotelKnownOrder && (
      <SelectHotelKnownOrder
        onInfoFetched={ this.handleFetchInfoHotel }
        tripService={ tripService }
      />
    );
  }

  renderSelectTrainKnownOrder() {
    const { tripService, accountId } = this.props;
    const { showSelectTrainKnownOrder } = this.state;

    return showSelectTrainKnownOrder && (
      <SelectTrainKnownOrder
        onInfoFetched={ this.handleFetchInfoTrain }
        tripService={ tripService }
        accountId={ accountId }
      />
    );
  }

  renderSelectTransferKnownOrder() {
    const { tripService } = this.props;
    const { showSelectTransferKnownOrder } = this.state;

    return showSelectTransferKnownOrder && (
      <SelectTransferKnownOrder
        onInfoFetched={ this.handleFetchInfoTransfer }
        tripService={ tripService }
      />
    );
  }

  render() {
    const {
      companies,
      airService,
      aeroexpressService,
      tripService,
      users,
      projectsForNewTrip,
      mandatoryProject,
      travelPolicyService,
      companyId,
      accountId,
      analytics,
      tripAnalyticsValueIds,
      setTripAnalytics,
      guidService,
      specialBillAccount,
      changeRate,
    } = this.props;

    const {
      openSubMenu,
      newItem,
      copyItem,
      cacheItem,
      name,
      versions,
      validation,
      openDialog,
      // sendVouchersDialog,
      sendResultVouchers,
      resultVouchersText,
      // email,
      waitResConfirm,
      userId,
    } = this.state;

    let dialogHtml = null;
    if (openDialog) {
      dialogHtml = (
        <Dialog onClick={ this.handleCloseDialog }>
          <DialogForm
            users={ users }
            handlerConfirmUsers={ this.handlerConfirmUsers }
          />
        </Dialog>
      );
    }

    let itemHtml = null;

    if (newItem) {
      const { ServiceType, OrderTripItemId } = newItem;
      const noOrder = SERVICETYPE.TRAIN === ServiceType && OrderTripItemId === 0;

      itemHtml = renderNew({
        companies,
        projectsForNewTrip,
        mandatoryProject,
        tripService,
        airService,
        aeroexpressService,
        travelPolicyService,
        companyId,
        accountId,
        item: newItem,
        onConfirm: this.handleConfirmNew,
        onCancel: this.handleCancelNew,
        analytics,
        tripAnalyticsValueIds,
        setTripAnalytics,
        addTrip: this.addTrip,
        guidService,
        specialBillAccount,
        noOrder,
        changeRate,
      });
    } else if (copyItem) {
      itemHtml = renderCopy({
        companies,
        projectsForNewTrip,
        mandatoryProject,
        tripService,
        airService,
        aeroexpressService,
        travelPolicyService,
        companyId,
        item: copyItem,
        accountId,
        onConfirm: this.handleConfirmCopy,
        onCancel: this.handleCancelCopy,
        analytics,
        tripAnalyticsValueIds,
        setTripAnalytics,
        addTrip: this.addTrip,
        guidService,
        changeRate,
      });
    } else if (cacheItem) {
      itemHtml = renderCacheItem({
        companies,
        projectsForNewTrip,
        mandatoryProject,
        tripService,
        airService,
        aeroexpressService,
        travelPolicyService,
        companyId,
        item: cacheItem,
        accountId,
        onConfirm: this.handleConfirmEdit,
        onCancel: this.handleCancelEdit,
        analytics,
        tripAnalyticsValueIds,
        setTripAnalytics,
        addTrip: this.addTrip,
        guidService,
        changeRate,
      });
    }

    const sendResultVouchersHtml = sendResultVouchers
      ? (
        <Dialog onClick={ this.closeResultVouchersDialog }>
          <div className={ styles['user-dialog'] }>
            <p className={ styles['dialog-item'] }>{ resultVouchersText }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button label='Закрыть' theme='flat' onClick={ this.closeResultVouchersDialog } />
            </div>
          </div>
        </Dialog>
      )
      : null;

    const renderAddOrderButton = () => (userId
      ? <Button theme='flat' label='ДОБАВИТЬ ЗАКАЗ' onClick={ this.handlerAddOrderItem } />
      : null);

    const renderOrderSubMenu = () => {
      if (specialBillAccount) {
        return (
          <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
            <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.AIR, e) }>
              { SERVICETYPERU[SERVICETYPE.AIR] }
            </div>
          </div>
        );
      }

      return (
        <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.AIR, e) }>
            { SERVICETYPERU[SERVICETYPE.AIR] }
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.HOTEL, e) }>
            { SERVICETYPERU[SERVICETYPE.HOTEL] }
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.TRANSFER, e) }>
            { SERVICETYPERU[SERVICETYPE.TRANSFER] }
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.CUSTOM, e) }>
            { SERVICETYPERU[SERVICETYPE.CUSTOM] }
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.TRAIN, e) }>
            { SERVICETYPERU[SERVICETYPE.TRAIN] }
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.VIP_HALL, e) }>
            { SERVICETYPERU[SERVICETYPE.VIP_HALL] }
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.AEROEXPRESS, e) }>
            Аэроэкспресс
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.EVENT, e) }>
            Мероприятие
          </div>
          <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem(SERVICETYPE.BUS, e) }>
            { SERVICETYPERU[SERVICETYPE.BUS] }
          </div>
        </div>
      );
    };

    return (
      <div>
        <FormNewTrip
          users={ users }
          userId={ userId }
          nameTrip={ name }
          versions={ versions }
          validation={ validation }
          submenu={ this.submenu }
          loading={ waitResConfirm }
          onSelectUser={ this.handlerSelectUser }
          onChangeInput={ this.handlerChangeInput }
          onValidationInput={ this.validationInput }
          onCopyItem={ this.handleCopyItem }
          onEditItem={ this.handleEditItem }
          onConfirmNew={ this.handlerConfirmNew }
          addOrderButtonHtml={ renderAddOrderButton }
          renderOrderSubMenu={ renderOrderSubMenu }
        />
        { this.renderSelectHotelKnownOrder() }
        { this.renderSelectTrainKnownOrder() }
        { this.renderSelectTransferKnownOrder() }
        <div
          className={ styles.row }
          ref={ (element) => { this.editPanel = element; } }
        >
          <div className={ styles.col }>
            { itemHtml }
          </div>
        </div>
        { dialogHtml }
        { sendResultVouchersHtml }
      </div>
    );
  }
}

NewTrip.defaultProps = {
  users: [],
  companies: [],
  projectsForNewTrip: [],
  moveToTrip: () => {},
  analytics: [],
  tripAnalyticsValueIds: [],
  setTripAnalytics: () => {},
  specialBillAccount: null,
};

export default NewTrip;
