import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Input } from 'sw-ui';

import { NextButton } from './NextButton';

import { SOURCE_TYPE } from '../../../../bi/constants/unloading1c';

import styles from '../../styles.module.css';

const LABELS = {
  TITLE: 'Введите ссылку на документ',
  ERROR: 'Поле не может быть пустым',
  COLUMN_NAME: 'Название столбца с идентификатором заказа',
  PLACEHOLDER: 'TripItemId',
};

const StepOne = ({
  unloading1cService: {
    get,
    getWorkSheets,
    getNomenclatures,
    setError,
    setFileLink,
    setTripItemColumnName,
  },
  onHandleNextStep,
}) => {
  const [inputLinkValue, setInputLinkValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [inputColumnValue, setInputColumnValue] = useState('');

  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const handleChangeInputLink = (value) => setInputLinkValue(value);

  const handleChangeInputColumn = (value) => {
    setInputColumnValue(value);
    setTripItemColumnName(value);
  };

  const handleRequest = async () => {
    setError('');

    if (!inputLinkValue) {
      return setErrorMessage(LABELS.ERROR);
    }

    setLoading(true);
    await getWorkSheets({ FileLink: inputLinkValue, TripItemColumnName: SOURCE_TYPE.TRIP_ITEM_ID });
    await getNomenclatures({ query: '' });

    const { error } = get();

    if (error) {
      setInputLinkValue('');
      setLoading(false);
      setErrorMessage(error);

      return;
    }

    setFileLink(inputLinkValue);
    setErrorMessage('');
    setLoading(false);
    onHandleNextStep();
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.TITLE }
      </div>
      <Input
        placeholder={ LABELS.TITLE }
        value={ inputLinkValue }
        onChange={ handleChangeInputLink }
        error={ errorMessage }
        ref={ inputRef }
      />
      <div className={ styles.title }>
        { LABELS.COLUMN_NAME }
      </div>
      <Input
        placeholder={ LABELS.PLACEHOLDER }
        value={ inputColumnValue }
        onChange={ handleChangeInputColumn }
        readOnly
      />
      <div className={ styles.modal_buttons }>
        <NextButton
          onHandleNextStep={ handleRequest }
          loading={ loading }
          disabled={ loading }
        />
      </div>
    </div>
  );
};

StepOne.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandleNextStep: PropTypes.func.isRequired,
};

export { StepOne };
