const TAXI_CLASSES = {
  ECONOMY: 1,
  COMFORT: 2,
  COMFORT_PLUS: 3,
  BUSINESS: 4,
  CHILDREN: 5,
  MINIVAN: 6,
  CRUISE: 7,
  PREMIER: 8,
  LITE: 9,
  STANDART: 10,
  OPTIMUM: 11,
};

const TAXI_CLASSES_TITLE = {
  [TAXI_CLASSES.ECONOMY]: 'Эконом',
  [TAXI_CLASSES.COMFORT]: 'Комфорт',
  [TAXI_CLASSES.COMFORT_PLUS]: 'Комфорт+',
  [TAXI_CLASSES.BUSINESS]: 'Бизнес',
  [TAXI_CLASSES.MINIVAN]: 'Минивен',
  [TAXI_CLASSES.CHILDREN]: 'Детский',
  [TAXI_CLASSES.CRUISE]: 'Круиз',
  [TAXI_CLASSES.PREMIER]: 'Премьер',
  [TAXI_CLASSES.LITE]: 'Лайт',
  [TAXI_CLASSES.STANDART]: 'Стандарт',
  [TAXI_CLASSES.OPTIMUM]: 'Оптимум',
};

const GENERAL_TAXI_CLASSES = [
  { value: TAXI_CLASSES.BUSINESS, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.BUSINESS] },
  { value: TAXI_CLASSES.MINIVAN, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.MINIVAN] },
  { value: TAXI_CLASSES.CHILDREN, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.CHILDREN] },
  { value: TAXI_CLASSES.CRUISE, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.CRUISE] },
  { value: TAXI_CLASSES.PREMIER, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.PREMIER] },
];

const NEW_TAXI_CLASSES = [
  { value: TAXI_CLASSES.LITE, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.LITE] },
  { value: TAXI_CLASSES.STANDART, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.STANDART] },
  { value: TAXI_CLASSES.OPTIMUM, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.OPTIMUM] },
  ...GENERAL_TAXI_CLASSES,
];

const OLD_TAXI_CLASSES = [
  { value: TAXI_CLASSES.ECONOMY, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.ECONOMY] },
  { value: TAXI_CLASSES.COMFORT, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.COMFORT] },
  { value: TAXI_CLASSES.COMFORT_PLUS, label: TAXI_CLASSES_TITLE[TAXI_CLASSES.COMFORT_PLUS] },
  ...GENERAL_TAXI_CLASSES,
];

export {
  TAXI_CLASSES,
  TAXI_CLASSES_TITLE,
  NEW_TAXI_CLASSES,
  OLD_TAXI_CLASSES,
};
