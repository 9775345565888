import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import styles from '../../styles/settings.module.css';

const LABELS = { TITLE: 'Управление поставщиками по гостиницам' };

const PROVIDERS_NAME = {
  BRONEVIK: 'Bronevik-online',
  DEPRECATD: 'Bronevik',
};

const ControlHotelsProviders = ({
  providersObject,
  businessService,
}) => {
  const [providers, setProviders] = useState(providersObject);

  useEffect(() => {
    setProviders(providersObject);
  }, []);

  const handlerChangeProvider = async (providerName, value) => {
    const newProviders = { ...providers };
    newProviders[providerName] = value;

    try {
      await businessService.setHotelsAggregationProviders(newProviders);
      setProviders(newProviders);
    } catch (e) {
      console.log('Error', e);
    }
  };

  const providersHtml = Object.entries(providers).map(([name, value], id) => {
    const shownName = name === PROVIDERS_NAME.DEPRECATD ? PROVIDERS_NAME.BRONEVIK : name;

    return (
      <div className={ styles.control } key={ `${name}-${id}` }>
        <span className={ styles['control-title'] }>{ shownName }</span>
        <Checkbox
          value={ value }
          className={ styles['control-checkbox'] }
          onChange={ (val) => handlerChangeProvider(name, val) }
        />
      </div>
    );
  });

  return (
    <div className={ styles['scheme-aggregation'] }>
      <div className={ styles['setup-title'] }>
        { LABELS.TITLE }
      </div>
      { providersHtml }
    </div>
  );
};

ControlHotelsProviders.propTypes = {
  providersObject: PropTypes.object.isRequired,
  businessService: PropTypes.object.isRequired,
};

export { ControlHotelsProviders };
