import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import Radio from '../../../../../../components/radio';
import Input from '../../../../../../components/input';
import { Button, FlatButton } from '../../../../../../components/button';

import { preventKeyNotNumber } from '../../../../../../bi/utils/trip';

import {
  HOTEL_PROVIDER_IS_AUTO_CALC_VAT, HOTEL_PROVIDER_VALUE, FIELDS_VAT,
} from '../../../../../../bi/constants/hotel';
import { VAT_VALUE_BY_VAT_BLOCK } from '../../../../../../bi/constants/hotels';
import COMPONENTS from '../../../../../../bi/constants/components';

import baseStyles from '../../../../styles/form.module.scss';

const LABELS = {
  VENDOR_AMOUNT: 'Сумма НДС от поставщика',
  RACK_RATE_AMOUNT: 'RR от AA',
  CONFIRM: 'Внести',
  CANCEL: 'Отмена',
  VAT20: 'НДС 20%',
  VAT7: 'НДС 7%',
  VAT5: 'НДС 5%',
  VAT0: 'НДС 0%',
  WITHOUT_VAT: 'Без НДС',
};

const WIDTH = '480px';

const VatDialog = ({
  internalVat,
  providerName,
  onCloseDialog,
  onChangeRadio,
  onChangeInput,
  onChangeChangeRackRate,
  onConfirm,
}) => {
  const isVat20 = internalVat.Rate === VAT_VALUE_BY_VAT_BLOCK.VAT20;
  const isVat7 = internalVat.Rate === VAT_VALUE_BY_VAT_BLOCK.VAT7;
  const isVat5 = internalVat.Rate === VAT_VALUE_BY_VAT_BLOCK.VAT5;
  const isVatRatePresent = isVat20 || isVat7 || isVat5;
  const showAmount = !HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(providerName) && isVatRatePresent;
  const showRackRate = providerName === HOTEL_PROVIDER_VALUE.aanda && isVatRatePresent;
  const disabledConfirmButton =
    !internalVat.Rate
    || (isVatRatePresent && !internalVat.Amount && showAmount)
    || (isVatRatePresent && !internalVat.RackRate && showRackRate);
  const disabledInput = !isVatRatePresent || !internalVat.Rate;
  const disabledWithVat = providerName === HOTEL_PROVIDER_VALUE.expedia
    || providerName === HOTEL_PROVIDER_VALUE.tripcom;

  const rate = internalVat.Rate;
  const amount = internalVat.Amount;

  const renderInputAmount = () => {
    if (!showAmount) return null;

    return (
      <div className={ `${baseStyles.row}` }>
        <Input
          field={ FIELDS_VAT.AMOUNT }
          value={ amount }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          label={ LABELS.VENDOR_AMOUNT }
          onChange={ (e, field, value) => onChangeInput(e, field, value) }
          disabled={ disabledInput }
          onKeyDown={ preventKeyNotNumber }
        />
      </div>
    );
  };

  const renderRackRate = () => {
    if (!showRackRate) return null;

    return (
      <div className={ `${baseStyles.row}` }>
        <Input
          field={ FIELDS_VAT.RACK_RATE }
          value={ internalVat.RackRate }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          label={ LABELS.RACK_RATE_AMOUNT }
          onChange={ (e, field, value) => onChangeChangeRackRate(e, field, value) }
          disabled={ disabledInput }
          onKeyDown={ preventKeyNotNumber }
        />
      </div>
    );
  };

  return (
    <Dialog onClick={ onCloseDialog } width={ WIDTH }>
      <div className={ baseStyles.dialog }>
        <div className={ `${baseStyles.vat_block}` }>
          <Radio
            label={ LABELS.VAT20 }
            field={ FIELDS_VAT.RATE }
            checked={ rate === VAT_VALUE_BY_VAT_BLOCK.VAT20 }
            value={ VAT_VALUE_BY_VAT_BLOCK.VAT20 }
            onChange={ (field, value) => onChangeRadio(field, value) }
            disabled={ disabledWithVat }
          />
          <Radio
            label={ LABELS.VAT7 }
            field={ FIELDS_VAT.RATE }
            checked={ rate === VAT_VALUE_BY_VAT_BLOCK.VAT7 }
            value={ VAT_VALUE_BY_VAT_BLOCK.VAT7 }
            onChange={ (field, value) => onChangeRadio(field, value) }
            disabled={ disabledWithVat }
          />
          <Radio
            label={ LABELS.VAT5 }
            field={ FIELDS_VAT.RATE }
            checked={ rate === VAT_VALUE_BY_VAT_BLOCK.VAT5 }
            value={ VAT_VALUE_BY_VAT_BLOCK.VAT5 }
            onChange={ (field, value) => onChangeRadio(field, value) }
            disabled={ disabledWithVat }
          />
          <Radio
            label={ LABELS.VAT0 }
            field={ FIELDS_VAT.RATE }
            checked={ rate === VAT_VALUE_BY_VAT_BLOCK.VAT0 }
            value={ VAT_VALUE_BY_VAT_BLOCK.VAT0 }
            onChange={ (field, value) => onChangeRadio(field, value) }
            disabled={ disabledWithVat }
          />
          <Radio
            label={ LABELS.WITHOUT_VAT }
            field={ FIELDS_VAT.RATE }
            checked={ rate === VAT_VALUE_BY_VAT_BLOCK.WITHOUT }
            value={ VAT_VALUE_BY_VAT_BLOCK.WITHOUT }
            onChange={ (field, value) => onChangeRadio(field, value) }
          />
        </div>
        { renderInputAmount() }
        { renderRackRate() }
        <div className={ `${baseStyles.row} ${baseStyles.vat_buttons}` }>
          <Button
            label={ LABELS.CONFIRM }
            onClick={ onConfirm }
            disabled={ disabledConfirmButton }
          />
          <FlatButton
            label={ LABELS.CANCEL }
            onClick={ onCloseDialog }
          />
        </div>
      </div>
    </Dialog>
  );
};

VatDialog.propTypes = {
  internalVat: PropTypes.object.isRequired,
  providerName: PropTypes.string.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChangeChangeRackRate: PropTypes.func.isRequired,
};

export { VatDialog };
