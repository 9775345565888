const TRAIN_PROVDERS = {
  TeleTrain: 'TeleTrain',
  Oneliya: 'Oneliya',
  RZHDCart: 'RZHDCart',
};

const TRAIN_TARIFFS = {
  SINGLE: 6,
  KUPEK: 5,
};

const TRAIN_TRANSIT_DOCUMENT_TYPE = {
  /** Нет выбранного документа */
  NO_VALUE: 0,
  /** Есть документ для транзитного проезда */
  PERMISSION: 1,
  /** Упрощенный проездной документ на железной дороге (УПД ЖД) */
  SIMPLIFED: 2,
  /** Значение по умолчанию для нетранзитного поезда для валидации */
  DEFAULT: '',
};

const TRAIN_TRANSIT_DOCUMENT_LABEL = {
  UPD_TITLE: 'УПД-ЖД',
  PERMISSION: 'Есть документ для транзитного проезда',
  UPD: 'Оформить упрощенный проездной документ на железной дороге (УПД ЖД)',
};

const KALININGRAD_CITY_NAME = 'Калининград';

export {
  TRAIN_PROVDERS,
  TRAIN_TARIFFS,
  TRAIN_TRANSIT_DOCUMENT_TYPE,
  TRAIN_TRANSIT_DOCUMENT_LABEL,
  KALININGRAD_CITY_NAME,
};
