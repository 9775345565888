import React from 'react';
import PropTypes from 'prop-types';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const LABELS = { WITHOUT_STARS: 'Без звезд' };

const StarsHotel = ({
  groupHotels: {
    disableMainHotelFields,
    JsonData: data,
  },
  onChangeJsonInput,
}) => {
  const { Hotel: { Stars } } = data;

  return (
    <div className={ styles.wrap }>
      <label>{ TRIPSLABELS.HOTEL_STARS }</label>
      <select
        value={ Stars }
        onChange={ ({ target: { value } }) => onChangeJsonInput(FIELDS.HOTELSTARS, value) }
        disabled={ disableMainHotelFields }
      >
        <option value={ 0 }>{ LABELS.WITHOUT_STARS }</option>
        <option value={ 1 }>1</option>
        <option value={ 2 }>2</option>
        <option value={ 3 }>3</option>
        <option value={ 4 }>4</option>
        <option value={ 5 }>5</option>
      </select>
    </div>
  );
};

StarsHotel.propTypes = {
  onChangeJsonInput: PropTypes.func.isRequired,
  groupHotels: PropTypes.object.isRequired,
};

export { StarsHotel };
