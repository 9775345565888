import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import Input from '../../../../components/input';
import { SecondStep } from './components/SecondStep';

import { FIELDS_GIFT_VOUCHER } from '../../../../bi/constants/trips';

import styles from './index.module.css';

const LABELS = {
  TITLE: 'Подарочный ваучер',
  SET_ID: 'Введите Id компании',
  NEXT_STEP: 'Далее',
  UNDO_STEP: 'Назад',
  SAVE: 'Сохранить',
};

const GiftVoucherDialog = ({
  data,
  newTaxiClasses,
  onClose,
  onChangeCompanyId,
  onSendCompanyId,
  onSelectEmployeeSuggest,
  onSetValue,
  onSave,
  validate,
  employeesList,
  loadingEmployeesList,
  errorEmployeesList,
}) => {
  const {
    companyId,
    isSecondStep,
    loadingSave,
    resultSave,
  } = data;

  const renderSteps = () => {
    if (isSecondStep) {
      return (
        <SecondStep
          data={ data }
          newTaxiClasses={ newTaxiClasses }
          onSendCompanyId={ onSendCompanyId }
          onSetValue={ onSetValue }
          onSelectEmployeeSuggest={ onSelectEmployeeSuggest }
          validate={ validate }
          employeesList={ employeesList }
        />
      );
    }

    return (
      <div>
        <Input
          field={ FIELDS_GIFT_VOUCHER.COMPANY_ID }
          value={ companyId }
          label={ LABELS.SET_ID }
          onChange={ (e, field, value) => onChangeCompanyId(value) }
        />
        <h4 className={ styles.error_text }>{ errorEmployeesList }</h4>
      </div>
    );
  };

  const renderActionButtons = () => {
    if (isSecondStep) {
      return (
        <div className={ styles.rows }>
          <Button
            theme='flat'
            onClick={ () => onSetValue(FIELDS_GIFT_VOUCHER.IS_SECOND_STEP, false) }
          >
            { LABELS.UNDO_STEP }
          </Button>
          <Button
            theme='flat'
            onClick={ onSave }
            loading={ loadingSave }
          >
            { LABELS.SAVE }
          </Button>
        </div>
      );
    }

    return (
      <Button
        theme='flat'
        onClick={ () => onSendCompanyId(companyId) }
        loading={ loadingEmployeesList }
      >
        { LABELS.NEXT_STEP }
      </Button>
    );
  };

  const renderBody = () => {
    if (resultSave && !resultSave.isSuccess) {
      return <h4 className={ styles.error_text }>{ resultSave.errorMessage }</h4>;
    }

    return (
      <div>
        { renderSteps() }
        { renderActionButtons() }
      </div>
    );
  };

  return (
    <Dialog onClick={ onClose } showCloseButton>
      <div className={ styles.wrapper }>
        <h4>{ LABELS.TITLE }</h4>
        { renderBody() }
      </div>
    </Dialog>
  );
};

GiftVoucherDialog.propTypes = {
  data: PropTypes.object.isRequired,
  newTaxiClasses: PropTypes.bool.isRequired,
  employeesList: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeCompanyId: PropTypes.func.isRequired,
  onSendCompanyId: PropTypes.func.isRequired,
  onSelectEmployeeSuggest: PropTypes.func.isRequired,
  onSetValue: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
  loadingEmployeesList: PropTypes.bool.isRequired,
  errorEmployeesList: PropTypes.string.isRequired,
};

export { GiftVoucherDialog };
