import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dialog } from 'sw-ui';

import { DECISIONBUTTONS } from '../../bi/constants/decisionButtons';
import COMPONENTS from '../../bi/constants/components';
import MESSAGE from '../../bi/constants/noResult';
import SEARCH from '../../bi/constants/search';
import { formatDate, getMoment } from '../../bi/utils/formatDate';
import {
  FIELDLABELS, REPORTLABALES, REPORTNAME,
} from '../../bi/constants/reports';
import { FORMATDATETIME, DEFAULTDATE } from '../../constants/time';

import SearchReport from '../SearchReport';
import NoResult from '../NoResult';
import DecisionButtons from '../DecisionButtons';

import styles from './styles/index.module.scss';

class TripByDate extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
  };

  state = {
    list: [],
    title: '',
    error: false,
    date: getMoment(),
    showDialog: false,
  };

  componentWillUnmount() {
    this.toggleShownReport([], true);
  }

  toggleError = () => this.setState({ error: !this.state.error });

  toggleShownReport = (list, value = false) => this.setState({
    list,
    title: !list.length && !value ? 'ничего не найдено' : '',
  });

  handleCloseDialog = () => this.setState({ showDialog: false, waitingResponse: false });

  handleDownload = (startDate, endDate) => this.props.onDownload(startDate, endDate)
    .then((list) => this.toggleShownReport(list));

  handleChangeCheckBoxRequest = (id, checkboxes) => {
    const { list } = this.state;

    this.props.onSend(id, checkboxes)
      .then(() => {
        let newList = list.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              ...checkboxes,
            };
          }

          return item;
        });

        if (checkboxes.called && checkboxes.approved) {
          newList = list.filter((item) => item.id !== id);
        }

        this.setState({ list: newList, showDialog: false });
      })
      .catch(this.toggleError);
  };

  handleChangeCheckBoxRequestAndDeleteTrip = (id, checkboxes) => {
    const { list } = this.state;

    this.props.onSend(id, checkboxes)
      .then(() => {
        const newList = list.filter((item) => item.id !== id);

        this.setState({ list: newList, showDialog: false });
      })
      .catch(this.toggleError);
  };

  handleChangeCheckbox = (field, fieldToDisable, id, value) => {
    const itemTrip = this.state.list.find((el) => el.id === id);

    const checkboxes = {
      tripItemId: itemTrip.id,
      called: !!itemTrip.called,
      notCalled: !!itemTrip.notCalled,
      approved: !!itemTrip.approved,
      notApproved: !!itemTrip.notApproved,
      [fieldToDisable]: false,
      [field]: value,
    };

    if (checkboxes.called) {
      checkboxes.notCalled = false;
    }

    if (checkboxes.notCalled) {
      checkboxes.called = false;
    }

    if (checkboxes.called && checkboxes.approved) {
      return this.setState({ showDialog: true, trip: { checkboxes, id } });
    }

    return this.handleChangeCheckBoxRequest(id, checkboxes);
  };

  save = () => {
    const { trip: { id, checkboxes } } = this.state;

    this.handleChangeCheckBoxRequestAndDeleteTrip(id, checkboxes);
  };

  renderDialog = () => {
    const { waitingResponse, showDialog } = this.state;

    return (
      showDialog
      && (
        <Dialog onClick={ this.handleCloseDialog }>
          <div className={ `${styles.form} ${styles['form-dialog']}` }>
            <span className={ `${styles.row} ${styles.title}` }>Эта запись будет удалена из отчета. Вы уверены?</span>
            <div className={ `${styles.row} ${styles.action}` }>
              <DecisionButtons
                waitingResponse={ waitingResponse }
                onSave={ this.save }
                onCancel={ this.handleCloseDialog }
                tooltipPosition={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
                labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
              />
            </div>
          </div>
        </Dialog>
      )
    );
  };

  renderItemHotel = ({
    id,
    date,
    checkinDate,
    checkoutDate,
    providerName,
    companyName,
    companyId,
    companyType,
    tripLink,
    called,
    notCalled,
    approved,
    notApproved,
    customCheckinDate,
    customCheckoutDate,
  }, index) => (
    <div className={ styles.row_item } key={ `collection_${id}_${index}` }>
      <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ formatDate(date, FORMATDATETIME) }</div>
      <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ !customCheckinDate ? formatDate(checkinDate, DEFAULTDATE) : formatDate(checkinDate, FORMATDATETIME) }</div>
      <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ !customCheckoutDate ? formatDate(checkoutDate, DEFAULTDATE) : formatDate(checkoutDate, FORMATDATETIME) }</div>
      <div className={ `${styles.text_center} ${styles['col-1-13']}` }>{ providerName }</div>
      <div className={ `${styles.text_center} ${styles['col-1-10']}` }>{ companyName }</div>
      <div className={ `${styles.text_center} ${styles['col-1-14']}` }>{ companyId }</div>
      <div className={ `${styles.text_center} ${styles['col-1-14']}` }>{ companyType === 1 ? 'вип' : 'не вип' }</div>
      <div className={ `${styles.text_center} ${styles['col-1-13']}` }><a target='_blank' href={ tripLink } rel='noreferrer'>ссылка</a></div>
      <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
        <Checkbox
          value={ !!called }
          onChange={ (value) => this.handleChangeCheckbox(FIELDLABELS.CALLED, FIELDLABELS.NOT_CALLED, id, value) }
        />
      </div>
      <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
        <Checkbox
          value={ !!notCalled }
          onChange={ (value) => this.handleChangeCheckbox(FIELDLABELS.NOT_CALLED, FIELDLABELS.CALLED, id, value) }
        />
      </div>
      <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
        <Checkbox
          disabled={ !called }
          value={ !!approved }
          onChange={ (value) => this.handleChangeCheckbox(FIELDLABELS.APPROVED, FIELDLABELS.NOT_APPROVED, id, value) }
        />
      </div>
      <div className={ `${styles['col-1-13']} ${styles.checkbox}` }>
        <Checkbox
          value={ !!notApproved }
          onChange={ (value) => this.handleChangeCheckbox(FIELDLABELS.NOT_APPROVED, FIELDLABELS.APPROVED, id, value) }
        />
      </div>
    </div>
  );

  renderReport = () => (
    <div className={ styles.report_wrapper }>
      <div className={ styles.row }>
        <div className={ styles['col-1-10'] }>Дата и время формирования услуги бронирования отеля</div>
        <div className={ styles['col-1-10'] }>Дата и время предполагаемого заезда гостя</div>
        <div className={ styles['col-1-10'] }>Дата и время выезда гостя</div>
        <div className={ styles['col-1-13'] }>Название поставщика</div>
        <div className={ styles['col-1-10'] }>Название компании</div>
        <div className={ styles['col-1-14'] }>ID компании</div>
        <div className={ styles['col-1-14'] }>Тип компании</div>
        <div className={ styles['col-1-13'] }>Ссылка на поездку</div>
        <div className={ styles['col-1-13'] }>Дозвонились</div>
        <div className={ styles['col-1-13'] }>Не дозвонились</div>
        <div className={ styles['col-1-13'] }>Подтверждено</div>
        <div className={ styles['col-1-13'] }>Не подтверждено</div>
      </div>
      <div className={ styles.col }>
        { this.state.list.map((item, index) => this.renderItemHotel(item, index)) }
      </div>
    </div>
  );

  renderErrorDialog = () => (
    <NoResult
      message={ MESSAGE.RESPONSE }
      onClose={ this.toggleError }
    />
  );

  render() {
    const { list, error } = this.state;

    const renderOption = !!list.length && this.renderReport();
    const errorDialogHtml = error && this.renderErrorDialog();

    return (
      <div className={ styles.wrapper }>
        <SearchReport
          nameList={ this.state.title }
          description={ REPORTNAME.TRIPBYDATE }
          labelButton={ REPORTLABALES.TOFORM }
          onDownload={ this.handleDownload }
          renderOption={ renderOption }
          datePickerLabels={ SEARCH.DATE_TRAVEL }
        />
        { this.renderDialog() }
        { errorDialogHtml }
      </div>
    );
  }
}

export default TripByDate;
