import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../components/input';
import {
  FUNDSFIELDS, FUNDSFIELDSTYPES, PRICELABELS,
} from '../../../bi/constants/trips';
import COMPONENTS from '../../../bi/constants/components';
import { SERVICETYPE } from '../../../bi/constants/serviceType';

import styles from '../styles/trip.module.scss';

const LABELS = {
  DESCRIPTION: 'Описание изменения для отчетности',
  BASE: 'Сумма возврата',
  BASE_HOTEL: 'Сумма возврата поставщику',
  COMMISSION: 'Наценка Smartway',
};

const renderFundsForm = (params, onChange, type, typeService = '') => {
  const {
    Description, Penalties, Tax, Base, Commission, Taxes, Fee,
  } = params;

  const descriptionHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          field={ FUNDSFIELDS.DESCRIPTION }
          value={ Description }
          label={ LABELS.DESCRIPTION }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const descriptionHtmlNoEdit = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          field={ FUNDSFIELDS.DESCRIPTION }
          value={ Description }
          label={ LABELS.DESCRIPTION }
          disabled
        />
      </div>
    </div>
  );

  const labelByService = typeService === SERVICETYPE.HOTEL ? LABELS.BASE_HOTEL : LABELS.BASE;

  const baseLabel = type === FUNDSFIELDSTYPES.SURCHARGES ? PRICELABELS.BASESURCHARGES : labelByService;

  const baseHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          field={ FUNDSFIELDS.BASE }
          value={ Base }
          label={ baseLabel }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const penaltiesHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          field={ FUNDSFIELDSTYPES.PENALTIES }
          value={ Penalties }
          label={ PRICELABELS.PENALTIES }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const taxValue = typeService === SERVICETYPE.AIR ? PRICELABELS.AVIATAX : PRICELABELS.TAX;
  const taxLabel = type === FUNDSFIELDSTYPES.PENALTIES ? PRICELABELS.TAXPENALTIES : taxValue;
  const taxesLabel = typeService === SERVICETYPE.AIR ? PRICELABELS.AVIATAXES : PRICELABELS.TAXES;
  const feeLabel = typeService === SERVICETYPE.AIR ? PRICELABELS.AVIAFEE : PRICELABELS.FEE;

  const taxHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          field={ FUNDSFIELDS.TAX }
          value={ Tax }
          label={ taxLabel }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const commissionHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          field={ FUNDSFIELDS.COMMISSION }
          value={ Commission }
          label={ LABELS.COMMISSION }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const taxesHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          field={ FUNDSFIELDS.TAXES }
          value={ Taxes }
          label={ taxesLabel }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const feeHtml = (
    <div className={ styles.row }>
      <div className={ styles['col-1'] }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          field={ FUNDSFIELDS.FEE }
          value={ Fee }
          label={ feeLabel }
          onChange={ onChange }
        />
      </div>
    </div>
  );

  const renderCommission = () => (typeof Commission !== 'undefined' ? commissionHtml : null);

  const renderTaxes = () => (typeof Taxes !== 'undefined' ? taxesHtml : null);

  const renderFee = () => (typeof Fee !== 'undefined' ? feeHtml : null);

  const renderTax = () => (typeof Tax !== 'undefined' ? taxHtml : null);

  const renderPenaltiesForm = () => (
    <div className={ styles.row }>
      { descriptionHtml }
      { penaltiesHtml }
      { taxHtml }
    </div>
  );

  const renderReturnForm = () => (
    <div className={ styles.row }>
      { descriptionHtml }
      { baseHtml }
      { renderCommission() }
      { renderTaxes() }
      { renderFee() }
      { renderTax() }
    </div>
  );

  const renderSurchargeForm = () => {
    const descriptionSurcharge = typeService === SERVICETYPE.AIR ? descriptionHtmlNoEdit : descriptionHtml;

    return (
      <div className={ styles.row }>
        { descriptionSurcharge }
        { baseHtml }
        { commissionHtml }
        { renderFee() }
        { renderTax() }
      </div>
    );
  };

  const mapTypeToFunc = {
    [FUNDSFIELDSTYPES.PENALTIES]: renderPenaltiesForm,
    [FUNDSFIELDSTYPES.RETURNFUNDS]: renderReturnForm,
    [FUNDSFIELDSTYPES.SURCHARGES]: renderSurchargeForm,
  };

  return mapTypeToFunc[type] ? mapTypeToFunc[type]() : null;
};

renderFundsForm.propTypes = {
  params: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default renderFundsForm;
