const TYPE_PATH = {
  NEW: 'new',
  EDIT: 'edit',
};

const DOCUMENTS = {
  ACTS: 'Acts',
  INVOICES: 'Invoices',
};

const NEW_ACT = {
  Id: null,
  ClosingSettingsId: null,
  Name: '',
  CompanyDocumentPlaceHolders: null,
  SettingVAT: 'Отображать столбец НДС',
  SeparateColumn: 'Не отображать дополнительные колонки',
  FillColumns: [],
  ServiceOperations: [],
};

const NEW_INVOICE = {
  Id: null,
  ClosingSettingsId: null,
  Name: '',
  ServiceOperations: [],
};

const PARAMS = [
  {
    name: 'А/б с НДС',
    nameContents: [
      'Тип услуги (а/б)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'А/б без НДС',
    nameContents: [
      'Тип услуги (а/б)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат а/б с НДС',
    nameContents: [
      'Тип услуги (Возврат а/б)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат а/б без НДС',
    nameContents: [
      'Тип услуги (Возврат а/б)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Изменение а/б с НДС',
    nameContents: [
      'Тип услуги',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Изменение а/б без НДС',
    nameContents: [
      'Тип услуги',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Сборы для а/б',
    nameContents: [
      'Тип услуги ("А/б" или "Комиссия при изменении а/б")',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
      'Наименование услуги ("сбор за выписку" или "добор сбора по правилам a/к [название а/к]" или "сбор за изменение")',
    ],
  },
  {
    name: 'Штрафы для а/б',
    nameContents: [
      'Тип услуги (Штраф при изменении бронирования а/б)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Ж/д с НДС',
    nameContents: [
      'Тип услуги (Ж/д)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Ж/д без НДС',
    nameContents: [
      'Тип услуги (Ж/д)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат ж/д с НДС',
    nameContents: [
      'Тип услуги (Возврат ж/д)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат ж/д без НДС',
    nameContents: [
      'Тип услуги (Возврат ж/д)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Сборы для ж/д',
    nameContents: [
      'Тип услуги ("Сбор за выписку ж/д билета" или "Сбор за возврат ж/д билета")',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Штрафы для ж/д',
    nameContents: [
      'Тип услуги (Штраф за возврат ж/д билета)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Гостиницы с НДС',
    nameContents: [
      'Тип услуги (Гостиница/Отель)',
      'Наименование гостиницы',
      'Город',
      'ФИО сотрудника',
      'Даты проживания',
    ],
  },
  {
    name: 'Гостиницы без НДС',
    nameContents: [
      'Тип услуги (Гостиница/Отель)',
      'Наименование гостиницы',
      'Город',
      'ФИО сотрудника',
      'Даты проживания',
    ],
  },
  {
    name: 'Штрафы для гостиниц',
    nameContents: [
      'Тип услуги (Штраф за аннуляцию бронирования гостиницы)',
      'Наименование гостиницы',
      'Город',
      'ФИО сотрудника',
      'Даты проживания',
    ],
  },
  {
    name: 'Покупка справки',
    nameContents: [
      'Тип услуги (Справка о совершенном перелете по билету)',
      'Маршрут авиабилета',
      'Номер авиабилета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат справки',
    nameContents: [
      'Тип услуги (Возврат справки о совершенном перелете по билету)',
      'Маршрут авиабилета',
      'Номер авиабилета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Тариф по справкам',
    nameContents: [
      'Тип услуги (Сбор за предоставление справок, подтверждающих перелет по авиабилетам)',
      'Период оказания услуги',
    ],
  },
  {
    name: 'Вип-залы с НДС',
    nameContents: [
      'Тип услуги (Название услуги)',
      'Направление',
      'Город',
      'Аэропорт',
      'Даты оказания услуги',
      'ФИО сотрудника',
    ],
  },
  {
    name: 'Вип-залы без НДС',
    nameContents: [
      'Тип услуги (Название услуги)',
      'Направление',
      'Город',
      'Аэропорт',
      'Даты оказания услуги',
      'ФИО сотрудника',
    ],
  },
  {
    name: 'Штрафы для вип-залов',
    nameContents: [
      'Тип услуги (Штраф за отмену)',
      'Направление',
      'Город',
      'Аэропорт',
      'Даты оказания услуги',
      'ФИО сотрудника',
    ],
  },
  {
    name: 'Трансферы',
    nameContents: [
      'Тип услуги (Трансфер)',
      'Маршрут',
      'Класс машины',
      'ФИО сотрудника',
      'Дата поездки',
    ],
  },
  {
    name: 'Штрафы для трансферов',
    nameContents: [
      'Тип услуги (Штраф за отмену трансфера)',
      'Маршрут',
      'Класс машины',
      'ФИО сотрудника',
      'Дата поездки',
    ],
  },
  {
    name: 'Кастомы с НДС',
    nameContents: [
      'Тип услуги (Услуга)',
      'Название услуги',
      'ФИО сотрудника',
      'Даты оказания услуги',
    ],
  },
  {
    name: 'Кастомы без НДС',
    nameContents: [
      'Тип услуги (Услуга)',
      'Название услуги',
      'ФИО сотрудника',
      'Даты оказания услуги',
    ],
  },
  {
    name: 'Штрафы для кастомов',
    nameContents: [
      'Тип услуги (Штраф за отмену)',
      'Название услуги',
      'ФИО сотрудника',
      'Даты оказания услуги',
    ],
  },
  {
    name: 'Аэроэкспресс',
    nameContents: [
      'Тип услуги (Билет на Аэроэкспресс)',
      'Номер билета',
      'Тариф',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Сборы для аэроэкспрессов',
    nameContents: [
      'Тип услуги (Билет на Аэроэкспресс)',
      'Номер билета',
      'Тариф',
      'ФИО сотрудника',
      'Даты поездки',
      'Наименование услуги ("сбор за выписку")',
    ],
  },
  {
    name: 'Возврат Аэроэкспресса',
    nameContents: [
      'Тип услуги (Возврат билета на Аэроэкспресс)',
      'Номер билета',
      'Тариф',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Штрафы для аэроэкспрессов',
    nameContents: [
      'Тип услуги (Сбор за возврат билета на Аэроэкспресс)',
      'Номер билета',
      'Тариф',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Комиссия за транзакцию',
    nameContents: [
      'Дата операции',
      'Время операции',
      'Тип услуги (Комиссия за транзакцию)',
    ],
  },
  {
    name: 'Такси с НДС',
    nameContents: [
      'Тип услуги (Поездка на такси)',
      'Номер поездки',
      'Маршрут',
      'Дата и время поездки',
      'ФИО сотрудника',
    ],
  },
  {
    name: 'Абонентская плата',
    nameContents: [
      'Тип услуги (Доступ к платформе для бронирования командировок Smartway)',
      'Период оказания услуги',
    ],
  },
  {
    name: 'Доп. услуга к а/б без НДС',
    nameContents: [
      'Тип услуги (Дополнительная услуга к а/б)',
      'Наименование доп. услуги (Питание, страховка и тд.)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Доп. услуга к а/б с НДС',
    nameContents: [
      'Тип услуги (Дополнительная услуга к а/б)',
      'Наименование доп. услуги (Питание, страховка и тд.)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат доп. услуги к а/б с НДС',
    nameContents: [
      'Тип услуги (Возврат дополнительной услуги к а/б)',
      'Наименование доп. услуги (Питание, страховка и тд.)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Возврат доп. услуги к а/б без НДС',
    nameContents: [
      'Тип услуги (Возврат дополнительной услуги к а/б)',
      'Наименование доп. услуги (Питание, страховка и тд.)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Сборы для доп. услуги к а/б',
    nameContents: [
      'Тип услуги (Дополнительная услуга к а/б)',
      'Наименование доп. услуги (Питание, страховка и тд.)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
      'Наименование услуги ("сбор за выписку" или "сбор за возврат")',
    ],
  },
  {
    name: 'Штраф для доп. услуги к а/б',
    nameContents: [
      'Тип услуги (Штраф за отмену дополнительной услуги к а/б)',
      'Наименование доп. услуги (Питание, страховка и тд.)',
      'Маршрут',
      'Номер билета',
      'ФИО сотрудника',
      'Даты поездки',
    ],
  },
  {
    name: 'Мероприятие с НДС',
    nameContents: [
      'Наименование мероприятия',
      'Даты мероприятия',
    ],
  },
  {
    name: 'Мероприятие без НДС',
    nameContents: [
      'Наименование мероприятия',
      'Даты мероприятия',
    ],
  },
  {
    name: 'Штрафы для мероприятия',
    nameContents: [
      'Тип услуги (Штраф за отмену)',
      'Наименование мероприятия',
      'Даты мероприятия',
    ],
  },
];

const OTHER_PARAMS = [
  'Пользовательская аналитика',
  'Центр затрат',
  'Заявка из 1С',
  'Метки',
  'Произвольный текст',
];

export {
  TYPE_PATH,
  DOCUMENTS,
  NEW_ACT,
  NEW_INVOICE,
  PARAMS,
  OTHER_PARAMS,
};
