import renderComponents from './components';

const NAME = 'sw-carts-and-notes';

class CartsAndNotesModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const appService = this.context.getService('App');
    const cartsAndNotesService = this.context.getService('CartsAndNotes');
    const rightsService = context.getService('Rights');

    this.page = renderComponents(this.box, {
      appService,
      cartsAndNotesService,
      rightsService,
      goBack: this.goBack,
      moveToCompany: this.moveToCompany,
      moveToEditCart: this.moveToEditCart,
      moveToCreateCart: this.moveToCreateCart,
      moveToCreateTrip: this.moveToCreateTrip,
    });
  }

  goBack = () => {
    history.back();
  };

  moveToCompany = () => {
    const { accountId, companyId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/account/profile`;

    this.context.navigate(path);
  };

  moveToEditCart = (user, cartId) => {
    const { accountId, companyId } = this.context.getService('App').get().params;

    const path = `/cart/${accountId}/company/${companyId}/user/${user}/edit/${cartId}`;

    this.context.navigate(path);
  };

  moveToCreateCart = (user) => {
    const { accountId, companyId } = this.context.getService('App').get().params;

    const path = `/cart/${accountId}/company/${companyId}/user/${user}/new`;

    this.context.navigate(path);
  };

  moveToCreateTrip = (user, cartId) => {
    const { accountId, companyId } = this.context.getService('App').get().params;

    const path = `/cart/${accountId}/company/${companyId}/user/${user}/create-trip/${cartId}`;

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, CartsAndNotesModule);
}
