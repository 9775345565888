import React from 'react';
import { createRoot } from 'react-dom/client';

import SearchReport from '../../components/SearchReport';
import { REPORTNAMELIST } from '../../bi/constants/reports';

const renderComponents = (box, opts) => {
  const { businessService } = opts;

  const page = (
    <SearchReport
      onDownload={ businessService.downloadTaxiBi }
      nameList={ REPORTNAMELIST.REPORTNAME }
    />
  );

  const root = createRoot(box);

  root.render(page);

  return root;
};

export default renderComponents;
