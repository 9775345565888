import moment from 'moment';

import lodashReplaces from '../../../utils/lodashReplaces';

const NO_EMPTY = 'Поле не должно быть пустым';
const NO_STARS = 'Введите кол-во звезд от 0 до 5';
const NO_TIME = 'Введите время в формате HH:mm:ss';
const NO_INN = 'Введите 10 или 12 символов';
const NO_ACC = 'Введите 20 символов';
const NO_BIK = 'Введите 9 символов';

const require = (value) => value !== '';
const requireInn = (value) => value.length === 10 || value.length === 12;
const requireAcc = (value) => value.length === 20;
const requireBik = (value) => value.length === 9;

const staticSimpleValidStorage = {
  AddressEn: {
    fn: require,
    msg: NO_EMPTY,
  },
  Name: {
    fn: require,
    msg: NO_EMPTY,
  },
  NameEn: {
    fn: require,
    msg: NO_EMPTY,
  },
  ShortDescription: {
    fn: require,
    msg: NO_EMPTY,
  },
  ShortDescriptionEn: {
    fn: require,
    msg: NO_EMPTY,
  },
  Thumbnail: {
    fn: require,
    msg: NO_EMPTY,
  },
  Stars: {
    fn: require,
    msg: NO_STARS,
  },
  CheckinTime: {
    fn: require,
    msg: NO_TIME,
  },
  CheckoutTime: {
    fn: require,
    msg: NO_TIME,
  },
  Latitude: {
    fn: require,
    msg: NO_EMPTY,
  },
  Longitude: {
    fn: require,
    msg: NO_EMPTY,
  },
  Source: {
    fn: require,
    msg: NO_EMPTY,
  },
  DistanceFromCenter: {
    fn: require,
    msg: NO_EMPTY,
  },
  Description: {
    fn: require,
    msg: NO_EMPTY,
  },
  DescriptionEn: {
    fn: require,
    msg: NO_EMPTY,
  },
  LegalName: {
    fn: require,
    msg: NO_EMPTY,
  },
  Inn: {
    fn: requireInn,
    msg: NO_INN,
  },
  CorrespondentAcc: {
    fn: requireAcc,
    msg: NO_ACC,
  },
  Acc: {
    fn: requireAcc,
    msg: NO_ACC,
  },
  Bik: {
    fn: requireBik,
    msg: NO_BIK,
  },
  BankName: {
    fn: require,
    msg: NO_EMPTY,
  },
};

class Validator {
  isValid(path, value) {
    const validationPartObj = lodashReplaces.getValueInObjByPath(staticSimpleValidStorage, path);

    if (!validationPartObj) {
      return '';
    }

    const { fn, msg } = validationPartObj;

    return fn(value) ? '' : msg;
  }

  getValidationFields = (obj, validStorage) => Object.keys(obj).reduce((acc, key) => {
    const field = obj[key].value;
    const partValidObj = validStorage[key];

    if (!partValidObj) {
      return acc;
    }

    const { fn, msg } = partValidObj;
    const value = fn(field) ? '' : msg;

    return { ...acc, [key]: value };
  }, {});

  isValidFields = (fields) => Object.keys(fields)
    .every((key) => {
      const field = fields[key];

      return field instanceof Object && !moment.isMoment(field) ? this.isValidFields(field) : !field;
    });

  getValidation = ({
    simpleFields, Description, DescriptionEn,
  }) => {
    const fields = {
      ...simpleFields,
      Description: {
        field: 'Description',
        value: Description,
      },
      DescriptionEn: {
        field: 'DescriptionEn',
        value: DescriptionEn,
      },
    };
    const validation = this.getValidationFields(fields, staticSimpleValidStorage);

    return { validation, isValid: this.isValidFields(validation) };
  };
}

export default Validator;
