import renderComponents from './components.jsx';
import { TYPE_PATH } from '../../bi/constants/documentsConstructor';

const NAME = 'sw-constructor';

class DocumentsConstructorModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    const appService = context.getService('App');
    const documentsConstructorService = context.getService('DocumentsConstructor');

    const params = {
      ...appService.get().params,
      accountId: Number(appService.get().params.accountId),
      companyId: Number(appService.get().params.companyId),
      tabId: appService.get().params.tabId,
    };

    this.page = renderComponents(this.box, {
      documentsConstructorService,
      moveToDocuments: this.moveToDocuments,
      toAnotherTab: this.toAnotherTab,
      moveToDocument: this.moveToDocument,
      params,
    });
  }

  moveToDocuments = () => {
    const { companyId, accountId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/account/documents`;

    this.context.navigate(path);
  };

  toAnotherTab = ({ tabId }) => {
    const { companyId, accountId } = this.context.getService('App').get().params;
    const path = `/account/${accountId}/company/${companyId}/constructor/${tabId}`;

    this.context.navigate(path);
  };

  getPath = (companyId, accountId, tabId, type, id) => {
    if (type === TYPE_PATH.NEW) {
      return `/account/${accountId}/company/${companyId}/construct/${tabId}/new`;
    }

    return `/account/${accountId}/company/${companyId}/construct/${tabId}/edit/${id}`;
  };

  moveToDocument = (type, id) => {
    const {
      companyId, accountId, tabId,
    } = this.context.getService('App').get().params;
    const path = this.getPath(companyId, accountId, tabId, type, id);

    this.context.navigate(path);
  };

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, DocumentsConstructorModule);
}
