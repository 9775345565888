import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import Autocomplete from 'react-autocomplete';

import Input from '../../../../../../components/input';

import { DEFAULTTIME, DEFAULTDATE } from '../../../../../../constants/time';
import TRANSFERTYPES from '../../../../../../bi/constants/transferTypes';
import { FIELDS } from '../../../../../../bi/constants/trips';

import {
  getMoment,
  getDate,
  isBeforeDateParameter,
} from '../../../../../../bi/utils/formatDate';

import styles from '../../../../styles/form.module.scss';

const LABELS = {
  NAME_AIRPORT: 'Название аэропорта',
  NAME_RAILWAY: 'Название ж/д вокзала',
  TYPE_SEGMENT: 'Тип пункта',
  ARRIVAL_DATE: 'Дата приезда / дата вылета',
  ARRIVAL_TIME: 'Время приезда / время вылета',
  PICKUP_DATE: 'Дата подачи машины',
  PICKUP_TIME: 'Время подачи машины',
  ARRIVALNUMBERAIRPORT: 'Номер рейса прилета (и терминала, если есть)',
  ARRIVALNUMBERREILWAY: 'Номер поезда прибытия',
  CARRIAGENUMBER: 'Номер вагона',
  DEPARTURE_NUMBER_AIRPORT: 'Номер рейса вылета (и терминала, если есть)',
  DEPARTURENUMBERRAILWAY: 'Номер поезда отправления',
  ADDRESS: (label) => `Страна/Город/Улица и номер дома пункта ${label}`,
};

const TEXT = {
  START: 'отправления',
  END: 'прибытия',
};

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

const MAX_LENGTH = 40;
const MAX_LENGTH_ADDRESS = 1000;

const CustomFields = ({
  field,
  jsonData: {
    CheckinDate,
    CheckinTime,
    CheckoutDate,
    CheckoutTime,
    ArrivalNumber,
    DepartureNumber,
    CarriageNumber,
  },
  transferPlaceType,
  type,
  address,
  validation: {
    DepartureCountry: DepartureCountryValidation,
    AirportFrom: AirportFromValidation,
    TrainFrom: TrainFromValidation,
    DepartureNumber: DepartureNumberValidation,
    ArrivalCountry: ArrivalCountryValidation,
    AirportTo: AirportToValidation,
    TrainTo: TrainToValidation,
    ArrivalNumber: ArrivalNumberValidation,
    CheckinDate: CheckinDateValidation,
    CheckinTime: CheckinTimeValidation,
    CheckoutDate: CheckoutDateValidation,
    CheckoutTime: CheckoutTimeValidation,
  },
  renderError,
  onValidate,
  onChangeInput,
  onChangePlaceType,
  onSelectSuggestAir,
  onSelectSuggestTrain,
  onChangeSuggestAirport,
  onChangeSuggestTrain,
}) => {
  const label = field === FIELDS.STARTPLACE ? TEXT.START : TEXT.END;

  const errorClassDepartureCountry = DepartureCountryValidation ? styles['no-valid-input'] : '';
  const errorClassAirportFrom = AirportFromValidation ? styles['no-valid-input'] : '';
  const errorClassTrainFrom = TrainFromValidation ? styles['no-valid-input'] : '';
  const errorClassTrainDeparture = DepartureNumberValidation ? styles['no-valid-input'] : '';
  const errorClassAirportDeparture = DepartureNumberValidation ? styles['no-valid-input'] : '';
  const errorClassArrivalCountry = ArrivalCountryValidation ? styles['no-valid-input'] : '';
  const errorClassAirportTo = AirportToValidation ? styles['no-valid-input'] : '';
  const errorClassTrainTo = TrainToValidation ? styles['no-valid-input'] : '';
  const errorClassTrainArrival = ArrivalNumberValidation ? styles['no-valid-input'] : '';
  const errorClassAirportArrival = ArrivalNumberValidation ? styles['no-valid-input'] : '';

  const renderDay = (items, currentDate) => {
    const todayDate = getMoment();
    const currentDateFormat = getDate(currentDate);

    if (isBeforeDateParameter(currentDate, todayDate)) {
      return <td { ...items } className={ `${items.className} ${styles['date-before']}` }>{ currentDateFormat }</td>;
    }

    return <td { ...items }>{ currentDateFormat }</td>;
  };

  const renderAutocomplete = ({
    Code, City, Country, Name,
  }) => (
    <div className={ styles['autocomplete-item'] }>
      { Code }
      { ' ' }
      -&nbsp;
      { City }
&nbsp;
      { Country }
&nbsp;
      { Name }
    </div>
  );

  const renderAutocompleteRailway = ({ Name, Hint }) => (
    <div className={ styles['autocomplete-item'] }>
      { Name }
      { Hint && ` - ${Hint}` }
    </div>
  );

  return (
    <div className={ styles.row }>
      { field === FIELDS.STARTPLACE && (
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ false }
              renderDay={ renderDay }
              locale='ru'
              value={ CheckinDate }
              onChange={ (value) => onChangeInput(value, FIELDS.CHECKIN_DATE, MAX_LENGTH) }
              onBlur={ () => onValidate(FIELDS.CHECKIN_DATE, CheckinDate) }
              className={ `${styles['date-green']} componentHook ${CheckinDateValidation ? 'no-valid' : ''}` }
            />
            <label>{ LABELS.PICKUP_DATE }</label>
            { renderError(FIELDS.CHECKIN_DATE) }
          </div>
          <div className={ styles['col-1-2'] }>
            <DatePicker
              dateFormat={ false }
              timeFormat={ DEFAULTTIME }
              locale='ru'
              value={ CheckinTime }
              onChange={ (value) => onChangeInput(value, FIELDS.CHECKINTIME, MAX_LENGTH) }
              onBlur={ () => onValidate(FIELDS.CHECKINTIME, CheckinTime) }
              className={ `componentHook ${CheckinTimeValidation ? 'no-valid' : ''}` }
            />
            <label>{ LABELS.PICKUP_TIME }</label>
            { renderError(FIELDS.CHECKINTIME) }
          </div>
        </div>
      ) }
      { field === FIELDS.ENDPLACE && (
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ false }
              renderDay={ renderDay }
              locale='ru'
              value={ CheckoutDate }
              onChange={ (value) => onChangeInput(value, FIELDS.CHECKOUT_DATE, MAX_LENGTH) }
              onBlur={ () => onValidate(FIELDS.CHECKOUT_DATE, CheckoutDate) }
              className={ `${styles['date-green']} componentHook ${CheckoutDateValidation ? 'no-valid' : ''}` }
            />
            <label>{ LABELS.ARRIVAL_DATE }</label>
            { renderError(FIELDS.CHECKOUT_DATE) }
          </div>
          <div className={ styles['col-1-2'] }>
            <DatePicker
              dateFormat={ false }
              timeFormat={ DEFAULTTIME }
              locale='ru'
              value={ CheckoutTime }
              onChange={ (value) => onChangeInput(value, FIELDS.CHECKOUTTIME, MAX_LENGTH) }
              onBlur={ () => onValidate(FIELDS.CHECKOUTTIME, CheckoutTime) }
              className={ `componentHook ${CheckoutTimeValidation ? 'no-valid' : ''}` }
            />
            <label>{ LABELS.ARRIVAL_TIME }</label>
            { renderError(FIELDS.CHECKOUTTIME) }
          </div>
        </div>
      ) }
      <div className={ styles.row }>
        <div className={ `${styles['col-1-4']} ${styles.select}` }>
          <div className={ styles.wrap }>
            <label>{ `${LABELS.TYPE_SEGMENT} ${label}` }</label>
            <select
              value={ type }
              onChange={ ({ target: { value } }) => onChangePlaceType(field, value) }
            >
              <option value={ TRANSFERTYPES.AIRPORT.TYPE }>{ TRANSFERTYPES.AIRPORT.VALUE }</option>
              <option value={ TRANSFERTYPES.RAILWAY.TYPE }>{ TRANSFERTYPES.RAILWAY.VALUE }</option>
              <option value={ TRANSFERTYPES.ADDRESS.TYPE }>{ TRANSFERTYPES.ADDRESS.VALUE }</option>
            </select>
          </div>
        </div>
      </div>
      { transferPlaceType === TRANSFERTYPES.ADDRESS.TYPE
      && (
        <div className={ styles.row }>
          { field === FIELDS.STARTPLACE && (
            <div className={ styles.row }>
              <div className={ `${styles['col-1-2']} ${errorClassDepartureCountry}` }>
                <Input
                  field={ `${field}.Address.label` }
                  value={ address.label }
                  label={ LABELS.ADDRESS(label) }
                  onChange={ ({ target: { value } }) => (
                    onChangeInput(value, `${field}.Address.label`, MAX_LENGTH_ADDRESS)) }
                  onBlur={ () => onValidate(FIELDS.DEPARTURE_COUNTRY, address) }
                />
                { renderError(FIELDS.DEPARTURE_COUNTRY) }
              </div>
            </div>
          ) }
          { field === FIELDS.ENDPLACE && (
            <div className={ styles.row }>
              <div className={ `${styles['col-1-2']} ${errorClassArrivalCountry}` }>
                <Input
                  field={ `${field}.Address.label` }
                  value={ address.label }
                  label={ LABELS.ADDRESS(label) }
                  onChange={ ({ target: { value } }) => (
                    onChangeInput(value, `${field}.Address.label`, MAX_LENGTH_ADDRESS)) }
                  onBlur={ () => onValidate(FIELDS.ARRIVAL_COUNTRY, address) }
                />
                { renderError(FIELDS.ARRIVAL_COUNTRY) }
              </div>
            </div>
          ) }
        </div>
      ) }
      { transferPlaceType === TRANSFERTYPES.AIRPORT.TYPE
      && (
        <div className={ styles.row }>
          { field === FIELDS.STARTPLACE && (
            <div className={ styles.row }>
              <div className={ `${styles['col-1-3']} ${errorClassAirportFrom}` }>
                <div className={ `${styles['auto-input']}` }>
                  <label>{ LABELS.NAME_AIRPORT }</label>
                  <Autocomplete
                    menuStyle={ MENUSTYLE }
                    value={ address.label }
                    items={ address.suggests }
                    getItemValue={ (item) => item.Code }
                    onSelect={ (cValue, value) => onSelectSuggestAir(`${field}.${[FIELDS.ADDRESS]}`, value) }
                    onChange={ (event, value) => {
                      onChangeSuggestAirport(`${field}.${[FIELDS.ADDRESS]}`, FIELDS.AIRPORT_FROM, value);
                    } }
                    renderItem={ renderAutocomplete }
                  />
                  { renderError(FIELDS.AIRPORT_FROM) }
                </div>
              </div>
              <div className={ `${styles['col-1-2']} ${errorClassAirportArrival}` }>
                <Input
                  field={ FIELDS.ARRIVALNUMBER }
                  value={ ArrivalNumber }
                  label={ LABELS.ARRIVALNUMBERAIRPORT }
                  onChange={ ({ target: { value } }) => onChangeInput(value, FIELDS.ARRIVALNUMBER, MAX_LENGTH) }
                  onBlur={ () => onValidate(FIELDS.ARRIVALNUMBER, ArrivalNumber) }
                />
                { renderError(FIELDS.ARRIVALNUMBER) }
              </div>
            </div>
          ) }
          { field === FIELDS.ENDPLACE && (
            <div className={ styles.row }>
              <div className={ `${styles['col-1-3']} ${errorClassAirportTo}` }>
                <div className={ `${styles['auto-input']}` }>
                  <label>{ LABELS.NAME_AIRPORT }</label>
                  <Autocomplete
                    menuStyle={ MENUSTYLE }
                    value={ address.label }
                    items={ address.suggests }
                    getItemValue={ (item) => item.Code }
                    onSelect={ (cValue, value) => onSelectSuggestAir(`${field}.${[FIELDS.ADDRESS]}`, value) }
                    onChange={ (event, value) => {
                      onChangeSuggestAirport(`${field}.${[FIELDS.ADDRESS]}`, FIELDS.AIRPORT_TO, value);
                    } }
                    renderItem={ renderAutocomplete }
                  />
                  { renderError(FIELDS.AIRPORT_TO) }
                </div>
              </div>
              <div className={ `${styles['col-1-2']} ${errorClassAirportDeparture}` }>
                <Input
                  field={ FIELDS.DEPARTURENUMBER }
                  value={ DepartureNumber }
                  label={ LABELS.DEPARTURE_NUMBER_AIRPORT }
                  onChange={ ({ target: { value } }) => onChangeInput(value, FIELDS.DEPARTURENUMBER, MAX_LENGTH) }
                />
              </div>
            </div>
          ) }
        </div>
      ) }
      { transferPlaceType === TRANSFERTYPES.RAILWAY.TYPE
      && (
        <div className={ styles.row }>
          { field === FIELDS.STARTPLACE && (
            <div className={ styles.row }>
              <div className={ `${styles['col-1-3']} ${errorClassTrainFrom}` }>
                <div className={ `${styles['auto-input']}` }>
                  <label>{ LABELS.NAME_RAILWAY }</label>
                  <Autocomplete
                    menuStyle={ MENUSTYLE }
                    value={ address.label }
                    items={ address.suggests }
                    getItemValue={ (item) => item.Code }
                    onSelect={ (cValue, value) => onSelectSuggestTrain(`${field}.${[FIELDS.ADDRESS]}`, value) }
                    onChange={ (event, value) => {
                      onChangeSuggestTrain(`${field}.${[FIELDS.ADDRESS]}`, FIELDS.TRAIN_FROM, value);
                    } }
                    renderItem={ renderAutocompleteRailway }
                  />
                  { renderError(FIELDS.TRAIN_FROM) }
                </div>
              </div>
              <div className={ `${styles['col-1-3']} ${errorClassTrainDeparture}` }>
                <Input
                  field={ FIELDS.CARRIAGENUMBER }
                  value={ CarriageNumber }
                  label={ LABELS.CARRIAGENUMBER }
                  onChange={ ({ target: { value } }) => onChangeInput(value, FIELDS.CARRIAGENUMBER, MAX_LENGTH) }
                  onBlur={ () => onValidate(FIELDS.CARRIAGENUMBER, CarriageNumber) }
                />
                { renderError(FIELDS.CARRIAGENUMBER) }
              </div>
              <div className={ `${styles['col-1-3']} ${errorClassTrainArrival}` }>
                <Input
                  field={ FIELDS.ARRIVALNUMBER }
                  value={ ArrivalNumber }
                  label={ LABELS.ARRIVALNUMBERREILWAY }
                  onChange={ ({ target: { value } }) => onChangeInput(value, FIELDS.ARRIVALNUMBER, MAX_LENGTH) }
                  onBlur={ () => onValidate(FIELDS.ARRIVALNUMBER, ArrivalNumber) }
                />
                { renderError(FIELDS.ARRIVALNUMBER) }
              </div>
            </div>
          ) }
          { field === FIELDS.ENDPLACE && (
            <div className={ styles.row }>
              <div className={ `${styles['col-1-3']} ${errorClassTrainTo}` }>
                <div className={ `${styles['auto-input']}` }>
                  <label>{ LABELS.NAME_RAILWAY }</label>
                  <Autocomplete
                    menuStyle={ MENUSTYLE }
                    value={ address.label }
                    items={ address.suggests }
                    getItemValue={ (item) => item.Code }
                    onSelect={ (cValue, value) => onSelectSuggestTrain(`${field}.${[FIELDS.ADDRESS]}`, value) }
                    onChange={ (event, value) => {
                      onChangeSuggestTrain(`${field}.${[FIELDS.ADDRESS]}`, FIELDS.TRAIN_TO, value);
                    } }
                    renderItem={ renderAutocompleteRailway }
                  />
                  { renderError(FIELDS.TRAIN_TO) }
                </div>
              </div>
              <div className={ `${styles['col-1-3']} ${errorClassTrainDeparture}` }>
                <Input
                  field={ FIELDS.DEPARTURENUMBER }
                  value={ DepartureNumber }
                  label={ LABELS.DEPARTURENUMBERRAILWAY }
                  onChange={ ({ target: { value } }) => onChangeInput(value, FIELDS.DEPARTURENUMBER, MAX_LENGTH) }
                />
              </div>
            </div>
          ) }
        </div>
      ) }
    </div>
  );
};

CustomFields.propTypes = {
  field: PropTypes.string.isRequired,
  jsonData: PropTypes.object.isRequired,
  transferPlaceType: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  address: PropTypes.object.isRequired,
  validation: PropTypes.object.isRequired,
  renderError: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onChangePlaceType: PropTypes.func.isRequired,
  onSelectSuggestAir: PropTypes.func.isRequired,
  onSelectSuggestTrain: PropTypes.func.isRequired,
  onChangeSuggestAirport: PropTypes.func.isRequired,
  onChangeSuggestTrain: PropTypes.func.isRequired,
};

export { CustomFields };
