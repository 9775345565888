import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dialog } from 'sw-ui';

import { getMoment } from '../../../../bi/utils/formatDate';
import RangeDatePicker from '../../../../components/RangeDatePicker';
import DecisionButtons from '../../../../components/DecisionButtons';
import Input from '../../../../components/input';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';
import { INSURANSE } from '../../../../bi/constants/company';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/company.module.scss';

class InsuranceDialog extends Component {
  static propTypes = {
    insuranceData: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { insuranceData: { ActiveInsurance } } = this.props;
    const { StartDate, EndDate } = ActiveInsurance;
    this.state = {
      StartDate: StartDate || getMoment(),
      EndDate,
      isInsured: Object.keys(ActiveInsurance).length !== 0,
      reasonForCancellationOfInsurance: '',
    };
  }

  handleChangeDate = (field, value) => {
    this.setState({ [field]: value });
  };

  handleChangeInsurance = () => this.setState({ isInsured: !this.state.isInsured });

  handleChangeInput = (e, field, value) => this.setState({ reasonForCancellationOfInsurance: value });

  renderReasonForCancellationInput = () => (
    <div className={ styles['reason-for-cancellation'] }>
      <Input
        label={ INSURANSE.REASONFORCANCELLATIONOFINSURANCE }
        value={ this.state.reasonForCancellationOfInsurance }
        onChange={ this.handleChangeInput }
      />
    </div>
  );

  render() {
    const {
      onSave, onClose, insuranceData: { ActiveInsurance },
    } = this.props;
    const {
      StartDate, EndDate, isInsured, reasonForCancellationOfInsurance,
    } = this.state;
    const renderInput = EndDate && this.renderReasonForCancellationInput();
    let disableButtons = false;
    let tooltip = '';

    if (isInsured && EndDate && !reasonForCancellationOfInsurance) {
      disableButtons = true;
      tooltip = INSURANSE.TOOLTIPREASONREUIRED;
    }

    if (!isInsured) {
      disableButtons = true;
      tooltip = INSURANSE.TOOLTIPETEXT;
    }

    return (
      <Dialog
        width={ COMPONENTS.DIALOG.WIDTH.MIDDLE }
        onClick={ onClose }
      >
        <div className={ styles['insurance-dialog'] }>
          <div className={ styles.client }>
            { INSURANSE.CLIENTISINSURENCE }
            <Checkbox
              className={ styles['checkbox-client'] }
              value={ isInsured }
              onChange={ this.handleChangeInsurance }
              disabled={ Object.keys(ActiveInsurance).length !== 0 }
            />
          </div>
          <div className={ styles.dates }>
            <RangeDatePicker
              toCol
              nullableTo
              nullableFrom={ false }
              startDate={ StartDate }
              fieldStart='StartDate'
              endDate={ EndDate }
              fieldEnd='EndDate'
              onChange={ this.handleChangeDate }
              disabledFrom={ !isInsured }
              disabledTo={ !isInsured }
              placeholderNameFrom={ INSURANSE.STARTDATEINSURANCE }
              placeholderNameTo={ INSURANSE.ENDDATEINSURANCE }
            />
          </div>
          { renderInput }
          <DecisionButtons
            loadingIncluded
            labelSave={ ACCOUNTTEXT.SAVE }
            onCancel={ onClose }
            onSave={ () => onSave({
              StartDate, EndDate, isInsured, reasonForCancellationOfInsurance, Id: ActiveInsurance.Id || 0,
            }) }
            disabled={ disableButtons }
            tooltip={ tooltip }
          />
        </div>
      </Dialog>
    );
  }
}

export default InsuranceDialog;
