import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import Input from '../../components/input';
import CircularLoaders from '../../components/loaders';

import styles from './styles/login.module.scss';

class Login extends Component {
  static propTypes = { login: PropTypes.func.isRequired };

  constructor() {
    super();

    this.state = {
      UserName: '',
      Password: '',

      loading: false,
      isValid: true,
      msg: '',
    };
  }

  onChangeField = (e, field, val) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState((state) => {
      state[field] = val;

      return state;
    });
  };

  onSubmit = (e) => {
    e.stopPropagation();
    e.preventDefault();

    if (!this.state.loading) {
      this.setState({ loading: true });

      const { UserName, Password } = this.state;

      this.props.login({ UserName, Password }).catch((err) => {
        if (err.status === 403) {
          this.setState({
            loading: false,
            isValid: false,
            msg: 'Неверный логин или пароль',
          });
        }

        if (err.status === 401) {
          this.setState({
            loading: false,
            isValid: false,
            msg: 'Скорее всего, вам не сюда',
          });
        }
      });
    }
  };

  render() {
    const {
      UserName, Password, isValid, msg, loading,
    } = this.state;

    let errorHtml = null;
    if (!isValid) {
      errorHtml = (
        <div className={ styles['no-valid'] }>{ msg }</div>
      );
    }

    return (
      <div className='app-center-layout'>
        <div className='logo' />
        <div className={ styles.dialog }>
          <div className={ styles.title }>SmartWay Backoffice</div>
          { errorHtml }
          <form className='form' onSubmit={ this.onSubmit }>
            <Input
              type='email'
              field='UserName'
              label='Эл. почта'
              onChange={ this.onChangeField }
              value={ UserName }
            />
            <Input
              type='password'
              field='Password'
              label='Пароль'
              onChange={ this.onChangeField }
              value={ Password }
            />
            <button type='submit' className='button primary max' onSubmit={ this.onSubmit }>
              { loading ? <CircularLoaders wrapWidth={ 24 } /> : 'Войти' }
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(<Login login={ opts.login } />);

  return root;
};

export default renderComponents;
