import React from 'react';
import PropTypes from 'prop-types';

import CartItem from './cartItem';

const Cart = ({
  cart,
  companies,
  projects,
  cartsAndNotesService,
  appService,
  loadingApprovals,
  moveToEditCart,
}) => {
  const html = cart.Items.map((item) => (
    <CartItem
      key={ item.Id }
      item={ item }
      cart={ cart }
      serverTime={ cart.ServerTime }
      companies={ companies }
      projects={ projects }
      cartsAndNotesService={ cartsAndNotesService }
      appService={ appService }
      loadingApprovals={ loadingApprovals }
      moveToEditCart={ moveToEditCart }
    />
  ));

  return (
    <div>
      { html }
    </div>
  );
};

Cart.propTypes = {
  cart: PropTypes.object.isRequired,
  companies: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  appService: PropTypes.object.isRequired,
  loadingApprovals: PropTypes.bool.isRequired,
  moveToEditCart: PropTypes.func.isRequired,
};

export default Cart;
