import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Loading, Checkbox,
} from 'sw-ui';

import DisabledAccountDialog from './dialogs/disabledAccount';
import { MoveTrip } from './dialogs/moveTrip';
import PlanFee from './dialogs/planFee';
import TripFilters from './filters';
import Trip from './trip';
import AjaxButton from '../../../../components/ajaxButton';

import { updateSelectedTrips } from '../../../../bi/utils/trip';

import COMPONENTS from '../../../../bi/constants/components';
import { TRIPSLABELS, DIALOGSTITLES } from '../../../../bi/constants/trips';
import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from '../../styles/company.module.scss';

const ALLTRIPSINDEX = 0;

const LABEL_PLAN_FEE = 'Добавить первую абонплату';
const LABELS = {
  HEADER: [
    { SIGN: '#' },
    { START_DATE: 'Дата начала' },
    { END_DATE: 'Дата окончания' },
    { NAME: 'Название' },
    { SERVICES: 'Услуги' },
    { EMPLOYEES: 'Сотрудники' },
    { AMOUNT: 'Сумма' },
    { STATUS: 'Статус' },
  ],
  TRIPS: 'Поездки',
  SELECT: 'Выбрано',
  FROM: 'из',
};

class Trips extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    accountId: PropTypes.number.isRequired,
    handlerClearCache: PropTypes.func.isRequired,
    onNewTrip: PropTypes.func.isRequired,
    getXmlVisibilityFlag: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { accountService, companyService } = props;

    this.state = {
      trips: companyService.get(),
      account: accountService.get().account,

      filteredTrips: [],
      filters: null,
      waitingResponse: false,
      showLoadTripButton: true,
      loading: true,
      showDisabledAccountDialog: false,
      showOpenPlanFeeDialog: false,
      isScrollToTrips: false,
      projects: [],
      companies: [],
      tags: [],
      analytics: [],
      departments: [],
      initComponent: true,
      selectedTripsId: [],
      showOpenMoveDialog: false,
      selectedCompany: null,
      closingDocuments: [],
      integration1CMessages: [],
      errorMessages: [],
      waitResMove: false,
      hasResultMoveTrips: false,
    };
  }

  componentDidMount() {
    const {
      companyService, companyId, accountId, accountService,
      // tripService ,
    } = this.props;
    accountService.loadAccount(accountId).then(({ account: { Companies } }) => this.setState({ Companies }));

    this.unsubscribe = companyService.subscribe(this.update);

    companyService.getDepartments(accountId).then((departments) => this.setState({ departments }));
    companyService.getProjects(accountId).then((projects) => this.setState({ projects }));
    companyService.getTags(accountId).then((tags) => {
      const mappedTripTags = tags.map(({ Id, Value }) => ({ value: Id, label: Value }));
      this.setState({ tags: mappedTripTags });
    });
    // tripService.getAnalytics(accountId).then((analytics) => this.setState({ analytics }));
    companyService.loadTrips(companyId).then(() => this.setState({ initComponent: false }));
    this.setScrollFlag();
    setTimeout(this.handleScroll, 300);
  }

  componentDidUpdate(prevProps, prevState) {
    const { accountService, accountId } = this.props;
    const { Id } = prevState.account;

    if (Id !== accountService.get().account.Id && Id !== accountId) {
      this.setAccount();
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({ trips }) => this.setState({
    loading: false,
    filteredTrips: trips,
  });

  setAccount = () => this.setState({ account: this.props.accountService.get().account });

  setScrollFlag = () => {
    const [key, value] = location.search.slice(1).split('=');
    if (!!key && key === 'toScroll') {
      this.setState({ isScrollToTrips: Boolean(value) });
    } else {
      this.setState({ isScrollToTrips: false });
    }
  };

  handleScroll = () => {
    const { isScrollToTrips } = this.state;
    if (!isScrollToTrips) {
      return;
    }
    window.scroll({
      top: document.body.clientHeight - 40, left: 0, behavior: 'smooth',
    });
  };

  toggleDisabledAccountDialog = () => (
    this.setState({ showDisabledAccountDialog: !this.state.showDisabledAccountDialog })
  );

  toggleOpenPlanFee = () => this.setState({ showOpenPlanFeeDialog: !this.state.showOpenPlanFeeDialog });

  toggleOpenMoveDialog = () => this.setState({ showOpenMoveDialog: !this.state.showOpenMoveDialog });

  toggleCloseMoveDialog = async () => {
    const { companyService, companyId } = this.props;
    const { selectedTripsId, hasResultMoveTrips } = this.state;

    if (hasResultMoveTrips) {
      await companyService.loadTrips(companyId);

      const updatedSelectedTrips = companyService.get().trips;
      const updatedSelectedTripsId = updateSelectedTrips(updatedSelectedTrips, selectedTripsId);

      return this.setState({
        showOpenMoveDialog: false,
        hasResultMoveTrips: false,
        selectedCompany: null,
        selectedTripsId: updatedSelectedTripsId,
        showLoadTripButton: true,
        waitingResponse: false,
      });
    }

    return this.setState({
      showOpenMoveDialog: false,
      selectedCompany: null,
    });
  };

  handleCheckTrips = async () => {
    const { checkTrips } = this.props.tripService;
    const { selectedTripsId } = this.state;

    const response = await checkTrips(selectedTripsId);

    if (!response) {
      this.setState({ errorMessages: [DIALOGSTITLES.ERROR_MOVE_VOUCHER] });

      return this.toggleOpenMoveDialog();
    }

    const {
      ErrorMessages, ClosingDocumentsMessages, Integration1CMessages,
    } = response;

    this.setState({
      closingDocuments: ClosingDocumentsMessages,
      integration1CMessages: Integration1CMessages,
      errorMessages: ErrorMessages,
    });
    this.toggleOpenMoveDialog();
  };

  handleMoveTrips = async () => {
    const { moveTrips } = this.props.tripService;
    const { selectedTripsId, selectedCompany } = this.state;

    this.setState({ waitResMove: true });

    const response = await moveTrips(selectedTripsId, selectedCompany);

    if (!response) {
      return this.setState({
        errorMessages: [DIALOGSTITLES.ERROR_MOVE_VOUCHER],
        waitResMove: false,
        hasResultMoveTrips: true,
      });
    }

    const { ErrorMessages, ClosingDocumentsMessages } = response;

    this.setState({
      closingDocuments: ClosingDocumentsMessages,
      errorMessages: ErrorMessages,
      waitResMove: false,
      hasResultMoveTrips: true,
    });
  };

  handleFilterTrips = (filteredTrips, filters) => {
    const filtersLength = filters.cities.length
      || filters.employees.length
      || filters.services.length
      || filters.statuses.length
      || filters.startDate
      || filters.endDate;
    const trips = filtersLength ? filteredTrips : this.state.trips;

    this.setState({
      filteredTrips: trips,
      filters,
      selectedTripsId: [],
    });
  };

  handleAddNewTrip = () => {
    const { onNewTrip } = this.props;
    const { account: { Enabled } } = this.state;

    if (!Enabled) {
      return this.toggleDisabledAccountDialog();
    }

    return onNewTrip();
  };

  handleAddPlanFee = (data) => {
    const {
      accountService, companyService, accountId, companyId,
    } = this.props;

    const preparedDate = {
      ...data,
      AccountId: accountId,
      CompanyId: companyId,
    };

    return companyService.addPlanFee(preparedDate).then(() => accountService.updateOnlyAccount(accountId));
  };

  handleSelectedTripsId = (id) => {
    const { selectedTripsId } = this.state;

    if (selectedTripsId.includes(id)) {
      return this.setState({ selectedTripsId: selectedTripsId.filter((item) => item !== id) });
    }

    return this.setState({ selectedTripsId: [...selectedTripsId, id] });
  };

  handleAllSelectedTripsId = () => {
    const { filteredTrips, selectedTripsId } = this.state;

    if (!selectedTripsId.length) {
      return this.setState({
        selectedTripsId: filteredTrips
          .filter(({ Services }) => !Services.includes(SERVICETYPE.TAXI)).map(({ Id }) => Id),
      });
    }

    return this.setState({ selectedTripsId: [] });
  };

  handleSelectCompany = (companyId) => this.setState({ selectedCompany: companyId });

  loadTrips = () => {
    const { companyService, companyId } = this.props;

    this.setState({ waitingResponse: true }, () => {
      companyService.loadTrips(companyId, ALLTRIPSINDEX).then(() => {
        this.setState({
          showLoadTripButton: false,
          waitingResponse: false,
        });
      });
    });
  };

  renderDisabledAccountDialog = () => (
    <DisabledAccountDialog onClose={ this.toggleDisabledAccountDialog } />
  );

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading
        size='large'
      />
    </div>
  );

  renderCheckbox = () => {
    const { filteredTrips, selectedTripsId } = this.state;
    const { featureFlagsService: { getTripMove } } = this.props;

    const isTaxi = filteredTrips.every(({ Services }) => Services.includes(SERVICETYPE.TAXI));

    if (!getTripMove()) {
      return null;
    }

    if (isTaxi) {
      return <div className={ styles.checkbox_wrap } />;
    }

    const tripsWithoutTaxi = filteredTrips.filter(({ Services }) => !Services.includes(SERVICETYPE.TAXI));
    const isPartialSelect = tripsWithoutTaxi.length !== selectedTripsId.length && !!selectedTripsId.length;
    const isCheck = tripsWithoutTaxi.length === selectedTripsId.length;

    return (
      <div className={ styles.checkbox_wrap }>
        <Checkbox
          onChange={ this.handleAllSelectedTripsId }
          partialSelect={ isPartialSelect }
          value={ isCheck }
        />
      </div>
    );
  };

  renderHeader = () => (
    <div className={ styles.row }>
      { this.renderCheckbox() }
      { LABELS.HEADER.map((item) => Object.values(item)).flatMap((value) => (
        <div className={ styles.col_wrap } key={ value }>
          <label>{ value }</label>
        </div>
      )) }
    </div>
  );

  renderList = (list) => {
    if (list.length) {
      const {
        companyId,
        accountId,
        featureFlagsService: { getTripMove },
      } = this.props;

      const {
        waitingResponse, showLoadTripButton, selectedTripsId,
      } = this.state;

      const tripsList = list.map((item) => (
        <Trip
          key={ item.Id }
          item={ item }
          companyId={ companyId }
          accountId={ accountId }
          onHandleSelectedTripsId={ this.handleSelectedTripsId }
          selectedTripsId={ selectedTripsId }
          getTripMove={ getTripMove }
        />
      ));

      if (showLoadTripButton) {
        tripsList.push(
          <div className={ styles['center-button'] } key='loadTripsButton'>
            <div className={ `${styles.action} ${styles['load-all']}` }>
              <AjaxButton
                loading={ waitingResponse }
                theme='flat'
                label='ЗАГРУЗИТЬ ВСЕ ПОЕЗДКИ'
                onClick={ this.loadTrips }
              />
            </div>
          </div>,
        );
      }

      return (
        <div className={ styles.form }>
          { this.renderHeader() }
          { tripsList }
        </div>
      );
    }

    return null;
  };

  renderEmptyList = () => <div className={ `empty-list ${styles['empty-list']}` }>Нет поездок</div>;

  renderPlanFeeDialog = () => {
    const { companyId, accountService } = this.props;
    const { LastPlanFeeHistories } = accountService.get().account;

    return (
      <PlanFee
        companyId={ companyId }
        lastPlanFeeHistories={ LastPlanFeeHistories }
        onAddPlanFee={ this.handleAddPlanFee }
        onClose={ this.toggleOpenPlanFee }
        labelHeader={ LABEL_PLAN_FEE }
        placeholderLabel={ TRIPSLABELS.PLANFEE }
      />
    );
  };

  renderMoveTripDialog = () => {
    const {
      showOpenMoveDialog,
      closingDocuments,
      integration1CMessages,
      errorMessages,
      waitResMove,
      hasResultMoveTrips,
    } = this.state;

    if (!showOpenMoveDialog) {
      return null;
    }

    return (
      <MoveTrip
        onClose={ this.toggleCloseMoveDialog }
        onHandleSelectCompany={ this.handleSelectCompany }
        tripService={ this.props.tripService }
        closingDocuments={ closingDocuments }
        integration1CMessages={ integration1CMessages }
        errorMessages={ errorMessages }
        onHandleMoveTrips={ this.handleMoveTrips }
        waitResMove={ waitResMove }
        hasResultMoveTrips={ hasResultMoveTrips }
      />
    );
  };

  render() {
    const {
      companyService,
      handlerClearCache,
      accountId,
      featureFlagsService,
      featureFlagsService: { getTripMove },
      getXmlVisibilityFlag,
      companyId,
    } = this.props;

    const {
      filteredTrips,
      loading,
      showDisabledAccountDialog,
      showOpenPlanFeeDialog,
      departments,
      projects,
      Companies,
      tags,
      analytics,
      initComponent,
      selectedTripsId,
    } = this.state;
    const trips = companyService.get().trips;

    const html = filteredTrips && filteredTrips.length > 0 ? this.renderList(filteredTrips) : this.renderEmptyList();
    const disabledAccountDialogHtml = showDisabledAccountDialog ? this.renderDisabledAccountDialog() : null;
    const planFeeDialogHtml = showOpenPlanFeeDialog && this.renderPlanFeeDialog();
    const selectedTripsCount = `${LABELS.SELECT}: ${selectedTripsId.length} ${LABELS.FROM} ${filteredTrips.length}`;

    return loading || initComponent
      ? this.renderLoading()
      : (
        <div className={ styles['trip-company'] }>
          <div className={ styles.panel }>
            <TripFilters
              departments={ departments }
              companies={ Companies }
              projects={ projects }
              trips={ trips }
              companyService={ companyService }
              accountTripTags={ tags }
              analytics={ analytics }
              accountId={ accountId }
              filteredTrips={ filteredTrips }
              onChange={ this.handleFilterTrips }
              getXmlVisibilityFlag={ getXmlVisibilityFlag }
              featureFlagsService={ featureFlagsService }
              companyId={ companyId }
            />
          </div>
          <div className={ styles.panel }>
            <div className={ styles.title }>
              <div className={ styles['panel-name'] }>
                { LABELS.TRIPS }
                { getTripMove()
                && (
                  <div>
                    { selectedTripsCount }
                  </div>
                ) }
              </div>
              { getTripMove()
              && (
                <div>
                  <Button
                    label={ TRIPSLABELS.MOVE }
                    theme={ COMPONENTS.BUTTON.THEME.FLAT }
                    disabled={ !selectedTripsId.length }
                    onClick={ this.handleCheckTrips }
                  />
                </div>
              ) }
              <div>
                <Button
                  label={ TRIPSLABELS.CACHE }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ handlerClearCache }
                  disabled={ !!selectedTripsId.length }
                />
              </div>
              <div>
                <Button
                  label={ TRIPSLABELS.FEE }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.toggleOpenPlanFee }
                  disabled={ !!selectedTripsId.length }
                />
              </div>
              <div>
                <Button
                  label={ TRIPSLABELS.TRIP }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.handleAddNewTrip }
                  disabled={ !!selectedTripsId.length }
                />
              </div>
            </div>
            { html }
            { disabledAccountDialogHtml }
            { planFeeDialogHtml }
            { this.renderMoveTripDialog() }
          </div>
        </div>
      );
  }
}

export default Trips;
