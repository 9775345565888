import React from 'react';
import PropTypes from 'prop-types';

import { Dialog, Loading } from 'sw-ui';

import MoneyFormat from '../../../../../bi/utils/money';

import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../styles/index.module.css';

const LABELS = {
  ESTABLISHED_LIMITS: 'Установленные лимиты',
  ACCOUNT_LIMIT: 'Общий лимит на аккаунте:',
  COMPANY_LIMIT: 'Лимит компании',
};
const DIALOG_WIDTH = '600px';

const EstablishedLimits = ({
  onClose, companies, loading,
}) => {
  const sumOverdraft = companies.reduce((acc, { Overdraft }) => acc + Overdraft, 0);
  const sumAdditionalOverdraft = companies.reduce((acc, { AdditionalOverdraft }) => acc + AdditionalOverdraft, 0);
  const fullOverdraft = MoneyFormat.moneyWithDecimal(sumOverdraft);
  const fullAdditionalOverdraft = MoneyFormat.moneyWithDecimal(sumAdditionalOverdraft);

  const accountLimitsHtml = (
    <div>
      { LABELS.ACCOUNT_LIMIT }
      <span>
        { ` ${fullOverdraft} ` }
      </span>
      (
      <span className={ styles.additional_limits }>
        { ` +${fullAdditionalOverdraft} ` }
      </span>
      )
    </div>
  );

  const companyLimitsHtml = (company, index) => {
    const {
      Overdraft, AdditionalOverdraft, ShortCompanyName, CompanyName,
    } = company;
    const companyLimits = MoneyFormat.moneyWithDecimal(Overdraft);
    const additionalCompanyLimits = MoneyFormat.moneyWithDecimal(AdditionalOverdraft);
    const name = ShortCompanyName || CompanyName;
    const accountLimitsName = `${LABELS.COMPANY_LIMIT} ${name}:`;

    return (
      <div className={ styles.established_limits_row } key={ index } >
        { accountLimitsName }
        <span>
          { ' ' }
          { companyLimits }
          { ' ' }
        </span>
        (
        <span className={ styles.additional_limits }>
          { ' ' }
          { `+${additionalCompanyLimits}` }
          { ' ' }
        </span>
        )
      </div>
    );
  };

  const renderCompanyLitmits = companies.map((company, index) => companyLimitsHtml(company, index));

  const loadingHtml = (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  const limitsHtml = (
    <div className={ styles.established_limits }>
      <div className={ styles.established_limits_header }>
        <h3>{ LABELS.ESTABLISHED_LIMITS }</h3>
      </div>
      <div className={ styles.established_limits_row }>
        { accountLimitsHtml }
      </div>
      { renderCompanyLitmits }
      <div>
        <DecisionButtons
          labelCancel='Закрыть'
          onCancel={ () => onClose() }
          showSave={ false }
        />
      </div>
    </div>
  );

  const isLoad = loading ? loadingHtml : limitsHtml;

  return (
    <Dialog
      width={ DIALOG_WIDTH }
      onClick={ onClose }
    >
      { isLoad }
    </Dialog>
  );
};

EstablishedLimits.propTypes = {
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  companies: PropTypes.array.isRequired,
};

export default EstablishedLimits;
