import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './searchInput.module.css';

const SEARCH = 'search';

class SearchInput extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    defaultValue: PropTypes.string.isRequired,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { value: props.defaultValue || '' };
  }

  onChangeInput = (event) => {
    this.setState({ value: event.target.value });
  };

  onKeyPress = (target) => {
    if (target.charCode === 13) this.search();
  };

  onBlur = () => {
    this.search();
  };

  search = () => {
    if (this.props.onSubmit) this.props.onSubmit(this.state.value);
  };

  render() {
    const { label } = this.props;
    const { value } = this.state;

    return (
      <div className={ styles.wrap }>
        <input
          type={ SEARCH }
          value={ value }
          placeholder={ label }
          onChange={ this.onChangeInput }
          onKeyPress={ this.onKeyPress }
          onBlur={ this.onBlur }
        />
      </div>
    );
  }
}

export default SearchInput;
