import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../../../components/input';

import { TRIPSLABELS, FIELDS } from '../../../../../../../../bi/constants/trips';

import styles from '../../../../../../styles/form.module.scss';

const HotelName = ({
  groupHotels: {
    validation,
    CustomHotelName,
    disableMainHotelFields,
  },
  onValidateInput,
  tripService: { changeFieldGroupBooking },
  onChangeJsonInput,
}) => {
  const { Hotel: { Name: validationName } } = validation;

  const handleChangeInput = ({ target: { value } }, field) => {
    changeFieldGroupBooking(field, value);
    onChangeJsonInput(FIELDS.HOTELNAMEUPPER, value);
  };

  return (
    <div className={ styles['col-3-6'] }>
      <div className={ `${styles['auto-input']} ${styles.wrap} ${validationName ? styles['no-valid-hotel-name'] : ''}` }>
        <Input
          field={ FIELDS.CUSTOM_HOTEL_NAME }
          value={ CustomHotelName }
          label={ TRIPSLABELS.HOTELNAME }
          onChange={ handleChangeInput }
          onBlur={ () => onValidateInput(FIELDS.HOTELNAMEUPPER) }
          valid={ validationName }
          disabled={ disableMainHotelFields }
        />
      </div>
    </div>
  );
};

HotelName.propTypes = {
  onValidateInput: PropTypes.func.isRequired,
  groupHotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
  onChangeJsonInput: PropTypes.func.isRequired,
};

export { HotelName };
