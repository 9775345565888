
import debounce from 'es6-promise-debounce';

import ACTIONS from '../../actions';
import Api from '../../api';

import lodashReplaces from '../../utils/lodashReplaces';
import { normalizeRegionFields, prepareRegionFields } from '../../utils/regions';
import { alertDev } from '../../../utils/alert';

import { FIELDS } from '../../constants/regions';

import Validator from '../regions/validators/validator';

let store = null;

const DEBOUNCE_TIME = 200;

class RegionService {
  constructor(InitStore) {
    store = InitStore || this.getStore('Regions');
    this.validator = new Validator();
  }

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);

  setSearchFilter = (label, value) => {
    store.dispatch({
      type: ACTIONS.REGIONS.INPUT_SEARCH,
      label,
      value,
      init: true,
    });
  };

  unSetSearchFilter = () => store.dispatch({
    type: ACTIONS.REGIONS.INPUT_SEARCH,
    label: '',
    value: '',
    init: false,
  });

  autocompleteRegion = debounce(async (value) => {
    try {
      const res = await Api.Trip.autocompleteRegion(value);

      return res;
    } catch (e) {
      return [];
    }
  }, DEBOUNCE_TIME);

  searchRegions = async (regionName) => {
    store.dispatch({
      type: ACTIONS.REGIONS.SEARCH_REGIONS,
      isRequest: true,
      regions: [],
    });
    try {
      const res = await Api.Trip.searchRegions(regionName);

      store.dispatch({
        type: ACTIONS.REGIONS.SEARCH_REGIONS,
        isRequest: false,
        regions: res,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.REGIONS.SEARCH_REGIONS,
        isRequest: false,
        regions: [],
      });

      alertDev(e, 'regions', 'searchRegions');
    }
  };

  reset = () => store.dispatch({ type: ACTIONS.REGIONS.RESET });

  getRegion = async (id) => {
    store.dispatch({
      type: ACTIONS.REGIONS.GET_REGION, regionLoad: true, formData: null,
    });

    try {
      const res = await Api.Trip.getRegion(id);

      store.dispatch({
        type: ACTIONS.REGIONS.GET_REGION,
        formData: {
          ...prepareRegionFields(res),
          Id: res.Id,
        },
        regionLoad: false,
      });
    } catch (e) {
      store.dispatch({
        type: ACTIONS.REGIONS.GET_REGION,
        formData: null,
        regionLoad: false,
      });

      alertDev(e, 'regions', 'getRegion');
    }
  };

  updateRegion = async (region) => {
    this.updateBtnRequest(true);

    try {
      await Api.Trip.updateRegion(region);

      this.updateBtnRequest(false);
    } catch (e) {
      this.updateBtnRequest(false);

      alertDev(e, 'regions', 'updateRegion');

      return Promise.reject();
    }
  };

  addRegion = async (region) => {
    this.updateBtnRequest(true);
    store.dispatch({
      type: ACTIONS.REGIONS.ADD_REGION,
      addedRegionId: null,
    });

    try {
      const res = await Api.Trip.addRegion(region);

      store.dispatch({
        type: ACTIONS.REGIONS.ADD_REGION,
        addedRegionId: res.RegionId,
      });

      this.updateBtnRequest(false);
    } catch (e) {
      this.updateBtnRequest(false);

      store.dispatch({
        type: ACTIONS.REGIONS.ADD_REGION,
        addedRegionId: null,
      });

      alertDev(e, 'regions', 'addRegion');

      return Promise.reject();
    }
  };

  changeField = (value, path) => {
    const currentState = store.getState();
    const updatedState = lodashReplaces.setWith(currentState, path, value);

    return store.dispatch({
      type: ACTIONS.REGIONS.CHANGE_FIELD,
      updatedState,
    });
  };

  changeFields = (paths) => {
    const currentState = store.getState();
    const updatedState = paths.reduce((acc, { value, path }) => lodashReplaces.setWith(acc, path, value), currentState);

    store.dispatch({
      type: ACTIONS.HOTELS.CHANGE_FIELD,
      updatedState,
    });
  };

  updateBtnRequest = (value) => store.dispatch({
    type: ACTIONS.REGIONS.DO_REQUEST,
    btnRequest: value,
  });

  validateField = (field, value, type) => this.validator.isValid(field, value, type);

  isValidForm = (fields) => this.validator.isValidFields(fields);


  doValidation = async (value, field) => {
    const isValid = this.validateField(field, value);
    await this.changeField(isValid, `${FIELDS.VALIDATION}.${field}`);

    const { validation } = this.get();

    const validForm = this.isValidForm(validation);
    this.changeField(validForm, FIELDS.IS_VALID_FORM);
  };

  normalizeRegion = (region) => ({
    ...normalizeRegionFields(region),
    Id: region.Id,
  });

  getValidation = (fields) => this.validator.getValidation(fields);
}

export default RegionService;
