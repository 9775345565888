import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, MultiSelect } from 'sw-ui';

import TextArea from '../../../../components/textarea';
import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import COMPONENTS from '../../../../bi/constants/components';
import {
  GROUPING_TYPE,
  DAY_TYPE,
  NUMBERS,
} from '../../../../bi/constants/unloading1c';

import styles from '../../styles.module.css';

const LABELS = {
  UNIFICATION: 'Объединение реализаций',
  DATE: 'Дата реализации',
  SELECT_TAB: 'Выберите вкладку',
  ALL_TABS: 'Все вкладки',
  AMOUNT: 'Сумма реализации (из Excel) по столбцу',
  NOMENCLATURE: 'Номенклатура',
  COMMENT: 'Комментарий',
  WRITE_COMMENT: 'Напишите комментарий',
  LIMIT: 'Ограничение 1000 символов',
};

const LIMIT = 1000;

const StepThree = ({
  unloading1cService: {
    get,
    setGroupingType,
    setDayType,
    setAmountType,
    setNomenclature,
    setComment,
    setSheet,
  },
  onHandleNextStep,
  onHandlePrevStep,
}) => {
  const {
    workSheets,
    nomenclatures,
    settings,
  } = get();
  const {
    groupingType,
    dateDivisionType,
    realizationSettings: { comment },
    fileImportSettings: { excelSettings: { columnNamesNomenclaturesIds, sheetTitles } },
  } = settings;

  const initStateSheet = sheetTitles.length <= NUMBERS.ONE ? sheetTitles.join('') : LABELS.ALL_TABS;
  const initStateColumnTitles = Object.keys(columnNamesNomenclaturesIds);
  const currentWorkSheets = workSheets.map(({ Name }) => ({ label: Name, value: Name }));
  const allWorkSheets = [...currentWorkSheets, { label: LABELS.ALL_TABS, value: LABELS.ALL_TABS }];

  const getColumnTitles = () => {
    if (initStateSheet === LABELS.ALL_TABS) {
      return workSheets[0].ColumnTitles.map((title) => ({ label: title, value: title }));
    }

    const columnTitles = workSheets.find(({ Name }) => Name === initStateSheet).ColumnTitles;

    return columnTitles.map((title) => ({ label: title, value: title }));
  };

  const [selectedUnification, setSelectedUnification] = useState(groupingType);
  const [selectedDate, setSelectedDate] = useState(dateDivisionType);
  const [currentSheet, setCurrentSheet] = useState(initStateSheet);
  const [selectedColumnTitles, setSelectedColumnTitles] = useState(initStateColumnTitles);
  const [selectedNomenclature, setSelectedNomenclature] = useState(columnNamesNomenclaturesIds);
  const [textareaValue, setInputValue] = useState(comment);
  const [errorMessage, setErrorMessage] = useState('');

  const nomenclatureKeysLength = Object.keys(selectedNomenclature).length;

  const isDisabled = (
    !selectedUnification
    || !selectedDate
    || !currentSheet
    || !selectedColumnTitles.length
    || nomenclatureKeysLength === 0
    || nomenclatureKeysLength !== selectedColumnTitles.length
    || Object.values(selectedNomenclature).some((value) => !value)
    || !!errorMessage
  );

  const handleSelectUnification = ({ value }) => {
    setSelectedUnification(value);
    setGroupingType(value);
  };

  const handleSelectDate = ({ value }) => {
    setSelectedDate(value);
    setDayType(value);
  };

  const handleSelectSheet = ({ value }) => {
    setCurrentSheet(value);

    if (value !== initStateSheet) {
      setSelectedColumnTitles([]);
      setSelectedNomenclature({});
      setAmountType(null);
    }

    if (value === LABELS.ALL_TABS) {
      const sheets = workSheets.map(({ Name }) => Name);

      return setSheet(sheets);
    }

    setSheet([value]);
  };

  const handleSelectColumnTitle = (value) => {
    setSelectedColumnTitles(value);
    setAmountType(value);

    setSelectedNomenclature((prev) => {
      return Object.keys(prev)
        .reduce((acc, column) => {
          if (value.includes(column)) {
            acc[column] = prev[column];
          }

          return acc;
        }, {});
    });
  };

  const handleSelectNomenclature = (column, { value }) => {
    setSelectedNomenclature((prev) => ({
      ...prev,
      [column]: value,
    }));
    setNomenclature({ column, value });
  };

  const handleChangeTextArea = (value) => {
    if (value.length > LIMIT) {
      return setErrorMessage(LABELS.LIMIT);
    }

    setErrorMessage('');
    setInputValue(value);
    setComment(value);
  };

  const renderColumnTitles = () => {
    if (!currentSheet) return null;

    return (
      <>
        <div className={ styles.title }>
          { LABELS.AMOUNT }
        </div>
        <MultiSelect
          label={ LABELS.AMOUNT }
          items={ getColumnTitles() }
          values={ selectedColumnTitles }
          onChange={ handleSelectColumnTitle }
        />
      </>
    );
  };

  const renderNomenclature = () => {
    if (!selectedColumnTitles.length) return null;

    return (
      <>
        <div className={ styles.title }>
          { LABELS.NOMENCLATURE }
        </div>
        { selectedColumnTitles.map((column) => {
          const value = selectedNomenclature[column] || null;

          return (
            <Select
              key={ column }
              label={ column }
              items={ nomenclatures }
              value={ value }
              theme={ COMPONENTS.SELECT.THEME.BORDER }
              onChange={ (value) => handleSelectNomenclature(column, value) }
            />
          );
        }) }
      </>
    );
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.UNIFICATION }
      </div>
      <Select
        label={ LABELS.UNIFICATION }
        items={ GROUPING_TYPE }
        value={ selectedUnification }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ (value) => handleSelectUnification(value) }
      />
      <div className={ styles.title }>
        { LABELS.DATE }
      </div>
      <Select
        label={ LABELS.DATE }
        items={ DAY_TYPE }
        value={ selectedDate }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ (value) => handleSelectDate(value) }
      />
      <div className={ styles.title }>
        { LABELS.SELECT_TAB }
      </div>
      <Select
        label={ LABELS.SELECT_TAB }
        items={ allWorkSheets }
        value={ currentSheet }
        theme={ COMPONENTS.SELECT.THEME.BORDER }
        onChange={ (value) => handleSelectSheet(value) }
      />
      { renderColumnTitles() }
      { renderNomenclature() }
      <div className={ styles.title }>
        { LABELS.COMMENT }
      </div>
      <TextArea
        placeholder={ LABELS.WRITE_COMMENT }
        value={ textareaValue }
        onChange={ handleChangeTextArea }
        valid={ errorMessage }
        className={ styles.width_100 }
      />
      <div className={ styles.modal_buttons }>
        <BackButton
          onHandlePrevStep={ onHandlePrevStep }
        />
        <NextButton
          onHandleNextStep={ onHandleNextStep }
          disabled={ isDisabled }
        />
      </div>
    </div>
  );
};

StepThree.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandleNextStep: PropTypes.func.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
};

export { StepThree };
