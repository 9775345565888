import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Tabs, TabsItem,
} from 'sw-ui';

import DecisionButtons from '../../../components/DecisionButtons';
import Profile from './profile';
import Trips from './trips';

import COMPONENTS from '../../../bi/constants/components';
import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';

import styles from './styles/account.module.scss';
import UserProfile from './user-profile';
import Points from './e-points';
import Travellers from './travellers';
import Transactions from './transactions';

const TOOLTIP = 'Учитывайте, операция долгая. Придется подождать, возможно, несколько минут!';

const TAB_IDS = {
  ACCOUNT_PROFILE: 'profile',
  USER_PROFILE: 'user-profile',
  TRIPS: 'drips',
  TRAVELLERS: 'employees',
  POINTS: 'points',
  TRANSACTIONS: 'transactions',
};

const LABELS = {
  ACCOUNT_PROFILE: 'Профиль компании',
  USER_PROFILE: 'Профиль пользователя',
  TRIPS: 'Поездки',
  TRAVELLERS: 'Путешественники',
  POINTS: 'Баллы',
  TRANSACTIONS: 'Транзакции',
};

class BottomTabsLegacy extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    toNewTrip: PropTypes.func.isRequired,
    toUserCartsAndNote: PropTypes.func.isRequired,
    toAnotherTab: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      params: { companyId }, companyService, featureFlagsService, hotelsService,
    } = props;
    const { search } = hotelsService.get();

    companyService.setId(companyId);

    this.state = {
      openDialog: false,
      waitResClearCache: false,
      showTooltip: true,
      expenseReportsFeatureFlag: featureFlagsService.getExpenseReports(),
      searchHotelsInput: { ...search },
    };
  }

  componentDidMount() {
    const { featureFlagsService, hotelsService } = this.props;

    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    this.unsubscribeHotels = hotelsService.subscribe(this.updateHotels);
  }

  componentWillUnmount() {
    if (this.unsubFeatureFlags) { this.unsubFeatureFlags(); }
    if (this.unsubscribeHotels) { this.unsubscribeHotels(); }
  }

  updateHotels = ({ search }) => {
    this.setState({ searchHotelsInput: { ...search } });
  };

  updateFeatureFlags = ({ ExpenseReports }) => {
    this.setState({ expenseReportsFeatureFlag: ExpenseReports });
  };

  handleOpenDialog = () => this.setState({ openDialog: true });

  handleCloseDialog = () => this.setState({ openDialog: false, waitResClearCache: false });

  handlerClearCacheByCompanyId = () => {
    const { params: { companyId }, companyService } = this.props;

    const clearCache = () => {
      companyService.clearCacheByCompanyId(companyId)
        .then(() => companyService.updateTrips(companyId))
        .then(() => this.handleCloseDialog())
        .catch(() => this.handleCloseDialog());
    };

    this.setState({ waitResClearCache: true }, clearCache);
  };

  renderTabTitle = (tabId, title) => (
    <div className={ styles['tab-link'] } onClick={ () => this.props.toAnotherTab({ tabId }) }>
      { title }
    </div>);

  render() {
    const {
      companyService,
      notificationsService,
      accountService,
      airService,
      toNewTrip,
      toUserCartsAndNote,
      params,
      featureFlagsService,
      featureFlagsService: { getXmlVisibilityFlag },
      hotelsService,
      tripService,
    } = this.props;
    const {
      openDialog,
      waitResClearCache,
      showTooltip,
    } = this.state;

    const {
      companyId, tabId, accountId,
    } = params;

    return (
      <div>
        <Tabs theme='default' activeTabId={ tabId } >
          <TabsItem
            id={ TAB_IDS.ACCOUNT_PROFILE }
            title={ () => this.renderTabTitle(TAB_IDS.ACCOUNT_PROFILE, LABELS.ACCOUNT_PROFILE) }
          >
            <Profile
              accountService={ accountService }
              companyService={ companyService }
              featureFlagsService={ featureFlagsService }
              hotelsService={ hotelsService }
              notificationsService={ notificationsService }
              airService={ airService }
              toUserCartsAndNote={ toUserCartsAndNote }
              params={ params }
            />
          </TabsItem>
          <TabsItem
            id={ TAB_IDS.USER_PROFILE }
            title={ () => this.renderTabTitle(TAB_IDS.USER_PROFILE, LABELS.USER_PROFILE) }
          >
            <UserProfile />
          </TabsItem>
          <TabsItem
            id={ TAB_IDS.TRIPS }
            title={ () => this.renderTabTitle(TAB_IDS.TRIPS, LABELS.TRIPS) }
          >
            <Trips
              companyId={ companyId }
              accountId={ accountId }
              companyService={ companyService }
              accountService={ accountService }
              tripService={ tripService }
              onNewTrip={ () => toNewTrip(companyId) }
              handlerClearCache={ this.handleOpenDialog }
              getXmlVisibilityFlag={ getXmlVisibilityFlag }
              featureFlagsService={ featureFlagsService }
            />
          </TabsItem>
          <TabsItem
            id={ TAB_IDS.TRANSACTIONS }
            title={ () => this.renderTabTitle(TAB_IDS.TRANSACTIONS, LABELS.TRANSACTIONS) }
          >
            <Transactions />
          </TabsItem>
          <TabsItem
            id={ TAB_IDS.POINTS }
            title={ () => this.renderTabTitle(TAB_IDS.POINTS, LABELS.POINTS) }
          >
            <Points />
          </TabsItem>
          <TabsItem
            id={ TAB_IDS.TRAVELLERS }
            title={ () => this.renderTabTitle(TAB_IDS.TRAVELLERS, LABELS.TRAVELLERS) }
          >
            <Travellers />
          </TabsItem>
        </Tabs>
        { openDialog
          ? (
            <Dialog onClick={ this.handleCloseDialog }>
              <div className={ `${styles.form} ${styles['form-dialog']}` }>
                <span>Вы уверены, что хотите обновить поездки компании в кэше?</span>
                <div className={ `${styles.row} ${styles.action}` }>
                  <DecisionButtons
                    waitingResponse={ waitResClearCache }
                    onSave={ this.handlerClearCacheByCompanyId }
                    onCancel={ this.handleCloseDialog }
                    showTooltip={ showTooltip }
                    tooltip={ TOOLTIP }
                    tooltipPosition={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
                    labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
                  />
                </div>
              </div>
            </Dialog>
          )
          : null }
      </div>
    );
  }
}

export default BottomTabsLegacy;
