const ADDITIONAL_SERVICES_LABELS = {
  Number: 'Номер места',
  Mass: 'Вес багажа (кг)',
  Description: 'Название услуги',
  emd: 'EMD',
  supplier: 'Поставщик услуги',
  price: 'Цена',
  commission: 'Наценка Smartway',
  vat: 'НДС',
};

const ADDITIONAL_SERVICES = [
  {
    name: 'Выбор места', type: 0, inputs: ['Number'],
  },
  {
    name: 'Питание', type: 1, inputs: [],
  },
  {
    name: 'Дополнительный багаж', type: 2, inputs: ['Mass'],
  },
  {
    name: 'Дополнительная ручная кладь', type: 3, inputs: ['Mass'],
  },
  {
    name: 'Сверхнормативный багаж', type: 4, inputs: ['Mass'],
  },
  {
    name: 'Перевозка животного', type: 5, inputs: [],
  },
  {
    name: 'Провоз оружия на борту ВС', type: 6, inputs: [],
  },
  {
    name: 'Спорт снаряжение', type: 7, inputs: [],
  },
  {
    name: 'Велосипед (провоз)', type: 8, inputs: [],
  },
  {
    name: 'Страховка', type: 9, inputs: [],
  },
  {
    name: 'Поздравление на борту', type: 10, inputs: [],
  },
  {
    name: 'Другое', type: 11, inputs: ['Description'],
  },
];

export { ADDITIONAL_SERVICES, ADDITIONAL_SERVICES_LABELS };
