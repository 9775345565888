import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../../../components/input';

import COMPONENTS from '../../../../../../bi/constants/components';
import { PRICEFIELDSSHORT, PRICELABELS } from '../../../../../../bi/constants/trips';

import styles from '../../../../styles/form.module.scss';

const EditPrice = ({
  priceDetails: {
    Base,
    Commission,
    Fee,
    Tax,
  },
  onChangeEditPriceInput,
  onValidate,
  validation,
  renderVat,
}) => (
  <div className={ `${styles.row} ${styles['price_vat']}` } >
    <div className={ styles['col-1-5'] }>
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        field={ PRICEFIELDSSHORT.BASE }
        value={ Base }
        label={ PRICELABELS.BASE }
        onChange={ onChangeEditPriceInput }
        valid={ validation.Base }
        onBlur={ () => onValidate(PRICEFIELDSSHORT.BASE, Base) }
      />
    </div>
    <div className={ styles['col-1-5'] }>
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        field={ PRICEFIELDSSHORT.COMMISSION }
        value={ Commission }
        label={ PRICELABELS.COMMISSIONSMARTWAY }
        onChange={ onChangeEditPriceInput }
        valid={ validation.Commission }
        onBlur={ () => onValidate(PRICEFIELDSSHORT.COMMISSION, Commission) }
      />
    </div>
    <div className={ styles['col-1-5'] }>
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        field={ PRICEFIELDSSHORT.FEE }
        value={ Fee }
        label={ PRICELABELS.FEE }
        onChange={ onChangeEditPriceInput }
        valid={ validation.Fee }
        onBlur={ () => onValidate(PRICEFIELDSSHORT.FEE, Fee) }
      />
    </div>
    <div className={ styles['col-1-5'] }>
      <Input
        type={ COMPONENTS.INPUT.TYPE.NUMBER }
        field={ PRICEFIELDSSHORT.TAX }
        value={ Tax }
        label={ PRICELABELS.TAX }
        onChange={ onChangeEditPriceInput }
        valid={ validation.Tax }
        onBlur={ () => onValidate(PRICEFIELDSSHORT.TAX, Tax) }
      />
    </div>
    { renderVat() }
  </div>
);

EditPrice.propTypes = {
  priceDetails: PropTypes.object.isRequired,
  onChangeEditPriceInput: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  validation: PropTypes.func.isRequired,
};

export { EditPrice };
