import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import {
  Button, Dialog, Select,
} from 'sw-ui';

import { getMoment, isAfterDate } from '../../../../../bi/utils/formatDate';

import Input from '../../../../../components/input';
import AjaxButton from '../../../../../components/ajaxButton';

import COMPONENTS from '../../../../../bi/constants/components';
import { DEFAULTDATE } from '../../../../../constants/time';
import { FIELDS_FORM, FIELDS_FORM_RU } from '../../../../../bi/constants/profile';

import styles from '../../../styles/company.module.scss';

export default class AddCompanyDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    AccountTypeList: this.props.companyService.get().accountTypeList,
    CompanyTypeId: null,
    details: {
      CompanyId: '',
      CompanyName: '',
      ShortCompanyName: '',
      Contract: {
        Number: '',
        StartDate: '',
      },
      INN: '',
      KPP: '',
      LegalAddress: '',
      ActualAddress: '',
      CEO: '',
    },
    waitingResponse: false,
    validation: {
      CompanyName: '',
      INN: '',
      ContractNumber: '',
      ContractStartDate: '',
    },
  };

  handlerChangeInput = (field, value) => {
    this.setState({
      details: {
        ...this.state.details,
        [field]: value,
      },
    });
  };

  handleChangeSelect = ({ value }) => (
    this.setState({
      ...this.state,
      CompanyTypeId: value,
    })
  );

  handleChangeContract = (value, field) => this.setState({
    details: {
      ...this.state.details,
      Contract: {
        ...this.state.details.Contract,
        [field]: value,
      },
    },
  });

  handlerFocusInput = () => {
    this.setState({ error: null });
  };

  validationOnAdd = () => {
    const { details, CompanyTypeId } = this.state;
    const { isValid, validation } = this.validationAdd({ details, CompanyTypeId });

    if (isValid) {
      const save = () => this.props.onAdd({ ...this.state.details, CompanyTypeId });

      this.setState({ waitingResponse: true }, save);
    } else {
      this.setState({ validation: { ...validation } });
    }
  };

  validationAdd = ({ details, CompanyTypeId }) => {
    const { companyService } = this.props;
    const {
      CompanyName, INN, KPP, Contract,
    } = details;

    const companyName = companyService.infoCompanyFieldValidation(FIELDS_FORM.COMPANY_NAME, CompanyName);
    const inn = companyService.infoCompanyFieldValidation(FIELDS_FORM.INN, INN);
    const kpp = companyService.infoCompanyFieldValidation(FIELDS_FORM.KPP, KPP);
    const companyTypeId = companyService.infoCompanyFieldValidation(FIELDS_FORM.COMPANY_TYPE_ID, CompanyTypeId);
    const contractNumber = Contract && Contract.StartDate && !Contract.Number ? companyService.infoCompanyFieldValidation(FIELDS_FORM.CONTRACT_NUMBER, Contract.Number) : '';
    const contractStartDate = Contract && Contract.Number && !Contract.StartDate ? companyService.infoCompanyFieldValidation(FIELDS_FORM.CONTRACT_START_DATE, Contract.StartDate) : '';

    return companyName
      || inn
      || kpp
      || contractNumber
      || companyTypeId
      || contractStartDate
      ? {
        isValid: false,
        validation: {
          companyTypeId,
          CompanyName: companyName,
          INN: inn,
          KPP: kpp,
          ContractNumber: contractNumber,
          ContractStartDate: contractStartDate,
        },
      }
      : { isValid: true };
  };

  validationInput = (e, field) => {
    const { companyService } = this.props;

    this.setState((state) => ({
      ...state,
      validation: {
        ...state.validation,
        [field]: companyService.infoCompanyFieldValidation(field, state.details[field]),
      },
    }));
  };

  getValidStartDate = (currentDate) => isAfterDate(getMoment(), currentDate);

  render() {
    const { onClose } = this.props;
    const {
      AccountTypeList,
      CompanyTypeId,
      details: {
        CompanyName,
        ShortCompanyName,
        INN,
        KPP,
        LegalAddress,
        ActualAddress,
        CEO,
        Contract: {
          Number,
          StartDate,
        },
      },
      validation,
      waitingResponse,
    } = this.state;
    const {
      ContractStartDate, ContractNumber, companyTypeId,
    } = validation;

    const validDateHtml = ContractStartDate && <span className={ styles['error-msg_contract'] }>{ ContractStartDate }</span>;
    const notSelectedCompanyType = companyTypeId && <span className={ styles['error-msg_contract'] }>{ companyTypeId }</span>;

    return (
      <Dialog onClick={ onClose } width='400px'>
        <div className={ `${styles.form} ${styles['company-dialog']}` }>
          <div className={ `${styles.row} ${styles.title}` }>
            <div className={ styles.col } >
              Добавление организации
            </div>
          </div>
          <div className={ `${styles.row} ${styles.select}` } >
            <div className={ styles.col } >
              <Select
                label='Признак подаккаунта'
                items={ AccountTypeList }
                theme={ COMPONENTS.SELECT.THEME.BORDER }
                value={ CompanyTypeId }
                onChange={ this.handleChangeSelect }
              />
              { notSelectedCompanyType }
            </div>
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.COMPANY_NAME }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ CompanyName }
                label={ FIELDS_FORM_RU[FIELDS_FORM.COMPANY_NAME] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
                onBlur={ this.validationInput }
                valid={ validation.CompanyName }
              />
            </div>
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.SHORT_COMPANY_NAME }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ ShortCompanyName }
                label={ FIELDS_FORM_RU[FIELDS_FORM.SHORT_COMPANY_NAME] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <Input
                field={ FIELDS_FORM.CONTRACT_NUMBER }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ Number }
                label={ FIELDS_FORM_RU[FIELDS_FORM.CONTRACT_NUMBER] }
                onChange={ (e, field, value) => this.handleChangeContract(value, field) }
                onFocus={ this.handlerFocusInput }
                valid={ ContractNumber }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.label }>{ FIELDS_FORM_RU[FIELDS_FORM.CONTRACT_START_DATE] }</div>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat=''
              value={ StartDate }
              onChange={ (value) => this.handleChangeContract(value, FIELDS_FORM.CONTRACT_START_DATE) }
              isValidDate={ this.getValidStartDate }
              className={ `componentHook ${ContractStartDate ? 'no-valid' : ''}` }
            />
            { validDateHtml }
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.INN }
                value={ INN }
                label={ FIELDS_FORM_RU[FIELDS_FORM.INN] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
                onBlur={ this.validationInput }
                valid={ validation.INN }
              />
            </div>
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.KPP }
                value={ KPP }
                label={ FIELDS_FORM_RU[FIELDS_FORM.KPP] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
                onBlur={ this.validationInput }
                valid={ validation.KPP }
              />
            </div>
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.LEGAL_ADDRESS }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ LegalAddress }
                label={ FIELDS_FORM_RU[FIELDS_FORM.LEGAL_ADDRESS] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
              />
            </div>
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.ACTUAL_ADDRESS }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ ActualAddress }
                label={ FIELDS_FORM_RU[FIELDS_FORM.ACTUAL_ADDRESS] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
              />
            </div>
          </div>
          <div className={ styles.row } >
            <div className={ styles.col } >
              <Input
                field={ FIELDS_FORM.CEO }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ CEO }
                label={ FIELDS_FORM_RU[FIELDS_FORM.CEO] }
                onChange={ (e, field, value) => this.handlerChangeInput(field, value) }
                onFocus={ this.handlerFocusInput }
              />
            </div>
          </div>
          <div className={ `${styles.row} ${styles.action}` } >
            <Button label='Отменить' onClick={ onClose } theme='flat' />
            <AjaxButton label='Добавить' loading={ waitingResponse } onClick={ this.validationOnAdd } />
          </div>
        </div>
      </Dialog>
    );
  }
}

