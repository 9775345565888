import moment from 'moment';

const PASSPORTTIME = 'DD-MM-YYYY';

const getPassportType = (code, type) => {
  if (code === 'RU') {
    if (type === 'DomesticPassport') {
      return 'Паспорт РФ';
    } else if (type === 'ForeignPassport') {
      return 'Загран.паспорт';
    } else if (type === 'BirthCertificate') {
      return 'Свидетельство о рождении';
    }
  }

  return 'Паспорт иностранного гр-на';
};

const getPassportDueDate = (dueDate = '') => {
  if (!dueDate) {
    return null;
  }

  return moment(dueDate).format(PASSPORTTIME);
};

export {
  getPassportType,
  getPassportDueDate,
};
