import { UUID } from 'node:crypto';
import { Response } from 'superagent';

import ApiInstance from '../../../bi/api';

import { documentTemplates } from './stores/documentTemplates';

import { getCompanyFromParams } from './utils/query';

import { LABELS } from '../../pages/Company/components/DocumentTemplates/consts';

export class Company {
  documentTemplates = documentTemplates;

  loadTemplatesRaw = async () => {
    const templates = await ApiInstance.Company.loadListRegistryTemplates(
      getCompanyFromParams(),
    );

    this.documentTemplates.setTemplates(templates);
  };

  uploadFile = async (file: File) => {
    if (this.documentTemplates.templates.length === 3) {
      return this.documentTemplates.setError(LABELS.ERRORS.TEMPLATE_LIMIT);
    }

    this.documentTemplates.setLoading(true);
    try {
      await ApiInstance.Company.uploadRegistryTemplates(getCompanyFromParams(), file);
      await this.loadTemplatesRaw();
    } catch (e: unknown) {
      if (e instanceof Response) {
        this.documentTemplates.setLoading(false);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    this.documentTemplates.setLoading(false);

    return null;
  };

  getFilePreview = async (rId: UUID, format: string) => {
    try {
      await ApiInstance.Company.downloadRegistry(rId, format, true);

      return null;
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }
  };

  getTemplate = async (rId: UUID) => {
    try {
      await ApiInstance.Company.downloadTemplate(rId);

      return null;
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }
  };

  loadTemplates = async () => {
    this.documentTemplates.setLoading(true);
    try {
      await this.loadTemplatesRaw();
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoading(false);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    return this.documentTemplates.setLoading(false);
  };

  removeTemplates = async (ids: UUID[]) => {
    this.documentTemplates.setLoading(true);
    try {
      await ApiInstance.Company.removeRegistryTemplates(ids);
      await this.loadTemplatesRaw();
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoading(false);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }

      return e;
    }

    return this.documentTemplates.setLoading(false);
  };


  updateTemplateName = async (name: string, id: UUID) => {
    this.documentTemplates.setLoadingField(id);
    try {
      await ApiInstance.Company.updateRegistryTemplatesName(getCompanyFromParams(), id, name);
    } catch (e) {
      if (e instanceof Response) {
        this.documentTemplates.setLoadingField(id);
        this.documentTemplates.setError(e.body ? e.body : 'Неизвестная ошибка');
      }
    }

    await this.loadTemplatesRaw();

    return this.documentTemplates.setLoadingField(null);
  };
}

export const company = new Company();
