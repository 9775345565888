import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/index.module.css';

const HAS_VALUE = 'has-value';
const DISABLE_RESIZE = 'disable-resize';

class TextArea extends Component {
  static propTypes = {
    type: PropTypes.string,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    height: PropTypes.number,
    disableResize: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    valid: PropTypes.string,
    labelTop: PropTypes.bool,
    rows: PropTypes.number,
  };

  static defaultProps = {
    type: 'text',
    label: '',
    value: '',
    placeholder: '',
    className: '',
    height: 0,
    disableResize: false,
    onChange: null,
    disabled: false,
    labelTop: false,
    valid: '',
    rows: null,
  };

  constructor(props) {
    super(props);

    this.state = { hasValue: Boolean(props.value) };
  }

  handleChange = (value) => {
    const { onChange } = this.props;

    const hasValue = Boolean(value);

    this.setState({ hasValue });

    if (onChange) {
      onChange(value);
    }
  };

  render() {
    const {
      type,
      value,
      label,
      height,
      placeholder,
      className,
      disableResize,
      disabled,
      valid,
      labelTop,
      rows,
    } = this.props;

    const heightStyle = height ? `${height}px` : '';
    const style = { height: heightStyle };
    const labelHtml = label.length ? <label className={ `${labelTop ? styles.label : ''}` }>{ label }</label> : null;
    const validHtml = valid ? <span className={ `error-msg ${styles.error}` }>{ valid }</span> : null;
    const textAreaClass = `${styles.textarea} ${valid && styles.invalid_area} ${this.state.hasValue && HAS_VALUE} ${disableResize && DISABLE_RESIZE} ${labelTop ? styles.with_label_top : ''} ${className}`;

    return (
      <div className={ styles.contaiener }>
        <div className={ `${styles.wrap} ${labelTop ? styles.wrap_lable_top : ''}` }>
          <textarea
            placeholder={ placeholder }
            type={ type }
            className={ textAreaClass }
            style={ style }
            onChange={ ({ target }) => this.handleChange(target.value) }
            value={ value || '' }
            disabled={ disabled }
            rows={ rows }
          />
          { labelHtml }
        </div>
        { validHtml }
      </div>
    );
  }
}

export default TextArea;
