import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';
import TextArea from '../../../../../components/textarea';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import { COMMENTS } from '../../../../../bi/constants/account';

import styles from '../styles/dialog.module.css';

const LABELS = {
  HEADER: (documentNumber) => `Комментарий к пакету документов ${documentNumber}`,
  COMMENT: 'Комментарий к пакету документов',
  VALIDATION: 'Превышено максиммальное количество символов',
};

class EditPackageDetails extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    packageId: PropTypes.number.isRequired,
    documentId: PropTypes.string.isRequired,
    comment: PropTypes.string.isRequired,
    correction: PropTypes.number,
  };

  static defaultProps = { correction: null };

  state = {
    waitingResponse: false,
    editComment: this.props.comment || '',
  };

  handleChange = (editComment) => {
    this.setState({ editComment });
  };

  handleConfirm = () => {
    const {
      companyService, packageId, onClose, correction,
    } = this.props;
    const { editComment } = this.state;

    companyService.updatePackageDetails(packageId, correction, editComment)
      .then(() => onClose());
  };

  render() {
    const { onClose, documentId } = this.props;
    const { waitingResponse, editComment } = this.state;

    const comment = editComment === COMMENTS.IntercomComment.NOCOMMENTTEXT
      ? ''
      : editComment;

    const validation = comment.length > 400;
    const validationTitle = validation ? LABELS.VALIDATION : null;

    return (
      <Dialog onClick={ onClose } width='500px'>
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            <div className={ styles.header }>
              { LABELS.HEADER(documentId) }
            </div>
          </div>
          <TextArea
            value={ comment }
            placeholder={ LABELS.COMMENT }
            className={ styles.text_area }
            onChange={ this.handleChange }
            height={ 100 }
            valid={ validationTitle }
            labelTop
          />
          <div className={ styles.actions }>
            <DecisionButtons
              waitingResponse={ waitingResponse }
              onSave={ this.handleConfirm }
              onCancel={ onClose }
              labelSave={ DECISIONBUTTONS.LABELS.SAVE }
              disabled={ validation }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { EditPackageDetails };
