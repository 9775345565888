import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../components/DecisionButtons';

import styles from '../../styles/trip.module.scss';

const SendVoucher = ({
  employees, waitingResponse, onClose, onSend,
}) => {
  const preparedEmployees = employees ? employees.map(({ Email }) => Email).join(', ') : [];
  const emailHtml = preparedEmployees.length
    ? (
      <div>
        Отправить ваучер на
        { preparedEmployees }
        ?
      </div>
    )
    : null;

  return (
    <Dialog onClick={ onClose }>
      <div className={ styles['user-dialog'] }>
        { emailHtml }
        <div className={ `${styles.row} ${styles.right}` }>
          <DecisionButtons
            waitingResponse={ waitingResponse }
            onSave={ onSend }
            onCancel={ onClose }
          />
        </div>
      </div>
    </Dialog>
  );
};

SendVoucher.propTypes = {
  employees: PropTypes.array.isRequired,
  waitingResponse: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSend: PropTypes.func.isRequired,
};

export default SendVoucher;
