import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { getCurrentDate, formatDate } from '../../utils/date';
import { isEmail } from '../../bi/utils/emailValidator';

import { DATE } from '../../constants/time';
import COMPONENTS from '../../bi/constants/components';

import RangeDatePicker from '../RangeDatePicker';
import Input from '../input';

import styles from './index.module.css';

const LABELS = {
  HEADER: 'Автоматическая отправка писем',
  START_DATE: 'Дата начала',
  END_DATE: 'Дата окончания',
  DOWNLOAD: 'Скачать',
  EMAIL: 'email',
  EMAIL_RU: 'Эл. почта',
};

export default class AutoSendingEmails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: getCurrentDate(),
      endDate: getCurrentDate(),
      email: '',
      disabled: true,
    };
  }

  onDateChange = (field, value) => this.setState({ [field]: value });

  onChangeField = (e, field, val) => {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ email: val });
  };

  onSubmit = () => {
    const {
      startDate, endDate, email,
    } = this.state;
    const { autoSendingEmailReport } = this.props.businessService;

    autoSendingEmailReport(formatDate(startDate, DATE), formatDate(endDate, DATE), email);
  };

  render() {
    const {
      onDateChange, onChangeField, onSubmit, state: {
        startDate, endDate, email,
      },
    } = this;
    const disabled = !isEmail(email);

    return (
      <div className={ styles.content }>
        <div className={ styles.header }>
          { LABELS.HEADER }
        </div>
        <div className={ styles.form }>
          <div className={ `${styles.alignCenter}` }>
            <RangeDatePicker
              onChange={ onDateChange }
              startDate={ startDate }
              endDate={ endDate }
              placeholderNameFrom={ LABELS.START_DATE }
              placeholderNameTo={ LABELS.END_DATE }
              placeholderClassName={ styles.paddingAround }
            />
          </div>
          <div className={ `${styles.paddingLeft} ${styles.alignCenter}` }>
            <Input
              type={ LABELS.EMAIL }
              field={ LABELS.EMAIL }
              placeholder={ LABELS.EMAIL_RU }
              onChange={ onChangeField }
              value={ email }
              className={ styles.emptyPadding }
            />
          </div>
          <div className={ `${styles.paddingLeft} ${styles.alignCenter}` }>
            <Button
              label={ LABELS.DOWNLOAD }
              theme={ COMPONENTS.BUTTON.THEME.RED }
              onClick={ onSubmit }
              disabled={ disabled }
            />
          </div>
        </div>
      </div>
    );
  }
}
AutoSendingEmails.propTypes = { businessService: PropTypes.object.isRequired };
