import ACTIONS from '../../actions';

let store = null;
let appStore = null;

const LABELSEARCHINPUT = 'Поиск поездок';

class FilterService {
  constructor(InitStore, AppStore) {
    store = InitStore || this.getStore('Filter');
    appStore = AppStore || this.getStore('App');
  }

  setSearchFilter(label, value) {
    store.dispatch({
      type: ACTIONS.FILTER.SETSEARCHFILTER,
      label,
      value,
    });
  }

  cancelSearchFilter() {
    store.dispatch({ type: ACTIONS.FILTER.CANCELSEARCHFILTER });
  }

  changeSearchFilterValue(value) {
    store.dispatch({
      type: ACTIONS.FILTER.CHANGESEARCHFILTERVALUE,
      value,
    });
  }

  setSearchTravellerFilter(label, value) {
    store.dispatch({
      type: ACTIONS.FILTER.SETSEARCHTRAVELLERSFILTER,
      label,
      value,
    });
  }

  cancelSearchTravellerFilter() {
    store.dispatch({ type: ACTIONS.FILTER.CANCELSEARCHTRAVELLERFILTER });
  }

  changeIsActiveBookTrips(valueActive) {
    store.dispatch({
      type: ACTIONS.FILTER.CHANGEISACTIVEBOOKTRIPS,
      valueActive,
    });
  }

  changeIsSearchByInn(isInnOnly) {
    store.dispatch({
      type: ACTIONS.FILTER.CHANGEISSEARCHBYINN,
      isInnOnly,
    });
  }

  changeSearchTravellerFilterValue(value) {
    store.dispatch({
      type: ACTIONS.FILTER.CHANGESEARCHTRAVELLERFILTERVALUE,
      value,
    });
  }

  get() {
    return store.getState();
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

  searchOrdersFilter() {
    const { params } = appStore.getState();

    let active = false;
    if (params.active) {
      active = Boolean(parseInt(params.active, 10));
    }

    let obj = null;

    if (params.query || params.active) {
      obj = {
        type: ACTIONS.FILTER.SETSEARCHFILTERBYQUERY,
        payload: {
          query: params.query,
          label: LABELSEARCHINPUT,
          active,
        },
      };
    } else {
      obj = {
        type: ACTIONS.FILTER.SEARCHORDERSFILTER,
        label: LABELSEARCHINPUT,
        value: '',
        valueActive: false,
      };
    }

    store.dispatch(obj);
  }
}

export default FilterService;
