import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ANCHORS, TITLE } from '../../bi/constants/employee';
import LinkToNewTab from '../../components/LinkToNewTab';

import styles from './styles/employee.module.scss';

const LABELS = {
  SEND_TO_EVERYONE: 'Требуется согласование одного из сотрудников, отправлять запрос всем из списка',
  SEND_SELECTIVELY: 'Требуется согласование одного из сотрудников, отправлять запрос любому из списка:',
  SEND_TO_EVERYONE_SAME_TIME: 'Требуется одновременное согласование:',
  TRIPS_FOR_TP: 'для поездок нарушающих travel-политику',
  FOR_ALL_TRIPS: 'для всех поездок',
  LEADER_PROJECT: 'руководитель центра затрат',
  LEADER_DEPARTMENT: 'руководитель отдела',
  REQUEST: 'заявка',
};

class EmployeeScheme extends Component {
  static propTypes = {
    approvalService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    rights: PropTypes.object,
    approvalScheme: PropTypes.object,
    setRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    rights: null,
    approvalScheme: null,
  };

  state = { employeesWithRights: [] };

  componentDidMount() {
    this.getEmployeesWithRight();
  }

  getEmployeesWithRight = async () => {
    const { approvalService, appService } = this.props;
    const { employeeId } = appService.get().params;
    const employeesWithRights = await approvalService.getEmployeesWithRight(employeeId);

    this.setState({ employeesWithRights });
  };

  renderEmployeeLink = ({ EmployeeId, ShortName }) => (
    <LinkToNewTab to={ `/employeePage/${EmployeeId}` } key={ EmployeeId }>
      <span className={ styles.dashed }>
        { ShortName }
      </span>
    </LinkToNewTab>
  );

  renderSchemeList = () => {
    const { rights: { buyApprovalScheme }, approvalScheme } = this.props;
    const { employeesWithRights } = this.state;
    const type = (Type) => (Type ? LABELS.TRIPS_FOR_TP : LABELS.FOR_ALL_TRIPS);

    let PreSteps = [];
    let Steps = [];

    if (approvalScheme && 'PreSteps' in approvalScheme) PreSteps = approvalScheme.PreSteps;

    if (approvalScheme && 'Steps' in approvalScheme) Steps = approvalScheme.Steps;

    const allSteps = [...PreSteps, ...Steps];

    const approverName = ({
      ConditionOfApproval, Roles, Approvers,
    }) => {
      const union = ConditionOfApproval > 1 ? ' или ' : ' и ';
      const roles = Roles.length
        ? Roles.map((i) => (i === 2 ? LABELS.LEADER_PROJECT : LABELS.LEADER_DEPARTMENT))
        : null;

      const findApprover = Approvers.length
        && employeesWithRights.filter((i) => Approvers.find((em) => em === i.UserId));
      const approverEmployee = findApprover
        ? findApprover.map((employee) => this.renderEmployeeLink(employee))
        : null;

      const arrRoles = roles || [];
      const arrApproverEmployee = approverEmployee || [];
      const arrConcat = [...arrRoles, ...arrApproverEmployee];

      const newArr = [...arrConcat]
        .map((el, i) => (i < arrConcat.length - 1 ? [el, union] : [el]))
        .reduce((a, b) => a.concat(b));

      return (
        <div>
          { newArr }
        </div>
      );
    };

    const whoIsReceivers = ({
      ConditionOfApproval, Approvers, Roles,
    }) => {
      if (Approvers.length + Roles.length < 2) return null;
      if (ConditionOfApproval === 0) return <div>{ LABELS.SEND_TO_EVERYONE_SAME_TIME }</div>;

      const whoShouldReceive = ConditionOfApproval === 1 ? LABELS.SEND_TO_EVERYONE : LABELS.SEND_SELECTIVELY;

      return <div>{ whoShouldReceive }</div>;
    };

    return (
      buyApprovalScheme && allSteps.map((item, index) => {
        const { Id, Type } = item;

        return (
          <div className={ styles.row } key={ Id }>
            <div className={ styles['col-1-2'] }>
              <label>
                { index + 1 }
                { ' ' }
                Шаг (
                { PreSteps.length > index ? LABELS.REQUEST : type(Type) }
                )
              </label>
            </div>
            <div className={ `${styles['col-1-3']} ${styles.list}` }>
              { whoIsReceivers(item) }
              <div>
                <ul className={ styles.list }>
                  <li className={ styles.item } key={ Id } >{ approverName(item) }</li>
                </ul>
              </div>
            </div>
          </div>
        );
      })
    );
  };

  render() {
    const { rights, setRef } = this.props;

    return rights && rights.buyApprovalScheme && (
      <div id={ ANCHORS.SCHEME } className={ styles.col1 } ref={ (ref) => setRef(ANCHORS.SCHEME, ref) }>
        <div className={ styles.title }>
          { TITLE.EMPLOYEE_SCHEME }
        </div>
        { this.renderSchemeList() }
      </div>
    );
  }
}

export default EmployeeScheme;
