import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Checkbox, Button,
} from 'sw-ui';

import { STEP_CONDITION_VALUES } from '../../../../bi/constants/cart';
import COMPONENTS from '../../../../bi/constants/components';

import TextArea from '../../../../components/textarea';

import styles from './index.module.css';

const LABELS = {
  SEND_TITLE: 'Отправка на согласование',
  STEP_NUM: 'Шаг №',
  PLEASE_SELECT: 'Пожалуйста, выберите, кому отправить запрос:',
  NEED_APPROVER: 'Требуется согласование хотя бы одного из указанных сотрудников',
  NEED_ALL_APPROVERS: 'Требуется согласование каждого из указанных сотрудников',
  COMMENT: 'При необходимости оставьте комментарий',
  COMMENT_PLACEHOLDER: 'Введите комментарий',
  SEND_TO_APPROVE: 'Отправить запрос',
  SCHEME_SPPROVE: 'Схема согласования:',
};

const ApproveDialog = ({
  items: { Steps },
  accountId,
  companyId,
  userId,
  cartId,
  approvalName,
  selectedApprovers,
  commentForApprove,
  cartsAndNotesService,
  errorApprove,
  onClick,
}) => {
  const [loading, setLoading] = useState(false);

  const handleChangeApprovers = (approver, stepId) => {
    cartsAndNotesService.changeApprovers(approver, stepId);
  };

  const handleChangeComment = (item) => {
    cartsAndNotesService.changeComment(item);
  };

  const handleSendToApprove = async () => {
    setLoading(true);

    await cartsAndNotesService.sendToApprove(cartId, userId, accountId, companyId);

    setLoading(false);
  };

  const renderNumStep = (index) => {
    if (Steps.length <= 1) return null;

    return (
      <h4>
        { LABELS.STEP_NUM }
        { index + 1 }
      </h4>
    );
  };

  const renderApprovers = ({
    Approvers, Id, StepCondition,
  }) => Approvers.map((item) => {
    const {
      FirstName, LastName, MiddleName, Email, UserId,
    } = item;

    const fio = `${LastName || ''} ${FirstName || ''} ${MiddleName || ''}`;
    const fullLabel = `${fio} (${Email || ''})`;

    const checkedApprover = selectedApprovers.find(({ Id: selectId }) => selectId === Id);
    const checkboxValue = selectedApprovers.length && checkedApprover
      ? !!checkedApprover.Approvers.filter((approver) => approver.UserId === UserId).length
      : false;

    return (
      <div className={ styles.checkbox }>
        <Checkbox
          value={ checkboxValue }
          onChange={ () => handleChangeApprovers({ UserId, Email }, Id) }
          disabled={ StepCondition === STEP_CONDITION_VALUES.ALL_APPROVERS }
        >
          <span>{ fullLabel }</span>
        </Checkbox>
      </div>
    );
  });

  const renderApprovalName = () => {
    if (!approvalName) return null;

    return (
      <h4>
        { LABELS.SCHEME_SPPROVE }
        { ' ' }
        { approvalName }
      </h4>
    );
  };

  const renderSteps = () => Steps.map((item, index) => {
    const pleaseSelectText = item.StepCondition !== STEP_CONDITION_VALUES.ALL_APPROVERS
      ? LABELS.PLEASE_SELECT
      : null;
    const needApproverText = item.StepCondition !== STEP_CONDITION_VALUES.ALL_APPROVERS
      ? LABELS.NEED_APPROVER
      : null;

    return (
      <div className={ styles.steps }>
        { renderNumStep(index) }
        <p>{ pleaseSelectText }</p>
        { renderApprovers(item) }
        <p>{ needApproverText }</p>
      </div>
    );
  });

  return (
    <Dialog
      onClick={ onClick }
    >
      <div className={ styles.dialog }>
        <h3>{ LABELS.SEND_TITLE }</h3>
        { renderApprovalName() }
        { renderSteps() }
        <p className={ styles.error }>{ errorApprove }</p>
        <div className={ styles.comment }>
          <h4>{ LABELS.COMMENT }</h4>
          <TextArea
            className={ styles.textarea }
            value={ commentForApprove }
            placeholder={ LABELS.COMMENT_PLACEHOLDER }
            height={ 100 }
            onChange={ handleChangeComment }
          />
        </div>
        <Button
          label={ LABELS.SEND_TO_APPROVE }
          theme={ COMPONENTS.BUTTON.THEME.RED }
          onClick={ handleSendToApprove }
          disabled={ loading }
          loading={ loading }
        />
      </div>
    </Dialog>
  );
};

ApproveDialog.propTypes = {
  items: PropTypes.object.isRequired,
  accountId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  userId: PropTypes.object.isRequired,
  cartId: PropTypes.number.isRequired,
  approvalName: PropTypes.string.isRequired,
  selectedApprovers: PropTypes.array.isRequired,
  commentForApprove: PropTypes.string.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  errorApprove: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export { ApproveDialog };
