import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import RangeDatePicker from '../../components/RangeDatePicker';
import AjaxButton from '../../components/ajaxButton';

import { formatDate } from '../../bi/utils/formatDate';

import styles from './styles.module.css';

const TEXT = {
  NO_COMMENT: 'Нет комментария',
  NO_PACKAGE: 'Нет пакетов документов требующих исправления',
  DATE: 'Дата',
  SHOW: 'Показать',
};

const HEADER = {
  COMPANY_NAME: 'Название компании',
  PACKAGE_NUMBER: 'Номер пакета',
  DATE: 'Дата формирования',
  PERIOD: 'Период за который сформирован пакет документов',
  COMMENT: 'Комменатрий',
};

class PackagesCorrection extends Component {
  static propTypes = { companyService: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    const {
      documentsCorrection,
      dates: {
        startDate,
        endDate,
      },
      loadingButton,
      maxDate,
    } = props.companyService.get();

    this.state = {
      documentsCorrection,
      loadingButton,
      dates: {
        startDate,
        endDate,
      },
      maxDate,
    };
  }

  componentDidMount() {
    const { companyService } = this.props;
    const { startDate, endDate } = this.state.dates;

    this.unsubscribe = companyService.subscribe(this.updateState);

    companyService.loadPackageDocumentsCorrection(startDate, endDate);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = (state) => this.setState({ ...state });

  getPackageDocuments = () => {
    const { startDate, endDate } = this.state.dates;

    this.setState({ loadingButton: true });

    this.props.companyService.loadPackageDocumentsCorrection(startDate, endDate);
  };

  handleChangeDate = (field, value) => this.props.companyService.changeDate(field, value);

  handleOpenNewWidow = (AccountId, CompanyId) => {
    const generatePath = `${window.location.origin}/account/${AccountId}/company/${CompanyId}/account/documents`;

    window.open(generatePath, '_blank');
  };

  renderCompanies = () => {
    const { documentsCorrection } = this.state;

    const commentDocument = (comment) => comment || TEXT.NO_COMMENT;

    return documentsCorrection.map(({
      Id,
      CompanyName,
      Number,
      AccountId,
      CompanyId,
      CreatedDate,
      PeriodStart,
      PeriodEnd,
      PackageDetails: { Comment },
    }) => (
      <div className={ `${styles.row} ${styles['company-item']}` } key={ Id }>
        <div
          className={ `${styles.title} ${styles.col} ${styles['col-1-7']} ${styles['company-name']}` }
          onClick={ () => this.handleOpenNewWidow(AccountId, CompanyId) }
        >
          { CompanyName }
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']}` }>
          { Number }
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']}` }>
          { formatDate(CreatedDate) }
        </div>
        <div className={ `${styles.col} ${styles['col-1-7']}` }>
          { formatDate(PeriodStart) }
          { ' ' }
          -
          { formatDate(PeriodEnd) }
        </div>
        <div className={ `${styles.col} ${styles['col-3-7']}` }>
          { commentDocument(Comment) }
        </div>
      </div>
    ));
  };

  renderCompanyList = () => {
    const { documentsCorrection } = this.state;

    const documentList = documentsCorrection.length < 1
      ? TEXT.NO_PACKAGE
      : this.renderCompanies();

    return (
      <div ref={ (elem) => { this.tableHeader = elem; } } className={ styles.wrap }>
        <div className={ `${styles.row} ${styles['row-title']}` }>
          <div className={ `${styles.col} ${styles['col-1-7']}` }>
            { HEADER.COMPANY_NAME }
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']}` }>
            { HEADER.PACKAGE_NUMBER }
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']}` }>
            { HEADER.DATE }
          </div>
          <div className={ `${styles.col} ${styles['col-1-7']}` }>
            { HEADER.PERIOD }
          </div>
          <div className={ `${styles.col} ${styles['col-3-7']}` }>
            { HEADER.COMMENT }
          </div>
        </div>
        { documentList }
      </div>
    );
  };

  render() {
    const {
      dates: { startDate, endDate }, maxDate, loadingButton,
    } = this.state;

    return (
      <div className={ styles.main }>
        <div className={ styles['render-panel'] }>
          <RangeDatePicker
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDate }
            onChange={ this.handleChangeDate }
            label={ TEXT.DATE }
          />
          <div className={ styles['main-button'] }>
            <AjaxButton
              label={ TEXT.SHOW }
              loading={ loadingButton }
              onClick={ this.getPackageDocuments }
            />
          </div>
        </div>
        { this.renderCompanyList() }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <PackagesCorrection
      appService={ opts.appService }
      goBack={ opts.goBack }
      companyService={ opts.companyService }
    />,
  );

  return root;
};

export default renderComponents;
