import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import moment from 'moment';
import { Dialog } from 'sw-ui';

import AjaxButton from '../../../../components/ajaxButton';

import { DEFAULTDATE, DEFAULTTIME } from '../../../../constants/time';

import styles from '../../styles/company.module.scss';

const LABEL = 'Сохранить';
const LOCALE = 'ru';
const WIDTH = '400px';

class IntegrationForm extends Component {
  static propTypes = {
    date: PropTypes.string.isRequired,
    onSaveIntegration: PropTypes.func.isRequired,
    toggleIntegrationDialog: PropTypes.func.isRequired,
  };

  state = {
    currentDate: moment(this.props.date),
    loading: false,
  };

  handleChangeInput = (value) => this.setState({ currentDate: value });

  handleBlurInput = (value) => this.setState({ currentDate: value });

  handleCloseDialogAfterSave = () => {
    this.toggleLoading();
    this.props.toggleIntegrationDialog();
  };

  handleSaveIntegration = (value) => {
    this.toggleLoading();
    this.props.onSaveIntegration(value)
      .then(this.handleCloseDialogAfterSave)
      .catch(this.handleCloseDialogAfterSave);
  };

  toggleLoading = () => this.setState({ loading: !this.state.loading });

  render() {
    const { currentDate } = this.state;
    const { toggleIntegrationDialog } = this.props;

    return (
      <Dialog onClick={ toggleIntegrationDialog } width={ WIDTH }>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <div className={ `${styles.form} ${styles.dialog}` }>
            <div className={ `${styles.row} ${styles.title}` }>
              Изменение даты и времени подключения интеграции с 1С:БП
            </div>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ DEFAULTTIME }
              locale={ LOCALE }
              value={ currentDate }
              onChange={ this.handleChangeInput }
              onBlur={ this.handleBlurInput }
            />
            <div className={ `${styles.row} ${styles.action}` }>
              <AjaxButton label={ LABEL } onClick={ () => this.handleSaveIntegration(currentDate) } />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default IntegrationForm;
