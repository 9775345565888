import React from 'react';
import PropTypes from 'prop-types';
import { Button, COMPONENTS } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../styles/dialog.module.css';

const LABELS = {
  OK: 'Готово',
  CANCEL: 'Отменить',
  APROVE: 'Загрузить',
  DOWNLOAD: 'Скачать',
  SUCCESSFUL_RESULT_TEXT: 'Загрузка сотрудников завершена',
  INFORMATION_TITLE: 'Предварительная обработка файла',
  PROCESSED_FILE: 'Результат обработки доступен по кнопке',
  RESULT_FILE: 'Результат загрузки доступен по кнопке',
  ALL_EMPLOYEES_COUNT: (count) => `Всего сотрудников к загрузке: ${count}`,
  NEW_EMPLOYEES_COUNT: (count) => `Новых сотрудников: ${count}`,
  UPDATE_EMPLOYEES_COUNT: (count) => `Обновляемых сотрудников: ${count}`,
  ALL_EMPLOYEES_COUNT_UPLOAD: (count) => `Всего сотрудников к загрузке: ${count}`,
  NEW_EMPLOYEES_COUNT_UPLOAD: (count) => `Загружено новых сотрудников: ${count}`,
  UPDATE_EMPLOYEES_COUNT_UPLOAD: (count) => `Обновлено сотрудников: ${count}`,
  UNCHANGEBLE_EMPLOYEES_COUNT_UPLOAD: (count) => `Сотрудников без изменений: ${count}`,
  ERROR_EMPLOYEES_COUNT_UPLOAD: (count) => `Сотрудников с некорректными данными: ${count}`,
  ERROR_LOAD: 'Ошибка загрузки:',
};

const ResultSection = ({
  loadDocument,
  secondPhase,
  information,
  formationResultProps,
  disableButtons,
  messageHtml,
  onDownloadEmployeesFile,
  onCloseFile,
}) => {
  const {
    fileLink,
    errors,
  } = information;

  const {
    titleFromPhase,
    messageFromPhase,
    aproveFuncFromPhase,
    messageOfNewEployees,
    aproveButtonFromPhase,
    errorMessage,
  } = formationResultProps();

  const showCancelButton = secondPhase || loadDocument;
  const resultErrorMessage = secondPhase && errors && messageHtml(errors);

  const renderErrorLoad = !!errorMessage && (
    <span
      className={ styles.error_message }
    >
      { LABELS.ERROR_LOAD }
      { ' ' }
      { errorMessage }
    </span>
  );

  return (
    <div className={ styles.empolyees_information }>
      <h3 className={ styles.title }>
        { titleFromPhase }
      </h3>
      { messageOfNewEployees }
      { resultErrorMessage }
      <span className={ styles.message }>
        { messageFromPhase }
        <b>
          { ' ' }
          { LABELS.DOWNLOAD }
          { ' ' }
        </b>
      </span>
      { renderErrorLoad }
      <div className={ styles.buttons }>
        <Button
          onClick={ () => onDownloadEmployeesFile(fileLink) }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          className={ styles.pattern_button }
        >
          { LABELS.DOWNLOAD }
        </Button>
        <DecisionButtons
          disabled={ disableButtons }
          showCancel={ showCancelButton }
          onCancel={ onCloseFile }
          onSave={ aproveFuncFromPhase }
          labelCancel={ LABELS.CANCEL }
          labelSave={ aproveButtonFromPhase }
        />
      </div>
    </div>
  );
};

ResultSection.propTypes = {
  loadDocument: PropTypes.bool.isRequired,
  secondPhase: PropTypes.bool.isRequired,
  information: PropTypes.object.isRequired,
  disableButtons: PropTypes.bool.isRequired,
  messageHtml: PropTypes.func.isRequired,
  onDownloadEmployeesFile: PropTypes.func.isRequired,
  onCloseFile: PropTypes.func.isRequired,
  formationResultProps: PropTypes.func.isRequired,
};


export default ResultSection;
