import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { getMoment, isAfterDate } from '../../../../../bi/utils/formatDate';

import AjaxButton from '../../../../../components/ajaxButton';
import RangeDatePicker from '../../../../../components/RangeDatePicker';

import styles from '../../../styles/company.module.scss';

const LABELS = {
  EXPENSE_REPORTS: 'Изменение периода выгрузки авансовых отчетов',
  CHANGE: 'Изменить',
};

const WIDTH = '400px';

export default class ExpenseReportsPeriodDialog extends Component {
  static propTypes = {
    toggleUnloadingExpenseReportsDialog: PropTypes.func.isRequired,
    onChangeExpenseReportsPeriod: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      startDate: getMoment(),
      endDate: getMoment(),
      loading: false,
    };
  }

  handleChangeDate = (field, value) => {
    const { endDate } = this.state;

    if (isAfterDate(value, endDate)) {
      this.setState({ endDate: value });
    }

    return this.setState({ [field]: value });
  };

  setLoading = (value) => this.setState({ loading: value });

  handleSavePeriod = () => {
    const { onChangeExpenseReportsPeriod, toggleUnloadingExpenseReportsDialog } = this.props;
    const { startDate, endDate } = this.state;

    this.setLoading(true);
    onChangeExpenseReportsPeriod(startDate, endDate)
      .then(() => {
        this.setLoading(false);
        toggleUnloadingExpenseReportsDialog();
      });
  };

  render() {
    const { toggleUnloadingExpenseReportsDialog } = this.props;
    const {
      startDate, endDate, loading,
    } = this.state;

    return (
      <Dialog onClick={ toggleUnloadingExpenseReportsDialog } width={ WIDTH }>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          <div className={ `${styles.form} ${styles.dialog}` }>
            <div className={ `${styles.row} ${styles.title}` }>{ LABELS.EXPENSE_REPORTS }</div>
            <div className={ styles['expenses-date'] }>
              <RangeDatePicker
                startDate={ startDate }
                endDate={ endDate }
                onChange={ this.handleChangeDate }
              />
            </div>
            <div className={ `${styles.row} ${styles.action}` }>
              <AjaxButton
                label={ LABELS.CHANGE }
                onClick={ this.handleSavePeriod }
                loading={ loading }
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

ExpenseReportsPeriodDialog.propTypes = {
  toggleUnloadingExpenseReportsDialog: PropTypes.func.isRequired,
  onChangeExpenseReportsPeriod: PropTypes.func.isRequired,
};
