export const updatedUsers = (users, userId) => users.map((item) => {
  if (item.Id !== userId) {
    return item;
  }

  return {
    ...item,
    Notification: {
      ...item.Notification,
      LowBalance: !item.Notification.LowBalance,
    },
  };
});
