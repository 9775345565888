import { requiredField, requiredNumber } from '../../../../bi/utils/checkValidation';
import { INVALID_CONTRACT } from '../../../constants/profile';

const validationObjHotelContract = {
  HotelName: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
  HotelId: {
    fn: requiredField,
    msg: INVALID_CONTRACT.BOOKING,
  },
  Commission: {
    fn: requiredNumber,
    msg: INVALID_CONTRACT.NUMBER,
  },
  Start: {
    fn: requiredField,
    msg: INVALID_CONTRACT.EMPTY_FOR_HOTEL,
  },
};

export { validationObjHotelContract };
