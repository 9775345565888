import Store from '../../store/store';

let ACTIONS;

const DEFAULT = {
  userName: '',
  user: {},
  enums: null,
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.STOREINIT:

      state = {};
      break;

    case ACTIONS.WORKSPACE.INIT:

      state.user = action.user;
      break;

    case ACTIONS.WORKSPACE.GETUSERNAME: {
      return {
        ...state,
        userName: action.payload,
      };
    }

    case ACTIONS.WORKSPACE.GET_ENUMS: {
      return {
        ...state,
        enums: action.payload.enums,
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('Workspace', reducer, DEFAULT, {});

  const createStore = () => new Store('Workspace', reducer, DEFAULT);

  return createStore();
}
