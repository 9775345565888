import React from 'react';
import { createRoot } from 'react-dom/client';

import SearchHotels from './pages/SearchHotels';
import AddHotel from './pages/AddHotel';
import Hotel from './pages/Hotel';
import { MatchHotels } from './pages/MatchHotels';

const PAGE_NAME = {
  searchHotels: SearchHotels,
  addHotel: AddHotel,
  hotel: Hotel,
  matchHotels: MatchHotels,
};

const renderComponents = (box, opts) => {
  const {
    hotelsPageId,
    hotelsService,
    hotelId,
    workplaceService,
    moveToAddHotel,
  } = opts;

  const Page = PAGE_NAME[hotelsPageId];
  const root = createRoot(box);

  root.render(
    <Page
      hotelsService={ hotelsService }
      workplaceService={ workplaceService }
      hotelId={ hotelId }
      moveToAddHotel={ moveToAddHotel }
    />,
  );

  return root;
};

export default renderComponents;
