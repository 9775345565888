import React from 'react';
import useUserProfilePage from './hooks/useUserProfile';

import General from './General';
import Documents from './Documents';
import BonusCards from './BonusCards';
import CircularLoaders from '../../../../components/loaders';

import styles from './styles.module.css';

const UserProfile = () => {
  // TODO: add an actual accountId, use error
  const {
    data, enums, loading,
  } = useUserProfilePage(0);

  if (loading) {
    return (
      <div>
        <CircularLoaders />
      </div>
    );
  }

  // if (error) {
  //   return (
  //     <div>
  //       <h1>Ошиб очка</h1>
  //     </div>
  //   );
  // }

  return (
    <div className={ styles.container }>
      <General profile={ data } citizenshipEnums={ enums.citizenship } />
      <Documents documents={ data.documents } />
      <BonusCards bonuses={ data.bonuses } bonusesEnum={ enums.bonus } />
    </div>
  );
};

export default UserProfile;
