import Api from '../../api';
import { SERVICETYPE } from '../../constants/serviceType';
import { TRAVEL_POLICY_CURRENCIES } from '../../constants/travelPolicy';

import { buildTPMessages } from '../../utils/travelPolicy';

const TP_NAMES = [
  { code: 'airline', name: 'Авиабилеты' },
  { code: 'train', name: 'Ж/д Билеты' },
  { code: 'hotel', name: 'Гостиницы' },
  { code: 'taxi', name: 'Такси' },
  { code: 'transfer', name: 'Трансфер' },
  { code: 'vipHall', name: 'Vip-залы' },
];

class TravelPolicyService {
  getServiceTypeConst = () => SERVICETYPE;

  getEmployeeTP = (employeeId) => {
    if (!employeeId) return null;

    return Api.TravelPolicy.getEmployeeTP(employeeId);
  };

  getTravelPolicies = async () => {
    try {
      const travelPolicies = await Api.TravelPolicy.getTravelPolicies();

      return travelPolicies;
    } catch (e) {
      return null;
    }
  };

  getTPDetails = (policy, type, rate = null) => {
    const details = {
      Name: policy.Name,
      Description: policy.Description,
      Messages: [],
    };

    const newPolicy = this.expandingMaxPriceModel(policy, type, rate);

    switch (type) {
      case SERVICETYPE.AIR: {
        details.Messages = buildTPMessages(newPolicy, SERVICETYPE.AIR);
        break;
      }
      case SERVICETYPE.HOTEL: {
        details.Messages = buildTPMessages(newPolicy, SERVICETYPE.HOTEL);
        break;
      }
      case SERVICETYPE.TRAIN: {
        details.Messages = buildTPMessages(newPolicy, SERVICETYPE.TRAIN);
        break;
      }
      case SERVICETYPE.TRANSFER: {
        details.Messages = buildTPMessages(newPolicy, SERVICETYPE.TRANSFER);
        break;
      }
      case SERVICETYPE.TAXI: {
        details.Messages = buildTPMessages(newPolicy, SERVICETYPE.TAXI);
        break;
      }
      case SERVICETYPE.VIP_HALL: {
        details.Messages = buildTPMessages(newPolicy, SERVICETYPE.VIP_HALL);
      }
    }

    return details.Messages.isEmpty ? null : details;
  };

  getEveryoneTPDetails = (policy) => {
    const details = {
      Name: policy.Name,
      Description: policy.Description,
      Messages: [],
    };
    const buildesTpRules = {
      airline: buildTPMessages(policy, SERVICETYPE.AIR),
      train: buildTPMessages(policy, SERVICETYPE.TRAIN),
      hotel: buildTPMessages(policy, SERVICETYPE.HOTEL),
      taxi: buildTPMessages(policy, SERVICETYPE.TAXI),
      transfer: buildTPMessages(policy, SERVICETYPE.TRANSFER),
      vipHall: buildTPMessages(policy, SERVICETYPE.VIP_HALL),
    };
    details.Messages = TP_NAMES.map(({ code, name }) => {
      const {
        rules, exception, isEmpty,
      } = buildesTpRules[code];

      return {
        name, rules, exception, isEmpty,
      };
    });
    const isAllEmtpty = details.Messages.every(({ isEmpty }) => isEmpty);

    return isAllEmtpty ? null : details;
  };

  expandingExceptionsModel = (exceptions, rate = null) => exceptions.map((exception) => ({
    ...exception,
    MaxPrice: {
      ...exception.MaxPrice,
      ValueCurrency: exception.MaxPrice.IsEnabled ? this.getCurrencyConversionForType(exception, rate) : null,
    },
  }));

  expandingMaxPriceModel = (policy, type, rate = null) => {
    switch (type) {
      case SERVICETYPE.AIR: {
        return {
          ...policy,
          AirlineRule: {
            ...policy.AirlineRule,
            MaxPrice: {
              ...policy.AirlineRule.MaxPrice,
              ValueCurrency: this.getCurrencyConversionForType(policy.AirlineRule, rate),
            },
            Exceptions: this.expandingExceptionsModel(policy.AirlineRule.Exceptions, rate),
          },
        };
      }
      case SERVICETYPE.HOTEL: {
        return {
          ...policy,
          HotelRule: {
            ...policy.HotelRule,
            MaxPrice: {
              ...policy.HotelRule.MaxPrice,
              ValueCurrency: this.getCurrencyConversionForType(policy.HotelRule, rate),
            },
            Exceptions: this.expandingExceptionsModel(policy.HotelRule.Exceptions, rate),
          },
        };
      }
      case SERVICETYPE.TRAIN: {
        return {
          ...policy,
          TrainRule: {
            ...policy.TrainRule,
            MaxPrice: {
              ...policy.TrainRule.MaxPrice,
              ValueCurrency: this.getCurrencyConversionForType(policy.TrainRule, rate),
            },
            Exceptions: this.expandingExceptionsModel(policy.TrainRule.Exceptions, rate),
          },
        };
      }
    }

    return policy;
  };

  getRateForType = ({ MaxPrice: { Currency } }) => this.getRateFromTo(Currency, TRAVEL_POLICY_CURRENCIES.RUB);

  getRateFromTo = (from, to) => {
    try {
      const data = {
        Value: 1,
        From: from,
        To: to,
      };

      return Api.Currency.getCurrencyConversionFromTo(data);
    } catch (e) {
      return null;
    }
  };

  getCurrencyConversionForType = ({ MaxPrice: { Value } }, rate) => {
    if (!Value) {
      return null;
    }

    return Number((Value * rate).toFixed(2));
  };
}

export default TravelPolicyService;
