import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AEROEXPRESS_TARIFF_DESCRIPTIONS, AEROEXPRESS_TARIFF_MAP } from '../../../../bi/constants/aeroexpress';
import { DATEWITHWEEKDAY } from '../../../../constants/time';
import { formatDate } from '../../../../utils/date';

import styles from './styles.module.css';

class AeroexpressItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    fromTrip: PropTypes.bool,
  };

  static defaultProps = { fromTrip: false };

  prepareTitle = (tariff) => `Аэроэкспресс (${AEROEXPRESS_TARIFF_MAP[tariff]})`;

  renderTripView = () => {
    const {
      item: {
        Trip: {
          tariff_type, departure_date, backward_date: backDate,
        },
      },
    } = this.props;

    const title = this.prepareTitle(tariff_type);
    const departureDateContent = <span>{ formatDate(departure_date, DATEWITHWEEKDAY) }</span>;
    const backwardDateContent = backDate
      ? (
        <span>
          { ' ' }
          -
          { formatDate(backDate, DATEWITHWEEKDAY) }
        </span>
      )
      : null;
    const descriptionContent = this.renderDescription(tariff_type, departure_date);

    return (
      <div className={ styles['from-trip-wrapper'] }>
        <div className={ `${styles.icon} smartway-aeroexpress-temp-big` } />
        <div className={ styles['title-wrapper'] }>
          <h3>{ title }</h3>
          <div className={ styles['dates-wrapper'] }>
            { departureDateContent }
            { ' ' }
            { backwardDateContent }
          </div>
        </div>
        <div className={ styles['description-wrapper'] }>
          { descriptionContent }
        </div>
      </div>
    );
  };

  renderDescription = (tariff, date) => AEROEXPRESS_TARIFF_DESCRIPTIONS[tariff](date)
    .split('\n')
    .map((v, i) => <p key={ i }>{ v }</p>);

  render() {
    const {
      item: {
        Trip: {
          tariff_type, departure_date, backward_date: backwardDate,
        },
      }, fromTrip,
    } = this.props;

    if (fromTrip) {
      return this.renderTripView();
    }

    const title = this.prepareTitle(tariff_type);
    const descriptionContent = this.renderDescription(tariff_type, departure_date);
    const departureDateContent = <h3>{ formatDate(departure_date) }</h3>;
    const backDateContent = backwardDate ? <h3>{ formatDate(backwardDate) }</h3> : null;

    return (
      <div className={ styles.wrapper }>
        <h3>{ title }</h3>
        <div className={ styles.dates }>
          { departureDateContent }
          { backDateContent }
        </div>
        <div className={ styles.description }>
          { descriptionContent }
        </div>
      </div>
    );
  }
}

export default AeroexpressItem;
