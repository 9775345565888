import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input/index';
import DecisionButtons from '../../../../components/DecisionButtons';
import { MODELS } from '../../../../bi/constants/trips';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import styles from '../../styles/company.module.scss';

class EditTransactionForm extends Component {
  static propTypes = {
    priceDetails: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = { priceDetails: { ...MODELS.PriceDetails } };

  constructor(props) {
    super(props);

    this.state = {
      ...props.priceDetails,
      waitingResponse: false,
    };
  }

  handleChangeField = (e, field, value) => this.setState({ [field]: value });
  handleChangeHasVAT = () => this.setState({ hasVAT: !this.state.hasVAT });

  handleSave = () => this.setState({ waitingResponse: true }, () => this.props.onSave(this.state));

  render() {
    const { onClose } = this.props;
    const {
      OperationDetailsId,
      Base,
      Tax,
      Fee,
      VAT,
      hasVAT,
      Commission,
      Taxes,
      waitingResponse,
    } = this.state;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          Редактирование транзакции #
          { OperationDetailsId }
        </div>
        <div className={ styles.row }>
          <Input
            field='Base'
            label='Base'
            type='number'
            value={ Base }
            onChange={ this.handleChangeField }
          />
          <Input
            field='Tax'
            label='Tax'
            type='number'
            value={ Tax }
            onChange={ this.handleChangeField }
          />
          <Input
            field='Fee'
            label='Fee'
            type='number'
            value={ Fee }
            onChange={ this.handleChangeField }
          />
          <Input
            field='VAT'
            label='VAT'
            type='number'
            value={ VAT }
            onChange={ this.handleChangeField }
          />
          <div className={ styles.row }>
            <label>
              <input
                type='checkbox'
                checked={ hasVAT }
                onChange={ this.handleChangeHasVAT }
              />
              { ' ' }
              HasVAT
            </label>
          </div>
          <Input
            field='Commission'
            label='Commission'
            type='number'
            value={ Commission }
            onChange={ this.handleChangeField }
          />
          <Input
            field='Taxes'
            label='Taxes'
            type='number'
            value={ Taxes }
            onChange={ this.handleChangeField }
          />
        </div>
        <div className={ `${styles.row} ${styles.action}` }>
          <DecisionButtons
            waitingResponse={ waitingResponse }
            onSave={ this.handleSave }
            onCancel={ onClose }
            labelSave={ DECISIONBUTTONS.LABELS.SAVE }
          />
        </div>
      </div>
    );
  }
}

export default EditTransactionForm;
