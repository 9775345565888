import React from 'react';
import PropTypes from 'prop-types';

import LinkToNewTab from '../../../../components/LinkToNewTab';
import {
  calculatingBlockHeight, sortListSurnameABC, getShortName,
} from '../../../../bi/utils/employee';

import styles from './styles/index.module.css';

const EmployeesItem = ({ list, labelStaff }) => {
  const renderList = ({
    Id, Surname, Name, Patronymic,
  }) => (
    <div className={ styles.wrapper_employee } key={ Id }>
      <LinkToNewTab to={ `/employeePage/${Id}` }>
        <span className={ styles.employee }>
          { getShortName({
            Surname, Name, Patronymic,
          }) }
        </span>
      </LinkToNewTab>
    </div>
  );

  if (list.length) {
    const employeesSorted = sortListSurnameABC(list);
    const heigthCol = calculatingBlockHeight(list.length);

    return (
      <div>
        <p className={ styles.header }>
          { labelStaff }
          &ensp;
          { list.length }
        </p>
        <div className={ styles.wrapper_block_employees } style={ { height: `${heigthCol}px` } }>
          { employeesSorted.map(renderList) }
        </div>
      </div>
    );
  }

  return null;
};

EmployeesItem.propTypes = {
  list: PropTypes.array.isRequired,
  labelStaff: PropTypes.string.isRequired,
};

export default EmployeesItem;
