const useDisplayingBonusCardsData = (bonuses, bonusesEnum) => (
  bonuses.map(({
    type, code, number,
  }) => ({
    name: `${bonusesEnum[type].find(({ Code }) => Code === code).Name} (${code})`,
    number,
  }))
);

export default useDisplayingBonusCardsData;
