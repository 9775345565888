import { Moment } from 'moment';
import { isMoment } from './date';
import { formatDate } from '../bi/utils/formatDate';
import { DATE } from '../constants/time';

interface IRequestFilter {
  field: string;
  value: string | number | boolean | Moment;
}

export const mapFilters = (filters?: IRequestFilter[]) => {
  if (!filters || !filters.length) return;

  const filtered = filters.filter(({ value }) => {
    const isNaN = typeof value === 'number' ? Number.isNaN(value) : false;

    return value !== undefined && value !== null && !isNaN;
  });

  const mapped = filtered.map(({ field, value }) => {
    if (isMoment(value)) {
      const date = formatDate(value, DATE);

      return `${field}=${encodeURIComponent(date)}`;
    }

    return `${field}=${value}`;
  });

  return '?' + mapped.join('&');
};
