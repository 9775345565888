import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, Loading, Select, RadioButton,
} from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import {
  RATETYPE,
  AIRLINEORDERRATE,
  AIRLINECHANGERATE,
  AIRLINE_CHANGE_RATE_FOR_SMARTAGENT,
  AIRLINE_ORDER_RATE_FOR_SMARTAGENT,
} from '../../../../../constants/rateTypes';
import COMPONENTS from '../../../../../bi/constants/components';

import styles from '../styles/dialog.module.css';

const WIDTH = '400px';

const LABELS = {
  YES: 'Да',
  NO: 'Нет',
  SUMMATION_FEES: 'Сложение «Сбор Смартвэй» и «Добор поставщика»',
};

class AirlineRateDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    accountService: PropTypes.object.isRequired,
    currentRate: PropTypes.object.isRequired,
    isSmartAgent: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super();

    this.state = {
      rates: {
        Order: [],
        Change: [],
      },
      rate: {
        Order: props.currentRate.Order,
        Change: props.currentRate.Change,
        SUDiscount: props.currentRate.SUDiscount,
        SummationFees: props.currentRate.SummationFees,
      },

      loading: true,
    };
  }

  componentDidMount() {
    this.props.accountService.getRates(RATETYPE.AIRLINE)
      .then((res) => this.setState({ loading: false, rates: res }));
  }

  handleChangeOrderRate = ({ value }) => this.setState({
    rate: {
      ...this.state.rate,
      Order: value,
    },
  });

  handleChangeChangeRate = ({ value }) => this.setState({
    rate: {
      ...this.state.rate,
      Change: value,
    },
  });

  handleSwitchRadioSumFees = (value) => this.setState({
    rate: {
      ...this.state.rate,
      SummationFees: value,
    },
  });

  handleSave = () => {
    const { accountService } = this.props;
    const { rate } = this.state;

    return accountService.updateRates(RATETYPE.AIRLINE, rate);
  };

  getDefaultItem = (items, rate) => (items.some(({ value }) => value === rate) ? rate : items[0].value);

  renderSelectOrderRate = () => {
    const { rates, rate } = this.state;
    const { isSmartAgent } = this.props;

    const items = rates.Order.reduce((acc, item) => {
      if (!isSmartAgent && AIRLINEORDERRATE[item]) {
        return [
          ...acc,
          {
            value: item,
            label: AIRLINEORDERRATE[item],
          },
        ];
      }

      if (isSmartAgent && AIRLINE_ORDER_RATE_FOR_SMARTAGENT[item]) {
        return [
          ...acc,
          {
            value: item,
            label: AIRLINE_ORDER_RATE_FOR_SMARTAGENT[item],
          },
        ];
      }

      return acc;
    }, []);

    return (
      <div>
        <h4>Выписка:</h4>
        <Select
          items={ items }
          value={ this.getDefaultItem(items, rate.Order) }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleChangeOrderRate }
        />
      </div>
    );
  };

  renderSelectChangeRate = () => {
    const { rates, rate } = this.state;
    const { isSmartAgent } = this.props;

    const items = rates.Change.reduce((acc, item) => {
      if (!isSmartAgent && AIRLINECHANGERATE[item]) {
        return [
          ...acc,
          {
            value: item,
            label: AIRLINECHANGERATE[item],
          },
        ];
      }

      if (isSmartAgent && AIRLINE_CHANGE_RATE_FOR_SMARTAGENT[item]) {
        return [
          ...acc,
          {
            value: item,
            label: AIRLINE_CHANGE_RATE_FOR_SMARTAGENT[item],
          },
        ];
      }

      return acc;
    }, []);

    return (
      <div>
        <h4>Изменение:</h4>
        <Select
          items={ items }
          value={ this.getDefaultItem(items, rate.Change) }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleChangeChangeRate }
        />
      </div>
    );
  };

  renderSelectSumFees = () => {
    const { rate: { SummationFees } } = this.state;

    return (
      <div>
        <h4>{ LABELS.SUMMATION_FEES }</h4>
        <div className={ styles.radios }>
          <div className={ styles.radio }>
            <RadioButton
              value
              checked={ SummationFees === true }
              onChange={ () => this.handleSwitchRadioSumFees(true) }
            >
              <div>{ LABELS.YES }</div>
            </RadioButton>
          </div>
          <div className={ styles.radio }>
            <RadioButton
              value={ false }
              checked={ SummationFees === false }
              onChange={ () => this.handleSwitchRadioSumFees(false) }
            >
              <div>{ LABELS.NO }</div>
            </RadioButton>
          </div>
        </div>
      </div>
    );
  };

  renderLoading = () => (
    <Dialog onClick={ this.props.onClose } width={ WIDTH }>
      <div className={ styles.loading }>
        <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
      </div>
    </Dialog>
  );

  render() {
    const { onClose } = this.props;
    const { loading } = this.state;

    return loading
      ? this.renderLoading()
      : (
        <Dialog onClick={ onClose } width={ WIDTH } >
          <div className={ styles.dialog }>
            <h3>Изменение тарифа для а/б</h3>
            { this.renderSelectOrderRate() }
            { this.renderSelectChangeRate() }
            { this.renderSelectSumFees() }
            <DecisionButtons
              loadingIncluded
              onCancel={ onClose }
              onSave={ this.handleSave }
            />
          </div>
        </Dialog>
      );
  }
}

export default AirlineRateDialog;
