import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../components/button';
import CircularLoaders from '../../../components/loaders';

import styles from '../styles/approve-users.module.scss';

const CLASSNAME = {
  Created: 'created',
  Pending: 'pending',
  Approved: 'approved',
  Rejected: 'rejected',
};

class UserItem extends Component {
  constructor() {
    super();
    this.state = {
      loadingApprove: false,
      loadingReject: false,
    };

    this.renderRejectUserButton = this.renderRejectUserButton.bind(this);
  }

  renderApproveUserButton(item) {
    this.props.approveUser(item);

    this.setState({ loadingApprove: true });
  }

  renderRejectUserButton(item) {
    this.props.rejectUser(item);

    this.setState({ loadingReject: true });
  }

  render() {
    const { item } = this.props;

    const { loadingReject, loadingApprove } = this.state;

    const cn = CLASSNAME[item.RegistrationStatusStr];

    const allowApprove = [
      'Created',
      'Pending',
      'Rejected',
    ].includes(item.RegistrationStatusStr);
    const allowReject = ['Created', 'Pending'].includes(item.RegistrationStatusStr);

    let labelApprove = 'Подтвердить';
    if (loadingApprove) {
      labelApprove = <CircularLoaders wrapWidth={ 24 } />;
    }

    let labelReject = 'Отклонить';
    if (loadingReject) {
      labelReject = <CircularLoaders wrapWidth={ 24 } />;
    }

    return (
      <div className={ `${styles.content} ${styles[cn]}` }>
        <div className={ styles.title }>
          <div>
            { item.RegistrationName }
          </div>
          <div id={ `user-email-${item.Id}` }>
            { item.Email }
          </div>
          <div>
            { item.PhoneNumber }
          </div>
        </div>
        <div className={ styles.region }>
          { item.Region }
        </div>
        <div className={ styles['time-zone'] }>
          { item.TimeZoneId }
        </div>
        <div className={ styles.action }>
          { allowApprove
            ? (
              <div id={ `confirm-user-${item.Id}` }>
                <FlatButton onClick={ () => this.renderApproveUserButton(item) }>
                  { labelApprove }
                </FlatButton>
              </div>
            )
            : null }
          { allowReject
            ? (
              <FlatButton onClick={ () => this.renderRejectUserButton(item) }>
                { labelReject }
              </FlatButton>
            )
            : null }
        </div>
      </div>
    );
  }
}

UserItem.propTypes = {
  item: PropTypes.object.isRequired,
  approveUser: PropTypes.func.isRequired,
  rejectUser: PropTypes.func.isRequired,
};

export default UserItem;
