import numeral from 'numeral';

numeral.language('ru', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: { symbol: ' ₽' },
});
numeral.language('ru');

class MoneyFormat {
  static money = (value, withSymbol = false) => {
    let pattern = '0,0';

    if (withSymbol) pattern = '0,0$';

    return numeral(value).format(pattern);
  };

  static moneyWithDecimal = (value, withSymbol = false) => {
    let pattern = '0,0[.]00';

    if (withSymbol) pattern = '0,0[.]00$';

    return numeral(value).format(pattern);
  };

  static symbolWithMoney = (value) => `₽ ${MoneyFormat.money(value)}`;

  static symbolWithMoneyWithDecimal = (value) => `₽ ${MoneyFormat.moneyWithDecimal(value)}`;

  static symbolWithPrice = (value) => (numeral(value).format('0.00')).replace(',', '.');

  static delimitedTextWithPrice = (value, delimiter = ',') => `${value}${delimiter} ₽`;
}

export default MoneyFormat;
