import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { ACCOUNTTEXT } from '../../bi/constants/account';
import COMPONENTS from '../../bi/constants/components';

import SalesReportDialog from './components/dialog';

import styles from './styles/index.module.css';

class SalesReport extends Component {
  static propTypes = { onDownload: PropTypes.func.isRequired };

  state = { dialog: false };

  toggleDialog = () => this.setState({ dialog: !this.state.dialog });

  renderDialog = () => (
    <SalesReportDialog
      onDownload={ this.props.onDownload }
      onClose={ this.toggleDialog }
    />
  );

  render() {
    const dialogHtml = this.state.dialog && this.renderDialog();

    return (
      <div className={ styles.wrap }>
        <Button
          label={ ACCOUNTTEXT.SALESREPORT }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.toggleDialog }
        />
        { dialogHtml }
      </div>
    );
  }
}

export default SalesReport;
