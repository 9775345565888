import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Input } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import styles from '../styles/dialog.module.css';

class CreatePackageDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onCreate: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    monthAmt: '',
    waitingResponse: false,

    validation: { monthAmt: '' },
  };

  validateMonthAmtInput = (monthAmt) => {
    const message = this.props.companyService.documentsValidation('MonthAmt', monthAmt);

    this.setState({ validation: { monthAmt: message } });

    return message === '';
  };

  handleChangeMonthAmt = (value) => this.setState({ monthAmt: value });

  handleConfirm = () => {
    const { monthAmt } = this.state;
    const valid = this.validateMonthAmtInput(monthAmt);

    if (valid) {
      // по просьбе менеджеров, если пустое поле, то преобразуем в 0
      const amt = Number(this.state.monthAmt);
      const create = () => this.props.onCreate(amt);

      this.setState({ waitingResponse: true }, create);
    }
  };

  render() {
    const {
      monthAmt, waitingResponse, validation,
    } = this.state;
    const { onClose } = this.props;

    return (
      <Dialog onClick={ onClose } width='400px'>
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            <Input
              placeholder='Введите количество будущих месяцев'
              value={ monthAmt }
              onChange={ this.handleChangeMonthAmt }
              onBlur={ () => this.validateMonthAmtInput(monthAmt) }
              error={ validation.monthAmt }
            />
          </div>
          <div className={ styles.actions }>
            <DecisionButtons
              waitingResponse={ waitingResponse }
              onSave={ this.handleConfirm }
              onCancel={ onClose }
              labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { CreatePackageDialog };
