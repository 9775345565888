import React from 'react';
import PropTypes from 'prop-types';

import SearchReport from '../SearchReport';
import { REPORTNAME, REPORTNAMELIST } from '../../bi/constants/reports';

const Mice = ({ downloadAlvistReport }) => (
  <SearchReport
    description={ REPORTNAME.ALVIST_REPORT }
    nameList={ REPORTNAMELIST.REPORTNAME }
    onDownload={ downloadAlvistReport }
    disabledButton
  />
);

Mice.propTypes = { downloadAlvistReport: PropTypes.func.isRequired };

export { Mice };
