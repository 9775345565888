import React from 'react';
import PropTypes from 'prop-types';

import {
  FIELDS, FLIGHT_TYPE, TRIP_TYPE, VIP_HALL_TYPES,
} from '../../../../../../../../bi/constants/trips';

import style from '../../../../../../styles/form.module.scss';

const LABELS = {
  FLIGHT_TYPE: 'Направление',
  TRIP_TYPE: 'Тип перелета',
};

const TypeFields = ({
  onChangeInput, value, type,
}) => {
  const selectOptionsHtml = (types) => Object.keys(types).map((item, id) => (
    <option key={ id } value={ types[item].value }>{ types[item].label }</option>
  ));

  const selectType = type === VIP_HALL_TYPES.FLIGHT_TYPE ? FLIGHT_TYPE : TRIP_TYPE;

  return (
    <div className={ style.select }>
      <div className={ style.wrap }>
        <label>{ LABELS[type] }</label>
        <select
          value={ value }
          onChange={ (e) => onChangeInput(e, FIELDS[type], e.target.value) }
        >
          { selectOptionsHtml(selectType) }
        </select>
      </div>
    </div>
  );
};

TypeFields.propTypes = {
  onChangeInput: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export { TypeFields };
