export const FROM_LAST_MONTH = 'sinceLastMonth';
export const ALL_TIME = 'allTime';
export const LIMIT_ON_PAGE = 10;

export const LABELS = {
  TRANSACTION_AUTHOR: 'Плательщик',
  TRANSACTION_AUTHOR_SURNAME: 'Фамилия плательщика',
  SHOW_OPERATIONS: 'Отображать операции',
  FROM_PREVIOUS_MONTH: 'c предыдущего месяца',
  ALL_TIME: 'за все время',
};

export const TransactionsStatusMap = {
  WaitingPayment: 'Ожидает оплаты',
  Complete: 'Завершена',
  Authorization: 'На авторизации',
  Suspended: 'Отменена',
};
