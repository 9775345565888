import React, { Component } from 'react';
import PropTypes from 'prop-types';

import LinkToNewTab from '../../../components/LinkToNewTab';
import CircularLoaders from '../../../components/loaders';

import { LABELS_FIELDS_FORM } from '../../../bi/constants/regions';

import styles from '../styles/search.module.scss';

const LABELS = {
  EMPTY_LIST: 'Не найдено ни одного региона!',
  REGIONS: 'Регионы',
};

class SearchRegions extends Component {
  static propTypes = { regionsService: PropTypes.object.isRequired };

  constructor(props) {
    super();
    const { regions, isRequest } = props.regionsService.get();
    this.state = {
      regions,
      isRequest,
    };
  }

  componentDidMount() {
    const { regionsService } = this.props;
    this.unsubscribeRegions = regionsService.subscribe(this.updateRegions);
  }

  componentWillUnmount() {
    if (this.unsubscribeRegions) {
      this.unsubscribeRegions();
    }
  }

  updateRegions = ({ regions, isRequest }) => {
    this.setState({
      regions,
      isRequest,
    });
  };

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderEmptyList = () => (
    <div className='empty-list'>
      { LABELS.EMPTY_LIST }
    </div>
  );

  renderList = (list) => {
    if (list.length === 0) {
      return null;
    }

    return (
      <div className={ styles.list }>
        <h2>{ LABELS.REGIONS }</h2>
        { list.map(({
          Id,
          CountryCode,
          Latitude,
          Longitude,
          Radius,
          NameRu,
          CountryNameRu,
          NameEn,
          CountryNameEn,
        }) => (
          <LinkToNewTab to={ `/regions/region/${Id}` } key={ Id }>
            <div className={ styles.item }>
              <div className={ styles.text_wrap }>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.NAME_RU }
                    :
                  </b>
                  { ` ${NameRu}` }
                </div>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.COUNTRY_NAME_RU }
                    :
                  </b>
                  { ` ${CountryNameRu}` }
                </div>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.NAME_EN }
                    :
                  </b>
                  { ` ${NameEn}` }
                </div>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.COUNTRY_NAME_EN }
                    :
                  </b>
                  { ` ${CountryNameEn}` }
                </div>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.COUNTRY_CODE }
                    :
                  </b>
                  { ` ${CountryCode}` }
                </div>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.RADIUS }
                    :
                  </b>
                  { ` ${Radius}` }
                </div>
                <div className={ styles.text_item }>
                  <b>
                    { LABELS_FIELDS_FORM.COORDINATES }
                    :
                  </b>
                  &nbsp;
                  { LABELS_FIELDS_FORM.LATITUDE }
                  :
                  { ' ' }
                  { ` ${Latitude}` }
                  &nbsp;
                  { LABELS_FIELDS_FORM.LONGITUDE }
                  :
                  { ' ' }
                  { ` ${Longitude}` }
                </div>
              </div>
            </div>
          </LinkToNewTab>
        )) }
      </div>
    );
  };

  render() {
    const { regions, isRequest } = this.state;

    if (!regions.length && !isRequest) {
      return this.renderEmptyList();
    }

    if (isRequest) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.wrap }>
        { this.renderList(regions) }
      </div>
    );
  }
}

export default SearchRegions;

