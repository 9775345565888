import { formatDate } from '../../utils/date';

const AEROEXPRESS_TARIFF_TYPES = {
  STANDARD: 'Standard',
  PRE_STANDARD: 'PreStandard',
  BUSINESS: 'Business',
  TWOWAY: 'TwoWay',
};

const AEROEXPRESS_TARIFF_MAP = {
  [AEROEXPRESS_TARIFF_TYPES.STANDARD]: 'Стандарт',
  [AEROEXPRESS_TARIFF_TYPES.PRE_STANDARD]: 'Стандарт Предварительный',
  [AEROEXPRESS_TARIFF_TYPES.BUSINESS]: 'Бизнес',
  [AEROEXPRESS_TARIFF_TYPES.TWOWAY]: 'Туда-Обратно',
};

const AEROEXPRESS_TARIFF_DESCRIPTIONS = {
  [AEROEXPRESS_TARIFF_TYPES.STANDARD]: () => 'Одна поездка в вагоне стандартного класса без гарантированного посадочного места по любому маршруту Аэроэкспресса. \nВ случае изменения планов вы можете воспользоваться билетом в течение 30 суток, начиная с выбранной даты.',
  [AEROEXPRESS_TARIFF_TYPES.PRE_STANDARD]: () => 'Одна поездка в вагоне стандартного класса без гарантированного посадочного места по любому маршруту Аэроэкспресса. Скидка 10% от тарифа Стандарт за покупку более чем за 3 дня до отправления. \nВ случае изменения планов вы можете воспользоваться билетом в течение 30 суток, начиная с выбранной даты.',
  [AEROEXPRESS_TARIFF_TYPES.BUSINESS]: () => '1 поездка в вагоне Бизнес класса Аэроэкспресс строго в соответствии с датой, временем и маршрутом, указанными в билете (выбранными при покупке). \nПосадочное место гарантировано. \nПриобретение билета возможно за 90 суток до предполагаемой даты поездки, включая день предполагаемой поездки. \nОднократное изменение реквизитов электронного билета возможно за 30 минут до отправления рейса.',
  [AEROEXPRESS_TARIFF_TYPES.TWOWAY]: (date) => `Поездка в аэропорт + поездка из аэропорта в вагоне стандартного класса без гарантированного посадочного места по любому маршруту Аэроэкспресса в течение 30 суток, начиная с ${formatDate(date, 'LL')}`,
};

const AEROEXPRESS_ROUTE_TYPES = { AIRPORT: 'airport' };

export {
  AEROEXPRESS_TARIFF_TYPES, AEROEXPRESS_TARIFF_DESCRIPTIONS, AEROEXPRESS_ROUTE_TYPES, AEROEXPRESS_TARIFF_MAP,
};
