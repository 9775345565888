import React, {
  useEffect, useRef, useState,
} from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';

import { CalculatorAdditionalFee } from './CalculatorAdd';
import { CalculatorAdditionalFeeInfo } from './CalculatorInfo';

import scrollToTop from '../../utils/scrollToTop';

import AIRPROVIDERS from '../../bi/constants/arline';

import styles from './styles.module.css';

const LABELS = {
  VALIDATION: {
    PROVIDER: 'Выберите провайдера',
    OFFICE_ID: 'Выберите Office Id',
    FEE: 'Введите данные сбора поставщика или сбора за выписку',
  },
};

const DEFAULT_FEE = {
  forTicket: 0,
  forSegment: 0,
  percent: 0,
};

const DEFAULT_PROVIDER_DATA = {
  provider: '',
  officeId: '',
};

const DEFAULT_VALIDATION = {
  provider: '',
  fee: '',
};

const Calculator = ({ calculatorService }) => {
  const [list, setList] = useState([]);

  const [validation, setValidation] = useState(DEFAULT_VALIDATION);

  const [providerData, setProviderData] = useState(DEFAULT_PROVIDER_DATA);

  const [hiddenFeeData, setHiddenFeeData] = useState(DEFAULT_FEE);

  const [additionalFeeData, setAdditionalFeeData] = useState(DEFAULT_FEE);

  const scrollRef = useRef(null);

  const getInfo = () => calculatorService.getInfo().then((listRes) => setList(listRes));

  useEffect(() => {
    getInfo();
  }, []);

  const handleClear = () => {
    setProviderData(DEFAULT_PROVIDER_DATA);
    setHiddenFeeData(DEFAULT_FEE);
    setAdditionalFeeData(DEFAULT_FEE);
  };

  const handleEditInfo = (item) => {
    const {
      providerData: providerDataItem,
      hiddenFeeData: hiddenFeeDataItem,
      additionalFeeData: additionalFeeDataItem,
    } = item;

    scrollToTop(scrollRef.current);

    setProviderData(providerDataItem);
    setHiddenFeeData(hiddenFeeDataItem);
    setAdditionalFeeData(additionalFeeDataItem);
  };

  const validData = () => {
    if (!providerData.provider) {
      return setValidation((prev) => ({ ...prev, provider: LABELS.VALIDATION.PROVIDER }));
    }

    if (providerData.provider === AIRPROVIDERS.AMADEUS.TYPE && !providerData.officeId.length) {
      return setValidation((prev) => ({ ...prev, provider: LABELS.VALIDATION.OFFICE_ID }));
    }

    const allHiddenFeeDataEmpty = !hiddenFeeData.forSegment && !hiddenFeeData.forTicket && !hiddenFeeData.percent;
    const allAdditionalFeeDataEmpty = !additionalFeeData.percent
      && !additionalFeeData.forSegment && !additionalFeeData.forTicket;

    if (allHiddenFeeDataEmpty && allAdditionalFeeDataEmpty) {
      return setValidation((prev) => ({ ...prev, fee: LABELS.VALIDATION.FEE }));
    }

    return true;
  };

  const handleSaveInfo = async () => {
    if (validData()) {
      await calculatorService.saveInfo({
        providerData, hiddenFeeData, additionalFeeData,
      });
      getInfo();
      handleClear();
      setValidation(DEFAULT_VALIDATION);
    }
  };

  const handleDeleteInfo = async (item) => {
    await calculatorService.deleteInfo(item);
    getInfo();
  };

  return (
    <div className={ styles.wrapper }>
      <CalculatorAdditionalFee
        providerData={ providerData }
        setProviderData={ setProviderData }
        hiddenFeeData={ hiddenFeeData }
        setHiddenFeeData={ setHiddenFeeData }
        additionalFeeData={ additionalFeeData }
        setAdditionalFeeData={ setAdditionalFeeData }
        handleSaveInfo={ handleSaveInfo }
        handleClear={ handleClear }
        validation={ validation }
        scrollRef={ scrollRef }
      />
      <CalculatorAdditionalFeeInfo
        list={ list }
        handleEditInfo={ handleEditInfo }
        handleDeleteInfo={ handleDeleteInfo }
      />
    </div>
  );
};

Calculator.propTypes = { calculatorService: PropTypes.object.isRequired };

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <Calculator
      calculatorService={ opts.calculatorService }
    />,
  );

  return root;
};

export default renderComponents;
