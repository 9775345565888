import Api from '../../../../../bi/api';
import useLoadData from '../../../hooks/useLoadData';

const useLoadProfile = ({ accountId }) => {
  const loadProfile = async () => Api.Vacation.getUserProfile(accountId);
  const {
    loading, data, error,
  } = useLoadData(loadProfile, [accountId]);

  return {
    loading, data, error,
  };
};

export default useLoadProfile;
