import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Dialog } from 'sw-ui';

import CircularLoaders from '../../components/loaders';
import { FlatButton } from '../../components/button';
import DecisionButtons from '../../components/DecisionButtons';
import ExistingTrip from './components/existingTrip';
import NewTrip from './components/newTrip';
import AjaxButton from '../../components/ajaxButton';

import { DECISIONBUTTONS } from '../../bi/constants/decisionButtons';
import { TRIPSTATUS } from '../../bi/constants/trips';

import styles from './styles/trip.module.scss';

const REFRESHCACHETRIP = 'Обновить поездку в кэше';

const NO_EVENT = {
  FIRST: 'отсутствуeт',
  SECOND: 'отсутствует',
};

const LABELS = {
  APROOVE_TO_RELOAD: 'Вы точно хотите уйти? Есть несохраненные изменения',
  UNPIN: (event) => `Вы действительно хотите открепить заявку ${event} от поездки?`,
  DELETE: 'Открепить',
  CANCEL: 'Отменить',
};

const STATUSES = [
  'Created',
  'Booked',
  'Completed',
];

class TripComponents extends Component {
  static propTypes = {
    airService: PropTypes.object.isRequired,
    aeroexpressService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    guidService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    travelPolicyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    moveToTrip: PropTypes.func.isRequired,
    moveToCompany: PropTypes.func.isRequired,
    accountService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      accountId, companyId, tripId,
    } = props.appService.get().params;
    const {
      waitPreloader,
      isLegalDialog,
      legalEntity,
      legalAllowEditingValues,
      legalSelectValue,
      versionsCount,
      events,
      event,
      estimate,
    } = props.tripService.get();

    props.tripService.getApplicationEvents({ AccountId: accountId, Statuses: STATUSES });
    props.tripService.getEstimate(tripId);

    props.companyService.loadCompaniesSettings(accountId);

    this.state = {
      loading: true,
      loadingAll: true,
      trip: null,
      tripId: parseInt(tripId, 10),
      isNewTrip: tripId === 'new',
      accountId: parseInt(accountId, 10),
      companyId: parseInt(companyId, 10),
      openDialog: false,
      waitingResponse: false,
      waitPreloader,
      isLegalDialog,
      legalEntity,
      legalAllowEditingValues,
      legalSelectValue,
      versionsCount,
      events,
      event: event.label,
      isModalDeleteApp: false,
      estimate,
    };
  }

  componentDidMount() {
    const {
      tripService,
      accountService,
      companyService,
    } = this.props;
    const {
      accountId,
      companyId,
      isNewTrip,
      tripId,
    } = this.state;

    companyService.loadCompanySettings(companyId);
    accountService.loadSpecialBill(accountId);
    tripService.loadAll(accountId, companyId, tripId);
    this.unsubscribeCb = tripService.subscribe(this.updateTrip);

    if (companyId) {
      tripService.loadMandatoryProject(accountId);
    }

    if (isNewTrip) {
      tripService.createNewTrip();
    } else {
      this.reloadTrip();
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeCb) {
      this.props.tripService.clearStore();
      this.unsubscribeCb();
    }
  }

  updateTrip = ({
    loading,
    currentTrip,
    companies,
    users,
    projects,
    projectsForNewTrip,
    analytics,
    mandatoryProject,
    changeRate,
    integration,
    integrationVersions,
    versionButtons,
    waitPreloader,
    isLegalDialog,
    legalEntity,
    legalAllowEditingValues,
    legalSelectValue,
    versionsCount,
    estimate,
  }) => this.setState({
    loading: !currentTrip,
    loadingAll: loading,
    trip: currentTrip,
    companies,
    users,
    projects,
    projectsForNewTrip,
    mandatoryProject,
    changeRate,
    integration,
    integrationVersions,
    versionButtons,
    analytics,
    waitPreloader,
    isLegalDialog,
    legalEntity,
    legalAllowEditingValues,
    legalSelectValue,
    versionsCount,
    estimate,
  });

  reloadTrip = () => {
    const { tripService } = this.props;
    const { accountId, tripId } = this.state;

    this.setState({ loading: true }, () => {
      tripService.load(accountId, tripId);
    });
  };

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderEmptyList = () => (
    <div className={ `empty-list ${styles['empty-wrap']}` }>
      <span>Воспользуйтесь</span>
      { ' ' }
      <a href='/companies'>поиском</a>
      { ' ' }
      <span>по компаниям</span>
    </div>
  );

  handleOpenDialog = () => this.setState({ openDialog: true });

  handleCloseDialog = () => this.setState({
    openDialog: false,
    waitResClearCache: false,
  });

  handlerClearCacheByTripId = () => {
    const {
      appService,
      tripService,
    } = this.props;

    const companyId = appService.get().params.companyId;
    const tripId = appService.get().params.tripId;

    const clearCache = () => {
      tripService.clearCacheByTripId(companyId, tripId)
        .then(() => tripService.updateTrip(companyId, tripId))
        .then(() => this.handleCloseDialog())
        .catch(() => this.handleCloseDialog());
    };

    this.setState({ waitResClearCache: true }, clearCache);
  };

  setTripAnalytics = (analyticsValues = []) => {
    this.props.tripService.setTripAnalytics(analyticsValues);
  };


  setConfitmDialog = () => confirm(LABELS.APROOVE_TO_RELOAD);

  handleMoveToCompany = () => {
    const {
      accountId, companyId, versionsCount,
    } = this.state;
    const { moveToCompany } = this.props;

    if (versionsCount) {
      return this.setConfitmDialog() ? moveToCompany(accountId, companyId) : false;
    }

    return moveToCompany(accountId, companyId);
  };

  onChangeApplication = async (data, tripId) => {
    const { value, label } = data;

    this.setState({ event: label });

    await this.props.tripService.setApplicationEvents({ EventId: value, TripId: tripId });
  };

  downloadTripApprovalReport = (tripId) => this.props.tripService.downloadTripApprovalReport(tripId);

  onChangeEstimate = async (estimate, tripId) => {
    this.setState({ estimate });

    await this.props.tripService.setEstimate(tripId, estimate);
  };

  handleDeleteModal = () => {
    this.setState({ isModalDeleteApp: true });
  };

  renderModalDelete = (trip) => {
    const { MICEEventName, TripId } = trip;
    const { isModalDeleteApp, event } = this.state;
    const { tripService: { unpinApplicationEvents } } = this.props;

    if (!isModalDeleteApp) {
      return null;
    }

    const handleDelete = () => {
      unpinApplicationEvents(TripId);

      this.setState({ isModalDeleteApp: false, event: NO_EVENT.FIRST });
    };

    const labelEvent = event === NO_EVENT.SECOND ? MICEEventName : event;

    return (
      <Dialog
        onClick={ () => this.setState({ isModalDeleteApp: false }) }
      >
        <div
          className={ styles.wrapper_dialog }
        >
          <div
            className={ styles.wrapper_dialog_text }
          >
            { LABELS.UNPIN(labelEvent) }
          </div>
          <div
            className={ styles.wrapper_dialog_button }
          >
            <AjaxButton
              label={ LABELS.DELETE }
              onClick={ () => handleDelete() }
            />
            <AjaxButton
              theme='flat'
              label={ LABELS.CANCEL }
              onClick={ () => this.setState({ isModalDeleteApp: false }) }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  render() {
    const {
      loading,
      loadingAll,
      trip,
      versionButtons,
      isNewTrip,
      companyId,
      companies,
      users,
      openDialog,
      projects,
      projectsForNewTrip,
      mandatoryProject,
      accountId,
      waitResClearCache,
      changeRate,
      integration,
      integrationVersions,
      analytics,
      waitPreloader,
      isLegalDialog,
      legalEntity,
      legalAllowEditingValues,
      legalSelectValue,
      estimate,
      event,
    } = this.state;

    const {
      accountService,
      tripService,
      aeroexpressService,
      featureFlagsService,
      companyService,
      guidService,
      airService,
      travelPolicyService,
      notificationsService,
      moveToTrip,
      moveToCompany,
    } = this.props;

    if (loadingAll || loading || !companies) {
      return this.renderLoading();
    }

    if (!loading && !trip) {
      return this.renderEmptyList();
    }

    const tripAnalyticsValueIds = trip.UserAnalytics || [];
    const specialBillAccount = accountService.get().specialAccount;

    const commonProps = {
      featureFlagsService,
      companyService,
      tripService,
      aeroexpressService,
      guidService,
      specialBillAccount,
      airService,
      travelPolicyService,
      notificationsService,
      accountService,
      companyId,
      companies,
      users,
      projects,
      projectsForNewTrip,
      mandatoryProject,
      accountId,
      changeRate,
      integration,
      integrationVersions,
      versionButtons,
      analytics,
      tripAnalyticsValueIds,
      setTripAnalytics: this.setTripAnalytics,
      legalEntity,
      isLegalDialog,
      legalAllowEditingValues,
      legalSelectValue,
    };

    const tripItem = isNewTrip
      ? (
        <NewTrip
          { ...commonProps }
          moveToTrip={ moveToTrip }
        />
      )
      : (
        <ExistingTrip
          { ...commonProps }
          trip={ trip }
          moveToCompany={ moveToCompany }
          reloadPage={ this.reloadTrip }
          waitPreloader={ waitPreloader }
          event={ event }
          estimate={ estimate }
          onChangeApplication={ this.onChangeApplication }
          onChangeEstimate={ this.onChangeEstimate }
          onDeleteModal={ this.handleDeleteModal }
          downloadTripApprovalReport={ this.downloadTripApprovalReport }
        />
      );

    let dialogCacheHtml = null;
    if (openDialog) {
      dialogCacheHtml = (
        <Dialog onClick={ this.handleCloseDialog }>
          <div className={ styles['form-cache'] }>
            <span>Вы уверены, что хотите обновить поездку в кэше?</span>
            <div className={ `${styles.row} ${styles['row-cache']}` }>
              <DecisionButtons
                waitingResponse={ waitResClearCache }
                onSave={ this.handlerClearCacheByTripId }
                onCancel={ this.handleCloseDialog }
                labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
              />
            </div>
          </div>
        </Dialog>
      );
    }

    return (
      <div className={ styles.wrap }>
        { this.renderModalDelete(trip) }
        <div className={ styles.row }>
          <div className={ styles['button-trip'] }>
            <div className={ styles['button-trip__left'] }>
              <FlatButton onClick={ this.handleMoveToCompany }>
                <i className='material-icons'>keyboard_backspace</i>
                { ' ' }
                к компании
              </FlatButton>
            </div>
            { !isNewTrip && trip.Status !== TRIPSTATUS.AWAITINGCONFIRMATION
              ? (
                <FlatButton
                  label={ REFRESHCACHETRIP }
                  onClick={ this.handleOpenDialog }
                />
              )
              : null }
          </div>
        </div>
        { tripItem }
        { dialogCacheHtml }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <TripComponents
      { ...opts }
    />,
  );

  return root;
};

export default renderComponents;
