import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Button } from 'sw-ui';

import CircularLoaders from '../../components/loaders';
import { CancelVoucherDialog } from '../../components/CancelVoucherDialog';
import TaxiResult from './components/TaxiResult';
import { GiftVoucherDialog } from './components/GiftVoucherDialog';

import { SERVICETYPE } from '../../bi/constants/serviceType';

import styles from './index.module.css';

const LABELS = {
  NUMBER: '№ поездки',
  COMPANY: 'Компания',
  EMPLOYEE: 'Фамилия И.О.',
  DATE: 'Даты',
  AMOUNT: 'Сумма',
  NOT_FOUND: 'Нет поездок',
  ADD_GIFT_VOUCHER: 'Добавить подарочный ваучер',
  CONFIRM_BUTTON: 'Отменить ваучер',
};

class SearchTaxi extends Component {
  static propTypes = {
    filterService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    moveToCompany: PropTypes.func.isRequired,
    moveToTrip: PropTypes.func.isRequired,
    toTrip: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { searchFilter, byQuery } = props.filterService.get();
    const {
      cancelVoucherDialog: {
        isRefundDialog,
        isAprooveDialog,
        isResultDialog,
        loadingCancelDialog,
        dialogResultMsg,
        amountCancelVoucher,
        cancelVoucherInputValue,
      },
      giftVoucherDialog,
      employeesList,
      loadingEmployeesList,
      errorEmployeesList,
    } = props.tripService.get();

    this.state = {
      loading: false,
      defaultMessage: searchFilter.label,
      searchValue: searchFilter.value,
      list: [],
      byQuery,
      isRefundDialog,
      isAprooveDialog,
      isResultDialog,
      loadingCancelDialog,
      dialogResultMsg,
      amountCancelVoucher,
      cancelVoucherInputValue,
      giftVoucherDialog,
      employeesList,
      loadingEmployeesList,
      errorEmployeesList,
    };
  }

  componentDidMount() {
    const { tripService } = this.props;

    this.unsubscribe = tripService.subscribe(this.updateState);
  }

  componentWillMount() {
    const { filterService } = this.props;

    this.unsubscribeFilterServiceCb = filterService.subscribe(this.updateFilter.bind(this));
  }

  componentWillUnmount() {
    if (this.unsubscribeFilterServiceCb) this.unsubscribeFilterServiceCb();

    this.unsubscribe();
  }

  updateState = ({
    cancelVoucherDialog: {
      isRefundDialog,
      isAprooveDialog,
      isResultDialog,
      loadingCancelDialog,
      dialogResultMsg,
      amountCancelVoucher,
      cancelVoucherInputValue,
    },
    giftVoucherDialog,
    employeesList,
    loadingEmployeesList,
    errorEmployeesList,
  }) => this.setState({
    isRefundDialog,
    isAprooveDialog,
    isResultDialog,
    loadingCancelDialog,
    dialogResultMsg,
    amountCancelVoucher,
    cancelVoucherInputValue,
    giftVoucherDialog,
    employeesList,
    loadingEmployeesList,
    errorEmployeesList,
  });

  updateFilter = () => {
    const { tripService, filterService } = this.props;
    const { searchValue } = this.state;

    const { searchFilter } = filterService.get();

    if (searchValue !== searchFilter.value) {
      this.setState({ loading: true });

      tripService.getTaxiVoucher(searchFilter.value).then((result) => {
        this.setState({
          list: result,
          searchValue: searchFilter.value,
          loading: false,
        });
      })
        .catch(() => {
          this.setState({
            searchValue: searchFilter.value,
            loading: false,
          });
        });
    }
  };

  handleClick = (item) => {
    this.props.toTrip(item);
  };

  handleOpenCompany = (company) => {
    this.props.moveToCompany(company);
  };

  renderEmptyList = () => (
    <div className={ styles['empty-list'] }>
      { LABELS.NOT_FOUND }
    </div>
  );

  renderList = () => {
    const listHtml = this.state.list.map((item, ind) => (
      <TaxiResult
        key={ ind }
        item={ item }
        onClick={ this.handleClick }
        handleOpenCompany={ this.handleOpenCompany }
      />
    ));

    return (
      <div className={ styles.wrap }>
        <div className={ styles.panel }>
          <div className={ styles.form }>
            <div className={ styles.row }>
              <div className={ styles['col-1-16'] }>
                <label>{ LABELS.NUMBER }</label>
              </div>
              <div className={ styles['col-3-16'] }>
                <label>{ LABELS.COMPANY }</label>
              </div>
              <div className={ styles['col-3-16'] }>
                <label>{ LABELS.EMPLOYEE }</label>
              </div>
              <div className={ styles['col-3-16'] }>
                <label>{ LABELS.DATE }</label>
              </div>
              <div className={ styles['col-3-16'] }>
                <label>{ LABELS.AMOUNT }</label>
              </div>
            </div>
            { listHtml }
          </div>
        </div>
      </div>
    );
  };

  renderResultList = () => {
    const { list, searchValue } = this.state;
    const isSearch = !!searchValue;
    const isList = list.length;

    if (!isSearch) return null;

    if (!isList) {
      return (
        <div className={ `${styles.wrap} ${styles['wrap-empty']} ${styles['empty-list']}` }>
          { LABELS.NOT_FOUND }
        </div>
      );
    }

    return this.renderList();
  };

  renderAddGiftVoucherButton = () => {
    const { tripService: { setGiftVoucherDialog }, featureFlagsService } = this.props;
    const { LoyaltyVoucher = false } = featureFlagsService.get();

    if (!LoyaltyVoucher) return null;

    return (
      <Button
        theme='second'
        className={ styles.cancel_btn }
        onClick={ () => setGiftVoucherDialog(true) }
      >
        { LABELS.ADD_GIFT_VOUCHER }
      </Button>
    );
  };

  renderCancelButton = () => {
    const { tripService: { setRefundDialog } } = this.props;

    return (
      <Button
        theme='second'
        className={ styles.cancel_btn }
        onClick={ () => setRefundDialog(true) }
      >
        { LABELS.CONFIRM_BUTTON }
      </Button>
    );
  };

  renderGiftVoucherDialog = () => {
    const {
      tripService: {
        setGiftVoucherDialog,
        setGiftVoucherCompanyId,
        getGiftVoucherEmployeesInfo,
        setGiftVoucherEmployee,
        setGiftVoucherValue,
        saveGiftVoucher,
        giftVoucherFieldValidation,
      },
      featureFlagsService,
      moveToTrip,
    } = this.props;
    const {
      giftVoucherDialog, employeesList, loadingEmployeesList, errorEmployeesList,
    } = this.state;
    const { NewTaxiClasses = false } = featureFlagsService.get();
    const { isOpenGiftVoucher } = giftVoucherDialog;

    if (!isOpenGiftVoucher) return null;

    return (
      <GiftVoucherDialog
        data={ giftVoucherDialog }
        newTaxiClasses={ NewTaxiClasses }
        onChangeCompanyId={ setGiftVoucherCompanyId }
        onSendCompanyId={ (value, query) => getGiftVoucherEmployeesInfo(value, query) }
        onClose={ () => setGiftVoucherDialog(false) }
        onSetValue={ setGiftVoucherValue }
        onSelectEmployeeSuggest={ setGiftVoucherEmployee }
        onSave={ () => saveGiftVoucher(moveToTrip) }
        validate={ giftVoucherFieldValidation }
        employeesList={ employeesList }
        loadingEmployeesList={ loadingEmployeesList }
        errorEmployeesList={ errorEmployeesList }
      />
    );
  };

  render() {
    const {
      loading,
      isRefundDialog,
      isAprooveDialog,
      isResultDialog,
      loadingCancelDialog,
      dialogResultMsg,
      amountCancelVoucher,
      cancelVoucherInputValue,
    } = this.state;
    const {
      tripService: {
        setRefundDialog,
        setAprooveDialog,
        setResultVoucherDialog,
        getRefundVoucher,
        cancelVoucher,
        setCancelVoucherInputValue,
      },
    } = this.props;

    if (loading) {
      return <CircularLoaders wrapClassName='content-loader-wrap' />;
    }

    return (
      <div>
        <div className={ styles.action_buttons }>
          { this.renderAddGiftVoucherButton() }
          { this.renderCancelButton() }
        </div>
        { this.renderResultList() }
        { this.renderGiftVoucherDialog() }
        <CancelVoucherDialog
          setRefundDialog={ setRefundDialog }
          isRefundDialog={ isRefundDialog }
          setAprooveDialog={ setAprooveDialog }
          isAprooveDialog={ isAprooveDialog }
          isResultDialog={ isResultDialog }
          setResultVoucherDialog={ setResultVoucherDialog }
          getRefundVoucher={ getRefundVoucher }
          cancelVoucher={ cancelVoucher }
          loadingCancelDialog={ loadingCancelDialog }
          dialogResultMsg={ dialogResultMsg }
          amountCancelVoucher={ amountCancelVoucher }
          setCancelVoucherInputValue={ setCancelVoucherInputValue }
          cancelVoucherInputValue={ cancelVoucherInputValue }
          serviceType={ SERVICETYPE.TAXI }
        />
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  const page = (
    <SearchTaxi
      tripService={ opts.tripService }
      filterService={ opts.filterService }
      featureFlagsService={ opts.featureFlagsService }
      toTrip={ opts.toTrip }
      moveToCompany={ opts.moveToCompany }
      moveToTrip={ opts.toTrip }
    />
  );

  root.render(page);

  return root;
};

export { SearchTaxi };

export default renderComponents;
