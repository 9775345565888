import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog } from 'sw-ui';

import Input from '../../../../components/input';
import DecisionButtons from '../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';
import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles/company.module.scss';

const LABELS = {
  DIALOG_TITLE: 'Реквизиты Смартвэй',
  DELETE_REQUISITES: 'Удалить реквизиты',
};

const DIALOG_WIDTH = { SMALL: '450px' };

const FIELDS_FORM = {
  companyName: 'Имя компании',
  inn: 'ИНН',
  kpp: 'КПП',
  bik: 'БИК Банка',
  invoiceNumber: 'Счет№',
  email: 'Email',
  bankDetails: 'Реквизиты банка',
  correspondentBill: 'Корр. счет',
};

export default class Requisites extends Component {
  static propTypes = {
    INPUT_FIELDS: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    params: PropTypes.object.isRequired,
    onShowRequisites: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { requisites } = props.companyService.get();

    this.state = {
      requisites,
      validation: {
        bicValidate: '',
        invoiceNumberValidate: '',
        emailValidate: '',
        bankDetailsValidate: '',
        correspondentBillValidate: '',
      },
      waitingResponse: false,
      isValid: true,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.companyService.subscribe(this.update);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({ requisites }) => this.setState({ requisites });

  handlerChangeRequisitesInput = (field, value) => {
    const { INPUT_FIELDS } = this.props;
    const { requisites } = this.state;

    if ((field === INPUT_FIELDS.BIC && value.length > 9)
      || (field === INPUT_FIELDS.INVOICE_NUMBER && value.length > 20)
      || (field === INPUT_FIELDS.EMAIL && value.length > 50)
      || (field === INPUT_FIELDS.BANK_DETAILS && value.length > 50)
      || (field === INPUT_FIELDS.CORRESPONDENT_BILL && value.length > 20)) return;

    this.setState({
      requisites: {
        ...requisites,
        [field]: value,
      },
    });
  };

  validatiHandleSave = (requisites) => {
    const { INPUT_FIELDS, companyService: { requisitesFieldValidation } } = this.props;
    const {
      bik, invoiceNumber, email, bankDetails, correspondentBill,
    } = requisites;

    const bicValidate = requisitesFieldValidation(INPUT_FIELDS.BIC, bik);
    const invoiceNumberValidate = requisitesFieldValidation(INPUT_FIELDS.INVOICE_NUMBER, invoiceNumber);
    const emailValidate = requisitesFieldValidation(INPUT_FIELDS.EMAIL, email);
    const bankDetailsValidate = requisitesFieldValidation(INPUT_FIELDS.BANK_DETAILS, bankDetails);
    const correspondentBillValidate = requisitesFieldValidation(INPUT_FIELDS.CORRESPONDENT_BILL, correspondentBill);

    const operations = bicValidate
      || invoiceNumberValidate
      || emailValidate
      || bankDetailsValidate
      || correspondentBillValidate;

    return operations
      ? {
        isValid: false,
        validation: {
          bicValidate,
          invoiceNumberValidate,
          emailValidate,
          bankDetailsValidate,
          correspondentBillValidate,
        },
      }
      : { isValid: true };
  };

  handleSave = (requisites) => {
    const { companyService, params: { companyId } } = this.props;

    companyService.saveRequisites({ ...requisites, companyId })
      .then(this.handleClose);
  };

  handleSaveDefalut = () => {
    const {
      companyService, onShowRequisites, params: { companyId },
    } = this.props;

    companyService.updateRequisitesDefault(companyId);
    onShowRequisites();
  };

  handleClose = () => this.props.onShowRequisites();

  validationSave = () => {
    const { requisites } = this.state;
    const { isValid, validation } = this.validatiHandleSave(requisites);

    if (isValid) {
      this.setState({
        waitingResponse: true,
        validation: {
          bicValidate: '',
          invoiceNumberValidate: '',
          emailValidate: '',
          bankDetailsValidate: '',
          correspondentBillValidate: '',
        },
      }, this.handleSave(requisites));
    } else {
      this.setState({ validation: { ...validation } });
    }
  };

  renderRequisitesDialog = () => {
    const { INPUT_FIELDS, onShowRequisites } = this.props;
    const {
      requisites: {
        companyName,
        inn,
        kpp,
        bik,
        invoiceNumber,
        email,
        bankDetails,
        correspondentBill,
      },
      validation: {
        bicValidate,
        invoiceNumberValidate,
        emailValidate,
        bankDetailsValidate,
        correspondentBillValidate,
      },
      waitingResponse,
    } = this.state;

    return (
      <Dialog onClick={ () => onShowRequisites() } width={ DIALOG_WIDTH.SMALL } >
        <div className={ styles.requisites_dialog }>
          <div>
            <h3 className={ styles.requisites_dialog_title }>
              { LABELS.DIALOG_TITLE }
            </h3>
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.NAME }
              value={ companyName }
              label={ FIELDS_FORM.companyName }
              disabled
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.INN }
              value={ inn }
              label={ FIELDS_FORM.inn }
              disabled
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.KPP }
              value={ kpp }
              label={ FIELDS_FORM.kpp }
              disabled
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.BIC }
              type={ bik.type }
              value={ bik }
              label={ FIELDS_FORM.bik }
              labelDropDown
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
              valid={ bicValidate }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.INVOICE_NUMBER }
              value={ invoiceNumber }
              label={ FIELDS_FORM.invoiceNumber }
              labelDropDown
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
              valid={ invoiceNumberValidate }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.EMAIL }
              value={ email }
              label={ FIELDS_FORM.email }
              labelDropDown
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
              valid={ emailValidate }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.BANK_DETAILS }
              value={ bankDetails }
              label={ FIELDS_FORM.bankDetails }
              labelDropDown
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
              valid={ bankDetailsValidate }
            />
          </div>
          <div>
            <Input
              field={ INPUT_FIELDS.CORRESPONDENT_BILL }
              value={ correspondentBill }
              label={ FIELDS_FORM.correspondentBill }
              labelDropDown
              onChange={ (e, field, value) => this.handlerChangeRequisitesInput(field, value) }
              valid={ correspondentBillValidate }
            />
          </div>
          <div className={ styles.requisites_dialog_buttons } >
            <Button
              onClick={ this.handleSaveDefalut }
              label={ LABELS.DELETE_REQUISITES }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
            />
            <DecisionButtons
              waitingResponse={ waitingResponse }
              onSave={ this.validationSave }
              onCancel={ () => this.handleClose() }
              labelSave={ DECISIONBUTTONS.LABELS.SAVE }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  render() {
    return (
      <div>
        { this.renderRequisitesDialog() }
      </div>
    );
  }
}
