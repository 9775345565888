import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import ProfileCardWithStyle from '../../components/profile';
import MenuWithStyle from './Menu';
import SignoutWithStyle from '../../components/auth';

import cn from '../../utils/classNames';

import styles from './styles/menu.module.css';

const LOGOS = {
  SMALL: '/static/media/logo_small.png',
  REGULAR: '/static/media/logo.svg',
};

class LeftMenu extends Component {
  static propTypes = {
    workplaceService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { userName, user } = props.workplaceService.get();
    const { menu } = props.appService.get();

    this.state = {
      menu,
      userName,
      user,
      isCloseMenu: true,
    };
  }

  componentDidMount() {
    const { appService, workplaceService } = this.props;

    this.unsubscribeAppService = appService.subscribe(this.updateMenuItem);
    this.unsubWorkplaceService = workplaceService.subscribe(this.updateWorkplaceService);

    workplaceService.getUserName();
  }

  componentWillUnmount() {
    if (this.unsubscribeAppService) {
      this.unsubscribeAppService();
    }

    if (this.unsubWorkplaceService) {
      this.unsubWorkplaceService();
    }
  }

  updateWorkplaceService = ({ userName, user }) => this.setState({
    userName,
    user,
  });

  updateMenuItem = ({ menu }) => {
    this.setState({ menu });
  };

  goMenuItem = (e, menuItem) => {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.appService.getCurrentMenuItem() !== menuItem.type && !menuItem.items) {
      this.props.appService.updateMenuItem(menuItem);
      this.props.navigate(menuItem.url);
    }
  };

  signout = (e) => {
    e.stopPropagation();
    e.preventDefault();

    this.props.workplaceService.logout().then(() => {
      this.props.navigate('/login');
    });
  };

  renderProfileCard = () => {
    const { userName } = this.state;

    return userName && (
      <ProfileCardWithStyle
        userName={ userName }
      />
    );
  };

  handleChangeViewMenu = (isCloseMenu) => () => this.setState({ isCloseMenu });

  render() {
    const {
      menu: items,
      isCloseMenu,
    } = this.state;

    const getWrapClases = (cl) => cn({
      [cl]: true,
      [styles.menu_close]: isCloseMenu,
    });

    const logo = isCloseMenu ? LOGOS.SMALL : LOGOS.REGULAR;

    return (
      <div className={ getWrapClases(styles.wrap) }>
        <div className={ getWrapClases(styles.menu) } onMouseEnter={ this.handleChangeViewMenu(false) }>
          <button className={ styles.button_wrap } onClick={ this.handleChangeViewMenu(!isCloseMenu) }>
            <i className='material-icons'>{ `keyboard_${isCloseMenu ? 'arrow_right' : 'arrow_left'}` }</i>
          </button>
          <div className={ styles.header }>
            <img
              className={ styles.logo }
              alt='smartway - dance tomorrow, dance today'
              src={ logo }
            />
          </div>
          <div className={ styles.profile }>
            { this.renderProfileCard() }
          </div>
          <div className={ styles.content }>
            <MenuWithStyle
              items={ items }
              navigate={ this.goMenuItem }
            />
          </div>
          <div className={ styles.footer }>
            <SignoutWithStyle signout={ this.signout } />
          </div>
        </div>
      </div>
    );
  }
}

const renderComponents = (box, services) => {
  const root = createRoot(box);

  root.render(
    <LeftMenu
      workplaceService={ services.workplaceService }
      appService={ services.appService }
      navigate={ services.navigate }
      featureFlagsService={ services.featureFlagsService }
    />,
  );

  return root;
};

export { LeftMenu };

export default renderComponents;
