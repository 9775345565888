import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from 'react-autocomplete';
import Button from '../../components/button/Button';

import { FIELDS } from '../../bi/constants/regions';

import styles from './styles/hotelSearch.module.css';

const SEARCH_REGIONS = 'Найти Регион';

const INPUT_STYLE = {
  background: 'transparent',
  position: 'relative',
  zIndex: 5,
  border: 'none',
  width: '100%',
  padding: '2px',
  color: 'inherit',
  boxSizing: 'borderBox',
  font: 'inherit',
};

const WRAP_STYLE = { width: '100%' };

class RegionsSearch extends Component {
  static propTypes = {
    regionsService: PropTypes.object.isRequired,
    searchRegionsInput: PropTypes.object.isRequired,
  };

  handleChange = async (value) => {
    const { regionsService: { autocompleteRegion, changeField } } = this.props;
    changeField(value, FIELDS.SEARCH.VALUE);

    const suggest = await autocompleteRegion(value);

    changeField(suggest, FIELDS.SEARCH.SUGGEST);
  };

  handleSelect = (value) => this.props.regionsService.changeField(value, FIELDS.SEARCH.VALUE);

  handleSearchRegion = (e) => {
    e.preventDefault();
    const {
      searchRegionsInput: { value },
      regionsService: { searchRegions },
    } = this.props;

    searchRegions(value);
  };

  renderMenu = (items) => (
    <div className={ styles.autocomplete }>
      <div className={ styles.items_wrap }>
        { items }
      </div>
    </div>
  );

  renderItem = (item) => item && (
    <div key={ item.RegionId } className={ styles.autocomplete_item }>
      <div className={ styles.autocomplete_item_text }>
        { ' ' }
        { `${item.City}` }
      </div>
    </div>
  );

  render() {
    const {
      searchRegionsInput: {
        value: currentValue, suggest, label,
      },
    } = this.props;
    const checkSuggest = suggest[0] ? suggest : [];
    const inputProps = {
      placeholder: label,
      style: INPUT_STYLE,
      type: 'search',
      required: true,
    };

    return (
      <form className={ styles.form_wrap } onSubmit={ this.handleSearchRegion }>
        <div className={ styles.input_wrap }>
          <Autocomplete
            value={ currentValue }
            items={ checkSuggest }
            getItemValue={ (item) => item.City }
            onSelect={ (value) => this.handleSelect(value) }
            onChange={ ({ target: { value } }) => this.handleChange(value) }
            renderMenu={ this.renderMenu }
            inputProps={ inputProps }
            wrapperStyle={ WRAP_STYLE }
            renderItem={ (item) => this.renderItem(item) }
          />
        </div>
        <Button
          label={ SEARCH_REGIONS }
          className={ styles.button_wrap }
          type='submit'
        />
      </form>
    );
  }
}

export default RegionsSearch;
