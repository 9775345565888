import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CircularLoaders from '../../components/loaders';

import CompaniesItem from './item';

import styles from './styles/companies.module.scss';

const EMPTYLIST = 'Не найдено ни одной компании';

class CompaniesComponents extends Component {
  static propTypes = {
    filterService: PropTypes.object.isRequired,
    companiesService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const { searchFilter } = props.filterService.get();

    this.state = {
      loading: Boolean(searchFilter.value),
      searchValue: searchFilter.value,
      defaultMessage: searchFilter.label,
      list: [],
      isInnOnly: searchFilter.isSearchByInn,
    };
  }

  UNSAFE_componentWillMount() {
    const { filterService, companiesService } = this.props;

    this.unsubscribeFilterServiceCb = filterService.subscribe(this.updateFilter);
    this.unsubscribeCompaniesServiceCb = companiesService.subscribe(this.updateCompaniesList);
  }

  componentWillUnmount() {
    this.props.filterService.cancelSearchFilter();

    if (this.unsubscribeFilterServiceCb) this.unsubscribeFilterServiceCb();
    if (this.unsubscribeCompaniesServiceCb) this.unsubscribeCompaniesServiceCb();
  }

  updateFilter = () => {
    const { filterService, companiesService } = this.props;
    const { value, isSearchByInn } = filterService.get().searchFilter;

    if (this.state.searchValue !== value) {
      companiesService.searchAccounts(value, isSearchByInn);

      this.setState({
        searchValue: value,
        isInnOnly: isSearchByInn,
        loading: true,
      });
    }
  };

  updateCompaniesList = () => this.setState({
    list: this.props.companiesService.get().accounts,
    loading: false,
  });

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  renderDefaultMessage = () => (
    <div className='empty-list'>
      { this.state.defaultMessage }
    </div>
  );

  renderEmptyList = () => (
    <div className='empty-list'>
      { EMPTYLIST }
    </div>
  );

  renderCompaniesItem = (item, id) => (
    <CompaniesItem
      key={ id }
      item={ item }
    />
  );

  render() {
    const {
      loading, list, searchValue,
    } = this.state;
    const isSearch = Boolean(searchValue);

    if (loading) return this.renderLoading();
    if (!loading && !list.length && !isSearch) return this.renderDefaultMessage();
    if (!loading && !list.length && isSearch) return this.renderEmptyList();

    return (
      <div className={ styles.wrap }>
        { list.map(this.renderCompaniesItem) }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <CompaniesComponents
      companiesService={ opts.companiesService }
      filterService={ opts.filterService }
    />,
  );

  return root;
};

export default renderComponents;
