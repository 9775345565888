import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, Tooltip } from 'sw-ui';

import { prepareDataEdit } from '../../../bi/utils/cart';

import documentType from '../../../bi/constants/documentType';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import COMPONENTS from '../../../bi/constants/components';
import { CARTSTATUS } from '../../../bi/constants/cart';
import { HOTEL_PROVIDER_VALUE } from '../../../bi/constants/hotel';

import AirlineItem from './AirlineItem';
import HotelItem from './HotelItem';
import TransferItem from './TransferItem';
import TrainItem from './TrainItem';
import AeroexpressItem from './AeroexpressItem';
import ItemPanel from './ItemPanel';
import ItemLayout from './ItemLayout';
import EmployeeList from './EmployeeList';
import Timer from './Timer';
import TransferFields from './TransferFields';
import { InsuranceItem } from './InsuranceItem';

import styles from '../styles/cart.module.css';

const LABELS = {
  EDIT: 'Внести изменения',
  DELETE: 'Удалить',
  ERROR_TRAVEL_POLICY: 'Нарушена Travel политика',
};

const CartItem = ({
  item,
  cart,
  serverTime,
  companies,
  projects,
  appService,
  cartsAndNotesService,
  moveToEditCart,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const itemData = JSON.parse(item.Data);
  const companyId = appService.get().params.companyId;

  let timerHtml = null;
  let reservedIconHtml = null;
  let transferHtml = null;

  if (item.ServiceType === SERVICETYPE.TRANSFER) {
    transferHtml = (
      <TransferFields
        itemData={ itemData }
        employees={ item.Employees }
      />
    );
  }

  if (item.IsReserved) {
    timerHtml = (
      <div className={ styles.timer }>
        <Timer
          deadLineInStrUTC={ item.BookDeadline }
          currentTime={ moment(serverTime) }
        />
      </div>
    );
    reservedIconHtml = (
      <div className={ styles['reserved-icon'] }>
        <span className='smartway-smart icon' />
      </div>
    );
  }

  const handleOpenFormEdit = async () => {
    let departments = [];

    if (item.Employees.length) {
      departments = await cartsAndNotesService.getDepartments(item.Employees[0].Employee.Id, companyId);
    }

    cartsAndNotesService.setItemCart(prepareDataEdit(item, departments));
    moveToEditCart(cart.Id);
  };

  const handleDeleteItem = async () => {
    const userId = appService.get().params.userId;
    const accountId = appService.get().params.accountId;

    await cartsAndNotesService.deleteItem(cart.Id, item.Id);
    cartsAndNotesService.loadCartsAndNotes(userId, accountId, companyId);
  };

  const renderItem = () => {
    const itemMetadata = JSON.parse(item.Metadata) || {};
    const PNR = itemMetadata.PNR;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR: {
        return itemData.Routes.map((route) => (
          <AirlineItem
            key={ route.ID }
            providerName={ itemData.ProviderName }
            route={ route }
            pnr={ PNR }
            meta={ itemData.Metadata }
            showSegments
          />
        ));
      }
      case SERVICETYPE.HOTEL: {
        return (
          <HotelItem
            item={ itemData }
          />
        );
      }
      case SERVICETYPE.TRANSFER: {
        return (
          <TransferItem
            item={ itemData }
          />
        );
      }
      case SERVICETYPE.TRAIN: {
        return (
          <TrainItem
            item={ itemData }
          />
        );
      }
      case SERVICETYPE.AEROEXPRESS: {
        return (
          <AeroexpressItem
            item={ itemData }
          />
        );
      }
      case SERVICETYPE.INSURANCE: {
        return (
          <InsuranceItem
            item={ itemData }
          />
        );
      }
    }

    return null;
  };

  const renderItemEmployees = () => {
    const readOnly = true;

    let showDocument = false;

    switch (item.ServiceType) {
      case SERVICETYPE.AIR:
      case SERVICETYPE.TRAIN: {
        showDocument = true;
        break;
      }
      case SERVICETYPE.HOTEL:
      case SERVICETYPE.TRANSFER:
    }

    return (
      <EmployeeList
        isCart
        isMulti={ companies.length > 0 }
        items={ item.Employees }
        showDocument={ showDocument }
        readOnly={ readOnly }
        enumOfDocuments={ documentType }
        employeesWithCompanies={ item.EmployeeCartItems }
        companies={ companies }
        projects={ projects }
      />
    );
  };

  const renderTooltipTP = () => {
    if (!showTooltip) return null;

    const textTooltip = Object.keys(item.TravelPolicy)
      .map((key) => item.TravelPolicy[key])
      .join(' ');

    return (
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
      >
        { textTooltip }
      </Tooltip>
    );
  };

  const renderTravelPolicy = () => {
    if (!Object.keys(item.TravelPolicy).length) return null;

    return (
      <div className='sw-tooltip-wrapper'>
        <div
          className={ styles['tp-text'] }
          onMouseEnter={ () => setShowTooltip(true) }
          onMouseLeave={ () => setShowTooltip(false) }
        >
          { LABELS.ERROR_TRAVEL_POLICY }
        </div>
        { renderTooltipTP() }
      </div>
    );
  };

  const renderOfflineButtons = () => {
    if (!cart.IsOffline) return null;

    const disabledDelete = !cart.CanDeleteCart || cart.Status === CARTSTATUS.APPROVED;
    const disabledEditCart = !cart.CanEditCart
      || item.ProviderName === HOTEL_PROVIDER_VALUE.expedia
      || item.ProviderName === HOTEL_PROVIDER_VALUE.tripcom;

    return (
      <div className={ styles['offline-info'] }>
        <div>
          { renderTravelPolicy() }
        </div>
        <div className={ styles['buttons-offline'] }>
          <div className={ styles['button-offline'] }>
            <Button
              label={ LABELS.EDIT }
              theme={ COMPONENTS.BUTTON.THEME.WHITE }
              onClick={ handleOpenFormEdit }
              disabled={ disabledEditCart }
            />
          </div>
          <div className={ styles['button-offline'] }>
            <Button
              label={ LABELS.DELETE }
              theme={ COMPONENTS.BUTTON.THEME.WHITE }
              onClick={ handleDeleteItem }
              disabled={ disabledDelete }
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <ItemPanel className={ styles.item_panel }>
      <div className={ styles.item }>
        { reservedIconHtml }
        <ItemLayout
          price={ item.Price }
          serviceType={ item.ServiceType }
        >
          { renderItem() }
        </ItemLayout>
        { transferHtml }
        { renderOfflineButtons() }
        <div className={ styles['actions-item'] }>
          <div className={ styles.employees }>
            { renderItemEmployees() }
          </div>
        </div>
        { timerHtml }
      </div>
    </ItemPanel>
  );
};

CartItem.propTypes = {
  item: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  serverTime: PropTypes.string.isRequired,
  companies: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  cartsAndNotesService: PropTypes.object.isRequired,
  appService: PropTypes.object.isRequired,
  moveToEditCart: PropTypes.func.isRequired,
};

export default CartItem;
