import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../../../styles/trip.module.scss';

const LABELS = {
  TEXT: (name, empl) => `Вы действительно хотите удалить ${name} ${empl}?`,
  BUTTONS: {
    YES: 'Да',
    CANCEL: 'Отмена',
  },
};

const DIALOG_WIDTH = '400px';

const RemoveDialog = ({
  item, onDelete, onClose,
}) => {
  const {
    employee, name, deleteItemLoading,
  } = item;

  return (
    <Dialog
      width={ DIALOG_WIDTH }
      onClick={ onClose }
    >
      <div className={ styles['expense-remove-dialog'] }>
        <div>
          { LABELS.TEXT(name, employee) }
        </div>
        <div className={ styles['expense-remove-dialog-buttons'] }>
          <DecisionButtons
            labelSave={ LABELS.BUTTONS.YES }
            labelCancel={ LABELS.BUTTONS.CANCEL }
            onSave={ onDelete }
            onCancel={ onClose }
            waitingResponse={ deleteItemLoading }
          />
        </div>
      </div>
    </Dialog>
  );
};

RemoveDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
};

export default RemoveDialog;
