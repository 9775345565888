import {
  dateWithUtcOffset, diffDays, momentSetHoursAndMinutes,
} from './formatDate';

import { REQUEST_STATUS_LABELS } from '../constants/hotelBookRequest';

const extractIntercomChatId = (url) => {
  if (!url) return null;

  const regex = /conversation\/(\d+)/;
  const regUrl = url.match(regex);

  if (!regUrl) {
    return null;
  }

  return regUrl[1];
};

const prepareDataForBookingRequest = (cart, accountId, userEmail) => {
  const {
    IntercomLink,
    Items,
    UserAnalytics,
  } = cart;

  const {
    CartId,
    TripId,
    EmployeeCartItems,
    Employees,
    Data,
    UserAnalytics: itemUserAnalytics,
  } = Items[0];

  const {
    CompanyId,
    ProjectId,
    DepartmentId,
  } = EmployeeCartItems[0];

  const hotelInfo = JSON.parse(Data);

  const {
    hotel: {
      Name,
      Email,
      Phone,
      ClassificatorId,
    },
    room: { Name: roomName },
    Rate: {
      Amenities,
      Price,
      CheckinDate,
      CheckoutDate,
      CustomCheckInDate,
      CustomCheckOutDate,
    },
  } = hotelInfo;

  const {
    Base,
    Commission,
    HasVAT,
    EarlyCheckIn,
    LateCheckOut,
  } = Price;

  const {
    CancellationInfo,
    CancellationPenalties,
    Meal: {
      Include,
      Price: MealPrice,
    },
  } = Amenities;

  const employeesList = Employees.map(({ Employee }) => Employee.Id);

  const daysDiff = diffDays(momentSetHoursAndMinutes(CheckinDate, 0, 0), momentSetHoursAndMinutes(CheckoutDate, 0, 0));

  const isFreeCancellation = !!CancellationInfo;
  const cancellationDateWithoutFee = isFreeCancellation ? dateWithUtcOffset(CancellationInfo) : null;

  const checkingDate = dateWithUtcOffset(CheckinDate);
  const checkoutDate = dateWithUtcOffset(CheckoutDate);

  const dailyPrice = CancellationPenalties ? parseFloat((Base / daysDiff).toFixed(2)) : null;

  const cancellationFeeTotal = CancellationPenalties.length ? String(CancellationPenalties[0].Total) : '0';
  const cancellationFeeBase = CancellationPenalties.length ? Number(CancellationPenalties[0].Base) : 0;

  const intercomChatId = Number(extractIntercomChatId(IntercomLink));

  const userAnalytics = Array.from(new Set([...UserAnalytics, ...itemUserAnalytics]));

  const customCheckinPrice = CustomCheckInDate ? Number(EarlyCheckIn) : 0;
  const customChecoutPrice = CustomCheckOutDate ? Number(LateCheckOut) : 0;

  return {
    companyId: CompanyId,
    hotelId: Number(ClassificatorId),
    status: REQUEST_STATUS_LABELS.Incomplete,
    employees: employeesList,
    hotelName: Name || '',
    sendAt: null,
    phone: Phone || '',
    emails: [Email] || [],
    dailyPrice,
    commission: Number(Commission) || 0,
    checkinTime: checkingDate,
    checkoutTime: checkoutDate,
    roomCategory: roomName,
    bedType: '',
    isSelfEmployed: false,
    worksWithVat: HasVAT,
    priceInclVat: false,
    hasFreeCancellation: isFreeCancellation,
    freeCancellationDate: cancellationDateWithoutFee,
    freeCancellation: isFreeCancellation,
    cancellationFeeTotal,
    cancellationFeeBase,
    hasBreakfast: Include,
    breakfastPrice: Number(MealPrice) || 0,
    breakfast: Include,
    hasEarlyCheckin: CustomCheckInDate || false,
    earlyCheckinPrice: customCheckinPrice,
    earlyCheckin: CustomCheckInDate || false,
    hasLateCheckout: CustomCheckOutDate || false,
    lateCheckoutPrice: customChecoutPrice,
    lateCheckout: CustomCheckOutDate || false,
    conversationId: intercomChatId,
    customerEmail: userEmail,
    accountId: Number(accountId),
    tripId: TripId || 0,
    departmentId: DepartmentId || 0,
    projectId: ProjectId || 0,
    cartId: CartId,
    letterSubject: '',
    additionalComment: '',
    userAnalytics: userAnalytics || [],
  };
};

export { prepareDataForBookingRequest, extractIntercomChatId };
