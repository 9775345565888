import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Dialog, Loading, Select,
} from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { RATETYPE, HOTELORDERRATE } from '../../../../../constants/rateTypes';
import COMPONENTS from '../../../../../bi/constants/components';
import styles from '../styles/dialog.module.css';

const WIDTH = '400px';

class HotelRateDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    accountService: PropTypes.object.isRequired,
    currentRate: PropTypes.string.isRequired,
  };

  constructor(props) {
    super();

    this.state = {
      rates: [],
      rate: props.currentRate,

      loading: true,
    };
  }

  componentDidMount() {
    this.props.accountService.getRates(RATETYPE.HOTEL)
      .then((res) => this.setState({ loading: false, rates: res }));
  }

  handleChangeRate = ({ value }) => this.setState({ rate: value });

  handleSave = () => {
    const { accountService } = this.props;
    const { rate } = this.state;

    return accountService.updateRates(RATETYPE.HOTEL, rate);
  };

  renderSelectRate = () => {
    const { rates, rate } = this.state;

    const items = rates.map((item) => ({
      value: item,
      label: HOTELORDERRATE[item],
    }));

    const currentRate = rates.includes(rate) ? rate : rates[0];

    return (
      <div className={ styles.select }>
        <h4>Выписка:</h4>
        <Select
          items={ items }
          value={ currentRate }
          theme={ COMPONENTS.SELECT.THEME.BORDER }
          onChange={ this.handleChangeRate }
        />
      </div>
    );
  };

  renderLoading = () => (
    <Dialog onClick={ this.props.onClose } width={ WIDTH }>
      <div className={ styles.loading }>
        <Loading size={ COMPONENTS.LOADING.SIZE.LARGE } />
      </div>
    </Dialog>
  );

  render() {
    const { onClose } = this.props;
    const { loading } = this.state;

    return loading
      ? this.renderLoading()
      : (
        <Dialog onClick={ onClose } width={ WIDTH }>
          <div className={ styles.dialog }>
            <h3>Изменение тарифа для гостиниц</h3>
            { this.renderSelectRate() }
            <DecisionButtons
              loadingIncluded
              onCancel={ onClose }
              onSave={ this.handleSave }
            />
          </div>
        </Dialog>
      );
  }
}

export default HotelRateDialog;
