import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { formatDate } from '../../../../bi/utils/formatDate';

import styles from './styles/index.module.css';

class EmployeePassport extends Component {
  static propTypes = {
    documents: PropTypes.array.isRequired,
    employee: PropTypes.object.isRequired,
    enumOfDocuments: PropTypes.object.isRequired,
  };

  renderLabelSelect = (item) => (
    <div>
      { item.label }
      { item.anotherLabel }
    </div>
  );

  renderItemSelect = (item) => (
    <div className={ styles['item-select'] }>{ item.secondLabel }</div>
  );

  render() {
    const {
      documents, employee, enumOfDocuments,
    } = this.props;

    const items = documents.map((document) => {
      let type = '';

      if (employee.Code === 'RU') {
        type = document.Type === enumOfDocuments.DomesticPassport ? 'Паспорт РФ' : 'Заграничный паспорт';
      } else {
        type = 'Паспорт иностранного гражданина';
      }

      const isPatronymic = document.Patronymic ? ` ${document.Patronymic}` : '';
      const passport = `${type} ${document.Surname} ${document.Name}${isPatronymic}`;
      const number = document.DueDate ? ` до ${formatDate(document.DueDate)}` : '';

      return ({
        label: passport,
        secondLabel: `Номер ${document.Number}${number}`,
        anotherLabel: `, ${document.Number}`,
        value: document.Id,
      });
    });

    const documentHtml = (
      <div className={ styles['employee-document'] }>
        <span className={ styles.select }>
          { items[0].label }
          { items[0].anotherLabel ? `${items[0].anotherLabel}` : '' }
        </span>
      </div>
    );

    return (
      <div>
        { documentHtml }
      </div>
    );
  }
}

export default EmployeePassport;
