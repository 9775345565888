import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';

import { FIELDS } from '../../../../../../../../bi/constants/trips';
import {
  DEFAULTTIME, DEFAULTDATE, LOCALERU,
} from '../../../../../../../../constants/time';

import style from '../../../../../../styles/form.module.scss';

const LABELS = {
  CANCELLATION_DATE: 'Дата отмены',
  CANCELLATION_TIME: 'Время отмены',
};

const CancellationDatePicker = ({
  cancellationDate,
  cancellationTime,
  onChangeInput,
  validationInput,
  validation,
  renderError,
  isTime,
}) => {
  const dateFormat = !isTime ? DEFAULTDATE : false;
  const timeFormat = isTime ? DEFAULTTIME : false;

  const value = isTime ? cancellationTime : cancellationDate;

  const type = isTime ? 'CANCELLATION_TIME' : 'CANCELLATION_DATE';

  return (
    <div className={ style['col-1-3'] }>
      <DatePicker
        dateFormat={ dateFormat }
        timeFormat={ timeFormat }
        locale={ LOCALERU }
        value={ value }
        onChange={ (val) => onChangeInput(null, FIELDS[type], val) }
        onBlur={ (e) => validationInput(e, FIELDS[type]) }
        className={ `componentHook ${validation.CancellationDate ? 'no-valid' : ''}` }
      />
      <label>{ LABELS[type] }</label>
      { renderError(FIELDS[type]) }
    </div>
  );
};

CancellationDatePicker.propTypes = {
  cancellationDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  cancellationTime: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
  ]),
  onChangeInput: PropTypes.func.isRequired,
  validationInput: PropTypes.func.isRequired,
  validation: PropTypes.object.isRequired,
  renderError: PropTypes.func.isRequired,
  isTime: PropTypes.bool,
};

CancellationDatePicker.defaultProps = {
  isTime: false,
  cancellationDate: '',
  cancellationTime: 0,
};

export { CancellationDatePicker };
