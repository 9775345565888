import { getMoment } from '../../utils/formatDate';

const deafultPeriodRate = () => ({
  StartLifeTime: getMoment(),
  EndLifeTime: getMoment(),
  RefundableHours: '',
  Price: '',
  Id: 0,
  RateId: 0,
});

const defaultActiveHotelContract = () => ({
  HotelName: '',
  HotelId: '',
  BookingId: '',
  City: '',
  Id: 0,
  CompanyId: '',
  FileId: '',
  Commission: '0',
  IsPercent: 0,
  Start: getMoment(),
  End: getMoment(),
  IsDraft: false,
  Rates: [
    {
      RoomTypeId: '',
      RateName: '',
      BookingRoomNames: [],
      Compares: [],
      Description: '',
      Pax: -1,
      Breakfast: -1,
      Vat: -1,
      VatRate: -1,
      Images: [],
      Periods: [
        {
          Id: 0,
          RateId: 0,
          StartLifeTime: getMoment(),
          EndLifeTime: getMoment(),
          RefundableHours: '',
          Price: '',
        },
      ],
    },
  ],
});

const defaultHotelAutocomplete = () => ({
  label: '',
  selected: {},
  suggests: [],
});

const createNewRate = () => ({
  RoomTypeId: '',
  RateName: '',
  BookingRoomNames: [],
  Compares: [],
  Description: '',
  Pax: -1,
  Breakfast: -1,
  Price: '',
  Vat: -1,
  Images: [],
  Periods: [
    {
      Id: 0,
      RateId: 0,
      StartLifeTime: getMoment(),
      EndLifeTime: getMoment(),
      RefundableHours: '',
      Price: '',
    },
  ],
});

const createDefaultValidation = () => ({
  HotelName: '',
  HotelId: '',
  Rates: [
    {
      Compares: [],
      RoomTypeId: '',
      RateName: '',
      BookingRoomNames: '',
      Description: '',
      Pax: '',
      Breakfast: '',
      Price: '',
      Vat: '',
      Periods: [
        {
          StartLifeTime: '',
          EndLifeTime: '',
          Price: '',
        },
      ],
    },
  ],
  Commission: '',
  IsPercent: '',
  Start: '',
});

const createNewValidationRate = () => ({
  RoomTypeId: '',
  RateName: '',
  Compares: '',
  BookingRoomNames: '',
  Description: '',
  Pax: -1,
  Breakfast: -1,
  RefundableHours: '',
  Price: '',
  Vat: -1,
  StartLifeTime: '',
  EndLifeTime: '',
  Images: '',
  Periods: [],
});

const defaultActiveAviaContract = () => ({
  Iata: -1,
  Number: '',
  IsDiscount: -1,
});

const createDefaultValidationAviaContract = () => ({
  Iata: '',
  Number: '',
  IsDiscount: '',
});

export {
  createNewRate,
  deafultPeriodRate,
  defaultActiveHotelContract,
  defaultHotelAutocomplete,
  createNewValidationRate,
  createDefaultValidation,
  defaultActiveAviaContract,
  createDefaultValidationAviaContract,
};
