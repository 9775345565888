import React from 'react';
import { createRoot } from 'react-dom/client';
import { ClientSession } from './clientSession';

const LABELS = { CLIP: 'Пароль скопирован в буфер обмена' };

const renderComponents = (box, opts) => {
  const { businessService, notificationsService } = opts;

  const handleCopyNotification = () => {
    notificationsService.send({
      header: LABELS.CLIP,
      level: notificationsService.levels.get('SUCCESS'),
    });
  };

  const page = (
    <div>
      <ClientSession
        onWorkplaceSession={ businessService.startWorkplaceClientSession }
        onSmartagentSession={ businessService.startSmartagentClientSession }
        onGeneratePass={ businessService.generateClientSessionPass }
        onCopyNotification={ handleCopyNotification }
      />
    </div>
  );

  const root = createRoot(box);

  root.render(page);

  return root;
};

export default renderComponents;
