import React from 'react';
import PropTypes from 'prop-types';

import ProviderName from '../ProviderName/index';
import { formatDate } from '../../../../bi/utils/formatDate';

import styles from './styles/index.module.css';
import { DEFAULTTIME, FORMATDATETIME } from '../../../../constants/time';

const MAPCARCLASS_RU = {
  All: 'все',
  Undefined: 'не определен',
  Standart: 'стандарт',
  TollRoadStandart: 'стандарт (по платной дороге)',
  Comfort: 'комфорт',
  TollRoadComfort: 'комфорт (по платной дороге)',
  BusinessLite: 'бизнес лайт',
  Business: 'бизнес',
  BusinessComfort: 'бизнес комфорт',
  TollRoadBusiness: 'бизнес (по платной дороге)',
  Minivan: 'минивен',
  Minibus: 'микроавтобус',
  Executive: 'представительский',
};

const getPlaceName = (place, address) => {
  const locationAddress = [place.Name];

  if (address) {
    locationAddress.push(address);
  }

  return locationAddress.join(', ');
};

const TransferItem = ({ item }) => {
  const {
    StartPlace,
    EndPlace,
    DateArrival,
    Class,
    Capacity,
    LocationAddress,
    DestinationAddress,
    ProviderId,
  } = item;

  const startPlace = getPlaceName(StartPlace, LocationAddress);
  const endPlace = getPlaceName(EndPlace, DestinationAddress);
  const carClass = Class || item.CarClass;

  return (
    <div className={ styles.item }>
      <div className={ styles.row }>
        <h3>{ startPlace }</h3>
        <h3 className={ styles.end_h3 }>{ endPlace }</h3>
      </div>
      <div className={ styles.col }>
        <div className={ styles.date_title }>Дата и время подачи:</div>
        <h3>
          { formatDate(DateArrival, DEFAULTTIME) }
          { ' ' }
          в
          { ' ' }
          { formatDate(DateArrival, FORMATDATETIME) }
        </h3>
      </div>
      <div className={ styles.details }>{ `${MAPCARCLASS_RU[carClass]}, количество мест: ${Capacity}` }</div>
      <ProviderName providerName={ ProviderId } />
    </div>
  );
};


TransferItem.propTypes = { item: PropTypes.object.isRequired };

export default TransferItem;
