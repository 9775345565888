import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'sw-ui';

import styles from '../../styles/company.module.scss';

const COMMENT_PLACEHOLDER = 'Введите комментарий не более 1000 символов';

class Comment extends Component {
  static propTypes = {
    text: PropTypes.string,
    update: PropTypes.func,
  };

  static defaultProps = {
    text: '',
    update: () => {},
  };

  state = {
    editMode: false,
    text: '',
  };

  toggleEditMode = () => {
    const { text } = this.props;

    this.setState({
      editMode: !this.state.editMode,
      text,
    });
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ text: value });
  };

  handleSave = () => {
    this.props.update(this.state.text);
    this.toggleEditMode();
  };

  render() {
    const {
      props: { text },
      state: { editMode, text: textFromState },
    } = this;

    if (editMode) {
      return (
        <div className={ styles.comment }>
          <div className={ styles.comment_textarea_wrap }>
            <textarea
              className={ styles.comment_textarea }
              placeholder={ COMMENT_PLACEHOLDER }
              value={ textFromState }
              onChange={ this.handleChange }
            />
          </div>
          <div className={ styles.comment_actions }>
            <div className={ styles.comment_action }>
              <Button
                label='ОТМЕНИТЬ'
                theme='flat'
                onClick={ this.toggleEditMode }
              />
            </div>
            <div className={ styles.comment_action }>
              <Button
                label='СОХРАНИТЬ'
                theme='second'
                onClick={ this.handleSave }
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={ styles.comment }
      >
        <div className={ styles.comment_text }>
          Комментарий:
          { text }
        </div>
        <div className={ styles.comment_action }>
          <Button onClick={ this.toggleEditMode } theme='flat'>РЕДАКТИРОВАТЬ</Button>
        </div>
      </div>
    );
  }
}

export default Comment;
