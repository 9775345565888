import React from 'react';
import PropTypes from 'prop-types';

import { Collapsible } from '../../../../components/Collapsible';
import { OperationItem } from './OperationItem';

import styles from '../../styles/index.module.css';

const TypesServices = ({ serviceOperations }) => (
  <div className={ styles.content }>
    { serviceOperations.map(({ ServiceType, ServiceOperationNames }) => {
      const isOpen = ServiceOperationNames.some((item) => !item.added);

      return (
        <Collapsible
          key={ ServiceType }
          title={ ServiceType }
          wrapperClassname={ styles.collapsible_wrap }
          headerWrapperClassname={ styles.header_collapsible_wrap }
          open={ isOpen }
        >
          <OperationItem
            serviceOperationNames={ ServiceOperationNames }
          />
        </Collapsible>
      );
    }) }
  </div>
);

TypesServices.propTypes = { serviceOperations: PropTypes.array.isRequired };

export { TypesServices };
