let ACTIONS;

const DEFAULT = {
  loading: true,
  user: {},
  carts: [],
  versions: [],
  note: {},
  companies: [],
  projects: [],
  users: [],
  analytics: [],
  userAnalytics: [],
  mandatoryProject: [],
  employeeId: null,
  itemCart: null,
  approvalsList: null,
  showApprovalsDialog: false,
  loadingApprovals: false,
  approvalName: '',
  selectedApprovers: [],
  commentForApprove: '',
  errorApprove: '',
  canCreateCart: false,
  isLoad: false,
  link: [],
  newCartLink: '',
  errorLink: '',
};

const reducer = (action, state) => {
  let newState = { ...state };

  switch (action.type) {
    case ACTIONS.CARTSANDNOTES.SET: {
      newState = { ...newState, ...action.newState };
      break;
    }

    case ACTIONS.CARTSANDNOTES.LOADING: {
      newState.loading = true;
      break;
    }

    case ACTIONS.CARTSANDNOTES.LOADCARTSANDNOTES: {
      newState = {
        ...newState,
        loading: false,
        user: action.user,
        carts: action.carts.Carts,
        versions: action.carts.Carts,
        note: action.note,
        companies: action.companies,
        projects: action.projects,
        users: action.users,
        analytics: action.analytics,
        mandatoryProject: action.mandatoryProject,
        employeeId: action.userInfo.employeeId,
        canCreateCart: action.carts.CanCreateCart,
        isLoad: true,
        link: action.carts.Carts.map(({ IntercomLink }) => IntercomLink || ''),
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.CLEARCARTSANDNOTES: {
      newState = DEFAULT;
      break;
    }

    case ACTIONS.CARTSANDNOTES.CHANGE_CARTS: {
      newState = {
        ...newState,
        versions: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.SET_ANALYTICS: {
      newState = {
        ...newState,
        userAnalytics: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.CHANGE_INTERCOM_LINK: {
      const { link, index } = action.props;

      const newLink = newState.link.map((value, idx) => {
        if (idx === index) return link;

        return value;
      });

      newState = {
        ...newState,
        link: newLink,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.ERROR_INTERCOM_LINK: {
      newState = {
        ...newState,
        errorLink: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.SAVE_INTERCOM_LINK: {
      newState = {
        ...newState,
        newCartLink: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.SET_ITEM_CART: {
      newState = {
        ...newState,
        itemCart: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.APPROVALS_LIST: {
      newState = {
        ...newState,
        approvalsList: action.props,
        loadingApprovals: false,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.SHOW_APPROVALS: {
      newState = {
        ...newState,
        showApprovalsDialog: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.LOADING_APPROVALS: {
      newState = {
        ...newState,
        loadingApprovals: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.APPROVAL_NAME: {
      newState = {
        ...newState,
        approvalName: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.SET_APPROVERS: {
      newState = {
        ...newState,
        selectedApprovers: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.CHANGE_APPROVERS: {
      const { approver, stepId } = action.props;

      const selectedStep = newState.selectedApprovers.find(({ Id }) => Id === stepId);

      if (selectedStep) {
        const updSteps = newState.selectedApprovers.map((item) => {
          const approversList = item.Approvers.filter(({ UserId }) => UserId === approver.UserId).length
            ? item.Approvers.filter(({ UserId }) => UserId !== approver.UserId)
            : [...item.Approvers, approver];

          if (item === selectedStep) {
            return {
              Id: item.Id,
              Approvers: approversList,
            };
          }

          return item;
        });

        newState = {
          ...newState,
          selectedApprovers: updSteps,
          errorApprove: '',
        };
      } else {
        newState = {
          ...newState,
          selectedApprovers: [
            ...newState.selectedApprovers,
            {
              Id: stepId,
              Approvers: [approver],
            },
          ],
          errorApprove: '',
        };
      }
      break;
    }

    case ACTIONS.CARTSANDNOTES.COMMENT_FOR_APPROVE: {
      newState = {
        ...newState,
        commentForApprove: action.props,
      };
      break;
    }

    case ACTIONS.CARTSANDNOTES.ERROR_APPROVE: {
      newState = {
        ...newState,
        errorApprove: action.props,
      };
      break;
    }
  }

  return newState;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');
  core.addStore('CartsAndNotes', reducer, DEFAULT);
}
