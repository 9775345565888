import React from 'react';
import PropTypes from 'prop-types';
import { Button, Loading } from 'sw-ui';

import { LABELS } from './index';
import { LOADING_STATUS } from '../../../../../bi/stores/trip';

import styles from './styles.module.css';

export const FoundTrip = ({
  loading, tripId, trip, onClose, tripService,
}) => {
  switch (loading) {
    case LOADING_STATUS.NEVER:
      return null;
    case LOADING_STATUS.LOADING:
      return <Loading size='small' />;
    case LOADING_STATUS.ERROR:
      return LABELS.ERROR(tripId);
    case LOADING_STATUS.LOADED: {
      return (
        <React.Fragment>
          <div className={ styles.matchTrip }>
            <div>
              { `${trip.TripName} ` }
              <span className={ styles.tripId }>{ `#${trip.TripIdToMerge}` }</span>
            </div>
            <div>
              { LABELS.DATE_CREATED(trip.CreationDate) }
            </div>
          </div>
          <div className={ styles.bottom }>
            <Button theme='flat' onClick={ onClose }>
              { LABELS.CANCEL }
            </Button>
            <Button theme='main' onClick={ tripService.mergeTrips }>
              { LABELS.CONFIRM }
            </Button>
          </div>
        </React.Fragment>
      );
    }
  }
};

FoundTrip.propTypes = {
  tripId: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
  trip: PropTypes.shape({
    TripName: PropTypes.string.isRequired,
    TripIdToMerge: PropTypes.string.isRequired,
    CreationDate: PropTypes.string.isRequired,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  tripService: PropTypes.object.isRequired,
};
