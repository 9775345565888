import ACTIONS from '../../actions';
import Api from '../../api';

let store = null;

class EmployeesService {
  constructor() {
    store = this.getStore('Employees');
  }

  searchEmployees = (value) => Api.Employees.searchEmployees(value).then((res) => store.dispatch({
    type: ACTIONS.EMPLOYEES.UPDATE,
    res,
  }))
    .catch(() => store.dispatch({
      type: ACTIONS.EMPLOYEES.UPDATE,
      res: [],
    }));

  get = () => store.getState();

  subscribe = (callback) => store.subscribe(callback);
}

export default EmployeesService;
