import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../components/input';
import Button from '../../../components/button/Button';

import styles from '../styles/imgInput.module.css';

import { TEXT } from '../../../bi/constants/hotels';
import { isUrl } from '../../../utils/validations';

class ImgInput extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    onClose: () => {},
  };

  state = {
    inputValue: '',
    inValidText: '',
    disabledAddButton: true,
  };

  handleChangeInput = (inputValue) => {
    const isValid = isUrl(inputValue);

    this.setState({
      inputValue,
      inValidText: isValid ? '' : TEXT.INPUT_URL_VALID,
      disabledAddButton: !isValid,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { inputValue } = this.state;
    const { onSubmit, onClose } = this.props;
    onSubmit(e, inputValue);

    this.setState({ inputValue: '' });
    onClose();
  };

  render() {
    const {
      inputValue, inValidText, disabledAddButton,
    } = this.state;
    const { disabled } = this.props;

    return (
      <form className={ styles.wrap } onSubmit={ this.handleSubmit }>
        <div className={ styles.img_input_wrpap }>
          <Input
            value={ inputValue }
            field=''
            label={ TEXT.INPUT_URL_IMG }
            onChange={ ({ target: { value } }) => this.handleChangeInput(value) }
            valid={ inValidText }
            disabled={ disabled }
          />
        </div>
        <div className={ styles.img_button_wrpap }>
          <Button
            label={ TEXT.ADD_IMG }
            type='submit'
            disabled={ disabled || disabledAddButton }
          />
        </div>
      </form>
    );
  }
}

export default ImgInput;
