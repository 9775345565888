import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { RadioButton } from 'sw-ui';

import {
  FIELDS, TEXT, VAT_VALUE,
} from '../../../../bi/constants/hotels';

import Input from '../../../../components/input';
import { TYPE_ICON } from '../../../../components/AddButton/consts';
import { AddButton } from '../../../../components/AddButton';
import { FlatButton } from '../../../../components/button';

import styles from './style.module.css';

class Contract extends Component {
  static propTypes = {
    contract: PropTypes.object,
    isNoEdit: PropTypes.bool.isRequired,
    onAddContract: PropTypes.func.isRequired,
    onChangeContractField: PropTypes.func.isRequired,
    onDeleteContract: PropTypes.func.isRequired,
    setRef: PropTypes.func,
  };

  static defaultProps = {
    contract: null,
    setRef: () => {},
  };

  renderEmail = () => {
    const {
      contract, isNoEdit, onChangeContractField,
    } = this.props;
    const contentStyle = [styles.item_content, styles.email].join(' ');

    return (
      <div className={ styles.item }>
        <div className={ styles.item_title }>
          { ' ' }
          { TEXT.HOTEL_CONTRACT.EMAIL }
        </div>
        <div className={ contentStyle }>
          <Input
            type='string'
            disabled={ isNoEdit }
            value={ contract.Email }
            field={ FIELDS.HOTEL_CONTRACT.EMAIL }
            onChange={ (e, field, value) => onChangeContractField(field, value) }
          />
        </div>
      </div>
    );
  };

  renderVat = () => {
    const {
      isNoEdit, contract: { HasVAT }, onChangeContractField,
    } = this.props;

    return (
      <div className={ styles.vat }>
        <div className={ styles.vat_title }>{ TEXT.HOTEL_CONTRACT.SUB_WORKS_WITH_VAT.toUpperCase() }</div>
        <div className={ styles.vat_content }>
          <RadioButton
            disabled={ isNoEdit }
            checked={ HasVAT }
            onChange={ (value) => onChangeContractField('HasVAT', value) }
          >
            { VAT_VALUE.YES }
          </RadioButton>
          <RadioButton
            disabled={ isNoEdit }
            checked={ !HasVAT }
            onChange={ (value) => onChangeContractField('HasVAT', !value) }
          >
            { VAT_VALUE.NO }
          </RadioButton>
        </div>
      </div>
    );
  };

  renderDiscount = () => {
    const {
      contract, isNoEdit, onChangeContractField,
    } = this.props;

    const label = TEXT.HOTEL_CONTRACT.DISCOUNT.toUpperCase();

    return (
      <div className={ styles.item }>
        <div className={ styles.item_title }>
          { ' ' }
          { label }
        </div>
        <div className={ styles.item_content }>
          <Input
            type='number'
            disabled={ isNoEdit }
            value={ contract.Discount }
            field={ FIELDS.HOTEL_CONTRACT.DISCOUNT }
            onChange={ (e, field, value) => onChangeContractField(field, value) }
          />
        </div>
        <span>%</span>
      </div>
    );
  };

  renderCommon = () => (
    <div className={ styles.content }>
      { this.renderEmail() }
      { this.renderDiscount() }
      { this.renderVat() }
    </div>
  );

  renderAddButton = () => {
    const { isNoEdit, onAddContract } = this.props;

    return (
      <AddButton
        icon={ TYPE_ICON.ADD }
        isNoEdit={ isNoEdit }
        text={ isNoEdit ? TEXT.EDIT_INFO : TEXT.HOTEL_CONTRACT.ADD_CONTRACT }
        onClick={ onAddContract }
      />
    );
  };

  renderDeleteButton = () => {
    const {
      isNoEdit, onDeleteContract, contract,
    } = this.props;

    return (
      <div className={ styles.delete_button }>
        <FlatButton
          disabled={ isNoEdit }
          label={ TEXT.HOTEL_CONTRACT.DELETE_CONTRACT }
          onClick={ () => onDeleteContract(contract.HotelId) }
        />
      </div>
    );
  };

  render() {
    const { setRef, contract } = this.props;

    const addButtoHtml = !contract && this.renderAddButton();
    const deleteButtoHtml = contract && contract.HotelId && this.renderDeleteButton();
    const commonHtml = contract && this.renderCommon();

    return (
      <div className={ styles.wrapper } ref={ (ref) => setRef(ref) } >
        <div className={ styles.header }>
          <h2>{ TEXT.HOTEL_CONTRACT.TITLE }</h2>
          { addButtoHtml }
          { deleteButtoHtml }
        </div>
        { commonHtml }
        <div />
      </div>
    );
  }
}
export { Contract };
