import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  isMoreThanThirtyOneDays,
  isAfterDate,
  formatDate,
  getLastDayOfMonth,
  diffDays,
  getStartDateDocumenents,
  getEndDateDocumenents,
  getMoment,
  isMoment,
} from '../../../../bi/utils/formatDate';

import SEARCH from '../../../../bi/constants/search';
import { REPORT_COMPANIES } from '../../../../bi/constants/reports';
import { DAYPATTERN } from '../../../../constants/time';

import SearchDocumentForPrint from '../../../../components/SearchDocumentsForPrints';

import styles from '../../styles/report.module.scss';

const DATES = {
  FIRST: 'начало',
  SECOND: 'конец',
};

const UPDATEINTERVAL = 5000;

const DAYS = {
  START_MONTH: 1,
  HALF_MONTH: 15,
  END_MONTH: (value) => value,
};

const ERROR = {
  DATES: (firstDay, secondDay, date) => `${date} периода может быть только ${firstDay} или ${secondDay} число месяца`,
  DIFF: `Период между датами должен быть не более ${DAYS.HALF_MONTH} дней`,
  SAME: 'Даты должны быть разными',
  COMPANY: 'Диапазон компаний не выбран',
  LOADING: (Progress) => `Подождите идёт формирование документа: ${Progress} %`,
};

class DocumentsForPrintsReport extends Component {
  static propTypes = {
    businessService: PropTypes.object.isRequired,
    nameList: PropTypes.string,
    maxDate: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    labelButton: PropTypes.string,
    disabledButton: PropTypes.bool,
    onDownload: PropTypes.func.isRequired,
    checkStatus: PropTypes.func.isRequired,
    dateStartPickerLabels: PropTypes.string,
    dateEndPickerLabels: PropTypes.string,
    renderOption: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  static defaultProps = {
    maxDate: null,
    startDate: getStartDateDocumenents(DAYS.HALF_MONTH),
    endDate: getEndDateDocumenents(DAYS.HALF_MONTH),
    nameList: '',
    labelButton: SEARCH.SEND,
    renderOption: null,
    disabledButton: false,
    dateStartPickerLabels: SEARCH.START_DATE,
    dateEndPickerLabels: SEARCH.END_DATE,
  };

  constructor(props) {
    super(props);
    const { responseProgress, responseStatus } = props.businessService.get();

    this.state = {
      waitingResponse: false,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      validateSubmit: true,
      tooltipMsg: '',
      selected: null,
      maxDateLimit: getMoment(),
      responseProgress,
      responseStatus,
    };
  }

  componentDidMount() {
    const { businessService } = this.props;

    this.startHandleCheckStatus();
    this.unsubscribe = businessService.subscribe(this.updateState);
    this.interval = setInterval(this.handleCheckStatus, UPDATEINTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.unsubscribe();
  }

  updateState = ({ responseProgress, responseStatus }) => this.setState({
    responseProgress,
    responseStatus,
  });

  startHandleCheckStatus = async () => {
    const { checkStatus } = this.props;

    await checkStatus();
  };

  handleCheckStatus = async () => {
    const { responseStatus } = this.state;

    if (responseStatus === 1) {
      const { checkStatus } = this.props;

      await checkStatus();
    }
  };

  sendingRequestCreateReport = async () => {
    const { selected } = this.state;

    const currentCompanyRange = REPORT_COMPANIES.find((el) => el.value === selected);

    if (currentCompanyRange) {
      const { onDownload } = this.props;
      const { startDate, endDate } = this.state;

      await onDownload(startDate, endDate, currentCompanyRange.companyStart, currentCompanyRange.companyEnd);
    }
  };

  handleChangeDate = (field, value) => {
    if (!isMoment(value)) {
      return;
    }

    const { endDate } = this.state;

    if (isAfterDate(value, endDate)) {
      this.setState({ endDate: value });
    }

    return this.setState({ [field]: value });
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  validate = () => {
    const {
      startDate, endDate, selected, responseStatus, responseProgress,
    } = this.state;

    const startDay = Number(formatDate(startDate, DAYPATTERN));
    const endDay = Number(formatDate(endDate, DAYPATTERN));
    const endMonth = Number(getLastDayOfMonth(endDate).date());
    const diff = diffDays(startDate, endDate);
    const validateObj = { error: '' };

    if (diff > DAYS.HALF_MONTH + 1) {
      validateObj.error = ERROR.DIFF;

      return validateObj;
    }

    if (startDay !== DAYS.START_MONTH && startDay !== DAYS.HALF_MONTH + 1) {
      validateObj.error = ERROR.DATES(DAYS.START_MONTH, DAYS.HALF_MONTH + 1, DATES.FIRST);

      return validateObj;
    }

    if (endDay !== DAYS.HALF_MONTH && endDay !== DAYS.END_MONTH(endMonth)) {
      validateObj.error = ERROR.DATES(DAYS.HALF_MONTH, DAYS.END_MONTH(endMonth), DATES.SECOND);

      return validateObj;
    }

    if (diff === 0) {
      validateObj.error = ERROR.SAME;

      return validateObj;
    }

    if (selected === null) {
      validateObj.error = ERROR.COMPANY;

      return validateObj;
    }

    if (responseStatus === 1) {
      validateObj.error = ERROR.LOADING(responseProgress);

      return validateObj;
    }

    return validateObj;
  };

  handleChangeSelect = (value) => {
    this.setState({ selected: value.value });
  };

  renderEmpty = () => {
    const { nameList } = this.props;

    return nameList && nameList.length && (
      <div className={ styles.content }>
        <div className='empty-list'>{ nameList }</div>
      </div>
    );
  };

  render() {
    const {
      waitingResponse, startDate, endDate, selected, maxDateLimit,
    } = this.state;
    const {
      maxDate, labelButton, disabledButton, renderOption, dateStartPickerLabels, dateEndPickerLabels,
    } = this.props;
    const errorMsg = this.validate().error;
    const disabled = errorMsg.length > 0 || (disabledButton && isMoreThanThirtyOneDays(startDate, endDate));
    const optionHtml = renderOption || this.renderEmpty();

    return (
      <div className={ styles.main }>
        <div className={ styles.header }>
          <SearchDocumentForPrint
            labelButton={ labelButton }
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDate }
            disabled={ disabled }
            waitingResponse={ waitingResponse }
            tooltipMsg={ errorMsg }
            dateStartPickerLabels={ dateStartPickerLabels }
            dateEndPickerLabels={ dateEndPickerLabels }
            onChangeDate={ this.handleChangeDate }
            onDownload={ this.sendingRequestCreateReport }
            onChangeSelectedValue={ this.handleChangeSelect }
            selectedValue={ selected }
            maxDateLimit={ maxDateLimit }
          />
        </div>
        { optionHtml }
      </div>
    );
  }
}

export default DocumentsForPrintsReport;
