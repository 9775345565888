import React, { Component } from 'react';
import PropTypes from 'prop-types';

import COMPONENTS from '../../bi/constants/components';
import { TYPERATRATES } from '../../bi/constants/account';

import styles from './styles/index.module.css';

const LABELS = {
  CHOOSE: 'выбрать',
  ENTERVALUE: 'введите значение',
};

class SelectWithInput extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    items: PropTypes.array.isRequired, // { type, value, label }
    currentItem: PropTypes.object.isRequired, // { type, value, label }
    open: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    typeInput: PropTypes.string,
    label: PropTypes.string,
    valid: PropTypes.string,
    onScroll: PropTypes.bool, // работает при длине масиива 8 и больше
  };

  static defaultProps = {
    label: '',
    valid: '',
    open: false,
    placeholder: '',
    onScroll: false,
    typeInput: COMPONENTS.INPUT.TYPE.NUMBER,
  };

  constructor(props) {
    super();

    const {
      currentItem: { value }, items, open, placeholder,
    } = props;

    const foundItem = items.find(({ type }) => type === TYPERATRATES.OPTIONAL);

    this.state = {
      value: value || 0,
      inputValue: `${foundItem.value || ''}`,
      open,
      check: false,
      placeholder,
    };
  }

  toggleOpenSelect = () => this.setState({ open: !this.state.open });

  handleSelect = (item) => {
    const { inputValue } = this.state;
    const { type, value } = item;

    const typeOptional = type === TYPERATRATES.OPTIONAL;
    const preparedValue = typeOptional ? Number(inputValue) : value;

    this.setState({
      value: preparedValue,
      placeholder: '',
    }, () => {
      const preparedItem = { ...item };

      if (typeOptional) {
        preparedItem.value = Number(inputValue);
        preparedItem.label = inputValue;
      }

      this.props.onChange(preparedItem);
      this.toggleOpenSelect();
    });
  };

  handleChangeInput = (value) => this.setState({
    inputValue: value,
    check: !!value,
  });

  renderInput = (item) => {
    const { inputValue, check } = this.state;
    const { typeInput, onScroll } = this.props;

    const styleTextCheck = () => {
      if (typeInput === COMPONENTS.INPUT.TYPE.TEXT && onScroll) {
        return `${styles.text_check}`;
      }

      return '';
    };

    const checkHtml = check && (
      <i
        onClick={ () => this.handleSelect(item, inputValue) }
        className={ `${styles.icon_check} material-icons ${styleTextCheck()}` }
      >
        done
      </i>
    );

    return (
      <div key={ `${item.id}` } className={ `${styles.item} ${styles.optional}` }>
        <input
          placeholder={ LABELS.ENTERVALUE }
          className={ styles.input }
          type={ typeInput }
          value={ inputValue }
          onChange={ ({ target: { value } }) => this.handleChangeInput(value) }
          onBlur={ () => this.handleSelect(item, inputValue) }
        />
        { checkHtml }
      </div>
    );
  };

  renderItem = (item) => {
    const {
      type, id, label,
    } = item;

    return (type === TYPERATRATES.OPTIONAL
      ? this.renderInput(item)
      : (
        <div
          key={ id }
          className={ styles.item }
          onClick={ () => this.handleSelect(item) }
        >
          { label }
        </div>
      ));
  };

  renderList = () => {
    const {
      onScroll, items, currentItem: { id },
    } = this.props;

    const list = [];

    const stylesScroll = onScroll ? `${styles.list_scroll}` : '';

    items.forEach((item) => {
      if (this.state.placeholder.length || item.type === TYPERATRATES.OPTIONAL || item.id !== id) {
        list.push(item);
      }
    });

    return (
      <div className={ `${styles.list} ${stylesScroll}` }>
        { list.map((item) => this.renderItem(item)) }
      </div>
    );
  };

  render() {
    const { currentItem: { label }, valid } = this.props;
    const { open } = this.state;
    const { placeholder } = this.props;

    const listHtml = open && this.renderList();
    const openStyles = open ? styles.open : '';

    const labelHtml = !label
      ? (
        <div className={ styles.placeholder }>{ placeholder }</div>
      )
      : (
        <div className={ styles.label }>{ label }</div>
      );

    const validHtml = !!valid.length && <span className={ styles['error-msg'] }>{ valid }</span>;

    return (
      <div className={ styles.wrapper_block }>
        <label>{ this.props.label }</label>
        <div className={ styles.wrap }>
          <div className={ styles.header } onClick={ this.toggleOpenSelect }>
            { labelHtml }
            <i className={ `${styles.icon} material-icons ${openStyles}` }>keyboard_arrow_down</i>
          </div>
          { listHtml }
        </div>
        { validHtml }
      </div>
    );
  }
}

export default SelectWithInput;

