import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import styles from './styles.module.css';

const DIALOGWIDTHPX = '310px';

const LABELS = { CLOSE: 'Закрыть' };

const ErrorDialog = ({
  onClick = () => {},
  error = '',
}) => (
  <Dialog
    onClick={ onClick }
    width={ DIALOGWIDTHPX }
  >
    <div className={ styles.dialog }>
      <div>{ error }</div>
      <div className={ styles.dialog_button_wrapper }>
        <Button
          className={ styles.dialog_button }
          onClick={ onClick }
        >
          { LABELS.CLOSE }
        </Button>
      </div>
    </div>
  </Dialog>
);

ErrorDialog.propTypes = {
  onClick: PropTypes.func,
  error: PropTypes.string,
};

ErrorDialog.defaultProps = {
  onClick: () => {},
  error: '',
};

export default ErrorDialog;
