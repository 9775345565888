import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';
import { BONUSES } from '../../../../bi/constants/statistic';
import COMPONENTS from '../../../../bi/constants/components';

import CommentComponent from '../../../../components/Comment';

import styles from '../../styles/bonuses.module.css';

class Bonuses extends Component {
  static propTypes = {
    comment: PropTypes.string,
    onSave: PropTypes.func.isRequired,
  };

  static defaultProps = { comment: '' };

  state = {
    waitingResponse: false,
    editing: false,
  };

  toggleEditing = () => this.setState({
    waitingResponse: false,
    editing: !this.state.editing,
    comment: this.props.comment,
  });

  handleChange = (value) => {
    const maxValue = BONUSES.MAX_COMMENT_LENGTH;

    if (!maxValue || value.length < maxValue) {
      this.setState({ comment: value });
    }
  };

  handleSave = async () => {
    const { onSave } = this.props;
    const { comment } = this.state;

    this.setState({ waitingResponse: true });

    try {
      await onSave(comment);
    } finally {
      this.toggleEditing();
    }
  };

  renderComment = () => {
    const { comment } = this.props;

    if (!comment) return BONUSES.NO_COMMENT_TEXT;

    const mappedText = comment.split('\n').map((string, index) => <p key={ index } className={ styles['comment-text'] }>{ string }</p>);

    return (
      <div className={ styles.value }>
        <b>{ mappedText }</b>
      </div>
    );
  };

  renderEditText = () => {
    const { waitingResponse } = this.state;
    const { comment } = this.props;

    return (
      <CommentComponent
        value={ comment }
        placeholder={ BONUSES.INPUT_COMMENT }
        limit={ BONUSES.MAX_COMMENT_LENGTH }
        waitingResponse={ waitingResponse }
        onChange={ this.handleChange }
        onClose={ this.toggleEditing }
        onSave={ this.handleSave }
      />
    );
  };

  renderText = () => (
    <div className={ styles.row }>
      <span className={ styles.name }>
        { BONUSES.LABEL }
        :
      </span>
      { this.renderComment() }
      <Button
        label={ ACCOUNTTEXT.EDIT }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ this.toggleEditing }
        className={ styles['edit-button'] }
      />
    </div>
  );

  render() {
    return this.state.editing ? this.renderEditText() : this.renderText();
  }
}

export { Bonuses };
