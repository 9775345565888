import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CircularLoaders from '../../components/loaders';

import styles from './styles/index.module.scss';

const EMPTYLIST = 'Нет пользователей';

class SearchUsersComponents extends Component {
  static propTypes = {
    searchUsersService: PropTypes.object.isRequired,
    toUserCartsAndNote: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      list, label, value,
    } = props.searchUsersService.get();

    this.state = {
      loading: false,
      list,
      label,
      value,
    };
  }

  componentDidMount() {
    const { searchUsersService } = this.props;

    this.unsubscribeSearchUsersServiceCb = searchUsersService.subscribe(this.updateSearchUsers.bind(this));
  }

  componentWillUnmount() {
    this.props.searchUsersService.unsetSearchUsers();

    if (this.unsubscribeSearchUsersServiceCb) this.unsubscribeSearchUsersServiceCb();
  }

  updateSearchUsers = () => {
    const { searchUsersService } = this.props;
    const { value, list } = searchUsersService.get();

    if (this.state.value !== value) {
      searchUsersService.searchUsers(value);

      this.setState({
        value,
        loading: true,
      });
    } else if (list.length !== 0) {
      this.setState({
        list,
        loading: false,
      });
    }
  };

  handleClickOnUser = (user) => {
    this.props.toUserCartsAndNote(user);
  };

  renderLoading = () => (
    <CircularLoaders wrapClassName='content-loader-wrap' />
  );

  renderDefaultMessage = () => (
    <div className='empty-list'>
      { this.state.label }
    </div>
  );

  renderEmptyList = () => (
    <div className={ styles['empty-list'] }>
      { EMPTYLIST }
    </div>
  );

  renderList = () => {
    const renderListCompanies = (user) => user.Companies.map((company, idx, companies) => (
      <span key={ company.CompanyId } className={ styles.company }>
        {
          companies.length === idx + 1 ? company.CompanyName : `${company.CompanyName},\u00A0\u00A0\u00A0`
        }
      </span>
    ));

    const listHtml = this.state.list.map((user) => (
      <div className={ `${styles.row} ${styles.click}` } key={ user.Id } onClick={ () => this.handleClickOnUser(user) }>
        <div className={ styles['col-1-5'] }>{ user.UserName }</div>
        <div className={ `${styles['col-4-5']} ${styles.companies}` }>{ renderListCompanies(user) }</div>
      </div>
    ));

    return (
      <div className={ styles.panel }>
        <div className={ styles.form }>
          <div className={ styles.row }>
            <div className={ styles['col-1-5'] }>
              <label>Имя</label>
            </div>
            <div className={ styles['col-4-5'] }>
              <label>Компании</label>
            </div>
          </div>
          { listHtml }
        </div>
      </div>
    );
  };

  render() {
    const {
      loading, list, value,
    } = this.state;
    const isSearch = Boolean(value);
    const result = !loading && isSearch;

    if (loading) return this.renderLoading();
    if (!loading && !list.length && !isSearch) return this.renderDefaultMessage();

    let html = null;
    if (result && list.length) {
      html = this.renderList();
    } else if (result && !list.length) {
      html = this.renderEmptyList();
    }

    return (
      <div className={ `${styles.wrap} ${result && !list.length ? styles['wrap-empty'] : ''}` }>
        { html }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <SearchUsersComponents
      searchUsersService={ opts.searchUsersService }
      toUserCartsAndNote={ opts.toUserCartsAndNote }
    />,
  );

  return root;
};

export default renderComponents;
