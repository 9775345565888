import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Checkbox, Input,
} from 'sw-ui';

import CircularLoaders from '../../../../components/loaders';
import DecisionButtons from '../../../../components/DecisionButtons';

import ACCOUNTING from '../../../../bi/constants/accounting';

import styles from '../../styles/form.module.css';

const DIALOGSIZE = '400px';

class LimitAmountForm extends Component {
  static propTypes = {
    loading: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    amount: PropTypes.number,
    companyService: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = { amount: null };

  constructor(props) {
    super();

    const { enabled, amount } = props;

    this.state = {
      enabled,
      amount: amount !== null ? String(amount) : '',
      validation: '',
      waitingResponse: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { amount, enabled } = this.props;

    if (amount !== prevProps.amount && enabled !== prevProps.enabled) {
      this.fetchData(enabled, amount);
    }
  }

  componentWillUnmount() {
    this.props.companyService.clearLimitAmount();
  }

  fetchData = (enabled, amount) => this.setState({
    enabled,
    amount: amount !== null ? String(amount) : '',
  });

  toggleRestriction = (value) => {
    this.setState({
      enabled: value,
      validation: !value ? '' : this.state.validation,
      amount: !value ? '' : this.state.amount,
    });
  };

  validationField = (amount) => (
    this.props.companyService.accountingFieldValidation(ACCOUNTING.VALIDATION.AMOUNT, amount)
  );

  validateAmountInput = (amount) => this.setState({ validation: this.validationField(amount) });

  handleSave = () => {
    const { enabled, amount } = this.state;

    const isValid = this.validationField(amount);

    if (isValid && enabled) {
      return this.validateAmountInput(amount);
    }

    const data = enabled ? amount : null;

    return this.setState({ waitingResponse: true }, () => this.props.onSave(data));
  };

  handleChangeAmount = (amount) => {
    const number = Number(amount);
    const str = !isNaN(number) ? String(number) : '';

    this.setState({
      amount: str,
      validation: this.validationField(str),
    });
  };

  renderLimitAmount = () => {
    const { onClose } = this.props;
    const {
      enabled, amount, validation, waitingResponse,
    } = this.state;

    return (
      <div>
        <div className={ styles.title }>Доступные средства</div>
        <div className={ styles.content }>
          <Checkbox value={ enabled } onChange={ this.toggleRestriction }>
            <span>Показывать рядовым сотрудникам сумму не более указанной</span>
          </Checkbox>
          <div className={ styles.content }>
            <Input
              placeholder='100 000'
              readOnly={ !enabled }
              value={ amount }
              onChange={ this.handleChangeAmount }
              onBlur={ () => this.validateAmountInput(amount) }
              error={ validation }
            />
          </div>
        </div>
        <DecisionButtons
          waitingResponse={ waitingResponse }
          onSave={ this.handleSave }
          onCancel={ onClose }
        />
      </div>
    );
  };

  renderLoading = () => <CircularLoaders wrapClassName='content-loader-wrap' />;

  render() {
    const { loading, onClose } = this.props;

    const html = loading ? this.renderLoading() : this.renderLimitAmount();

    return (
      <Dialog onClick={ onClose } width={ DIALOGSIZE }>
        <div className={ `${styles.form} ${styles['form-dialog']}` }>
          { html }
        </div>
      </Dialog>
    );
  }
}

export default LimitAmountForm;
