const ACCOUNT = {
  INIT: 'init',

  LOADACCOUNT: 'load.account',
  LOAD_SPECIAL_BILL: 'load.special.bill',
  LOAD_COMPANIES_GROUP: 'load.companies.group',
  UPDATEACCOUNT: 'update.account',
  UPDATECOMPANIES: 'updateCompanies',
  UPDATERATES: 'update.rates',
  LOADCOMPANYFUNDS: 'load.company.funds',
  LOADACCOUNTFUNDS: 'load.account.funds',
  LOAD_ACCOUNT_SETTINGS: 'load.accountSettings',
  UPDATE_LOADING_ACCOUNT_SETTINGS: 'update.loading.accountSettings',
  LOADING_LIMITS: 'update.loading.limits',
  UPDATEPLANFEESETTINGS: 'update.account.planFeeSettings',
  GET_AMO_ID: 'get.amo.id',
  ACCOUNT_DISABLED: 'update.accountDeisabled.loader',
  SHOW_PLAN_FEE_SETTING_DIALOG: 'showPlanFeeSettingsDialog',
  SHOW_ACCOUNT_SETTINGS_DIALOG: 'showAccountSettingsDialog',
  SHOW_ESTABLISHED_LIMITS_DIALOG: 'showEstablishedLimitsDialog',
  LOAD_ACCOUNT_FOR_ESTABLISHED_LIMITS: 'loadAccountForEstablishedLimits',
  UPDATE_PLAN_FEE_SETTINGS: 'updatePlanFeeSettings',
  REMOVE_PLAN_FEE_SETTINGS: 'removePlanFeeSettings',
  ADD_PLAN_FEE_SETTING_ENTITY: 'addPlanFeeSettingsEntity',
  CLOSE_PLAN_FEE_SETTINGS: 'closePlanFeeSettings',
  UPDATE_ACCOUNT_SETTINGS: 'updateAccountSettings',
  CLOSE_ACCOUNT_SETTINGS: 'closeAccountSettings',
  HIDE_ACCOUNT_SETTINGS: 'hideAccountSettings',
  LOAD_AGGREGATION: 'load.aggregation',
  LOAD_AGGREGATION_AIRLINE: 'load.aggregationAirline',
  UPDATE_AGGREGATION: 'update.aggregation',
  UPDATE_AGGREGATION_AIRLINE: 'update.aggregationAirline',
  COMPANIES_IN_ACCOUNT: 'companiesInAccount',

  UPDATE_HOTEL_AGGREGATION: 'update.hotelAggregation',
  UPDATE_HIDE_TYPES_HOTEL_AGGREGATION: 'update.hideTypesHotelAggregation',
  ADD_HOTELS: 'update.addHotels',
  DELETE_HOTELS: 'update.deleteHotels',
  UPDATE_LOADING_HOTEL_AGGREGATION: 'update.loadingHotelAggregation',

  UPDATE_SMART_AGENT: 'update.smart.agent',
  UPDATE_SMART_AGENT_AUTO: 'update.smart.agent.auto',
  UPDATE_SMARTWAY_KZ: 'update.smartway.kz',

  CHANGE_CURRENCY_INFO: 'changeCurrencyInfo',
  CHANGE_EXCHANGE_CURRENCY: 'changeExchangeCurrency',
  CHANGE_PRICES_CURRENCY: 'changePricesCurrency',
  ADD_PENALTIES_CURRENCY: 'addPenaltiesCurrency',
  UPDATE_PENALTIES_CURRENCY: 'updatePenaltiesCurrency',
  REMOVE_PENALTY_CURRENCY: 'removePenaltyCurrency',
  RESET_FORM_CURRENCY: 'resetFromCurrency',
  CHANGE_LEAD_TYPE: 'changeLeadType',

  SET_AIRLINE_AGGREGATION: 'set.airline.aggregation',
};

export default ACCOUNT;
