import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ProviderName from '../ProviderName/index';
import {
  timeWithoutMoment, dateWithoutMoment, weekWithoutMoment,
} from '../../../../bi/utils/formatDate';

import styles from './styles/index.module.css';

class TrainItem extends Component {
  static propTypes = { item: PropTypes.object.isRequired };

  renderPlaneNum = (places) => {
    let result = null;

    if (Array.isArray(places)) {
      const leftPlace = places[0];
      const rightPlace = places[1];

      result = [];

      if (leftPlace) {
        const leftPlaceStr = isNaN(leftPlace)
          ? `${parseInt(leftPlace.substring(0, leftPlace.length - 1), 10)}${leftPlace.charAt(leftPlace.length - 1)}`
          : parseInt(leftPlace, 10);
        result.push(leftPlaceStr);
      }
      if (rightPlace) {
        const rightPlaceStr = isNaN(rightPlace)
          ? `${parseInt(rightPlace.substring(0, rightPlace.length - 1), 10)}${rightPlace.charAt(rightPlace.length - 1)}`
          : parseInt(rightPlace, 10);
        result.push(rightPlaceStr);
      }

      result = result.join('–');
    }

    return result || places;
  };

  render() {
    const { item } = this.props;

    const classService = item.ClassService ? `(${item.ClassService})` : '';
    const placeNumber = this.renderPlaneNum(item.PlaceNumber);
    const typeHtml = item.CarTypeDescription
      ? (
        <div className={ styles.type }>
          <b>{ item.CarTypeDescription }</b>
        </div>
      )
      : null;

    return (
      <div className={ styles.item }>
        <div className={ styles['item-train'] }>
          <div className={ `${styles.col} ${styles.col_departure}` }>
            <h3 className={ styles['col_departure--h3'] }>{ item.StationDepart }</h3>
            <div className={ `${styles.col} ${styles['col_departure--info']}` }>
              <h3 className={ styles.time }>{ timeWithoutMoment(item.DateDeparture) }</h3>
              <div className={ styles.date }>
                { `${dateWithoutMoment(item.DateDeparture)}, ` }
                <span className={ styles.capitalize }>{ weekWithoutMoment(item.DateDeparture) }</span>
              </div>
            </div>
          </div>
          <div className={ `${styles.col} ${styles.col_train}` }>
            <div>
              Поезд №:
              <span className={ styles['main-text'] }>
                { ' ' }
                { item.TrainNumber }
              </span>
            </div>
            <div className={ styles.details }>
              Вагон №:
              <span className={ styles['main-text'] }>
                { ' ' }
                { item.CarNumber }
              </span>
              { ' ' }
              { classService }
            </div>
            <div>
              Место:
              <span className={ styles['main-text'] }>
                { ' ' }
                { placeNumber }
              </span>
            </div>
          </div>
          <div className={ `${styles.col} ${styles.col_arrival}` }>
            <h3 className={ styles['col_arrival--h3'] }>{ item.StationArrive }</h3>
            <div className={ `${styles.col} ${styles['col_arrival--info']}` }>
              <h3 className={ styles.time }>{ timeWithoutMoment(item.DateArrive) }</h3>
              <div className={ styles.date }>
                { `${dateWithoutMoment(item.DateArrive)}, ` }
                <span className={ styles.capitalize }>{ weekWithoutMoment(item.DateArrive) }</span>
              </div>
            </div>
          </div>
        </div>
        <ProviderName providerName={ item.ProviderName } />
        { typeHtml }
      </div>
    );
  }
}

export default TrainItem;
