import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';

import { DraggableItem } from '../../../../components/DraggableItem';
import { DropZone } from '../../../../components/DropZone';

import { PARAMS, OTHER_PARAMS } from '../../../../bi/constants/documentsConstructor';

import styles from '../../styles/index.module.css';

const LABELS = {
  SETTING_SERVICE_NAMES: 'Настройка наименования услуг',
  HINT_CONTENT: 'Перетащите карточку из списка ниже на поле',
  CUSTOM_TEXT: 'Произвольный текст',
  PLACEHOLDER: 'Введите текст',
  ENTER: 'Enter',
  LIMIT: 'Ограничение 20 символов',
};

const COUNTS = { LIMIT: 20 };

const SettingServiceNames = ({
  serviceName,
  onHandleSettingServiceNames,
  serviceOperations,
}) => {
  const service = serviceOperations
    .flatMap(({ ServiceOperationNames }) => ServiceOperationNames
      .filter(({ name }) => name === serviceName));

  const [draggableItem, setDraggableItem] = useState(null);
  const [droppedItems, setDroppedItems] = useState(service[0].nameContents || []);
  const [value, setValue] = useState('');
  const input = useRef(null);

  useEffect(() => {
    if (input.current) {
      input.current.focus();
    }
  }, [input.current]);

  const { nameContents } = PARAMS.find(({ name }) => name === serviceName);
  const params = [...nameContents, ...OTHER_PARAMS];

  const handleDragStart = (item) => setDraggableItem(item);

  const handleDragEnd = () => setDraggableItem(null);

  const handleItemDropped = (item) => {
    if (item && !droppedItems.includes(item)) {
      const updatedDroppedItems = [...droppedItems, item];

      if (item === LABELS.CUSTOM_TEXT) {
        setDroppedItems(updatedDroppedItems);
      } else {
        setDroppedItems(updatedDroppedItems);
        onHandleSettingServiceNames(serviceName, updatedDroppedItems);
      }
    }
  };

  const handleInputBlur = () => {
    if (value.trim()) {
      const updatedDroppedItems = droppedItems.map((item) => (item === LABELS.CUSTOM_TEXT ? value : item));
      setDroppedItems(updatedDroppedItems);
      onHandleSettingServiceNames(serviceName, updatedDroppedItems);
      setValue('');
    }
  };

  const handleKeyDown = ({ key }) => {
    if (key === LABELS.ENTER) {
      handleInputBlur();
    }
  };

  const handleRemoveItem = (itemToRemove) => {
    if (itemToRemove !== LABELS.CUSTOM_TEXT) {
      const updatedDroppedItems = droppedItems.filter((item) => item !== itemToRemove);
      setDroppedItems(updatedDroppedItems);
      onHandleSettingServiceNames(serviceName, updatedDroppedItems);
    }
  };

  const renderParams = () => (
    params.map((param) => (
      <div key={ param }>
        <DraggableItem
          text={ param }
          onDragStart={ () => handleDragStart(param) }
          onDragEnd={ handleDragEnd }
        />
      </div>
    ))
  );

  const renderInput = (text) => {
    if (text === LABELS.CUSTOM_TEXT) {
      const error = value.length === COUNTS.LIMIT ? LABELS.LIMIT : null;

      return (
        <React.Fragment>
          <input
            type='text'
            value={ value }
            onChange={ (e) => setValue(e.target.value) }
            onBlur={ handleInputBlur }
            onKeyDown={ handleKeyDown }
            className={ styles.input }
            placeholder={ LABELS.PLACEHOLDER }
            ref={ input }
            maxLength={ COUNTS.LIMIT }
          />
          <span>
            { error }
          </span>
        </React.Fragment>
      );
    }

    return text;
  };

  return (
    <div className={ styles.block_wrap }>
      <div className={ styles.block_title }>
        { LABELS.SETTING_SERVICE_NAMES }
      </div>
      <div className={ styles.block_content }>
        <DropZone
          draggableItem={ draggableItem }
          droppedItems={ droppedItems }
          onItemDropped={ handleItemDropped }
          onRemoveItem={ handleRemoveItem }
          renderInput={ renderInput }
        />
        <div className={ styles.list_wrap }>
          <div className={ styles.hint }>
            { LABELS.HINT_CONTENT }
          </div>
          { renderParams() }
        </div>
      </div>
    </div>
  );
};

SettingServiceNames.propTypes = {
  serviceName: PropTypes.string.isRequired,
  onHandleSettingServiceNames: PropTypes.func.isRequired,
  serviceOperations: PropTypes.array.isRequired,
};

export { SettingServiceNames };
