import React, {
  memo, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import AjaxButton from '../../../../../../components/ajaxButton';
import Input from '../../../../../../components/input';
import ModalDialog from '../../../../../../components/dialog';

import COMPONENTS from '../../../../../../bi/constants/components';

import styles from './styles.module.css';

const { BUTTON: { THEME: { SECOND } } } = COMPONENTS;

const LABELS = {
  BUTTONS: {
    CANCEL: 'Отменить',
    CONFIRM: 'Подтвердить',
    EDIT: 'Изменить',
    CONFIRM_FORCE: 'Изменить все равно',
  },
  VERSION: 'Версии',
  TICKET_NUMBER: 'Номер билета',
  CONFIRM_TEXT_CURRENT: 'Вы подтверждаете внесение изменений в билет?',
  CONFIRM_TEXT_CLOSING: 'Изменение номера авиабилета в версии заказа через БО невозможно. Она учтена в закрывающих документах',
};

export const ChangeTicketNumberModal = memo(({
  versionId,
  onAirTripFieldValidationAir,
  onChangeTicketNumber,
  reloadPage,
  showModalDialog,
  lastNumberTicket,
  closingDocument,
  preparedTripItems,
  setShowModalDialog,
  isFlagEditTicketNumberClosingPeriod,
}) => {
  const [numberTicketErrorMessage, setNumberTicketErrorMessage] = useState('');
  const [numberTicketValue, setNumberTicketValue] = useState(lastNumberTicket);
  const [showConfirmEditingButton, setShowConfirmEditingButton] = useState(false);

  const isDisabled = numberTicketErrorMessage || numberTicketValue === lastNumberTicket;

  const handleCloseModalDialog = () => {
    setNumberTicketValue(lastNumberTicket);
    setShowModalDialog(false);
    setShowConfirmEditingButton(false);
  };

  const handleCloseConfirmEditingButton = () => {
    setNumberTicketValue(lastNumberTicket);
    setShowConfirmEditingButton(false);
  };

  const handleChangeInput = (e, field, value) => {
    if (numberTicketErrorMessage) {
      setNumberTicketErrorMessage('');
    }

    const getValueWithoutSpaces = value.split(' ').join('');

    setNumberTicketValue(getValueWithoutSpaces);
  };

  const handleConfirmEditNumber = () => {
    const error = onAirTripFieldValidationAir('Num', numberTicketValue);

    if (error) {
      return setNumberTicketErrorMessage(error);
    }

    setShowConfirmEditingButton(true);
  };

  const handleChangeNumberTicket = async () => {
    await onChangeTicketNumber(versionId, numberTicketValue);
    handleCloseModalDialog();
    reloadPage();
  };

  const versionList = useMemo(() => (
    preparedTripItems.reverse().map((item, index) => item.map(({ idDetails, description }) => (
      <div key={ idDetails }>{ `${idDetails} ${index + 1}. ${description}` }</div>
    )))), [preparedTripItems]);

  const isClosingPeriod = Array.isArray(closingDocument) && closingDocument.length;

  const getConfirmText = () => {
    if (isFlagEditTicketNumberClosingPeriod && isClosingPeriod) {
      const isClossingDocumentNumber = closingDocument[0].number;
      const isClossingDocumentName = closingDocument[0].companyName;

      return `${LABELS.CONFIRM_TEXT_CLOSING} ${isClossingDocumentName} ${isClossingDocumentNumber}`;
    }

    return LABELS.CONFIRM_TEXT_CURRENT;
  };

  const getConfirmButtonText = () => {
    if (isFlagEditTicketNumberClosingPeriod && isClosingPeriod) {
      return LABELS.BUTTONS.CONFIRM_FORCE;
    }

    return LABELS.BUTTONS.CONFIRM;
  };

  const renderConfirmEditingButton = () => {
    if (!showConfirmEditingButton) return null;

    return (
      <div className={ styles.wrapper }>
        <p className={ styles.confirm__edit__text }>
          { getConfirmText() }
        </p>
        <div className={ styles.buttons__wrapper }>
          <AjaxButton
            label={ getConfirmButtonText() }
            theme={ SECOND }
            onClick={ handleChangeNumberTicket }
          />
          <Button
            label={ LABELS.BUTTONS.CANCEL }
            theme={ SECOND }
            onClick={ handleCloseConfirmEditingButton }
          />
        </div>
      </div>
    );
  };

  return (
    <ModalDialog
      show={ showModalDialog }
      onClose={ handleCloseModalDialog }
    >
      <div className={ styles.modal__wrapper }>
        <div className={ styles.verssion__list }>
          <p className={ styles.title__text }>{ `${LABELS.VERSION}:` }</p>
          <div>{ versionList }</div>
        </div>
        <div className={ styles.change__number__wrapper }>
          <p className={ styles.title__text }>{ `${LABELS.TICKET_NUMBER}:` }</p>
          <div className={ ` ${styles.wrapper} ${styles.modal__input__wrapper}` }>
            <Input
              disabled={ showConfirmEditingButton }
              field='Num'
              value={ numberTicketValue }
              onChange={ handleChangeInput }
              valid={ numberTicketErrorMessage }
              className={ styles.modal__input }
            />
          </div>
          <AjaxButton
            label={ LABELS.BUTTONS.EDIT }
            theme={ SECOND }
            disabled={ isDisabled }
            onClick={ handleConfirmEditNumber }
          />
        </div>
        { renderConfirmEditingButton() }
      </div>
    </ModalDialog>
  );
});

ChangeTicketNumberModal.propTypes = {
  reloadPage: PropTypes.func.isRequired,
  versionId: PropTypes.number.isRequired,
  onAirTripFieldValidationAir: PropTypes.func.isRequired,
  onChangeTicketNumber: PropTypes.func.isRequired,
  showModalDialog: PropTypes.bool.isRequired,
  preparedTripItems: PropTypes.array.isRequired,
  setShowModalDialog: PropTypes.func.isRequired,
  lastNumberTicket: PropTypes.string.isRequired,
  closingDocument: PropTypes.array,
  isFlagEditTicketNumberClosingPeriod: PropTypes.bool,
};

ChangeTicketNumberModal.defaultProps = {
  closingDocument: null,
  isFlagEditTicketNumberClosingPeriod: false,
};
