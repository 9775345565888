import React from 'react';
import PropTypes from 'prop-types';

import { Collapsible } from '../../../../components/Collapsible';
import { DeleteButton } from '../Buttons/DeleteButton';
import { SettingServiceNames } from './SettingServiceNames';

import styles from '../../styles/index.module.css';

const LABELS = { SETTING_SERVICES: 'Настройка услуг в акте' };

const SettingServices = ({
  selectedServices,
  onHandleRemoveServices,
  onHandleSettingServiceNames,
  serviceOperations,
}) => {
  const renderCollapsibleHeader = (service) => (
    <div className={ styles.collapsible_title_wrap }>
      { service }
      <DeleteButton onHandleClick={ () => onHandleRemoveServices(service) } />
    </div>
  );

  const renderCollapsible = () => (
    selectedServices.map((service) => (
      <div
        key={ service }
        className={ styles.types_services }
      >
        <Collapsible
          title={ renderCollapsibleHeader(service) }
          wrapperClassname={ styles.collapsible_wrap }
          headerWrapperClassname={ styles.header_collapsible_wrap }
        >
          <SettingServiceNames
            serviceName={ service }
            onHandleSettingServiceNames={ onHandleSettingServiceNames }
            serviceOperations={ serviceOperations }
          />
        </Collapsible>
      </div>
    ))
  );

  return (
    <div className={ styles.types_services_wrap }>
      <div className={ styles.types_services }>
        <div className={ styles.title }>
          { LABELS.SETTING_SERVICES }
        </div>
      </div>
      { renderCollapsible() }
    </div>
  );
};

SettingServices.propTypes = {
  selectedServices: PropTypes.array.isRequired,
  onHandleRemoveServices: PropTypes.func.isRequired,
  onHandleSettingServiceNames: PropTypes.func.isRequired,
  serviceOperations: PropTypes.array.isRequired,
};

export { SettingServices };
