import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datetime';
import { Button } from 'sw-ui';

import Input from '../../../../../components/input';

import { formatDate } from '../../../../../utils/date';

import COMPONENTS from '../../../../../bi/constants/components';
import { DEFAULTDATE, LOCALERU } from '../../../../../constants/time';

import styles from '../../../styles/complaints.module.css';

const { BUTTON: { THEME: { FLAT, SECOND } } } = COMPONENTS;

const FIELDS = { LINK_COMPLAINT: 'link_complaint' };

const LABELS = {
  REMOVE_COMPLAINT: 'Удалить',
  CHANGE_COMPLAINT: 'Изменить',
  CANCEL_CHANGE_COMPLAINT: 'Отмена',
  SAVE_CHANGE_COMPLAINT: 'Сохранить',
};

class Complaint extends Component {
  static propTypes = {
    complaint: PropTypes.object.isRequired,
    isEditingAll: PropTypes.bool.isRequired,
    isEditingCurrenItem: PropTypes.string.isRequired,
    isRemovingCurrenItem: PropTypes.string.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onChangeComplaint: PropTypes.func.isRequired,
    onChangeComplaintSave: PropTypes.func.isRequired,
    onChangeComplaintCancel: PropTypes.func.isRequired,
    onRemoveComplaint: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = { complaint: props.complaint };
  }

  handelCancelChanges = () => {
    const { onChangeComplaintCancel, complaint } = this.props;

    this.setState({ complaint });

    onChangeComplaintCancel();
  };

  renderComplaint = () => {
    const {
      onChangeComplaint,
      onRemoveComplaint,
      isEditingAll,
      isLoading,
      isRemovingCurrenItem,
    } = this.props;
    const { complaint } = this.state;

    const isLoadingRemove = isLoading && isRemovingCurrenItem === complaint.id;

    return (
      <section className={ styles.row }>
        <div className={ styles.item }>{ formatDate(complaint.claim_date, DEFAULTDATE) }</div>
        <a
          className={ `${styles.item} ${styles.link}` }
          href={ complaint.claim_link }
          target='_blank'
          rel='noreferrer'
        >
          { complaint.claim_link }
        </a>
        <div className={ styles.field_name }>{ complaint.description }</div>
        <div className={ styles.buttons }>
          <Button
            label={ LABELS.CHANGE_COMPLAINT }
            theme={ FLAT }
            onClick={ () => onChangeComplaint(complaint) }
            disabled={ isEditingAll }
          />
          <Button
            label={ LABELS.REMOVE_COMPLAINT }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            onClick={ () => onRemoveComplaint(complaint) }
            loading={ isLoadingRemove }
            disabled={ isEditingAll }
          />
        </div>
      </section>
    );
  };

  renderComplaintEditing = () => {
    const {
      onChangeComplaintSave,
      onRemoveComplaint,
      isEditingCurrenItem,
      isLoading,
    } = this.props;
    const { complaint } = this.state;

    const isLoadingSave = isLoading && (!complaint.id || complaint.id === isEditingCurrenItem);
    const valueData = complaint.claim_date ? formatDate(complaint.claim_date, DEFAULTDATE) : '';
    const isDisabledSave = !(complaint.claim_date && complaint.claim_link && complaint.description);

    const onCancel = complaint.id ? this.handelCancelChanges : () => onRemoveComplaint(complaint);

    return (
      <section className={ styles.row }>
        <DatePicker
          className={ styles.input_date }
          dateFormat={ DEFAULTDATE }
          timeFormat=''
          locale={ LOCALERU }
          value={ valueData }
          onChange={ (value) => this.setState({ complaint: { ...complaint, claim_date: value } }) }
        />
        <Input
          field={ FIELDS.LINK_COMPLAINT }
          value={ complaint.claim_link }
          onChange={ (event) => this.setState({ complaint: { ...complaint, claim_link: event.target.value } }) }
        />
        <textarea
          className={ `${styles.comment} al-textarea` }
          value={ complaint.description }
          onChange={ (event) => this.setState({ complaint: { ...complaint, description: event.target.value } }) }
          rows={ 5 }
        />
        <div className={ styles.buttons }>
          <Button
            label={ LABELS.SAVE_CHANGE_COMPLAINT }
            theme={ FLAT }
            onClick={ () => onChangeComplaintSave(complaint) }
            loading={ isLoadingSave }
            disabled={ isDisabledSave }
          />
          <Button
            label={ LABELS.CANCEL_CHANGE_COMPLAINT }
            theme={ SECOND }
            onClick={ onCancel }
          />
        </div>
      </section>
    );
  };

  render() {
    const { isEditingCurrenItem } = this.props;
    const { complaint } = this.state;

    if (!complaint.id || complaint.id === isEditingCurrenItem) {
      return this.renderComplaintEditing();
    }

    return this.renderComplaint();
  }
}

export { Complaint };
