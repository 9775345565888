import { formatDate } from '../../utils/date';

import { HOTEL_PROVIDER_VALUE, CURRENCY_VALUES } from './hotel';

const SEND_TYPES = { BY_DIADOC: 'Diadoc' };

const PERIODSUPD = [
  { value: 0, label: 'Последним числом месяца' },
  { value: 1, label: '15 и последнее число месяца' },
  { value: 2, label: '10, 20 и последнее число месяца' },
  { value: 3, label: '5, 10, 15, 20, 25 и последнее число месяца' },
  { value: 4, label: 'Ежедневно' },
  { value: 5, label: 'Каждые 3 дня' },
];

const TAX_SHEMAS_SA = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Общая (НДС 20%)' },
  { value: 2, label: 'Упрощенная (без НДС)' },
];

const ADDITIONALOVERDRAFTTEXT = {
  ADDITIONALOVERDRAFT: 'Дополнительный лимит:',
  ADDITIONALPERIOD: 'Дополнительный период овердрафта:',
};

const DEBT = {
  OVER: 'просроченная',
  ALL: 'Задолженность',
  NONE: 'Задолженности нет',
};

const ACCOUNTTEXT = {
  EDIT: 'ИЗМЕНИТЬ',
  SALESREPORT: 'ОТЧЕТ ПО ПРОДАЖАМ',
  ATTENTION: 'Обратите внимание',
  NOTIFICATIONS: 'ОПОВЕЩЕНИЯ',
  CLOSING_SETTINGS: 'НАСТРОЙКА ЭДО',
  NEWRESERVATIONSUNAVAILABLE: 'Новые бронирования недоступны',
  AVAILABLEFORBOOKING: 'Доступно для бронирования',
  DOWNLOADINVOICE: 'СКАЧАТЬ СВОДНЫЙ СЧЕТ',
  DOWNLOADREVISE: 'СКАЧАТЬ АКТ СВЕРКИ',
  SEND_TO_EDO: 'Отправить в ЭДО',
  DOWNLOADPREPAYINVOICE: 'СКАЧАТЬ СЧЕТ НА ПРЕДОПЛАТУ',
  DOWNLOADDETAILEDREVISE: 'Акт сверки билеты детально',
  DOWNLOAD_CERTIFICATE: 'Скачать массовые справки',
  VISIBILITYRESTRICTION: 'ОГРАНИЧЕНИЕ ВИДИМОСТИ',
  FROM: 'с',
  TO: 'по',
  OK: 'OK',
  ALLTIME: 'за все время',
  DOWNLOAD: 'Скачать',
  DOWNLOAD_DETAILED: 'Скачать общий акт сверки',
  PERIOD_SHIPMENT: 'Период отправки документов:',
  NOINDICATED: 'не указано',
  NOINDICATEDDATE: 'не указана',
  HIDECOMPANY: 'скрыть',
  SAVE: 'Сохранить',
  CANCEL: 'Отменить',
  NO: 'Абонплата',
  DOWNLOADXLSX: 'Скачать в xlsx',
  ADD: 'ДОБАВИТЬ',
  DELETE: 'УДАЛИТЬ',
};

const PERIOD_SHIPMENT = [
  { value: 0, label: 'Не выбрано' },
  { value: 1, label: 'Каждый день' },
  { value: 2, label: 'Каждые 3 дня' },
  { value: 3, label: 'Каждые 5 дней' },
  { value: 4, label: 'Каждый понедельник' },
  { value: 5, label: 'Каждую пятницу' },
  { value: 6, label: 'Каждые 10 дней (10,20,30)' },
  { value: 7, label: 'Дважды в месяц' },
  { value: 8, label: 'Каждое 1 число месяца' },
];

const ACCOUNT_SETTINGS_FIELDS = {
  NO_ANALYTICS: 'noAnalytics',
  HIDE_PER_DIEM: 'hidePerDiem',
  HIDE_ADDITIONAL_ORDERS: 'hideAdditionalOrders',
  NO_BOOKING_TAXI: 'noBookingTaxi',
  CART_EMPLOYEE_BIRTHDAY: 'cartEmployeeBirthday',
  NO_CHANGE_OR_RETURN_TRIP: 'noChangeOrReturnTrip',
  HIDE_SECTIONS: 'hideSections',
  PERSONAL_DATA_SETTINGS: 'displayPersonalData',
  EMPLOYEE_RESTRICTIONS: 'employeeRestriction',
  FULL_NAME: 'fullName',
  DOCUMENTS: 'documents',
  SETTINGS1C: 'settings1C',
  SALARY1C: 'salary1C',
  ACQUIRING_ACCESS: 'acquiringAccess',
  BOOK_BY_PASSENGER_SCHEME: 'bookByPassengerScheme',
  TRANSFER: 'transfer',
  VIP_HALL: 'vipHall',
  AEROEXPRESS: 'aeroExpress',
  CONFERENCE: 'conference',
  GROUP_CHECKING: 'groupCheckin',
  NO_PERSONAL_BONUS_CARDS: 'noPersonalBonusCards',
  FORCE_TRAVEL_POLICY: 'forceTravelPolicy',
  NO_EXPENSE_REPORT: 'noExpenseReport',
  SETTINGS: 'settings',
  SEND_DEBT_NOTIFICATION_IN_FIVE_DAYS: 'sendDebtNotificationInFiveDays',
  SEND_CLOSINGS_WITHOUT_ARCHIVE: 'sendClosingsWithoutArchive',
  DELETE_PROJECT: 'deleteProject',
  SEND_SMS: 'sendSms',
  SEND_MIN_TARIF: 'lowestFareInformation',
  HIDE_TAGS_IN_CART: 'hideTagsInCart',
  DEPARTMENT_ONLY: 'departmentOnly',
  FORCE_TRIP_APPROVE_COMMENT: 'forceTripApproveComment',
  REMIDN_EXPENSE_REPORT: 'remindExpenseReport',
  FORCE_EMPLOYEE_NUMBER: 'forceEmployeeNumber',
  REQUESTS_VIEW_EQUALS_TRIPS: 'requestsViewEqualsTrips',
  NO_MICE: 'noMice',
};

const LABEL_ACCOUNT_SETTINGS_FIELDS = {
  [ACCOUNT_SETTINGS_FIELDS.HIDE_SECTIONS]: ' Скрыть разделы в ЛК:',
  [ACCOUNT_SETTINGS_FIELDS.PERSONAL_DATA_SETTINGS]: ' Настройки отображения персональных данных:',
  [ACCOUNT_SETTINGS_FIELDS.EMPLOYEE_RESTRICTIONS]: ' Ограничения сотрудников:',
  [ACCOUNT_SETTINGS_FIELDS.SETTINGS1C]: 'Дополнительные настройки',
  [ACCOUNT_SETTINGS_FIELDS.NO_BOOKING_TAXI]: 'Такси',
  [ACCOUNT_SETTINGS_FIELDS.NO_ANALYTICS]: 'Аналитика',
  [ACCOUNT_SETTINGS_FIELDS.HIDE_PER_DIEM]: 'Скрыть суточные в АО',
  [ACCOUNT_SETTINGS_FIELDS.CART_EMPLOYEE_BIRTHDAY]: 'Показывать дату рождения сотрудников',
  [ACCOUNT_SETTINGS_FIELDS.FULL_NAME]: 'Сотрудники с полным ФИО в поездке',
  [ACCOUNT_SETTINGS_FIELDS.DOCUMENTS]: 'Показывать номер паспорта сотрудников',
  [ACCOUNT_SETTINGS_FIELDS.NO_CHANGE_OR_RETURN_TRIP]: 'Запрет на аннуляцию и изменение поездок',
  [ACCOUNT_SETTINGS_FIELDS.FORCE_TRAVEL_POLICY]: 'Обязательная travel-политика в карточке сотрудника',
  [ACCOUNT_SETTINGS_FIELDS.SALARY1C]: 'Доступ к разделу Заявки в ЛК',
  [ACCOUNT_SETTINGS_FIELDS.ACQUIRING_ACCESS]: 'Разрешить оплату картой',
  [ACCOUNT_SETTINGS_FIELDS.BOOK_BY_PASSENGER_SCHEME]: 'Бронировать по схеме пассажира',
  [ACCOUNT_SETTINGS_FIELDS.TRANSFER]: 'Трансфер',
  [ACCOUNT_SETTINGS_FIELDS.VIP_HALL]: 'VIP-зал',
  [ACCOUNT_SETTINGS_FIELDS.AEROEXPRESS]: 'Аэроэкспресс',
  [ACCOUNT_SETTINGS_FIELDS.CONFERENCE]: 'Конференц-зал',
  [ACCOUNT_SETTINGS_FIELDS.GROUP_CHECKING]: 'Групповой заезд',
  [ACCOUNT_SETTINGS_FIELDS.NO_PERSONAL_BONUS_CARDS]: 'Запрет на добавление бонусной карты',
  [ACCOUNT_SETTINGS_FIELDS.NO_EXPENSE_REPORT]: 'Авансовые отчеты',
  [ACCOUNT_SETTINGS_FIELDS.SETTINGS]: 'Настройки уведомлений',
  [ACCOUNT_SETTINGS_FIELDS.SEND_DEBT_NOTIFICATION_IN_FIVE_DAYS]: 'Уведомление о задолженности за 5 дней',
  [ACCOUNT_SETTINGS_FIELDS.SEND_CLOSINGS_WITHOUT_ARCHIVE]: 'Отправка закрывающих без архива',
  [ACCOUNT_SETTINGS_FIELDS.DELETE_PROJECT]: 'Право изменять в корзине ЦЗ по умолчанию',
  [ACCOUNT_SETTINGS_FIELDS.SEND_SMS]: 'СМС-сообщение о покупке билета пассажиру',
  [ACCOUNT_SETTINGS_FIELDS.SEND_MIN_TARIF]: 'Показывать минимальный тариф',
  [ACCOUNT_SETTINGS_FIELDS.HIDE_TAGS_IN_CART]: 'Скрыть метки в корзине',
  [ACCOUNT_SETTINGS_FIELDS.DEPARTMENT_ONLY]: 'Ограничение видимости по отделам',
  [ACCOUNT_SETTINGS_FIELDS.FORCE_TRIP_APPROVE_COMMENT]: 'Обязательный комментарий при отправке поездки на согласование',
  [ACCOUNT_SETTINGS_FIELDS.REMIDN_EXPENSE_REPORT]: 'Уведомление на почту о завершении поездки',
  [ACCOUNT_SETTINGS_FIELDS.FORCE_EMPLOYEE_NUMBER]: 'Обязательный табельный номер в карточке сотрудника',
  [ACCOUNT_SETTINGS_FIELDS.REQUESTS_VIEW_EQUALS_TRIPS]: 'Видеть только свои заявки (1С)',
  [ACCOUNT_SETTINGS_FIELDS.HIDE_ADDITIONAL_ORDERS]: 'Запрет на покупку дополнительных услуг',
  [ACCOUNT_SETTINGS_FIELDS.NO_MICE]: 'Мероприятие (MICE)',
};

const CERTIFICATE = {
  EVERY_FLIGHT: 'Реестр статусов по перелетам',
  SINGLE_FLIGHT: 'Пакет справок по всем перелетам',
};

const CERTIFICATE_TYPES = {
  EVERY_FLIGHT: 'everyFlight',
  SINGLE_FLIGHT: 'singleFlight',
};

const SIGNATURE_CERTIFICATE = {
  FULL_SIGNATURE: 'С печатью и подписью',
  LIGHT_SIGNATURE: 'Без печати и подписи(с местом для них)',
  WITHOUT_SIGNATURE: 'Без печати и подписи(без места для них)',
};

const SIGNATURE_TYPES = {
  FULL_SIGNATURE: 'FullSignature',
  LIGHT_SIGNATURE: 'LightSignature',
  WITHOUT_SIGNATURE: 'WithoutSignature',
};

const COMPANIESLIST = [
  'компания',
  'компании',
  'компаний',
];

const ACCOUNTFIELDS = {
  COMMENT: 'Comment',
  INTERCOM: 'IntercomComment',
};

const STATUS = { PENDING: 'Pending' };

const TYPEICONLIST = {
  VERIFIEDUSER: {
    TYPEICON: 'verified_user',
    TOOLTIPTEXT: 'Обладает полным доступом',
  },
  SUPERVISOR: {
    TYPEICON: 'supervisor_account',
    TOOLTIPTEXT: 'Может редактировать информацию о сотрудниках и предоставлять доступ в сервис',
  },
  REGISTERURL: {
    TYPEICON: 'link',
    TOOLTIPTEXT: 'Скопировать ссылку для завершения регистрации',
  },
};

const STATUSLIST = {
  APPROVED: {
    NUMBER: 2,
    TEXT: 'Активные пользователи',
  },
  PENDING: {
    NUMBER: 1,
    TEXT: 'Неподтвержденные пользователи',
  },
  REJECTED: {
    NUMBER: 3,
    TEXT: 'Отклоненные пользователи',
  },
  DISABLED: {
    NUMBER: 4,
    TEXT: 'Отключенные пользователи',
  },
  CREATED: {
    NUMBER: 0,
    TEXT: 'Созданные пользователи',
  },
};

const COMMENTS = {
  Comment: {
    LABEL: 'Заметка',
    MAXCOMMENTLENGTH: 4000,
    NOCOMMENTTEXT: 'нет заметок',
    INPUTCOMMENT: 'Введите заметку не более 4000 символов',
  },
  IntercomComment: {
    LABEL: 'Комментарий Intercom',
    MAXCOMMENTLENGTH: 0,
    NOCOMMENTTEXT: 'нет комментариев',
    INPUTCOMMENT: 'Введите комментарий',
    DOCUMENT_CORRECTION: 'Этот пакет документов был отредактирован',
    INFO_DOCUMENT_CORRECTION: (userName, dateCorrection) => `Этот пакет документов был отредактирован ${formatDate(dateCorrection, 'LLL')} пользователем ${userName}`,
    INFO_COMMENT: (userName, dateCorrection) => `Детали пакета были отредактированы ${formatDate(dateCorrection, 'LLL')} пользователем ${userName}`,
    INFO_EDO: (userName, dateCorrection) => `Отредактированно ${formatDate(dateCorrection, 'LLL')} пользователем ${userName}`,
  },
};

const REGULARITY_PAY = [
  { value: 0, label: 'При наличии брони в ЛК' },
  { value: 1, label: 'При наличии брони у Юрлица' },
];

const defaultCompanyArrForPlanFee = () => ({
  value: 0,
  label: 'Юрлицо для списания',
});

const RATES = [
  1900,
  2900,
  4900,
  6900,
  8900,
  9900,
  10900,
  11900,
  13900,
  14900,
  17900,
  19900,
  23900,
  27900,
  29900,
  33900,
  38900,
];

const FIELDS_PLAN_FEE_SETTINGS = {
  COMPANY_ID: 'companyId',
  CURRENT_RATE: 'currentRate',
  REGULARITY_PAY: 'regularityPay',
  UPDATED_RATE: 'updatedRate',
  DATE_UPDATED_PLAN_FEE: 'dateUpdatedPlanfee',
};

const TYPERATRATES = {
  DEFAULT: 'default',
  ORIGINAL: 'original',
  OPTIONAL: 'optional',
};

const NONERATE = {
  id: TYPERATRATES.DEFAULT,
  type: TYPERATRATES.DEFAULT,
  value: 0,
  label: ACCOUNTTEXT.NO,
};

const OPTIONALRATE = {
  id: TYPERATRATES.OPTIONAL,
  type: TYPERATRATES.OPTIONAL,
  value: 0,
  label: '',
};

const RATE_LIST = [
  {
    id: 1, type: TYPERATRATES.ORIGINAL, value: 2000, label: '2000',
  },
  {
    id: 2, type: TYPERATRATES.ORIGINAL, value: 3000, label: '3000',
  },
  {
    id: 3, type: TYPERATRATES.ORIGINAL, value: 3900, label: '3900',
  },
  {
    id: 4, type: TYPERATRATES.ORIGINAL, value: 5900, label: '5900',
  },
  {
    id: 5, type: TYPERATRATES.ORIGINAL, value: 13900, label: '13900',
  },
  {
    id: 6, type: TYPERATRATES.ORIGINAL, value: 18900, label: '18900',
  },
  {
    id: 0, type: TYPERATRATES.OPTIONAL, value: 0, label: '',
  },
];

const ACCOUNTTYPES = [
  { value: 'VIP', label: '1 млн и более(VIP)' },
  { value: 'Common', label: 'Обычный' },
  { value: 'VIP2', label: '500 тыс - 1 млн' },
  { value: 'VIP3', label: '300 тыс - 500 тыс' },
  { value: 'VIP4', label: 'до 300 тыс' },
];

const SENDINVOICES = [
  { value: 'on', label: 'Включена' },
  { value: 'off', label: 'Выключена' },
];

const ACCOUNTVALUE = {
  COMMON: 'Common',
  ON: 'on',
  OFF: 'off',
};

const ACCOUNTNAMES = {
  ACCOUNTNAME: 'Name',
  CREATEDATE: 'CreateDate',
  SOLDBY: 'SoldBy',
  SUPPORTEDBY: 'SupportedBy',
  ACCOUNTAUTOCOMPLETE: 'AccountGroup',
  GROUPNAME: 'GroupName',
  SOLDBY_MICE: 'SoldByMICE',
};

const ACCOUNTNAMESRU = {
  [ACCOUNTNAMES.ACCOUNTNAME]: 'Аккаунт',
  [ACCOUNTNAMES.CREATEDATE]: 'Дата создания',
  [ACCOUNTNAMES.SOLDBY]: 'Продавец',
  [ACCOUNTNAMES.SUPPORTEDBY]: 'Сопровожденец',
  [ACCOUNTNAMES.GROUPNAME]: 'Наименование группы компаний',
  [ACCOUNTNAMES.SOLDBY_MICE]: 'Продавец MICE',
};

const SALESREPORTFORMAT = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
};

const SALESREPORTFORMATRU = {
  DAY: 'По дням',
  WEEK: 'По неделям',
  MONTH: 'По месяцам',
};

const SALESREPORTTYPES = [
  {
    value: SALESREPORTFORMAT.MONTH,
    label: SALESREPORTFORMATRU.MONTH,
  },
  {
    value: SALESREPORTFORMAT.WEEK,
    label: SALESREPORTFORMATRU.WEEK,
  },
  {
    value: SALESREPORTFORMAT.DAY,
    label: SALESREPORTFORMATRU.DAY,
  },
];

const NO_SEND = 'None';

const SENDTYPE = [
  { value: NO_SEND, label: 'Не отправлять' },
  { value: 'RussianPost', label: 'Почтой России' },
  { value: 'Courier', label: 'Курьером' },
  { value: 'RegisteredLetter', label: 'Заказными Письмами' },
  { value: 'Astral', label: 'Через Астрал' },
  { value: 'Diadoc', label: 'Через Диадок' },
];

const ACCOUNT_DOC = {
  DELETE_PACKAGE: 'УДАЛИТЬ ПАКЕТЫ ДОКУМЕНТОВ',
  DOWNLOAD_ADO: 'СКАЧАТЬ ДОКУМЕНТЫ ДЛЯ ЭДО',
  DOWNLOAD_CLOTHED: 'СКАЧАТЬ ЗАКРЫВАЮЩИЕ ДОКУМЕНТЫ',
  DOWNLOAD_VOUCHERS: 'СКАЧАТЬ ВАУЧЕРЫ',
  CREATE_PACKAGES: 'СОЗДАТЬ ПАКЕТЫ ДОКУМЕНТОВ',
  SEND_DIADOC: 'ОТПРАВИТЬ В ДИАДОК',
};

const UPD = {
  ALL: 'all',
  PERIOD: 'period',
  SCHEMA: 'schema',
  SENDING_TYPE: 'sendingType',
  PERIOD_EDO: 'periodEdo',
  TAX_SHEMAS_SA: 'taxShemasSA',
};

const CHANGE_UPD = {
  PERIOD_UPD: 'Изменение периода выставления УПД',
  SCHEMA: 'Изменение схемы',
  PERIOD: 'Изменение периода закрывающих документов',
  SENDING_TYPE: 'Изменение типа отправки',
  PERIOD_EDO: 'Изменение периода отправки документов',
  SETTINGS_EDO: 'Настройка ЭДО',
  EDO: 'Идентификатор ЭДО',
  TAX_SHEMAS_SA: 'Изменение системы налогообложения',
};

const AGGREGATION_IDS = {
  PPR: 19202,
  CONTUR: 20431,
  CKR: 19202,
};

const CLOSING_SCHEMAS = [
  'InvoicePlusClosing',
  'InvoicePlusClosingECLC',
  'InvoicePlusClosingForEachAct',
  'InvoicePlusClosingForEachProject',
  'InvoicePlusClosingIntratool',
  'SeparateActInvoicePlusClosing',
];

const SUPPORTS = [
  { name: 'i.klyanchina@smartway.today', id: 0 },
  { name: 'm.shalimova@smartway.today', id: 1 },
  { name: 't.semenuk@smartway.today', id: 2 },
  { name: 'n.goryaeva@smartway.today', id: 3 },
  { name: 'i.subbotina@smartway.today', id: 4 },
  { name: 'm.shalimova@smartway.today', id: 5 },
  { name: 'm.zhulyabina@smartway.today', id: 6 },
  { name: 'o.bikovskaya@smartway.today', id: 7 },
  { name: 't.yakovleva@smartway.today', id: 8 },
  { name: 'k.smirnova@smartway.today', id: 9 },
  { name: 'a.pischaeva@smartway.today', id: 10 },
];

const SALE_TYPES = {
  OLD_SALE: 'OldSale',
  NEW_SALE: 'NewSale',
  TEST: 'Test',
};

const DOCUMENT_FLAG = {
  NEED_CORRECTION: 'Требует исправления',
  NOT_NEED_CORRECTION: 'Не требует исправления',
};

const SALE_TYPES_ARRAY = [
  { value: SALE_TYPES.NEW_SALE, label: 'Новая продажа' },
  { value: SALE_TYPES.OLD_SALE, label: 'Старая продажа' },
  { value: SALE_TYPES.TEST, label: 'Тест' },
];

const PROJECT_TYPE = [
  { label: 'Smartway', value: 0 },
  { label: 'Smartagent', value: 1 },
];

const TYPE_UNLOADING_SA = [
  { label: 'СФ как в БО', value: 0 },
  { label: 'Акты', value: 2 },
  { label: 'АП', value: 4 },
];

const TYPE_UNLOADING = [
  { label: 'СФ как в БО', value: 0 },
  { label: 'СФ прямой контрактинг', value: 1 },
  { label: 'Акты', value: 2 },
];

const REPORT_TYPE = [
  { label: 'Сборы по автобусным билетам', value: 8 },
  { label: 'Вип-залы без НДС', value: 5 },
  { label: 'Отели', value: 3 },
  { label: 'Трансферы', value: 6 },
];

const NDS = [
  { label: '0%', value: 0 },
  { label: '20%', value: 2 },
  { label: 'Без НДС', value: 3 },
];

const ORGANIZATION = [
  { label: 'Smartway', value: 0 },
  { label: 'Савади', value: 1 },
];

const COUNTERAGENT = [
  { label: 'Smartway', value: 0 },
  { label: 'Клиент', value: 2 },
];

const COMMENT = [
  {
    id: 1, type: TYPERATRATES.ORIGINAL, value: 'С/Ф ПРЕДОСТАВЛЕНА КЛИЕНТУ', label: 'С/Ф ПРЕДОСТАВЛЕНА КЛИЕНТУ',
  },
  {
    id: 2, type: TYPERATRATES.ORIGINAL, value: 'АКТЫ ВЫП. РАБОТ БЕЗ НДС', label: 'АКТЫ ВЫП. РАБОТ БЕЗ НДС',
  },
  {
    id: 3, type: TYPERATRATES.ORIGINAL, value: 'Савади 3Д', label: 'Савади 3Д',
  },
  {
    id: 0, type: TYPERATRATES.OPTIONAL, value: 0, label: '',
  },
];

const PRINCIPAL_SA = [
  { label: 'Пустой', value: 1 },
  { label: 'Провайдер', value: 2 },
];

const PRINCIPAL = [
  { label: 'Отель', value: 0 },
  ...PRINCIPAL_SA,
];

const TYPE_SERVICE_SA = [
  { label: 'Агентские', value: 0 },
  { label: 'Собственные', value: 1 },
];

const TYPE_SERVICE = [
  ...TYPE_SERVICE_SA,
  { label: 'Фактический', value: 2 },
];

const DUKEMNT_NUMBER = [
  { label: 'Как в БО', value: 0 },
  { label: 'Без номера', value: 1 },
];

const PROVIDERS = [
  { value: 'travelline', label: 'TL' },
  { value: 'direct', label: 'Direct' },
  { value: 'sawady3d', label: 'sawady3d' },
];

const MENU_STYLE = {
  position: 'absolute',
  left: 0,
  top: 50,
  display: 'flex',
  flexDirection: 'column',
  minWidth: '200px',
  boxShadow: '0 1px 3px rgba(0, 0, 0, 0.5)',
  width: '100%',
  zIndex: 6,
  backgroundColor: 'white',
};

const SCROLL_STYLE = {
  overflowY: 'scroll',
  height: '300px',
};

const ITEMS_VISIBLE_LENGTH = 8;

const BRAND_NAME = {
  SMARTAGENT: 'Smartagent',
  SMARTAGENT_AUTO: 'Smartagent(auto)',
  SMARTWAY_KZ: 'SmartwayKZ',
};

const LEAD_TYPE = [
  { value: '', label: 'Не выбрано' },
  { value: 'Клиент от продаж', label: 'Клиент от продаж' },
];

const DEFAULT_CURRENCY_INFO = {
  Base: 0,
  Commission: 0,
  Total: 0,
  CurrencyName: CURRENCY_VALUES.USD,
  CurrencyValue: '',
  CurrencyMultiplierPercent: '',
  CurrencyValueWithMultiplier: '',
  DateUTC: '',
  Multiplier: '',
  Provider: HOTEL_PROVIDER_VALUE.expedia,
  MFInCurrency: 0,
  MF: 0,
  Inclusive: 0,
  Penalties: [],
  PenaltiesCurrency: [],
  RequiredTotalInRub: 0,
};

export {
  RATE_LIST,
  PROJECT_TYPE,
  COMMENT,
  PROVIDERS,
  DUKEMNT_NUMBER,
  TYPE_SERVICE_SA,
  TYPE_SERVICE,
  PRINCIPAL_SA,
  PRINCIPAL,
  COUNTERAGENT,
  ORGANIZATION,
  NDS,
  TYPE_UNLOADING_SA,
  TYPE_UNLOADING,
  REPORT_TYPE,
  SEND_TYPES,
  PERIODSUPD,
  ADDITIONALOVERDRAFTTEXT,
  DEBT,
  ACCOUNTTEXT,
  COMPANIESLIST,
  ACCOUNTFIELDS,
  STATUS,
  TYPEICONLIST,
  STATUSLIST,
  COMMENTS,
  RATES,
  TYPERATRATES,
  NONERATE,
  OPTIONALRATE,
  ACCOUNTTYPES,
  ACCOUNTNAMES,
  ACCOUNTNAMESRU,
  SALESREPORTFORMAT,
  SALESREPORTFORMATRU,
  SALESREPORTTYPES,
  SENDINVOICES,
  ACCOUNTVALUE,
  SENDTYPE,
  ACCOUNT_DOC,
  UPD,
  CHANGE_UPD,
  CERTIFICATE,
  CERTIFICATE_TYPES,
  NO_SEND,
  CLOSING_SCHEMAS,
  SUPPORTS,
  SIGNATURE_TYPES,
  SIGNATURE_CERTIFICATE,
  REGULARITY_PAY,
  FIELDS_PLAN_FEE_SETTINGS,
  SALE_TYPES,
  DOCUMENT_FLAG,
  SALE_TYPES_ARRAY,
  PERIOD_SHIPMENT,
  LABEL_ACCOUNT_SETTINGS_FIELDS,
  ACCOUNT_SETTINGS_FIELDS,
  AGGREGATION_IDS,
  defaultCompanyArrForPlanFee,
  MENU_STYLE,
  SCROLL_STYLE,
  ITEMS_VISIBLE_LENGTH,
  BRAND_NAME,
  DEFAULT_CURRENCY_INFO,
  TAX_SHEMAS_SA,
  LEAD_TYPE,
};
