import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FIELDS, PRICEFIELDSSHORT, PRICELABELS, TRIPSLABELS,
} from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';
import { DEFAULTTIME } from '../../../../../../constants/time';

import { findDepartments } from '../../../../../../bi/utils/trip';
import { formatDate } from '../../../../../../bi/utils/formatDate';

import Input from '../../../../../../components/input';
import { FlatButton } from '../../../../../../components/button';

import SelectProject from '../../../selectProject';
import SelectDepartment from '../../../selectDepartment';

import styles from '../../../../styles/form.module.scss';

const LABELS = {
  EMPLOYEE: 'Сотрудник:',
  START_PLACE: 'Пункт отправления:',
  END_PLACE: 'Пункт прибытия:',
  CHECKIN_DATE: 'Дата подачи машины:',
  CHECKIN_TIME: 'Время подачи машины:',
  CHECKOUT_DATE: 'Дата приезда / дата вылета:',
  CHECKOUT_TIME: 'Время приезда / время вылета:',
  ARRIVAL_NUMBER: 'Номер рейса самолета или поезда:',
  CARRIAGE_NUMBER: 'Номер вагона поезда:',
  CAR_CLASS: 'Класс машины:',
  CAR_MODEL: 'Модель машины:',
  CAPACITY: 'Количество посадочных мест:',
  NOTE: 'Заметки:',
  TABLE_SIGNATURE: 'Надпись на табличке:',
  PROVIDER: 'Название провайдера:',
  VIEWERS: 'Наблюдатели:',
};

class PartTransferForm extends Component {
  static propTypes = {
    transfer: PropTypes.object,
    onConfirm: PropTypes.func,
    projects: PropTypes.arrayOf(PropTypes.object),
    tripService: PropTypes.object.isRequired,
    isTripPaidByPersonalFunds: PropTypes.bool,
  };

  static defaultProps = {
    transfer: {},
    onConfirm: () => {},
    projects: [],
    employees: [],
    isTripPaidByPersonalFunds: false,
  };

  constructor(props) {
    super();

    const transfer = props.transfer;
    const currentEmployeeId = transfer && transfer.Employees && transfer.Employees[0] && transfer.Employees[0].Id;

    this.state = {
      OrderTripItemId: transfer.OrderTripItemId,
      ServiceType: transfer.ServiceType,
      Status: 3,
      JsonData: JSON.parse(transfer.JsonData),
      PriceDetails: JSON.parse(transfer.JsonData).PriceDetails,
      Employees: transfer.Employees,
      CompanyId: transfer.CompanyId || 0,
      ProjectId: transfer.ProjectId || 0,
      EmployeeId: currentEmployeeId,
      DepartmentId: transfer.DepartmentId || 0,
      providerName: transfer.ProviderName,
      departments: [],
    };
  }

  componentDidMount() {
    const { EmployeeId, CompanyId } = this.state;

    this.props.tripService.getEmployeeDataByID(EmployeeId)
      .then(((res) => this.setState({ departments: findDepartments(res, CompanyId) })))
      .catch(() => this.setState({ departments: [] }));
  }

  handleConfirm = () => {
    const {
      OrderTripItemId,
      ServiceType,
      Status,
      JsonData,
      PriceDetails,
      Employees,
      CompanyId,
      ProjectId,
      DepartmentId,
    } = this.state;

    const {
      Base, Commission, Fee,
    } = PriceDetails;

    JsonData.EmployeeIds = Employees.map((item) => item.Id);
    JsonData.PriceDetails = {
      ...PriceDetails,
      Base: parseFloat(Base, 10) || 0,
      Commission: parseFloat(Commission, 10) || 0,
      Fee: parseFloat(Fee, 10) || 0,
    };

    const item = {
      OrderTripItemId,
      TripId: 0,
      Status,
      ServiceType,
      CompanyId: parseInt(CompanyId, 10),
      ProjectId: parseInt(ProjectId, 10),
      DepartmentId: parseInt(DepartmentId, 10),
      JsonData: JSON.stringify(JsonData),
    };

    this.props.onConfirm(item);
  };

  handlerChangeInput = (e, field, value) => {
    this.setState((state) => {
      const newState = { ...state };
      const part = field.split('.');

      if (part.length === 1) {
        newState.JsonData[field] = value;
      } else {
        newState.JsonData[part[0]][part[1]] = value;
      }

      return newState;
    });
  };

  handlerChangePriceInput = (e, field, value) => this.setState({
    PriceDetails: {
      ...this.state.PriceDetails,
      [field]: value,
    },
  });

  handleSelectProject = ({ id }) => this.setState({ ProjectId: id });

  handleSelectDepartment = ({ id }) => this.setState({ DepartmentId: id });

  renderSelectProject = () => {
    const { projects, isTripPaidByPersonalFunds } = this.props;
    const { ProjectId } = this.state;

    if (isTripPaidByPersonalFunds) return null;

    return (
      <SelectProject
        currentProjectId={ ProjectId }
        projects={ projects }
        onSelect={ this.handleSelectProject }
      />
    );
  };

  render() {
    const {
      PriceDetails,
      Employees,
      DepartmentId,
      providerName,
      departments,
    } = this.state;

    const data = this.state.JsonData;
    const {
      StartPlace,
      EndPlace,
      CheckinDate,
      CheckoutDate,
      ArrivalNumber,
      CarriageNumber,
      CarClass,
      CarModel,
      Capacity,
      Note,
      TableSignature,
      Viewers,
    } = data;
    const employee = Employees.map((item) => `${item.Surname} ${item.Name} ${item.Patronymic ? item.Patronymic : ''}`);
    const employeeId = Employees.map((item) => item.Id);
    const viewers = Viewers ? Viewers.join(' ') : '';

    const infoHtml = (
      <div className={ styles.info }>
        <span>
          { LABELS.EMPLOYEE }
          { ' ' }
          { employee }
        </span>
        <span>
          { LABELS.START_PLACE }
          { ' ' }
          { StartPlace.Address }
        </span>
        <span>
          { LABELS.END_PLACE }
          { ' ' }
          { EndPlace.Address }
        </span>
        <span>
          { LABELS.CHECKIN_DATE }
          { ' ' }
          { formatDate(CheckinDate) }
        </span>
        <span>
          { LABELS.CHECKIN_TIME }
          { ' ' }
          { formatDate(CheckinDate, DEFAULTTIME) }
        </span>
        <span>
          { LABELS.CHECKOUT_DATE }
          { ' ' }
          { formatDate(CheckoutDate) }
        </span>
        <span>
          { LABELS.CHECKOUT_TIME }
          { ' ' }
          { formatDate(CheckoutDate, DEFAULTTIME) }
        </span>
        <span>
          { LABELS.ARRIVAL_NUMBER }
          { ' ' }
          { ArrivalNumber }
        </span>
        <span>
          { LABELS.CARRIAGE_NUMBER }
          { ' ' }
          { CarriageNumber }
        </span>
        <span>
          { LABELS.CAR_CLASS }
          { ' ' }
          { CarClass }
        </span>
        <span>
          { LABELS.CAR_MODEL }
          { ' ' }
          { CarModel }
        </span>
        <span>
          { LABELS.CAPACITY }
          { ' ' }
          { Capacity }
        </span>
        <span>
          { LABELS.NOTE }
          { ' ' }
          { Note }
        </span>
        <span>
          { LABELS.TABLE_SIGNATURE }
          { ' ' }
          { TableSignature }
        </span>
        <span>
          { LABELS.PROVIDER }
          { ' ' }
          { providerName }
        </span>
        <span>
          { LABELS.VIEWERS }
          { ' ' }
          { viewers }
        </span>
      </div>
    );

    return (
      <div className={ styles.wrap }>
        <div className={ `${styles.row} ${styles.panel}` }>
          <h4>Данные ваучера</h4>
          <div className={ styles.row }>
            { infoHtml }
          </div>
          <div className={ styles.row }>
            { this.renderSelectProject() }
            <SelectDepartment
              departments={ departments }
              onSelect={ this.handleSelectDepartment }
              currentDepartmentId={ DepartmentId }
              currentEmployeeId={ employeeId[0] }
            />
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-2'] }>
              <Input
                field={ FIELDS.PARTNERORDERID }
                value={ data.PartnerOrderId }
                label={ TRIPSLABELS.PARTNERORDERID }
                onChange={ this.handlerChangeInput }
              />
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ FIELDS.PROVIDERORDERID }
                value={ data.ProviderOrderId }
                label={ TRIPSLABELS.PROVIDERORDERID }
                onChange={ this.handlerChangeInput }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ PRICEFIELDSSHORT.BASE }
                value={ PriceDetails.Base }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                label={ PRICELABELS.BASEPRICE }
                onChange={ this.handlerChangePriceInput }
              />
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ PRICEFIELDSSHORT.COMMISSION }
                value={ PriceDetails.Commission }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                label={ PRICELABELS.TAXPENALTIES }
                onChange={ this.handlerChangePriceInput }
              />
            </div>
            <div className={ styles['col-1-3'] }>
              <Input
                field={ PRICEFIELDSSHORT.FEE }
                value={ PriceDetails.Fee }
                type={ COMPONENTS.INPUT.TYPE.NUMBER }
                label={ PRICELABELS.FEE }
                onChange={ this.handlerChangePriceInput }
              />
            </div>
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ TRIPSLABELS.CONFIRM }
            />
          </div>
        </div>
      </div>
    );
  }
}

export { PartTransferForm };
