import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './dialog.module.scss';

class ModalDialog extends Component {
  constructor(props) {
    super();

    this.hideOnOuterClick = this.hideOnOuterClick.bind(this);
    this.fadeIn = this.fadeIn.bind(this);
    this.fadeOut = this.fadeOut.bind(this);

    let opacity = 0;
    let visibility = 'hidden';

    if (props.show) {
      opacity = 1;
      visibility = 'visible';
    }

    this.state = {
      opacity,
      visibility,
      show: props.show,
    };
  }

  hideOnOuterClick(event) {
    if (this.props.closeOnOuterClick === false) return;
    if (event.target.dataset.modal && this.props.onClose instanceof Function) this.props.onClose(event);
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (this.props.show !== props.show) {
      if (props.show === true) this.fadeIn();
      else this.fadeOut();
    }
  }

  fadeIn() {
    this.setState({
      visibility: 'visible',
      show: true,
    }, () => {
      setTimeout(() => {
        this.setState({ opacity: 1 });
      }, 100);
    });
  }

  fadeOut() {
    this.setState({ opacity: 0 }, () => {
      setTimeout(() => {
        this.setState({ show: false });
      }, 300);
    });
  }

  render() {
    if (!this.state.show) return null;

    const modalStyle = Object.assign({}, this.state);

    return (
      <div className={ styles.overflow } style={ modalStyle } onClick={ this.hideOnOuterClick } data-modal='true'>
        <div className={ styles.modal }>
          { this.props.children }
        </div>
      </div>
    );
  }
}

ModalDialog.propTypes = {
  transitionSpeed: PropTypes.number,
  onClose: PropTypes.func,
  closeOnOuterClick: PropTypes.func,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node,
};

export default ModalDialog;
