import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input';
import { FlatButton } from '../../../../components/button';

import { FIELDS } from '../../../../bi/constants/trips';

import style from '../../styles/form.module.scss';

const LABELS = {
  ORDER_ID: 'Введите Id поездки',
  ADD_TRIP: 'Добавить поездку',
};

export default class AddTaxiTrip extends Component {
  static propTypes = {
    taxi: PropTypes.object.isRequired,
    taxiValidation: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      OrderId: 0,
      validation: { OrderId: '' },
    };
  }

  handleChangeInput = (e, field, value) => {
    this.setState({ OrderId: value });
  };

  handleValidateInput = (e, field) => {
    const { taxiValidation } = this.props;
    const { OrderId } = this.state;

    const result = taxiValidation(field, OrderId);

    this.setState({ validation: { OrderId: result } });
  };

  handleConfirm = () => {
    const { onConfirm, taxi } = this.props;
    const { OrderId } = this.state;

    const { VoucherId } = JSON.parse(taxi.JsonData);

    onConfirm(OrderId, VoucherId, taxi.Id);
  };

  render() {
    const { OrderId, validation } = this.state;
    const { loading } = this.props;

    return (
      <div className={ style.wrap }>
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <Input
              field={ FIELDS.ORDER_ID }
              value={ OrderId }
              label={ LABELS.ORDER_ID }
              onChange={ this.handleChangeInput }
              onBlur={ this.handleValidateInput }
              valid={ validation.OrderId }
            />
          </div>
        </div>
        <div className={ style.row }>
          <div className={ style['col-1-3'] }>
            <FlatButton
              onClick={ this.handleConfirm }
              label={ LABELS.ADD_TRIP }
              loading={ loading }
              disabled={ loading }
            />
          </div>
        </div>
      </div>
    );
  }
}
