import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ANCHORS, TITLE } from '../../bi/constants/employee';
import styles from './styles/employee.module.scss';

const LABELS = {
  PROHIBITION_TAXI: 'запретить покупку такси',
  TITLE_ALLOWED: 'Разрешено:',
};

const FIELDS = { VIP_HALL: 'Vip-залы' };

class employeeTP extends Component {
  static propTypes = {
    travelPolicy: PropTypes.object,
    setRef: PropTypes.func.isRequired,
  };

  static defaultProps = { travelPolicy: null };

  renderContentTP = () => {
    const { travelPolicy } = this.props;
    if (!travelPolicy) {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{ TITLE.NOT_RESTRICTIONS }</label>
          </div>
        </div>
      );
    }

    const {
      Name: name, Description: description, Messages: messages,
    } = travelPolicy;

    const descriptionBlock = description
      && (
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{ TITLE.DESCRIPTION }</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ description }</span>
          </div>
        </div>
      );

    return (
      <div>
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <label>{ TITLE.NANE }</label>
          </div>
          <div className={ styles['col-1-3'] }>
            <span>{ name }</span>
          </div>
        </div>
        { descriptionBlock }
        { this.renderTPRules(messages) }
      </div>
    );
  };

  renderTPRules = (messages) => {
    const getColumnInformationHtml = (isEmpty, rules, exception, name) => {
      if (isEmpty && name === FIELDS.VIP_HALL) {
        return <div className={ styles['col-1-2'] }>{ TITLE.NOT_AVAILABLE_VIP_HALL }</div>;
      }

      if (isEmpty) {
        return <div className={ styles['col-1-2'] }>{ TITLE.NOT_RESTRICTIONS }</div>;
      }

      return (
        <div className={ styles['col-1-3'] }>
          { this.renderTPRulesList(rules) }
          { this.renderTPExeption(exception) }
        </div>
      );
    };

    return (
      <div>
        { messages.map(({
          name, rules, exception, isEmpty,
        }) => (
          <div className={ styles.row } key={ `TPRule-${name}` }>
            <div className={ styles['col-1-2'] }>
              <label>{ name }</label>
            </div>
            { getColumnInformationHtml(isEmpty, rules, exception, name) }
          </div>
        )) }
      </div>
    );
  };

  renderTPRulesList = (rules, exeptionName) => {
    const prohibitBook = rules[0] === LABELS.PROHIBITION_TAXI;
    const titleName = !prohibitBook ? (exeptionName || LABELS.TITLE_ALLOWED) : '';

    const title = !prohibitBook && (
      <li className={ styles.item }>
        <span className={ styles.head }>{ titleName }</span>
        { !rules.length && !exeptionName && <span>{ TITLE.NOT_RESTRICTIONS }</span> }
      </li>);

    return (
      <ul className={ styles.list }>
        { title }
        { rules.map((rule) => (
          <li className={ styles.item } key={ `${rule}--${exeptionName}` }>
            { rule }
          </li>
        )) }
      </ul>
    );
  };

  renderTPExeption = (exceptions) => {
    if (!exceptions.length) {
      return null;
    }

    return (
      <div>
        <span className={ styles.head }>{ TITLE.EXCEPTIONS }</span>
        { exceptions.map(({ name, rules }) => (
          <div key={ `${name}--${rules}` }>
            { this.renderTPRulesList(rules, name) }
          </div>
        )) }
      </div>
    );
  };

  render() {
    const { setRef } = this.props;

    return (
      <div id='tp' className={ styles.col1 } ref={ (ref) => setRef(ANCHORS.TP, ref) }>
        <div className={ styles.title }>
          { TITLE.EMPLOYEE_TRAVELPOLICY }
        </div>
        { this.renderContentTP() }
      </div>
    );
  }
}
export default employeeTP;
