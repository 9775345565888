import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'sw-ui';

import styles from '../../styles/settings.module.css';

const LABELS = {
  SETTING: 'Настройка закрывающих документов',
  DOCUMENTS: 'Документы для клиента на ОПЗ',
  YES: 'Да',
  NO: 'Нет',
};

const SettingOPZ = ({ documentsConstructorService }) => {
  const initState = documentsConstructorService.get().isOPZ;
  const [OPZ, setOPZ] = useState(initState);

  const update = ({ isOPZ }) => setOPZ(isOPZ);

  useEffect(() => {
    const unSubscribe = documentsConstructorService.subscribe(update);

    return () => {
      if (unSubscribe) unSubscribe();
    };
  }, [documentsConstructorService]);

  const handleChange = (value) => {
    setOPZ(value);
    documentsConstructorService.setOPZ(value);
  };

  return (
    <div className={ styles.settings }>
      <div className={ styles.wrap }>
        <div className={ styles.title }>{ LABELS.SETTING }</div>
        <div className={ styles.content }>
          <div className={ styles.title }>{ LABELS.DOCUMENTS }</div>
          <div className={ styles.radio }>
            <RadioButton
              onChange={ () => handleChange(true) }
              checked={ OPZ }
            >
              { LABELS.YES }
            </RadioButton>
            <RadioButton
              onChange={ () => handleChange(false) }
              checked={ !OPZ }
            >
              { LABELS.NO }
            </RadioButton>
          </div>
        </div>
      </div>
    </div>
  );
};

SettingOPZ.propTypes = { documentsConstructorService: PropTypes.object.isRequired };

export { SettingOPZ };
