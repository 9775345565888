import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Tooltip, Dialog,
} from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';
import Suggest from '../../../../../components/Suggest';
import NoResult from '../../../../../components/NoResult';
import AviaContractForm from './aviaCodeForm';

import COMPONENTS from '../../../../../bi/constants/components';

import { selectObj } from '../../../../../bi/utils/air';

import { ERROR_MESSAGE, FIELDS } from '../../../../../bi/constants/profile';
import { AIRLINE_PROVIDERS } from '../../../../../constants/setting';
import { DECISION_BUTTONS_CAMEL } from '../../../../../bi/constants/decisionButtons';
import { BONUSES_TYPE } from '../../../../../bi/constants/bonuses';

import styles from '../../../styles/company.module.scss';

const {
  TOOLTIP: { POSITION: { RIGHT } },
  BUTTON: { THEME: { FLAT } },
} = COMPONENTS;

const LABELS = {
  ADD_CODE: 'ДОБАВИТЬ КОД',
  AEROFLOT_DIALOG: {
    TITLE: 'Необходимо выбрать схему выписки билетов авиакомпании Аэрофлот для аккаунта:',
    SUGGEST_LABEL: 'Схема выписки',
  },
  WARNING_MESSAGE: 'При переводе выписки в GDS Мixvel будет взиматься дополнительная плата +222р за сегмент',
  MIXVEL: 'Mixvel',
  SU: 'NDC SU',
  CURRENT_PROVIDER_TEXT: '(текущий поставщик)',
};

const SCHEMES = [
  { id: AIRLINE_PROVIDERS.AEROFLOT, name: LABELS.SU },
  { id: AIRLINE_PROVIDERS.MIXVEL, name: LABELS.MIXVEL },
];

export default class AviaContract extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    isSmartAgent: PropTypes.bool.isRequired,
    oldAggregationScheme: PropTypes.object.isRequired,
    setAggregationScheme: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    const { profile, aviaContractData } = props.companyService.get();
    const accountId = Number(location.pathname.split('/')[2]);

    this.state = {
      aeroflotDeleteDialog: false,
      aeroflotDeleteScheme: 0,
      currentItem: null,
      accountId,
      profile,
      aviaContractData,
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.companyService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = (state) => this.setState({ ...state });

  handleCloseErrorDialog = () => this.props.companyService.closeErrorAviaContractDialog();

  handleAddAviaContract = () => this.props.companyService.addAviaContract();

  handleCloseAviaContract = () => this.props.companyService.closeAviaContract();

  handleSaveContract = () => {
    const { accountId, aviaContractData } = this.state;
    const aviaContract = aviaContractData.activeAviaContract;

    this.props.companyService.preSaveAviaContract(accountId);

    if (aviaContract.AggregationScheme?.aeroflot !== null && aviaContract.Iata === 0) {
      this.props.setAggregationScheme(aviaContract.AggregationScheme)
    }
  };

  handleChangeField = (field, value) => (
    this.props.companyService.changeFieldAviaContractData(`${FIELDS.ACTIVE_AVIA_CONTRACT}.${field}`, value)
  );

  getDiscount = ({ Type, Iata }) => {
    if (Type === BONUSES_TYPE.MILES && Iata !== AIRLINE_PROVIDERS.S7) return 0;

    if (Type === BONUSES_TYPE.MILES && Iata === AIRLINE_PROVIDERS.S7) return 2;

    return 1;
  };

  handleShowAviaContract = (item = null) => {
    const modifiedItem = {
      ...item,
      IsDiscount: this.getDiscount(item),
    };

    this.props.companyService.showAviaContract(modifiedItem);
  };

  handleShowAeroflotDialog = () => (
    this.setState((prev) => ({ ...prev, aeroflotDeleteDialog: !prev.aeroflotDeleteDialog }))
  );

  handleDeleteContract = (item) => {
    const { aeroflotDeleteScheme, accountId } = this.state;
    const { companyService: { removeAviaContract } } = this.props;
    const { Iata } = item;

    const isAeroflot = Iata === 0;

    if (isAeroflot) {
      return this.setState({
        aeroflotDeleteDialog: true,
        currentItem: item,
      });
    }

    removeAviaContract({
      ...item, SchemeType: aeroflotDeleteScheme, AccountId: accountId,
    });
  };

  handleDeleteAeroflotContract = (currentItem, aeroflotDeleteScheme, accountId) => {
    const { companyService: { removeAviaContract } } = this.props;

    removeAviaContract({
      ...currentItem,
      SchemeType: aeroflotDeleteScheme,
      AccountId: accountId,
      AggregationScheme: {
        aeroflot: aeroflotDeleteScheme,
      },
    });

    this.props.setAggregationScheme(aeroflotDeleteScheme);
  };

  renderErrorDialog = (showError, message) => (
    showError
    && <NoResult
      message={ message }
      onClose={ this.handleCloseErrorDialog }
    />
  );

  renderAeroflotDeleteDialog = () => {
    const {
      aeroflotDeleteDialog, currentItem, aeroflotDeleteScheme, accountId,
    } = this.state;
    const { oldAggregationScheme } = this.props

    if (!aeroflotDeleteDialog) return null;

    const currentProviderHtml = oldAggregationScheme => {
      if (oldAggregationScheme === null) {
        return null;
      }

      if (oldAggregationScheme.aeroflot === AIRLINE_PROVIDERS.AEROFLOT) {
        return (
          <div className={ styles.message }>
            { LABELS.SU } { LABELS.CURRENT_PROVIDER_TEXT }
          </div>
        );
      }

      return (
        <div className={ styles.message }>
          { LABELS.MIXVEL } { LABELS.CURRENT_PROVIDER_TEXT }
        </div>
      );
    }

    const warningMessageHtml = currentProvider => {
      if (currentProvider === AIRLINE_PROVIDERS.MIXVEL) {
        return (
          <div className={ styles.message }>{ LABELS.WARNING_MESSAGE }</div>
        );
      }

      return null;
    }

    const currentScheme = SCHEMES.find(s => s.id === this.state.aeroflotDeleteScheme);
    const defaultValue = !currentScheme ? '' : currentScheme.name;

    return (
      <Dialog showCloseButton onClick={ this.handleShowAeroflotDialog } width='500px'>
        <div className={ styles.dialog_aeroflot }>
          <div>{ LABELS.AEROFLOT_DIALOG.TITLE }</div>
          <div className={ styles.suggest }>
            <Suggest
              withScroll
              title={ LABELS.AEROFLOT_DIALOG.SUGGEST_LABEL }
              suggests={ SCHEMES }
              currentLabel={ defaultValue }
              onSelect={ ({ id }) => this.setState({ aeroflotDeleteScheme: id }) }
            />
          </div>
          { currentProviderHtml(oldAggregationScheme) }
          { warningMessageHtml(aeroflotDeleteScheme) }
          <div className={ styles.buttons }>
            <DecisionButtons
              disabled={ aeroflotDeleteScheme === 0 }
              loadingIncluded
              onCancel={ this.handleShowAeroflotDialog }
              onSave={ async () => this.handleDeleteAeroflotContract(currentItem, aeroflotDeleteScheme, accountId) }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  renderItem = (item) => {
    const { Number, Iata } = item;
    const { loading } = this.state.aviaContractData;
    const finderIata = selectObj(item).find(({ cost }) => cost === Iata);
    const aviaCompanyName = finderIata ? finderIata.name : Iata;

    return item && (
      <div
        key={ Number }
        className={ `${styles.row} ${styles['button-change']}` }
      >
        <div className={ `${styles.avia_info} ${styles['col-1-3']}` }>
          <div className={ `${styles.avia_info__item} ${styles['col-1-2']}` }>
            <div className={ styles.avia_info__label }> Номер:</div>
            <div>{ Number }</div>
          </div>
          <div className={ `${styles.avia_info__item} ${styles['col-1-2']}` }>
            <div className={ styles.avia_info__label }> Авиакомпания:</div>
            <div>{ aviaCompanyName }</div>
          </div>
        </div>
        <Button
          label={ DECISION_BUTTONS_CAMEL.LABELS.EDIT }
          theme={ FLAT }
          onClick={ () => this.handleShowAviaContract(item) }
          className={ styles.button }
        />
        <Button
          label={ DECISION_BUTTONS_CAMEL.LABELS.DELETE }
          theme={ FLAT }
          onClick={ () => this.handleDeleteContract(item) }
          className={ styles.button }
          loading={ loading }
        />
      </div>
    );
  };

  renderShowAviaCodeForm = () => {
    const { aviaContractData, profile: { aviaContracts } } = this.state;
    const { oldAggregationScheme } = this.props;

    return (
      <AviaContractForm
        aviaContractData={ aviaContractData }
        aviaContracts={ aviaContracts }
        onClose={ this.handleCloseAviaContract }
        onSave={ this.handleSaveContract }
        onChange={ this.handleChangeField }
        oldAggregationScheme={ oldAggregationScheme }
      />
    );
  };

  renderTooltip = () => (this.props.isSmartAgent
    ? (
      <Tooltip
        position={ RIGHT }
      >
        { ERROR_MESSAGE.TOOLTIP_ERROR }
      </Tooltip>
    )
    : null);

  render() {
    const { isSmartAgent } = this.props;
    const {
      aviaContractData: {
        showAviaContractDialog, showErrorSaveAviaContractDialog, showErrorDeleteAviaContractDialog,
      }, profile: { aviaContracts },
    } = this.state;
    const aviaCodeFormHtml = showAviaContractDialog && this.renderShowAviaCodeForm();

    return (
      <div className={ styles.hotel_corporate }>
        <div className={ styles.form }>
          <label>Корпоративный код авиакомпании</label>
          { aviaContracts.map(this.renderItem) }
          <div className={ styles.row }>
            <div className='sw-tooltip-wrapper'>
              <Button
                label={ LABELS.ADD_CODE }
                theme={ FLAT }
                onClick={ () => this.handleAddAviaContract() }
                className={ styles.button }
                disabled={ isSmartAgent }
              />
              { this.renderTooltip() }
            </div>
          </div>
        </div>
        { aviaCodeFormHtml }
        { this.renderErrorDialog(showErrorSaveAviaContractDialog, ERROR_MESSAGE.SAVE) }
        { this.renderErrorDialog(showErrorDeleteAviaContractDialog, ERROR_MESSAGE.GET) }
        { this.renderAeroflotDeleteDialog() }
      </div>
    );
  }
}
