import { isMoment, formatDate } from './formatDate';

import { ARCHIVED } from '../constants/company';
import { DATE } from '../../constants/time';
import MoneyFormat from './money';

const validationDatesWriteOff = (startDate, endDate, isSame) => {
  const isEndDateMoment = isMoment(endDate);

  if (!isEndDateMoment) return false;

  return isSame(startDate, endDate);
};

const getArchivedEmployees = (employees) => employees.filter(({ Status }) => Status === ARCHIVED);

const getUnArchivedEmployees = (employees) => employees.filter(({ Status }) => Status !== ARCHIVED);

const mappedRates = (rates, isCopy) => rates.map((item) => {
  const {
    Breakfast, Vat, VatRate, Periods,
  } = item;

  const newPeriods = Periods.map((period) => {
    const {
      Id, StartLifeTime, EndLifeTime, Price, RefundableHours,
    } = period;

    return {
      ...period,
      Id: isCopy ? 0 : Id,
      StartLifeTime: formatDate(StartLifeTime, DATE),
      EndLifeTime: formatDate(EndLifeTime, DATE),
      Price: MoneyFormat.symbolWithPrice(Price),
      RefundableHours: Number(RefundableHours),
    };
  });

  return {
    ...item,
    Breakfast: !!Breakfast,
    Vat: !!Vat,
    VatRate,
    Periods: newPeriods,
  };
});

const getMappedUserSettings = (data) => {
  const settings = {
    ...data,
    displayPersonalData: {
      ...data.displayPersonalData,
      ...data.displayPersonalData.displayTripsEmployee,
    },
    hideSections: {
      ...data.hideSections,
      ...data.hideSections.searchSettings,
    },
    employeeRestriction: { ...data.employeeRestriction },
    notification: { userCompanyNotification: data.userCompanyNotification },
    adminSettingsSection: { ...data.adminSettings },
  };

  delete settings.displayPersonalData.displayTripsEmployee;
  delete settings.hideSections.searchSettings;
  delete settings.userCompanyNotification;
  delete settings.adminSettings;

  return settings;
};

const setMappedUserSettings = (data) => {
  const {
    displayPersonalData: {
      cartEmployeeBirthday,
      cyrillicForeignerPassport,
      birthday,
      documents,
      fullName,
    },
    employeeRestriction: {
      departmentOnly,
      noChangeOrReturnTrip,
      enableAnalyticsReport,
      noPersonalBonusCards,
      removeFlightCertificate,
      hideAdditionalOrders,
    },
    hideSections: {
      noExpenseReport,
      noAnalytics,
      noMice,
      noBookingTaxi,
      aeroExpress,
      conference,
      groupCheckin,
      transfer,
      vipHall,
    },
    adminSettingsSection,
    notification: { userCompanyNotification },
    settings1C,
    userId,
  } = data;

  return {
    displayPersonalData: {
      cartEmployeeBirthday,
      cyrillicForeignerPassport,
      displayTripsEmployee: {
        birthday,
        documents,
        fullName,
      },
    },
    employeeRestriction: {
      departmentOnly,
      noChangeOrReturnTrip,
      enableAnalyticsReport,
      noPersonalBonusCards,
      hideAdditionalOrders,
      removeFlightCertificate,
    },
    hideSections: {
      noAnalytics,
      noBookingTaxi,
      searchSettings: {
        aeroExpress,
        conference,
        groupCheckin,
        transfer,
        vipHall,
      },
      noExpenseReport,
      noMice,
    },
    adminSettings: { ...adminSettingsSection },
    settings1C: { ...settings1C },
    userCompanyNotification,
    userId,
  };
};

export {
  validationDatesWriteOff as default,
  validationDatesWriteOff,
  getArchivedEmployees,
  getUnArchivedEmployees,
  mappedRates,
  getMappedUserSettings,
  setMappedUserSettings,
};
