import React from 'react';
import { createRoot } from 'react-dom/client';

import SearchRegions from './pages/SearchRegions';
import Region from './pages/Region';
import AddRegion from './pages/AddRegion';

const PAGE_NAME = {
  region: Region,
  searchRegions: SearchRegions,
  addRegion: AddRegion,
};

const renderComponents = (box, opts) => {
  const {
    regionsPageId,
    regionId,
    regionsService,
    workplaceService,
    toRegion,
  } = opts;

  const Page = PAGE_NAME[regionsPageId];
  const root = createRoot(box);

  root.render(
    <Page
      regionsService={ regionsService }
      workplaceService={ workplaceService }
      regionId={ regionId }
      toRegion={ toRegion }
    />,
  );

  return root;
};

export default renderComponents;
