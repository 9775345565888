import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';
import { Button, Dialog } from 'sw-ui';

import CircularLoaders from '../../components/loaders';
import ListItem from '../../components/listItem';
import AjaxButton from '../../components/ajaxButton';

import UserItem from './components/item';
import AddUserForm from './components/addUserForm';
import { AddSAUserForm } from './components/addSAUserForm';

import COMPONENTS from '../../bi/constants/components';

import styles from './styles/approve-users.module.scss';

const { BUTTON: { THEME: { SECOND } } } = COMPONENTS;

export const TYPE_ADD_DIALOG = {
  SMARTAGENT: 'SA',
  KZ: 'KZ',
};

const LABELS = {
  EMPTY_LIST: 'Нет ни одного пользователя для подтверждения',
  ALERT: {
    APPROVE: 'Не удалось подтвердить пользователя',
    REJECT: 'Не удалось отклонить пользователя',
  },
  ADD_USER: 'Добавить аккаунт',
  ADD_SA_USER: 'Добавить Smartagent',
  ADD_KZ_USER: 'Добавить Smartway KZ',
  TITLE_MODAL_SA: 'Добавление нового Smartagent',
  TITLE_MODAL_KZ: 'Добавление нового Smartway KZ',
};

class Users extends Component {
  static propTypes = {
    userService: PropTypes.object.isRequired,
    appService: PropTypes.object.isRequired,
    toCompany: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      list: [],
      addUserModal: {
        opened: false,
        error: null,
      },
      roles: [],
      aggregatorIds: [],
      waitResUserRoles: false,
      addAnotherUserDialog: false,
      typeDialog: null,
    };
  }

  componentDidMount() {
    const { userService } = this.props;

    this.unsubscribeCb = userService.subscribe(this.updateUserList);

    this.loadingData();
  }

  componentWillUnmount() {
    if (this.unsubscribeCb) {
      this.unsubscribeCb();
    }
  }

  updateUserList = () => {
    this.setState({
      list: this.props.userService.get().users,
      aggregatorIds: this.props.userService.getAggregatorIds(),
      loading: false,
    });
  };

  loadingData = () => {
    this.setState({ loading: true });

    const {
      userService,
      appService,
    } = this.props;

    const filter = appService.get().params.filter;

    userService.load(filter);
  };

  approveUser = (user) => {
    this.props.userService.approveUser(user.Id, user.Email).then(() => {
      this.loadingData();
    })
      .catch(() => {
        alert(`${LABELS.ALERT.APPROVE} - ${user.Email}`);
      });
  };

  rejectUser = (user) => {
    this.props.userService.rejectUser(user.Id).then(() => {
      this.loadingData();
    })
      .catch(() => {
        alert(`${LABELS.ALERT.REJECT} - ${user.Email}`);
      });
  };

  handleAddUserModalOpen = () => {
    const getUserRoles = () => {
      this.props.userService.getUserRoles()
        .then((res) => {
          this.setState({
            addUserModal: {
              ...this.state.addUserModal,
              opened: true,
            },
            roles: res,
            waitResUserRoles: false,
          });
        });
    };

    this.setState({ waitResUserRoles: true }, getUserRoles);
  };

  toggleAddSAUserDialog = (value, type) => this.setState({
    addAnotherUserDialog: value,
    typeDialog: type,
  });

  handleAddUserModalClose = () => {
    this.setState({
      addUserModal: {
        opened: false,
        error: null,
      },
    });
  };

  addUser = (user) => {
    this.setState({
      addUserModal: {
        ...this.state.addUserModal,
        error: null,
      },
    });

    this.props.userService.registerUser(user)
      .then((res) => this.props.toCompany(res.AccountId, res.CompanyId))
      .finally(() => this.handleAddUserModalClose());
  };

  renderLoading = () => (
    <CircularLoaders wrapClassName='content-loader-wrap' />
  );

  renderEmptyList = () => (
    <div className='empty-list'>
      { LABELS.EMPTY_LIST }
    </div>
  );

  renderAddUserDialog = () => {
    const {
      roles,
      addUserModal: {
        opened,
        error,
      },
      aggregatorIds,
    } = this.state;

    if (!opened) return null;

    return (
      <Dialog onClick={ this.handleAddUserModalClose }>
        <AddUserForm
          error={ error }
          roles={ roles }
          aggregatorIds={ aggregatorIds }
          onAdd={ this.addUser }
          onClose={ this.handleAddUserModalClose }
          userService={ this.props.userService }
        />
      </Dialog>
    );
  };

  renderAddSAUserDialog = () => {
    const { addAnotherUserDialog, typeDialog } = this.state;
    const { userService, userService: { registerSAUser, registerKZUser } } = this.props;

    if (!addAnotherUserDialog || !typeDialog) return null;

    if (typeDialog === TYPE_ADD_DIALOG.KZ) {
      return (
        <AddSAUserForm
          label={ LABELS.TITLE_MODAL_KZ }
          userService={ userService }
          onRegister={ registerKZUser }
          onClose={ () => this.toggleAddSAUserDialog(false, null) }
        />
      );
    }

    return (
      <AddSAUserForm
        label={ LABELS.TITLE_MODAL_SA }
        userService={ userService }
        onRegister={ registerSAUser }
        onClose={ () => this.toggleAddSAUserDialog(false, null) }
      />
    );
  };

  renderAddSmartwayKZ = () => {
    const flag = true;
    // скрытый релиз добавление подакаунта с признаком KZ

    if (flag) {
      return null;
    }

    return (
      <Button
        label={ LABELS.ADD_KZ_USER }
        theme={ SECOND }
        className={ styles.sa_button }
        onClick={ () => this.toggleAddSAUserDialog(true, TYPE_ADD_DIALOG.KZ) }
      />
    );
  };

  render() {
    const {
      list,
      loading,
      waitResUserRoles,
    } = this.state;

    if (loading) return this.renderLoading();
    if (!loading && !list.length) return this.renderEmptyList();

    const itemComponentProps = {
      approveUser: this.approveUser,
      rejectUser: this.rejectUser,
    };

    return (
      <div className={ styles.main }>
        <div className={ styles['add-user-box'] }>
          <AjaxButton
            label={ LABELS.ADD_USER }
            loading={ waitResUserRoles }
            onClick={ this.handleAddUserModalOpen }
          />
          <Button
            label={ LABELS.ADD_SA_USER }
            theme={ SECOND }
            className={ styles.sa_button }
            onClick={ () => this.toggleAddSAUserDialog(true, TYPE_ADD_DIALOG.SMARTAGENT) }
          />
          { this.renderAddSmartwayKZ() }
        </div>
        <ListItem items={ list } itemComponent={ UserItem } itemComponentProps={ itemComponentProps } />
        { this.renderAddUserDialog() }
        { this.renderAddSAUserDialog() }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <Users
      userService={ opts.userService }
      appService={ opts.appService }
      toCompany={ opts.toCompany }
    />,
  );

  return root;
};

export default renderComponents;
