import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../../components/DecisionButtons';
import TextArea from '../../../../../components/textarea';

import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import { COMMENTS } from '../../../../../bi/constants/account';

import styles from '../styles/dialog.module.css';

const LABELS = {
  HEADER: (schema, getSchemas) => `Комментарий к схеме "${getSchemas[schema]}"`,
  WIDTH: '550px',
};

class EditCommentDialog extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    companyId: PropTypes.number.isRequired,
    getSchemas: PropTypes.object,
    schema: PropTypes.string,
    comment: PropTypes.string.isRequired,
  };

  static defaultProps = {
    schema: '',
    getSchemas: {},
  };

  state = {
    waitingResponse: false,
    text: this.props.comment || '',
  };

  handleChange = (editComment) => {
    this.setState({ text: editComment });
  };

  handleConfirm = () => {
    const {
      companyService, companyId, onClose, schema,
    } = this.props;
    const { text } = this.state;

    return companyService.editCommentCompanyHandler(companyId, schema, text)
      .then(() => onClose());
  };

  render() {
    const {
      onClose, schema, getSchemas,
    } = this.props;
    const { waitingResponse, text } = this.state;

    const comment = text === COMMENTS.IntercomComment.NOCOMMENTTEXT
      ? ''
      : text;

    return (
      <Dialog
        onClick={ () => onClose() }
        width={ LABELS.WIDTH }
      >
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            <div className={ styles.header }>
              { LABELS.HEADER(schema, getSchemas) }
            </div>
          </div>
          <TextArea
            value={ comment }
            placeholder={ LABELS.COMMENT }
            className={ styles.text_area }
            onChange={ this.handleChange }
            height={ 100 }
            labelTop
          />
          <div className={ styles.actions }>
            <DecisionButtons
              waitingResponse={ waitingResponse }
              onSave={ this.handleConfirm }
              onCancel={ () => onClose() }
              labelSave={ DECISIONBUTTONS.LABELS.SAVE }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { EditCommentDialog };
