import React from 'react';
import PropTypes from 'prop-types';
import { Button, Select } from 'sw-ui';

import Input from '../../../../components/input';

import { renderVersions } from '../items';

import styles from '../../styles/trip.module.scss';

const LABELS = {
  NEW_TRIP: 'Добавление новой услуги',
  SELECT_USER: 'Выберите пользователя',
  NAME_TRIP: 'Название поездки',
  INTERCOM_LINK: 'Ссылка на чат',
  CREATE_TRIP: 'СОЗДАТЬ ПОЕЗДКУ',
};

const FormNewTrip = ({
  users,
  userId,
  nameTrip,
  intercomLink,
  versions,
  validation,
  submenu,
  loading,
  label,
  isEditCart,
  isCreateCart,
  isCartToTrip,
  onSelectUser,
  onChangeInput,
  onValidationInput,
  onCopyItem,
  onEditItem,
  onConfirmNew,
  addOrderButtonHtml,
  renderOrderSubMenu,
  disabledCreateTrip,
  loadingCreateTrip,
}) => {
  const mappedUsers = users
    ? users.map(({ Id, UserName }) => ({
      label: UserName,
      value: Id,
    }))
    : [];

  const renderUserSelect = () => {
    if (!users) return null;

    return (
      <Select
        className={ styles.none_padding }
        theme='light'
        hideLabel
        search
        nullable
        label={ LABELS.SELECT_USER }
        items={ mappedUsers }
        value={ userId }
        onChange={ (value) => onSelectUser(value) }
      />
    );
  };

  const renderSelectUserAndName = () => {
    if (isEditCart || isCreateCart) return null;

    return (
      <div className={ styles.row }>
        <div className={ styles.col }>
          { renderUserSelect() }
          <div className={ styles.trip_name_input }>
            <Input
              field='name'
              value={ nameTrip }
              label={ LABELS.NAME_TRIP }
              onChange={ onChangeInput }
              onBlur={ onValidationInput }
              valid={ validation.name }
            />
          </div>
        </div>
      </div>
    );
  };

  const renderIntercomLink = () => {
    if (!isCreateCart || isCartToTrip) return null;

    return (
      <div className={ styles.row }>
        <div className={ styles.col }>
          <div className={ styles.trip_name_input }>
            <Input
              field='IntercomLink'
              value={ intercomLink }
              label={ LABELS.INTERCOM_LINK }
              onChange={ onChangeInput }
              onBlur={ onValidationInput }
              valid={ validation.IntercomLink }
            />
          </div>
        </div>
      </div>
    );
  };

  const renderButtonCreateTrip = () => {
    if (isEditCart || versions.length === 0) return null;

    return (
      <Button
        label={ LABELS.CREATE_TRIP }
        theme='second'
        onClick={ onConfirmNew }
        disabled={ disabledCreateTrip }
        loading={ loadingCreateTrip || loading }
        className={ styles.create_btn }
      />
    );
  };

  return (
    <div className={ styles.row }>
      <div className={ styles['col-4-9'] }>
        <div className={ `${styles.row} ${styles.panel}` }>
          <h4>{ label }</h4>
          { renderSelectUserAndName() }
          { renderIntercomLink() }
          <div className={ styles.row }>
            <div className={ styles.action } ref={ submenu }>
              { addOrderButtonHtml() }
              { renderOrderSubMenu() }
            </div>
            { renderButtonCreateTrip() }
          </div>
        </div>
      </div>
      { renderVersions(versions, onCopyItem, onEditItem) }
    </div>
  );
};

FormNewTrip.propTypes = {
  users: PropTypes.array.isRequired,
  userId: PropTypes.string,
  nameTrip: PropTypes.string,
  intercomLink: PropTypes.string,
  versions: PropTypes.array.isRequired,
  validation: PropTypes.object,
  submenu: PropTypes.element.isRequired,
  loading: PropTypes.bool,
  label: PropTypes.string,
  isEditCart: PropTypes.bool,
  isCreateCart: PropTypes.bool,
  isCartToTrip: PropTypes.bool,
  onSelectUser: PropTypes.func,
  onChangeInput: PropTypes.func,
  onValidationInput: PropTypes.func,
  onCopyItem: PropTypes.func,
  onEditItem: PropTypes.func.isRequired,
  onConfirmNew: PropTypes.func.isRequired,
  addOrderButtonHtml: PropTypes.func,
  renderOrderSubMenu: PropTypes.func.isRequired,
  disabledCreateTrip: PropTypes.bool,
  loadingCreateTrip: PropTypes.bool,
};

FormNewTrip.defaultProps = {
  userId: '',
  nameTrip: '',
  intercomLink: '',
  label: LABELS.NEW_TRIP,
  isEditCart: false,
  isCreateCart: false,
  isCartToTrip: false,
  onSelectUser: () => {},
  onChangeInput: () => {},
  onValidationInput: () => {},
  onCopyItem: () => {},
  validation: {},
  addOrderButtonHtml: () => {},
  disabledCreateTrip: false,
  loadingCreateTrip: false,
};

export { FormNewTrip };
