import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { COMPONENTS, Tooltip } from 'sw-ui';

import { REPORTLABALES } from '../../../../bi/constants/reports';
import AjaxButton from '../../../../components/ajaxButton';

import styles from '../../styles/report.module.scss';

const UPDATEINTERVAL = 4000;

const PROGRESS = (progress) => `Подождите, отправка уже идёт: ${progress} %`;

class AutoSendingEdo extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
    businessService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    const { responseSendingProgress, responseSendingStatus } = props.businessService.get();

    this.state = {
      disabled: false,
      responseSendingProgress,
      responseSendingStatus,
    };
  }

  componentDidMount() {
    const { businessService } = this.props;

    this.startHandleCheckStatus();
    this.unsubscribe = businessService.subscribe(this.updateState);
    this.interval = setInterval(this.handleCheckStatus, UPDATEINTERVAL);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.unsubscribe();
  }

  updateState = ({ responseSendingProgress, responseSendingStatus }) => this.setState({
    responseSendingProgress,
    responseSendingStatus,
  });

  startHandleCheckStatus = () => this.props.onDownload(false);

  handleCheckStatus = () => {
    if (this.state.responseSendingStatus === 1) {
      this.props.onDownload(false);
    }
  };

  sendingRequestCreateReport = () => this.props.onDownload(true);

  render() {
    const { responseSendingStatus, responseSendingProgress } = this.state;
    const tooltipMsg = PROGRESS(responseSendingProgress);

    const tooltipHtml = responseSendingStatus === 1
      ? (
        <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }>
          <div className={ styles.tooltip }>{ tooltipMsg }</div>
        </Tooltip>
      )
      : null;

    return (
      <div className={ styles.btn_auto_edo }>
        <div className={ `${styles.action} sw-tooltip-wrapper` }>
          { tooltipHtml }
          <AjaxButton
            label={ REPORTLABALES.SEND }
            disabled={ !!responseSendingStatus }
            onClick={ this.sendingRequestCreateReport }
          />
        </div>
      </div>
    );
  }
}

export default AutoSendingEdo;
