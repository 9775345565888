import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import styles from '../../../styles/settings.module.css';

const LABELS = {
  DIVIDE_INVOICES: 'Разделить счет-фактуры',
  DIVIDE: 'Разделить как в актах',
};

const InvoicesSetting = ({ documentsConstructorService }) => {
  const { DivideAsInTheActs } = documentsConstructorService.get().Invoices;

  const [check, setCheck] = useState(DivideAsInTheActs);

  const handleChangeCheckbox = (value) => {
    setCheck(value);
    documentsConstructorService.setDivideInvoices(value);
  };

  return (
    <div className={ styles.content }>
      <div className={ styles.title }>
        { LABELS.DIVIDE_INVOICES }
      </div>
      <div className={ styles.radio }>
        <Checkbox
          className={ styles.radio_width }
          onChange={ () => handleChangeCheckbox(!check) }
          value={ check }
        >
          { LABELS.DIVIDE }
        </Checkbox>
      </div>
    </div>
  );
};

InvoicesSetting.propTypes = { documentsConstructorService: PropTypes.object.isRequired };

export { InvoicesSetting };
