import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datetime';

import Input from '../../../../components/input';
import Suggest from '../../../../components/Suggest';
import { AddButton } from '../../../../components/AddButton';
import { TYPE_ICON } from '../../../../components/AddButton/consts';
import { FlatButton } from '../../../../components/button';

import {
  CHARGE_TYPES_EC_LC_CONDITIONS,
  CHARGE_VALUE_UNITS_EC_LC_CONDITIONS,
  FIELDS,
  TEXT,
} from '../../../../bi/constants/hotels';
import { TIME_SECONDS } from '../../../../constants/time';

import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import { getNormalimzeTime } from '../../../../bi/utils/hotels';
import { formatDate } from '../../../../utils/date';

import styles from './style.module.css';

const {
  EC_LC_CONDITIONS_FIELDS: {
    CHARGE_VALUE,
    TIME_BORDER,
  },
} = FIELDS;

class EcLcConditions extends Component {
  static propTypes = {
    isNoEdit: PropTypes.bool.isRequired,
    conditions: PropTypes.object.isRequired,
    validation: PropTypes.object,
    updateConditions: PropTypes.func.isRequired,
    deleteConditions: PropTypes.func.isRequired,
    addConditions: PropTypes.func.isRequired,
    setRef: PropTypes.func,
  };

  static defaultProps = {
    validation: null,
    setRef: () => {},
  };

  handleChangeTimeBorder = (value, Type) => {
    const newValue = value ? formatDate(value, TIME_SECONDS) : null;

    this.handleChangeField(newValue, Type, FIELDS.EC_LC_CONDITIONS_FIELDS.TIME_BORDER);
  };

  handleChangeField = (value, type, field) => {
    const { updateConditions } = this.props;

    updateConditions(value, type, field, `${type}.${field}`);
  };

  renderItem = (item) => {
    const { isNoEdit, validation } = this.props;
    const {
      Type,
      TimeBorder,
      ChargeValue,
      ChargeType,
      ChargeTypeUnit,
    } = item;

    const labelChargeValue = Type === 0 ? TEXT.EC_LC_CONDITIONS.EC : TEXT.EC_LC_CONDITIONS.LC;
    const labelTimeBorder = Type === 0 ? TEXT.EC_LC_CONDITIONS.TIME_BORDER_EC : TEXT.EC_LC_CONDITIONS.TIME_BORDER_LC;

    const currentChargeType = CHARGE_VALUE_UNITS_EC_LC_CONDITIONS.find(({ id }) => ChargeType === id);
    const currentChargeTypeUnit = CHARGE_TYPES_EC_LC_CONDITIONS.find(({ id }) => ChargeTypeUnit === id);
    const normalizeTimeBorder = TimeBorder ? getNormalimzeTime(TimeBorder) : '';

    const chargeValueValid = validation
      ? lodashReplaces.getValueInObjByPath(validation, `${Type}.${CHARGE_VALUE}`)
      : '';
    const timeBorderValid = validation
      ? lodashReplaces.getValueInObjByPath(validation, `${Type}.${TIME_BORDER}`)
      : '';

    const messageHtml = timeBorderValid && (
      <span className={ styles['error-msg'] }>{ timeBorderValid }</span>
    );

    return (
      <div className={ styles.item } key={ `ecLc-${Type}` }>
        <div className={ styles.row }>
          <h3>{ labelChargeValue }</h3>
        </div>
        <div className={ styles.row }>
          <div className={ styles.charge_value }>
            <div className={ styles.charge_value_fields }>
              <div className={ styles.charge_input }>
                <Input
                  type='number'
                  disabled={ isNoEdit }
                  value={ ChargeValue }
                  field={ FIELDS.EC_LC_CONDITIONS_FIELDS.CHARGE_VALUE }
                  onChange={ ({ target: { value } }, field) => this.handleChangeField(value, Type, field) }
                  valid={ chargeValueValid }
                />
              </div>
              <div className={ styles.charge_suggest_first }>
                <Suggest
                  withScroll
                  clear={ false }
                  disabled={ isNoEdit }
                  suggests={ CHARGE_VALUE_UNITS_EC_LC_CONDITIONS }
                  currentLabel={ currentChargeType ? currentChargeType.name : '' }
                  onSelect={ (value) => (
                    this.handleChangeField(value.id, Type, FIELDS.EC_LC_CONDITIONS_FIELDS.CHARGE_TYPE)) }
                />
              </div>
              <div className={ styles.charge_suggest_first }>
                <Suggest
                  withScroll
                  clear={ false }
                  disabled={ isNoEdit }
                  suggests={ CHARGE_TYPES_EC_LC_CONDITIONS }
                  currentLabel={ currentChargeTypeUnit ? currentChargeTypeUnit.name : '' }
                  onSelect={ (value) => (
                    this.handleChangeField(value.id, Type, FIELDS.EC_LC_CONDITIONS_FIELDS.CHARGE_TYPE_UNIT)) }
                />
              </div>
              <div className={ styles.charge_time }>
                <label className={ styles.charge_time_label }>{ labelTimeBorder }</label>
                <div>
                  <DatePicker
                    dateFormat={ false }
                    timeFormat={ TIME_SECONDS }
                    locale='ru'
                    value={ normalizeTimeBorder }
                    className={ `componentHook ${timeBorderValid ? 'no-valid' : ''}` }
                    onChange={ (value) => this.handleChangeTimeBorder(value, Type) }
                    inputProps={ { disabled: isNoEdit } }
                  />
                  { messageHtml }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAddButton = () => {
    const { isNoEdit, addConditions } = this.props;

    return (
      <AddButton
        icon={ TYPE_ICON.ADD }
        isNoEdit={ isNoEdit }
        text={ isNoEdit ? TEXT.EDIT_INFO : TEXT.EC_LC_CONDITIONS.ADD_CONDITIONS }
        onClick={ addConditions }
      />
    );
  };

  renderDeleteButton = () => {
    const { isNoEdit, deleteConditions } = this.props;

    return (
      <div className={ styles.delete_button }>
        <FlatButton
          disabled={ isNoEdit }
          label={ TEXT.EC_LC_CONDITIONS.DELETE_CONDITIONS }
          onClick={ deleteConditions }
        />
      </div>
    );
  };

  renderCommon = () => {
    const { conditions } = this.props;

    return (
      <div className={ styles.content }>
        { Object.keys(conditions).map((key) => this.renderItem(conditions[key])) }
      </div>
    );
  };

  render() {
    const { setRef, conditions } = this.props;

    const addButtoHtml = !conditions && this.renderAddButton();
    const deleteButtoHtml = conditions && this.renderDeleteButton();
    const commonHtml = conditions && this.renderCommon();

    return (
      <div className={ styles.wrapper } ref={ (ref) => setRef(ref) } >
        <div className={ styles.header }>
          <h2>{ TEXT.EC_LC_CONDITIONS.TITLE }</h2>
          { addButtoHtml }
          { deleteButtoHtml }
        </div>
        { commonHtml }
        <div />
      </div>
    );
  }
}

export { EcLcConditions };
