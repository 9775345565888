import { React, Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import { getMoment, startOfMonth } from '../../../../bi/utils/formatDate';
import { Button } from '../../../../components/button';
import Search from '../../../../components/Search';

import COMPONENTS from '../../../../bi/constants/components';
import { TOOLTIPMSG } from '../../../../bi/constants/reports';

import styles from '../../styles/report.module.scss';

const LABEL = 'Ok';

class PrintReportPage extends Component {
  static propTypes = { onDownload: PropTypes.func.isRequired };

  state = {
    startDate: startOfMonth(),
    endDate: getMoment(),
    showTimeOutDialog: false,
    waitingResponse: false,
    showTooltip: true,
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  handleChangeDate = (field, value) => {
    this.setState({ [field]: value });
  };

  handleDownload = () => {
    const { startDate, endDate } = this.state;

    this.toggleWaitingResponse();
    this.props.onDownload(startDate, endDate)
      .then(this.toggleWaitingResponse)
      .catch(this.toggleWaitingResponse);
  };

  toggleTimeOutDialog = () => {
    this.setState({ showTimeOutDialog: !this.state.showTimeOutDialog });
  };

  render() {
    const {
      startDate, endDate, showTimeOutDialog, waitingResponse, showTooltip,
    } = this.state;

    return (
      <div className={ styles.main }>
        <div className={ styles.header }>
          <Search
            startDate={ startDate }
            endDate={ endDate }
            onChangeDate={ this.handleChangeDate }
            onDownload={ this.handleDownload }
            waitingResponse={ waitingResponse }
            tooltipMsg={ TOOLTIPMSG.REQUESTINFO }
            showTooltip={ showTooltip }
          />
        </div>
        <div className={ styles.content }>
          { showTimeOutDialog
          && (
            <Dialog onClick={ this.toggleTimeOutDialog } width={ COMPONENTS.DIALOG.WIDTH.SMALL }>
              <div className={ `${styles.form} ${styles['form-dialog']}` }>
                <div className={ styles['text-conflict'] }>
                  Превышено время обработки запроса на сервере.
                  <br />
                  Повторите запрос позже.
                </div>
                <Button
                  label={ LABEL }
                  theme={ COMPONENTS.BUTTON.THEME.FLAT }
                  onClick={ this.toggleTimeOutDialog }
                />
              </div>
            </Dialog>
          ) }
        </div>
      </div>
    );
  }
}

export default PrintReportPage;
