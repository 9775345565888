import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SERVICETYPE } from '../../../../../../bi/constants/serviceType';
import { TRIPSLABELS, TRIPBUTTONS } from '../../../../../../bi/constants/trips';
import { convertTransferMetadata } from '../../../../../../bi/utils/trip';

import AjaxButton from '../../../../../../components/ajaxButton';
import Input from '../../../../../../components/input';

import formStyle from '../../../../styles/form.module.scss';
import tripStyle from '../../../../styles/trip.module.scss';

const FIELDS = { ORDER: 'Order' };

class SelectKnownOrder extends Component {
  static propTypes = {
    onInfoFetched: PropTypes.func.isRequired,
    tripService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      orderId: '',
      waitingResponse: false,
    };
  }

  handleChangeOrderId = (event) => this.setState({ orderId: event.target.value });

  getInfo = (event) => {
    event.preventDefault();

    const { orderId } = this.state;
    const { tripService, onInfoFetched } = this.props;

    let newItem = null;

    this.setState({ waitingResponse: true }, async () => {
      try {
        const data = await tripService.getOrderTransferInfo(orderId);

        newItem = convertTransferMetadata(data);
      } catch (err) {
        newItem = tripService.addNewItem(SERVICETYPE.TRANSFER);
      }

      this.setState({ waitingResponse: false }, () => onInfoFetched(newItem));
    });
  };

  renderOrderIdInput() {
    const { orderId } = this.state;

    return (
      <div className={ tripStyle['col-1-3'] }>
        <Input
          field={ FIELDS.ORDER }
          value={ orderId }
          label={ TRIPSLABELS.PARTNERORDERID }
          onChange={ this.handleChangeOrderId }
        />
      </div>
    );
  }

  renderGetInfoButton() {
    const { waitingResponse } = this.state;

    return (
      <div className={ `${tripStyle['col-1-3']} ${formStyle['add-provider']}` }>
        <AjaxButton
          label={ TRIPBUTTONS.GET_INFO }
          loading={ waitingResponse }
          onClick={ this.getInfo }
        />
      </div>
    );
  }

  render() {
    return (
      <div className={ tripStyle.row }>
        <div className={ `${tripStyle.panel} ${tripStyle['col-1-2']}` }>
          <form className={ `${tripStyle.row} ${tripStyle.provider}` }>
            { this.renderOrderIdInput() }
            { this.renderGetInfoButton() }
          </form>
        </div>
      </div>
    );
  }
}

export { SelectKnownOrder };
