const TOOLTIPMSG = {
  MORETHANTHIRTYONE: 'Период должен быть не больше, чем 31 день',
  REQUESTINFO: 'Имейте ввиду, что запрос обычно длится дольше 10 минут, т.е. превышает допустимый лимит установленный браузером на запросы',
};

const REPORTLABALES = {
  DOWNLOAD: 'Скачать',
  DATETRIP: 'Дата поездок',
  TRIP_BY_DATE: 'Дата заезда',
  TOFORM: 'Сформировать',
  RANGE_OF_COMPANIES: 'Диапазон компаний',
  SEND: 'Запустить отправку',
};

const REPORTNAME = {
  OVERDRAFTREPORT: 'Отчет по компаниям, у которых увеличивали лимит',
  EMPTYAUTOCOMPLITEREPTOR: 'Выгрузка неудавшихся автокомплитов',
  EMPTYRATESEREPTOR: 'Выгрузка пустых выдач отелей',
  SUPPLIERDOCUMENTS: 'Отчет для сбора закрывающих',
  TRIPBYDATE: 'Отчет по дате поездки',
  CALL_APARTMENTS: 'Прозвон апартаментов',
  ABOARD: 'Отчет Заграница',
  ALVIST_REPORT: 'Отчет по Alvist',
  HOTEL_VAT: 'Отчет по заказам отелей, требующих внесения НДС',
  AA_VAT: 'Отчет по заказам отелей с ошибками от A&A',
};

const REPORTNAMELIST = {
  REPORTNAME: 'ОТЧЕТ',
  SERVICENAME: 'ОТЧЕТ ПО INTERCOM',
  INSURANCE: 'ОТЧЕТ СТРАХОВАНИЕ',
  INSURANCE_SECOND: 'ОТЧЕТ СТРАХОВАНИЕ 2',
  INSURANCE_THIRD: 'ОТЧЕТ СТРАХОВАНИЕ 3',
  REFUNDS_ONELIYA: 'ОТЧЕТ ВОЗВРАТЫ ONELIYA',
};

const PAGENAME = {
  ABOARD: 'aboard',
  TRIP: 'trip',
  BOOKING: 'booking',
  BOOKING_WL: 'booking-wl',
  BOOKINGSEC: 'booking2',
  BOOKINGTHIRD: 'booking3',
  BOOKING_SPECIAL_BILLING: 'bookingSpecialBilling',
  DEBTOR: 'debtor',
  COMPANIESDAYSINDEBT: 'daysindebt',
  ACCOUNTS: 'accounts',
  INCOMING: 'incoming',
  PRINT: 'print',
  STYLEREPORT: 'style-report',
  TAGSREPORT: 'tags-report',
  REPLYREPORT: 'reply-report',
  KPIPOREPORT: 'kpipo-report',
  KPINOTEREPORT: 'kpinote-report',
  TIMECHATREPORT: 'timechat-report',
  STOPWORDREPORT: 'stopword-report',
  SENDINGTYPEREPORT: 'sendingtypes-report',
  EDITINGCLOSINGREPORT: 'editingclosing-report',
  CONSULTATIONSREPORT: 'consultations-report',
  HOTELS: 'hotels',
  HOTELSPK: 'hotels-pk',
  AIRTICKETS: 'airtickets',
  REPORT1: 'report1',
  TRAINTICKETS: 'traintickets',
  REFUNDS_ONELIYA: 'refundsOneliya',
  PROCEEDS: 'proceeds',
  REVISEACTTEST: 'reviseacttest',
  MANAGERSACTIONS: 'managers-actions',
  OVERDRAFT: 'overdraft',
  OVERDRAFT_SEC: 'overdraft2',
  HOTELSFULL: 'hotels-full',
  EMTYAUTOCOMPLETE: 'empty-autocomplete',
  EMTYRATES: 'empty-rates',
  ANALYTICSAMO: 'analytics-amo',
  GROUPINGHOTEL: 'grouping-hotel',
  HOTEL_VAT: 'hotel-vat',
  AA_VAT: 'aa-vat',
  SCHEMAS: 'schemas',
  AUTOSENDINGEDO: 'mailing',
  PAYMENT_CARD: 'paymentCard',
  PPR_AND_KONTUR: 'monetaryTransactions',
  GENERAL_ACT_COMPLETED_WORKS: 'generalAct',
  INSURANCE: 'insurance',
  INSURANCE_SECOND: 'insurance2',
  INSURANCE_THIRD: 'insurance3',
  REINSURANCE: 'reconciliation-with-insurance',
  SUPPLIERDOCUMENTS: 'supplier-documents',
  TRIPBYDATE: 'trip-by-date',
  CALL_APARTMENTS: 'call-apartments',
  PRINTDOCUMENTS: 'print-documents',
  AVIA_REPORT: 'aviaReport',
  PROVIDERS: 'report-providers',
};

const FIELDLABELS = {
  REQUESTDATE: 'RequestDate',
  RECEIVEDATE: 'ReceiveDate',
  CALLED: 'called',
  NOT_CALLED: 'notCalled',
  APPROVED: 'approved',
  NOT_APPROVED: 'notApproved',
  IS_NOT_HOTEL_OK: 'isNotHotelOk',
};

const TYPE_AVIA = [
  {
    label: 'S7', valueEn: 's7', value: 0,
  },
  {
    label: 'UTair', valueEn: 'utair', value: 1,
  },
  {
    label: 'Аэрофлот', valueEn: 'aeroflot', value: 2,
  },
];

const REPORT_COMPANIES = [
  {
    value: null, companyStart: 0, companyEnd: 0,
  },
  {
    value: 0, companyStart: 0, companyEnd: 10000,
  },
  {
    value: 1, companyStart: 10001, companyEnd: 20000,
  },
  {
    value: 2, companyStart: 20001, companyEnd: 30000,
  },
  {
    value: 3, companyStart: 30001, companyEnd: 40000,
  },
  {
    value: 4, companyStart: 40001, companyEnd: 50000,
  },
  {
    value: 5, companyStart: 50001, companyEnd: 60000,
  },
  {
    value: 6, companyStart: 60001, companyEnd: 70000,
  },
  {
    value: 7, companyStart: 70001, companyEnd: 80000,
  },
  {
    value: 8, companyStart: 80001, companyEnd: 90000,
  },
  {
    value: 9, companyStart: 90001, companyEnd: 100000,
  },
];

const RANGE_OF_COMPANIES = [
  { label: '0-10000', value: 0 },
  { label: '10001-20000', value: 1 },
  { label: '20001-30000', value: 2 },
  { label: '30001-40000', value: 3 },
  { label: '40001-50000', value: 4 },
  { label: '50001-60000', value: 5 },
  { label: '60001-70000', value: 6 },
  { label: '70001-80000', value: 7 },
  { label: '80001-90000', value: 8 },
  { label: '90001-100000', value: 9 },
];

const REPORT_COMPANIES__FOR_1C = [
  {
    value: null, companyStart: 0, companyEnd: 0,
  },
  {
    value: 0, companyStart: 0, companyEnd: 10000,
  },
  {
    value: 1, companyStart: 10001, companyEnd: 20000,
  },
  {
    value: 2, companyStart: 20001, companyEnd: 30000,
  },
  {
    value: 3, companyStart: 30001, companyEnd: 35000,
  },
  {
    value: 4, companyStart: 35001, companyEnd: 40000,
  },
  {
    value: 5, companyStart: 40001, companyEnd: 50000,
  },
  {
    value: 6, companyStart: 50001, companyEnd: 60000,
  },
  {
    value: 7, companyStart: 60001, companyEnd: 70000,
  },
  {
    value: 8, companyStart: 70001, companyEnd: 80000,
  },
  {
    value: 9, companyStart: 80001, companyEnd: 90000,
  },
  {
    value: 10, companyStart: 90001, companyEnd: 100000,
  },
  {
    value: 11, companyStart: 0, companyEnd: 100000,
  },
];

const RANGE_OF_COMPANIES__FOR_1C = [
  { label: '0-10000', value: 0 },
  { label: '10001-20000', value: 1 },
  { label: '20001-30000', value: 2 },
  { label: '30001-35000', value: 3 },
  { label: '35001-40000', value: 4 },
  { label: '40001-50000', value: 5 },
  { label: '50001-60000', value: 6 },
  { label: '60001-70000', value: 7 },
  { label: '70001-80000', value: 8 },
  { label: '80001-90000', value: 9 },
  { label: '90001-100000', value: 10 },
  { label: 'Все', value: 11 },
];

export {
  REPORTNAMELIST as default,
  TOOLTIPMSG,
  REPORTLABALES,
  REPORTNAME,
  REPORTNAMELIST,
  PAGENAME,
  FIELDLABELS,
  RANGE_OF_COMPANIES,
  RANGE_OF_COMPANIES__FOR_1C,
  REPORT_COMPANIES__FOR_1C,
  REPORT_COMPANIES,
  TYPE_AVIA,
};
