import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import {
  Dialog, Button, Select,
} from 'sw-ui';

import UploadForm from '../../components/UploadForm';
import AjaxButton from '../../components/ajaxButton';
import { FlatButton } from '../../components/button';
import CircularLoaders from '../../components/loaders';
import { WarningForm } from '../../components/WarningForm';
import Input from '../../components/input';

import COMPONENTS from '../../bi/constants/components';
import { AIRLINE_ITEMS } from '../../bi/constants/airline';
import { UPLOADFORM } from '../../bi/constants/amo';
import { FIELDS } from '../../bi/constants/trips';

import styles from './styles/airlineCancel.module.css';

const LABELS = {
  EMPTY_LIST: 'Нет поездок',
  TITLE: 'Отмена авиабилета',
  PROVIDER: 'Провайдер',
  PNR: 'PNR',
  CONFIRM_BUTTON: 'Отменить билет',
  ADD_WARNING: 'Добавить оповещение в ЛК',
  CANCEL_BUTTON: 'Отмена',
  CONFIRM_DIALOG: 'Билет успешно отменен',
  CANCEL_DIALOG: 'Ошибка, билет не отменен',
  ADD_COMPANY: 'Добавить новую авиакомпанию',
  ADD_COMPANY_HEADER: 'Добавить новую авиакомпанию',
  COMPANY_LATIN: 'Название авиакомпании на латинице',
  COMPANY_CYRILLIC: 'Название авиакомпании на кириллице',
  CODE_COMPANY: 'Код авиакомпании',
  AIRLINE_INN: 'ИНН авиакомпании',
  AIRLINE_KPP: 'КПП авиакомпании',
  AIRLINE_ADDRESS: 'Адрес авиакомпании',
  AIRLINE_NAME: 'Официальное название авиакомпании',
  LOGO_FILE: 'Логотип для ЛК (60х60)',
  LOGO_VOUCHER: 'Логотип для ваучера (350х100)',
  SUCCESS_ADD_COMPANY: 'Компания успешно добавлена',
};

const INPUT_FIELDS = {
  NAME_LATIN: 'nameLatin',
  NAME_CYRRILLIC: 'nameCyrillic',
  CODE_COMPANY: 'codeCompany',
  AIRLINE_INN: 'airlineINN',
  AIRLINE_KPP: 'airlineKPP',
  AIRLINE_ADRESS: 'airlineAdress',
  AIRLINE_NAME: 'airlineName',
};

class AirlineCancelPage extends Component {
  static propTypes = {
    airlineCancelService: PropTypes.object.isRequired,
    airlineWarningService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      success, resultMsg, isDisabled,
    } = props.airlineCancelService.get();
    const {
      listTemplateWarning, isLoading, type, textNewTemplateWarning, isEditing, isNewTemplateWarning, isError,
    } = props.airlineWarningService.get();
    const { CanManageTemplates } = props.featureFlagsService.get();

    this.state = {
      isOpen: false,
      showAddCompanyDialog: false,
      loading: false,
      showWarningForm: false,
      provider: 'None',
      pnr: '',
      resultMsg,
      isDisabled,
      success,
      flagCanManageTemplates: CanManageTemplates,
      listTemplateWarning,
      isLoading,
      type,
      isEditing,
      textNewTemplateWarning,
      isNewTemplateWarning,
      isError,
      nameLatin: '',
      nameCyrillic: '',
      codeCompany: '',
      airlineINN: '',
      airlineKPP: '',
      airlineAdress: '',
      airlineName: '',
      logoFile: null,
      logoVoucher: null,
      successAddCompany: false,
      errorAddCompany: '',
      validation: {},
    };
  }

  componentDidMount() {
    const {
      airlineCancelService, featureFlagsService, airlineWarningService,
    } = this.props;

    this.unsubscribe = airlineCancelService.subscribe(this.updateState);
    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    this.unsubscribeAirlineWarning = airlineWarningService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
    this.unsubFeatureFlags();
    this.unsubscribeAirlineWarning();
  }

  updateState = (state) => this.setState({ ...state });

  updateFeatureFlags = ({ CanManageTemplates }) => {
    this.setState({ flagCanManageTemplates: CanManageTemplates });
  };

  handleOpenDialog = () => {
    const { airlineCancelService } = this.props;
    const { isOpen } = this.state;

    this.setState({ isOpen: !isOpen }, () => {
      airlineCancelService.resetStore();
    });
  };

  handleOpenAddCompanyDialog = () => {
    this.setState((prev) => ({
      nameLatin: '',
      nameCyrillic: '',
      codeCompany: '',
      airlineINN: '',
      airlineKPP: '',
      airlineAdress: '',
      airlineName: '',
      logoFile: null,
      logoVoucher: null,
      showAddCompanyDialog: !prev.showAddCompanyDialog,
      successAddCompany: false,
      errorAddCompany: '',
      validation: {},
    }));
  };

  handleChangeInput = (field, value) => this.setState({ [field]: value });

  handleLogoAdded = (file) => this.props.airlineCancelService.uploadLogoFile(file);

  handleVoucherAdded = (file) => this.props.airlineCancelService.uploadVoucherFile(file);

  handleCancelTicket = () => {
    const { provider, pnr } = this.state;
    const { airlineCancelService } = this.props;

    this.setState({ isDisabled: true }, () => airlineCancelService.cancelTicket(provider, pnr));
  };

  validationSubmit = () => {
    const { airlineCancelService } = this.props;
    const {
      nameLatin,
      nameCyrillic,
      airlineINN,
      airlineKPP,
      codeCompany,
      validation,
    } = this.state;

    const nameLatinError = airlineCancelService.airlineFieldValidation(FIELDS.NAME_LATIN, nameLatin);
    const nameCyrillicError = airlineCancelService.airlineFieldValidation(FIELDS.NAME_CYRILLIC, nameCyrillic);
    const codeCompanyError = airlineCancelService.airlineFieldValidation(FIELDS.CODE_COMPANY, codeCompany);
    const airlineINNError = airlineINN.length ? airlineCancelService.airlineFieldValidation(FIELDS.AIRLINE_INN, airlineINN) : '';
    const airlineKPPError = airlineINN.length ? airlineCancelService.airlineFieldValidation(FIELDS.AIRLINE_KPP, airlineKPP) : '';

    if (
      nameLatinError
      || nameCyrillicError
      || codeCompanyError
      || airlineINNError
      || airlineKPPError
    ) {
      return {
        isValid: false,
        validation: {
          ...validation,
          nameLatin: nameLatinError,
          nameCyrillic: nameCyrillicError,
          codeCompany: codeCompanyError,
          airlineINN: airlineINNError,
          airlineKPP: airlineKPPError,
        },
      };
    }

    return { isValid: true };
  };

  handleAddCompanySubmit = async () => {
    const { isValid, validation } = this.validationSubmit();

    if (isValid) {
      const {
        nameLatin,
        nameCyrillic,
        codeCompany,
        airlineINN,
        airlineKPP,
        airlineAdress,
        airlineName,
        logoFile,
        logoVoucher,
      } = this.state;
      const { airlineCancelService } = this.props;

      this.setState({ loading: true });

      try {
        await airlineCancelService.addAirlineCompany({
          Code: codeCompany,
          NameEN: nameLatin,
          NameRU: nameCyrillic,
          INN: airlineINN,
          KPP: airlineKPP,
          LegalAddress: airlineAdress,
          LegalName: airlineName,
        });

        this.setState({
          nameLatin: '',
          nameCyrillic: '',
          codeCompany: '',
          airlineINN: '',
          airlineKPP: '',
          airlineAdress: '',
          airlineName: '',
          logoFile,
          logoVoucher,
          validation: {},
          loading: false,
          successAddCompany: true,
          errorAddCompany: '',
        });
      } catch (err) {
        this.setState({
          errorAddCompany: err.body.Error,
          loading: false,
        });
      }
    } else {
      this.setState({
        isValid,
        validation,
        successAddCompany: false,
        errorAddCompany: '',
      });
    }
  };

  changeFieldTemplateWarning = ({
    id, isActive, content,
  }) => this.props.airlineWarningService.changeFieldTemplateWarning({
    widgetId: id,
    content: { text: content },
    isActive,
  });

  addFieldTemplateWarning = (isActive) => {
    const { type, textNewTemplateWarning } = this.state;
    const {
      airlineWarningService: {
        addFieldTemplateWarning,
        setAdditionTemplateWarning,
        setTextTemplateWarning,
      },
    } = this.props;
    const params = {
      type, isActive, content: { text: textNewTemplateWarning },
    };

    addFieldTemplateWarning(params);
    setAdditionTemplateWarning(false);
    setTextTemplateWarning('');
  };

  deleteFieldTemplateWarning = (id) => this.props.airlineWarningService.deleteFieldTemplateWarning(id);

  handleShowWarningForm = (value) => this.setState({ showWarningForm: value });

  handleEditTemplate = (value) => this.props.airlineWarningService.setIsEditing(value);

  handleTextAreaChange = (value) => this.props.airlineWarningService.setTextTemplateWarning(value);

  handleTemplateWarning = (value) => this.props.airlineWarningService.setAdditionTemplateWarning(value);

  renderLoading = () => (
    <Dialog width='400px' onClick={ this.handleOpenDialog }>
      <div className={ styles.row }>
        <CircularLoaders wrapClassName='content-loader-wrap' />
      </div>
    </Dialog>
  );

  renderResultDialog = (resultMsg) => {
    const { success } = this.state;

    const message = success ? LABELS.CONFIRM_DIALOG : resultMsg;

    return (
      <Dialog width='400px' onClick={ this.handleOpenDialog }>
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            { message }
          </div>
        </div>
      </Dialog>
    );
  };

  renderAddCompanyDialog = () => {
    const {
      showAddCompanyDialog,
      nameLatin,
      nameCyrillic,
      codeCompany,
      airlineINN,
      airlineKPP,
      airlineAdress,
      airlineName,
      loading,
      logoFile,
      logoVoucher,
      validation,
      successAddCompany,
      errorAddCompany,
    } = this.state;

    const renderSuccessMessage = () => (successAddCompany ? <div className={ `${styles.message} ${styles.success}` }>{ LABELS.SUCCESS_ADD_COMPANY }</div> : '');
    const renderErrorMessage = () => (errorAddCompany ? <div className={ `${styles.message} ${styles.error}` }>{ errorAddCompany }</div> : '');

    if (!showAddCompanyDialog) return null;

    const renderLogoSection = () => {
      const ffs = this.props.featureFlagsService.get();
      const showAddCompany = ffs && ffs.EditAirline && ffs.EditAirline.includes('logo');

      if (!showAddCompany) return null;

      return (
        <div>
          <div className={ styles['logo-label'] }>{ LABELS.LOGO_FILE }</div>
          <UploadForm
            extensions={ ['png'] }
            onFileAdded={ this.handleLogoAdded }
            onFileRemoved={ this.handleOnRemove }
            uploaded={ logoFile }
            chooseFileLabel={ UPLOADFORM.CHOOSEFILE }
            description={ UPLOADFORM.DESCRIPTION }
            invalidExtension={ UPLOADFORM.INVALIDEXTENSION }
          />
          <div className={ styles['logo-voucher-label'] }>{ LABELS.LOGO_VOUCHER }</div>
          <UploadForm
            extensions={ ['png'] }
            onFileAdded={ this.handleVoucherAdded }
            onFileRemoved={ this.handleOnRemove }
            uploaded={ logoVoucher }
            chooseFileLabel={ UPLOADFORM.CHOOSEFILE }
            description={ UPLOADFORM.DESCRIPTION }
            invalidExtension={ UPLOADFORM.INVALIDEXTENSION }
          />
        </div>
      );
    };

    return (
      <Dialog
        width='600px'
        onClick={ this.handleOpenAddCompanyDialog }
      >
        <div className={ styles.dialog }>
          <h3 className={ styles.addCompanyHeader }>
            { LABELS.ADD_COMPANY_HEADER }
          </h3>
          <div className={ styles.wrapperRow }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ nameLatin }
              label={ LABELS.COMPANY_LATIN }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.NAME_LATIN, value) }
              valid={ validation.nameLatin }
            />
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ nameCyrillic }
              label={ LABELS.COMPANY_CYRILLIC }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.NAME_CYRRILLIC, value) }
              valid={ validation.nameCyrillic }
            />
          </div>
          <div className={ styles.wrapperRow }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ codeCompany }
              label={ LABELS.CODE_COMPANY }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.CODE_COMPANY, value) }
              valid={ validation.codeCompany }
            />
          </div>
          <div className={ styles.wrapperRow }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ airlineINN }
              label={ LABELS.AIRLINE_INN }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.AIRLINE_INN, value) }
              valid={ validation.airlineINN }
            />
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ airlineKPP }
              label={ LABELS.AIRLINE_KPP }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.AIRLINE_KPP, value) }
              valid={ validation.airlineKPP }
            />
          </div>
          <div className={ styles.wrapperRow }>
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ airlineAdress }
              label={ LABELS.AIRLINE_ADDRESS }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.AIRLINE_ADRESS, value) }
            />
            <Input
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ airlineName }
              label={ LABELS.AIRLINE_NAME }
              onChange={ (_, __, value) => this.handleChangeInput(INPUT_FIELDS.AIRLINE_NAME, value) }
            />
          </div>
          { renderLogoSection() }
          <div className={ styles['add-company-actions'] }>
            <div>
              <AjaxButton
                label='Добавить'
                onClick={ this.handleAddCompanySubmit }
                loading={ loading }
              >
                { LABELS.CONFIRM_BUTTON }
              </AjaxButton>
            </div>
            <div className={ styles.cancel }>
              <FlatButton
                onClick={ this.handleOpenAddCompanyDialog }
              >
                { LABELS.CANCEL_BUTTON }
              </FlatButton>
            </div>
          </div>
          { renderErrorMessage() }
          { renderSuccessMessage() }
        </div>
      </Dialog>
    );
  };

  renderDialog = () => {
    const {
      provider, pnr, isDisabled, resultMsg, success,
    } = this.state;

    const disabledButton =
      isDisabled
      || provider === 'None'
      || !pnr.length;

    if (isDisabled) return this.renderLoading();
    if (resultMsg.length || success) return this.renderResultDialog(resultMsg);

    return (
      <Dialog
        width='400px'
        onClick={ this.handleOpenDialog }
      >
        <div className={ styles.dialog }>
          <h3>
            { LABELS.TITLE }
          </h3>
          <div className={ styles.row }>
            <Select
              items={ AIRLINE_ITEMS }
              label={ LABELS.PROVIDER }
              value={ provider }
              theme={ COMPONENTS.SELECT.THEME.BORDER }
              onChange={ (value) => this.handleChangeInput('provider', value.value) }
            />
          </div>
          <div className={ styles.row }>
            <Input
              label={ LABELS.PNR }
              value={ pnr }
              onChange={ (_, __, value) => this.handleChangeInput('pnr', value) }
            />
          </div>
          <div className={ styles.action }>
            <Button
              theme='red'
              disabled={ disabledButton }
              onClick={ this.handleCancelTicket }
            >
              { LABELS.CONFIRM_BUTTON }
            </Button>
            <div className={ styles.cancel }>
              <FlatButton
                onClick={ this.handleOpenDialog }
              >
                { LABELS.CANCEL_BUTTON }
              </FlatButton>
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  renderWarningForm = () => {
    const { airlineWarningService } = this.props;
    const {
      showWarningForm,
      flagCanManageTemplates,
      listTemplateWarning,
      isLoading,
      isNewTemplateWarning,
      isError,
      textNewTemplateWarning,
      isEditing,
    } = this.state;

    if (!flagCanManageTemplates) return null;

    if (!showWarningForm) {
      return (
        <Button
          className={ styles.action }
          theme='second'
          onClick={ () => this.handleShowWarningForm(true) }
        >
          { LABELS.ADD_WARNING }
        </Button>
      );
    }

    return (
      <WarningForm
        onClose={ () => this.handleShowWarningForm(false) }
        listTemplateWarning={ listTemplateWarning }
        isLoading={ isLoading }
        isError={ isError }
        isEditing={ isEditing }
        isNewTemplateWarning={ isNewTemplateWarning }
        textNewTemplateWarning={ textNewTemplateWarning }
        getListTemplateWarning={ airlineWarningService.getListTemplateWarning }
        addFieldTemplateWarning={ this.addFieldTemplateWarning }
        handleTextAreaChange={ this.handleTextAreaChange }
        deleteFieldTemplateWarning={ this.deleteFieldTemplateWarning }
        handleTemplateWarning={ this.handleTemplateWarning }
        handleEditTemplate={ this.handleEditTemplate }
        changeFieldTemplateWarning={ this.changeFieldTemplateWarning }
      />
    );
  };

  render() {
    const { isOpen, showWarningForm } = this.state;
    const ffs = this.props.featureFlagsService.get();
    const showAddCompany = ffs && ffs.EditAirline && !!ffs.EditAirline.length;

    const dialogHtml = isOpen
      ? this.renderDialog()
      : null;

    return (
      <div className={ styles.wrap }>
        <div>
          <Button
            theme='second'
            disabled={ showWarningForm }
            onClick={ this.handleOpenDialog }
          >
            { LABELS.CONFIRM_BUTTON }
          </Button>
          <Button
            theme='second'
            disabled={ !showAddCompany }
            onClick={ this.handleOpenAddCompanyDialog }
            className={ styles.addCompanyButton }
          >
            { LABELS.ADD_COMPANY }
          </Button>
          { this.renderWarningForm() }
        </div>
        { dialogHtml }
        { this.renderAddCompanyDialog() }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <AirlineCancelPage
      airlineCancelService={ opts.airlineCancelService }
      airlineWarningService={ opts.airlineWarningService }
      featureFlagsService={ opts.featureFlagsService }
    />,
  );

  return root;
};

export default renderComponents;
