import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import AjaxButton from '../../../../../../components/ajaxButton';
import Radio from '../../../../../../components/radio';
import Input from '../../../../../../components/input';
import { FlatButton } from '../../../../../../components/button';

import COMPONENTS from '../../../../../../bi/constants/components';
import {
  HOTEL_PROVIDER_IS_AUTO_CALC_VAT, HOTEL_PROVIDER_VALUE, FIELDS_VAT,
} from '../../../../../../bi/constants/hotel';
import { STATUSCODES } from '../../../../../../bi/constants/trips';
import { VAT_VALUE_BY_VAT_BLOCK } from '../../../../../../bi/constants/hotels';

import baseStyles from '../../../../styles/form.module.scss';

const LABELS = {
  VENDOR_AMOUNT: 'Сумма НДС от поставщика',
  RACK_RATE_AMOUNT: 'RR от AA',
  CONFIRM: 'Внести',
  CANCEL: 'Отмена',
  ENTER_VAT: 'Внести НДС',
  VAT20: 'НДС 20%',
  VAT7: 'НДС 7%',
  VAT5: 'НДС 5%',
  VAT0: 'НДС 0%',
  WITHOUT_VAT: 'Без НДС',
  AUTO_CALC_TEXT: 'НДС будет рассчитан автоматически. Пожалуйста, нажмите кнопку &quot;Внести&quot;.',
};

const WIDTH = '480px';

const EditVatVersion = ({
  item,
  amount,
  rate = '',
  providerName,
  onConfirm,
  loading,
}) => {
  const [rateValue, setRateValue] = useState(rate);
  const [amountValue, setAmountValue] = useState(amount);
  const [rackRateValue, setRackRateValue] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  const showRateBlock = item.Status === STATUSCODES.CREATE;
  const isVat20 = rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT20;
  const isVat7 = rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT7;
  const isVat5 = rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT5;
  const isVatRatePresent = isVat20 || isVat5 || isVat7;
  const showAmount = !showRateBlock
    ? !HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(providerName) && item.InternalVat.VatInfo.CalcType !== 1
    : !HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(providerName) && isVatRatePresent;
  const showRackRate = providerName === HOTEL_PROVIDER_VALUE.aanda && showRateBlock && isVatRatePresent;
  const disabledConfirmButton = ((!amountValue && showAmount && isVatRatePresent)
    || (item.Status === STATUSCODES.CREATE && !rateValue));
  const disabledWithVat = providerName === HOTEL_PROVIDER_VALUE.expedia
    || providerName === HOTEL_PROVIDER_VALUE.tripcom;

  const handleConfirm = () => {
    setShowDialog(false);
    onConfirm({
      Amount: amountValue,
      RackRate: rackRateValue,
      Rate: rateValue,
    });
  };

  const handleClose = () => {
    setShowDialog(false);
    setRateValue(rate);
    setAmountValue(amount);
    setRackRateValue(0);
  };

  const handleOpen = () => {
    setShowDialog(true);
    setAmountValue(amount);
  };

  const renderAutoCalcText = () => !showAmount && !showRateBlock && (
    <div className={ baseStyles.vat_block }>
      <h4>{ LABELS.AUTO_CALC_TEXT }</h4>
    </div>
  );

  const renderRateBlock = () => {
    if (!showRateBlock) return null;

    return (
      <div className={ baseStyles.vat_block }>
        <Radio
          label={ LABELS.VAT20 }
          field={ FIELDS_VAT.RATE }
          checked={ rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT20 }
          value={ VAT_VALUE_BY_VAT_BLOCK.VAT20 }
          onChange={ (field, value) => setRateValue(value) }
          disabled={ disabledWithVat }
        />
        <Radio
          label={ LABELS.VAT7 }
          field={ FIELDS_VAT.RATE }
          checked={ rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT7 }
          value={ VAT_VALUE_BY_VAT_BLOCK.VAT7 }
          onChange={ (field, value) => setRateValue(value) }
          disabled={ disabledWithVat }
        />
        <Radio
          label={ LABELS.VAT5 }
          field={ FIELDS_VAT.RATE }
          checked={ rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT5 }
          value={ VAT_VALUE_BY_VAT_BLOCK.VAT5 }
          onChange={ (field, value) => setRateValue(value) }
          disabled={ disabledWithVat }
        />
        <Radio
          label={ LABELS.VAT0 }
          field={ FIELDS_VAT.RATE }
          checked={ rateValue === VAT_VALUE_BY_VAT_BLOCK.VAT0 }
          value={ VAT_VALUE_BY_VAT_BLOCK.VAT0 }
          onChange={ (field, value) => setRateValue(value) }
          disabled={ disabledWithVat }
        />
        <Radio
          label={ LABELS.WITHOUT_VAT }
          field={ FIELDS_VAT.RATE }
          checked={ rateValue === VAT_VALUE_BY_VAT_BLOCK.WITHOUT }
          value={ VAT_VALUE_BY_VAT_BLOCK.WITHOUT }
          onChange={ (field, value) => setRateValue(value) }
        />
      </div>
    );
  };

  const renderInputAmount = () => {
    if (!showAmount) return null;

    return (
      <Input
        field={ FIELDS_VAT.AMOUNT }
        value={ amountValue }
        type='number'
        label={ LABELS.VENDOR_AMOUNT }
        onChange={ (e, field, value) => setAmountValue(value) }
      />
    );
  };

  const renderRackRate = () => {
    if (!showRackRate) return null;

    return (
      <div className={ baseStyles.row }>
        <Input
          field={ FIELDS_VAT.RACK_RATE }
          value={ rackRateValue }
          type='number'
          label={ LABELS.RACK_RATE_AMOUNT }
          onChange={ (e, field, value) => setRackRateValue(value) }
        />
      </div>
    );
  };

  const renderDialog = () => (
    <Dialog onClick={ handleClose } width={ WIDTH }>
      <div className={ baseStyles.dialog }>
        { renderAutoCalcText() }
        { renderRateBlock() }
        <div className={ baseStyles.row }>
          { renderInputAmount() }
        </div>
        { renderRackRate() }
        <div className={ `${baseStyles.row} ${baseStyles.vat_buttons}` }>
          <AjaxButton
            label={ LABELS.CONFIRM }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            loading={ loading }
            disabled={ disabledConfirmButton }
            onClick={ handleConfirm }
          />
          <FlatButton
            label={ LABELS.CANCEL }
            onClick={ handleClose }
          />
        </div>
      </div>
    </Dialog>
  );

  const dialogHtml = showDialog ? renderDialog() : null;

  return (
    <div>
      <AjaxButton
        label={ LABELS.ENTER_VAT }
        theme={ COMPONENTS.BUTTON.THEME.SECOND }
        loading={ loading }
        onClick={ handleOpen }
      />
      { dialogHtml }
    </div>
  );
};

EditVatVersion.propTypes = {
  item: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  amount: PropTypes.number.isRequired,
  rate: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export { EditVatVersion };
