import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, Paginate,
} from 'sw-ui';

import Input from '../../../components/input';
import { FlatButton } from '../../../components/button';
import { HotelItem } from '../components/HotelItem';

import COMPONENTS from '../../../bi/constants/components';
import { DEFAULT_MATCH_HOTEL_IDS, DEFAULT_MATCHES_HOTELS } from '../../../bi/constants/hotels';

import styles from '../styles/match.module.css';
import stylesSearch from '../styles/search.module.scss';

const LABELS = {
  TITLE: 'Введите сопоставления',
  CHECK_MATCH: 'Проверить наличие отеля',
  FOUND_MATCHES: 'Найденные сопоставления',
  ADD_WITHOUT_MATCHING: 'Добавить без сопоставлений',
  WARNING_DIALOG: 'Вы уверены, что хотите продолжить без сопоставления отелей?',
  CONTINUE: 'Продолжить',
  CLOSE: 'Закрыть',
  UNDO: 'Назад',
};

const PROVIDERS_LIST = [
  { label: 'IdOstrovok', field: 'IdOstrovok' },
  { label: 'IdBronevik', field: 'IdBronevik' },
  { label: 'IdAanda', field: 'IdAanda' },
  { label: 'IdAcadem', field: 'IdAcadem' },
  { label: 'IdExpedia', field: 'IdExpedia' },
  { label: 'IdTripcom', field: 'IdTripcom' },
];

const VISIBLE_ON_PAGE = 20;

const MatchHotels = ({
  hotelsService,
  moveToAddHotel,
}) => {
  const [matches, setMatches] = useState(DEFAULT_MATCH_HOTEL_IDS);
  const [existenceHotels, setExistenceMatch] = useState(DEFAULT_MATCHES_HOTELS);
  const [paginateSelectedProviderIds, setPaginateSelectedProviderIds] = useState([]);
  const [errorMatchIds, setErrorMatch] = useState('');
  const [mapping, setMapping] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showWarningMatchDialog, setShowWarningMatchDialog] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const updateState = ({
    matchProvidersId, matchHotels, loadingMatchHotels, errorMatch, mappingGiata,
  }) => {
    setMatches({ ...matchProvidersId });
    setExistenceMatch({ ...matchHotels });
    setPaginateSelectedProviderIds(matchHotels.Hotels.slice(0, VISIBLE_ON_PAGE));
    setErrorMatch(errorMatch);
    setMapping(mappingGiata);
    setLoading(loadingMatchHotels);
  };

  const unsubscribe = () => hotelsService.subscribe(updateState);

  const handleResetMatches = () => {
    hotelsService.resetMatches();
  };

  useEffect(() => {
    unsubscribe();

    return () => {
      handleResetMatches();
    };
  }, []);

  const handleChangeIdProvider = (fieldName, value) => {
    hotelsService.changeMatchId(fieldName, value);
  };

  const handleCheckMatch = async () => {
    const isValid = hotelsService.validMatchIds();

    if (isValid) {
      await hotelsService.checkMatchHotels(moveToAddHotel);
    }
  };

  const handleShowWarningMatchDialog = () => setShowWarningMatchDialog(!showWarningMatchDialog);

  const getVisibleItemsOnPage = (current) => (
    existenceHotels.Hotels.slice((current - 1) * VISIBLE_ON_PAGE, current * VISIBLE_ON_PAGE)
  );

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    setPaginateSelectedProviderIds(getVisibleItemsOnPage(page));
  };

  const renderInputProviders = () => PROVIDERS_LIST.map(({ label, field }, idx) => (
    <Input
      key={ idx }
      field={ field }
      value={ matches[field] }
      label={ label }
      onChange={ (e, fieldName, value) => handleChangeIdProvider(fieldName, value) }
    />
  ));

  const renderBody = () => {
    if (existenceHotels.Exists) {
      const listHotelsHtml = paginateSelectedProviderIds.map((item, idx) => (
        <HotelItem
          key={ idx }
          item={ item }
          hotelsService={ hotelsService }
          mappingGiata={ mapping }
        />
      ));

      return (
        <ul className={ stylesSearch.list }>
          <FlatButton onClick={ () => hotelsService.setMatchedHotels(DEFAULT_MATCHES_HOTELS) }>
            <i className='material-icons'>keyboard_backspace</i>
            { ' ' }
            { LABELS.UNDO }
          </FlatButton>
          <h2>{ LABELS.FOUND_MATCHES }</h2>
          { listHotelsHtml }
          <Paginate
            handlePrevious={ handlePageChange }
            handlePage={ handlePageChange }
            handleNext={ handlePageChange }
            currentPage={ currentPage }
            itemsOnPage={ VISIBLE_ON_PAGE }
            totalItems={ existenceHotels.Hotels.length }
          />
        </ul>
      );
    }

    const diasabledAddWithoutMatching = Object.keys(matches).some((key) => !!matches[key]);

    return (
      <div>
        <h2>{ LABELS.TITLE }</h2>
        <div className={ styles.fields }>
          { renderInputProviders() }
        </div>
        <h4 className={ styles.error }>{ errorMatchIds }</h4>
        <div className={ styles.buttons }>
          <Button
            label={ LABELS.CHECK_MATCH }
            theme={ COMPONENTS.BUTTON.THEME.SECOND }
            onClick={ handleCheckMatch }
            className={ styles.button }
            loading={ loading }
          />
          <Button
            label={ LABELS.ADD_WITHOUT_MATCHING }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ handleShowWarningMatchDialog }
            className={ styles.button }
            disabled={ diasabledAddWithoutMatching }
          />
        </div>
      </div>
    );
  };

  const renderDialogWithoutMatching = () => {
    if (!showWarningMatchDialog) return null;

    return (
      <Dialog
        onClick={ handleShowWarningMatchDialog }
      >
        <div className={ styles.dialog_warning }>
          <h4>{ LABELS.WARNING_DIALOG }</h4>
          <div className={ styles.buttons }>
            <Button
              label={ LABELS.CLOSE }
              theme={ COMPONENTS.BUTTON.THEME.SECOND }
              onClick={ handleShowWarningMatchDialog }
              className={ styles.button }
            />
            <Button
              label={ LABELS.CONTINUE }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ moveToAddHotel }
              className={ styles.button }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <div className={ styles.wrap }>
      { renderBody() }
      { renderDialogWithoutMatching() }
    </div>
  );
};

MatchHotels.propTypes = {
  hotelsService: PropTypes.object.isRequired,
  moveToAddHotel: PropTypes.func.isRequired,
};

export { MatchHotels };
