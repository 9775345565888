import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Select } from 'sw-ui';

import RangeDatePicker from '../../../../components/RangeDatePicker';

import { ACCOUNTTEXT } from '../../../../bi/constants/account';

import styles from '../styles/account.module.scss';

class SelectDate extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    items: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    singleDate: PropTypes.bool.isRequired,
    rangeDate: PropTypes.bool.isRequired,
    dates: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onChangedDates: PropTypes.func.isRequired,
  };

  renderSingleDate = () => (
    <div className={ styles.date }>
      <div className={ styles['date-row'] }>
        <DatePicker
          placeholder='Выберите число'
          label='Выберите число'
          value={ this.props.dates.startDate }
          nullable
          onChange={ ({ value }) => this.props.onChangedDates('startDate', value) }
        />
      </div>
    </div>
  );

  renderRangeDate = () => {
    const { startDate, endDate } = this.props.dates;

    return (
      <div className={ styles.date }>
        <RangeDatePicker
          startDate={ startDate }
          endDate={ endDate }
          onChange={ this.props.onChangedDates }
          placeholderNameFrom={ ACCOUNTTEXT.FROM }
          placeholderNameTo={ ACCOUNTTEXT.TO }
        />
      </div>
    );
  };

  render() {
    const {
      label, items, value, singleDate, rangeDate, onChange,
    } = this.props;

    let dateHtml = null;

    if (singleDate) {
      dateHtml = this.renderSingleDate();
    } else if (rangeDate) {
      dateHtml = this.renderRangeDate();
    }

    const isDate = singleDate || rangeDate ? 'isDate' : '';

    return (
      <div className={ `${styles.filter} ${styles[isDate]}` }>
        <div className={ styles['select-date'] }>
          <Select
            label={ label }
            theme='border'
            items={ items }
            value={ value }
            onChange={ onChange }
          />
        </div>
        { dateHtml }
      </div>
    );
  }
}

export { SelectDate };
