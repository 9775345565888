import React, { Component } from 'react';
import PropTypes from 'prop-types';

import decline from '../../bi/utils/decline';
import {
  ACCOUNTTEXT, BRAND_NAME, COMPANIESLIST,
} from '../../bi/constants/account';

import LinkToNewTab from '../../components/LinkToNewTab';

import styles from './styles/companies.module.scss';

const COUNTNOTHIDDENCOMPANIES = 4;

const LABELS = {
  SMART_AGENT: 'Smartagent',
  SMART_AGENT_AUTO: 'Smartagent(auto)',
  SMARTWAY_KZ: 'Smartway KZ',
};

class CompaniesItem extends Component {
  static propTypes = { item: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    this.state = {
      toggleCompanies: false,
      isSmartwayKZ: false,
      // нужный костыль
    };
  }

  toggleOtherCompanies = () => this.setState({ toggleCompanies: !this.state.toggleCompanies });

  renderToggleAllView = (companiesValue) => {
    const { toggleCompanies } = this.state;

    const text = !toggleCompanies
      ? `Ещё ${companiesValue} ${decline(companiesValue, COMPANIESLIST)} в аккаунте`
      : ACCOUNTTEXT.HIDECOMPANY;

    return (
      <div
        className={ styles.others }
        onClick={ this.toggleOtherCompanies }
      >
        <div className={ styles.text }>{ text }</div>
        <span className={ `arrow ${styles.arrow} ${toggleCompanies ? styles.open : ''}` } />
      </div>
    );
  };

  renderCompanies = () => {
    const { item: { Companies } } = this.props;
    const { toggleCompanies } = this.state;

    let list = [];

    if (Companies.length <= COUNTNOTHIDDENCOMPANIES || toggleCompanies) {
      list = Companies;
    } else {
      list = Companies.slice(0, 4);
    }

    return list.map(({
      AccountId, CompanyId, CompanyName, ShortCompanyName,
    }) => (
      <LinkToNewTab
        key={ `${CompanyId}` }
        to={ `/account/${AccountId}/company/${CompanyId}/account/profile` }
      >
        <div className={ styles.list }>
          { ShortCompanyName || CompanyName }
          { ' ' }
          (
          { CompanyId }
          )
        </div>
      </LinkToNewTab>
    ));
  };

  renderPriznakAccount = (brand) => {
    switch (brand) {
      case BRAND_NAME.SMARTAGENT:
        return (
          <span className={ styles.smart_agent }>
            { LABELS.SMART_AGENT }
          </span>
        );
      case BRAND_NAME.SMARTAGENT_AUTO:
        return (
          <span className={ styles.smart_agent }>
            { LABELS.SMART_AGENT_AUTO }
          </span>
        );
      case BRAND_NAME.SMARTWAY_KZ:
        return (
          <span className={ styles.kz }>
            { LABELS.SMARTWAY_KZ }
          </span>
        );
      default:
        return null;
    }
  };


  render() {
    const {
      item: {
        Companies, Name, Id, BrandName,
      },
    } = this.props;

    const companiesValue = Companies.length > COUNTNOTHIDDENCOMPANIES ? Companies.length - COUNTNOTHIDDENCOMPANIES : 0;
    const othersCompaniesHtml = companiesValue ? this.renderToggleAllView(companiesValue) : null;
    const companiesHtml = this.renderCompanies();
    const nameHtml = Name
      || (
        <span className={ styles.nodicated } >
          { ' ' }
          { ACCOUNTTEXT.NOINDICATED }
          { ' ' }
        </span>
      );

    return (
      <div className={ styles.content } >
        <div className={ styles.header }>
          { nameHtml }
          { ' ' }
          (
          { Id }
          )
          { this.renderPriznakAccount(BrandName) }
        </div>
        <div className={ styles.details }>
          { companiesHtml }
        </div>
        { othersCompaniesHtml }
      </div>
    );
  }
}

export default CompaniesItem;
