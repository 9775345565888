import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import { dateUtcFormat } from '../../../../../../bi/utils/formatDate';
import MoneyFormat from '../../../../../../bi/utils/money';
import { routeTaxi } from '../../../../../../bi/utils/trip';

import COMPONENTS from '../../../../../../bi/constants/components';
import { TRIPBUTTONS } from '../../../../../../bi/constants/trips';
import { ACTION_IN_DATE_TIME_PATTERN } from '../../../../../../constants/time';
import { TAXI_CLASSES_TITLE } from '../../../../../../constants/taxi';

import AjaxButton from '../../../../../../components/ajaxButton';

import styles from '../../../../styles/trip.module.scss';

const LABELS = {
  ORDER_ID: 'Id заказа:',
  DATE_TIME: 'Дата и время подачи:',
  NO_VOUCHER_TRIPS: 'Поездок по ваучеру пока не было',
  VOUCHER_TRIPS: 'Совершенные поездки',
  VAT: 'НДС',
  ALL_TRIPS: 'Все поездки',
};

const OperationsTaxi = ({
  operations = [],
  onChangeCheckbox,
  onCheckAllOperations,
  checkboxesOperations,
  onOpenDeleteOperationsDialog,
  onOpenMoveOperationsDialog,
  isStartEdit,
  voucherId,
  checkAllOperational,
  flagReplaceTaxi,
  countOrders,
  operationsInf,
  order: {
    Surcharges, TripItemId, CompanyId, Id: tripItemVersionId,
  },
}) => {
  const renderEmptyOperations = () => (
    <div className={ `${styles.row}` }>
      <div className={ `${styles['col-1']} ${styles.operation_title}` }>
        { LABELS.NO_VOUCHER_TRIPS }
      </div>
    </div>
  );

  const renderMoveTripButton = () => {
    if (!checkboxesOperations.length
      || !isStartEdit
      || !flagReplaceTaxi
      || (countOrders > 1 && operations.length === checkboxesOperations.length)
      || operationsInf[0].VoucherId !== voucherId) return null;

    const labelMove = operations.length === checkboxesOperations.length
      ? TRIPBUTTONS.MOVE_FULL_VOUCHER
      : TRIPBUTTONS.MOVE_TAXI_TRIP;

    return (
      <div className={ styles.action }>
        <AjaxButton
          label={ labelMove }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ () => onOpenMoveOperationsDialog(Surcharges) }
        />
      </div>
    );
  };

  const renderOrdersButtons = () => {
    if (!checkboxesOperations.length
      || !isStartEdit
      || operationsInf[0].VoucherId !== voucherId) return null;

    return (
      <div className={ styles.action }>
        <AjaxButton
          label={ TRIPBUTTONS.DELETE_OPERATION_TAXI }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ onOpenDeleteOperationsDialog }
        />
      </div>
    );
  };

  const renderArrow = (index, length) => {
    if (index !== length - 1) return <span>&#8594;</span>;

    return null;
  };

  const renderRoutes = (routes) => routes.map((route, index) => (
    <div>
      { routeTaxi(route) }
      { ' ' }
      { renderArrow(index, routes.length) }
    </div>
  ));

  const renderCheckAllOperations = () => {
    if (!isStartEdit) return null;

    return (
      <div className={ styles.action }>
        <Checkbox
          theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
          value={ checkAllOperational && operationsInf[0].VoucherId === voucherId }
          onChange={ () => onCheckAllOperations(operations, voucherId, tripItemVersionId, TripItemId, CompanyId) }
        />
        <div>{ LABELS.ALL_TRIPS }</div>
      </div>
    );
  };

  const renderCheckbox = (id, operation) => {
    if (!isStartEdit) return null;

    return (
      <Checkbox
        theme={ COMPONENTS.CHECKBOX.THEME.MAIN }
        value={ checkboxesOperations.indexOf(id) !== -1 }
        onChange={ () => onChangeCheckbox(operations, operation, voucherId, tripItemVersionId, TripItemId, CompanyId) }
      />
    );
  };

  const renderVat = (vat) => (`${LABELS.VAT} ( ${MoneyFormat.symbolWithMoneyWithDecimal(vat)} )`);

  const renderOperations = () => (
    <div className={ `${styles.row}` }>
      <div className={ `${styles['col-1']} ${styles.operation_title}` }>
        { LABELS.VOUCHER_TRIPS }
      </div>
      <div className={ `${styles.row} ${styles['row-data']}` }>
        { renderCheckAllOperations() }
        <div className={ styles['icon-row'] }>
          { renderMoveTripButton() }
          { renderOrdersButtons() }
        </div>
      </div>

      { operations.map((operation) => {
        const {
          Id, EventDate, Route, Class, Amount, OrderId, Vat,
        } = operation;

        const vatHtml = renderVat(Vat);

        return (
          <div className={ `${styles.row} ${styles.operation_item}` } key={ Id }>
            <div className={ styles['col-1-16'] }>
              { renderCheckbox(Id, operation) }
            </div>
            <div className={ styles['col-1-16'] }>
              { Id }
            </div>
            <div className={ `${styles['col-12-16']} ${styles['order-info']}` }>
              <div className={ `${styles.row} ${styles.operation_row}` }>
                { renderRoutes(Route) }
              </div>
              <div className={ `${styles.row} ${styles.operation_row}` }>
                <div className={ styles['col-1-3'] }>
                  <div className={ styles.row }>
                    <div className={ styles['col-1'] }>
                      { LABELS.DATE_TIME }
                    </div>
                    <div className={ styles['col-1'] }>
                      { `${dateUtcFormat(EventDate, ACTION_IN_DATE_TIME_PATTERN)}` }
                    </div>
                  </div>
                </div>
                <div className={ `${styles['col-1-3']} ${styles.text_center}` }>
                  { TAXI_CLASSES_TITLE[Class] }
                </div>
              </div>
              <div className={ `${styles.row} ${styles.operation_row}` }>
                <div className={ styles['col-1'] }>
                  { `${LABELS.ORDER_ID} ${OrderId}` }
                </div>
              </div>
            </div>
            <div className={ `${styles['col-2-16']} ${styles.text_right}` }>
              <div className={ `${styles.row} ${styles.operation_row}` }>
                <div className={ styles['col-1'] }>
                  { MoneyFormat.symbolWithMoneyWithDecimal(Amount) }
                </div>
              </div>
              <div className={ `${styles.row} ${styles.operation_row}` }>
                <div className={ styles['col-1'] }>
                  { vatHtml }
                </div>
              </div>
            </div>
          </div>
        );
      }) }
    </div>
  );

  const contentHtml = !operations.length ? renderEmptyOperations() : renderOperations();

  return (
    <div className={ `${styles.row}` }>
      { contentHtml }
    </div>
  );
};


OperationsTaxi.propTypes = {
  operations: PropTypes.array.isRequired,
  checkboxesOperations: PropTypes.array.isRequired,
  onChangeCheckbox: PropTypes.func.isRequired,
  onCheckAllOperations: PropTypes.func.isRequired,
  onOpenDeleteOperationsDialog: PropTypes.func.isRequired,
  onOpenMoveOperationsDialog: PropTypes.func.isRequired,
  isStartEdit: PropTypes.bool.isRequired,
  voucherId: PropTypes.number.isRequired,
  checkAllOperational: PropTypes.bool.isRequired,
  flagReplaceTaxi: PropTypes.bool.isRequired,
  countOrders: PropTypes.number.isRequired,
  operationsInf: PropTypes.array.isRequired,
  order: PropTypes.object.isRequired,
};

export { OperationsTaxi };
