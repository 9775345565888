import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Button, Loading, COMPONENTS, Checkbox,
} from 'sw-ui';

import RenderField from './renderField';

import { formatDate } from '../../../../utils/date';

import { STATUSLIST } from '../../../../bi/constants/account';

import AviaContract from './aviaContract/aviaContract';
import HotelContract from './hotelContract/hotelContracts';
import CompanyInfoForm from './infoForm';
import UserList from './users/userList';
import Requisites from './requisites';
import { IsdNumber } from './isdNumber';
import CompanySettingsDialog from './company/companySettingsDialog';

import styles from '../styles/account.module.scss';

const LABELS = {
  DELETE_COMPANY: 'Удалить компанию',
  DELETE_COMPANY_TITLE: 'Вы действительно хотите удалить компанию?',
  DELETE_SOMETHING_WRONG: 'Что-то пошло не так',
  REQUISITES_SMARTWAY: 'Реквизиты Смартвэй для ИСД',
  COMPANY_SETTINGS: 'Настройки компании',
  DELETE: 'Удалить',
  CANCEL: 'Отменить',
  USER_STATUS_DISABLED: 'Disabled',
  DISPLAY_SETTING_DATE: 'Отображать номер и дату договора',
  COMMISSION_CHARGED: 'Берется комиссия при пополнении картой',
  PLEASE_WAIT: 'Пожалуйста подождите',
  FIELDS: {
    IINBIN: 'ИИН/БИН',
    INN: 'ИНН',
    KPP: 'КПП',
  },
  SHORTNAME: 'Краткое имя',
  LEGAL_ADRESS: 'Юридический адрес',
  ACTUAL_ADRESS: 'Фактический адрес',
  CEO: 'CEO',
  CONTRACT_NUMBER: 'Номер договора',
  CONTRACT_DATE: 'Дата договора',
};

const INPUT_FIELDS = {
  NAME: 'name',
  INN: 'inn',
  KPP: 'kpp',
  BIC: 'bik',
  INVOICE_NUMBER: 'invoiceNumber',
  EMAIL: 'email',
  BANK_DETAILS: 'bankDetails',
  CORRESPONDENT_BILL: 'correspondentBill',
  ISD: 'isd',
};

export default class Profile extends Component {
  static propTypes = {
    companyService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    toUserCartsAndNote: PropTypes.func.isRequired,
    params: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
  };

  updateCompany = ({
    companySettings,
    loadingCompanySettings,
    profile,
    userActiveData,
    userSettings,
    accountTypeList,
    userCompanies,
    loading,
  }) => this.setState({
    companySettings,
    loadingCompanySettings,
    accountTypeList,
    loading,
    ...profile,
    userActiveData,
    userSettings,
    userCompanies,
  });

  updateAccount = ({
    account: { Companies },
    isSmartAgent,
    isSmartwayKZ,
  }) => this.setState({
    Companies,
    isSmartAgent,
    isSmartwayKZ,
  });

  constructor(props) {
    super(props);

    const {
      accountTypeList,
      companySettings,
      loadingCompanySettings,
      profile: {
        details,
        schemas,
        periodUpd,
        users,
        aviaContracts,
      },
      id,
      userActiveData,
      userSettings,
      userCompanies,
      loading,
    } = props.companyService.get();

    const {
      account: { Companies },
      isSmartAgent,
      isSmartwayKZ,
    } = props.accountService.get();

    this.state = {
      companySettings,
      loadingCompanySettings,
      accountTypeList,
      details,
      userSettings,
      userCompanies,
      schemas,
      periodUpd,
      users,
      id,
      Companies,
      aviaContracts,
      showEmployeesDialog: false,
      showEditReportingSettingsDialog: false,
      showEditOverdraftDialog: false,
      showRequisitesDialog: false,
      showEditCompanyDialog: false,
      error: null,
      loading,
      showCompanySettingsDialog: false,
      userActiveData,
      isSmartAgent,
      isSmartwayKZ,
    };

    if (props.params.showEditCompanyDialog) {
      this.state.showEditCompanyDialog = true;
    }
  }

  async componentDidMount() {
    const { companyService, accountService } = this.props;

    await companyService.loadProfile(this.props.params.accountId);
    companyService.getSettingsDisplayDate();
    companyService.getCardPaymentFee();

    this.unsubscribeCompany = companyService.subscribe(this.updateCompany);
    this.unsubscribeAccount = accountService.subscribe(this.updateAccount);
  }

  componentWillUnmount() {
    this.unsubscribeCompany();
    this.unsubscribeAccount();
  }

  toggleEditReportingSettingsDialog = () => {
    this.setState({ showEditReportingSettingsDialog: !this.state.showEditReportingSettingsDialog });
  };

  toggleEditOverdraftDialog = () => {
    this.setState({ showEditOverdraftDialog: !this.state.showEditOverdraftDialog });
  };

  toggleEditCompanyDialog = () => {
    this.setState({
      showEditCompanyDialog: !this.state.showEditCompanyDialog,
      error: null,
    }, this.props.companyService.profile.clearQueryString);
  };

  editCompany = (details) => {
    const { details: { Isd } } = this.state;
    const { companyService, params: { accountId } } = this.props;

    this.setState({ error: null });

    return companyService.saveDetails({
      ...details, AccountId: accountId, Isd,
    }).then(() => {
      this.toggleEditCompanyDialog();
    })
      .catch((err) => {
        this.setState({ error: err.response.body.Message });
      });
  };

  handleClickOnUser = (user) => {
    this.props.toUserCartsAndNote(user);
  };

  handleSaveComment = (userId, comment) => this.props.companyService.saveUserComment(userId, comment);

  handleChangeUsersFields = (field, value, userId) => this.props.companyService.changeUsersFields(field, value, userId);

  handleSaveUserData = () => this.props.companyService.saveUserData();

  handleDeleteCompany = (cId) => {
    const { companyService } = this.props;

    companyService.deleteCompany(cId);
  };

  handleSetDeleteCompanyDialog = (value) => this.props.companyService.setDeleteDialog(value);

  handleShowCompanySettingsDialog = (value) => this.setState({ ...this.state, showCompanySettingsDialog: value });

  handleOpenCompanySettingsDialog = async (value) => {
    const { companyService } = this.props;
    const { details: { CompanyId } } = this.state;

    this.handleShowCompanySettingsDialog(value);

    await companyService.loadCompanySettingsWithWaiting(CompanyId);
  };

  handleSaveCompanySettings = async () => {
    const { companyService } = this.props;
    const { companySettings } = this.state;

    await companyService.updateCompanySettings(companySettings);

    this.handleShowCompanySettingsDialog(false);
  };

  handleResetDeleteErr = () => this.props.companyService.setDeleteErr(false);

  handleCloseDeleteDialog = () => {
    this.handleSetDeleteCompanyDialog(false);
    this.handleResetDeleteErr();
  };

  clearUserSettings = () => this.props.companyService.clearUserSettings();

  renderLoading = () => (
    <div className={ styles.loading }>
      <div className={ styles.text }>{ LABELS.PLEASE_WAIT }</div>
      <Loading
        size='large'
      />
    </div>
  );

  updateDisplayDateInDocuments = (show) => {
    this.props.companyService.updateDisplayDateInDocuments(show);
  };

  updateCardPaymentFee = (cardPaymentFee) => this.props.companyService.updateCardPaymentFee(cardPaymentFee);

  renderDeleteCompanyDialog = () => {
    const {
      details: { CompanyId },
      userActiveData: {
        deleteDialog: {
          loading,
          error,
        },
      },
    } = this.state;

    return (
      <Dialog onClick={ () => this.handleSetDeleteCompanyDialog(false) } width='400px'>
        <div className={ styles.delete_dialog }>
          { LABELS.DELETE_COMPANY_TITLE }
          { error && (
            <p className={ styles.err_text }>{ LABELS.DELETE_SOMETHING_WRONG }</p>
          ) }
          <div className={ styles.buttons }>
            <Button
              loading={ loading }
              label={ LABELS.DELETE }
              onClick={ () => this.handleDeleteCompany(CompanyId) }
            />
            <Button
              label={ LABELS.CANCEL }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ this.handleCloseDeleteDialog }
            />
          </div>
        </div>
      </Dialog>
    );
  };

  handleChangeCompanySettings = (name) => {
    const { companySettings } = this.state;

    this.setState({
      companySettings: {
        ...companySettings,
        [name]: !companySettings[name],
      },
    });
  };

  handleChangeUserSettings = (section, name) => {
    const { userSettings } = this.state;

    this.setState({
      userSettings: {
        ...userSettings,
        [section]: {
          ...userSettings[section],
          [name]: !userSettings[section][name],
        },
      },
    });
  };

  handleChangeArrayUserSettings = (section, name, value) => {
    const { userSettings } = this.state;

    this.setState({
      userSettings: {
        ...userSettings,
        [section]: {
          ...userSettings[section],
          [name]: value,
        },
      },
    });
  };

  handleShowRequisites = async () => {
    const { showRequisitesDialog } = this.state;
    const { companyService, params: { companyId } } = this.props;

    if (!showRequisitesDialog) {
      companyService.getRequisites(companyId);
    }

    this.setState({ showRequisitesDialog: !showRequisitesDialog });
  };

  renderRequisites = () => (
    <Requisites
      onShowRequisites={ this.handleShowRequisites }
      params={ this.props.params }
      INPUT_FIELDS={ INPUT_FIELDS }
      companyService={ this.props.companyService }
    />
  );

  renderCompanySettingsDialog = () => {
    const {
      details,
      companySettings,
      loadingCompanySettings,
    } = this.state;
    const { AccessSettingsChange } = this.props.featureFlagsService.get();

    return (
      <CompanySettingsDialog
        settings={ companySettings }
        loading={ loadingCompanySettings }
        details={ details }
        accessSettingsChange={ AccessSettingsChange }
        onChange={ this.handleChangeCompanySettings }
        onClose={ this.handleShowCompanySettingsDialog }
        onSave={ this.handleSaveCompanySettings }
      />
    );
  };

  renderWithTypeCompany = () => {
    const {
      isSmartwayKZ,
      details: {
        IINBIN,
        INN,
        KPP,
      },
    } = this.state;

    if (isSmartwayKZ) {
      return (
        <div className={ styles.row_kz }>
          <RenderField
            field={ LABELS.FIELDS.IINBIN }
            value={ IINBIN }
          />
        </div>
      );
    }

    return (
      <div className={ styles.row_kz }>
        <RenderField
          field={ LABELS.FIELDS.INN }
          value={ INN }
        />
        <RenderField
          field={ LABELS.FIELDS.KPP }
          value={ KPP }
        />
      </div>
    );
  };

  render() {
    const {
      accountTypeList,
      showEditCompanyDialog,
      showRequisitesDialog,
      showCompanySettingsDialog,
      error,
      loading,
      details,
      userSettings,
      userCompanies,
      userActiveData,
      Companies,
      userActiveData: {
        showUserErrorDialog,
        loadingUserSettings = userActiveData.loading,
        deleteDialog: { show: showDeleteCompanyDialog },
      },
      isSmartAgent,
      isSmartwayKZ,
    } = this.state;

    const {
      companyService,
      accountService,
      notificationsService,
      hotelsService,
      featureFlagsService: {
        get: getFeatureFlags,
        getDisplayRatesFromBooking,
      },
      params: { accountId, companyId },
    } = this.props;

    const {
      CompanyRemove: canRemoveCompany, AdminChange: adminChange, AccessSettingsChange,
    } = getFeatureFlags();
    const ShowInDocuments = companyService.get().ShowInDocuments;
    const cardPaymentFee = companyService.get().cardPaymentFee;

    const keyStatusList = Object.keys(STATUSLIST);
    const userListHTML = keyStatusList.map((item) => (
      <UserList
        key={ item }
        companies={ Companies }
        registrationStatus={ STATUSLIST[item] }
        companyService={ companyService }
        notificationsService={ notificationsService }
        userActiveData={ userActiveData }
        userSettings={ userSettings }
        userCompanies={ userCompanies }
        loadingUserSettings={ loadingUserSettings }
        showUserErrorDialog={ showUserErrorDialog }
        onClickOnUser={ this.handleClickOnUser }
        onSaveComment={ this.handleSaveComment }
        onChangeUsersData={ this.handleChangeUsersFields }
        onSaveUserData={ this.handleSaveUserData }
        onChangeUserSettings={ this.handleChangeUserSettings }
        onChangeArraySettings={ this.handleChangeArrayUserSettings }
        adminChange={ adminChange }
        accountId={ accountId }
        companyId={ companyId }
        clearUserSettings={ this.clearUserSettings }
        accessSettingsChange={ AccessSettingsChange }
      />
    ));
    const limitButtonStyle = !loading && `${details.AdditionalOverdraft !== 0 || details.AdditionalOverdraftPeriodInDays !== 0 ? styles['overdraft-button'] : ''}`;
    const contractStartDate = details.Contract && details.Contract.StartDate ? formatDate(details.Contract.StartDate) : '';

    return loading
      ? this.renderLoading()
      : (
        <div className={ styles['info-company'] }>
          <div className={ styles.panel }>
            <div className={ styles.title }>
              <div className={ styles['panel-name'] }>
                { `${details.CompanyName} (${details.CompanyId})` }
              </div>
              <div className={ styles.action }>
                <Button label='ПЕРИОД ВЫСТАВЛЕНИЯ УПД' theme='flat' onClick={ this.toggleEditReportingSettingsDialog } />
              </div>
              <div className={ styles.action }>
                <Button
                  label='ЛИМИТ'
                  theme='flat'
                  onClick={ this.toggleEditOverdraftDialog }
                  className={ limitButtonStyle }
                />
              </div>
              <div className={ styles.icon } onClick={ this.toggleEditCompanyDialog }>
                <i className='material-icons'>mode_edit</i>
              </div>
            </div>
            <div className={ styles.form }>
              <div className={ styles.row }>
                <RenderField
                  field={ LABELS.SHORTNAME }
                  value={ details.ShortCompanyName }
                />
              </div>
              { this.renderWithTypeCompany() }
              <div className={ styles.row }>
                <RenderField
                  field={ LABELS.LEGAL_ADRESS }
                  value={ details.LegalAddress }
                />
              </div>
              <div className={ styles.row }>
                <RenderField
                  field={ LABELS.ACTUAL_ADRESS }
                  value={ details.ActualAddress }
                />
              </div>
              <div className={ styles.row }>
                <RenderField
                  field={ LABELS.CEO }
                  value={ details.CEO }
                />
              </div>
              <div className={ styles.row }>
                <RenderField
                  field={ LABELS.CONTRACT_NUMBER }
                  value={ details.Contract ? details.Contract.Number : '' }
                />
              </div>
              <div className={ styles.row }>
                <RenderField
                  field={ LABELS.CONTRACT_DATE }
                  value={ contractStartDate }
                />
              </div>
              <IsdNumber
                details={ details }
                companyService={ companyService }
                INPUT_FIELDS={ INPUT_FIELDS }
              />
              <div className={ styles.row }>
                <div className={ styles.inline } >
                  <Checkbox
                    value={ ShowInDocuments }
                    onChange={ (value) => {
                      this.updateDisplayDateInDocuments(value);
                    } }
                  >
                    { LABELS.DISPLAY_SETTING_DATE }
                  </Checkbox>
                </div>
              </div>
              <div className={ styles.row }>
                <div
                  className={ `${styles['col-1-4']}, ${styles.requisites}` }
                  onClick={ this.handleShowRequisites }
                >
                  <span>
                    { ' ' }
                    { LABELS.REQUISITES_SMARTWAY }
                  </span>
                </div>
              </div>
              <AviaContract
                companyService={ companyService }
                isSmartAgent={ isSmartAgent }
              />
              <HotelContract
                companyService={ companyService }
                accountService={ accountService }
                hotelsService={ hotelsService }
                displayRates={ getDisplayRatesFromBooking() }
                isSmartAgent={ isSmartAgent }
              />
              <div className={ styles.row }>
                <div className={ styles.inline } >
                  <Checkbox
                    value={ cardPaymentFee }
                    onChange={ this.updateCardPaymentFee }
                  >
                    { LABELS.COMMISSION_CHARGED }
                  </Checkbox>
                </div>
              </div>
              <div className={ styles.row }>
                <div
                  className={ `${styles['col-1-4']}, ${styles.requisites}` }
                  onClick={ () => this.handleOpenCompanySettingsDialog(true) }
                >
                  <span>
                    { LABELS.COMPANY_SETTINGS }
                  </span>
                </div>
              </div>
            </div>
            { canRemoveCompany && (
              <Button
                label={ LABELS.DELETE_COMPANY }
                className={ styles.delete_button }
                onClick={ () => this.handleSetDeleteCompanyDialog(true) }
              />
            ) }
          </div>
          <div className={ `${styles.panel} ${styles['user-employee']}` }>
            <div className={ styles.form }>
              <div>
                { userListHTML }
              </div>
            </div>
          </div>
          { showEditCompanyDialog
            ? (
              <Dialog onClick={ this.toggleEditCompanyDialog } width='400px'>
                <div className={ `${styles.form} ${styles['form-dialog']}` }>
                  <CompanyInfoForm
                    details={ details }
                    AccountTypeList={ accountTypeList }
                    error={ error }
                    onSave={ this.editCompany }
                    onClose={ this.toggleEditCompanyDialog }
                    companyService={ this.props.companyService }
                    isSmartwayKZ={ isSmartwayKZ }
                  />
                </div>
              </Dialog>
            )
            : null }
          { showDeleteCompanyDialog && this.renderDeleteCompanyDialog() }
          { showCompanySettingsDialog && this.renderCompanySettingsDialog() }
          { showRequisitesDialog && this.renderRequisites() }
        </div>
      );
  }
}
