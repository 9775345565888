import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input';
import AjaxButton from '../../../../components/ajaxButton';
import Checkbox from '../../../../components/checkbox';
import Radio from '../../../../components/radio';

import FILEFORMATS from '../../../../constants/fileFormats';
import { ACCOUNTTEXT } from '../../../../bi/constants/account';

import styles from '../../styles/company.module.scss';

const LABELS = {
  STAMP: 'С печатью и подписью',
  NOT_STAMP: 'Без печати и подписи',
  DOWNLOAD_SCORE: 'Скачать счет на предоплату',
  SIGNATURE_TYPE: 'Выберите тип подписи:',
};

class InvoiceForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      format: 'pdf',
      sum: '',
      waitingResponse: false,
      flagStamp: true,
    };
  }

  static propTypes = { onDownload: PropTypes.func.isRequired };

  handleChangeFormat = (format) => {
    const valueFlagStamp = format === FILEFORMATS.PDF ? true : this.state.flagStamp;

    this.setState({
      flagStamp: valueFlagStamp,
      format,
    });
  };

  handleChangeSum = (e, field, value) => {
    this.setState({ sum: value });
  };

  handleDownload = () => {
    const {
      format, sum, flagStamp,
    } = this.state;
    const { onDownload } = this.props;

    this.setState({ waitingResponse: true }, () => onDownload(format, sum, flagStamp));
  };

  handleFlagStamp = (flagStamp) => {
    this.setState({ flagStamp });
  };

  render() {
    const {
      format, sum, waitingResponse, flagStamp,
    } = this.state;

    const valueDisabled = format === FILEFORMATS.PDF;

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <p className={ `${styles.row} ${styles.title}` }>{ LABELS.DOWNLOAD_SCORE }</p>
        <div className={ `${styles.row} ${styles['upd-row']} ${styles['row-between']}` }>
          <div className={ styles['col-1-3'] }>
            <Input
              field='Sum'
              value={ sum }
              type='number'
              label='Сумма'
              onChange={ this.handleChangeSum }
            />
          </div>
          <p className={ `${styles.title_stamp}` }>{ LABELS.SIGNATURE_TYPE }</p>
          <div className={ `${styles.revise__radio}` }>
            <Checkbox
              label={ LABELS.STAMP }
              value={ flagStamp }
              onChange={ () => this.handleFlagStamp(!flagStamp) }
              disabled={ valueDisabled }
            />
          </div>
          <div className={ `${styles.revise__radio} ${styles.row_for_user_item}` }>
            <Checkbox
              label={ LABELS.NOT_STAMP }
              value={ !flagStamp }
              onChange={ () => this.handleFlagStamp(!flagStamp) }
              disabled={ valueDisabled }
            />
          </div>
          <div className={ `${styles.btn_download}` }>
            <div className={ `${styles.btn_radio}` }>
              <div className={ `${styles.revise__radio} ${styles.revise__margin}` }>
                <Radio
                  className={ styles.revise__radiobutton }
                  label={ FILEFORMATS.PDF }
                  checked={ valueDisabled }
                  field={ FILEFORMATS.PDF }
                  value={ FILEFORMATS.PDF }
                  onChange={ () => this.handleChangeFormat(FILEFORMATS.PDF) }
                />
              </div>
              <div className={ styles.revise__radio }>
                <Radio
                  className={ styles.revise__radiobutton }
                  label={ FILEFORMATS.XLSX }
                  checked={ format === FILEFORMATS.XLSX }
                  field={ FILEFORMATS.XLSX }
                  value={ FILEFORMATS.XLSX }
                  onChange={ () => this.handleChangeFormat(FILEFORMATS.XLSX) }
                />
              </div>
            </div>
            <div className={ ` ${styles.action}` }>
              <AjaxButton
                label={ ACCOUNTTEXT.DOWNLOAD }
                loading={ waitingResponse }
                onClick={ this.handleDownload }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceForm;
