import { matchPath } from 'react-router-dom';
import { history } from '../../../utils/history';

export const getCompanyFromParams = (
  path = '/account/:accountId/company/:companyId/account/:tab',
) => {
  const matchedPath = matchPath(
    { path },
    history.location.pathname,
  );

  if (matchedPath) {
    return matchedPath.params.companyId;
  }

  throw new Error('Not Found');
};
