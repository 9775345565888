import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RadioButton, Checkbox } from 'sw-ui';

import styles from '../../../styles/settings.module.css';

const LABELS = {
  TITLE: 'Отображать значения в отдельной колонке',
  NOT_SHOW_COLUMNS: 'Не отображать дополнительные колонки',
  PROJECT: 'Центр затрат',
  USER_ANALYTICS: 'Пользовательская аналитика',
  REQUEST_1C: 'Заявки из 1С',
  CUSTOM_TAGS: 'Пользовательские метки',
  FILL_COLUMNS: 'Заполнять колонки значениями',
  UNIT: 'Единица измерения',
  QUANTITY: 'Количество',
};

const SettingAdditionalColumn = ({
  settings,
  onChangeSettings,
}) => {
  const { SeparateColumn, FillColumns } = settings;
  const [val, setVal] = useState(SeparateColumn);
  const [fillColumns, setFillColumns] = useState(FillColumns);

  const handleChange = (value) => {
    setVal(value);
    onChangeSettings({ ...settings, SeparateColumn: value });
  };

  const handleChangeCheckbox = (label) => {
    let updatedFillColumns;

    if (fillColumns.includes(label)) {
      updatedFillColumns = fillColumns.filter((item) => item !== label);
    } else {
      updatedFillColumns = [...fillColumns, label];
    }

    setFillColumns(updatedFillColumns);
    onChangeSettings({ ...settings, FillColumns: updatedFillColumns });
  };

  return (
    <div className={ styles.wrap }>
      <div className={ styles.content }>
        <div className={ styles.title }>
          { LABELS.TITLE }
        </div>
        <div className={ styles.radio }>
          <RadioButton
            onChange={ () => handleChange(LABELS.NOT_SHOW_COLUMNS) }
            checked={ val === LABELS.NOT_SHOW_COLUMNS }
            className={ styles.radio_width }
          >
            { LABELS.NOT_SHOW_COLUMNS }
          </RadioButton>
          <RadioButton
            onChange={ () => handleChange(LABELS.PROJECT) }
            checked={ val === LABELS.PROJECT }
            className={ styles.radio_width }
          >
            { LABELS.PROJECT }
          </RadioButton>
          <RadioButton
            onChange={ () => handleChange(LABELS.USER_ANALYTICS) }
            checked={ val === LABELS.USER_ANALYTICS }
            className={ styles.radio_width }
          >
            { LABELS.USER_ANALYTICS }
          </RadioButton>
          <RadioButton
            onChange={ () => handleChange(LABELS.REQUEST_1C) }
            checked={ val === LABELS.REQUEST_1C }
            className={ styles.radio_width }
          >
            { LABELS.REQUEST_1C }
          </RadioButton>
          <RadioButton
            onChange={ () => handleChange(LABELS.CUSTOM_TAGS) }
            checked={ val === LABELS.CUSTOM_TAGS }
            className={ styles.radio_width }
          >
            { LABELS.CUSTOM_TAGS }
          </RadioButton>
        </div>
      </div>
      <div className={ styles.content }>
        <div className={ styles.title }>
          { LABELS.FILL_COLUMNS }
        </div>
        <div className={ styles.radio }>
          <Checkbox
            className={ styles.radio_width }
            onChange={ () => handleChangeCheckbox(LABELS.UNIT) }
            value={ fillColumns.includes(LABELS.UNIT) }
          >
            { LABELS.UNIT }
          </Checkbox>
          <Checkbox
            className={ styles.radio_width }
            onChange={ () => handleChangeCheckbox(LABELS.QUANTITY) }
            value={ fillColumns.includes(LABELS.QUANTITY) }
          >
            { LABELS.QUANTITY }
          </Checkbox>
        </div>
      </div>
    </div>
  );
};

SettingAdditionalColumn.propTypes = {
  settings: PropTypes.object.isRequired,
  onChangeSettings: PropTypes.func.isRequired,
};

export { SettingAdditionalColumn };
