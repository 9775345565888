import React from 'react';
import PropTypes from 'prop-types';
import { MultiSelect, DatePicker } from 'sw-ui';

import Input from '../../../../../../components/input';
import EmployeeSuggest from '../../../../../../components/employee';

import { getYesterday } from '../../../../../../bi/utils/formatDate';

import COMPONENTS from '../../../../../../bi/constants/components';
import { NEW_TAXI_CLASSES, OLD_TAXI_CLASSES } from '../../../../../../constants/taxi';
import { FIELDS_GIFT_VOUCHER } from '../../../../../../bi/constants/trips';

import styles from '../../index.module.css';

const LABELS = {
  SET_EMPLOYEE: 'Выберите сотрудника',
  DATE_START: 'Дата начала',
  DATE_END: 'Дата окончания',
  COUNT: 'Стоимость (без учета НДС)',
  SELECT_CLASSES: 'Выберите классы',
};

const SecondStep = ({
  data: {
    companyId,
    selectedEmployee,
    startDate,
    endDate,
    classes,
    limit,
    validation,
  },
  newTaxiClasses,
  onSendCompanyId,
  onSelectEmployeeSuggest,
  onSetValue,
  validate,
  employeesList,
}) => {
  const classesForSelect = newTaxiClasses
    ? NEW_TAXI_CLASSES
    : OLD_TAXI_CLASSES;

  const renderValidation = (field) => {
    if (!validation[field]) return null;

    return <span className='error-msg'>{ validation[field] }</span>;
  };

  return (
    <div>
      <EmployeeSuggest
        items={ employeesList }
        selected={ selectedEmployee }
        placeholder={ LABELS.SET_EMPLOYEE }
        onRemove={ () => onSelectEmployeeSuggest({}) }
        onSelect={ onSelectEmployeeSuggest }
        onChange={ (value) => onSendCompanyId(companyId, value) }
        valid={ validation.selectedEmployee }
      />
      <div className={ styles.rows }>
        <div className={ styles.field }>
          <DatePicker
            label={ LABELS.DATE_START }
            type={ COMPONENTS.DATEPICKER.TYPE.DATE }
            inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
            value={ startDate }
            onChange={ ({ value }) => onSetValue(FIELDS_GIFT_VOUCHER.START_DATE, value) }
            className={ styles.datepicker }
            minDate={ getYesterday() }
          />
          { renderValidation(FIELDS_GIFT_VOUCHER.START_DATE) }
        </div>
        <div className={ styles.field }>
          <DatePicker
            label={ LABELS.DATE_END }
            type={ COMPONENTS.DATEPICKER.TYPE.DATE }
            inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
            value={ endDate }
            onChange={ ({ value }) => onSetValue(FIELDS_GIFT_VOUCHER.END_DATE, value) }
            className={ styles.datepicker }
            minDate={ getYesterday() }
          />
          { renderValidation(FIELDS_GIFT_VOUCHER.END_DATE) }
        </div>
      </div>
      <div className={ styles.rows }>
        <div className={ styles.field }>
          <MultiSelect
            label={ LABELS.SELECT_CLASSES }
            items={ classesForSelect }
            values={ classes }
            onChange={ (value) => onSetValue(FIELDS_GIFT_VOUCHER.CLASSES, value) }
            selectAll
          />
          { renderValidation(FIELDS_GIFT_VOUCHER.CLASSES) }
        </div>
        <div className={ styles.field }>
          <Input
            field={ FIELDS_GIFT_VOUCHER.LIMIT }
            value={ limit }
            label={ LABELS.COUNT }
            onChange={ (e, field, value) => onSetValue(field, value) }
            type='number'
            onBlur={ (e, field) => validate(field, limit) }
            valid={ validation.limit }
          />
        </div>
      </div>
    </div>
  );
};

SecondStep.propTypes = {
  data: PropTypes.object.isRequired,
  newTaxiClasses: PropTypes.bool.isRequired,
  employeesList: PropTypes.array.isRequired,
  onSendCompanyId: PropTypes.func.isRequired,
  onSelectEmployeeSuggest: PropTypes.func.isRequired,
  onSetValue: PropTypes.func.isRequired,
  validate: PropTypes.func.isRequired,
};

export { SecondStep };
