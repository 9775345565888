import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles/index.module.css';

const LABEL = 'Клиент отключен';

const DisabledAccount = ({ disabled }) => {
  if (!disabled) return null;

  return <div className={ styles.wrap }>{ LABEL }</div>;
};

DisabledAccount.propTypes = { disabled: PropTypes.bool.isRequired };

export default DisabledAccount;
