import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Checkbox extends Component {
  onChange = (e) => {
    const value = e.target.checked;

    if (this.props.onChange) {
      this.props.onChange(e, this.props.field, value);
    }
  };

  render() {
    const {
      value,
      label,
      children,
      labelClass,
      wrapClass,
      disabled,
    } = this.props;

    return (
      <div className={ `form-group ${wrapClass}` }>
        <label className={ labelClass }>
          <input
            type='checkbox'
            checked={ value }
            disabled={ disabled }
            onChange={ this.onChange }
          />
          { label }
        </label>
        { children }
      </div>
    );
  }
}

Checkbox.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  labelClass: PropTypes.string,
  wrapClass: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  children: null,
  label: '',
  labelClass: '',
  wrapClass: '',
  disabled: false,
  value: false,
};

export default Checkbox;
