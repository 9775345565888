const COMPONENTS = {
  BUTTON: {
    THEME: {
      FLAT: 'flat',
      WHITE: 'white',
      SECOND: 'second',
      LABEL: 'Ok',
      OPEN: 'open',
      MAIN: 'main',
      RED: 'red',
    },
    TYPE: { SUBMIT: 'submit' },
  },
  CHECKBOX: { THEME: { MAIN: 'main' } },
  LOADING: {
    SIZE: {
      SMALL: 'small',
      LARGE: 'large',
    },
  },
  DATEPICKER: {
    TYPE: {
      DATE: 'date',
      DATETIME: 'datetime',
    },
    INPUTTHEME: { OPEN: 'open' },
    VIEWMODE: { MONTHS: 'months' },
  },
  INPUT: {
    TYPE: {
      NUMBER: 'number',
      TEXT: 'text',
    },
  },
  SELECT: {
    THEME: {
      BORDER: 'border',
      LIGHT: 'light',
    },
  },
  TOOLTIP: {
    POSITION: {
      LEFT: 'left',
      RIGHT: 'right',
      TOP: 'top',
      BOTTOM: 'bottom',
    },
    THEME: { LIGHT: 'light' },
  },
  COMMENTCOMPONENT: {
    POSITION: {
      RIGHT: 'right',
      BOTTOM: 'bottom',
    },
  },
  DIALOG: {
    WIDTH: {
      SMALL: '360px',
      MIDDLE: '400px',
    },
  },
  PROGRESS: {
    VALUE: { SMALL: 0.3 },
    SPEED: { SLOW: 'slow' },
  },
};

export default COMPONENTS;
