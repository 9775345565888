let ACTIONS;

const newComplaint = {
  id: '',
  claim_date: '',
  claim_link: '',
  description: '',
};

const DEFAULT = {
  statics: {
    isLoading: true,
    functional: [],
    bonuses: '',
  },
  complaints: {
    complaintsContent: [],
    isLoading: true,
  },
};

const reducer = (action, state) => {
  switch (action.type) {
    case ACTIONS.COMPANY.UPDATE_STATISTIC_FUNCTIONAL: {
      return {
        ...state,
        statics: {
          ...state.statics,
          isLoading: false,
          functional: action.payload.statisticFunctional,
          bonuses: action.payload.bonuses,
        },
      };
    }
    case ACTIONS.COMPANY.UPDATE_LOADING_COMPLAINTS: {
      return {
        ...state,
        complaints: {
          ...state.complaints,
          isLoading: action.payload,
        },
      };
    }
    case ACTIONS.COMPANY.UPDATE_STATISTIC_COMPLAINTS: {
      return {
        ...state,
        complaints: {
          ...state.complaints,
          complaintsContent: action.payload,
          isLoading: false,
        },
      };
    }
    case ACTIONS.COMPANY.CREATE_STATISTIC_COMPLAINT: {
      return {
        ...state,
        complaints: {
          ...state.complaints,
          complaintsContent: [newComplaint, ...state.complaints.complaintsContent],
        },
      };
    }
    case ACTIONS.COMPANY.REMOVE_STATISTIC_COMPLAINT: {
      return {
        ...state,
        complaints: {
          ...state.complaints,
          complaintsContent: state.complaints.complaintsContent.filter((complaint) => complaint.id !== action.payload),
        },
      };
    }
    case ACTIONS.COMPANY.UPDATE_STATISTIC_BONUSES: {
      return {
        ...state,
        statics: {
          ...state.statics,
          bonuses: action.payload,
        },
      };
    }
  }

  return state;
};

export default function (core) {
  ACTIONS = core.getNamespace('ACTIONS');

  core.addStore('Statics', reducer, DEFAULT);
}
