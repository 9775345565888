import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';
import { Dialog } from 'sw-ui';

import { preparedRates } from '../../../../../bi/utils/account';
import {
  formatDate,
  momentObject,
  isValidMomentObject,
  isMoment,
  differenceMonths,
  getLastDayOfMonth,
  getFirstDayOfMonth,
  isSameOrAfterMonthMomentObject,
  isSameOrAfter,
} from '../../../../../bi/utils/formatDate';
import { validationDatesWriteOff } from '../../../../../bi/utils/company';
import { getCurrentDate } from '../../../../../utils/date';

import COMPONENTS from '../../../../../bi/constants/components';
import { TRIPSLABELS } from '../../../../../bi/constants/trips';
import {
  MONTHYEARPATTERN, DATE, DAYPATTERN, DEFAULTDATE, LOCALERU,
} from '../../../../../constants/time';

import SelectWithInput from '../../../../../components/SelectWithInput';
import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../../../styles/company.module.scss';

const LABELS = {
  DATE_START_PERIOD: 'Дата начала периода',
  DATE_END_PERIOD: 'Дата конца периода',
  DATE_WRITEOFF: 'Дата списания',
  SELECT_SUM: 'Выбор суммы списания',
  SELECT_PERIOD: 'Выберите период списания, не ранее ',
};

class PlanFee extends Component {
  static propTypes = {
    lastPlanFeeHistories: PropTypes.array.isRequired,
    companyId: PropTypes.number.isRequired,
    onAddPlanFee: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    arrayRate: PropTypes.array,
    labelHeader: PropTypes.string,
    placeholderLabel: PropTypes.string,
  };

  static defaultProps = {
    feeDate: '',
    labelHeader: '',
    placeholderLabel: '',
    arrayRate: null,
  };

  constructor(props) {
    super();

    const {
      lastPlanFeeHistories, companyId, arrayRate,
    } = props;
    const rates = arrayRate || preparedRates();

    const finderFeeDate = lastPlanFeeHistories.length
      ? lastPlanFeeHistories.find(({ CompanyId }) => CompanyId === companyId)
      : null;

    const feeDate = finderFeeDate ? finderFeeDate.FeeDate : '';

    this.state = {
      rates,
      currentRate: {
        id: '',
        type: '',
        value: 0,
        label: '',
      },
      feeDate,
      startDate: feeDate ? momentObject(feeDate) : momentObject(''),
      endDate: momentObject(''),
      viewStartDate: feeDate ? formatDate(getFirstDayOfMonth(feeDate), DAYPATTERN) : '',
      viewEndDate: '',
      eventDate: getCurrentDate(),
    };
  }

  getValidEventDate = (currentDate) => {
    const date = getCurrentDate();

    return validationDatesWriteOff(date, currentDate, isSameOrAfter);
  };

  getValidDate = (currentDate) => (
    validationDatesWriteOff(this.state.startDate, currentDate, isSameOrAfterMonthMomentObject)
  );

  handleSelectRate = (item) => this.setState({ currentRate: item });

  handleChangeStartDate = (value) => {
    const startDate = isMoment(value) ? getFirstDayOfMonth(value) : momentObject('');
    const viewStartDate = isValidMomentObject(startDate) ? formatDate(getFirstDayOfMonth(startDate), DAYPATTERN) : '';

    this.setState({
      startDate,
      viewStartDate,
      endDate: momentObject(''),
      viewEndDate: '',
    });
  };

  handleChangeEndDate = (value) => {
    const preparedValue = validationDatesWriteOff(this.state.startDate, value, isSameOrAfterMonthMomentObject) ? value : momentObject('');
    const viewEndDate = isValidMomentObject(preparedValue) ? formatDate(getLastDayOfMonth(preparedValue), DAYPATTERN) : '';

    this.setState({
      endDate: preparedValue,
      viewEndDate,
    });
  };

  handleChangeDate = (value) => {
    this.setState({ eventDate: value });
  };

  addTooltip = () => {
    const {
      startDate, endDate, eventDate, currentRate,
    } = this.state;

    const values = [
      { label: LABELS.DATE_START_PERIOD, value: !isValidMomentObject(startDate) },
      { label: LABELS.DATE_END_PERIOD, value: !isValidMomentObject(endDate) },
      { label: LABELS.DATE_WRITEOFF, value: !isMoment(eventDate) },
      { label: LABELS.SELECT_SUM, value: !currentRate.value },
    ];
    const arrErrors = [];

    values.forEach((item) => {
      if (item.value) {
        arrErrors.push(` ${item.label.toLowerCase()}`);
      }
    });

    return arrErrors;
  };

  renderPeriod = () => {
    const {
      startDate, endDate, viewStartDate, viewEndDate,
    } = this.state;

    return (
      <div className={ styles['plan-fee-dialog__date-row'] }>
        <div>c&nbsp;</div>
        <div className={ styles['plan-fee-dialog__date'] }>
          <span className={ `${styles['plan-fee-dialog__date-first-day']} ${!viewStartDate.length ? styles['not-view'] : ''}` }>{ viewStartDate }</span>
          <DatePicker
            closeOnSelect
            dateFormat={ MONTHYEARPATTERN }
            viewMode={ COMPONENTS.DATEPICKER.VIEWMODE.MONTHS }
            value={ startDate }
            onChange={ this.handleChangeStartDate }
          />
          <label>
            { ' ' }
            { LABELS.DATE_START_PERIOD }
            { ' ' }
          </label>
        </div>
        <div className={ styles['plan-fee-dialog__date-text'] }>по&nbsp;</div>
        <div className={ styles['plan-fee-dialog__date'] }>
          <span className={ `${styles['plan-fee-dialog__date-day']} ${!viewEndDate.length ? styles['not-view'] : ''}` }>{ viewEndDate }</span>
          <DatePicker
            closeOnSelect
            dateFormat={ MONTHYEARPATTERN }
            viewMode={ COMPONENTS.DATEPICKER.VIEWMODE.MONTHS }
            value={ endDate }
            isValidDate={ this.getValidDate }
            onChange={ this.handleChangeEndDate }
          />
          <label>{ LABELS.DATE_END_PERIOD }</label>
        </div>
      </div>
    );
  };

  renderEventDate = (eventDate) => (
    <div className={ styles.wrapper_date }>
      <DatePicker
        className={ styles.event_picker }
        closeOnSelect
        value={ eventDate }
        onChange={ (value) => this.handleChangeDate(value) }
        isValidDate={ this.getValidEventDate }
        dateFormat={ DEFAULTDATE }
        timeFormat={ false }
        locale={ LOCALERU }
      />
      <label>
        { LABELS.DATE_WRITEOFF }
      </label>
    </div>
  );

  render() {
    const {
      onAddPlanFee, onClose, labelHeader, placeholderLabel,
    } = this.props;
    const {
      rates, currentRate, startDate, endDate, feeDate, eventDate,
    } = this.state;

    const disabled = !startDate
      || !endDate
      || !isMoment(startDate)
      || !isMoment(endDate)
      || !isMoment(eventDate)
      || !isValidMomentObject(startDate)
      || !isValidMomentObject(endDate)
      || !currentRate.value;

    const data = !disabled
      ? {
        StartDate: formatDate(startDate, DATE),
        Rate: currentRate.value,
        Period: differenceMonths(startDate, endDate),
        EventDate: formatDate(eventDate, DATE),
      }
      : null;

    const feeDateHtml = feeDate.length
      ? (
        <div className={ styles['plan-fee-dialog__row'] }>
          { LABELS.SELECT_PERIOD }
          { formatDate(feeDate, MONTHYEARPATTERN) }
        </div>)
      : null;

    return (
      <Dialog
        width='500px'
        onClick={ onClose }
      >
        <div className={ styles['plan-fee-dialog__wrap'] }>
          <div className={ styles['plan-fee-dialog__header'] }>{ labelHeader }</div>
          { this.renderEventDate(eventDate) }
          { feeDateHtml }
          { this.renderPeriod() }
          <div className={ styles['plan-fee-dialog__row'] }>
            <div>
              { ' ' }
              { LABELS.SELECT_SUM }
              &nbsp;
            </div>
            <div className={ styles['plan-fee-dialog__select'] }>
              <SelectWithInput
                placeholder={ placeholderLabel }
                items={ rates }
                currentItem={ currentRate }
                onChange={ this.handleSelectRate }
              />
            </div>
          </div>
          <DecisionButtons
            loadingIncluded
            tooltip={ TRIPSLABELS.TOOLTIP(this.addTooltip()) }
            disabled={ disabled }
            onSave={ () => onAddPlanFee(data) }
            onCancel={ onClose }
          />
        </div>
      </Dialog>
    );
  }
}

export default PlanFee;
