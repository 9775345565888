import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../../../components/button';
import TextArea from '../../../../../../components/textarea';
import Input from '../../../../../../components/input';

import { FIELDS } from '../../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../../bi/constants/components';

import style from '../../../../styles/form.module.scss';

const LABELS = {
  ADDITIONAL_TITLE: 'Доступные опции',
  ADDITIONAL_PLACEHOLDER: 'Введите перечень включенных услуг',
  ADDITIONAL_SERVICE_TITLE: 'Дополнительные услуги',
  ADDITIONAL_SERVICE_NAME: 'Название услуги',
  ADDITIONAL_SERVICE_PRICE: 'Цена',
  DELETE_SERVICE: 'Удалить услугу',
  ADD_ADDITIONAL: '+ Добавить услугу',
};

const AdditionalFields = ({
  additionalInfo,
  additionalServices,
  onChangeInput,
  onAddAdditional,
  onChangePenaltyInput,
  onRemoveService,
}) => {
  const renderAdditionalServices = (name, price, i) => (
    <div className={ style.row } key={ i }>
      <div className={ style['col-1-3'] }>
        <Input
          field={ FIELDS.ADDITIONAL_SERVICES_NAME }
          value={ name }
          label={ LABELS.ADDITIONAL_SERVICE_NAME }
          onChange={ (e, field, val) => onChangePenaltyInput(field, val, i) }
        />
      </div>
      <div className={ style['col-1-3'] }>
        <Input
          field={ FIELDS.ADDITIONAL_SERVICES_PRTICE }
          value={ price }
          label={ LABELS.ADDITIONAL_SERVICE_PRICE }
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          onChange={ (e, field, val) => onChangePenaltyInput(field, val, i) }
        />
      </div>
      <div className={ style['col-1-3'] }>
        <div className={ style['col-1-3'] }>
          <div className={ style['add-conditions'] }>
            <FlatButton
              label={ LABELS.DELETE_SERVICE }
              onClick={ () => onRemoveService(i) }
            />
          </div>
        </div>
      </div>
    </div>
  );

  const additionalServicesList = additionalServices
    ? additionalServices.map((service, i) => (
      renderAdditionalServices(service.Name, service.Price, i)
    ))
    : renderAdditionalServices('', 0, false);

  return (
    <div className={ style.row }>
      <div className={ `${style['col-1-3']}` }>
        <h4>{ LABELS.ADDITIONAL_TITLE }</h4>
        <TextArea
          className={ style.textarea }
          value={ additionalInfo }
          placeholder={ LABELS.ADDITIONAL_PLACEHOLDER }
          height={ 100 }
          onChange={ (e) => onChangeInput(e, FIELDS.ADDITIONAL_INFO, e) }
        />
      </div>
      <div className={ `${style['col-1-2']}` }>
        <h4>{ LABELS.ADDITIONAL_SERVICE_TITLE }</h4>
        <div className={ style.row }>{ additionalServicesList }</div>
        <div className={ style['add-conditions'] }>
          <FlatButton
            label={ LABELS.ADD_ADDITIONAL }
            onClick={ onAddAdditional }
          />
        </div>
      </div>
    </div>
  );
};

AdditionalFields.propTypes = {
  additionalInfo: PropTypes.string,
  additionalServices: PropTypes.array.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onAddAdditional: PropTypes.func.isRequired,
  onChangePenaltyInput: PropTypes.func.isRequired,
  onRemoveService: PropTypes.func.isRequired,
};

AdditionalFields.defaultProps = { additionalInfo: '' };

export { AdditionalFields };
