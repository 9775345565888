import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, COMPONENTS } from 'sw-ui';

import { SIGNATURE_TYPES, SIGNATURE_CERTIFICATE } from '../../../bi/constants/account';
import Radio from '../../radio';
import AjaxButton from '../../ajaxButton';

import styles from './styles/formDownloadFlightCertificate.module.css';

const { BUTTON: { THEME: { FLAT } } } = COMPONENTS;

const LABELS = {
  DOWNLOAD: 'Скачать',
  CANCEL: 'Отмена',
  ERROR: 'Произошла ошибка в момент получения справки о перелете',
};

class FormDownloadFlightCertificate extends Component {
  static propTypes = {
    airItemId: PropTypes.number.isRequired,
    onDownload: PropTypes.func.isRequired,
    isFreeFlightCertificate: PropTypes.bool,
    onClickClose: PropTypes.func,
  };

  static defaultProps = {
    isFreeFlightCertificate: false,
    onClickClose: () => {},
  };

  state = {
    signatureType: SIGNATURE_TYPES.FULL_SIGNATURE,
    isLoading: false,
    isError: false,
  };

  handleChangeSignature = (value) => this.setState({ signatureType: value });

  handleDownload = async () => {
    const {
      airItemId,
      onDownload,
      onClickClose,
      isFreeFlightCertificate,
    } = this.props;
    const { signatureType } = this.state;

    this.setState({ isLoading: true });

    const status = await onDownload(airItemId, signatureType, isFreeFlightCertificate);

    if (status) {
      this.setState({ isLoading: false });

      return onClickClose();
    }

    return this.setState({
      isLoading: false,
      isError: true,
    });
  };

  render() {
    const { onClickClose } = this.props;
    const {
      signatureType,
      isLoading,
      isError,
    } = this.state;

    const contentHtml = isError
      ? (
        <p className={ styles.error }>
          { LABELS.ERROR }
        </p>
      )
      : (
        <form>
          <div className={ styles.radio }>
            <Radio
              label={ SIGNATURE_CERTIFICATE.FULL_SIGNATURE }
              checked={ signatureType === SIGNATURE_TYPES.FULL_SIGNATURE }
              field={ SIGNATURE_TYPES.FULL_SIGNATURE }
              value={ SIGNATURE_TYPES.FULL_SIGNATURE }
              onChange={ (field, value) => this.handleChangeSignature(value) }
            />
          </div>
          <div className={ styles.radio }>
            <Radio
              label={ SIGNATURE_CERTIFICATE.LIGHT_SIGNATURE }
              checked={ signatureType === SIGNATURE_TYPES.LIGHT_SIGNATURE }
              field={ SIGNATURE_TYPES.LIGHT_SIGNATURE }
              value={ SIGNATURE_TYPES.LIGHT_SIGNATURE }
              onChange={ (field, value) => this.handleChangeSignature(value) }
            />
          </div>
          <div className={ styles.radio }>
            <Radio
              label={ SIGNATURE_CERTIFICATE.WITHOUT_SIGNATURE }
              checked={ signatureType === SIGNATURE_TYPES.WITHOUT_SIGNATURE }
              field={ SIGNATURE_TYPES.WITHOUT_SIGNATURE }
              value={ SIGNATURE_TYPES.WITHOUT_SIGNATURE }
              onChange={ (field, value) => this.handleChangeSignature(value) }
            />
          </div>
        </form>
      );

    return (
      <div className={ styles.format_form }>
        { contentHtml }
        <div className={ styles.buttons }>
          <AjaxButton
            label={ LABELS.DOWNLOAD }
            loading={ isLoading }
            disabled={ isError }
            onClick={ this.handleDownload }
          />
          <Button
            theme={ FLAT }
            label={ LABELS.CANCEL }
            onClick={ onClickClose }
          />
        </div>
      </div>
    );
  }
}

export { FormDownloadFlightCertificate };
