import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';

import { FIELDS } from '../../../../../../bi/constants/trips';
import { DEFAULTTIME, DEFAULTDATE } from '../../../../../../constants/time';

import styles from '../../../../styles/form.module.scss';

const MAX_LENGTH = 40;

const LABELS = {
  CANCELED_DATE: 'Дата отмены трансфера',
  CANCELED_TIME: 'Время отмены трансфера',
};

const CancellationFields = ({
  cancellationDate,
  cancellationTime,
  validation,
  onChangeInput,
  onValidate,
  renderError,
}) => (
  <div className={ `${styles.row} ${styles.panel} ${styles.row_canceled}` }>
    <div className={ styles['col-1-3'] }>
      <DatePicker
        dateFormat={ DEFAULTDATE }
        timeFormat={ false }
        locale='ru'
        value={ cancellationDate }
        onChange={ (value) => onChangeInput(value, FIELDS.CANCELLATION_DATE, MAX_LENGTH) }
        onBlur={ () => onValidate(FIELDS.CANCELLATION_DATE, cancellationDate) }
        className={ `componentHook ${validation.CancellationDate ? 'no-valid' : ''}` }
      />
      <label>{ LABELS.CANCELED_DATE }</label>
      { renderError(FIELDS.CANCELLATION_DATE) }
    </div>
    <div className={ styles['col-1-3'] }>
      <DatePicker
        dateFormat={ false }
        timeFormat={ DEFAULTTIME }
        locale='ru'
        value={ cancellationTime }
        onChange={ (value) => onChangeInput(value, FIELDS.CANCELLATION_TIME, MAX_LENGTH) }
        onBlur={ () => onValidate(FIELDS.CANCELLATION_TIME, cancellationTime) }
        className={ `componentHook ${validation.CancellationTime ? 'no-valid' : ''}` }
      />
      <label>{ LABELS.CANCELED_TIME }</label>
      { renderError(FIELDS.CANCELLATION_TIME) }
    </div>
  </div>
);

CancellationFields.propTypes = {
  cancellationDate: PropTypes.string.isRequired,
  cancellationTime: PropTypes.string.isRequired,
  validation: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  renderError: PropTypes.func.isRequired,
};

export { CancellationFields };
