import React from 'react';
import PropTypes from 'prop-types';

import useDisplayingProfileData from './hooks/useDisplayingProfileData';

import styles from './styles.module.css';

const LABELS = {
  dateOfBirth: 'Дата рождения',
  citizenship: 'Гражданство',
  gender: 'Пол',
  email: 'Email',
  phone: 'Номер телефона',
  notes: 'Заметки',
};

const General = ({ profile, citizenshipEnums }) => {
  const {
    sex, fullName, dateOfBirth, citizenship, email, phone, notes,
  } = useDisplayingProfileData(profile, citizenshipEnums);

  return (
    <div className={ styles.section }>
      <h1>{ fullName }</h1>
      <div className={ styles['info-row'] }>
        <label>{ LABELS.dateOfBirth }</label>
        <span>{ dateOfBirth }</span>
      </div>
      <div className={ styles['info-row'] }>
        <label>{ LABELS.citizenship }</label>
        <span>{ citizenship }</span>
      </div>
      <div className={ styles['info-row'] }>
        <label>{ LABELS.gender }</label>
        <span className={ styles.emoji }>{ sex }</span>
      </div>
      <div className={ styles['info-row'] }>
        <label>{ LABELS.email }</label>
        <span>{ email }</span>
      </div>
      <div className={ styles['info-row'] }>
        <label>{ LABELS.phone }</label>
        <span>{ phone }</span>
      </div>
      <div className={ styles['info-row'] }>
        <label>{ LABELS.notes }</label>
        <span>{ notes }</span>
      </div>
    </div>
  );
};

General.propTypes = {
  profile: PropTypes.shape({}).isRequired,
  citizenshipEnums: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default General;
