const ERRORSFORALL = {
  NOCOMPANY: 'Выберите компанию',
  NOPROJECT: 'Выберите центр затрат',
  NODEPARTMENT: 'Выберите отдел',
  NODESCRIPTION: 'Введите наименование изменения',
  NOEMPLOYEE: 'Выберите сотрудника',
  NOCOMISSION: 'Введите наценку Smartway',
  NO_COMMISSION: 'Введите сбор Smartway',
  FIRSTSELECTCOMPANY: 'Сначала выберите компанию',
  NOANALYTICS: 'Выберите аналитику',
  NO_PROJECTS: 'ЦЗ является обязательным. Добавьте ЦЗ, чтобы создать поездку',
  NO_PASSPORT: 'Нет паспорта',
  NO_BASE: 'Введите стоимость',
  NO_TRANSACTION_ID: 'Введите номер транзакции',
  NO_TICKET_NUMBER: 'Введите номер билета',
  RESERVATION_DATE: 'Введите дату',
  MIN_LENGTH_VALUE: 'ИНН должен содержать только цифры и состоять из 10, 12 или 13 цифр',
};

const ERRORSFORAIR = {
  NONUM: 'Введите номер билета длиной 14 символов, укажите дефис (xxx-xxxxxxxxxx)',
  NOROUTESDURATION: 'Введите время полета',
  NOSEGMENTSCODE: 'Введите код авиакомпании',
  NOSEGMENTSNAME: 'Введите авиакомпанию',
  NOARRIVALAIRPORT: 'Введите код аэропорта прилета',
  NOARRIVALNAME: 'Введите аэропорт прилета',
  NOARRIVALCITY: 'Введите город прилета',
  NOCOUNTRY: 'Введите страну',
  NOARRIVALDATE: 'Введите дату прилета',
  NOARRIVALTIME: 'Введите время прилета',
  NODEPARTUREARPORT: 'Введите код аэропорта вылета',
  NODEPARTURENAME: 'Введите аэропорт вылета',
  NODEPARTURECITY: 'Введите город вылета',
  NODEPARTUREDATE: 'Введите дату вылета',
  NODEPARTURETIME: 'Введите время вылета',
  NOFLIGHTNUMBER: 'Введите номер рейса',
  NOPRICEDETAILS: 'Введите цену',
  NOPROVIDER: 'Выберите провайдера',
  NO_OFFICE_ID: 'Выберите Office Id',
  NO_SALE_POINT: 'Выберите ППР',
  NOPNR: 'Введите код брони ГДС',
};

const ERRORSFORHOTEL = {
  NORESERVNUMBER: 'Введите номер бронирования',
  NOGUESTS: 'Введите количество гостей',
  NONAME: 'Введите наименование',
  NOADRESS: 'Введите адрес',
  NOCITY: 'Введите город',
  NO_LOCATION: 'Выберите страну из выпадающего списка',
  NORESERVDATE: 'Введите дату бронирования',
  NOCHECKINDATE: 'Введите дату заезда',
  NOCHECKOUTDATE: 'Введите дату выезда',
  NOCHECKINDATEISLESS: 'Дата выезда не может быть меньше даты заезда',
  NO_CHECKIN_TIME_IS_LESS: 'Время выселения не может быть меньше времени заселения',
  NOBASE: 'Введите цену поставщика',
  NOPROVIDER: 'Провайдер должен быть выбран',
  NOHASVAT: 'Необходимо установить признак НДС',
  NOEARLYCHECKIN: 'Введите цену раннего заезда',
  NOLATECHECKOUT: 'Введите цену позднего выезда',
  DATELESSTODAY: 'Дата заезда уже прошла',
  DATE_LESS_CHECKOUT_DATE: 'Дата выезда уже прошла',
  DATELESSTODAYEDIT: 'Дата не может быть меньше текущей',
  DATE_CANCELLATION: 'Укажите дату',
  SUM_CANCELLATION: 'Введите сумму',
  BASEPENALTY_CANCELLATION: 'Введите базовую сумму',
  NOBREAKFASTNAME: 'Введите тип питания',
  NO_CHECKOUT_TIME: 'Введите время выселения (ЧЧ:мм)',
  NO_CHECKIN_TIME: 'Введите время заселения (ЧЧ:мм)',
  NO_CATEGORY: 'Введите категорию комнаты',
  NO_INTEGER_NUMBER: 'Введите целое число',
  VAT_AMOUNT: 'Введите сумму НДС',
  NO_MARKETING_FEE: 'Введите Marketing Fee',
  NO_INCLUSIVE: 'Введите сумму inclusive',
  NO_BASE_CURRENCY: 'Введите цену в валюте',
  NO_COMISSION_CURRENCY: 'Введите комиссию в валюте',
  CALCULATE_IN_CURRENCY: 'Рассчитайте в валюте',
};

const ERRORSFORTRAIN = {
  NOORDER: 'Введите номер заказа',
  NO_SALE_ORDER: 'Введите номер позиции в заказе',
  NOTICKET: 'Введите № билета(цифры), ограничение - 14 символов',
  NO_DEPARTURE_DATE: 'Введите дату отправления',
  NO_DEPARTURE_TIME: 'Введите время отправления',
  NO_ARRIVE_DATE: 'Введите дату прибытия',
  NO_ARRIVE_TIME: 'Введите время прибытия',
  NO_ARRIVE_STATION: 'Выберите станцию прибытия',
  NO_DEPARTURE_STATION: 'Выберите станцию отправления',
  NO_TRAIN_NUMBER: 'Введите № Поезда(буквы и цифры), ограничение - 6 символов',
  NO_CAR_NUMBER: 'Введите № вагона(цифры), ограничение - 2 символа',
  NO_PLACE_NUMBER: 'Введите № места(цифры), ограничение - 3 символа',
  NO_TARIFF_PRICE: 'Введите Тариф (tariff price), ограничение - 10 символов',
  NO_TARIFF_FARE: 'Введите Тариф (fare), ограничение - 10 символов',
  NO_AMOUNT: 'Введите сумму НДС, ограничение - 10 символов',
  NO_СOMMISION: 'Введите сбор Smartway, ограничение - 3 символа',
  NO_FEE: 'Введите сбор РЖД, ограничение -  ограничение - 10 символов',
  NO_CAR_TYPE_DESCRIPTION: 'Выберите описание вагона',
  NO_CLASS_SERVICE: 'Выберите класс вагона',
  NO_CARRIER: 'Выберите перевозчика',
  NO_PICK_FILE: 'Выберите файл',
  NO_TRANSIT_DOCUMENT: 'Выберите транзитный документ',
};

const ERRORSFORCUSTOM = {
  NO_NAME: 'Введите название услуги',
  NO_CHECKIN_DATE: 'Введите дату начала услуги',
  NO_CHECKIN_TIME: 'Введите время начала услуги',
  NO_CHECKOUT_DATE: 'Введите дату окончания услуги',
  NO_CHECKOUT_TIME: 'Введите время окончания услуги',
  DATE_LESS_TODAY: 'Дата поездки уже прошла',
  NO_CHECKIN_DATE_IS_LESS: 'Дата не может быть меньше начала услуги',
  DATE_LESS_TODAY_EDIT: 'Дата не может быть меньше текущей',
  NO_EMPLOYEE: 'Выберите сотрудника',
  NO_BASE: 'Введите цену поставщика',
  NO_PROVIDER: 'Провайдер должен быть выбран',
  NO_ORDER_ID: 'Введите номер бронирования у поставщика',
};

const ERRORS_FOR_EVENT = {
  NO_NAME: 'Введите название услуги',
  NO_CHECKIN_DATE: 'Введите дату начала услуги',
  NO_CHECKIN_TIME: 'Введите время начала услуги',
  NO_CHECKOUT_DATE: 'Введите дату окончания услуги',
  NO_CHECKOUT_TIME: 'Введите время окончания услуги',
  DATE_LESS_TODAY: 'Дата поездки уже прошла',
  NO_BASE: 'Введите корректную стоимость',
  NO_CHECKIN_DATE_IS_LESS: 'Дата не может быть меньше начала услуги',
  DATE_LESS_TODAY_EDIT: 'Дата не может быть меньше текущей',
  NO_PROVIDER: 'Выберите провайдера услуги',
};

const ERRORSFORTRANSFER = {
  NO_AIRPORT: 'Введите назавание аэропорта',
  NO_ARRIVAL_COUNTRY: 'Введите страну прибытия',
  NO_ARRIVAL_CITY: 'Введите город прибытия',
  NO_DESTINATION_COUNTRY: 'Введите страну отправления',
  NO_DESTINATION_CITY: 'Введите город отправления',
  NO_DEPARTURE: 'Введите номер рейса прилета (и терминал если есть) или поезда прибытия',
  NO_ARRIVAL: 'Введите номер рейса вылета (и терминал если есть) или поезда отправления',
  DATELESSTODAY: 'Дата поездки уже прошла',
  DATELESSTODAYEDIT: 'Дата не может быть меньше текущей',
  NOCHECKINDATE: 'Введите дату подачи машины',
  NOCHECKINTIME: 'Введите время подачи машины',
  NOCHECKINDATEISLESS: 'Дата приезда не может быть меньше даты подачи машины',
  NOCHECKOUTDATE: 'Введите дату приезда',
  NOCHECKOUTTIME: 'Введите время приезда',
  NOCANCELLATIONDATE: 'Введите дату отмены трансфера',
  NOCANCELLATIONTIME: 'Введите время отмены трансфера',
  NOSTARTPLACE: 'Введите пункт отправления',
  NOENDPLACE: 'Введите пункт прибытия',
  NOSTREETSTARTPLACE: 'Введите улицу и номер дома пункта отправления',
  NOSTREETENDPLACE: 'Введите улицу и номер дома пункта прибытия',
  NO_EMPTY_FIELD: 'Поле не может быть пустым',
  NO_TRAIN: 'Введите название ж/д вокзала',
  NOPROVIDER: 'Провайдер должен быть выбран',
  NO_CARRIAGE_NUMABER: 'Введите номер вагона',
};

const ERRORS_FOR_VIP_HALL = {
  NO_PROVIDER_ORDER_ID: 'Введите номер бронирования',
  NO_CHECKINDATE: 'Введите дату вылета',
  NO_CHECKINTIME: 'Введите время вылета',
  NO_CHECKOUTDATE: 'Введите дату прилета',
  NO_CHECKOUTTIME: 'Введите время прилета',
  NO_CANCELLATIONDATE: 'Введите дату отмены',
  NO_CANCELLATIONTIME: 'Введите время отмены',
  NO_AIRPORT_FROM: 'Введите место вылета',
  NO_AIRPORT_TO: 'Введите место прилета',
  NO_PRICE: 'Введите цену',
  NO_COMMISSION: 'Введите наценку',
  NO_PROVIDER: 'Провайдер должен быть выбран',
  NO_NAME: 'Введите название услуги',
  NO_ADDITIONAL_INFO: 'Введите доступные опции',
  NO_ORDER_ID: 'Введите номер бронирования',
  NO_TERMINAL: 'Введите терминал',
  NO_CHECKIN_DATE_IS_LESS: 'Дата прилета не может быть меньше даты вылета',
  NO_NUMBER: 'Введите номер рейса',
  CHECKIN_LESS_TODAY: ' Дата вылета уже прошла',
  DATELESSTODAY: 'Дата прилета уже прошла',
};

const ERRORS_FOR_TAXI = { NO_ORDER_ID: 'Введите Id поездки' };

const ERROR_FOR_GIFT_VOUCHER = {
  NO_DATE_START: 'Введите дату начала',
  NO_DATE_END: 'Введите дату окончания',
  NO_CLASSES: 'Выберите классы',
  NO_LIMIT: 'Выберите лимит',
};

const ERRORS_FOR_INSURANCE = {
  NO_NAME: 'Введите название',
  NO_DIRECTION: 'Введите направление',
  NO_SELECTED_ITEMS: 'Выберите услуги',
  NO_SELECTED_RATE: 'Выберите тариф',
};

const ERRORS_FOR_BUS = {
  NO_SEAT_NUMBER: 'Введите номер места в автобусе',
  NO_ORDER_ID: 'Введите номер чата/заказа',
  NO_LUGGAGE_TYPE: 'Выберите тип багажа',
  NO_DEPARTURE: 'Введите пункт отправления',
  NO_DESTINATION: 'Введите пункт прибытия',
  NO_DEPARTURE_DATE_TIME: 'Введите дату и время отправления',
  DESTINATION_DATE_EARLIER_THAN_DEPARTURE_DATE: 'Дата прибытия раньше даты отправления, либо одинаковы',
  NO_DESTINATION_DATE_TIME: 'Введите дату и время прибытия',
  NO_BASE: 'Введите цену поставщика',
  NO_VAT: 'Выберите НДС',
  NO_PICK_FILE: 'Выберите файл',
  NO_PROVIDERS: 'Нет доступных провайдеров. Проверьте интернет соединение',
};

export {
  ERRORSFORALL,
  ERRORSFORHOTEL,
  ERRORSFORAIR,
  ERRORSFORTRAIN,
  ERRORSFORCUSTOM,
  ERRORSFORTRANSFER,
  ERRORS_FOR_VIP_HALL,
  ERRORS_FOR_TAXI,
  ERRORS_FOR_EVENT,
  ERRORS_FOR_INSURANCE,
  ERRORS_FOR_BUS,
  ERROR_FOR_GIFT_VOUCHER,
};
