import React from 'react';
import PropTypes from 'prop-types';

const Radio = ({
  field,
  onChange,
  value,
  label,
  checked,
  className,
  disabled,
}) => {
  const handleChange = (e) => {
    if (onChange) {
      onChange(field, e.target.value);
    }
  };

  return (
    <div className='form-group'>
      <label>
        <input
          type='radio'
          value={ value }
          checked={ checked }
          className={ className }
          onChange={ handleChange }
          disabled={ disabled }
        />
        { label }
      </label>
    </div>
  );
};

Radio.propTypes = {
  checked: PropTypes.bool.isRequired,
  value: PropTypes.any.isRequired,
  field: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

Radio.defaultProps = {
  className: '',
  disabled: false,
};

export default Radio;
