import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Button } from 'sw-ui';

import AjaxButton from '../../../components/ajaxButton';

import styles from '../styles/dialog.module.css';

const LABELS = { PROHIBITION_VOUCHERS: 'Отправка ваучеров на email недоступна для этой компании.' };
class SaveMetadataDialog extends Component {
  static propTypes = {
    isProhibitionVoucher: PropTypes.bool,
    onSaveWithDocumentsSend: PropTypes.func.isRequired,
    onSaveWithoutDocumentsSend: PropTypes.func.isRequired,
    renderProhibitionCompaniesMassage: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static defaultProps = { isProhibitionVoucher: false };

  state = {
    waitingResponse: {
      saveWithDocumentsSend: false,
      saveWithoutDocumentsSend: false,
    },
  };

  handleSaveWithDocumentsSend = () => this.setState({
    waitingResponse: {
      ...this.state.waitingResponse,
      saveWithDocumentsSend: true,
    },
  }, this.props.onSaveWithDocumentsSend);

  handleSaveWithoutDocumentsSend = () => this.setState({
    waitingResponse: {
      ...this.state.waitingResponse,
      saveWithoutDocumentsSend: true,
    },
  }, this.props.onSaveWithoutDocumentsSend);

  render() {
    const { waitingResponse } = this.state;
    const { onClose, isProhibitionVoucher } = this.props;
    const isProhibition = isProhibitionVoucher
      ? LABELS.PROHIBITION_VOUCHERS
      : this.props.renderProhibitionCompaniesMassage(true);

    return (
      <Dialog onClick={ onClose } width='600px'>
        <div className={ styles.dialog }>
          <div className={ styles.row }>
            <p><b>Отправить ваучеры и счета пользователям?</b></p>
            <p>Если требуется внести изменения, документы будут отправлены после завершения обработки поездки.</p>
            <p>{ isProhibition }</p>
          </div>
          <div className={ styles.actions }>
            <div className={ styles.action }>
              <Button
                label='Отменить'
                theme='flat'
                onClick={ onClose }
              />
            </div>
            <div className={ styles.action }>
              <AjaxButton
                disabled={ isProhibitionVoucher }
                loading={ waitingResponse.saveWithDocumentsSend }
                label='Отправить сейчас'
                onClick={ this.handleSaveWithDocumentsSend }
              />
            </div>
            <div className={ styles.action }>
              <AjaxButton
                loading={ waitingResponse.saveWithoutDocumentsSend }
                label='Не отправлять'
                onClick={ this.handleSaveWithoutDocumentsSend }
              />
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default SaveMetadataDialog;
