import { FIELDS } from '../../../constants/hotels';

const {
  EC_LC_CONDITIONS_FIELDS: {
    CHARGE_VALUE,
    CHARGE_TYPE_UNIT,
    TIME_BORDER,
  },
} = FIELDS;

const NO_EMPTY = 'Поле не должно быть пустым';
const NO_TIME = 'Введите время в формате HH:mm:ss';

const require = (value) => !!value;

const staticConditionsValidStorage = {
  0: {
    ChargeValue: {
      fn: require,
      msg: NO_EMPTY,
    },
    TimeBorder: {
      fn: require,
      msg: NO_TIME,
    },
  },
  1: {
    ChargeValue: {
      fn: require,
      msg: NO_EMPTY,
    },
    TimeBorder: {
      fn: require,
      msg: NO_TIME,
    },
  },
};

class EcLcConditionsValidator {
  validateConditionsFields = (conditionsValidation, conditions, type, field, value) => {
    switch (field) {
      case CHARGE_VALUE: {
        return this.updateValidateConditions(conditionsValidation, type, field, value);
      }
      case TIME_BORDER: {
        if (conditions[type].ChargeTypeUnit !== 1) {
          return conditionsValidation;
        }

        return this.updateValidateConditions(conditionsValidation, type, field, value);
      }

      case CHARGE_TYPE_UNIT: {
        if (conditions[type].ChargeTypeUnit === 1) {
          return this.updateValidateConditions(conditionsValidation, type, TIME_BORDER, conditions[type].TimeBorder);
        }

        return {
          ...conditionsValidation,
          [type]: {
            ...conditionsValidation[type],
            TimeBorder: '',
          },
        };
      }
      default: {
        return conditionsValidation;
      }
    }
  };

  isValid = (conditionsValidation, conditions) => {
    const everyFieldsIsValid = Object
      .keys(conditionsValidation)
      .every((key) => Object.keys(conditionsValidation[key])
        .every((field) => !conditionsValidation[key][field]));

    const checkConditionsFields = Object
      .keys(conditions)
      .every((key) => Object.keys(conditions[key])
        .every((field) => {
          if (staticConditionsValidStorage[key][field]) {
            if (field === TIME_BORDER && conditions[key].ChargeTypeUnit !== 1) {
              return true;
            }

            return staticConditionsValidStorage[key][field].fn(conditions[key][field]);
          }

          return true;
        }));

    return everyFieldsIsValid && checkConditionsFields;
  };

  updateValidateConditions = (conditionsValidation, type, field, value) => ({
    ...conditionsValidation,
    [type]: {
      ...conditionsValidation[type],
      [field]: this.validateConditions(field, type, value),
    },
  });

  validateConditions = (field, type, value) => {
    if (staticConditionsValidStorage[type][field]) {
      const { fn, msg } = staticConditionsValidStorage[type][field];

      if (!fn(value)) {
        return msg;
      }
    }

    return '';
  };

  getValidationEcLcConditions = (conditions) => Object.keys(conditions).reduce((acc, key) => {
    const value = Object.keys(conditions[key]).reduce((accConditions, field) => {
      const partValidObj = staticConditionsValidStorage[key][field];

      if (!partValidObj) {
        return accConditions;
      }

      if (field === TIME_BORDER && conditions[key].ChargeTypeUnit !== 1) {
        return {
          ...accConditions,
          [field]: '',
        };
      }

      const { fn, msg } = partValidObj;

      return {
        ...accConditions,
        [field]: fn(conditions[key][field]) ? '' : msg,
      };
    }, {});

    return { ...acc, [key]: value };
  }, {});
}

export { EcLcConditionsValidator };
