import React from 'react';
import PropTypes from 'prop-types';

import Input from '../../../../components/input';

import COMPONENTS from '../../../../bi/constants/components';
import { TAGFIELDS } from '../../../../bi/constants/amo';
import styles from './styles.module.scss';

const UploadTag = ({
  onChange,
  tag,
}) => (
  <div className={ `${styles.row} ${styles.upload}` }>
    <div className={ styles.text }>{ TAGFIELDS.TEXTTAG }</div>
    <div>
      <span className={ styles.name }>
        { ' ' }
        { TAGFIELDS.HEADER }
        { ' ' }
      </span>
      <div className={ styles.row }>
        <Input
          field={ TAGFIELDS.TAG }
          type={ COMPONENTS.INPUT.TYPE.TEXT }
          value={ tag }
          label={ TAGFIELDS.ENTERTAG }
          onChange={ onChange }
        />
      </div>
    </div>
  </div>
);

UploadTag.propTypes = {
  tag: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default UploadTag;
