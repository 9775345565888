import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CircularLoaders from '../../components/loaders';
import Checkbox from '../../components/checkbox';

import BookTripList from './list';

import styles from './styles/bookTrip.module.scss';

const EMPTYLIST = 'Нет поездок';

class BookTripComponents extends Component {
  static propTypes = {
    companiesService: PropTypes.object.isRequired,
    filterService: PropTypes.object.isRequired,
    toTrip: PropTypes.func.isRequired,
    moveToCompany: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const { searchFilter, byQuery } = props.filterService.get();

    this.state = {
      loading: false,
      defaultMessage: searchFilter.label,
      searchValue: searchFilter.value,
      searchActive: searchFilter.isActiveBookTrip,
      list: [],
      byQuery,
    };
  }

  UNSAFE_componentWillMount() {
    const {
      filterService,
      companiesService,
    } = this.props;

    this.unsubscribeFilterServiceCb = filterService.subscribe(this.updateFilter.bind(this));
    this.unsubscribeCompaniesServiceCb = companiesService.subscribe(this.updateCompaniesList.bind(this));

    if (this.state.byQuery) {
      this.setState({ loading: true }, () => {
        const { searchValue, searchActive } = this.state;

        companiesService.searchOrders(searchValue, searchActive);
      });
    }
  }

  componentWillUnmount() {
    if (this.unsubscribeFilterServiceCb) this.unsubscribeFilterServiceCb();
    if (this.unsubscribeCompaniesServiceCb) this.unsubscribeCompaniesServiceCb();

    this.props.filterService.cancelSearchFilter();
  }

  updateFilter = () => {
    const { searchFilter } = this.props.filterService.get();
    const { companiesService } = this.props;
    const { searchValue, searchActive } = this.state;

    if (searchValue !== searchFilter.value || searchActive !== searchFilter.isActiveBookTrip) {
      companiesService.searchOrders(searchFilter.value, searchFilter.isActiveBookTrip);

      this.setState({
        searchValue: searchFilter.value,
        searchActive: searchFilter.isActiveBookTrip,
        loading: true,
      });
    }
  };

  updateCompaniesList = () => {
    const dateSort = (first, second) => {
      if (first.ChangeDate < second.ChangeDate) return 1;
      if (first.ChangeDate > second.ChangeDate) return -1;

      return 0;
    };

    this.setState({
      list: this.props.companiesService.get().sort(dateSort),
      loading: false,
    });
  };

  handleClick = (item, e) => {
    this.props.toTrip(item, e);
  };

  handleOpenCompany = (company) => {
    this.props.moveToCompany(company);
  };

  handleChangeCheckbox = (e, field, value) => {
    this.props.filterService.changeIsActiveBookTrips(value);
  };

  renderLoading = () => (
    <CircularLoaders wrapClassName='content-loader-wrap' />
  );

  renderDefaultMessage = () => (
    <div className={ styles['empty-list'] }>
      { this.state.defaultMessage }
    </div>
  );

  renderEmptyList = () => (
    <div className={ styles['empty-list'] }>
      { EMPTYLIST }
    </div>
  );

  renderCheckbox = () => (
    <div className={ `${styles.panel} ${styles['panel-checkbox']}` }>
      <div className={ styles['search-orders'] }>
        <Checkbox
          label='Только активные заказы'
          field='isActiveBookTrip'
          value={ this.state.searchActive }
          onChange={ this.handleChangeCheckbox }
        />
      </div>
    </div>
  );

  renderList = () => {
    const listHtml = this.state.list.map((itemCompany, ind) => (
      <BookTripList
        key={ ind }
        itemCompany={ itemCompany }
        onClick={ this.handleClick }
        handleOpenCompany={ this.handleOpenCompany }
      />
    ));

    return (
      <div className={ styles.panel }>
        <div className={ styles.form }>
          <div className={ styles.row }>
            <div className={ styles['col-1-16'] }>
              <label>№ поездки</label>
            </div>
            <div className={ styles['col-3-16'] }>
              <label>Компания</label>
            </div>
            <div className={ styles['col-3-4'] }>
              <div className={ styles.row }>
                <div className={ styles['col-1-10'] }>
                  <label>№ заказа</label>
                </div>
                <div className={ styles['col-1-10'] }>
                  <label>Фамилия И.О.</label>
                </div>
                <div className={ styles['col-1-3'] }>
                  <label>Направление</label>
                </div>
                <div className={ styles['col-1-7'] }>
                  <label>Даты</label>
                </div>
                <div className={ styles['col-1-5'] }>
                  <label>Сумма</label>
                </div>
                <div className={ styles['col-1-10'] }>
                  <label>Статус заказа</label>
                </div>
              </div>
            </div>
          </div>
          { listHtml }
        </div>
      </div>
    );
  };

  render() {
    const {
      loading, list, searchValue, searchActive,
    } = this.state;
    const isSearch = Boolean(searchValue);
    const result = !loading && isSearch;

    if (loading) return this.renderLoading();
    if (!loading && !list.length && !isSearch) return this.renderDefaultMessage();

    const checkboxHtml = !searchActive && result && !list.length ? null : this.renderCheckbox();

    let html = null;
    if (result && list.length) {
      html = this.renderList();
    } else if (result && !list.length) {
      html = this.renderEmptyList();
    }

    return (
      <div className={ `${styles.wrap} ${result && !list.length ? styles['wrap-empty'] : ''}` }>
        { checkboxHtml }
        { html }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <BookTripComponents
      companiesService={ opts.companiesService }
      companyService={ opts.companyService }
      tripService={ opts.tripService }
      filterService={ opts.filterService }
      toTrip={ opts.toTrip }
      moveToCompany={ opts.moveToCompany }
    />,
  );

  return root;
};

export default renderComponents;
