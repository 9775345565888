import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'sw-ui';

import { FlatButton } from '../../../../../components/button';
import Input from '../../../../../components/input';
import Suggest from '../../../../../components/Suggest';
import { UploadVoucher } from '../../custom/components/UploadVoucher';

import { getMoment } from '../../../../../bi/utils/formatDate';

import AIRPROVIDERS from '../../../../../bi/constants/arline';
import { onlyNumbers, regValidForEmd } from '../../../../../bi/constants/regExp';
import { SERVICETYPE } from '../../../../../bi/constants/serviceType';
import COMPONENTS from '../../../../../bi/constants/components';
import { ADDITIONAL_SERVICES, ADDITIONAL_SERVICES_LABELS } from '../../../../../bi/constants/additionalServices';
import { VALUE_PROPERTY } from '../../../../../bi/constants/airline';

import styles from '../../../styles/form.module.scss';

const VALIDATION = {
  SERVICE_NAME: 'Выберите наименование услуги',
  PRICE: 'Введите цену',
  EMD: 'Введите номер EMD длиной 14 символов, укажите дефис (xxx-xxxxxxxxxx)',
  FILE_VOUCHER: 'Выберите файл',
};

const LABELS = {
  CHOOSE_PROVIDER: 'Выберите провайдера',
  SERVICE_TITLE: 'Наименование услуги',
  CONFIRM: 'Добавить заказ',
  OFFICE_ID: 'OfficeId',
  SALE_POINT: 'ППР',
};

const PLACEHOLDERS = { VAT: 'Без НДС' };

const FIELDS = {
  DESCRIPTION: 'Description',
  EMD: 'emd',
  PRICE: 'price',
  COMMISSION: 'commission',
  VAT: 'vat',
  providerName: 'providerName',
  PROVIDER_INFO: { OFFICE_ID: 'ProviderInfo.OfficeId' },
};

class AddServiceFormAir extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    guidService: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    addTrip: PropTypes.func.isRequired,
    isCopy: PropTypes.bool,
  };

  static defaultProps = { isCopy: false };

  constructor(props) {
    super(props);

    this.state = this.setAddServiceAir();
  }

  setAddServiceAir = () => {
    const {
      item, item: { JsonData }, isCopy,
    } = this.props;

    const providerNameOptions = [
      ...this.props.airService.getAirProviderNamesForSelect(),
      { label: 'Оплата картой', value: 'Оплата картой' },
    ];

    if (item.isEdit) {
      return this.editState();
    }

    if (isCopy) {
      return this.copyState();
    }

    return this.defaultState(JsonData, providerNameOptions);
  };

  copyState = () => {
    const { item } = this.props;
    const { customFile } = this.props.tripService.get();

    return ({
      ...item,
      customFile,
      validation: {},
    });
  };

  editState = () => {
    const { item } = this.props;
    const { customFile } = this.props.tripService.get();

    return ({
      ...item,
      customFile,
      validation: {},
    });
  };

  defaultState = (JsonData, providerNameOptions) => {
    const { item, tripService } = this.props;
    const {
      Employees, CompanyId, ProjectId, DepartmentId, UserAnalytics, TripItemId,
    } = item;
    const json = JSON.parse(JsonData);
    const PNR = json.TicketsExtended[0].PNR;
    const TicketNumber = json.TicketsExtended[0].Num;
    const Class = json.Class;
    const ProviderInfo = json.ProviderInfo;

    return {
      Number: '',
      Mass: '',
      Description: '',
      ServiceType: SERVICETYPE.AIR_ADDITIONAL_SERVICE,
      routes: json.Routes,
      suggests: ADDITIONAL_SERVICES,
      serviceName: {
        name: '', id: 0, field: '', inputs: [],
      },
      serviceNameFieldSelected: '',
      segments: [],
      emd: '',
      placeNumber: '',
      kg: '',
      providerName: '',
      ProviderInfo,
      price: 0,
      commission: 0,
      vat: '',
      validation: {},
      Employees,
      providerNameOptions,
      customFile: tripService.get().customFile,
      PNR,
      TicketNumber,
      Class,
      CompanyId,
      ProjectId,
      DepartmentId,
      UserAnalytics,
      TripItemId,
    };
  };

  componentDidMount() {
    this.unsubscribe = this.props.tripService.subscribe(this.updateTripData);

    if (this.checkSingleRoute()) {
      this.handleChangeCheckboxValue(this.state.routes[0].Segments[0].ID, true);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateTripData = ({ customFile }) => this.setState({ customFile });

  checkSingleRoute = () => {
    const { routes } = this.state;

    return routes.length === 1 && routes[0].Segments.length === 1;
  };

  handleSendVouchers = async () => {
    const { customFile } = this.state;
    const { tripService: { uploadAirAdditionalFiles } } = this.props;

    const files = customFile.filter(({ file }) => !!file).map(({ file }) => file);

    if (!files.length) return '';

    return uploadAirAdditionalFiles(files);
  };

  handleConfirm = async () => {
    if (this.handleConfirmValidation()) {
      const {
        price,
        serviceName,
        emd,
        commission,
        vat,
        providerName,
        routes,
        suggests,
        PNR,
        TicketNumber,
        Class,
        Employees,
        CompanyId,
        ProjectId,
        DepartmentId,
        UserAnalytics,
        TripItemId,
        ProviderInfo,
      } = this.state;
      const {
        onConfirm, addTrip, item, guidService,
      } = this.props;

      const Guid = guidService.generate();
      const stateToSave = JSON.parse(JSON.stringify(this.state));
      stateToSave.Guid = Guid;

      const { fileId } = await this.handleSendVouchers();

      const segmentsIDSelected = [];

      routes.forEach((route) => {
        route.Segments.forEach((segment) => {
          if (segment.selected === true) {
            segmentsIDSelected.push(segment.ID);
          }
        });
      });

      const Service = {
        Price: price,
        Comission: commission !== '' ? commission : 0,
        Vat10: vat !== '' ? vat : 0,
      };

      suggests.forEach((suggest) => {
        if (serviceName.name === suggest.name) {
          suggest.inputs.forEach((input) => Service[input] = this.state[input]);
        }
      });

      const segments = routes.map(({ Segments }) => Segments.map((segment) => ({
        Id: segment.ID,
        Airline: {
          Code: segment.MarketingAirline.Code,
          Name: segment.MarketingAirline.Name,
        },
        ArrivalAirport: {
          Code: segment.ArrivalAirport.Code,
          Name: segment.ArrivalAirport.Name,
          City: segment.ArrivalCity,
          Country: segment.ArrivalCountry,
        },
        ArrivalDate: segment.ArrivalDate,
        DepartureAirport: {
          Code: segment.DepartureAirport.Code,
          Name: segment.DepartureAirport.Name,
          City: segment.DepartureCity,
          Country: segment.DepartureCountry,
        },
        DepartureDate: segment.DepartureDate,
      }))).flat();

      const segmentsSelected = segments.filter(({ Id }) => segmentsIDSelected.includes(Id));

      const addField = this.state[serviceName.inputs[0]];
      const employeesIds = Employees.map(({ Id }) => Id);
      const providerAmadeus = providerName === AIRPROVIDERS.AMADEUS.TYPE;

      const JsonData = {
        Type: serviceName.type,
        ReservationDate: getMoment(),
        AirTripItemId: item.OrderTripItemId,
        PNR,
        TicketNumber,
        Class,
        ProviderInfo,
        EmdNumber: emd,
        VoucherFileId: fileId,
        EmployeeIds: employeesIds,
        Segments: segmentsSelected,
        ProviderName: providerName,
        ServiceType: SERVICETYPE.AIR_ADDITIONAL_SERVICE,
        VatDetails: vat
          ? [
            {
              Rate: 10,
              Amount: vat,
            },
          ]
          : [],
      };

      if (serviceName.type === 0) {
        JsonData.SelectedSeat = { Number: addField };
      }

      if (serviceName.type === 2 || serviceName.type === 3 || serviceName.type === 4) {
        JsonData.Baggage = { Weight: addField };
      }

      if (serviceName.type === 11) {
        JsonData.OtherService = { Description: addField };
      }

      const newItem = {
        OrderTripItemId: 0,
        OrderTripId: 0,
        CompanyId: CompanyId ? parseInt(CompanyId, 10) : 0,
        ProjectId: ProjectId ? parseInt(ProjectId, 10) : 0,
        DepartmentId: ProjectId ? parseInt(DepartmentId, 10) : 0,
        Status: 0,
        ServiceType: SERVICETYPE.AIR_ADDITIONAL_SERVICE,
        UserAnalytics,
        Description: serviceName.name,
        JsonData: JSON.stringify(JsonData),
        OfficeId: providerAmadeus ? ProviderInfo.OfficeId : null,
        PriceDetails: {
          Base: price,
          commission,
        },
        TripId: TripItemId,
        Guid,
        stateToSave,
      };

      addTrip(stateToSave);
      onConfirm(newItem);
    }
  };

  handleServiceNameSelect = (value) => {
    this.handleValidationClear();

    this.setState({ serviceName: value }, () => this.validateAdditionalFields());
  };

  handleConfirmValidation = () => {
    const {
      serviceName,
      price,
      providerName,
      emd,
      customFile,
    } = this.state;

    this.setState({ validation: {} });

    let allFieldsValid = this.validateAdditionalFields();

    const checkboxSelected = this.validationCheckboxes();

    if (checkboxSelected === false) {
      this.setState({
        validation: {
          ...this.state.validation,
          routes: true,
        },
      });

      allFieldsValid = false;
    }

    if (providerName === '') {
      this.setState({
        validation: {
          ...this.state.validation,
          providerName: true,
        },
      });

      allFieldsValid = false;
    }

    if (serviceName.name === '') {
      this.setState({
        validation: {
          ...this.state.validation,
          serviceName: VALIDATION.SERVICE_NAME,
        },
      });

      allFieldsValid = false;
    }

    if (price === 0 || price.length === 0) {
      this.setState({
        validation: {
          ...this.state.validation,
          price: VALIDATION.PRICE,
        },
      });

      allFieldsValid = false;
    }

    const lengthString = emd.length > 0 && emd.length <= 14;

    if (!regValidForEmd.test(emd) || emd.includes(' ') || !lengthString) {
      this.setState({
        validation: {
          ...this.state.validation,
          emd: VALIDATION.EMD,
        },
      });

      allFieldsValid = false;
    }

    if (customFile[0].file === null) {
      this.setState({
        validation: {
          ...this.state.validation,
          uploaded: VALIDATION.FILE_VOUCHER,
        },
      });

      allFieldsValid = false;
    }

    const other = ADDITIONAL_SERVICES[ADDITIONAL_SERVICES.length - 1].name;

    if (this.state.serviceName.name === other && this.state.Description.length === 0) {
      this.setState({
        validation: {
          ...this.state.validation,
          Description: `Введите ${(ADDITIONAL_SERVICES_LABELS.Description).toLowerCase()}`,
        },
      });

      allFieldsValid = false;
    }

    return allFieldsValid;
  };

  validateAdditionalFields = () => {
    const { suggests } = this.state;

    let valid = true;

    const validationFieldsArray = suggests.reduce((array, suggest) => {
      suggest.inputs.forEach((input) => array.push({ name: suggest.name, value: input }));

      return array;
    }, []);

    validationFieldsArray.forEach((validationFields) => {
      if (this.validationField(validationFields.name, validationFields.value)) {
        valid = false;
      }
    });

    return valid;
  };

  validationField = (additionalServiceName, field) => {
    const { serviceName } = this.state;

    if (serviceName.name === additionalServiceName && this.state[field].length === 0) {
      this.setState((state) => {
        state.validation[field] = `Введите ${(ADDITIONAL_SERVICES_LABELS[field]).toLowerCase()}`;
      });

      return true;
    }

    return false;
  };

  validationCheckboxes = () => {
    const { routes } = this.state;

    let checkboxSelected = false;

    routes.forEach((route) => {
      route.Segments.forEach((segment) => {
        if (segment.selected === true) {
          checkboxSelected = true;
        }
      });
    });

    return checkboxSelected;
  };


  validationInput = (e, field, label) => {
    if (this.state[field].length === 0) {
      return this.setState({
        validation: {
          ...this.state.validation,
          [field]: `Введите ${label.toLowerCase()}`,
        },
      });
    }

    return this.setState({
      validation: {
        ...this.state.validation,
        [field]: '',
      },
    });
  };

  handleValidationClear() {
    this.setState({ validation: {} });
  }

  handleChangeInput = (e, field, value) => {
    let newValue = value;
    if (typeof value === 'number' && value < 0) {
      newValue = Math.abs(value);
    }

    this.setState({ [field]: newValue });
  };

  handleChangeInputAdditionalField = (e, field, value) => {
    if (field === 'Mass' && value.length && !onlyNumbers.test(value)) return;

    this.handleChangeInput(e, field, value);
  };

  handleChangeCheckboxValue = (id, value) => {
    this.setState((prevState) => {
      const state = { ...prevState };

      const routes = state.routes.map((route) => {
        route.Segments.forEach((segment) => {
          if (segment.ID === id) {
            segment.selected = value;
          }
        });

        return route;
      });

      return { routes };
    });
  };


  handleSelectProviderName = (e) => {
    this.handleChangeInput(null, FIELDS.providerName, e.target.value);
  };

  selectProvideNameHtml() {
    const { providerName, providerNameOptions } = this.state;

    return (
      <select
        value={ providerName }
        onChange={ this.handleSelectProviderName }
      >
        <option key='' value='' disabled>{ LABELS.CHOOSE_PROVIDER }</option>
        { providerNameOptions.map((options, providerKey) => (
          <option key={ `${providerKey}_name` } value={ options.value }>{ options.label }</option>
        )) }
      </select>
    );
  }

  renderPropertySelector = (type, label, valueKey) => {
    const { providerName, ProviderInfo } = this.state;

    if (providerName !== type || !ProviderInfo[valueKey]) {
      return null;
    }

    const value = ProviderInfo[valueKey];

    return (
      <div>
        <label>{ label }</label>
        <div className={ `${styles.wrap}` }>
          <select
            value={ value }
            disabled
          >
            <option
              key={ `${value}_name` }
              value={ value }
            >
              { value }
            </option>
          </select>
        </div>
      </div>
    );
  };

  renderOfficeIdSelector = () => this.renderPropertySelector(
    AIRPROVIDERS.AMADEUS.TYPE,
    LABELS.OFFICE_ID,
    VALUE_PROPERTY.OFFICE_ID,
  );

  renderSalePointSelector = () => this.renderPropertySelector(
    AIRPROVIDERS.MIXVEL.TYPE,
    LABELS.SALE_POINT,
    VALUE_PROPERTY.SALE_POINT,
  );

  validProviderNameHtml() {
    const { validation } = this.state;

    if (!validation.providerName) return null;

    return <span className={ `${styles['error-msg-company']} error-msg` }>{ LABELS.CHOOSE_PROVIDER }</span>;
  }

  validRoutesHtml() {
    const { validation } = this.state;

    if (!validation.routes) return null;

    return <span className={ `${styles['error-msg-company']} error-msg` }>Выберите хотя бы 1 сегмент</span>;
  }

  onSelectEmployee = (employee) => {
    this.setState({ selectedEmployee: employee });
  };

  additionalInputsHtml() {
    const { serviceName, validation } = this.state;

    const other = ADDITIONAL_SERVICES[ADDITIONAL_SERVICES.length - 1].name;

    if (serviceName.name === other) return null;

    if (serviceName.inputs && serviceName.inputs.length === 1) {
      return serviceName.inputs.map((input, index) => (
        <div key={ index } className={ styles['col-1-4'] }>
          <Input
            value={ this.state[input] }
            label={ ADDITIONAL_SERVICES_LABELS[input] }
            field={ input }
            onChange={ this.handleChangeInputAdditionalField }
            onBlur={ this.validationInput }
            valid={ validation[input] }
          />
        </div>
      ));
    }

    if (serviceName.inputs && serviceName.inputs.length === 2) {
      return (
        <div className={ styles['col-1-2'] }>
          <div className={ `${styles.row}` }>
            { serviceName.inputs.map((input, index) => (
              <div key={ index } className={ styles['col-1-2'] }>
                <Input
                  value={ this.state[input] }
                  label={ ADDITIONAL_SERVICES_LABELS[input] }
                  field={ input }
                  onChange={ this.handleChangeInput }
                  onBlur={ this.validationInput }
                  valid={ validation[input] }
                />
              </div>
            )) }
          </div>
        </div>
      );
    }

    return null;
  }

  renderServiceName() {
    const {
      suggests, serviceName, validation,
    } = this.state;
    const other = ADDITIONAL_SERVICES[ADDITIONAL_SERVICES.length - 1].name;

    return (
      <div className={ `${styles['col-1-4']}` }>
        { serviceName.name !== other
          ? (
            <Suggest
              title={ LABELS.SERVICE_TITLE }
              valid={ validation.serviceName }
              currentLabel={ serviceName.name }
              suggests={ suggests }
              onSelect={ this.handleServiceNameSelect }
            />
          )
          : (
            <Input
              value={ this.state.Description }
              label={ ADDITIONAL_SERVICES_LABELS.Description }
              field={ FIELDS.DESCRIPTION }
              onChange={ this.handleChangeInput }
              onBlur={ this.validationInput }
              valid={ validation.Description }
            />
          ) }
      </div>
    );
  }

  handleAddNewVoucher = () => {
    const { tripService: { addNewVoucher } } = this.props;

    addNewVoucher();
  };

  uploadFile = (file, ind) => {
    const { tripService: { uploadCustomFile } } = this.props;

    uploadCustomFile(file, ind);
  };

  renderUploadVoucher = () => {
    const { customFile, validation } = this.state;
    const { tripService: { resetCustomFile } } = this.props;

    return customFile.map((file, ind) => {
      const buttonAddVoucherHtml = file.file && ind === customFile.length - 1 && !file.guid
        ? (
          <div className={ styles['col-1-4'] }>
            <FlatButton
              label={ LABELS.ADD_NEW_VOUCHER }
              onClick={ this.handleAddNewVoucher }
            />
          </div>
        )
        : null;

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-2'] }>
            <UploadVoucher
              uploadFile={ (item) => this.uploadFile(item, ind) }
              customFile={ file }
              resetCustomFile={ () => resetCustomFile(ind) }
              valid={ validation.uploaded }
            />
          </div>
          { buttonAddVoucherHtml }
        </div>
      );
    });
  };

  renderSegments() {
    const { routes } = this.state;

    if (routes.length && this.checkSingleRoute()) return null;

    return (
      routes.map((route, index) => (
        <div key={ index } className={ `${styles['col-1-3']} ${styles.segments}` }>
          <div className={ styles.row }>
            Маршрут №
            { index + 1 }
          </div>
          { route.Segments.map((segment, indexSegment) => (
            <div className={ styles.row } key={ indexSegment }>
              <Checkbox
                onChange={ (value) => this.handleChangeCheckboxValue(segment.ID, value) }
                value={ segment.selected }
              >
                { segment.DepartureCity }
                { ' ' }
                -
                { segment.ArrivalCity }
              </Checkbox>
            </div>
          )) }
        </div>
      ))
    );
  }

  render() {
    const {
      emd, commission, vat, price, validation,
    } = this.state;

    const providerNameValidation = validation.providerName ? styles['no-valid'] : '';

    return (
      <div className={ styles.wrap }>
        <div className={ styles.form }>
          <div className={ `${styles.row}` }>
            <h4>Данные ваучера</h4>
            <div className={ styles.row }>
              { this.renderServiceName() }
              { this.additionalInputsHtml() }
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles['col-1-5'] }>
              <div className={ styles.row }>
                <Input
                  value={ emd }
                  field={ FIELDS.EMD }
                  label={ ADDITIONAL_SERVICES_LABELS.emd }
                  onChange={ this.handleChangeInput }
                  valid={ validation.emd }
                />
              </div>
            </div>
            <div className={ styles['col-4-5'] }>
              <div className={ `${styles.row}` } />
              { this.renderSegments() }
              { this.validRoutesHtml() }
            </div>
          </div>
          <div className={ `${styles.row} ${styles.panel}` }>
            <div className={ `${styles.select} ${styles.select__AddService} ${providerNameValidation}` }>
              <label>Провайдер</label>
              <div className={ `${styles.wrap} ${providerNameValidation}` }>
                { this.selectProvideNameHtml() }
                { this.validProviderNameHtml() }
              </div>
            </div>
          </div>
          <div className={ `${styles.row}` }>
            <div className={ `${styles.select} ${styles.select__AddService} ${providerNameValidation}` }>
              { this.renderOfficeIdSelector() }
              { this.renderSalePointSelector() }
            </div>
          </div>
          <div className={ `${styles.row} ${styles.panel}` }>
            <h4>Стоимость</h4>
            <div className={ styles.row }>
              <div className={ `${styles['col-1-3']}` }>
                <Input
                  field={ FIELDS.PRICE }
                  value={ price }
                  type={ COMPONENTS.INPUT.TYPE.NUMBER }
                  label={ ADDITIONAL_SERVICES_LABELS.price }
                  onChange={ this.handleChangeInput }
                  onBlur={ this.validationInput }
                  valid={ validation.price }
                />
              </div>
              <div className={ `${styles['col-1-3']}` }>
                <Input
                  field={ FIELDS.COMMISSION }
                  value={ commission }
                  label={ ADDITIONAL_SERVICES_LABELS.commission }
                  onChange={ this.handleChangeInput }
                  type={ COMPONENTS.INPUT.TYPE.NUMBER }
                />
              </div>
              <div className={ `${styles['col-1-3']}` }>
                <Input
                  field={ FIELDS.VAT }
                  value={ vat }
                  label={ ADDITIONAL_SERVICES_LABELS.vat }
                  onChange={ this.handleChangeInput }
                  placeholder={ PLACEHOLDERS.VAT }
                  type={ COMPONENTS.INPUT.TYPE.NUMBER }
                />
              </div>
            </div>
          </div>
          { this.renderUploadVoucher() }
          <div className={ styles.row }>
            <div className={ styles['col-1-3'] }>
              <FlatButton
                onClick={ this.handleConfirm }
                label={ LABELS.CONFIRM }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { AddServiceFormAir };
