import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import { PRICEDETAILS, PRICELABELS } from '../../../../../bi/constants/trips';
import COMPONENTS from '../../../../../bi/constants/components';

import Input from '../../../../../components/input';

import styles from '../../../styles/form.module.scss';

const LABELS = { TAXES: 'Если по билету берется одновременно валютный сбор и сбор за групповое бронирование, то в данное поле необходимо записать сумму этих двух сборов' };

const Price = ({
  isEditing,
  isCancelled,
  priceDetails,
  validation,
  onChangeInput,
  onBlurInput,
  showTaxes,
  showTaxesClue,
}) => {
  const {
    Base, Commission, Fee, Taxes, Tax, TaxesClue,
  } = priceDetails;

  const colStyle = styles['col-1-3'];

  const taxesLabel = showTaxes
    ? PRICELABELS.TAXES_SMARTWAY
    : PRICELABELS.TAXES_SMARTWAY_GB;

  const clueHtml = showTaxesClue && TaxesClue
    ? PRICELABELS.TAXES_SMARTWAY_CLUE(TaxesClue)
    : '';

  const renderTooltip = () => {
    if (!showTaxes || isEditing) return null;

    return (
      <Tooltip className={ styles.tooltip } position='right'>
        <span>{ LABELS.TAXES }</span>
      </Tooltip>
    );
  };

  const handleCalculateTaxes = (value) => {
    const taxValue = (value * 0.01).toFixed(2);

    onChangeInput(null, PRICEDETAILS.TAXES_CLUE, taxValue);
  };

  const handleChangeBase = (e, inputField, value) => {
    onChangeInput(e, inputField, value);

    handleCalculateTaxes(value + Tax);
  };

  const handleChangeTax = (e, inputField, value) => {
    onChangeInput(e, inputField, value);

    handleCalculateTaxes(Base + value);
  };

  return (
    <div className={ `${isCancelled ? styles.col : styles.row}` }>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.BASE }
          value={ Base }
          label={ PRICELABELS.AVIABASE }
          onChange={ handleChangeBase }
          onBlur={ onBlurInput }
          valid={ validation ? validation.PriceDetails.Base : '' }
        />
      </div>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.COMMISSION }
          value={ Commission }
          label={ PRICELABELS.COMMISSION }
          onChange={ onChangeInput }
        />
      </div>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.FEE }
          value={ Fee }
          label={ PRICELABELS.AVIAFEE }
          onChange={ onChangeInput }
        />
      </div>
      <div className={ colStyle }>
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.TAX }
          value={ Tax }
          label={ PRICELABELS.AVIATAX }
          onChange={ handleChangeTax }
        />
      </div>
      <div className={ `${colStyle} sw-tooltip-wrapper` }>
        { renderTooltip() }
        <Input
          type={ COMPONENTS.INPUT.TYPE.NUMBER }
          disabled={ isEditing }
          field={ PRICEDETAILS.TAXES }
          value={ Taxes }
          label={ taxesLabel }
          onChange={ onChangeInput }
          clue={ clueHtml }
        />
      </div>
    </div>
  );
};

Price.propTypes = {
  isEditing: PropTypes.bool,
  isCancelled: PropTypes.bool,
  priceDetails: PropTypes.object.isRequired,
  validation: PropTypes.object,
  onChangeInput: PropTypes.func.isRequired,
  onBlurInput: PropTypes.func,
  showTaxes: PropTypes.bool,
  showTaxesClue: PropTypes.bool,
};

Price.defaultProps = {
  isEditing: false,
  isCancelled: false,
  showTaxes: false,
  showTaxesClue: false,
  validation: null,
  onBlurInput: () => {},
};

export default Price;
