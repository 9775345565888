import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datetime';

import Input from '../../../../components/input';
import { AutocompleteHotel } from './components/AutocomleteHotel';
import { Providers } from './components/Providers';

import {
  TRIPSLABELS, FIELDS, VOUCHER_TEXT,
} from '../../../../bi/constants/trips';
import { HOTEL_PROVIDER_VALUE, PROVIDERS_AVAILABLE_INN } from '../../../../bi/constants/hotel';
import {
  DEFAULTDATE, DEFAULTTIME, LOCALERU,
} from '../../../../constants/time';

import styles from '../../styles/form.module.scss';

const CommonFields = ({
  isCreateOrder = false,
  isItemCart,
  isNewTrip,
  groupHotels: {
    validation,
    JsonData: data,
  },
  groupHotels,
  funcProps: {
    onChangeDate,
    onChangeNumberInput,
    onChangeJsonInput,
    onValidateDate,
    onValidateInn,
  },
  funcProps,
  tripService,
}) => {
  const { ProviderName } = data;

  const disabledFieldStyles = !isNewTrip ? styles['disabled-field'] : '';

  const handleChangeProviderName = (field, value) => {
    const { rates } = groupHotels;

    if (!rates && value === HOTEL_PROVIDER_VALUE.travelline) {
      tripService.getTravellineRates();
    }

    onChangeJsonInput(field, value);
    tripService.resetHotelProviderData();
  };

  const renderINN = () => {
    const { ProviderInn } = groupHotels;
    const { ProviderInn: validationInn } = validation;

    if (!PROVIDERS_AVAILABLE_INN.includes(ProviderName)) return null;

    return (
      <div className={ styles['col-1-6'] }>
        <Input
          field={ FIELDS.PROVIDER_INN }
          value={ ProviderInn }
          label={ TRIPSLABELS.INN }
          onChange={ ({ target: { value } }) => onChangeNumberInput(FIELDS.PROVIDER_INN, value) }
          onBlur={ () => onValidateInn(FIELDS.PROVIDER_INN) }
          valid={ validationInn }
        />
      </div>
    );
  };

  const renderReservationDate = () => {
    const { ReservationDate } = data;
    const { ReservationDate: validationReservationDate } = validation;

    const isValidate = !isItemCart && validationReservationDate;
    const validationHtml = isValidate && <span className='error-msg'>{ validationReservationDate }</span>;

    const datePickerHtml = () => (
      <div className={ styles['date-col'] }>
        <DatePicker
          dateFormat={ DEFAULTDATE }
          timeFormat={ DEFAULTTIME }
          locale={ LOCALERU }
          value={ ReservationDate }
          onChange={ (value) => onChangeDate(FIELDS.RESERVATIONDATE, value) }
          onBlur={ () => (!isItemCart ? onValidateDate(FIELDS.RESERVATIONDATE) : () => {}) }
          className={ `componentHook ${isValidate ? 'no-valid' : ''} ${disabledFieldStyles}` }
        />
        <label>{ TRIPSLABELS.RESERVATION_DATE }</label>
        { validationHtml }
      </div>
    );

    if (isCreateOrder) {
      return (
        <div className={ `${styles.row} ${styles['date-wrap']}` }>
          { datePickerHtml() }
          <div className={ `${styles.row} ${styles['date-link']}` }>
            <div className={ styles.link } onClick={ () => onChangeDate(FIELDS.RESERVATIONTODAY) }>
              { TRIPSLABELS.TODAY }
            </div>
            <div className={ styles.link } onClick={ () => onChangeDate(FIELDS.RESERVATIONYESTERDAY) }>
              { TRIPSLABELS.YESTERDAY }
            </div>
          </div>
        </div>
      );
    }

    return datePickerHtml();
  };

  const funcPropsObj = {
    ...funcProps,
    onChangeProviderName: handleChangeProviderName,
  };

  return (
    <div>
      <h4>{ VOUCHER_TEXT.TITLE }</h4>
      <div className={ styles.row }>
        <Providers
          onChangeProviderName={ handleChangeProviderName }
          providerName={ ProviderName }
          tripService={ tripService }
        />
        { renderINN() }
        <div className={ styles['col-1-3'] }>
          { renderReservationDate() }
        </div>
      </div>
      <AutocompleteHotel
        funcProps={ funcPropsObj }
        disabledFieldStyles={ disabledFieldStyles }
        groupHotels={ groupHotels }
        tripService={ tripService }
      />
    </div>
  );
};

CommonFields.propTypes = {
  funcProps: PropTypes.object.isRequired,
  isCreateOrder: PropTypes.bool.isRequired,
  isNewTrip: PropTypes.bool.isRequired,
  isItemCart: PropTypes.bool.isRequired,
  groupHotels: PropTypes.object.isRequired,
  tripService: PropTypes.object.isRequired,
};

export { CommonFields };
