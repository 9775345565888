import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../components/button';

import styles from '../../styles/buttons.module.css';

const LABELS = { CANCEL: 'Отменить' };

const CancelButton = ({ onGoBack }) => (
  <div className={ styles.back_button }>
    <FlatButton onClick={ onGoBack }>
      { LABELS.CANCEL }
    </FlatButton>
  </div>
);

CancelButton.propTypes = { onGoBack: PropTypes.func.isRequired };

export { CancelButton };
