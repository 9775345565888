import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import MESSAGE from '../../bi/constants/noResult';

import styles from './styles/index.module.css';
// Пока сырой компонентик, но пусть будет для дальнейшего использования и изменения
const NoResult = ({ message, onClose }) => (
  <Dialog showCloseButton onClick={ onClose } width='400px'>
    <div className={ styles.wrap }>{ message }</div>
  </Dialog>
);

NoResult.propTypes = {
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

NoResult.defaultProps = { message: MESSAGE.DEFAULT };

export default NoResult;
