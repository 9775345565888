import React from 'react';
import PropTypes from 'prop-types';

import {
  Checkbox, Dialog, Loading,
} from 'sw-ui';

import { LABEL_SETTINGS_FIELDS, SETTINGS_FIELD } from '../../../../../bi/constants/company';

import DecisionButtons from '../../../../../components/DecisionButtons';

import styles from '../../../styles/company.module.scss';

const LABELS = {
  TITLE: (companyName) => `Настройки компании ${companyName}`,
  EMPTY_SETTINGS: ' Настройки компании времено недоступны',
  SAVE: 'Сохранить',
};

const DIIALOG_WIDTH = '600px';

const CompanySettingsDialog = ({
  settings, loading, details, accessSettingsChange, onChange, onClose, onSave,
}) => {
  const { CompanyName, ShortCompanyName } = details;
  const companyName = ShortCompanyName || CompanyName;

  const settingsHtml = () => {
    if (loading) {
      return (
        <div className={ styles.company_loading }>
          <Loading />
        </div>
      );
    }

    const renderSettings = ([name, value], index) => {
      const valueCheck = name === SETTINGS_FIELD.IS_SHOW_REPORTING ? !value : value;

      return LABEL_SETTINGS_FIELDS[name] && (
        <div className={ styles.company_settings_wrap } key={ index + name }>
          <div>
            <Checkbox
              onChange={ () => onChange(name) }
              disabled={ !accessSettingsChange }
              value={ valueCheck }
            />
          </div>
          <div className={ styles.company_settings_name }>{ LABEL_SETTINGS_FIELDS[name] }</div>
        </div>
      );
    };

    const mappertSettings = (value) => Object.entries(value);
    const checkItem = (item) => mappertSettings(item).map((el, index) => renderSettings(el, index));
    const checkSettings = Object.keys(settings).length
      ? checkItem(settings)
      : LABELS.EMPTY_SETTINGS;

    return (
      <div className={ styles.dialog_company_settings }>
        <div className={ styles.account_company_header }>
          <h3>{ LABELS.ACCOUNT_SETTINGS }</h3>
        </div>
        { checkSettings }
        <DecisionButtons
          loadingIncluded
          className={ styles.company_settings_dialog_buttons }
          onSave={ () => onSave() }
          onCancel={ () => onClose(false) }
          labelSave={ LABELS.SAVE }
        />
      </div>
    );
  };

  return (
    <Dialog
      onClick={ () => onClose(false) }
      width={ DIIALOG_WIDTH }
      style={ { padding: '20px 0 0 0', 'margin-bottom': '80px' } }
    >
      <div className={ styles.company_settings }>
        <div className={ styles.company_settings_title }>
          { LABELS.TITLE(companyName) }
        </div>
        { settingsHtml() }
      </div>
    </Dialog>
  );
};

CompanySettingsDialog.propTypes = {
  settings: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  accessSettingsChange: PropTypes.bool.isRequired,
  details: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default CompanySettingsDialog;
