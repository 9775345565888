import React, { Component } from 'react';
import PropTypes from 'prop-types';

import decline from '../../bi/utils/decline';
import styles from './styles/employees.module.scss';
import { ACCOUNTTEXT, COMPANIESLIST } from '../../bi/constants/account';
import LinkToNewTab from '../../components/LinkToNewTab';

const COUNTNOTHIDDENCOMPANIES = 4;

class EmployeeItem extends Component {
  static propTypes = { item: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    this.state = { toggleCompanies: false };
  }

  toggleOtherCompanies = () => this.setState({ toggleCompanies: !this.state.toggleCompanies });

  renderToggleAllView = (companiesValue) => {
    const { toggleCompanies } = this.state;

    const text = !toggleCompanies
      ? `Ещё ${companiesValue} ${decline(companiesValue, COMPANIESLIST)} в аккаунте`
      : ACCOUNTTEXT.HIDECOMPANY;

    return (
      <div
        className={ styles.others }
        onClick={ this.toggleOtherCompanies }
      >
        <div className={ styles.text }>{ text }</div>
        <span className={ `arrow ${styles.arrow} ${toggleCompanies ? styles.open : ''}` } />
      </div>
    );
  };

  renderCompanies = () => {
    const { item: { Companies } } = this.props;
    const { toggleCompanies } = this.state;

    let list = [];

    if (Companies.length <= COUNTNOTHIDDENCOMPANIES || toggleCompanies) {
      list = Companies;
    } else {
      list = Companies.slice(0, COUNTNOTHIDDENCOMPANIES);
    }

    return list.map(({
      CompanyId, CompanyName, ShortCompanyName,
    }) => (
      <div
        key={ `${CompanyId}` }
        className={ styles.list }
      >
        { ShortCompanyName || CompanyName }
        { ' ' }
        (
        { CompanyId }
        )
      </div>
    ));
  };

  render() {
    const {
      item: {
        Name, Surname, Patronymic, Id, AccountName, AccountId, Companies,
      },
    } = this.props;
    const companiesValue = Companies.length > COUNTNOTHIDDENCOMPANIES ? Companies.length - COUNTNOTHIDDENCOMPANIES : 0;
    const othersCompaniesHtml = companiesValue ? this.renderToggleAllView(companiesValue) : null;
    const companiesHtml = this.renderCompanies();
    const nameHtml = AccountName
      || (
        <span className={ styles.nodicated } >
          { ' ' }
          { ACCOUNTTEXT.NOINDICATED }
          { ' ' }
        </span>
      );

    return (
      <LinkToNewTab to={ `/employeePage/${Id}` }>
        <div className={ styles.content }>
          <div
            className={ styles.account }
          >
            <div className={ styles.header }>
              { Name }
              { ' ' }
              { Surname }
              { ' ' }
              { Patronymic }
            </div>
            <div className={ styles.details }>
              { nameHtml }
              { ' ' }
              (
              { AccountId }
              )
            </div>
          </div>
          { companiesHtml }
          <div>
            { othersCompaniesHtml }
          </div>
        </div>
      </LinkToNewTab>
    );
  }
}

export default EmployeeItem;
