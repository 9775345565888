import React from 'react';
import PropTypes from 'prop-types';

import styles from '../../styles/account.module.scss';

const RenderField = ({
  field,
  value,
}) => (
  <div className={ styles.row }>
    <div className={ styles['col-1-4'] }>
      <label>
        { field }
      </label>
    </div>
    <div className={ styles['col-3-4'] }>
      <span>
        { value }
      </span>
    </div>
  </div>
);

RenderField.propTypes = {
  field: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

RenderField.defaultProps = { value: '' };

export default RenderField;
