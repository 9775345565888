import moment from 'moment';

const requiredField = (value) => Boolean(value.length);

const requiredNumber = (value) => value;

const requiredDate = (value) => moment.isMoment(value) && value.isValid();

const validationObj = {
  CreatedDate: {
    fn: requiredDate,
    msg: 'Введите дату',
  },
  Amount: {
    fn: requiredNumber,
    msg: 'Введите сумму',
  },
  Description: {
    fn: requiredField,
    msg: 'Введите описание',
  },
};

class TransactionValidator {
  isValid(field, value) {
    const validator = validationObj[field];
    const validationFn = validator.fn;
    let result = '';

    if (!validationFn(value)) {
      result = validator.msg;
    }

    return result;
  }
}

export default TransactionValidator;
