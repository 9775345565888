import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, Select,
} from 'sw-ui';
import DatePicker from 'react-datetime';

import DecisionButtons from '../../../../../components/DecisionButtons';

import { DEFAULTDATE, LOCALERU } from '../../../../../constants/time';
import { DECISIONBUTTONS } from '../../../../../bi/constants/decisionButtons';
import { SALE_TYPES, SALE_TYPES_ARRAY } from '../../../../../bi/constants/account';
import COMPONENTS from '../../../../../bi/constants/components';

import {
  momentObject, isMoment, momentSetHoursAndMinutes,
} from '../../../../../bi/utils/formatDate';
import { getCurrentYear } from '../../../../../utils/date';

import styles from '../styles/dialog.module.css';
import stylesForm from '../../../../trip/styles/form.module.scss';

const LABELS = {
  TITLE: 'Укажите дату открытия юридического лица и тип продажи',
  PERSON: 'Юрлицо',
  DATE: 'Дата открытия',
  SALE_TYPE: 'Тип продажи',
  DELETE: 'Удалить',
};

const DIALOG_WIDTH = '950px';

const FIELDS = {
  CREATED_DATE: 'CreatedDate',
  SELLING_TYPE: 'SellingType',
};

class ExtendedInfoDialog extends Component {
  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    getInfo: PropTypes.func.isRequired,
  };

  state = { extendedInfo: [] };

  componentDidMount() {
    const { getInfo } = this.props;

    getInfo().then((extendedInfo) => {
      const extendedInfoWithMoments = extendedInfo.map((info) => {
        const newInfo = info;

        newInfo.CreatedDate = newInfo.CreatedDate ? momentObject(info.CreatedDate) : null;

        return newInfo;
      });

      this.setState({ extendedInfo: extendedInfoWithMoments });
    });
  }

  handleChangeField = (companyId, field, value) => {
    const { extendedInfo } = this.state;

    const newExtendedInfo = extendedInfo.map((info) => {
      const changedInfo = info;

      if (info.CompanyId === companyId) {
        changedInfo[field] = value;
      }

      const changedInfoYear = changedInfo.CreatedDate;

      // Так как нужна только дата, решил так проблему с часовыми поясами
      if (changedInfoYear && isMoment(changedInfoYear)) {
        changedInfo.CreatedDate = momentSetHoursAndMinutes(changedInfoYear, 15, 0);
      }

      if (changedInfoYear && changedInfoYear.year() !== getCurrentYear()
        && changedInfo.SellingType === SALE_TYPES.NEW_SALE) {
        changedInfo.SellingType = SALE_TYPES.OLD_SALE;
      }

      return changedInfo;
    });

    this.setState({ info: newExtendedInfo });
  };

  handleClearDateAndType = (companyId) => {
    const { extendedInfo } = this.state;

    const newExtendedInfo = extendedInfo.map((info) => {
      const changedInfo = info;

      if (info.CompanyId === companyId) {
        changedInfo.CreatedDate = null;
        changedInfo.SellingType = null;
      }

      return changedInfo;
    });

    this.setState({ info: newExtendedInfo });
  };

  handleConfirm = () => {
    const { onSave, closeDialog } = this.props;
    const { extendedInfo } = this.state;

    onSave(extendedInfo).then(() => closeDialog());
  };

  renderRows = () => {
    const { extendedInfo } = this.state;

    return extendedInfo.map((info) => this.renderRow(info));
  };

  renderRow = (info) => {
    const {
      CompanyId, CompanyShortName, CreatedDate, SellingType,
    } = info;

    return (
      <div key={ CompanyId } className={ styles.wrap }>
        <div className={ stylesForm.row }>
          <div className={ stylesForm['col-1-4'] }>
            <div className={ styles.person_wrap }>
              <div className={ styles.person_text }>
                { ' ' }
                { CompanyShortName }
              </div>
            </div>
          </div>
          <div className={ stylesForm['col-1-4'] }>
            <DatePicker
              dateFormat={ DEFAULTDATE }
              timeFormat={ false }
              locale={ LOCALERU }
              value={ CreatedDate }
              onChange={ (value) => this.handleChangeField(CompanyId, FIELDS.CREATED_DATE, value) }
              className={ styles['datepicker-no-padding'] }
            />
          </div>
          <div className={ stylesForm['col-1-4'] }>
            <Select
              items={ SALE_TYPES_ARRAY }
              value={ SellingType }
              theme={ COMPONENTS.SELECT.THEME.BORDER }
              onChange={ (type) => this.handleChangeField(CompanyId, FIELDS.SELLING_TYPE, type.value) }
            />
          </div>
          <div className={ stylesForm['col-1-4'] }>
            <Button
              label={ LABELS.DELETE }
              theme={ COMPONENTS.BUTTON.THEME.FLAT }
              onClick={ () => this.handleClearDateAndType(CompanyId) }
              className={ styles['button-full-width'] }
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { closeDialog } = this.props;

    return (
      <Dialog onClick={ closeDialog } width={ DIALOG_WIDTH }>
        <div className={ styles.dialog }>
          <div className={ `${stylesForm.row} ${styles['title-center']}` }>
            { LABELS.TITLE }
          </div>
          <div className={ styles.wrap }>
            <div className={ stylesForm.row }>
              <label className={ stylesForm['col-1-4'] }>
                { LABELS.PERSON }
              </label>
              <label className={ stylesForm['col-1-4'] }>
                { LABELS.DATE }
              </label>
              <label className={ stylesForm['col-1-4'] }>
                { LABELS.SALE_TYPE }
              </label>
            </div>
          </div>
          { this.renderRows() }
          <div className={ styles.actions }>
            <DecisionButtons
              onSave={ this.handleConfirm }
              onCancel={ closeDialog }
              labelSave={ DECISIONBUTTONS.LABELS.SAVE }
            />
          </div>
        </div>
      </Dialog>
    );
  }
}

export { ExtendedInfoDialog };
