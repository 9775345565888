import React from 'react';
import PropTypes from 'prop-types';
import { Input, Loading } from 'sw-ui';

import { FoundTrip } from './FoundTrip';

import { LABELS } from './index';
import { LOADING_STATUS } from '../../../../../bi/stores/trip';

import styles from './styles.module.css';

export const InputId = ({
  setTripId, tripId, fTripProps, loading,
}) => {
  switch (loading) {
    case LOADING_STATUS.NEVER:
      return (
        <React.Fragment>
          <div className={ styles.title }>
            { LABELS.TITLE }
          </div>
          <div className={ styles.search }>
            <div>
              { LABELS.DESCRIPTION }
            </div>
            <Input placeholder={ LABELS.INPUT } onChange={ setTripId } value={ tripId } />
          </div>
          <FoundTrip { ...fTripProps } />
        </React.Fragment>
      );
    case LOADING_STATUS.ERROR:
      return LABELS.MERGE_ERROR;
    case LOADING_STATUS.LOADED:
      return LABELS.MERGE_SUCCESS;
    case LOADING_STATUS.LOADING:
    default:
      return <Loading size='small' />;
  }
};

InputId.propTypes = {
  setTripId: PropTypes.func.isRequired,
  tripId: PropTypes.string.isRequired,
  loading: PropTypes.string.isRequired,
  fTripProps: PropTypes.object.isRequired,
  // fTripProps: PropTypes.shape(...FoundTrip.propTypes).isRequired,
};
