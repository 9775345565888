import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Button, Input,
} from 'sw-ui';

import { FlatButton } from '../../../button';

import { SERVICETYPE } from '../../../../bi/constants/serviceType';

import styles from '../../styles/index.module.css';

const LABELS = {
  INPUT_PLACEHOLDER: 'id услуги в поездке',
  TAXI: {
    TITLE: 'Оформить отмену ваучера',
    CONFIRM_BUTTON: 'Отменить ваучер',
  },
  VIP_HALL: {
    TITLE: 'Оформить отмену бронирования',
    CONFIRM_BUTTON: 'Отменить бронирование',
  },
};

const DIALOGS_WIDTH = '400px';

const RefundDialog = ({
  isRefundDialog,
  cancelVoucherInputValue,
  setRefundDialog,
  setCancelVoucherInputValue,
  getRefundVoucher,
  serviceType,
}) => {
  if (!isRefundDialog) return null;

  const isVipHall = serviceType === SERVICETYPE.VIP_HALL;

  const handleCloseCancelDialog = () => {
    setRefundDialog(false);
    setCancelVoucherInputValue('');
  };

  const handleRefundButton = () => getRefundVoucher(cancelVoucherInputValue, serviceType);

  const handleChangeInput = (value) => setCancelVoucherInputValue(value);

  const confirm = isVipHall
    ? LABELS.VIP_HALL.CONFIRM_BUTTON
    : LABELS.TAXI.CONFIRM_BUTTON;

  const title = isVipHall
    ? LABELS.VIP_HALL.TITLE
    : LABELS.TAXI.TITLE;

  return (
    <Dialog
      width={ DIALOGS_WIDTH }
      onClick={ handleCloseCancelDialog }
    >
      <div className={ styles.dialog }>
        <h3>
          { title }
        </h3>
        <Input
          className={ styles.input }
          placeholder={ LABELS.INPUT_PLACEHOLDER }
          value={ cancelVoucherInputValue }
          onChange={ (value) => handleChangeInput(value) }
        />
        <div className={ styles.buttons_row }>
          <Button
            theme='red'
            disabled={ !cancelVoucherInputValue }
            onClick={ handleRefundButton }
          >
            { confirm }
          </Button>
          <div>
            <FlatButton
              onClick={ handleCloseCancelDialog }
            >
              { LABELS.CANCEL_BUTTON }
            </FlatButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

RefundDialog.propTypes = {
  isRefundDialog: PropTypes.bool.isRequired,
  cancelVoucherInputValue: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
  setRefundDialog: PropTypes.func.isRequired,
  setCancelVoucherInputValue: PropTypes.func.isRequired,
  getRefundVoucher: PropTypes.func.isRequired,
};

export { RefundDialog };

