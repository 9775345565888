import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from 'sw-ui';

import COMPONENTS from '../../../bi/constants/components';
import { TRIPSLABELS } from '../../../bi/constants/trips';

import Input from '../../../components/input';
import TextArea from '../../../components/textarea';

import styles from '../styles/penalty.module.css';

const LABELS = {
  DESCRIPTION: 'Название для документа',
  TAX: 'Штраф для клиента',
  PENALTIES: 'Штраф для Smartway',
  CANCELORDER: 'ОТМЕНИТЬ ЗАКАЗ',
  SAVE: 'СОХРАНИТЬ ИЗМЕНЕНИЯ',
};

const FIELDS = {
  DESCRIPTION: 'Description',
  PENALTIES: 'Penalties',
  TAX: 'Tax',
  BASE: 'Base',
  COMMISSION: 'Commission',
};

const FIELDSFORCANCELSEGMENT = {
  [FIELDS.PENALTIES]: FIELDS.BASE,
  [FIELDS.TAX]: FIELDS.COMMISSION,
};

const WIDTH = '450px';

const isNotNegativeNumber = (value) => {
  if (typeof value === 'string' && !value.length) return false;

  const num = Number(value);

  return (!isNaN(num) && num >= 0);
};

const validationObj = {
  Penalties: {
    fn: isNotNegativeNumber,
    msg: 'должно быть указано неотрицательное число',
  },
  Tax: {
    fn: isNotNegativeNumber,
    msg: 'должно быть указано неотрицательное число',
  },
};

class PenaltyVersionForm extends Component {
  static propTypes = {
    item: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    toggleDialog: PropTypes.func.isRequired,
  };

  static defaultProps = { item: null };

  constructor(props) {
    super(props);

    this.state = {
      penalty: {
        Penalties: 0,
        Tax: 0,
        Description: TRIPSLABELS.PENALTY_FOR_CANCELLATION,
        Comment: '',
      },
      CancelSegment: {
        Segments: [],
        Penalty: {
          Base: 0,
          Commission: 0,
        },
        Refund: {
          Base: 0,
          Tax: 0,
          Taxes: 0,
          Fee: 0,
          Commission: 0,
        },
      },
      VatDetails: {},
      JsonData: {},
      cancelSegmentAir: '',
      segments: {},
      allCancel: false,
      validation: {
        Penalties: '',
        Tax: '',
      },
    };
  }

  isValid(field, value) {
    const validator = validationObj[field];
    let msg = '';

    if (!validator.fn(value)) {
      msg = validator.msg;
    }

    return msg;
  }

  defaultDataCancel = () => {
    const { item } = this.props;
    const { penalty } = this.state;

    const { Penalties, Tax } = penalty;
    const {
      OrderTripItemId, ServiceType, UserAnalytics, ProviderName,
    } = item;

    return {
      OrderTripItemId,
      Status: 2,
      Description: penalty.Description,
      ServiceType,
      Penalties: [
        {
          ...penalty,
          Penalties: parseFloat(Penalties, 10) || 0,
          Tax: parseFloat(Tax, 10) - parseFloat(Penalties, 10),
        },
      ],
      ReturnFunds: [],
      Surcharges: [],
      ProviderName,
      UserAnalytics,
    };
  };

  handleChangeInput = (field, value) => {
    this.setState({
      penalty: {
        ...this.state.penalty,
        [field]: value,
      },
      CancelSegment: {
        ...this.state.CancelSegment,
        Penalty: {
          ...this.state.CancelSegment.Penalty,
          [FIELDSFORCANCELSEGMENT[field]]: value,
        },
      },
    });
  };

  handleConfirm = () => {
    const { onChange } = this.props;
    const { penalty: { Tax, Penalties } } = this.state;

    const validPenalties = this.validate('Penalties', Penalties);
    const validTax = this.validate('Tax', Tax);

    if (validPenalties && validTax) {
      const tripItem = this.defaultDataCancel();

      onChange(tripItem);
    }
  };

  handleChangeCheckbox = (segments, allCancel) => {
    const names = [];

    segments.forEach(({ Selected, Segment: { DepartureCity, ArrivalCity } }) => {
      if (Selected) {
        names.push(`${DepartureCity} - ${ArrivalCity}`);
      }
    });

    this.setState({
      segments,
      allCancel,
      CancelSegment: {
        ...this.state.CancelSegment,
        Segments: names,
      },
    });
  };

  renderButton = () => (
    <div className={ `${styles.row} ${styles.penalty_action}` }>
      <Button
        label={ LABELS.SAVE }
        theme={ COMPONENTS.BUTTON.THEME.FLAT }
        onClick={ this.handleConfirm }
      />
    </div>
  );

  validate = (field, description) => {
    const isValid = this.isValid(field, description);

    this.setState({
      validation: {
        ...this.validation,
        [field]: isValid,
      },
    });

    return !isValid;
  };

  handlerOnBlurPenalties = () => {
    this.validate('Penalties', this.state.penalty.Penalties);
  };

  handlerOnBlurTax = () => {
    this.validate('Tax', this.state.penalty.Tax);
  };

  renderDefault = () => {
    const {
      validation, penalty: {
        Tax, Penalties, Comment,
      },
    } = this.state;

    const isNotEditComment = this.props.item.Penalties[0].Amount !== Tax;

    return (
      <div className={ `${styles.form}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          Параметры штрафа
        </div>
        <div className={ styles.row }>
          <Input
            field={ FIELDS.PENALTIES }
            value={ Penalties }
            onBlur={ this.handlerOnBlurPenalties }
            label={ LABELS.PENALTIES }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
          <div className={ styles['error-text'] }>{ validation.Penalties }</div>
        </div>
        <div className={ styles.row }>
          <Input
            field={ FIELDS.TAX }
            value={ Tax }
            onBlur={ this.handlerOnBlurTax }
            label={ LABELS.TAX }
            type={ COMPONENTS.INPUT.TYPE.NUMBER }
            onChange={ (e, field, value) => this.handleChangeInput(field, value) }
          />
          <div className={ styles['error-text'] }>{ validation.Tax }</div>
        </div>
        <div className={ styles.row }>
          <TextArea
            value={ Comment }
            label='Комментарий'
            placeholder={ LABELS.COMMENT }
            className={ styles.text_area }
            onChange={ (value) => this.handleChangeInput('Comment', value) }
            height={ 100 }
            disabled={ isNotEditComment }
            labelTop
          />
        </div>
      </div>
    );
  };

  renderFullDefault = () => (
    <div>
      { this.renderDefault() }
      { this.renderButton() }
    </div>
  );

  render() {
    const { toggleDialog } = this.props;

    const html = this.renderFullDefault();

    return (
      <Dialog width={ WIDTH } onClick={ toggleDialog }>
        <div className={ styles.penalty_wrap }>
          { html }
        </div>
      </Dialog>
    );
  }
}

export { PenaltyVersionForm };
