import React from 'react';
import PropTypes from 'prop-types';

import { formatDate, isSameDate } from '../../../../bi/utils/formatDate';
import { getTotalPrice } from '../../../../bi/utils/trip';
import MoneyFormat from '../../../../bi/utils/money';

import { SERVICETYPE, SERVICETYPERU } from '../../../../bi/constants/serviceType';
import { FLIGHT_TYPE_FORMAT_SAVE_RU, FLIGHT_TYPE_FORMAT } from '../../../../bi/constants/trips';
import {
  DAYMONTHPATTERN, FORMATDATETIME, DATE,
} from '../../../../constants/time';
import { MAPCARCLASS_RU } from '../../../../bi/constants/transfer';

import styles from '../../styles/trip.module.scss';

const LABELS = { NEW_ORDER: 'Новый заказ' };

const getEmployeesList = (employees) => employees
  .map(({
    Name, Patronymic, Surname,
  }) => `${Surname} ${Name} ${Patronymic}`)
  .join(', ');

const getDateFromTo = (
  checkinDate, checkoutDate,
) => (isSameDate(formatDate(checkinDate, DATE), formatDate(checkoutDate, DATE))
  ? formatDate(checkinDate, DAYMONTHPATTERN)
  : `${formatDate(checkinDate, DAYMONTHPATTERN)}-${formatDate(checkoutDate, DAYMONTHPATTERN)}`);

const VersionInfo = ({
  item,
  data,
  serviceType,
}) => {
  const renderDirection = (flight) => {
    const {
      DepartureAirport, ArrivalAirport, Type,
    } = flight[0];

    if (Type === FLIGHT_TYPE_FORMAT.DEPARTURE) {
      return `${DepartureAirport.City}, ${DepartureAirport.Name}`;
    }

    if (Type === FLIGHT_TYPE_FORMAT.ARRIVAL) {
      return `${ArrivalAirport.City}, ${ArrivalAirport.Name}`;
    }

    const { DepartureAirport: TransitAirport } = flight[1];

    return `${TransitAirport.City}, ${TransitAirport.Name}`;
  };

  switch (serviceType) {
    case SERVICETYPE.HOTEL: {
      const { EmployeesList } = item;
      const {
        CheckinDate,
        CheckoutDate,
        Hotel: { Name, City },
        Room: { Category },
        PriceDetails,
      } = JSON.parse(data);

      const dateFromTo = `${formatDate(CheckinDate, DAYMONTHPATTERN)}-${formatDate(CheckoutDate, DAYMONTHPATTERN)}`;

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-8'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-8'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-8'] }>{ getEmployeesList(EmployeesList) }</div>
          <div className={ styles['col-1-8'] }>{ dateFromTo }</div>
          <div className={ styles['col-1-8'] }>{ Name }</div>
          <div className={ styles['col-1-8'] }>{ City }</div>
          <div className={ styles['col-1-8'] }>{ Category }</div>
          <div className={ `${styles['center-field']} ${styles['col-1-8']}` }>{ getTotalPrice(PriceDetails) }</div>
        </div>
      );
    }

    case SERVICETYPE.TRANSFER: {
      const { EmployeesList } = item;
      const {
        CheckinDate,
        StartPlace: { Address: AddressStart },
        EndPlace: { Address: AddressEnd },
        CarClass,
        PriceDetails: {
          Base,
          commission,
          Commission,
          Fee,
        },
      } = JSON.parse(data);

      const date = formatDate(CheckinDate, FORMATDATETIME);
      const price = getTotalPrice({
        Base, commission: commission || Commission || 0, Fee: Fee || 0,
      });

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-8'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-8'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-8'] }>{ getEmployeesList(EmployeesList) }</div>
          <div className={ styles['col-1-8'] }>{ date }</div>
          <div className={ `${styles['col-1-8']} ${styles.autotransfer}` }>{ AddressStart }</div>
          <div className={ `${styles['col-1-8']} ${styles.autotransfer}` }>{ AddressEnd }</div>
          <div className={ `${styles['col-1-8']} ${styles.autotransfer}` }>{ MAPCARCLASS_RU[CarClass] }</div>
          <div className={ styles['col-1-8'] }>{ price }</div>
        </div>
      );
    }

    case SERVICETYPE.VIP_HALL: {
      const { EmployeesList, PriceDetails } = item;
      const {
        CheckinDate,
        CheckoutDate,
        Flight,
      } = JSON.parse(data);

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-7'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-7'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-7'] }>{ getEmployeesList(EmployeesList) }</div>
          <div className={ styles['col-1-7'] }>{ FLIGHT_TYPE_FORMAT_SAVE_RU[Flight[0].Type] }</div>
          <div className={ styles['col-1-7'] }>{ getDateFromTo(CheckinDate, CheckoutDate) }</div>
          <div className={ `${styles['col-1-7']} ${styles.autotransfer}` }>{ renderDirection(Flight) }</div>
          <div className={ styles['col-1-7'] }>{ getTotalPrice(PriceDetails) }</div>
        </div>
      );
    }

    case SERVICETYPE.CUSTOM: {
      const { EmployeesList, PriceDetails } = item;
      const {
        CheckinDate,
        CheckoutDate,
        Type,
      } = JSON.parse(data);

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-6'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-6'] }>{ getEmployeesList([EmployeesList]) }</div>
          <div className={ `${styles['col-1-6']} ${styles.autotransfer}` }>{ Type }</div>
          <div className={ styles['col-1-6'] }>{ getDateFromTo(CheckinDate, CheckoutDate) }</div>
          <div className={ styles['col-1-6'] }>{ getTotalPrice(PriceDetails) }</div>
        </div>
      );
    }

    case SERVICETYPE.EVENT: {
      const { PriceDetails } = item;
      const {
        CheckinDate,
        CheckoutDate,
      } = JSON.parse(data);

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-6'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-6'] }>{ getDateFromTo(CheckinDate, CheckoutDate) }</div>
          <div className={ styles['col-1-6'] }>
            { getTotalPrice(PriceDetails) }
            { ' ' }
            ₽
          </div>
        </div>
      );
    }

    case SERVICETYPE.INSURANCE: {
      const { PriceDetails: { Base, Commission } } = item;
      const {
        CheckinDate,
        CheckoutDate,
      } = JSON.parse(data);
      const price = Number(Base) + Number(Commission);

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-6'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-6'] }>{ getDateFromTo(CheckinDate, CheckoutDate) }</div>
          <div className={ styles['col-1-6'] }>
            { price }
            { ' ' }
            ₽
          </div>
        </div>
      );
    }

    case SERVICETYPE.BUS: {
      const { PriceDetails: { Base, commission }, EmployeeList } = item;
      const {
        CheckinDate,
        CheckoutDate,
        Departure,
        Destination,
      } = JSON.parse(data);
      const sum = Number(Base) + Number(commission);
      const price = MoneyFormat.moneyWithDecimal(sum, true);

      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-6'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-6'] }>
            { SERVICETYPERU[serviceType] }
          </div>
          <div className={ styles['col-1-6'] }>{ getEmployeesList([EmployeeList]) }</div>
          <div className={ styles['col-1-6'] }>{ getDateFromTo(CheckinDate, CheckoutDate) }</div>
          <div className={ styles['col-1-6'] }>
            { ' ' }
            { Departure }
            { ' ' }
            -
            { ' ' }
            { Destination }
            { ' ' }
          </div>
          <div className={ styles['col-1-6'] }>{ price }</div>
        </div>
      );
    }

    default: {
      return (
        <div className={ styles.row }>
          <div className={ styles['col-1-5'] }>{ LABELS.NEW_ORDER }</div>
          <div className={ styles['col-1-4'] }>
            { SERVICETYPERU[serviceType] }
          </div>
        </div>
      );
    }
  }
};

VersionInfo.propTypes = {
  item: PropTypes.object.isRequired,
  data: PropTypes.string.isRequired,
  serviceType: PropTypes.string.isRequired,
};

export { VersionInfo };
