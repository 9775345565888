import { requiredDate, requiredValue } from '../../../../bi/utils/checkValidation';

import { INVALID_PROFILE_INFO } from '../../../constants/profile';

const INN = /^(([0-9]{12})|([0-9]{10}))?$/;
const KPP = /^(([0-9]{9})|(''))?$/;
const INDEX = /^(([0-9]{6})|(''))?$/;
const REGION = /^(([0-9]{2})|(''))?$/;
const IINBIN = /^\d{12}$/;

const notNull = (value) => !!value;

const requiredField = (value) => Boolean(value);

const formatINN = (value) => INN.test(value);

const formatKPP = (value) => KPP.test(value);

const formatIndex = (value) => INDEX.test(value);

const formatIINBIN = (value) => IINBIN.test(value);

const formatRegionCode = (value) => REGION.test(value);

const prevValue = ({ current, prev }) => current && prev !== current;

const validationObj = {
  CompanyTypeId: {
    fn: notNull,
    msg: INVALID_PROFILE_INFO.COMPANY_TYPE_ID,
  },
  CompanyName: {
    fn: requiredField,
    msg: INVALID_PROFILE_INFO.COMPANY_NAME,
  },
  INN: {
    fn: [requiredValue, formatINN],
    msg: [INVALID_PROFILE_INFO.INN, INVALID_PROFILE_INFO.FORMAT_INN],
  },
  Number: {
    fn: prevValue,
    msg: INVALID_PROFILE_INFO.CONTRACT_NUMBER,
  },
  StartDate: {
    fn: requiredDate,
    msg: INVALID_PROFILE_INFO.CONTRACT_DATE,
  },
  KPP: {
    fn: formatKPP,
    msg: INVALID_PROFILE_INFO.KPP,
  },
  Index: {
    fn: formatIndex,
    msg: INVALID_PROFILE_INFO.INDEX,
  },
  RegionCode: {
    fn: formatRegionCode,
    msg: INVALID_PROFILE_INFO.REGION,
  },
  IINBIN: {
    fn: formatIINBIN,
    msg: INVALID_PROFILE_INFO.IINBIN,
  },
};

class InfoValidator {
  isValid(field, value) {
    const validator = validationObj[field];

    const validationFn = validator.fn;
    let result = '';

    if (Array.isArray(validationFn)) {
      for (let i = 0; i < validationFn.length; i++) {
        if (!validationFn[i](value)) {
          result = validator.msg[i];
          break;
        }
      }
    } else if (!validationFn(value)) {
      result = validator.msg;
    }

    return result;
  }
}

export default InfoValidator;
