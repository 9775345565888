import React, { Component } from 'react';
import PropTypes from 'prop-types';

import MENUITEMS from '../../app/routers/menuItems';

import styles from './styles/menu-item.module.css';

class MenuItem extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    navigate: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const item = props.item;

    this.state = { isOpenSubMenu: item.items && item.selected };
  }

  handleChangeViewSubMenu = (isOpenSubMenu) => () => this.setState({ isOpenSubMenu });

  handleDisabled = (e, sub) => {
    if (sub.disabled) {
      e.stopPropagation();
      e.preventDefault();

      return () => {};
    }

    return this.props.navigate(e, sub);
  };

  renderSubMenu = () => {
    const { item: { items } } = this.props;
    if (!items) {
      return null;
    }

    return (
      <ul className={ `navigate ${styles.sub}` }>
        { items.map((sub, index) => (
          <li key={ index } className={ `${styles.item} ${sub.selected ? 'active' : ''} ${sub.disabled ? styles.disabled : ''}` }>
            <a id={ `submenu-item-${sub.type}` } href={ sub.disabled ? '' : sub.url } onClick={ (e) => this.handleDisabled(e, sub) }>
              <div>{ sub.name }</div>
            </a>
          </li>
        )) }
      </ul>
    );
  };

  renderMenuIcon = () => {
    const { item } = this.props;

    if (item.type === MENUITEMS.VIP_HALL_CANCEL) {
      return (
        <div className={ `smartway-vip-hall ${styles['icon-services']}` } />
      );
    }

    return (
      <i className='material-icons'>{ item.icon }</i>
    );
  };

  render() {
    const { item } = this.props;
    const { isOpenSubMenu } = this.state;

    const subIcon = item.items && <div className={ `arrow ${styles.arrow}` } />;

    const href = item.items ? null : item.url;

    // при переносе страницы, ссылка на которую есть в меню, удалить onClick функцию
    const onClick = item.items ? this.handleChangeViewSubMenu(!isOpenSubMenu) : (e) => this.props.navigate(e, item);

    return (
      <li className={ `${styles.item} ${item.selected ? 'active' : ''} ${isOpenSubMenu ? 'open' : ''}` }>
        <a id={ `menu-item-${item.type}` } href={ href } onClick={ onClick }>
          <div className={ styles.icon }>
            { this.renderMenuIcon() }
          </div>
          <div className={ styles.title }>
            <span>
              { item.name }
            </span>
          </div>
          { subIcon }
        </a>
        { this.renderSubMenu() }
      </li>
    );
  }
}

export default MenuItem;
