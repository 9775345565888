import { Checkbox } from 'sw-ui';
import { UUID } from 'node:crypto';

import { TemplateRow } from './TemplateRow';

import { switchArrayValuesBy } from '../../../../../../services/company/utils/documentTemplates';

import { COLUMN_LIST } from '../../consts';

import { IRegistryConstructorTemplate } from '../../../../../../services/company/types';

import styles from '../../styles.module.scss';

interface ITableProps {
  selected: UUID[];
  onSelect: (id: UUID[]) => void;
  list: IRegistryConstructorTemplate[];
}

const Columns = () => (
  <>
    { COLUMN_LIST.map((name, index) => (
      <th className={ styles.th } key={ index }>
        { name }
      </th>
    )) }
  </>
);

export const Table = ({
  selected, onSelect, list,
}: ITableProps) => {
  const allSelected = list.length > 0 && selected.length === list.length;

  const handleAllSelect = () => onSelect(allSelected ? [] : list.map(({ Id }) => Id));

  const handleSelect = (id: UUID) => () => onSelect(switchArrayValuesBy(selected, id));

  const renderRow = () => (
    list.map((item) => (
      <TemplateRow
        key={ item.Id }
        row={ item }
        value={ selected.includes(item.Id) }
        onSelect={ handleSelect(item.Id) }
      />
    ))
  );

  return (
    <table className={ styles.table }>
      <thead>
        <tr>
          <th className={ styles.th }>
            <Checkbox
              value={ allSelected }
              onChange={ handleAllSelect }
            />
          </th>
          <Columns />
        </tr>
      </thead>
      <tbody>
        { renderRow() }
      </tbody>
    </table>
  );
};
