import { isMoment } from '../../../../bi/utils/formatDate';

import { ERRORSFORALL, ERROR_FOR_GIFT_VOUCHER } from '../../../../bi/constants/tripValidator';

const requireValue = (value) => !!value;

const requiredField = (value) => Boolean(value.length);

const requiredDate = (value) => isMoment(value) && value.isValid();

const requiredEmployee = (value) => !!value.Id;

const validationObj = {
  selectedEmployee: {
    fn: requiredEmployee,
    msg: ERRORSFORALL.NOEMPLOYEE,
  },
  startDate: {
    fn: requiredDate,
    msg: ERROR_FOR_GIFT_VOUCHER.NO_DATE_START,
  },
  endDate: {
    fn: requiredDate,
    msg: ERROR_FOR_GIFT_VOUCHER.NO_DATE_END,
  },
  classes: {
    fn: requiredField,
    msg: ERROR_FOR_GIFT_VOUCHER.NO_CLASSES,
  },
  limit: {
    fn: requireValue,
    msg: ERROR_FOR_GIFT_VOUCHER.NO_LIMIT,
  },
};

class GiftVoucherValidator {
  isValid(field, value) {
    let validator = '';
    let validationFn = '';

    validator = validationObj[field];
    validationFn = validator.fn;

    if (!validationFn(value)) {
      return validator.msg;
    }

    return '';
  }
}

export default GiftVoucherValidator;
