import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { timePattern, timePatternWithSeparator } from '../../bi/constants/regExp';

import {
  getMoment, isMoment, momentHours, momentMinutes, momentSetHoursAndMinutes,
} from '../../bi/utils/formatDate';

import Input from '../input';

const DEFAULT_TIME = '00:00';

class InputTime extends Component {
  static propTypes = {
    field: PropTypes.string.isRequired,
    type: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    label: PropTypes.string,
    valid: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    disabled: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  static defaultProps = {
    label: '',
    type: 'text',
    valid: '',
    isNewProps: false,
    value: '',
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    disabled: false,
    placeholder: '',
  };

  constructor(props) {
    super(props);
    const { value } = props;
    const time = isMoment(value) ? this.setStateFromMoment(value) : DEFAULT_TIME;

    this.state = { value: time };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value && isMoment(this.props.value)) {
      const time = this.setStateFromMoment(this.props.value);
      this.setState({ value: time });
    }
  }

  setStateFromMoment = (value) => {
    let hours = momentHours(value).toString();
    let minutes = momentMinutes(value).toString();

    if (hours.length === 1) {
      hours = this.addZero(hours);
    }

    if (minutes.length === 1) {
      minutes = this.addZero(minutes);
    }

    return `${hours}:${minutes}`;
  };

  sanitizeInputTime = (value = '') => {
    const validValue = value.replace(timePatternWithSeparator, '').split(':');

    let hours = validValue[0];
    let minutes = validValue[1];
    let actualTime = value;

    if (actualTime.slice(-1) === ':') {
      hours = this.addZero(hours);
      minutes = minutes ? this.addZero(minutes) : minutes;
    }

    if (hours.length > 2) {
      minutes = hours[hours.length - 1];
      hours = hours.slice(0, -1);
      hours = this.addZero(hours);
    }

    if ((minutes && minutes.length > 2)) {
      minutes = minutes.slice(0, -1);
      minutes = this.addZero(minutes);
    }

    actualTime = hours;

    if (minutes && !!minutes.length) {
      actualTime = `${actualTime}:${minutes}`;
    }

    return {
      actualTime, hours, minutes,
    };
  };

  addZero = (value) => {
    if (value.length === 1) {
      return `0${value}`;
    }

    return value;
  };

  getHoursAndMinutes = (value) => {
    const time = value.split(':');

    return { hours: time[0], minutes: time[1] };
  };

  isValid = (value) => {
    const isValid = timePattern.test(value);
    const { hours, minutes } = this.getHoursAndMinutes(value);

    if (!isValid || hours > 24 || minutes > 60) return false;

    return true;
  };

  handleChangeInput = (e) => {
    const { onChange } = this.props;
    const { value } = e.target;

    const {
      actualTime, hours, minutes,
    } = this.sanitizeInputTime(value);

    this.setState({ value: actualTime });

    const isValid = this.isValid(actualTime);
    if (isValid) {
      const momentObj = momentSetHoursAndMinutes(getMoment(), hours, minutes);

      return onChange(momentObj);
    }

    return onChange('error');
  };

  render() {
    const { value } = this.state;
    const {
      field, type, label, valid, disabled, onFocus, onBlur, placeholder,
    } = this.props;

    return (
      <Input
        field={ field }
        type={ type }
        value={ value }
        label={ label }
        valid={ valid }
        disabled={ disabled }
        onChange={ this.handleChangeInput }
        onBlur={ onBlur }
        onFocus={ onFocus }
        placeholder={ placeholder }
      />
    );
  }
}

export default InputTime;
