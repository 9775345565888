import React, { Component } from 'react';
import PropTypes from 'prop-types';

import UserItem from './userItem';
import NoResult from '../../../../../components/NoResult';

import { updatedUsers } from '../../../../../bi/utils/users';
import clipboard from '../../../../../bi/utils/clipboard';

import styles from '../../styles/account.module.scss';

const LABELS = {
  CLIP: 'Ссылка для завершения регистрации пользователя скопирована',
  ERROR_USER_SAVE: 'Не удалось сохранить изменения. Обратитесь в поддержку',
  TEXT: {
    COMMENT: 'Комментарии Intercom',
    NOTIFICATION: 'Уведомления',
    SEND_USER_DOC: 'Закрывающие',
    USER_SETTINGS: 'Настройки',
  },
};

const preparedUsers = (users, number) => {
  const filteredUser = users.filter(({ RegistrationStatus }) => RegistrationStatus === number);
  const enabled = filteredUser.reduce((accum, { Id, Notification }) => {
    const newObj = { ...accum };

    newObj[Id] = Boolean(Notification && Notification.LowBalance);

    return newObj;
  }, {});

  const primaryAccount = [];
  const editRightsOnNot = [];
  const editRightsOffNot = [];
  const onNotification = [];
  const other = [];

  filteredUser.forEach((item) => {
    const {
      IsPrimaryAccount, EditRightsLevelIsAvailable, Notification,
    } = item;

    if (IsPrimaryAccount) {
      return primaryAccount.push(item);
    }

    if (EditRightsLevelIsAvailable) {
      if (Notification.LowBalance) return editRightsOnNot.push(item);

      return editRightsOffNot.push(item);
    }

    if (Notification.LowBalance) {
      return onNotification.push(item);
    }

    return other.push(item);
  });

  const userList = [
    ...primaryAccount,
    ...editRightsOnNot,
    ...editRightsOffNot,
    ...onNotification,
    ...other,
  ];

  return {
    userList,
    enabled,
  };
};

class UserList extends Component {
  static propTypes = {
    accountId: PropTypes.number.isRequired,
    companyId: PropTypes.number.isRequired,
    companies: PropTypes.array.isRequired,
    userSettings: PropTypes.object.isRequired,
    userCompanies: PropTypes.array.isRequired,
    loadingUserSettings: PropTypes.bool.isRequired,
    registrationStatus: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    userActiveData: PropTypes.object.isRequired,
    showUserErrorDialog: PropTypes.bool.isRequired,
    accessSettingsChange: PropTypes.bool.isRequired,
    adminChange: PropTypes.bool.isRequired,
    onClickOnUser: PropTypes.func.isRequired,
    onSaveComment: PropTypes.func.isRequired,
    onChangeUsersData: PropTypes.func.isRequired,
    onSaveUserData: PropTypes.func.isRequired,
    onChangeUserSettings: PropTypes.func.isRequired,
    onChangeArraySettings: PropTypes.func.isRequired,
    clearUserSettings: PropTypes.func.isRequired,
  };


  constructor(props) {
    super();

    const { profile: { users } } = props.companyService.get();

    const { registrationStatus: { NUMBER } } = props;

    this.state = {
      users,
      userList: preparedUsers(users, NUMBER).userList,
      enabled: preparedUsers(users, NUMBER).enabled,
      waitingResponseIds: [],
    };
  }

  componentDidMount() {
    const { companyService } = this.props;

    this.unsubscribe = companyService.subscribe(this.update);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  update = ({ profile: { users } }) => {
    const { registrationStatus: { NUMBER } } = this.props;

    this.setState({
      users,
      userList: preparedUsers(users, NUMBER).userList,
      enabled: preparedUsers(users, NUMBER).enabled,
    });
  };

  getDataUserApproveScheme = (item = null) => this.props.companyService.getDataUserApproveScheme(item);

  getUserSettings = (userId) => this.props.companyService.getUserSettings(this.props.accountId, userId);

  getUserCompanies = (userId) => this.props.companyService.getUserCompanies(userId);

  getDisabledUserSettings = (userId) => this.props.companyService.getDisabledUserSettings(userId, this.props.companyId);

  handleEditUserDialog = (item = null) => this.props.companyService.showUserDialog(item);

  handleShowOfRightsDialog = (item = null) => this.props.companyService.showUserOfRightsDialog(item);

  handleNextTapDisableOfRights = (item = null) => this.props.companyService.showUserNextTapDisableOfRightsDialog(item);

  handleSetRights = (id, status) => this.props.companyService.userSetRights(id, status);

  handleCancelOfRights = () => this.props.companyService.closeSetRightUserDialog();

  handleSaveErrorSetRightUser = (item = null) => this.props.companyService.saveErrorSetRightUser(item);

  handleSendUserDoc = (item, id, sendDoc) => this.props.companyService.sendUserDoc(item, id, sendDoc);

  handleErrorUserDialog = () => this.props.companyService.showErrorUserDialog();

  handleSendNotification = (userId) => {
    const { companyService } = this.props;
    const { users, enabled } = this.state;

    const newUsers = updatedUsers(users, userId);

    if (enabled[userId]) {
      return companyService.userNotificationOff(userId, newUsers);
    }

    return companyService.userNotificationOn(userId, newUsers);
  };

  handleClickRegistrationLink = (link) => {
    const { notificationsService: notifier } = this.props;

    clipboard(link);
    notifier.send({
      header: LABELS.CLIP,
      level: notifier.levels.get('SUCCESS'),
      message: (
        <span>
          { link }
        </span>
      ),
    });
  };

  renderErrorUserSaveDialog = () => this.props.showUserErrorDialog && (
    <NoResult
      onClose={ this.handleErrorUserDialog }
      message={ LABELS.ERROR_USER_SAVE }
    />
  );

  renderList = () => {
    const {
      registrationStatus: {
        TEXT,
        NUMBER,
      },
      companies,
      onClickOnUser,
      onSaveComment,
      userActiveData,
      onChangeUsersData,
      onSaveUserData,
      adminChange,
      userSettings,
      userCompanies,
      loadingUserSettings,
      onChangeUserSettings,
      onChangeArraySettings,
      companyService,
      accessSettingsChange,
    } = this.props;

    const { users } = this.state;
    const {
      userList, enabled, waitingResponseIds,
    } = this.state;
    const commentLabel = NUMBER !== 1 ? LABELS.TEXT.COMMENT : null;
    const notificationLabel = NUMBER === 2 ? LABELS.TEXT.NOTIFICATION : null;
    const sendUserDocLabel = NUMBER === 2 ? LABELS.TEXT.SEND_USER_DOC : null;
    const applicationFormLabel = NUMBER === 2 ? LABELS.TEXT.USER_SETTINGS : null;
    const isActiveUser = (item) => item.RegistrationStatus !== 3 && item.RegistrationStatus !== 4;

    const headerHtml = (
      <div className={ `${styles['user-list']} ${styles.label_user_list}` }>
        <div className={ `${styles['col-4-9']} ${styles.status_label}` }>
          { TEXT }
          { ' ' }
        </div>
        <div className={ `${styles['col-1-9']} ${styles.notification_label}` }>{ notificationLabel }</div>
        <div className={ `${styles['col-1-9']} ${styles.send_doc_label}` }>{ sendUserDocLabel }</div>
        <div className={ `${styles['col-1-9']} ${styles.application_form_label}` }>{ applicationFormLabel }</div>
        <div className={ styles['user-comment__wrap'] }>{ commentLabel }</div>
      </div>
    );

    return (
      <div>
        { headerHtml }
        { userList.map((item, i) => (
          <UserItem
            key={ `user_${item.Id}_${i}` }
            item={ item }
            users={ users }
            companies={ companies }
            isActiveUser={ isActiveUser(item) }
            enabled={ enabled }
            userSettings={ userSettings }
            userCompanies={ userCompanies }
            loadingUserSettings={ loadingUserSettings }
            adminChange={ adminChange }
            waitingResponseIds={ waitingResponseIds }
            onClickOnUser={ onClickOnUser }
            userActiveData={ userActiveData }
            getUserSettings={ this.getUserSettings }
            getUserCompanies={ this.getUserCompanies }
            getDisabledUserSettings={ this.getDisabledUserSettings }
            onSendNotification={ this.handleSendNotification }
            onSaveComment={ onSaveComment }
            onClickRegistrationLink={ this.handleClickRegistrationLink }
            onEditUserDialog={ () => this.handleEditUserDialog(item) }
            onChangeUsersData={ onChangeUsersData }
            onSaveUserData={ onSaveUserData }
            onNextTapDisableOfRights={ () => this.handleNextTapDisableOfRights(item) }
            onShowOfRightsDialog={ () => this.handleShowOfRightsDialog(item) }
            onSetOfRightsDialog={ this.handleSetRights }
            onCancelOfRightsDialog={ this.handleCancelOfRights }
            getDataUserApproveScheme={ () => this.getDataUserApproveScheme(item) }
            onSaveErrorSetRightUser={ () => this.handleSaveErrorSetRightUser(item) }
            onSendUserDoc={ this.handleSendUserDoc }
            onChangeUserSettings={ onChangeUserSettings }
            onChangeArraySettings={ onChangeArraySettings }
            updateUserSettings={ companyService.updateUserSettings }
            companyService={ companyService }
            clearUserSettings={ this.props.clearUserSettings }
            accessSettingsChange={ accessSettingsChange }
          />
        )) }
        { this.renderErrorUserSaveDialog() }
      </div>
    );
  };

  render() {
    return this.state.userList.length ? this.renderList() : null;
  }
}

export default UserList;
