import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { DatePicker } from 'sw-ui';

import Input from '../Input';
import TimeInput from '../TimeInput';

import {
  TRANSFERFIELDS, FIELDSLABELS, PLACETYPE,
} from '../../../../bi/constants/transfer';

import styles from './styles/index.module.css';

const requiredFields = (itemData) => {
  const currentFields = {};

  currentFields.Note = itemData.Notes || '';
  currentFields.Table = itemData.TableSignature || '';

  itemData.AdditionalData.forEach((i) => {
    currentFields[i] = itemData[i];
  });

  return currentFields;
};

class TransferFields extends Component {
  static propTypes = {
    itemData: PropTypes.object.isRequired,
    employees: PropTypes.array.isRequired,
  };

  constructor(props) {
    super(props);

    const currentDate = moment(props.itemData.DateDeparture) || moment(props.itemData.DateArrival).add(3, 'h');

    this.state = {
      fields: requiredFields(props.itemData),
      date: currentDate,
      time: currentDate.format('HH:mm'),
    };
  }

  renderInput = (fields, field) => (
    <div>
      <Input
        value={ fields[field] || '' }
        readOnly
      />
    </div>
  );

  renderField = (field) => {
    const { itemData, employees } = this.props;
    const {
      fields, date, time,
    } = this.state;

    let label = '';
    let inputHtml = null;

    switch (field) {
      case TRANSFERFIELDS.Note: {
        label = FIELDSLABELS[field];
        inputHtml = this.renderInput(fields, field);

        break;
      }

      case TRANSFERFIELDS.Table: {
        const firstEmployee = employees && employees.length ? employees[0].Employee : null;
        const isFirstEmployee = firstEmployee
          ? `${firstEmployee.Surname} ${firstEmployee.Name} ${firstEmployee.Patronymic}`
          : '';

        fields.Table = fields.Table || isFirstEmployee;

        label = FIELDSLABELS[field];
        inputHtml = this.renderInput(fields, field);

        break;
      }

      case TRANSFERFIELDS.DateDeparture: {
        switch (itemData.EndPlace.TransferPlaceType) {
          case PLACETYPE.AIRPORT: {
            label = FIELDSLABELS[`${field}Airline`];

            break;
          }

          case PLACETYPE.RAILSTATION: {
            label = FIELDSLABELS[`${field}Railway`];

            break;
          }
        }

        inputHtml = (
          <div className={ styles.date }>
            <DatePicker
              type='date'
              placeholder='Когда'
              className={ styles.date_picker }
              value={ date }
              onChange={ () => {} }
              disabled
            />
            <TimeInput
              border
              className={ styles.time_input }
              value={ time }
            />
          </div>
        );

        break;
      }

      case TRANSFERFIELDS.DepartureNumber: {
        switch (itemData.FinishPlace.TransferPlaceType) {
          case PLACETYPE.AIRPORT: {
            label = FIELDSLABELS[`${field}Airline`];

            break;
          }
          case PLACETYPE.RAILSTATION: {
            label = FIELDSLABELS[`${field}Railway`];

            break;
          }
        }

        inputHtml = this.renderInput(fields, field);

        break;
      }

      case TRANSFERFIELDS.ArrivalNumber: {
        switch (itemData.StartPlace.TransferPlaceType) {
          case PLACETYPE.AIRPORT: {
            label = FIELDSLABELS[`${field}Airline`];

            break;
          }
          case PLACETYPE.RAILSTATION: {
            label = FIELDSLABELS[`${field}Railway`];

            break;
          }
        }

        inputHtml = this.renderInput(fields, field);

        break;
      }
    }

    return {
      label,
      inputHtml,
    };
  };

  render() {
    const { fields } = this.state;

    const arrayFields = Object.keys(fields);
    const html = arrayFields
      .map((field, index) => {
        if (field !== TRANSFERFIELDS.DateArrival) {
          const { label, inputHtml } = this.renderField(field);

          return (
            <div className={ styles.col } key={ `field_${field}_${index}` }>
              <div className={ styles.label }>{ label }</div>
              { inputHtml }
            </div>
          );
        }

        return null;
      })
      .filter((field) => field !== null);

    return (
      <div className={ styles.wrap_fields }>
        { html }
      </div>
    );
  }
}

export default TransferFields;
