import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Button, Input,
} from 'sw-ui';

import AjaxButton from '../../../../components/ajaxButton';

import { LOCALE } from '../../../../bi/constants/hotels';

import styles from '../../styles/trip.module.scss';

const LABELS = {
  SEND: 'Отправить',
  SEND_RU: 'Отправить RU',
  SEND_EN: 'Отправить EN',
  CANCEL: 'Отмена',
};

export default class SendDialog extends Component {
  static propTypes = {
    email: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
    onSend: PropTypes.func.isRequired,
    onEmailChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    showLocaleButtons: PropTypes.bool,
  };

  static defaultProps = { showLocaleButtons: false };

  constructor(props) {
    super(props);

    this.setInputElRef = (el) => {
      this.inputRef = el;
    };
  }

  componentDidMount() {
    this.inputRef.querySelector('input').select();
  }

  renderButtons = () => {
    const {
      showLocaleButtons, loading, onSend, onClose,
    } = this.props;

    if (showLocaleButtons) {
      return (
        <div>
          <div className={ `${styles.dialog_buttons}` }>
            <AjaxButton
              label={ LABELS.SEND_RU }
              loading={ loading }
              onClick={ () => onSend(LOCALE.RU) }
            />
            <AjaxButton
              label={ LABELS.SEND_EN }
              loading={ loading }
              onClick={ () => onSend(LOCALE.EN) }
            />
          </div>
          <Button
            label={ LABELS.CANCEL }
            theme='flat'
            onClick={ onClose }
          />
        </div>
      );
    }

    return (
      <div className={ `${styles.row} ${styles.right}` }>
        <Button
          label={ LABELS.CANCEL }
          theme='flat'
          onClick={ onClose }
        />
        <AjaxButton label={ LABELS.SEND } loading={ loading } onClick={ () => onSend(LOCALE.RU) } />
      </div>
    );
  };

  render() {
    const {
      email, title, onClose, onEmailChange,
    } = this.props;

    return (
      <Dialog onClick={ onClose }>
        <div className={ styles['user-dialog'] } ref={ this.setInputElRef }>
          <p>{ title }</p>
          <Input
            value={ email }
            onChange={ (value) => onEmailChange(value) }
            placeholder='email'
          />
          { this.renderButtons() }
        </div>
      </Dialog>
    );
  }
}

