import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, MultiSelect } from 'sw-ui';

import COMPONENTS from '../../../../../bi/constants/components';

import TextArea from '../../../../../components/textarea';
import ImgInput from '../../../../hotels/components/ImgInput';

import {
  FIELD_TEXT_HOTEL_CONTRACT,
  PLACING_SELECT,
  FIELDS,
  BREAKFAST_SELECT,
  NDS_SELECT,
  LABELS_HOTEL_CONTRACT,
} from '../../../../../bi/constants/profile';

import styles from '../../styles/account.module.scss';

class HotelRate extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    ratesFromAanda: PropTypes.array.isRequired,
    rateCompares: PropTypes.array.isRequired,
    roomTypeIds: PropTypes.array.isRequired,
    onChangeFields: PropTypes.func.isRequired,
    onDeleteRate: PropTypes.func.isRequired,
    onCopyRate: PropTypes.func.isRequired,
    onRemoveField: PropTypes.func.isRequired,
    onAddPeriodRate: PropTypes.func.isRequired,
    onDeletePeriod: PropTypes.func.isRequired,
    renderSelect: PropTypes.func.isRequired,
    renderDatePickerForm: PropTypes.func.isRequired,
    renderInput: PropTypes.func.isRequired,
    renderInputRate: PropTypes.func.isRequired,
    renderDeleteButton: PropTypes.func.isRequired,
    onAddFields: PropTypes.func.isRequired,
    isRate: PropTypes.bool.isRequired,
    index: PropTypes.number.isRequired,
    validation: PropTypes.object.isRequired,
    displayRates: PropTypes.bool.isRequired,
  };

  handleChangeRate = (values) => {
    const {
      ratesFromAanda, onAddFields, index,
    } = this.props;

    const formatSelectedRates = values.map((item) => {
      const rate = ratesFromAanda.find(({ value }) => value === item);

      return { Code: Number(rate.value), Name: rate.label };
    });

    onAddFields(FIELDS.COMPARE, formatSelectedRates, index);
  };

  renderElement = ({ value, key }, field, index) => {
    const imgHtml = field === FIELDS.IMAGES ? <img className={ styles.img } alt='img' src={ value } /> : '';

    return (
      <li key={ key } className={ styles.item_img }>
        { imgHtml }
        <div>
          { ' ' }
          { value }
          { ' ' }
        </div>
        <div className={ styles.remove_button_img_wrap }>
          <button
            className={ `${styles.button} ${styles.button_close}` }
            onClick={ () => this.props.onRemoveField(field, value, index) }
          >
            <i className='material-icons'>clear</i>
          </button>
        </div>
      </li>
    );
  };

  renderBookingRate = () => {
    const { item, displayRates } = this.props;

    if (!displayRates || !item.BookingRoomNames.length) return null;

    return (
      <div className={ styles.input_rate }>
        <label>{ FIELD_TEXT_HOTEL_CONTRACT.NAME_BOOKING_RATE }</label>
        <ul className={ styles.list }>
          { item.BookingRoomNames.map((name) => <div>{ name }</div>) }
        </ul>
      </div>
    );
  };

  renderRatesFromAanda = () => {
    const { ratesFromAanda, rateCompares } = this.props;

    return (
      <div className={ `${styles.input_rate} ${styles.select_rates}` }>
        <MultiSelect
          label={ LABELS_HOTEL_CONTRACT.NAME_RATE_AANDA }
          items={ ratesFromAanda }
          values={ rateCompares }
          disabled={ !ratesFromAanda.length }
          onChange={ this.handleChangeRate }
        />
      </div>
    );
  };

  renderPeriodRate = () => {
    const {
      item,
      index,
      onDeletePeriod,
      renderInputRate,
      renderDatePickerForm,
    } = this.props;

    return item.Periods.map(({
      StartLifeTime, EndLifeTime, Price, RefundableHours,
    }, ind) => {
      const btnDelete = ind !== 0
        ? (
          <Button
            label={ FIELD_TEXT_HOTEL_CONTRACT.DELETE_PERION }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            className={ styles.button_period_rate }
            onClick={ () => onDeletePeriod(index, ind) }
          />
        )
        : null;

      return (
        <div className={ `${styles.row_input} ${styles.wrap_period}` }>
          { renderDatePickerForm(
            StartLifeTime, EndLifeTime, FIELD_TEXT_HOTEL_CONTRACT.RATE_VALIDITY_PERIOD, index, ind,
          ) }
          <div className={ styles['row_input-cost'] }>
            { renderInputRate(FIELDS.PRICE, Price, FIELD_TEXT_HOTEL_CONTRACT.COST_PER_NIGHT, index, ind) }
          </div>
          <div className={ styles.date_cancellation }>
            <label className={ styles['date_cancellation-label'] }>
              { LABELS_HOTEL_CONTRACT.DATE_CANCELLATION }
            </label>
            <div className={ styles.date_cancellation_input }>
              { LABELS_HOTEL_CONTRACT.FOR }
              { renderInputRate(FIELDS.REFUNDABLE_HOURS, RefundableHours, '', index, ind) }
              { LABELS_HOTEL_CONTRACT.HOURS_TO_CHECKIN }
            </div>
          </div>
          { btnDelete }
        </div>
      );
    });
  };

  renderErrorPeriodDates = () => {
    const { validation } = this.props;

    if (!validation.PeriodDates) return null;

    return <p>{ validation.PeriodDates }</p>;
  };

  render() {
    const {
      item,
      onChangeFields,
      onAddPeriodRate,
      renderSelect,
      renderInput,
      renderDeleteButton,
      isRate,
      onDeleteRate,
      onCopyRate,
      onAddFields,
      index,
      roomTypeIds,
      validation,
    } = this.props;

    const roomType = renderSelect(
      FIELD_TEXT_HOTEL_CONTRACT.ROOM_TYPE_ID, roomTypeIds, FIELDS.ROOM_TYPE_ID, item, isRate, index,
    );
    const rateName = renderInput(
      FIELDS.RATE_NAME, item.RateName, FIELD_TEXT_HOTEL_CONTRACT.NAME_DOC_RATE, isRate, index,
    );
    const placing = renderSelect(
      FIELD_TEXT_HOTEL_CONTRACT.PLACING, PLACING_SELECT, FIELDS.PAX, item, isRate, index,
    );
    const breakfast = renderSelect(
      FIELD_TEXT_HOTEL_CONTRACT.BREAKFAST, BREAKFAST_SELECT, FIELDS.BREAKFAST, item, isRate, index,
    );
    const nds = renderSelect(FIELD_TEXT_HOTEL_CONTRACT.NDS, NDS_SELECT, FIELDS.VAT, item, isRate, index);
    const images = item.Images.map((img) => this.renderElement({ value: img, key: img }, FIELDS.IMAGES, index));

    return (
      <div>
        { renderDeleteButton(
          styles.delete_button, styles.btn, LABELS_HOTEL_CONTRACT.DELETE_RATE, onDeleteRate, false, index,
        ) }
        { renderDeleteButton(styles.delete_button, '', LABELS_HOTEL_CONTRACT.COPY_RATE, onCopyRate, false, index) }
        <div className={ styles.rate }>
          <div className={ styles.input_rate }>
            { roomType }
          </div>
          <div className={ styles.input_rate }>
            { rateName }
          </div>
          { this.renderBookingRate() }
          { this.renderRatesFromAanda() }
          <div className={ styles.input_rate }>
            <ImgInput
              onSubmit={ (e, value) => onAddFields(FIELDS.IMAGES, value, index) }
            />
            <ul className={ styles.list }>
              { images }
            </ul>
          </div>
          <div className={ styles.input_rate }>
            <TextArea
              value={ item.Description }
              placeholder={ LABELS_HOTEL_CONTRACT.DESCRIBE_NUMBER }
              className={ styles.text_area }
              onChange={ (value) => onChangeFields(FIELDS.DESCRIPTION, value, index) }
              label={ LABELS_HOTEL_CONTRACT.DESCRIPTION_ROOM }
              height={ 100 }
              labelTop
              valid={ validation ? validation[FIELDS.DESCRIPTION] : '' }
            />
          </div>
          <div className={ styles.input_rate }>
            <div className={ styles.row_input }>
              { placing }
              { breakfast }
              { nds }
            </div>
          </div>
          <div className={ styles.block_hint }>
            <div className={ styles.hint }>
              { LABELS_HOTEL_CONTRACT.DIFF_TIMES }
              <ul>
                <li>
                  { LABELS_HOTEL_CONTRACT.DIFF_MORE }
                </li>
                <li>
                  { LABELS_HOTEL_CONTRACT.DIFF_LOW }
                </li>
              </ul>
            </div>
          </div>
          { this.renderPeriodRate() }
          { this.renderErrorPeriodDates() }
          <Button
            label={ FIELD_TEXT_HOTEL_CONTRACT.ADD_PERIOD }
            onClick={ () => onAddPeriodRate(index) }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            className={ styles.button_period }
          />
        </div>
      </div>
    );
  }
}

export default HotelRate;
