import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from 'sw-ui';

import DecisionButtons from '../../../../components/DecisionButtons';

import { DECISIONBUTTONS } from '../../../../bi/constants/decisionButtons';

import styles from '../../styles/dialog.module.css';

const DIALOG_WIDTH = '500px';

const AdditionalServiceDialog = ({
  onClose, onConfirm, labelInfo, labelContinue,
}) => (
  <Dialog onClick={ onClose } width={ DIALOG_WIDTH }>
    <div className={ styles.dialog }>
      <div className={ `${styles.row} ${styles.info}` }>
        { labelInfo }
      </div>
      <div className={ styles.actions }>
        <DecisionButtons
          onSave={ onConfirm }
          onCancel={ onClose }
          labelSave={ labelContinue }
          labelCancel={ DECISIONBUTTONS.LABELS.CANCEL }
          loadingIncluded
        />
      </div>
    </div>
  </Dialog>
);

AdditionalServiceDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  labelInfo: PropTypes.string.isRequired,
  labelContinue: PropTypes.string.isRequired,
};

export { AdditionalServiceDialog };
