import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker } from 'sw-ui';

import { getMoment } from '../../../../bi/utils/formatDate';
import AjaxButton from '../../../../components/ajaxButton';

import styles from '../../styles/report.module.scss';
import COMPONENTS from '../../../../bi/constants/components';
import { REPORTLABALES } from '../../../../bi/constants/reports';

class TripReportPage extends Component {
  static propTypes = { onDownload: PropTypes.func.isRequired };

  state = {
    date: getMoment(),
    waitingResponse: false,
  };

  handleChangeDate = ({ value }) => {
    this.setState({ date: value });
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  handleDownload = () => {
    const { date } = this.state;

    this.toggleWaitingResponse();
    this.props.onDownload(date)
      .then(this.toggleWaitingResponse)
      .catch(this.toggleWaitingResponse);
  };

  render() {
    const { waitingResponse, date } = this.state;

    return (
      <div className={ styles.main }>
        <div className={ styles.header }>
          <div className={ styles.search }>
            <DatePicker
              placeholder={ REPORTLABALES.DATETRIP }
              label={ REPORTLABALES.DATETRIP }
              inputTheme={ COMPONENTS.DATEPICKER.INPUTTHEME.OPEN }
              value={ date }
              onChange={ this.handleChangeDate }
            />
            <div className={ styles.actions }>
              <AjaxButton
                label={ REPORTLABALES.DOWNLOAD }
                onClick={ this.handleDownload }
                loading={ waitingResponse }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TripReportPage;
