import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';

import styles from '../../styles.module.css';

const LABELS = { NEXT: 'Далее' };

const NextButton = ({
  onHandleNextStep,
  disabled,
  loading,
  label,
}) => {
  const LABEL = label || LABELS.NEXT;

  return (
    <Button
      onClick={ onHandleNextStep }
      theme={ COMPONENTS.BUTTON.THEME.SECOND }
      className={ styles.next }
      label={ LABEL }
      disabled={ disabled }
      loading={ loading }
    />
  );
};

NextButton.propTypes = {
  onHandleNextStep: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  label: PropTypes.string,
};

NextButton.defaultProps = {
  disabled: false,
  loading: false,
  label: '',
};

export { NextButton };
