import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { clearInterval, setInterval } from 'worker-timers';
import moment from 'moment';

import { formatTimer } from '../../../../bi/utils/formatTimer';
import diffTime from '../../../../bi/utils/diffTime';

import styles from './styles/timer.module.css';

const SECOND = 1000;

const Timer = ({
  deadLineInStrUTC, cleanDeadLineInNum, currentTime, onCloseTimer,
}) => {
  const dateDeadLine = new Date(deadLineInStrUTC); // из '2023-11-07 19:00:00' в 07 2023 19:00:00 GMT+0300 (Москва, стандартное время)

  const valueCurrentTime = currentTime || moment(); // если в компонент не передали текущее время, оно по дефолту moment()
  const timerTime = cleanDeadLineInNum || (dateDeadLine - valueCurrentTime); // можно передать чистое время сразу(cleanDeadLineInNum) или найти его - это разница между дедлайном(deadLineInStrUTC) и временем в данный момент(valueCurrentTime)

  const [counter, setCounter] = useState(timerTime);

  useEffect(() => {
    if (onCloseTimer && counter <= 0) {
      onCloseTimer();
    }
  });

  useEffect(() => {
    setCounter(timerTime);
  }, [timerTime]);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - SECOND), SECOND);

    return () => clearInterval(timer);
  }, [counter]);

  const {
    days, hours, minutes, seconds,
  } = diffTime(counter); // утилита преобразует милисекнды в дни, часы, минуты и секунды
  const time = formatTimer(days, hours, minutes, seconds);

  return (
    <div className={ styles.wrap }>
      { time }
    </div>
  );
};

Timer.propTypes = {
  deadLineInStrUTC: PropTypes.string, // дата и время в формате '2029-12-31 19:00:00' до которого должен работать таймер
  cleanDeadLineInNum: PropTypes.number, // чистое время работы таймера в милисекундах(60000)
  currentTime: PropTypes.object, // время в данный момент(начало отсчёта)
  onCloseTimer: PropTypes.func, // функция закрытия таймера после завершения отсчёта
};

Timer.defaultProps = {
  deadLineInStrUTC: '',
  cleanDeadLineInNum: 0,
  currentTime: null,
  onCloseTimer: null,
};

export default Timer;
