import { useEffect, useState } from 'react';
import { Loading, MultiSelect } from 'sw-ui';

import CompanyService from '../../../../bi/services/company/service';

import { Table } from './components/Table/Table.tsx';

import { LIMIT_ON_PAGE, LABELS } from './constants.ts';

import { IFilters, IPaidByCardState } from './types.ts';

import styles from './styles/styles.module.scss';

interface IPaidByCardProps {
  companyId: number;
  accountId: number;
  companyService: CompanyService;
}

export const PaidByCard = ({
  companyId,
  accountId,
  companyService,
}: IPaidByCardProps) => {
  const {
    paidByCard,
    startDate,
    endDate,
    // maxDate,
  } = companyService.get();

  const [state, setState] = useState<IPaidByCardState>({ paidByCard });
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<IFilters>({
    dates: { startDate, endDate },
    userIds: [],
  });

  const {
    paidByCard: {
      isLoading,
      transactions,
      users,
      totalCount,
    },
  } = state;

  const offset = LIMIT_ON_PAGE * (currentPage - 1);

  useEffect(() => {
    const unsubscribe = companyService.subscribe(({ paidByCard }) => setState({ paidByCard }));

    if (users.length < 1) {
      companyService.getCompanyUsers(companyId);
    } else {
      companyService.getPersonalTransactions(
        companyId,
        filters.userIds,
        { limit: LIMIT_ON_PAGE, offset: offset },
        [
          { field: 'startDate', value: filters.dates.startDate },
          { field: 'endDate', value: filters.dates.endDate },
        ],
      );
    }

    return () => unsubscribe();
  }, [
    filters,
    users,
    offset,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters.userIds]);

  const handleChangePage = (_event: unknown, newPage: number) => {
    setCurrentPage(newPage);
  };

  // const handleSetDates = ({
  //   startDate,
  //   endDate,
  // }: {
  //   startDate: Moment,
  //   endDate: Moment,
  // }) => {
  //   setFilters((prevState) => ({
  //     ...prevState,
  //     dates: { startDate, endDate },
  //   }));
  // };

  const handleSelectUsers = (selectedUsers: string[]) => {
    setFilters((prevState) => ({
      ...prevState,
      userIds: selectedUsers,
    }));
  };

  const getMappedUsers = () => {
    return users.map(({ RegistrationName, Id }) => ({
      label: RegistrationName,
      value: Id,
    }));
  };

  const renderTable = () => {
    if (isLoading) return <Loading />;

    return (
      <Table
        transactions={ transactions }
        companyId={ companyId }
        accountId={ accountId }
        currentPage={ currentPage }
        itemsOnPage={ LIMIT_ON_PAGE }
        totalItems={ totalCount }
        onChangePage={ handleChangePage }
      />
    );
  };

  // TODO вернуть фильтр по датам когда будет бэк
  return (
    <div className={ styles.root }>
      <h2 className={ styles.title }>Оплачено картой</h2>

      { /*<DateFilter*/ }
      { /*  dates={ filters.dates }*/ }
      { /*  maxDate={ maxDate }*/ }
      { /*  onSetDates={ handleSetDates }*/ }
      { /*/>*/ }

      <div className={ styles.limitWidth }>
        <MultiSelect
          label={ LABELS.TRANSACTION_AUTHOR }
          items={ getMappedUsers() }
          values={ filters.userIds }
          search
          searchPlaceholder={ LABELS.TRANSACTION_AUTHOR_SURNAME }
          onChange={ handleSelectUsers }
          overflow
        />
      </div>

      { renderTable() }
    </div>
  );
};
