import { UUID } from 'node:crypto';

const switchArrayValuesBy = (
  first: UUID[] = [],
  second: UUID,
  alg = (a: UUID, b: UUID) => a === b,
) => {
  const exist = first.filter((fVal) => !alg(fVal, second));

  if (exist.length < first.length) {
    return exist;
  }

  return [...first, second];
};

export { switchArrayValuesBy };
