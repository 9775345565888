import React from 'react';
import PropTypes from 'prop-types';
import {
  COMPONENTS,
  Loading,
  Tooltip,
} from 'sw-ui';

import { FlatButton } from '../../button';
import { FormDownloadFlightCertificate } from './FormDownloadFlightCertificate';

import {
  FLIGHT_CERTIFICATE_STATUS_TEXT,
  FLIGHT_CERTIFICATE_STATUS_TYPES,
} from '../../../bi/constants/trips';

import styles from './styles/contentDialogButtonInAir.module.css';

const {
  TOOLTIP: { POSITION: { RIGHT } },
  LOADING: { SIZE: { MAIN } },
} = COMPONENTS;

const LABELS = {
  ORDER: 'ЗАКАЗАТЬ СПРАВКУ за 400 рублей',
  ORDER_SHORT: 'ЗАКАЗАТЬ СПРАВКУ',
  ORDER_BACK: 'Назад',
  DOWNLOAD: 'CКАЧАТЬ СПРАВКУ БЕЗ ФИНАНСОВОЙ ОПЕРАЦИИ',
  INFO_RATE_COMPANY: 'Обратите внимание на информацию о тарифе в комментариях к компании',
  WARNING: 'Для компании стоит запрет на покупку справок о перелете',
  BOOK_ERROR: 'Произошла ошибка при заказе справки о перелете',
  TOOLTIP: { ORDER: 'Произойдет списание 400 рублей со счета организации' },
};

const ContentDialogButtonInAir = ({
  isFreeFlightCertificate,
  isRemoveFlightCertificate,
  showDownloadForm,
  showOrderConfirmation,
  isLoading,
  bookError,
  airItemId,
  status,
  onDownload,
  onBook,
  onShowModalClose,
  onShowOrderConfirmationOpen,
  onShowOrderConfirmationClose,
  onShowDownloadFormOpen,
  onShowDownloadFormClose,
}) => {
  const renderContentDialogWithFreeFlightCertificate = () => {
    if (status !== FLIGHT_CERTIFICATE_STATUS_TYPES.CAN_DOWNLOAD) {
      return (
        <p className={ styles.warning }>
          { FLIGHT_CERTIFICATE_STATUS_TEXT[status] }
        </p>
      );
    }

    return (
      <FormDownloadFlightCertificate
        airItemId={ airItemId }
        onDownload={ onDownload }
        isFreeFlightCertificate
        onClickClose={ onShowModalClose }
      />
    );
  };

  const renderOrderConfirmation = () => (
    <div>
      <p className={ styles.warning }>
        { LABELS.TOOLTIP.ORDER }
      </p>
      <div className={ styles.action_tooltip }>
        <FlatButton
          label={ LABELS.ORDER_SHORT }
          onClick={ onBook }
        />
        <FlatButton
          label={ LABELS.ORDER_BACK }
          onClick={ onShowOrderConfirmationClose }
        />
      </div>
    </div>
  );

  const warningContent = isRemoveFlightCertificate && (
    <p className={ styles.warning }>
      { LABELS.WARNING }
    </p>
  );

  const tooltipDownload = status !== FLIGHT_CERTIFICATE_STATUS_TYPES.CAN_DOWNLOAD && (
    <Tooltip
      position={ RIGHT }
    >
      { FLIGHT_CERTIFICATE_STATUS_TEXT[status] }
    </Tooltip>
  );

  if (isLoading) {
    return <Loading size={ MAIN } />;
  }

  if (bookError) {
    return (
      <p className={ styles.warning }>
        { LABELS.BOOK_ERROR }
      </p>
    );
  }

  if (showOrderConfirmation) {
    return renderOrderConfirmation();
  }

  if (isFreeFlightCertificate) {
    return renderContentDialogWithFreeFlightCertificate();
  }

  if (showDownloadForm) {
    return (
      <FormDownloadFlightCertificate
        airItemId={ airItemId }
        onDownload={ onDownload }
        isFreeFlightCertificate
        onClickClose={ onShowDownloadFormClose }
      />
    );
  }

  return (
    <div>
      <p>
        { LABELS.INFO_RATE_COMPANY }
      </p>
      { warningContent }
      <div className={ styles.action }>
        <div className='sw-tooltip-wrapper'>
          <FlatButton
            label={ LABELS.ORDER }
            onClick={ onShowOrderConfirmationOpen }
          />
          <Tooltip
            position={ RIGHT }
          >
            { LABELS.TOOLTIP.ORDER }
          </Tooltip>
        </div>
        <div className='sw-tooltip-wrapper'>
          <FlatButton
            label={ LABELS.DOWNLOAD }
            disabled={ status !== FLIGHT_CERTIFICATE_STATUS_TYPES.CAN_DOWNLOAD }
            onClick={ onShowDownloadFormOpen }
          />
          { tooltipDownload }
        </div>
      </div>
    </div>
  );
};

ContentDialogButtonInAir.propTypes = {
  isFreeFlightCertificate: PropTypes.bool.isRequired,
  isRemoveFlightCertificate: PropTypes.bool.isRequired,
  showDownloadForm: PropTypes.bool.isRequired,
  showOrderConfirmation: PropTypes.bool.isRequired,
  bookError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  airItemId: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired,
  onDownload: PropTypes.func.isRequired,
  onBook: PropTypes.func.isRequired,
  onShowModalClose: PropTypes.func.isRequired,
  onShowOrderConfirmationOpen: PropTypes.func.isRequired,
  onShowOrderConfirmationClose: PropTypes.func.isRequired,
  onShowDownloadFormOpen: PropTypes.func.isRequired,
  onShowDownloadFormClose: PropTypes.func.isRequired,
};

export { ContentDialogButtonInAir };
