import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import LinkToNewTab from '../../components/LinkToNewTab';

import {
  SHORTFIELDRIGHTS, FIELDRIGHTS, CREATE_EXPENCE_REPORTS,
} from '../../bi/constants/rights';
import { ANCHORS, TITLE } from '../../bi/constants/employee';

import styles from './styles/employee.module.scss';

class EmploeeRights extends Component {
  static propTypes = {
    rights: PropTypes.object,
    approvalScheme: PropTypes.object,
    сreateReportsApproval: PropTypes.object,
    approvalSchemaER: PropTypes.object,
    travelPolicyService: PropTypes.object.isRequired,
    approversForEmployee: PropTypes.array,
    email: PropTypes.string,
    tpName: PropTypes.string,
    setRef: PropTypes.func.isRequired,
  };

  static defaultProps = {
    email: '',
    tpName: '',
    rights: null,
    approvalScheme: null,
    сreateReportsApproval: [],
    approversForEmployee: [],
    approvalSchemaER: [],
  };

  constructor(props) {
    super(props);
    this.state = { travelPolicies: null };
  }

  componentDidMount() {
    this.getTravelPolicies();
  }

  getTravelPolicies = async () => {
    const { travelPolicyService } = this.props;
    const travelPolicies = await travelPolicyService.getTravelPolicies();
    this.setState({ travelPolicies });
  };


  renderApprovalScheme = (approve) => {
    if (!approve) {
      return null;
    }

    return <span className={ styles.dashed }>{ approve.Name }</span>;
  };

  renderApproveItems = (approvers) => approvers.map(({
    Name, MiddleName, EmployeeId,
  }, index) => {
    const NameAndMiddleName = `${Name}${MiddleName ? ` ${MiddleName}` : ''}${index !== approvers.length - 1 ? ', ' : ''}`;

    if (EmployeeId === 0) {
      return (
        <span>
          { NameAndMiddleName }
        </span>
      );
    }

    return (
      <LinkToNewTab key={ EmployeeId } to={ `/employeePage/${EmployeeId}` }>
        { NameAndMiddleName }
      </LinkToNewTab>
    );
  });

  renderApproveSchema = (approvers) => approvers.map(({ Name }, index) => {
    const pushName = index !== approvers.length - 1 ? `${Name}, ` : Name;

    return (
      <span className={ styles.dashed }>
        { pushName }
      </span>
    );
  });

  renderApprove = (approvers) => {
    if (!approvers.length) return null;

    return (
      <span className={ styles.dashed }>
        (
        { this.renderApproveItems(approvers) }
        )
      </span>
    );
  };

  renderApproversForEmployee = () => {
    const { approversForEmployee } = this.props;
    if (!approversForEmployee.length) {
      return null;
    }

    const approvers = approversForEmployee.map(({ Name, Email }) => `${Name}(${Email})`).join(', ');
    const subText = approversForEmployee.length > 1 ? 'согласующими лицами являются:' : 'согласующим лицом является:';

    return (
      <div className={ styles.row }>
        <div>
          <span>{ `В рамках тревел-политик ${subText}` }</span>
          { ' ' }
          <b>{ approvers }</b>
          <span> (если нет иной схемы согласования)</span>
        </div>
      </div>
    );
  };

  renderTravelPolicy = () => {
    const { travelPolicies } = this.state;
    if (!travelPolicies) {
      return null;
    }

    const { tpName } = this.props;
    const tPWithoutCurrent = travelPolicies.filter(({ Name }) => Name !== tpName);

    return (
      <div className={ `sw-tooltip-wrapper ${styles.wrap_tooltip_tp}` }>
        <span><b>{ tpName }</b></span>
        <Tooltip position='bottom'>
          { this.renderTpNames(tPWithoutCurrent) }
        </Tooltip>
      </div>
    );
  };

  renderTpNames = (names) => (
    <ul className={ styles.list }>
      { names.map(({ Name, Id }) => (
        <li key={ Id } className={ styles.item }>{ Name }</li>
      )) }
    </ul>
  );

  renderRightsList = (rightsList, email, isAvailable) => {
    if (!rightsList.length) {
      return null;
    }
    const {
      rights: {
        buyAfterApprove, buyApprovalScheme, buyTravelPolicy, buyTripAccount, CreateExpenseReports,
      }, approvalScheme, approvalSchemaER,
    } = this.props;
    const { approversForEmployee, сreateReportsApproval } = this.props;

    const getHtmLabel = (id) => {
      if (id === SHORTFIELDRIGHTS.BUYTRIP) {
        if (buyTravelPolicy && !buyTripAccount) {
          return this.renderTravelPolicy();
        } else if (buyAfterApprove) {
          return this.renderApprove(approversForEmployee);
        } else if (buyApprovalScheme) {
          return this.renderApprovalScheme(approvalScheme);
        }
      }

      if (id === FIELDRIGHTS.CREATEEXPENCEREPORTS) {
        if (CreateExpenseReports === CREATE_EXPENCE_REPORTS.OnlyAfterApprove) {
          return this.renderApprove(сreateReportsApproval);
        }
        if (CreateExpenseReports === CREATE_EXPENCE_REPORTS.ApprovalScheme) {
          return this.renderApproveSchema(approvalSchemaER);
        }
      }

      if (id === SHORTFIELDRIGHTS.DOCUMENTS || id === FIELDRIGHTS.SENDDOCUMENTS) {
        return <b>{ email }</b>;
      }

      return null;
    };

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1-2'] }>
          <label>{ isAvailable ? TITLE.EMPLOYEE_CAN : TITLE.EMPLOYEE_CANT }</label>
        </div>
        <div className={ styles['col-1-3'] }>
          <ul className={ styles.list }>
            { rightsList.map(({ label, id }) => (
              <li className={ styles.item } key={ id } >
                { label }
                { ' ' }
                { getHtmLabel(id) }
              </li> // придумать имя переменной
            )) }
          </ul>
        </div>
      </div>
    );
  };

  render() {
    const { rights } = this.props;
    const { email, setRef } = this.props;

    if (!rights) {
      return null;
    }

    const { availableRights, unAvailableRights } = rights;

    return (
      <div id='right' className={ styles.col1 } ref={ (ref) => setRef(ANCHORS.RIGHT, ref) }>
        <div className={ styles.title }>
          { TITLE.EMPLOYEE_RIGHT }
        </div>
        { this.renderApproversForEmployee() }
        { this.renderRightsList(availableRights, email, true) }
        { this.renderRightsList(unAvailableRights, email, false) }
      </div>
    );
  }
}

export default EmploeeRights;
