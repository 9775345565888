import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../../components/button/Button';
import TextArea from '../../../components/textarea';

import styles from './index.module.css';

const CONTENT_MAX_LENGTH = 10;
const TEXTAREA_COUNT_ROWS = 10;

const LABELS = {
  EDIT_TEMPLATE: 'Редактировать',
  SEND_IN_WP: 'Отправить в ЛК',
  REMOVE_IN_WP: 'Убрать из ЛК',
  REMOVE: 'Удалить',
  SAVE_TEMPLATE: 'Сохранить шаблон',
  SAVE_AND_SEND_TEMPLATE: 'Сохранить шаблон и отправить в лк',
  CANCEL: 'Отменить',
  STATUS_ACTIVE: 'Отправлен в личный кабинет',
};

class ItemTemplateWarning extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isEditingAll: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      content: this.props.content,
      isEditing: false,
      openApproveSend: false,
      openApproveRemove: false,
    };
  }

  handelEdit = (isEdit) => {
    const { onEdit } = this.props;

    this.setState({ isEditing: isEdit });
    onEdit(isEdit);
  };

  handleClickButton = () => {
    const {
      id, isActive, onChange,
    } = this.props;
    const { content } = this.state;

    onChange({
      id, isActive: !isActive, content,
    });
  };

  handleRemoveButton = () => {
    const { id, onRemove } = this.props;

    onRemove(id);
  };

  handleSaveTemplate = (value) => {
    const { id, onChange } = this.props;
    const { content } = this.state;

    onChange({
      id, isActive: value, content,
    });
    this.handelEdit(false);
  };

  handleCancelButton = () => {
    const { content } = this.props;

    this.handelEdit(false);
    this.setState({ content });
  };

  handleTextAreaChange = (value) => this.setState({ content: value });

  renderTemplate = () => {
    const { isActive, isEditingAll } = this.props;
    const { content, isEditing } = this.state;

    const disabledButton = isEditingAll && !isEditing;
    const activeStatus = isActive ? <p className={ styles.status }>{ LABELS.STATUS_ACTIVE }</p> : null;
    const textSend = isActive ? LABELS.REMOVE_IN_WP : LABELS.SEND_IN_WP;

    return (
      <section className={ styles.item_wrapper }>
        <div className={ styles.info }>
          { activeStatus }
          <p className={ styles.content }>{ content }</p>
        </div>
        <div className={ styles.action }>
          <Button
            label={ textSend }
            onClick={ this.handleClickButton }
            disabled={ disabledButton }
          />
          <Button
            label={ LABELS.EDIT_TEMPLATE }
            onClick={ () => this.handelEdit(true) }
            disabled={ disabledButton }
          />
          <Button
            label={ LABELS.REMOVE }
            disabled={ disabledButton }
            onClick={ this.handleRemoveButton }
          />
        </div>
      </section>
    );
  };

  renderEditingTemplate = () => {
    const { isActive } = this.props;
    const { content } = this.state;

    const activeStatus = isActive ? <p className={ styles.status }>{ LABELS.STATUS_ACTIVE }</p> : null;
    const disabledButton = content.length < CONTENT_MAX_LENGTH;

    return (
      <section className={ styles.item_wrapper }>
        <div className={ styles.info }>
          { activeStatus }
          <TextArea
            className={ `${styles.edit_template} al-textarea` }
            value={ content }
            onChange={ (value) => this.handleTextAreaChange(value) }
            rows={ TEXTAREA_COUNT_ROWS }
          />
        </div>
        <div className={ styles.action }>
          <Button
            label={ LABELS.SAVE_TEMPLATE }
            disabled={ disabledButton }
            onClick={ () => this.handleSaveTemplate(false) }
          />
          <Button
            label={ LABELS.SAVE_AND_SEND_TEMPLATE }
            disabled={ disabledButton }
            onClick={ () => this.handleSaveTemplate(true) }
          />
          <Button
            label={ LABELS.CANCEL }
            onClick={ this.handleCancelButton }
          />
        </div>
      </section>
    );
  };

  render() {
    const { isEditing } = this.state;

    const htmlContent = isEditing ? this.renderEditingTemplate() : this.renderTemplate();

    return (
      <div className={ styles.wrapper }>
        { htmlContent }
      </div>
    );
  }
}

export { ItemTemplateWarning };
