import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Select } from 'sw-ui';

import { startOfMonth, getMoment } from '../../../bi/utils/formatDate';
import {
  ACCOUNTTEXT, SALESREPORTFORMAT, SALESREPORTTYPES,
} from '../../../bi/constants/account';
import COMPONENTS from '../../../bi/constants/components';

import DecisionButtons from '../../DecisionButtons';
import RangeDatePicker from '../../RangeDatePicker';

import styles from '../styles/index.module.css';

class SalesReportDialog extends Component {
  static propTypes = {
    onDownload: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {
    startDate: startOfMonth(),
    endDate: getMoment(),
    maxDate: getMoment(),
    selected: SALESREPORTFORMAT.MONTH,
  };

  handleChangeDate = (field, value) => this.setState({ [field]: value });

  handleChangeSelect = ({ value }) => this.setState({ selected: value });

  render() {
    const { onDownload, onClose } = this.props;
    const {
      startDate, endDate, maxDate, selected,
    } = this.state;

    return (
      <Dialog
        width={ COMPONENTS.DIALOG.WIDTH.MIDDLE }
        onClick={ onClose }
      >
        <div className={ styles.dialog }>
          <div className={ styles.header }>Отчет по продажам</div>
          <div className={ styles.dates }>
            <RangeDatePicker
              startDate={ startDate }
              endDate={ endDate }
              maxDate={ maxDate }
              onChange={ this.handleChangeDate }
              placeholderNameFrom={ ACCOUNTTEXT.FROM }
              placeholderNameTo={ ACCOUNTTEXT.TO }
            />
          </div>
          <div className={ styles.format }>
            <div>Формат</div>
            <div className={ styles.select }>
              <Select
                items={ SALESREPORTTYPES }
                value={ selected }
                theme={ COMPONENTS.SELECT.THEME.BORDER }
                onChange={ this.handleChangeSelect }
              />
            </div>
          </div>
          <DecisionButtons
            loadingIncluded
            labelSave={ ACCOUNTTEXT.DOWNLOADXLSX }
            onCancel={ onClose }
            onSave={ () => onDownload(startDate, endDate, selected) }
          />
        </div>
      </Dialog>
    );
  }
}

export default SalesReportDialog;
