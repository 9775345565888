import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SearchDocumentForPrint from '../../../../components/SearchDocumentsForPrints';

import {
  isMoreThanThirtyOneDays,
  isAfterDate,
  diffDays,
  getMoment,
  isMoment,
} from '../../../../bi/utils/formatDate';

import SEARCH from '../../../../bi/constants/search';
import { TYPE_AVIA } from '../../../../bi/constants/reports';

import styles from '../../styles/report.module.scss';

const DAYS = { HALF_MONTH: 92 };

const LABELS = { TYPE: 'Тип' };

const ERROR = {
  DIFF: `Период между датами должен быть не более ${DAYS.HALF_MONTH} дней`,
  COMPANY: 'Тип компании не выбран',
};

class AviaReport extends Component {
  static propTypes = {
    nameList: PropTypes.string,
    maxDate: PropTypes.object,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    labelButton: PropTypes.string,
    disabledButton: PropTypes.bool,
    onDownload: PropTypes.func.isRequired,
    dateStartPickerLabels: PropTypes.string,
    dateEndPickerLabels: PropTypes.string,
    renderOption: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.object,
    ]),
  };

  static defaultProps = {
    maxDate: null,
    startDate: getMoment(),
    endDate: getMoment(),
    nameList: '',
    labelButton: SEARCH.DOWNLOAD,
    renderOption: null,
    disabledButton: false,
    dateStartPickerLabels: SEARCH.START_DATE,
    dateEndPickerLabels: SEARCH.END_DATE,
  };

  constructor(props) {
    super(props);

    this.state = {
      waitingResponse: false,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      validateSubmit: true,
      tooltipMsg: '',
      selected: null,
      maxDateLimit: getMoment(),
    };
  }

  sendingRequestCreateReport = async () => {
    const { selected } = this.state;

    const currentCompanyRange = TYPE_AVIA.find(({ value }) => value === selected);

    if (currentCompanyRange) {
      const { onDownload } = this.props;
      const { startDate, endDate } = this.state;

      await onDownload(startDate, endDate, currentCompanyRange.valueEn);
    }
  };

  handleChangeDate = (field, value) => {
    if (!isMoment(value)) {
      return;
    }

    const { endDate } = this.state;

    if (isAfterDate(value, endDate)) {
      this.setState({ endDate: value });
    }

    return this.setState({ [field]: value });
  };

  toggleWaitingResponse = () => this.setState({ waitingResponse: !this.state.waitingResponse });

  validate = () => {
    const {
      startDate, endDate, selected,
    } = this.state;

    const diff = diffDays(startDate, endDate);
    const validateObj = { error: '' };

    if (diff > DAYS.HALF_MONTH) {
      validateObj.error = ERROR.DIFF;

      return validateObj;
    }

    if (selected === null) {
      validateObj.error = ERROR.COMPANY;

      return validateObj;
    }

    return validateObj;
  };

  handleChangeSelect = (value) => {
    this.setState({ selected: value.value });
  };

  renderEmpty = () => {
    const { nameList } = this.props;

    return nameList && nameList.length && (
      <div className={ styles.content }>
        <div className='empty-list'>{ nameList }</div>
      </div>
    );
  };

  render() {
    const {
      waitingResponse, startDate, endDate, selected, maxDateLimit,
    } = this.state;
    const {
      maxDate, labelButton, disabledButton, renderOption, dateStartPickerLabels, dateEndPickerLabels,
    } = this.props;
    const errorMsg = this.validate().error;
    const disabled = errorMsg.length > 0 || (disabledButton && isMoreThanThirtyOneDays(startDate, endDate));
    const optionHtml = renderOption || this.renderEmpty();

    return (
      <div className={ styles.main }>
        <div className={ styles.header }>
          <SearchDocumentForPrint
            labelButton={ labelButton }
            startDate={ startDate }
            endDate={ endDate }
            maxDate={ maxDate }
            disabled={ disabled }
            waitingResponse={ waitingResponse }
            tooltipMsg={ errorMsg }
            dateStartPickerLabels={ dateStartPickerLabels }
            dateEndPickerLabels={ dateEndPickerLabels }
            onChangeDate={ this.handleChangeDate }
            onDownload={ this.sendingRequestCreateReport }
            onChangeSelectedValue={ this.handleChangeSelect }
            selectedValue={ selected }
            maxDateLimit={ maxDateLimit }
            lableSelect={ LABELS.TYPE }
            items={ TYPE_AVIA }
          />
        </div>
        { optionHtml }
      </div>
    );
  }
}

export default AviaReport;
