import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, COMPONENTS } from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import Suggest from '../../../../components/Suggest';
import Input from '../../../../components/input';
import InputTime from '../../../../components/InputTime';

import {
  LIMITS_INTPUT,
  FIELDS,
  ROUTE,
  ROUTE_TEXT,
  UNDEF_VALUE_SEAT_SELECT,
} from '../../../../bi/constants/trips';
import {
  CAR_TYPES_DESCRIPTION,
  CAR_TYPES,
  CLASSES_SERVICE,
  SEAT_TYPES,
  VALID_SEAT_TYPES_CAR,
} from '../../../../bi/constants/trainDescription';
import { noLettersAndNumbers, noNumbers } from '../../../../bi/constants/regExp';

import lodashReplaces from '../../../../bi/utils/lodashReplaces';
import { isInputInvalid } from '../../../../bi/utils/trip';
import { momentObject } from '../../../../bi/utils/formatDate';

import styles from '../../styles/form.module.scss';

const MENUSTYLE = {
  position: 'absolute',
  left: 0,
  top: 51,
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
};

const LABELS = {
  ARRIVE: 'Прибытие по московскому времени',
  ARRIVE_LOCAL: 'Прибытие по местному времени',
  DEPARTURE: 'Отправление по местному времени',
  DEPARTURE_LOCAL: 'Отправление по московскому времени',
};

class Route extends Component {
  static propTypes = {
    tripService: PropTypes.object.isRequired,
    partEditItem: PropTypes.bool,
    train: PropTypes.object,
    isCopy: PropTypes.bool,
  };

  static defaultProps = {
    partEditItem: false,
    train: {},
    isCopy: false,
  };

  constructor(props) {
    super(props);
    const {
      validation, JsonData, stations,
    } = props.tripService.getTrainData();
    this.state = {
      validation,
      JsonData,
      stations,
      seatTypeValue: '',
      showSeatSelect: false,
    };
  }

  componentDidMount() {
    const {
      tripService, train: { isEdit }, isCopy,
    } = this.props;
    const { JsonData: { Route: { CarTypeDescription } } } = this.state;

    this.unsubscribeTrainData = tripService.subscribeTrain(this.updateTrainData);

    if (isEdit || isCopy) {
      this.showSeatTypeSelect(CarTypeDescription, ROUTE_TEXT.CAR_DESCRIPTION);
    }
  }

  componentWillUnmount() {
    this.unsubscribeTrainData();
  }

  updateTrainData = ({
    stations, validation, JsonData,
  }) => {
    this.setState({
      validation,
      stations,
      JsonData,
    });
  };

  handleChangeInput = (value, path) => {
    this.props.tripService.changeField(value, path);
  };

  handleChangeNumberInput = (e, path, limit) => {
    const { target: { value } } = e;
    const isSymbolInvalid = path === ROUTE.TRAIN_NUMBER ? noLettersAndNumbers.test(value) : noNumbers.test(value);

    if (isInputInvalid(value, isSymbolInvalid, limit)) {
      e.preventDefault();

      return;
    }

    this.props.tripService.changeField(value, `${FIELDS.JSON_DATA}.${path}`);
  };

  handleBlurInput = (value, path) => {
    const { tripService } = this.props;
    const isValid = tripService.trainTripFieldValidation(path, value);
    tripService.changeField(isValid, `${FIELDS.VALIDATION}.${path}`);
  };

  handleSelectAutocomplete = (value, item, fieldStation, pathJson) => {
    const { Name, Code } = item;
    const { tripService: { changeFields } } = this.props;

    const paths = [
      { value, path: `${FIELDS.STATIONS}.${fieldStation}.${FIELDS.LABEL}` },
      { value: Name, path: `${FIELDS.JSON_DATA}.${pathJson}` },
      { value: Code, path: `${FIELDS.JSON_DATA}.${pathJson}Code` },
    ];

    changeFields(paths);
  };

  handleChangeAutocomplete = (value, field, pathJson) => {
    const { tripService: { changeField, autocompleteTrainStation } } = this.props;
    changeField(value, `${FIELDS.JSON_DATA}.${pathJson}`);

    autocompleteTrainStation(value).then((res) => {
      changeField(res, `${FIELDS.STATIONS}.${field}.${FIELDS.SUGGESTS}`);
    });
  };

  handleSelect = (value, path, title) => {
    const { tripService } = this.props;

    tripService.changeField(value, `${FIELDS.JSON_DATA}.${path}`);
    const isValid = tripService.trainTripFieldValidation(path, value);

    const paths = [
      { value, path: `${FIELDS.JSON_DATA}.${path}` },
      { value: isValid, path: `${FIELDS.VALIDATION}.${path}` },
    ];

    this.showSeatTypeSelect(value, title);
    tripService.changeFields(paths);
  };

  showSeatTypeSelect = (value, title) => {
    const { train: { isEdit } } = this.props;
    const { JsonData: { Route: { SeatType } } } = this.state;

    const isTypeCarDescOption = title === ROUTE_TEXT.CAR_DESCRIPTION;

    if (isTypeCarDescOption) {
      const isValidOption = !!VALID_SEAT_TYPES_CAR.find(({ name }) => name === value);

      this.resetSeatSelect(isValidOption);

      this.setState({ showSeatSelect: isValidOption });
    }

    if (isEdit) {
      this.setState({ seatTypeValue: SeatType });
    }
  };

  handleSeatSelect = (value, path) => {
    this.handleSelect(value, path);

    this.setState({ seatTypeValue: value });
  };

  resetSeatSelect = (isValidOption) => {
    if (isValidOption) return;

    const { tripService } = this.props;

    tripService.changeField(UNDEF_VALUE_SEAT_SELECT, `${FIELDS.JSON_DATA}.${ROUTE.SEAT_TYPE}`);

    this.setState({ seatTypeValue: UNDEF_VALUE_SEAT_SELECT });
  };

  renderDate = (title, path) => {
    const { validation, JsonData } = this.state;
    const { partEditItem } = this.props;

    const valueData = lodashReplaces.getValueInObjByPath(JsonData, path);
    const valid = lodashReplaces.getValueInObjByPath(validation, path);

    const messageHtml = valid
      && <span className={ styles['error-msg'] }>{ valid }</span>;

    return (
      <div>
        <label>{ title }</label>
        <DatePicker
          disabled={ partEditItem }
          inputTheme={ COMPONENTS.DATEPICKER.INPUT_THEME.OPEN }
          value={ valueData }
          onChange={ ({ value }) => this.handleChangeInput(value, `${FIELDS.JSON_DATA}.${path}`) }
          onBlur={ (value) => this.handleBlurInput(value, path) }
          className={ `componentHook ${valid ? styles['no-valid'] : ''}` }
        />
        { messageHtml }
      </div>
    );
  };

  renderTime = (title, path) => {
    const { validation, JsonData } = this.state;
    const { partEditItem } = this.props;

    const valueData = lodashReplaces.getValueInObjByPath(JsonData, path);
    const valid = lodashReplaces.getValueInObjByPath(validation, path);

    const messageHtml = valid
      && <span className={ styles['error-msg'] }>{ valid }</span>;

    const valueTime = partEditItem ? momentObject(valueData) : valueData;

    return (
      <div>
        <InputTime
          disabled={ partEditItem }
          field={ `${FIELDS.JSON_DATA}.${path}` }
          label={ title }
          value={ valueTime }
          onChange={ (value) => this.handleChangeInput(value, `${FIELDS.JSON_DATA}.${path}`) }
          className={ `componentHook ${valid ? styles['no-valid'] : ''}` }
        />
        { messageHtml }
      </div>
    );
  };

  renderAutocomplete = (title, fieldStation, pathJson) => {
    const {
      validation, stations, JsonData,
    } = this.state;
    const { partEditItem } = this.props;

    const valid = lodashReplaces.getValueInObjByPath(validation, pathJson);
    const currentLabel = lodashReplaces.getValueInObjByPath(JsonData, pathJson);
    const route = partEditItem
      ? currentLabel
      : (
        <Autocomplete
          menuStyle={ MENUSTYLE }
          value={ currentLabel }
          items={ stations[fieldStation].suggests }
          getItemValue={ (item) => item.Name }
          onSelect={ (value, item) => this.handleSelectAutocomplete(value, item, fieldStation, pathJson) }
          onChange={ ({ target: { value } }) => this.handleChangeAutocomplete(value, fieldStation, pathJson) }
          renderItem={ (item) => (
            <div className={ styles['autocomplete-item'] }>
              { `${item.Name}` }
            </div>
          ) }
        />
      );

    const messageHtml = valid
      && <span className={ styles['error-msg'] }>{ valid }</span>;

    return (
      <div className={ `${styles['auto-input']}` }>
        <label>{ title }</label>
        { route }
        { messageHtml }
      </div>
    );
  };

  renderSelect = (title, content, path) => {
    const { validation, JsonData } = this.state;
    const { partEditItem, train: { JsonData: metaData } } = this.props;

    const inValid = lodashReplaces.getValueInObjByPath(validation, path);
    const currentLabel = lodashReplaces.getValueInObjByPath(JsonData, path);
    const isExist = partEditItem && !!JSON.parse(metaData)[path.split('.')[1]];
    const disabled = partEditItem && isExist;

    return (
      <Suggest
        disabled={ disabled }
        title={ title }
        valid={ inValid }
        suggests={ content }
        currentLabel={ currentLabel }
        onSelect={ ({ name }) => this.handleSelect(name, path, title) }
        withScroll
      />
    );
  };

  renderInput = (label, path, limit) => {
    const { validation, JsonData } = this.state;
    const { partEditItem } = this.props;

    const valueData = lodashReplaces.getValueInObjByPath(JsonData, path);
    const valid = lodashReplaces.getValueInObjByPath(validation, path);
    const disabled = partEditItem && path !== ROUTE.PLACE_NUMBER;

    return (
      <Input
        disabled={ disabled }
        value={ valueData }
        field={ path }
        label={ label }
        onChange={ (e, field) => this.handleChangeNumberInput(e, field, limit) }
        onBlur={ ({ target: { value } }) => this.handleBlurInput(value, path) }
        valid={ valid }
      />
    );
  };

  renderFirstRow = () => (
    <div className={ styles.row }>
      <div className={ styles.row }>
        <div className={ styles['col-1-2'] }>
          <span className={ styles['calendar-label'] }>{ LABELS.DEPARTURE }</span>
        </div>
        <div className={ styles['col-1-2'] }>
          <span className={ styles['calendar-label'] }>{ LABELS.DEPARTURE_LOCAL }</span>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-4'] }>
          { this.renderDate(ROUTE_TEXT.DATE, ROUTE.DATE_DEPARTURE_LOCAL) }
        </div>
        <div className={ styles['col-1-4'] }>
          { this.renderTime(ROUTE_TEXT.TIME, this.props.partEditItem
            ? ROUTE.DATE_DEPARTURE_LOCAL
            : ROUTE.TIME_DEPARTURE_LOCAL) }
        </div>
        <div className={ styles['col-1-4'] }>
          { this.renderDate(ROUTE_TEXT.DATE, ROUTE.DATE_DEPARTURE) }
        </div>
        <div className={ styles['col-1-4'] }>
          { this.renderTime(ROUTE_TEXT.TIME, this.props.partEditItem ? ROUTE.DATE_DEPARTURE : ROUTE.TIME_DEPARTURE) }
        </div>
      </div>
    </div>
  );

  renderSecondRow = () => (
    <div className={ styles.row }>
      <div className={ styles.row }>
        <div className={ styles['col-1-2'] }>
          <span className={ styles['calendar-label'] }>{ LABELS.ARRIVE }</span>
        </div>
        <div className={ styles['col-1-2'] }>
          <span className={ styles['calendar-label'] }>{ LABELS.ARRIVE_LOCAL }</span>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-4'] }>
          { this.renderDate(ROUTE_TEXT.DATE, ROUTE.DATE_ARRIVE) }
        </div>
        <div className={ styles['col-1-4'] }>
          { this.renderTime(ROUTE_TEXT.TIME, this.props.partEditItem ? ROUTE.DATE_ARRIVE : ROUTE.TIME_ARRIVE) }
        </div>
        <div className={ styles['col-1-4'] }>
          { this.renderDate(ROUTE_TEXT.DATE, ROUTE.DATE_ARRIVE_LOCAL) }
        </div>
        <div className={ styles['col-1-4'] }>
          { this.renderTime(ROUTE_TEXT.TIME, this.props.partEditItem
            ? ROUTE.DATE_ARRIVE_LOCAL
            : ROUTE.TIME_ARRIVE_LOCAL) }
        </div>
      </div>
    </div>
  );

  renderThirdRow = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-4'] }>
        { this.renderAutocomplete(ROUTE_TEXT.FROM, FIELDS.FROM, ROUTE.STATION_DEPART) }
      </div>
      <div className={ styles['col-1-4'] }>
        { this.renderAutocomplete(ROUTE_TEXT.TO, FIELDS.TO, ROUTE.STATION_ARRIVE) }
      </div>
      <div className={ styles['col-1-4'] }>
        { this.renderSelect(ROUTE_TEXT.CLASS_SERVICE, CLASSES_SERVICE, ROUTE.CLASS_SERVICE) }
      </div>
      <div className={ styles['col-1-4'] }>
        { this.renderSelect(ROUTE_TEXT.CAR_TYPE, CAR_TYPES, ROUTE.CAR_TYPE) }
      </div>
    </div>
  );

  renderFourthRow = () => (
    <div className={ styles.row }>
      <div className={ styles['col-1-4'] }>
        { this.renderInput(ROUTE_TEXT.TRAIN, ROUTE.TRAIN_NUMBER, LIMITS_INTPUT.SIX) }
      </div>
      <div className={ styles['col-1-4'] }>
        { this.renderInput(ROUTE_TEXT.CAR, ROUTE.CAR_NUMBER, LIMITS_INTPUT.TWO) }
      </div>
      <div className={ styles['col-1-4'] }>
        { this.renderSelect(ROUTE_TEXT.CAR_DESCRIPTION, CAR_TYPES_DESCRIPTION, ROUTE.CAR_TYPE_DESCRIPTION) }
      </div>
      <div className={ styles['col-1-4'] }>
        { this.renderInput(ROUTE_TEXT.PLACE, ROUTE.PLACE_NUMBER, LIMITS_INTPUT.THREE) }
      </div>
    </div>
  );

  renderFivehRow = () => {
    const { showSeatSelect, seatTypeValue } = this.state;

    if (!showSeatSelect) return null;

    const selectOptionsHtml = SEAT_TYPES.map(({
      id, value, label,
    }) => <option key={ id } value={ value }>{ label }</option>);

    return (
      <div className={ styles.row }>
        <div className={ styles['col-1-4'] }>
          <div className={ styles.select }>
            <div className={ styles.wrap }>
              <label>{ ROUTE_TEXT.PLACE_TYPE }</label>
              <select
                value={ seatTypeValue }
                onChange={ ({ target }) => this.handleSeatSelect(target.value, ROUTE.SEAT_TYPE) }
              >
                { selectOptionsHtml }
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        <div className={ styles.row }>
          <div className={ `${styles.row} ${styles.title}` }>
            <h4>{ ROUTE_TEXT.TITLE }</h4>
          </div>
        </div>
        { this.renderFirstRow() }
        { this.renderSecondRow() }
        { this.renderThirdRow() }
        { this.renderFourthRow() }
        { this.renderFivehRow() }
      </div>
    );
  }
}

export default Route;
