import React from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';

import WorkplaceService from './bi/services/workplace/service';
import AppService from './bi/services/app/service';
import FeatureFlagsService from './bi/services/featureFlags/service';
import NotificationsService from './bi/services/notifications/service';
import FilterService from './bi/services/filter/service';
import SearchUsersService from './bi/services/seacrhUsers/service';
import HotelService from './bi/services/hotels/service';
import RegionService from './bi/services/regions/service';

import { LeftMenu } from './modules/menu/components';
import { STORE_NAMES } from './bi/constants/store';
import { Notifications } from './modules/notifications/notifications';
import { HeaderComponents } from './modules/header/components';

const getStore = (stores, name) => stores.find((store) => store && store.storeName === name);

const LABELS = { HOME: 'Домашняя' };

const App = ({ stores, navigate }) => {
  const workplaceService = new WorkplaceService(getStore(stores, STORE_NAMES.WORKSPACE));
  const appService = new AppService(getStore(stores, STORE_NAMES.APP));
  const featureFlagsService = new FeatureFlagsService(getStore(stores, STORE_NAMES.FEATURE_FLAGS));
  const notificationsService = new NotificationsService(getStore(stores, STORE_NAMES.NOTIFICATIONS));
  const filterService = new FilterService(getStore(stores, STORE_NAMES.FILTER), getStore(stores, STORE_NAMES.APP));
  const searchUsersService = new SearchUsersService(getStore(stores, STORE_NAMES.SEARCH_USERS));
  const hotelsService = new HotelService(getStore(stores, STORE_NAMES.HOTELS));
  const regionsService = new RegionService(getStore(stores, STORE_NAMES.REGIONS));

  const renderMenu = () => (
    <div id='aside' className='app-aside'>
      <LeftMenu
        workplaceService={ workplaceService }
        appService={ appService }
        featureFlagsService={ featureFlagsService }
        navigate={ navigate }
      />
    </div>
  );

  const renderHome = () => (
    <div className='empty-list'>
      { LABELS.HOME }
    </div>
  );

  const renderContent = () => (
    <div id='content' className='app-content'>
      <div id='sw-header' className='app-header'>
        <HeaderComponents
          filterService={ filterService }
          searchUsersService={ searchUsersService }
          hotelsService={ hotelsService }
          regionsService={ regionsService }
        />
      </div>

      <div id='page' className='app-body'>
        <BrowserRouter basename='/'>
          <Routes>
            <Route path='/' Component={ renderHome } />
          </Routes>
        </BrowserRouter>
      </div>

      <div id='sw-notifications'>
        <Notifications notificationsService={ notificationsService } />
      </div>
    </div>
  );

  return (
    <div className='app-wrapper'>
      { renderMenu() }
      { renderContent() }
    </div>
  );
};

App.propTypes = {
  stores: PropTypes.array.isRequired,
  navigate: PropTypes.func.isRequired,
};

const renderApp = (stores, navigate) => <App stores={ stores } navigate={ navigate } />;

export { renderApp };
