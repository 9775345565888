import React from 'react';
import PropTypes from 'prop-types';

import useDisplayingBonusCardsData from './hooks/useDisplayingBonusCardsData';

import styles from './styles.module.css';

const LABELS = { TITLE: 'Бонусные карты' };

const BonusCards = ({ bonuses, bonusesEnum }) => {
  const bonusCards = useDisplayingBonusCardsData(bonuses, bonusesEnum);

  if (!bonusCards.length) {
    return null;
  }

  return (
    <div className={ styles.section }>
      <h2>{ LABELS.TITLE }</h2>
      { bonusCards.map(({ name, number }, index) => (
        <div className={ styles['info-row'] } key={ index }>
          <label>{ name }</label>
          <span>{ number }</span>
        </div>
      )) }

    </div>
  );
};

BonusCards.propTypes = {
  bonuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  bonusesEnum: PropTypes.shape({
    Air: PropTypes.arrayOf(PropTypes.shape({})),
    Train: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export default BonusCards;
