import PropTypes from 'prop-types';
import React, { Component } from 'react';
import DatePicker from 'react-datetime';
import { Dialog } from 'sw-ui';

import { ACCOUNTNAMES, ACCOUNTNAMESRU } from '../../../../../bi/constants/account';
import COMPONENTS from '../../../../../bi/constants/components';
import AutocompleteWithButton from '../../../../../components/AutocompleteWithButton';
import { FlatButton } from '../../../../../components/button';

import DecisionButtons from '../../../../../components/DecisionButtons';
import Input from '../../../../../components/input';
import Suggest from '../../../../../components/Suggest';
import { DEFAULTDATE } from '../../../../../constants/time';

import { formatDate, momentDate } from '../../../../../utils/date';

import styles from '../../styles/account.module.scss';

const MAXACCOUNTLENGTH = 100;
const WIDTH = '400px';
const LOCALE = 'ru';
const LABELS = {
  ERROR: { ADD_TO_GROUP_ERR: 'связь установить невозможно' },
  NOINDICATED: 'не указано',
  BUTTON_LABELS: { DELETE_BUTTON: 'Удалить' },
};

export default class EditAccountNameDialog extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    account: PropTypes.object.isRequired,
    accountAutocomplete: PropTypes.func.isRequired,
    addCompanyToGroup: PropTypes.func.isRequired,
    removeAccountFromCompaniesGroup: PropTypes.func.isRequired,
    companiesGroup: PropTypes.object.isRequired,
    getAccountSupports: PropTypes.func.isRequired,
  };

  static defaultProps = { companiesGroup: {} };

  constructor(props) {
    super(props);
    const { companiesGroup } = props;

    this.state = {
      accountInfo: {
        Name: props.account.Name,
        CreateDate: props.account.CreateDate,
        SoldBy: props.account.SoldBy,
        SoldByMICE: props.account.SoldByMICE,
        SupportedBy: props.account.SupportedBy,
        suggestItems: [],
        accountAutocomplete: '',
        currentAccountGroup: null,
        submittedAccountGroup: companiesGroup.Accounts || [],
        GroupName: companiesGroup.GroupName || '',
        companiesGroup,
      },
      showEditAccountNameDialog: false,
      error: '',
      accountSupports: [],
    };
  }

  async componentDidMount() {
    const { getAccountSupports } = this.props;
    const accountSupports = await getAccountSupports();
    const mappedAccountSupports = accountSupports.map(({ Email, SupportId }) => ({ name: Email, id: SupportId }));
    this.setAccountSupports(mappedAccountSupports);
  }

  setAccountSupports = (accountSupports) => this.setState((state) => ({ ...state, accountSupports }));

  handleDeleteObject = (items, index) => [
    ...items.slice(0, index),
    ...items.slice(index + 1),
  ];

  handleChange = (event, field, value) => {
    let data = value;
    if (field === ACCOUNTNAMES.SOLDBY || field === ACCOUNTNAMES.SOLDBY_MICE) {
      data = event.target.value.length < MAXACCOUNTLENGTH ? event.target.value : this.state.accountInfo[field];
    }

    this.setState({
      ...this.state,
      accountInfo: {
        ...this.state.accountInfo,
        [field]: data,
      },
    });
  };

  handleChangeAutocomplete = (field, value) => {
    this.setState({
      ...this.state,
      accountInfo: {
        ...this.state.accountInfo,
        accountAutocomplete: value,
      },
    }, async () => {
      const data = await this.props.accountAutocomplete(value);
      this.setState({
        ...this.state,
        accountInfo: {
          ...this.state.accountInfo,
          suggestItems: data,
          accountAutocomplete: value,
        },
      });
    });
  };

  handleSelectAutocompleteItem = (field, value) => {
    this.setState({
      ...this.state,
      accountInfo: {
        ...this.state.accountInfo,
        accountAutocomplete: field,
        currentAccountGroup: value,
      },
      error: '',
    });
  };

  handleSubmitButton = () => {
    const { currentAccountGroup, submittedAccountGroup } = this.state.accountInfo;
    const { addCompanyToGroup, account: { Id } } = this.props;
    const newGroup = currentAccountGroup ? [...submittedAccountGroup, currentAccountGroup] : [...submittedAccountGroup];

    addCompanyToGroup(newGroup[newGroup.length - 1].AccountId, Id, newGroup)
      .then((result) => {
        if (result === null) {
          this.setState({
            ...this.state,
            accountInfo: {
              ...this.state.accountInfo,
              submittedAccountGroup: newGroup,
              accountAutocomplete: '',
              companiesGroup: {
                ...this.state.accountInfo.companiesGroup,
                Accounts: newGroup,
              },
            },
          });
        } else {
          this.setState({
            ...this.state,
            accountInfo: {
              ...this.state.accountInfo,
              submittedAccountGroup,
              accountAutocomplete: '',
            },
            error: LABELS.ERROR.ADD_TO_GROUP_ERR,
          });
        }
      })
      .catch(() => {
        this.setState({
          ...this.state,
          error: LABELS.ERROR.ADD_TO_GROUP_ERR,
        });
      });
  };

  handleRemoveCompanyFromGroup = (index) => {
    const { removeAccountFromCompaniesGroup } = this.props;
    const submittedGroup = [...this.state.accountInfo.submittedAccountGroup];

    const newGroup = this.handleDeleteObject(submittedGroup, index);

    removeAccountFromCompaniesGroup(submittedGroup[index].AccountId, newGroup)
      .then((result) => {
        if (result === null) {
          this.setState({
            ...this.state,
            accountInfo: {
              ...this.state.accountInfo,
              submittedAccountGroup: newGroup,
              companiesGroup: {
                ...this.state.companiesGroup,
                Accounts: newGroup,
              },
            },
          });
        }
      });
  };

  renderSelectedCompanies = () => {
    const { Accounts } = this.props.companiesGroup;

    return Accounts
      ? Accounts.map(({ AccountId, AccountName }, index) => (
        <div className={ styles.addedCompanies } key={ index }>
          <div className={ styles['company-name'] }>{ `${AccountName || LABELS.NOINDICATED} (${AccountId})` }</div>
          <div className={ styles['col-1-5'] }>
            <div className={ styles['add-conditions'] }>
              <FlatButton
                label={ LABELS.BUTTON_LABELS.DELETE_BUTTON }
                onClick={ () => this.handleRemoveCompanyFromGroup(index) }
              />
            </div>
          </div>
        </div>
      ))
      : null;
  };

  handleChangeCreateDate = (value) => this.setState({
    accountInfo: {
      ...this.state.accountInfo,
      CreateDate: value ? momentDate(value) : '',
    },
  });

  handleChangeGroupName = (event, field, value) => {
    this.setState({
      ...this.state,
      accountInfo: {
        ...this.state.accountInfo,
        companiesGroup: {
          ...this.state.accountInfo.companiesGroup,
          [field]: value,
        },
        [field]: value,
      },
    });
  };

  render() {
    const {
      onClose, onSave, companiesGroup,
    } = this.props;
    const { accountInfo, accountSupports } = this.state;
    const {
      Name,
      CreateDate,
      SoldBy,
      SupportedBy,
      accountAutocomplete,
      suggestItems,
      currentAccountGroup,
      GroupName,
      SoldByMICE,
    } = accountInfo;

    const renderCompanies = currentAccountGroup || companiesGroup ? this.renderSelectedCompanies() : null;
    const createDate = CreateDate ? formatDate(CreateDate) : '';
    const mapedItems = () => suggestItems.filter((item) => item.AccountName);

    return (
      <Dialog onClick={ onClose } width={ WIDTH }>
        <div className={ `${styles.form} ${styles['company-dialog']}` }>
          <div className={ `${styles.row} ${styles.title}` }>
            <div className={ styles.col }>
              Редактирование аккаунта
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <Input
                field={ ACCOUNTNAMES.ACCOUNTNAME }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ Name }
                label={ ACCOUNTNAMESRU[ACCOUNTNAMES.ACCOUNTNAME] }
                onChange={ this.handleChange }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <Input
              field={ ACCOUNTNAMES.GROUPNAME }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ GroupName }
              label={ ACCOUNTNAMESRU[ACCOUNTNAMES.GROUPNAME] }
              onChange={ this.handleChangeGroupName }
            />
          </div>
          <div className={ styles.row }>
            <AutocompleteWithButton
              inputValue={ accountAutocomplete }
              items={ mapedItems() }
              onInputChange={ (event, value) => this.handleChangeAutocomplete(ACCOUNTNAMES.ACCOUNTAUTOCOMPLETE, value) }
              onSelect={ this.handleSelectAutocompleteItem }
              onClick={ this.handleSubmitButton }
              disableButton={ false }
              valid={ this.state.error }
            />
          </div>
          <div className={ styles.row }>
            { renderCompanies }
          </div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <label>{ ACCOUNTNAMESRU[ACCOUNTNAMES.CREATEDATE] }</label>
              <DatePicker
                placeholder={ ACCOUNTNAMES.CREATEDATE }
                dateFormat={ DEFAULTDATE }
                timeFormat=''
                locale={ LOCALE }
                value={ createDate }
                onChange={ this.handleChangeCreateDate }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <Input
                field={ ACCOUNTNAMES.SOLDBY }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ SoldBy }
                label={ ACCOUNTNAMESRU[ACCOUNTNAMES.SOLDBY] }
                onChange={ this.handleChange }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <Suggest
                suggests={ accountSupports }
                onSelect={ ({ name }) => this.handleChange(null, ACCOUNTNAMES.SUPPORTEDBY, name) }
                title={ ACCOUNTNAMESRU[ACCOUNTNAMES.SUPPORTEDBY] }
                currentLabel={ SupportedBy || '' }
              />
            </div>
          </div>
          <div className={ styles.row }>
            <div className={ styles.col }>
              <Input
                field={ ACCOUNTNAMES.SOLDBY_MICE }
                type={ COMPONENTS.INPUT.TYPE.TEXT }
                value={ SoldByMICE }
                label={ ACCOUNTNAMESRU[ACCOUNTNAMES.SOLDBY_MICE] }
                onChange={ this.handleChange }
              />
            </div>
          </div>
          <DecisionButtons
            loadingIncluded
            onCancel={ onClose }
            onSave={ () => onSave(accountInfo) }
          />
        </div>
      </Dialog>
    );
  }
}

