import ACTIONS from '../../actions';
import Api from '../../api';
import UserValidator from './validator/user';

import { BRAND_NAME } from '../../constants/account';

import mapUserUrlFilterToUserStatus from './mapper';

let store = null;

class UserService {
  constructor() {
    store = this.getStore('User');

    this.validator = { user: new UserValidator() };

    this.aggregatorIds = [
      {
        value: 0, label: 'По умолчанию', select: true,
      },
      {
        value: 19202, label: 'Петролкард', select: false,
      },
      {
        value: 20431, label: 'Контур тревел', select: false,
      },
      {
        value: 35748, label: 'ЦКР', select: false,
      },
      {
        value: 38980, label: 'РусГидро', select: false,
      },
      {
        value: 40048, label: 'ТЕМП-Ростех', select: false,
      },
    ];
  }

  load(filter) {
    const status = mapUserUrlFilterToUserStatus(filter);

    return Api.Users.getUsers(status).then((res) => {
      store.dispatch({
        type: ACTIONS.USER.INIT,
        res,
      });
    });
  }

  getUserRoles() {
    return Api.Users.getUserRoles();
  }

  registerUser(user) {
    return Api.Users.registerUser(user);
  }

  registerSAUser = async ({
    Name, Phone, Email,
  }) => {
    const data = {
      user_name: Name,
      phone_number: Phone,
      account_id: 0,
      email: Email,
    };

    try {
      await Api.Users.registerSAUser(data);
    } catch (e) {
      // console.error(e);
      // добавить сценарий на падение роута при раскрытии релиза признака KZ
    }
  };


  registerKZUser = async ({
    Name, Phone, Email,
  }) => {
    const data = {
      user_name: Name,
      phone_number: Phone,
      account_id: 0,
      email: Email,
      brand_name: BRAND_NAME.SMARTWAY_KZ,
    };

    await Api.Users.registerKZUser(data);
    // добавить сценарий на падение роута при раскрытии релиза признака KZ
  };

  registerSAAutoUser = async ({
    Name, Phone, Email,
  }) => {
    const data = {
      user_name: Name,
      phone_number: Phone,
      account_id: 0,
      email: Email,
      brand_name: BRAND_NAME.SMARTAGENT_AUTO,
    };

    await Api.Users.registerSAAutoUser(data);
  };

  approveUser(userId, email) {
    return Api.Users.approveUser(userId, email);
  }

  rejectUser(userId) {
    return Api.Users.rejectUser(userId);
  }

  get() {
    return store.getState();
  }

  subscribe(callback) {
    return store.subscribe(callback);
  }

  userFieldValidation(field, value) {
    return this.validator.user.isValid(field, value);
  }

  getAggregatorIds() {
    return this.aggregatorIds;
  }
}

export default UserService;
