import React from 'react';

import MoneyFormat from '../../bi/utils/money';
import { dateWithoutCurrentYear } from '../../bi/utils/formatDate';

import { SERVICETYPE, SERVICETYPEICON } from '../../bi/constants/serviceType';
import { STATUSRU } from '../../bi/constants/trips';
import { HOTELPROVIDERNAME } from '../../bi/constants/hotel';

import styles from './styles/bookTrip.module.scss';

const LABELS = {
  VATUNKNOWN: ' НДС неизвестен',
  VAT: 'НДС',
  CITYUNKNOWN: 'город неизвестен',
};

const bookTripItem = (
  key,
  {
    Employee,
    VAT,
    Type,
    Direction,
    City,
    ProviderName,
    Id,
    CheckInDate,
    CheckOutDate,
    Price,
    Status,
  },
) => {
  const employeesHtml = Employee.map((employee, index) => (
    <div key={ index }>
      { employee }
    </div>
  ));

  const providerName = HOTELPROVIDERNAME[ProviderName];
  const vat = isNaN(VAT) ? LABELS.VATUNKNOWN : ` (${LABELS.VAT} = ${MoneyFormat.symbolWithMoneyWithDecimal(VAT)})`;

  const directionText = Type === SERVICETYPE.HOTEL
    ? `${Direction} (${City || LABELS.CITYUNKNOWN} ${providerName ? ',' : ''} ${providerName || ''})`
    : Direction;

  const renderIcon = () => {
    if (Type === SERVICETYPE.AEROEXPRESS) {
      return (<div key={ key } className={ `smartway-aeroexpress-temp ${styles['icon-services']}` } />);
    }

    if (Type === SERVICETYPE.VIP_HALL) {
      return (
        <div className={ `smartway-vip-hall ${styles['icon-services']}` } />
      );
    }

    return (
      <i className={ `material-icons ${styles['icon-services']}` }>
        { SERVICETYPEICON[Type] }
      </i>
    );
  };

  const iconHtml = renderIcon();

  return (
    <div key={ key } className={ `${styles.row} ${styles.item}` }>
      <div className={ styles['col-1-10'] }>
        { Id }
        { iconHtml }
      </div>
      <div className={ styles['col-1-10'] }>
        { employeesHtml }
      </div>
      <div className={ styles['col-1-3'] }>{ directionText }</div>
      <div className={ styles['col-1-7'] }>
        { dateWithoutCurrentYear(CheckInDate) }
        -
        { dateWithoutCurrentYear(CheckOutDate) }
      </div>
      <div className={ styles['col-1-5'] }>
        <div>
          { MoneyFormat.symbolWithMoneyWithDecimal(Price) }
        </div>
        <div>
          { vat }
        </div>
      </div>
      <div className={ styles['col-1-10'] }>
        { STATUSRU[Status] }
      </div>
    </div>
  );
};

export default bookTripItem;
