import moment from 'moment';
import trimTimezone from './trimTimezone';
import parseUnix from './parseDateTime';

import {
  DEFAULTDATE,
  DAYMONTHPATTERN,
  DEFAULTTIME,
  DAYDAYPATTERN,
  FORMATDATETIME,
  MONTHPATTERN,
  FORMAT_DATE_FULL,
  DAY_OF_MONTH_WITH_TIME,
  DATE_TIME_WITH_YEAR,
  DATE_SECOND_SHORT_TIME,
  FULLTIME,
} from '../../constants/time';

const formatDate = (value, pattern = DEFAULTDATE) => moment(value).format(pattern);

const formatDataValue = (value, pattern = DEFAULTDATE) => (moment(value, pattern, true).isValid()
  ? value.format(pattern)
  : false);

const momentObject = (value) => moment(value);

const dayOff = (value) => value.endOf('day');

const dayStart = (value) => value.startOf('day');

const isSameOrAfterMonthMomentObject = (first, second) => second.isSameOrAfter(first, 'month');

const isSameOrBeforeMonthMomentObject = (first, second) => first.isSameOrBefore(second, 'month');

const getThirdMonthFromCurrent = (current) => moment(current).add(2, 'month');

const getFirstDayOfMonth = (value) => moment(value).startOf('month');

const getFirstDayOfYear = () => moment().startOf('year');

const getEndDayOfYear = () => moment().endOf('year');

const getLastDayOfMonth = (value) => moment(value).endOf('month');

const isValidMomentObject = (value) => (value ? momentObject(value).isValid() : false);

const isMoment = (value) => moment.isMoment(value);

const isSameDate = (first, second) => moment(first).isSame(moment(second));

const isSameDateByDay = (first, second) => moment(first).isSame(moment(second), 'day');

const isSameDateBySecond = (
  first, second, pattern = FORMAT_DATE_FULL,
) => moment(first, pattern).isSame(moment(second, pattern), 'second');

const isSameOrAfter = (first, second) => moment(first).isSameOrAfter(moment(second));

const isSameOrBefore = (first, second) => moment(first).isSameOrBefore(moment(second));

const isBetweenDate = (
  first, second, third, granularity = 'day', inclusivity = '[]',
) => moment(first).isBetween(second, third, granularity, inclusivity);

const differenceMonths = (first, second) => second.diff(first, 'month') + 1;

const diffDays = (start, end) => end.diff(start, 'days');

const isMoreThanThirtyOneDays = (start, end) => diffDays(start, end) > 30;

const getMoment = () => moment();

const startOfMonth = () => getMoment().startOf('M');

const dateSubtruct = (count) => moment().subtract(count, 'day');

const compareDateWithToday = (date, pattern = FORMAT_DATE_FULL) => moment().diff(moment(date, pattern));

const dateWithoutCurrentYear = (value) => {
  const currentYear = momentObject().year();
  const year = momentObject(value).year();
  const pattern = currentYear === year ? DAYMONTHPATTERN : DEFAULTDATE;

  return formatDate(value, pattern);
};

const dateLessThanOtherDate = (start, end) => moment.duration(momentObject(start).diff(momentObject(end))).asSeconds();

const dateLessThanOtherDateByDays = (start, end) => moment.duration(start.diff(end, 'days')).asSeconds();

const subDate = (count, type = 'days') => moment().subtract(count, type);

const earlyDay = (value) => getMoment().startOf('day').subtract(value, 'days');

const trimTimeZone = (date) => {
  if (typeof date === 'string') {
    return trimTimezone(date);
  }

  return date;
};

const timeWithoutMoment = (value, pattern = DEFAULTTIME) => parseUnix(value).format(pattern);

const dateWithoutMoment = (value, pattern = DEFAULTDATE) => parseUnix(value).format(pattern);

const weekWithoutMoment = (value, pattern = DAYDAYPATTERN) => parseUnix(value).format(pattern);

const dateInterval = (minDate, maxDate, pattern = DEFAULTDATE) => {
  if (minDate
    .clone()
    .startOf('day')
    .isSame(maxDate.clone().startOf('day'))) {
    return minDate.format(pattern);
  }

  return `${minDate.format(pattern)} - ${maxDate.format(pattern)}`;
};

const dateWithInterval = (minDate, maxDate) => {
  const firstDate = formatDate(minDate);
  const secondDate = formatDate(maxDate);

  return `${firstDate} - ${secondDate}`;
};

const dateUtcFormat = (value, pattern = FORMATDATETIME) => moment.utc(value).format(pattern);

const dateToUtc = (value) => moment.utc(value);

const dateParseZone = (value) => moment.parseZone(value);

const dateAddMinutes = (value, amount) => moment(value).add(amount, 'minutes');

const isBeforeDate = (firstValue, secondValue) => moment(firstValue).isBefore(moment(secondValue));

const isAfterDate = (firstValue, secondValue) => moment(firstValue).isAfter(moment(secondValue));

const isMonthAfter = (firstValue, secondValue) => moment(firstValue).month() < moment(secondValue).month();

const isBeforeDateParameter = (firstValue, secondValue) => moment(firstValue).isBefore(moment(secondValue), 'day');

const getYesterday = (current) => moment(current).add(-1, 'days');

const getLastMonthInPrepositionalCase = (currentDate = getMoment()) => currentDate.format(MONTHPATTERN);

const datePrevMonth = (date) => subDate(1, 'months').date(date);

const minutesToMoment = (value) => {
  const hours = Math.floor(value / 60);
  const minutes = value % 60;

  return getMoment().hours(hours).minutes(minutes);
};

const momentSetHoursAndMinutes = (momentValue, hours, minutes) => momentObject(momentValue)
  .set('hour', hours)
  .set('minute', minutes);

const momentHours = (value) => moment(value).hours();

const momentMinutes = (value) => moment(value).minutes();

const momentAddHoursMinutes = (value, hours, minutes) => moment(value)
  .hours(hours)
  .minutes(minutes);

const getDefaultMoment = () => momentSetHoursAndMinutes(getMoment(), 0, 0);

const getYear = (value) => value.year();

const getDate = (value) => value.date();

const getDateValue = (value) => getMoment().date(value);

const getStartDateDocumenents = (value = 15) => (getDate(getMoment()) > value
  ? (getFirstDayOfMonth())
  : (datePrevMonth(value + 1)));

const getEndDateDocumenents = (value = 15) => (getDate(getMoment()) > value
  ? (getDateValue(value))
  : (subDate(1, 'months').endOf('month')));

const getTimeFromTimezoneFormat = (value) => value.split('T')[1].split(':')[0] + value.split('T')[1].split(':')[1];

const getTimeFromSpaceFormat = (value) => value.split(' ')[1].split(':')[0] + value.split(' ')[1].split(':')[1];

const momentSubPeriod = (current, value, pattern) => momentObject(current).subtract(value, pattern);

const prevMonthFirstDay = () => moment().subtract(1, 'months').startOf('month');
const prevMonthLastDay = () => moment().subtract(1, 'months').endOf('month');

const isCurrentYear = (value) => {
  const currentYear = moment().year();
  const dateObject = moment(value);

  return currentYear === dateObject.year();
};

const getPatternDateWithMinutes = (value) => (
  isCurrentYear(value) ? DAY_OF_MONTH_WITH_TIME : DATE_TIME_WITH_YEAR
);

const formatDateWithMinutes = (value) => formatDate(value, getPatternDateWithMinutes(value));

const timerAdder = (selectedTime, units) => moment().add(selectedTime, units).format(DATE_SECOND_SHORT_TIME);

const dateEqual = (firstDate, secondDate, format = 'M') => firstDate.format(format) !== secondDate.format(format);

const hoursSubtract = (value, hours) => moment(value).subtract(hours, 'hours');

const getMonthsDifference = (first, second) => {
  const startDate = momentObject(first);
  const endDate = momentObject(second);

  return differenceMonths(startDate, endDate);
};

const dateWithUtcOffset = (value, pattern = FULLTIME) => {
  const date = moment(value).utcOffset(3);

  return `${date.format(pattern)}+03:00`;
};

export {
  dateEqual,
  prevMonthFirstDay,
  prevMonthLastDay,
  getStartDateDocumenents,
  getEndDateDocumenents,
  getDateValue,
  getLastMonthInPrepositionalCase,
  formatDate,
  formatDataValue,
  momentObject,
  isSameOrAfterMonthMomentObject,
  isSameOrBeforeMonthMomentObject,
  isValidMomentObject,
  getThirdMonthFromCurrent,
  getLastDayOfMonth,
  getFirstDayOfMonth,
  getFirstDayOfYear,
  getEndDayOfYear,
  differenceMonths,
  isMoment,
  isSameDate,
  isSameDateByDay,
  isSameDateBySecond,
  isSameOrAfter,
  isSameOrBefore,
  isBetweenDate,
  isMoreThanThirtyOneDays,
  startOfMonth,
  dateSubtruct,
  compareDateWithToday,
  getMoment,
  dateWithoutCurrentYear,
  dateLessThanOtherDate,
  dateLessThanOtherDateByDays,
  subDate,
  earlyDay,
  trimTimeZone,
  timeWithoutMoment,
  dateWithoutMoment,
  weekWithoutMoment,
  dateInterval,
  dateWithInterval,
  dateUtcFormat,
  dateToUtc,
  dateParseZone,
  dateAddMinutes,
  isBeforeDate,
  isAfterDate,
  isBeforeDateParameter,
  getYesterday,
  diffDays,
  datePrevMonth,
  minutesToMoment,
  momentSetHoursAndMinutes,
  momentHours,
  momentMinutes,
  getDefaultMoment,
  getYear,
  getDate,
  getTimeFromTimezoneFormat,
  getTimeFromSpaceFormat,
  momentSubPeriod,
  formatDateWithMinutes,
  momentAddHoursMinutes,
  dayOff,
  dayStart,
  timerAdder,
  isMonthAfter,
  hoursSubtract,
  getMonthsDifference,
  dateWithUtcOffset,
};
