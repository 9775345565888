import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input } from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import { BackButton } from './BackButton';
import { NextButton } from './NextButton';

import { setSelectMenuStyle } from '../../../../bi/utils/account';
import { isEmail } from '../../../../bi/utils/emailValidator';

import { EXCEL, TYPE_SERVICE } from '../../../../bi/constants/unloading1c';

import styles from '../../styles.module.css';

const LABELS = {
  SELECT_PRINCIPAL: 'Выберите принципала',
  SEARCH_PRINCIPAL: 'Поиск принципала',
  ENTER_EMAIL: 'Введите email',
  ERROR_EMAIL: 'Некорректный email',
  SEND_XML: 'Отправить XML на email',
  LOAD_FILE_EXCEL: 'Загрузить другой файл Excel',
};

const StepFour = ({
  unloading1cService: {
    get,
    setPrincipal,
    getPrincipals,
    sendSettings,
    setEmail,
  },
  onHandleStepOne,
  onHandlePrevStep,
}) => {
  const { settings, settings: { itemKind, fileExportSettings: { email } } } = get();

  const [checkedExcel, setCheckedExcel] = useState(false);
  const [principals, setPrincipals] = useState({ label: '', suggests: [] });
  const [loading, setLoading] = useState(false);
  const [emailValue, setEmailValue] = useState(email);
  const [emailError, setEmailError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const checkEmail = !emailValue || !!emailError;
  const isDisabled = itemKind === TYPE_SERVICE[1].value
    ? checkEmail
    : (!checkedExcel && !principals.label) || loading || checkEmail;

  const handleChangeCheckbox = (value) => {
    if (value) {
      setPrincipal(EXCEL.value);
      setPrincipals((state) => ({
        ...state, label: '', suggests: [],
      }));
    }

    setCheckedExcel(value);
  };

  const handleSelectSuggest = (_, { Id, Code }) => {
    setPrincipals((state) => ({ ...state, label: Code }));
    setPrincipal(Id);
  };

  const handleChangeSuggest = async (value) => {
    setPrincipals((state) => ({ ...state, label: value }));

    const result = await getPrincipals({ query: value });

    setPrincipals((state) => ({ ...state, suggests: result }));
  };

  const handleChangeEmail = (value) => {
    setEmailError('');
    setEmailValue(value);
    setEmail(value);
  };

  const handleRequest = async () => {
    if (!isEmail(emailValue)) {
      return setEmailError(LABELS.ERROR_EMAIL);
    }

    setEmailError('');
    setErrorMessage('');
    setLoading(true);

    try {
      const { Data } = await sendSettings(settings);

      setSuccessMessage(Data);
    } catch (error) {
      const { Error: { Message } } = error.body;

      setErrorMessage(Message);
    }

    setLoading(false);
  };

  const renderSelectPrincipals = () => {
    if (itemKind === TYPE_SERVICE[1].value) {
      return null;
    }

    return (
      <>
        <div className={ styles.title }>
          { LABELS.SELECT_PRINCIPAL }
        </div>
        <Checkbox
          onChange={ () => handleChangeCheckbox(!checkedExcel) }
          value={ checkedExcel }
        >
          { EXCEL.label }
        </Checkbox>
        <div className={ styles.autocomplete_input }>
          <Autocomplete
            menuStyle={ setSelectMenuStyle(principals.suggests) }
            value={ principals.label }
            items={ principals.suggests }
            getItemValue={ ({ Code }) => Code }
            onSelect={ (cValue, value) => handleSelectSuggest(cValue, value) }
            onChange={ ({ target: { value } }) => handleChangeSuggest(value) }
            renderItem={ renderItem }
            inputProps={ {
              placeholder: LABELS.SEARCH_PRINCIPAL, autoFocus: true, disabled: checkedExcel,
            } }
          />
        </div>
      </>
    );
  };

  const renderItem = ({ Code }) => {
    if (!Code) {
      return null;
    }

    return (
      <div className={ styles.autocomplete_item } key={ Code }>
        { Code }
      </div>
    );
  };

  const renderError = () => {
    if (!errorMessage) return null;

    return (
      <span className='error-msg'>
        { errorMessage }
      </span>
    );
  };

  const renderSuccess = () => {
    if (!successMessage) return null;

    return (
      <span className={ styles.success_message }>
        { successMessage }
      </span>
    );
  };

  return (
    <div className={ styles.content }>
      { renderSelectPrincipals() }
      <div className={ styles.title }>
        { LABELS.ENTER_EMAIL }
      </div>
      <Input
        placeholder={ LABELS.ENTER_EMAIL }
        value={ emailValue }
        onChange={ handleChangeEmail }
        error={ emailError }
      />
      <div className={ `${styles.modal_buttons} ${styles.last_step}` }>
        <BackButton
          onHandlePrevStep={ onHandlePrevStep }
        />
        <NextButton
          onHandleNextStep={ handleRequest }
          loading={ loading }
          disabled={ isDisabled }
          label={ LABELS.SEND_XML }
        />
        { renderError() }
        { renderSuccess() }
        <NextButton
          onHandleNextStep={ onHandleStepOne }
          label={ LABELS.LOAD_FILE_EXCEL }
        />
      </div>
    </div>
  );
};

StepFour.propTypes = {
  unloading1cService: PropTypes.object.isRequired,
  onHandlePrevStep: PropTypes.func.isRequired,
  onHandleStepOne: PropTypes.func.isRequired,
};

export { StepFour };
