import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from 'sw-ui';

import Input from '../../../../components/input';
import EmployeeSuggest from '../../../../components/employee';
import Checkbox from '../../../../components/checkbox';
import AjaxButton from '../../../../components/ajaxButton';
import NoResult from '../../../../components/NoResult';
import Vat from './components/Vat';
import Price from './components/Price';
import ProviderInfoSelect from './components/ProviderInfoSelect';

import { getPassportType, getPassportDueDate } from '../../../../bi/utils/passport';
import { isMandatory } from '../../../../bi/utils/account';

import { FIELDS, TRIPSLABELS } from '../../../../bi/constants/trips';
import { ERRORSFORALL } from '../../../../bi/constants/tripValidator';
import COMPONENTS from '../../../../bi/constants/components';
import AIRPROVIDERS from '../../../../bi/constants/arline';
import FLYCLASS, { VALUE_PROPERTY } from '../../../../bi/constants/airline';

import SelectProject from '../selectProject';
import SelectDepartment from '../selectDepartment';

import styles from '../../styles/form.module.scss';
import { isShowTaxes, isShowTaxesClue } from '../../../../bi/utils/air';

const FORMATDATE = 'DD-MM-YYYY HH:mm';

const GET_INFO_ENTER_FIELDS = {
  TICKET_NUMBER: 'Введите номер билета',
  TICKET_NUMBER_EMPLOYEE_PASSPORT: 'Дожен быть заполнен сотрудник, паспорт и номер билета',
  TICKET_PNR: 'Дожен быть заполнен PNR',
  TICKET_PNR_NUMBER: 'Дожен быть заполнен номер билета и PNR',
  TICKER_ALL: 'Дожен быть заполнен сотрудник, паспорт и номер билета и PNR',
};

const LABELS_GET_INFO = {
  [AIRPROVIDERS.AMADEUS.TYPE]: GET_INFO_ENTER_FIELDS.TICKER_ALL,
  [AIRPROVIDERS.SSEVEN.TYPE]: GET_INFO_ENTER_FIELDS.TICKET_NUMBER,
  [AIRPROVIDERS.AEROFLOT.TYPE]: GET_INFO_ENTER_FIELDS.TICKET_PNR_NUMBER,
  [AIRPROVIDERS.TAIS.TYPE]: GET_INFO_ENTER_FIELDS.TICKET_NUMBER_EMPLOYEE_PASSPORT,
  [AIRPROVIDERS.MIXVEL.TYPE]: GET_INFO_ENTER_FIELDS.TICKET_NUMBER,
};

const GET_INFO_ERRORS = {
  ERROR_NUMBER: 'Не удалось загрузить информацию, неверный номер билета',
  ERROR_MANY_FIELDS: 'Не удалось загрузить информацию, PNR и фамилия не совпадают',
  ERROR_PNR: 'Не удалось загрузить информацию, PNR не совпадает',
  ERROR_NUMBER_PNR: 'Не удалось загрузить информацию, неверный номер билета или номер PNR',
};

const LABELS = {
  SELECT_OFFICE_ID: 'Выберите OfficeID провайдера',
  SELECT_SALE_POINT_ID: 'Выберите ППР провайдера',
  OFFICE_ID: 'Office Id',
  SALE_POINT: 'ППР',
};

const renderSegmentInfo = (segment, isBaggage = false) => {
  const baggageHtml = isBaggage
    ? (
      <span>
        Номер багажа:
        { segment.Baggage }
      </span>
    )
    : null;

  return (
    <div className={ styles['segment-info--full'] }>
      <span>
        Страна вылета:
        { segment.DepartureCountry }
      </span>
      <span>
        Город вылета:
        { segment.DepartureCity }
      </span>
      <span>
        Аэропорт вылета:
        { segment.DepartureAirport.Code }
        { ' ' }
        { segment.DepartureAirport.Name }
      </span>
      <span>
        Страна прилета:
        { segment.ArrivalCountry }
      </span>
      <span>
        Город прилета:
        { segment.ArrivalCity }
      </span>
      <span>
        Аэропорт прилета:
        { segment.ArrivalAirport.Code }
        { ' ' }
        { segment.ArrivalAirport.Name }
      </span>
      <span>
        Маркетинговая авиакомпания:
        { segment.MarketingAirline.Code }
        { ' ' }
        { segment.MarketingAirline.Name }
      </span>
      <span>
        Операционная авиакомпания:
        { segment.OperatingAirline.Code }
        { ' ' }
        { segment.OperatingAirline.Name }
      </span>
      <span>
        Время вылета:
        { moment(segment.DepartureDate).format(FORMATDATE) }
      </span>
      <span>
        Время прилета:
        { moment(segment.ArrivalDate).format(FORMATDATE) }
      </span>
      <span>
        Терминал вылета:
        { segment.DepartureTerminal }
      </span>
      <span>
        Терминал прилета:
        { segment.ArrivalTerminal }
      </span>
      <span>
        Самолет:
        { segment.AircraftName }
      </span>
      <span>
        Номер рейса:
        { segment.FlightNumber }
      </span>
      { baggageHtml }
      <span>
        Класс:
        { segment.Class }
      </span>
    </div>
  );
};

const renderRoutes = ({
  airService,
  key,
  employees,
  providerNameOptions,
  ticket,
  airline,
  handleChangeInput,
  handlerRemoveEmployee,
  onSelectEmployee,
  onChangeEmployee,
  onEmployeeFocus,
  routes,
  segments,
  handleChangeCheckboxTicket,
  handleChangeCheckboxSegment,
  handleChangeVatDetails,
  onSelectProject,
  onSelectDepartment,
  projects,
  ProjectId,
  DepartmentId,
  departments,
  employeeId,
  renderGetInfo,
  getInfoError,
  handleSetInfoError,
  mandatoryProject,
  validation,
}) => {
  const selectedEmployee = ticket.Travellers ? ticket.Travellers : {};

  const renderGetInfoError = () => {
    const { ProviderName } = ticket;

    if (getInfoError.length === 0) return null;

    switch (ProviderName) {
      case AIRPROVIDERS.AMADEUS.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError() }
            message={ GET_INFO_ERRORS.ERROR_NUMBER }
          />
        );
      }
      case AIRPROVIDERS.SSEVEN.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError() }
            message={ GET_INFO_ERRORS.ERROR_NUMBER }
          />
        );
      }
      case AIRPROVIDERS.AEROFLOT.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError() }
            message={ GET_INFO_ERRORS.ERROR_NUMBER_PNR }
          />
        );
      }
      case AIRPROVIDERS.TAIS.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError() }
            message={ GET_INFO_ERRORS.ERROR_NUMBER_PNR }
          />
        );
      }
      case AIRPROVIDERS.MIXVEL.TYPE: {
        return (
          <NoResult
            onClose={ () => handleSetInfoError() }
            message={ GET_INFO_ERRORS.ERROR_NUMBER }
          />
        );
      }
      default:
        return null;
    }
  };

  let selectHtml = null;
  if (ticket.Travellers && ticket.Travellers.Documents.length) {
    const passportHtml = ticket.Travellers
      ? ticket.Travellers.Documents.map((passport) => {
        const passportType = getPassportType(ticket.Travellers.Code, passport.Type);
        const passportDueDate = getPassportDueDate(passport.DueDate);
        const passportDueDateHtml = passportDueDate ? `до ${passportDueDate}` : '';

        return (
          <option
            key={ passport.Id }
            value={ passport.Id }
          >
            { `${passportType} -  ${passport.Number} ${passportDueDateHtml}` }
          </option>
        );
      })
      : null;


    const passportValue = ticket.Passport ? (ticket.Passport.PassportId ? ticket.Passport.PassportId : '') : '';

    selectHtml = (
      <select
        value={ passportValue }
        onChange={ (e) => handleChangeInput(key, null, null, e, 'Passport.PassportId', e.target.value) }
      >
        { passportHtml }
      </select>
    );
  } else {
    selectHtml = (
      <span className={ styles['no-valid'] }>У сотрудника нет паспорта</span>
    );
  }

  let routesHtml = null;
  if (ticket.Routes.length > 1) {
    routesHtml = ticket.Routes.map((route, ind) => {
      let segmentsHtml = null;
      if (route.Segments.length > 1) {
        segmentsHtml = route.Segments.map((segment, i) => {
          const segmentHtml = (
            <div className={ styles['segment-info'] } key={ ind }>
              { renderSegmentInfo(segment) }
              <div className={ styles['col-1-4'] }>
                <Input
                  field='Baggage'
                  value={ segment.Baggage }
                  label='Номер багажа'
                  onChange={ (...props) => handleChangeInput(key, ind, i, ...props) }
                />
              </div>
            </div>
          );

          return (
            <div className={ styles['segment-info'] } key={ i }>
              <Checkbox
                field='Segment'
                label={ `Сегмент №${(i + 1)}` }
                value={ segments.indexOf(segment) !== -1 }
                key={ i }
                onChange={ (e, field, value) => handleChangeCheckboxSegment(key, ind, i, value, segment) }
              >
                { segmentHtml }
              </Checkbox>
            </div>
          );
        });
      } else {
        segmentsHtml = route.Segments.map((segment, index) => (
          <div className={ `${styles['segment-info']} ${styles['alone-segment']}` } key={ ind }>
            <span className={ styles['route-label'] }>Сегмент №1</span>
            <div className={ styles['route-info'] } key={ index }>
              { renderSegmentInfo(segment) }
              <div className={ styles['col-1-4'] }>
                <Input
                  field='Baggage'
                  value={ segment.Baggage }
                  label='Номер багажа'
                  onChange={ (...props) => handleChangeInput(key, ind, index, ...props) }
                />
              </div>
            </div>
          </div>
        ));
      }

      const routeHtml = (
        <div className={ styles['route-info'] }>
          <span>
            Время полета:
            { route.Duration }
            { ' ' }
            мин.
          </span>
          <div>
            { segmentsHtml }
          </div>
        </div>
      );

      return (
        <Checkbox
          field='Route'
          label={ `Маршрут №${(ind + 1)}` }
          value={ routes.indexOf(route) !== -1 }
          key={ ind }
          onChange={ (e, field, value) => handleChangeCheckboxTicket(key, ind, value, route) }
        >
          { routeHtml }
        </Checkbox>
      );
    });
  } else {
    routesHtml = ticket.Routes.map((route, ind) => {
      let segmentsHtml = null;
      if (route.Segments.length > 1) {
        segmentsHtml = route.Segments.map((segment, i) => {
          const segmentHtml = (
            <div className={ styles['segment-info'] } key={ ind }>
              { renderSegmentInfo(segment, true) }
            </div>
          );

          return (
            <div className={ styles['segment-info'] } key={ i }>
              <Checkbox
                field='Segment'
                label={ `Сегмент №${(i + 1)}` }
                value={ segments.indexOf(segment) !== -1 }
                key={ i }
                onChange={ (e, field, value) => handleChangeCheckboxSegment(key, ind, i, value, segment) }
              >
                { segmentHtml }
              </Checkbox>
            </div>
          );
        });
      } else {
        segmentsHtml = route.Segments.map((segment, index) => (
          <div className={ `${styles['segment-info']} ${styles['alone-segment']}` } key={ ind }>
            <span className={ styles['route-label'] }>Сегмент №1</span>
            <div className={ styles['route-info'] } key={ index }>
              { renderSegmentInfo(segment, true) }
            </div>
          </div>
        ));
      }

      return (
        <div key={ ind }>
          <span className={ styles['route-label'] }>Маршрут №1</span>
          <div className={ styles['route-info'] }>
            <span>
              Время полета:
              { route.Duration }
              { ' ' }
              мин.
            </span>
          </div>
          <div>
            { segmentsHtml }
          </div>
        </div>
      );
    });
  }

  const selectProvideNameHtml = () => {
    const { ProviderName, ProviderInfo } = ticket;

    const handelSelectProviderName = (e) => {
      if (ProviderName === AIRPROVIDERS.AMADEUS.TYPE && ProviderInfo) {
        handleChangeInput(key, null, null, null, FIELDS.PROVIDER_INFO.OFFICE_ID, null);
      }

      handleChangeInput(key, null, null, e, FIELDS.PROVIDERNAME, e.target.value);
    };

    const optionsSelevtor = (
      providerNameOptions.map((options, providerKey) => (
        <option key={ `${providerKey}_name` } value={ options.value }>{ options.label }</option>
      ))
    );

    return (
      <select
        value={ ProviderName }
        onChange={ handelSelectProviderName }
      >
        <option key='' value='' disabled>Выберите провайдера</option>
        { optionsSelevtor }
      </select>
    );
  };

  const selectProviderInfoHtml = (
    type,
    labelSelect,
    field,
    infoType,
    airProviderSelectorFunc,
    selectLabel,
  ) => {
    const { ProviderName, ProviderInfo } = ticket;

    if (ProviderName !== type) {
      return null;
    }

    return (
      <ProviderInfoSelect
        providerInfo={ ProviderInfo }
        providerName={ ProviderName }
        labelSelect={ labelSelect }
        field={ field }
        k={ key }
        infoType={ infoType }
        airProviderSelectorFunc={ airProviderSelectorFunc }
        selectLabel={ selectLabel }
        handleChangeInput={ handleChangeInput }
      />
    );
  };

  const selectProvideOfficeIdHtml = () => selectProviderInfoHtml(
    AIRPROVIDERS.AMADEUS.TYPE,
    LABELS.OFFICE_ID,
    FIELDS.PROVIDER_INFO.OFFICE_ID,
    VALUE_PROPERTY.OFFICE_ID,
    airService.getAirProviderOfficeIdForSelect,
    LABELS.SELECT_OFFICE_ID,
  );

  const selectProviderSalePointHtml = () => selectProviderInfoHtml(
    AIRPROVIDERS.MIXVEL.TYPE,
    LABELS.SALE_POINT,
    FIELDS.PROVIDER_INFO.SALE_POINT,
    VALUE_PROPERTY.SALE_POINT,
    airService.getAirProviderSalePointForSelect,
    LABELS.SELECT_SALE_POINT_ID,
  );

  const renderVat = () => (
    <Vat
      keyItem={ key }
      airline={ airline }
      ticket={ ticket }
      handleChangeVatDetails={ handleChangeVatDetails }
    />
  );

  const selectOptionsHtml = Object.keys(FLYCLASS)
    .map((flyClassKey) => <option key={ flyClassKey } value={ flyClassKey }>{ FLYCLASS[flyClassKey] }</option>);

  const validEmployee = selectedEmployee.Id ? '' : 'Добавьте сотрудника';
  const projectMassage = projects.length
    ? airService.airTripFieldValidationAir(FIELDS.PROJECTID, ProjectId)
    : ERRORSFORALL.NO_PROJECTS;

  const validProject = mandatoryProject ? projectMassage : '';
  const showTaxes = isShowTaxes(ticket);
  const showTaxesClue = isShowTaxesClue(ticket);

  const validProviderNameHtml = validation && validation.ProviderName
    ? (
      <span className={ `${styles['error-msg-company']} error-msg` }>{ validation.ProviderName }</span>
    )
    : null;

  return (
    <div className={ styles.wrap } key={ key }>
      <div className={ `${styles.row} ${styles.title}` }>
        <h5>
          Билет №
          { (key + 1) }
        </h5>
        <div className={ styles.row }>
          { routesHtml }
        </div>
      </div>
      <div className={ styles.row }>
        <SelectProject
          currentProjectId={ ProjectId }
          projects={ projects }
          onSelect={ onSelectProject }
          mandatoryProject={ mandatoryProject }
          validationProject={ validProject }
        />
        <SelectDepartment
          departments={ departments }
          onSelect={ onSelectDepartment }
          currentDepartmentId={ DepartmentId }
          currentEmployeeId={ employeeId }
        />
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-3'] }>
          <EmployeeSuggest
            items={ employees }
            selected={ selectedEmployee }
            placeholder='Сотрудники'
            onRemove={ () => handlerRemoveEmployee({ ticketInd: key }) }
            onSelect={ (employee) => onSelectEmployee({ ticketInd: key, employee }) }
            onFocus={ () => onEmployeeFocus({ ticketInd: key }) }
            valid={ validEmployee }
            onChange={ onChangeEmployee }
          />
        </div>
        <div className={ styles['col-1-3'] }>
          <div className={ styles.select }>
            <div className={ styles.wrap }>
              <label>Паспорт</label>
              { selectHtml }
            </div>
          </div>
        </div>
        <div className={ styles['col-1-3'] }>
          <div className={ styles.select }>
            <div className={ styles.wrap }>
              <label>Класс перелета</label>
              <select
                value={ ticket.Class }
                onChange={ (e) => handleChangeInput(key, null, null, e, 'Class', e.target.value) }
              >
                { selectOptionsHtml }
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className={ styles.row }>
        <div className={ styles['col-1-5'] }>
          <Input
            field='Num'
            value={ ticket.Num }
            label='Номер билета'
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
            valid={ validation && validation.Num }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field='PNR'
            value={ ticket.PNR }
            label='PNR'
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field='PNRProvider'
            value={ ticket.PNRProvider }
            label='PNRProvider'
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <Input
            field='PNRLocator'
            value={ ticket.PNRLocator }
            label='PNRLocator'
            onChange={ (...props) => handleChangeInput(key, null, null, ...props) }
          />
        </div>
        <div className={ styles['col-1-5'] }>
          <div className={ styles.select }>
            <div className={ `${styles.wrap} ${validation && validation.ProviderName ? styles['no-valid'] : ''}` }>
              <label>Провайдер</label>
              { selectProvideNameHtml() }
            </div>
            <div>
              { validProviderNameHtml }
              { selectProvideOfficeIdHtml() }
              { selectProviderSalePointHtml() }
              { renderGetInfo(ticket, selectedEmployee) }
            </div>
          </div>
        </div>
      </div>
      <Price
        showTaxes={ showTaxes }
        showTaxesClue={ showTaxesClue }
        isEditing={ false }
        priceDetails={ ticket.PriceDetails }
        onChangeInput={ (...props) => handleChangeInput(key, null, null, ...props) }
        validation={ validation }
      />
      { renderVat() }
      { renderGetInfoError() }
    </div>
  );
};

renderRoutes.propTypes = {
  airService: PropTypes.object.isRequired,
  key: PropTypes.number.isRequired,
  employees: PropTypes.array.isRequired,
  providerNameOptions: PropTypes.array.isRequired,
  ticket: PropTypes.object.isRequired,
  airline: PropTypes.object.isRequired,
  handleChangeInput: PropTypes.func.isRequired,
  handlerRemoveEmployee: PropTypes.func.isRequired,
  onSelectEmployee: PropTypes.func.isRequired,
  onChangeEmployee: PropTypes.func.isRequired,
  onEmployeeFocus: PropTypes.func.isRequired,
  renderGetInfo: PropTypes.func.isRequired,
  handleSetInfoError: PropTypes.func.isRequired,
  getInfoError: PropTypes.func.isRequired,
  routes: PropTypes.array.isRequired,
  segments: PropTypes.array.isRequired,
  handleChangeCheckboxTicket: PropTypes.func.isRequired,
  handleChangeCheckboxSegment: PropTypes.func.isRequired,
  handleChangeVatDetails: PropTypes.func.isRequired,
  onSelectProject: PropTypes.func.isRequired,
  onSelectDepartment: PropTypes.func.isRequired,
  projects: PropTypes.arrayOf(PropTypes.object),
  ProjectId: PropTypes.number.isRequired,
  DepartmentId: PropTypes.number.isRequired,
  departments: PropTypes.array.isRequired,
  employeeId: PropTypes.number.isRequired,
  mandatoryProject: PropTypes.bool.isRequired,
};

class PartEditTicket extends Component {
  static propTypes = {
    tickets: PropTypes.array.isRequired,
    employees: PropTypes.array,
    providerNameOptions: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    handlerRemoveEmployee: PropTypes.func.isRequired,
    onSelectEmployee: PropTypes.func.isRequired,
    onEmployeeFocus: PropTypes.func.isRequired,
    onChangeEmployee: PropTypes.func.isRequired,
    onSelectProject: PropTypes.func.isRequired,
    handleChangeVatDetails: PropTypes.func.isRequired,
    handlerAddPart: PropTypes.func.isRequired,
    handlerDeletePart: PropTypes.func.isRequired,
    handleGetInfo: PropTypes.func.isRequired,
    handleSetInfoError: PropTypes.func.isRequired,
    airService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    companies: PropTypes.array.isRequired,
    ProjectId: PropTypes.number.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object),
    airline: PropTypes.object,
    departments: PropTypes.array,
    employeeId: PropTypes.number,
    waitingResponse: PropTypes.bool.isRequired,
    getInfoError: PropTypes.string,
    mandatoryProject: PropTypes.array.isRequired,
    validation: PropTypes.object,
  };

  static defaultProps = {
    projects: [],
    airline: {},
    departments: [],
    employees: [],
    getInfoError: '',
    employeeId: 0,
    validation: {},
  };

  constructor(props) {
    super(props);

    const routes = props.tickets.reduce((arrRoutes, ticket) => [...arrRoutes, ...ticket.Routes], []);
    const segments = routes.reduce((arrSegments, route) => [...arrSegments, ...route.Segments], []);
    const newMandatory = isMandatory(props.mandatoryProject, props.airline.CompanyId || 0);

    this.state = {
      routes,
      segments,
      mandatoryProject: newMandatory,
      CompanyId: props.airline.CompanyId || 0,
      DepartmentId: props.airline.DepartmentId || 0,
    };
  }

  handleChangeInput = (ticketInd, routeInd, segmentInd, event, field, value) => {
    this.props.onChange({
      ticketInd,
      routeInd,
      segmentInd,
      field,
      value,
    });
  };

  handleBlurInput = (...props) => this.props.onBlur(...props);

  handleChangeCheckboxTicket = (ticketInd, routeInd, value, item) => {
    const routes = this.state.routes;
    const result = routes.indexOf(item);

    if (value) {
      routes.push(item);
    } else {
      routes.splice(result, 1);
    }

    this.setState({ routes });

    this.props.onChange({
      ticketInd,
      routeInd,
      segmentInd: null,
      field: 'HasValue',
      value,
    });
  };

  handleChangeCheckboxSegment = (ticketInd, routeInd, segmentInd, value, item) => {
    const segments = this.state.segments;
    const result = segments.indexOf(item);

    if (value) {
      segments.push(item);
    } else {
      segments.splice(result, 1);
    }

    this.setState({ segments });

    this.props.onChange({
      ticketInd,
      routeInd,
      segmentInd,
      field: 'HasValue',
      value,
    });
  };

  handleSelectDepartment = ({ id }) => this.setState({ DepartmentId: id });

  renderGetInfo = (ticket) => {
    const {
      ProviderName,
      Num,
      PNR,
      Travellers,
    } = ticket;
    const { waitingResponse } = this.props;
    const documents = (Travellers && typeof (Travellers) !== 'number' && Travellers.Documents[0]) || null;
    const Lastname = documents && documents.LastName;
    const Surname = documents && documents.Surname;

    if (ProviderName !== AIRPROVIDERS.AMADEUS.TYPE
      && ProviderName !== AIRPROVIDERS.SSEVEN.TYPE
      && ProviderName !== AIRPROVIDERS.AEROFLOT.TYPE
      && ProviderName !== AIRPROVIDERS.TAIS.TYPE
      && ProviderName !== AIRPROVIDERS.MIXVEL.TYPE) {
      return null;
    }

    let disabled = !Num;

    switch (ProviderName) {
      case AIRPROVIDERS.AEROFLOT.TYPE: {
        disabled = !Num || !PNR;

        break;
      }
    }

    const tooltip = disabled && (
      <Tooltip position={ COMPONENTS.TOOLTIP.POSITION.BOTTOM }>
        { LABELS_GET_INFO[ProviderName] }
      </Tooltip>
    );

    return (
      <div className={ styles.button_info }>
        <div className='sw-tooltip-wrapper'>
          <AjaxButton
            disabled={ disabled }
            label={ TRIPSLABELS.GET_DATA }
            loading={ waitingResponse }
            onClick={ () => this.props.handleGetInfo(ProviderName, Num, PNR, Surname, Lastname) }
          />
          { tooltip }
        </div>
      </div>
    );
  };

  render() {
    const {
      tickets,
      employees,
      providerNameOptions,
      onSelectEmployee,
      onChangeEmployee,
      onSelectProject,
      onEmployeeFocus,
      handleChangeVatDetails,
      handlerRemoveEmployee,
      handlerAddPart,
      handlerDeletePart,
      handleSetInfoError,
      airService,
      tripService,
      companies,
      projects,
      departments,
      airline,
      employeeId,
      getInfoError,
      ProjectId,
      validation,
    } = this.props;

    const {
      routes,
      segments,
      CompanyId,
      DepartmentId,
      mandatoryProject,
    } = this.state;

    let addHtml = null;
    if (handlerAddPart) {
      addHtml = (
        <div
          className={ `${styles.icon} ${styles.icon__AwaitingTicketing}` }
          onClick={ () => handlerAddPart({ type: 'ticket' }) }
        >
          <i
            className='material-icons'
            title='Добавить заказ'
          >
            add
          </i>
          Добавить заказ
        </div>
      );
    }

    let deleteHtml = null;
    if (handlerDeletePart) {
      deleteHtml = (
        <div
          className={ `${styles.icon} ${styles.icon__AwaitingTicketing}` }
          onClick={ () => handlerDeletePart({ type: 'ticket' }) }
        >
          <i
            className='material-icons'
            title='Удалить последний заказ'
          >
            clear
          </i>
          Удалить последний заказ
        </div>
      );
    }

    const html = tickets.map((ticket, index) => {
      const routeHtml = renderRoutes({
        key: index,
        handleChangeInput: this.handleChangeInput,
        ticket,
        employees,
        providerNameOptions,
        onSelectEmployee,
        onChangeEmployee,
        onEmployeeFocus,
        handlerRemoveEmployee,
        handlerAddPart,
        handlerDeletePart,
        handleBlurInput: this.handleBlurInput,
        airService,
        tripService,
        routes,
        segments,
        handleChangeCheckboxTicket: this.handleChangeCheckboxTicket,
        handleChangeCheckboxSegment: this.handleChangeCheckboxSegment,
        onSelectProject,
        onSelectDepartment: this.handleSelectDepartment,
        renderGetInfo: this.renderGetInfo,
        handleSetInfoError,
        handleChangeVatDetails,
        companies,
        projects,
        CompanyId,
        ProjectId,
        DepartmentId,
        airline,
        departments,
        employeeId,
        getInfoError,
        mandatoryProject,
        validation: validation[index],
      });

      return (
        <div className={ styles.wrap } key={ index }>
          { routeHtml }
        </div>
      );
    });

    return (
      <div>
        <div className={ styles.row }>
          { html }
        </div>
        <div className={ styles.row }>
          { addHtml }
          { deleteHtml }
        </div>
      </div>
    );
  }
}

export default PartEditTicket;
