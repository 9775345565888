import React from 'react';
import PropTypes from 'prop-types';

import { dateWithoutCurrentYear } from '../../../../bi/utils/formatDate';

import LinkToNewTab from '../../../../components/LinkToNewTab';

import { SERVICETYPE, SERVICETYPEICON } from '../../../../bi/constants/serviceType';

import MICE_IMG from '../../../../app/styles/img/calendar_mice.svg';
import IMG_BUS from '../../../../app/styles/img/bus_icon.png';


import styles from '../styles/trip.module.css';

const Trip = ({
  item, companyId, accountId, isEmployeeTrip,
}) => {
  const colClass = isEmployeeTrip ? styles['col-1-7'] : styles['col-1-8'];
  const serviceHtml = item.Services.map((service, key) => {
    switch (service) {
      case SERVICETYPE.AEROEXPRESS:
        return <div key={ key } className='smartway-aeroexpress-temp' />;
      case SERVICETYPE.VIP_HALL:
        return <div key={ key } className='smartway-vip-hall' />;
      case SERVICETYPE.AIR_ADDITIONAL_SERVICE:
        return <div className={ `airplane_ticket ${styles['icon-services']}` } />;
      case SERVICETYPE.EVENT:
        return <img className={ styles.mice } src={ MICE_IMG } alt='MICE' />;
      case SERVICETYPE.BUS:
        return <img className={ styles.bus } src={ IMG_BUS } alt='BUS' />;

      default:
        return (
          <i
            className={ `material-icons ${styles['icon-services']}` }
            key={ key }
          >
            { SERVICETYPEICON[service] }
          </i>
        );
    }
  });

  const employeesHtml = item.Employees.map((employee, ind) => (
    <div key={ ind }>
      { employee }
    </div>
  ));

  const statusCol = !isEmployeeTrip
    && (
      <div className={ colClass }>
        { item.StatusRu }
      </div>
    );

  return (
    <LinkToNewTab to={ `/account/${accountId}/company/${companyId}/trip/${item.Id}` }>
      <div className={ `${styles.row} ${styles.click}` }>
        <div className={ colClass }>
          { item.Id }
        </div>
        <div className={ colClass }>
          { dateWithoutCurrentYear(item.CheckInDate) }
        </div>
        <div className={ colClass }>
          { dateWithoutCurrentYear(item.CheckOutDate) }
        </div>
        <div className={ colClass }>
          { item.Name }
        </div>
        <div className={ `${colClass} ${styles['data-services']}` }>
          { serviceHtml }
        </div>
        <div className={ colClass }>
          { employeesHtml }
        </div>
        <div className={ colClass }>
          { item.Price }
        </div>
        { statusCol }
      </div>
    </LinkToNewTab>
  );
};

Trip.propTypes = {
  item: PropTypes.object,
  accountId: PropTypes.number.isRequired,
  companyId: PropTypes.number.isRequired,
  isEmployeeTrip: PropTypes.bool,
};

Trip.defaultProps = {
  item: {},
  isEmployeeTrip: false,
};

export default Trip;
