import { pickValue } from '../../../utils/trip';
import { isMoment } from '../../../utils/formatDate';

import { ERRORSFORALL, ERRORS_FOR_INSURANCE } from '../../../../bi/constants/tripValidator';

const requiredField = (value) => Boolean(value.length);

const requiredPrice = (value) => value && value >= 0;

const requiredDate = (value) => isMoment(value) && value.isValid();

const validationObj = {
  Base: {
    fn: pickValue,
    msg: ERRORSFORALL.NO_BASE,
  },
  Commission: {
    fn: requiredPrice,
    msg: ERRORSFORALL.NOCOMISSION,
  },
  CompanyId: {
    fn: requiredPrice,
    msg: ERRORSFORALL.NOCOMPANY,
  },
  Description: {
    fn: requiredField,
    msg: ERRORSFORALL.NODESCRIPTION,
  },
  Name: {
    fn: requiredField,
    msg: ERRORS_FOR_INSURANCE.NO_NAME,
  },
  Direction: {
    fn: requiredField,
    msg: ERRORS_FOR_INSURANCE.NO_DIRECTION,
  },
  CheckinDate: {
    fn: requiredDate,
    msg: ERRORSFORALL.RESERVATION_DATE,
  },
  CheckoutDate: {
    fn: requiredDate,
    msg: ERRORSFORALL.RESERVATION_DATE,
  },
  Amount: {
    fn: requiredPrice,
    msg: ERRORSFORALL.NO_BASE,
  },
  SelectedItems: {
    fn: requiredField,
    msg: ERRORS_FOR_INSURANCE.NO_SELECTED_ITEMS,
  },
  SelectedRate: {
    fn: requiredField,
    msg: ERRORS_FOR_INSURANCE.NO_SELECTED_RATE,
  },
};

class InsuranceValidator {
  isValid(field, value) {
    let validator = '';
    let validationFn = '';

    validator = validationObj[field];
    validationFn = validator.fn;

    if (!validationFn(value)) {
      return validator.msg;
    }

    return '';
  }
}

export default InsuranceValidator;
