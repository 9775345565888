import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'sw-ui';

import COMPONENTS from '../../../../bi/constants/components';

import EditPlacementIssuance from './dialogs/editPlacementIssuance';

import styles from './styles/index.module.css';

const LABEL = 'Изменить ограничения гостиниц';

class SetingsPlacementIssuance extends Component {
  static propTypes = {
    accountService: PropTypes.object.isRequired,
    accountId: PropTypes.number.isRequired,
    isHotelChange: PropTypes.bool.isRequired,
  };

  state = { showDialog: false };

  toggleEdit = () => this.setState({ showDialog: !this.state.showDialog });

  renderDialog = () => {
    const { showDialog } = this.state;
    const {
      accountService,
      accountId,
      isHotelChange,
    } = this.props;

    if (!showDialog) return null;

    return (
      <EditPlacementIssuance
        accountService={ accountService }
        accountId={ accountId }
        onClose={ this.toggleEdit }
        isHotelChange={ isHotelChange }
      />
    );
  };

  render() {
    const dialogHtml = this.renderDialog();

    return (
      <div className={ styles.row }>
        <Button
          label={ LABEL }
          theme={ COMPONENTS.BUTTON.THEME.FLAT }
          onClick={ this.toggleEdit }
        />
        { dialogHtml }
      </div>
    );
  }
}

export default SetingsPlacementIssuance;
