import React from 'react';
import PropTypes from 'prop-types';
import styles from './documentIcon.module.css';

const DocumentIcon = ({
  type, onClick, className,
}) => (
  <button className={ styles.button } onClick={ onClick } >
    <div className={ `document-icon document-icon-${type} ${styles.icon} ${className || ''}` } />
  </button>
);

DocumentIcon.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string.isRequired,
};

DocumentIcon.defaultProps = {
  onClick: () => {},
  className: '',
};

export default DocumentIcon;

