import renderComponents from './components.jsx';

const NAME = 'sw-login';

class LoginModule {
  constructor(name, context, opts) {
    this.name = name;
    this.box = opts.box;
    this.context = context;

    this.page = renderComponents(this.box, { login: this.login.bind(this) });
  }

  login(user) {
    const workplaceService = this.context.getService('Workplace');

    return workplaceService.login(user).then(() => {
      this.context.navigate('/');
      window.location.reload();
    });
  }

  destroy() {
    this.page.unmount();
  }
}

export default function (core) {
  core.addModule(NAME, LoginModule);
}
