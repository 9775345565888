import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Tooltip } from 'sw-ui';

import { requiredLength } from '../../../../../bi/utils/checkValidation';

import Input from '../../../../../components/input';
import DecisionButtons from '../../../../../components/DecisionButtons';

import {
  USER_FIELD,
  USER_FIELD_RU,
} from '../../../../../bi/constants/profile';
import COMPONENTS from '../../../../../bi/constants/components';
import { TEXT } from '../../../../../bi/constants/hotels';

import styles from '../../../styles/users.module.css';

const disabledValues = (name, number) => {
  const registrationName = !requiredLength(name) || !name.trim();
  const phoneNumber = !requiredLength(number) || !number.trim();

  return registrationName || phoneNumber;
};

const WIDTH = '500px';

const LABELS = { TOOLTIP: 'Заполните имя и номер телефона' };

class UserEditDialog extends Component {
  static propTypes = {
    item: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  renderInput = (title, magnitude, label, userId) => (
    <Input
      type={ COMPONENTS.INPUT.TYPE.TEXT }
      field={ title }
      value={ magnitude }
      label={ label }
      onChange={ (e, field, value) => this.props.onChange(title, value, userId) }
    />
  );

  renderTooltip = () => {
    const { item: { RegistrationName, PhoneNumber } } = this.props;

    return disabledValues(RegistrationName, PhoneNumber) && (
      <Tooltip
        position={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
      >
        { LABELS.TOOLTIP }
      </Tooltip>
    );
  };

  render() {
    const {
      item: {
        RegistrationName, Id, PhoneNumber, loading,
      }, onClose, onSave,
    } = this.props;

    return (
      <Dialog onClick={ onClose } width={ WIDTH }>
        <div className={ styles.user_dialog }>
          <h2> Изменение данных</h2>
          <div>
            { this.renderInput(
              USER_FIELD.REGISTRATION_NAME, RegistrationName, USER_FIELD_RU[USER_FIELD.REGISTRATION_NAME], Id,
            ) }
            { this.renderInput(USER_FIELD.PHONE_NUMBER, PhoneNumber, USER_FIELD_RU[USER_FIELD.PHONE_NUMBER], Id) }
          </div>
          <div className={ `sw-tooltip-wrapper ${styles.notification}` }>
            <DecisionButtons
              onCancel={ onClose }
              onSave={ onSave }
              labelSave={ TEXT.BUTTONS.SAVE }
              waitingResponse={ loading }
              disabled={ disabledValues(RegistrationName, PhoneNumber) }
            />
            { this.renderTooltip() }
          </div>
        </div>
      </Dialog>
    );
  }
}

export default UserEditDialog;
