import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Button, LinkButton as LinkButtonWrap, Select, Input,
} from 'sw-ui';
import Autocomplete from 'react-autocomplete';

import AjaxButton from '../../../components/ajaxButton';
import ModalDialog from '../../../components/dialog';
import { FlatButton } from '../../../components/button';
import SendDialog from './dialogs/sendDialog';
import { LegalEntityDialog } from './dialogs/LegalEntityDialog';
import SendVoucher from './dialogs/sendVoucher';
import { DownloadDialog } from './dialogs/DownloadDialog';
import { VoidingDialog } from './dialogs/voidingDialog';
import { TripCommentsDialog } from './dialogs/tripComments';
import { AdditionalServiceDialog } from './dialogs/additionalServiceDialog';
import DialogForm from './form/emailDialogForm';
import PenaltyForm from './penaltyForm';
import AirPenaltyForm from './airPenaltyForm';
import TrainCancelForm from './trainCancelForm';
import AirCancelForm from './airCancelForm';
import AeroexpressCancelForm from './aeroexpressCancelForm';
import ChangeTicketDialog from './changeTicketForm';
import DeleteItemDialogs from './deleteItemDialogs';
import MoveItemDialogs from './moveItemDialogs';
import SaveMetadataDialog from './saveMetadataDialog';
import { PenaltyVersionForm } from './penaltyVersionForm';
import { AdjustDialog } from './adjustDialog';
import { ChangeVersionDateDialog } from './ChangeVersionDateDialog';
import {
  renderEdit, renderNew, renderView, renderCopy, renderPartEdit, renderCacheItem,
} from './editPanel/editPanel';
import { renderItem, renderVersions } from './items';
import { MergeTrips } from './dialogs/MergeTrips';
import { VatErrorDialog } from './hotel/compoments/VatErrorDialog';

import {
  actualIntegrationVersion,
  isUploadedIntegrationVersion,
  defaultDataCancel,
} from '../../../bi/utils/trip';
import { formatDate } from '../../../bi/utils/formatDate';
import { getVatErrorText } from '../../../bi/utils/hotels';
import MoneyFormat from '../../../bi/utils/money';
import { VALID_SYMBLOL, priceValidation } from '../../../bi/utils/price';

import COMPONENTS from '../../../bi/constants/components';
import { SERVICETYPE } from '../../../bi/constants/serviceType';
import {
  TRIPBUTTONS,
  TRIPSTATUS,
  DIALOGSTITLES,
  DIALOGS,
  DIALOGFIELD,
  FIELDS,
  STATUSCODES,
  TYPES_EDIT,
  // STATUSES_INSURANCE,
} from '../../../bi/constants/trips';
import { HOTEL_PROVIDER_IS_AUTO_CALC_VAT, HOTEL_PROVIDER_VALUE } from '../../../bi/constants/hotel';
import {
  VAT_ERROR_STATUS, VAT_ERROR_STATUS_TEXT, VAT_VALUE_BY_VAT_BLOCK,
} from '../../../bi/constants/hotels';
import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';
import { FORMATDATETIME } from '../../../constants/time';
import FILEFORMATS from '../../../constants/fileFormats';
import { VALUES } from '../../account/components/trips/utils/cardPayment';

import styles from '../styles/trip.module.scss';

const { LinkButton, LinkButtonItem } = LinkButtonWrap;

const ITEMS_VISIBLE_LENGTH = 8;

const MENU_STYLE = {
  position: 'absolute',
  left: 0,
  top: 'auto',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  backgroundColor: 'white',
  zIndex: 6,
  boxShadow: '0 1px 3px rgba(0, 0, 0, .15)',
};

const SCROLL_STYLE = {
  overflowY: 'scroll',
  height: '288px',
};

const foundProjectName = (currentProjectId, projects) => (
  currentProjectId ? projects.find(({ id }) => id === currentProjectId).name : ''
);

const {
  BUTTON: { THEME: { FLAT } },
  DIALOG: { WIDTH: { MIDDLE } },
} = COMPONENTS;

const LABELS = {
  ESTIMATE: 'сумма сметы:',
  EMPTY: 'отсутствует',
  CHANGING_COST_CENTER: 'Изменение центра затрат',
  CANCEL: 'Отмена',
  CONFIRM_CHANGES: 'Подтвердить изменения',
  PROHIBITION_VOUCHERS: 'Отправка ваучеров на email недоступна для этой компании.',
  BANNED_COMPANY_NAMES: (companies) => `Ваучеры по компании ${companies} не будут отправлены.`,
  TRIP_COMMENTS: 'КОММЕНТАРИИ',
  NOTIFY_ERROR_ADD_TRIP: 'Не удалось сохранить изменения.',
  SEND_VOUCHER_ON: 'Отправить ваучер',
  DOWNLOAD_VOUCHER: 'Скачать ваучер',
  SEND_VOUCHERS: 'ОТПРАВИТЬ ВАУЧЕРЫ',
  SEND_INVOICE: 'ОТПРАВИТЬ СЧЕТ',
  MERGE_TRIPS: 'ОБЪЕДИНИТЬ ПОЕЗДКИ',
  TOTAL_TRIP_BILL: 'ОБЩИЙ СЧЁТ ПО ПОЕЗДКЕ',
  TOTAL_TRIP_BILL_XLSX: 'СЧЁТ В ФОРМАТЕ XLSX БЕЗ ПОДПИСИ И ПЕЧАТИ',
  ADDITIONAL_INFO: 'Внимание! К билету оформлена дополнительная услуга. Убедитесь, что не требуется её отмена!',
  EVENT_SELECT: 'Выберите заявку на мероприятие',
  EVENT: 'заявка на мероприятие:',
  AGREEMENT_INFO: 'Информация о согласовании поездки',
  MESSAGE_INFO: 'Для поездки согласования не найдены',
  WARNING: 'WARNING',
  CURRENT_STATUS: 'Текущий статус:',
  PAYMENT_TYPES: {
    NAME: 'Способ оплаты:',
    CARD: 'Банковская карта',
    ACCOUNT: 'Счет компании',
  },
};

const NO_EVENT = {
  FIRST: 'отсутствует',
  SECOND: 'отсутствуeт',
};

const ERROR = {
  EMPTY_INPUT: 'Выберите центр затрат',
  ARCHIVE_COST_CENTER: 'Обратите внимание, вы установили архивный ЦЗ',
  CHANGE_DATE_VERSION: 'Произошла ошибка сервера, попробуйте позже',
};

const ANALYTICS_EVENT_TYPE = {
  TRIP_OPEN: 'tripOpen',
  TRIP_CHANGE: 'tripChange',
};

const ANALYTICS_EVENT_SERVICE = { BACKOFFICE: 'Backoffice' };

const DialogConstants = {
  CONFIRM_ADJUST_ACTUAL_VERSION: 'confirmAdjustActualVersionDialog',
  ACTIVE_CONFIRM_DIALOG: 'dangerousConfirmAdjustActualVersionDialog',
  ACTIVE_DIALOG: 'confirmDeleteActualVersionDialog',
  CRITICAL_DIALOG: 'dangerousActualVersionOnlyIntegration',
  ACTIVE_DIALOG_DANGEROUS: 'dangerousDeleteActualVersionDialog',
  ACTIVE_MODAL: 'dangerousActualVersionWithIntegration',
  ACTIVE_DIALOG_CANNOT_DELETE_ACTUAL: 'cannotDeleteActualVersionDialog',
  ACTIVE_MODAL_CANNOT_DELETE_TRIP: 'cannotDeleteTripDialog',
  ACTIVE_DIALOG_CANNOT_DELETE_ORDERS: 'cannotDeleteOrdersDialog',
  ACTIVE_DIALOG_ORDERS_DELETED: 'ordersDeletedDialog',
  ACTIVE_DIALOG_TRIP_DELETED: 'tripDeletedDialog',
  ACTIVE_DIALOG_ACTUAL_VERSION_DELETED: 'actualversionDeletedDialog',
  ACTIVE_DIALOG_TRIP_MOVED: 'tripMovedDialog',
  ACTIVE_DIALOG_TRIP_MOVED_ALERT: 'tripMovedDialogAlert',
  ACTIVE_DIALOG_CANNOT_MOVE_TRIP: 'cannotMoveTripDialog',
  ACTIVE_DIALOG_ORDERS_MOVED: 'ordersMovedDialog',
  ACTIVE_DIALOG_ORDERS_MOVED_ALERT: 'ordersMovedDialogAlert',
  ACTIVE_DIALOG_CANNOT_MOVE_ORDERS: 'cannotMoveOrdersDialog',
  ACTIVE_DIALOG_WAIT_RES_CHECK_TRIP_DELETE: 'waitResCheckTripDelete',
  ACTIVE_DIALOG_WAIT_RES_CHECK_TRIP_MOVE: 'waitResCheckTripMove',
  ACTIVE_DIALOG_WAIT_RES_CHECK_ORDERS_DELETE: 'waitResCheckOrdersDelete',
  ACTIVE_DIALOG_WAIT_RES_CHECK_ORDERS_MOVE: 'waitResCheckOrdersMove',
};

const URL_QUERY_PARAMS = { MANUAL_BOOKING: 'manualBooking' };

export default class ExistingTrip extends Component {
  static propTypes = {
    specialBillAccount: PropTypes.object,
    trip: PropTypes.object.isRequired,
    companyId: PropTypes.number.isRequired,
    companies: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object),
    tripService: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    aeroexpressService: PropTypes.object.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    reloadPage: PropTypes.func.isRequired,
    moveToCompany: PropTypes.func.isRequired,
    projects: PropTypes.arrayOf(PropTypes.object),
    projectsForNewTrip: PropTypes.arrayOf(PropTypes.object),
    mandatoryProject: PropTypes.array.isRequired,
    accountId: PropTypes.number.isRequired,
    travelPolicyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    changeRate: PropTypes.object,
    integration: PropTypes.bool,
    integrationVersions: PropTypes.array,
    versionButtons: PropTypes.array.isRequired,
    analytics: PropTypes.array,
    tripAnalyticsValueIds: PropTypes.array,
    setTripAnalytics: PropTypes.func,
    guidService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    waitPreloader: PropTypes.bool.isRequired,
    isLegalDialog: PropTypes.bool.isRequired,
    legalEntity: PropTypes.object.isRequired,
    legalAllowEditingValues: PropTypes.array,
    legalSelectValue: PropTypes.object,
    event: PropTypes.object.isRequired,
    onChangeApplication: PropTypes.func.isRequired,
    downloadTripApprovalReport: PropTypes.func.isRequired,
    onChangeEstimate: PropTypes.func.isRequired,
    onDeleteModal: PropTypes.func.isRequired,
    estimate: PropTypes.number.isRequired,
  };

  static defaultProps = {
    users: [],
    projects: [],
    projectsForNewTrip: [],
    companies: [],
    changeRate: {},
    integration: false,
    integrationVersions: [],
    analytics: [],
    tripAnalyticsValueIds: [],
    setTripAnalytics: () => {},
    specialBillAccount: null,
    legalSelectValue: null,
    legalAllowEditingValues: [],
  };

  constructor(props) {
    super(props);
    const { featureFlagsService: { getEditTripItemVersion } } = props;
    const {
      successRefund,
      errorRefund,
      tripComments,
      event,
      events,
    } = props.tripService.get();
    const {
      prohibitionVouchers,
      companySettings,
    } = props.companyService.get();

    this.state = {
      openSubMenu: false,
      openDialog: false,
      cancelDialog: false,
      airCancelPenaltyForm: false,
      autoCancelDialog: false,
      cancelTrainDialog: false,
      cancelAirDialog: false,
      cancelAeroExpressDialog: false,
      changeTicketDialog: false,
      tripCommentsDialog: false,
      editItem: null,
      newItem: null,
      copyItem: null,
      cacheItem: null,
      editPartItem: null,
      viewItem: null,
      cancelItem: null,
      cancelTrainItem: null,
      cancelAirItem: null,
      changeTicketItem: null,
      voucherId: null,
      tripItemVersionId: null,
      versions: [],
      cacheTrips: [],
      checkboxes: [],
      checkboxesOperations: [],
      checkAllOperational: false,
      prohibitionVouchers,
      companySettings,
      operationsInf: [],
      surcharges: [],
      closingDocuments: [],
      activeDialog: '',
      closingConfirmDocuments: [],
      activeConfirmDialog: '',
      selectedCompany: null,
      orderIdDeleteCandidate: null,
      sendVouchersDialog: false,
      showSaveMetadataDialog: false,
      voidingItem: false,
      resultTextMoveVouchers: '',
      documentNumber: '',

      sendVoucherDialog: false,
      downloadVoucherDialog: false,
      oldVoucherDialog: false,
      sendResultVouchers: false,
      sendConfirmCancelAirItem: false,
      sendConfirmCancelAeroExpressItem: false,
      resultVouchersText: false,
      currentItem: null,
      versionItem: null,
      email: '',
      emails: '',
      currentServiceId: null,
      currentTravellerId: null,

      sendInvoiceDialog: false,
      sendResultInvoice: false,
      resultInvoiceText: false,

      event,
      events,

      waitResStartEdit: false,
      waitResCancelEdit: false,
      waitResSaveTrip: false,
      waitResSendVouchers: false,
      waitResSendVoucher: false,
      waitResChangeVoucher: false,
      waitResSendInvoice: false,

      waitResCheckTripDelete: false,
      waitResCheckTripMove: false,
      waitResCheckOrdersDelete: false,
      waitResCheckOrdersMove: false,

      waitResDelete: false,
      waitResMove: false,
      waitResChange: false,

      waitResSendIntegration: '',
      showIntegrationDialog: false,

      tripChange1C: false,
      manualTrainCancel: false,
      disabledButton: false,
      flagEditTripItemVersion: getEditTripItemVersion(),

      successRefund,
      errorRefund,
      tripComments,

      cancelVersionDialog: false,
      cancelVersionItem: null,

      errorAddTaxiTrip: false,
      successAddTaxiTrip: false,
      errorTotalTripBill: false,
      messageTotalTripBill: '',
      messageTaxi: '',
      loading: false,
      projectId: 0,
      tripItemId: 0,
      label: '',
      suggests: props.projects,
      flagCC: false,
      checkingArchivalСС: false,
      isLeaveExcess: false,
      selectedSubAccount: '',
      selectedCompanyId: null,
      selectedTaxiVoucher: [],
      waitResSaveVat: false,

      showChangeVersionDateDialog: false,
      versionDateData: null,
      isShowAllVersionDate: false,
      additionalServiceDialog: {
        show: false,
        labelContinue: '',
        handleConfirm: () => {},
        handleCancel: () => this.setState({
          additionalServiceDialog: {
            ...this.state.additionalServiceDialog,
            show: false,
          },
        }),
      },
      showApplication: false,
      showEditEstimate: false,
      tickets: [],
      showMergeTrips: false,
      estimateState: props.estimate,
    };
  }

  componentDidMount() {
    const {
      featureFlagsService, accountService, accountId, tripService, estimate,
    } = this.props;

    document.addEventListener('click', this.handleTouchOutside);
    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    tripService.sendTripAnalytic(accountId, ANALYTICS_EVENT_TYPE.TRIP_OPEN, ANALYTICS_EVENT_SERVICE.BACKOFFICE);
    accountService.getCompaniesInAccount(accountId);

    this.unsubscribeTrainData = tripService.subscribeTrain(this.updateTrainData);

    featureFlagsService.getAllFeatureFlags();

    const manualBookingFlag = new URLSearchParams(window.location.search).get(URL_QUERY_PARAMS.MANUAL_BOOKING);

    if (manualBookingFlag) {
      this.downloadVouchersOnNewManualTrip();
    }

    this.onChangeStateEstimate(String(estimate));
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleTouchOutside);

    this.unsubscribeTrainData();

    if (this.unsubFeatureFlags) {
      this.unsubFeatureFlags();
    }
  }

  handleChangeMergeTrips = (showMergeTrips) => () => {
    this.setState({ showMergeTrips });
  };

  updateTrainData = ({ tickets, loading }) => {
    this.setState({
      tickets,
      loading,
    });
  };

  updateFeatureFlags = ({
    TripChange1C,
    ManualTrainCancel,
    EditTripItemVersion,
  }) => {
    this.setState({
      tripChange1C: TripChange1C,
      manualTrainCancel: ManualTrainCancel,
      flagEditTripItemVersion: EditTripItemVersion,
    });
  };

  downloadVouchersOnNewManualTrip = () => {
    const {
      trip: { OrderItems }, companyId, tripService,
    } = this.props;

    return OrderItems.forEach(({ Versions }) => {
      return Versions.forEach(({ ServiceType, TripItemId }) => {
        if (ServiceType === SERVICETYPE.HOTEL) {
          tripService.downloadVoucher(companyId, TripItemId);
        }
      });
    });
  };

  scrollDown = () => window.scrollTo(0, this.editPanel.offsetTop - 100);

  toggleWaitResChange = () => this.setState({ waitResChangeVoucher: !this.state.waitResChangeVoucher });

  toggleOldVoucherDialog = (item = null) => {
    this.setState({
      oldVoucherDialog: !this.state.oldVoucherDialog,
      versionItem: item,
    });
  };

  toggleWaitResDeleteAndDisabledButton = (value) => this.setState({
    waitResDelete: value,
    disabledButton: value,
  });

  handleTouchOutside = (event) => {
    const { openSubMenu } = this.state;

    if (this.submenu && !this.submenu.contains(event.target) && openSubMenu !== false) {
      this.setState({ openSubMenu: false });
    }
  };

  onChangeStateEstimate = (value) => {
    if (priceValidation(value) || value === '' || value === VALID_SYMBLOL.ZERO) {
      this.setState({ stateEstimate: value });
    }
  };

  onChangeEstimate = (value, tripId) => {
    const response = value || VALID_SYMBLOL.ZERO;

    this.props.onChangeEstimate(response, tripId);

    this.onChangeStateEstimate(value);

    this.setState({ showEditEstimate: false });
  };

  handleOpenDeleteOperationsDialog = () => {
    this.setState({ activeDialog: DIALOGS.CONFIRM_OPERATIONS_DELETE });
  };

  handleOpenMoveOperationsDialog = (surcharges) => {
    const { trip, tripService } = this.props;
    const { tripItemId } = this.state;

    this.setState({
      activeDialog: DIALOGS.CONFIRM_MOVE_OPERATIONS,
      surcharges,
    });

    tripService.check(trip.TripId, [tripItemId]).then((result) => {
      this.setState({ documentNumber: result });
    });
  };

  hiddenAllVersionDate = (disable) => this.setState({ isShowAllVersionDate: disable });

  checkTrip = (action) => {
    const {
      trip,
      tripService,
      integration,
      integrationVersions,
    } = this.props;
    const { OrderItems } = trip;
    const orderItem = OrderItems.map((item) => item.Versions);

    const isUploaded = isUploadedIntegrationVersion(OrderItems, integrationVersions);

    const hasAirServiceType = orderItem.some((item) => item.some((version) => version.ServiceType === 'Air'
      && integration
      && integrationVersions.length));

    let waitAction = null;

    switch (action) {
      case 'Delete': {
        waitAction = DialogConstants.ACTIVE_DIALOG_WAIT_RES_CHECK_TRIP_DELETE;
        break;
      }
      case 'Move': {
        waitAction = DialogConstants.ACTIVE_DIALOG_WAIT_RES_CHECK_TRIP_MOVE;
        break;
      }
      default:
        break;
    }

    const check = () => {
      tripService.check(trip.TripId, []).then((response) => {
        if (response !== null) {
          if (response.length === 0 && !isUploaded && !hasAirServiceType) {
            this.setState({
              activeDialog: `confirm${action}TripDialog`,
              [waitAction]: false,
            });
          } else if (response.length === 0) {
            this.setState({
              activeDialog: `dangerous${action}TripOnlyIntegration`,
              [waitAction]: false,
            });
          } else if (response.length > 0 && !isUploaded) {
            this.setState({
              closingDocuments: response,
              activeDialog: `dangerous${action}TripDialog`,
              [waitAction]: false,
            });
          } else if (response.length > 0) {
            this.setState({
              closingDocuments: response,
              activeDialog: `dangerous${action}TripWithIntegration`,
              [waitAction]: false,
            });
          }
        } else {
          this.setState({
            activeDialog: `cannot${action}TripDialog`,
            [waitAction]: false,
          });
        }
      });
    };

    this.setState({ [waitAction]: true }, check);
  };

  saveVersionDateData = (data) => {
    this.setState({
      versionDateData: data,
      showChangeVersionDateDialog: true,
    });
  };

  closeChangeVersionDateDialog = () => {
    const { reloadPage } = this.props;

    this.setState({ showChangeVersionDateDialog: false });

    reloadPage();
  };

  checkOrders = (action) => {
    const {
      trip,
      tripService,
      integration,
      integrationVersions,
    } = this.props;
    const { checkboxes } = this.state;

    const { OrderItems } = trip;

    const isHotelSelected = checkboxes.some((orderId) => {
      const orderItem = OrderItems.find((item) => item.Id === orderId);

      return orderItem && orderItem.Versions.some((version) => version.ServiceType === 'Hotel');
    });

    const AirSelected = checkboxes.some((orderId) => {
      const orderItem = OrderItems.find((item) => item.Id === orderId);

      const integrationVersion = orderItem.Versions
        .some((version) => actualIntegrationVersion(integrationVersions, version.Id));

      return orderItem && orderItem.Versions.some((version) => version.ServiceType === 'Air'
        && integration
        && integrationVersion);
    });

    const isUploaded = isUploadedIntegrationVersion(OrderItems, integrationVersions);

    let waitAction = null;

    switch (action) {
      case 'Delete': {
        waitAction = DialogConstants.ACTIVE_DIALOG_WAIT_RES_CHECK_ORDERS_DELETE;
        break;
      }
      case 'Move': {
        waitAction = DialogConstants.ACTIVE_DIALOG_WAIT_RES_CHECK_ORDERS_MOVE;
        break;
      }
      default:
        break;
    }

    const check = () => {
      tripService.check(trip.TripId, checkboxes).then((response) => {
        if (response !== null) {
          if (response.length === 0 && !isUploaded && !AirSelected) {
            this.setState({
              activeDialog: `confirm${action}OrdersDialog`,
              [waitAction]: false,
            });
          } else if (response.length === 0 && !isHotelSelected) {
            this.setState({
              activeDialog: `dangerous${action}OrdersOnlyIntegration`,
              [waitAction]: false,
            });
          } else if (response.length === 0 && isHotelSelected && !AirSelected) {
            this.setState({
              activeDialog: `confirm${action}OrdersDialog`,
              [waitAction]: false,
            });
          } else if (response.length === 0 && isHotelSelected && AirSelected) {
            this.setState({
              activeDialog: `dangerous${action}OrdersOnlyIntegration`,
              [waitAction]: false,
            });
          } else if (response.length > 0 && !isUploaded) {
            this.setState({
              closingDocuments: response,
              activeDialog: `dangerous${action}OrdersDialog`,
              [waitAction]: false,
            });
          } else if (response.length > 0) {
            this.setState({
              closingDocuments: response,
              activeDialog: `dangerous${action}OrdersWithIntegration`,
              [waitAction]: false,
            });
          }
        } else {
          this.setState({
            activeDialog: `cannot${action}OrdersDialog`,
            [waitAction]: false,
          });
        }
      });
    };

    this.setState({ [waitAction]: true }, check);
  };

  checkActualVersionFromConfirm = async (orderId) => {
    const {
      trip,
      tripService,
    } = this.props;
    const { orderIdDeleteCandidate } = this.state;

    try {
      const response = await tripService.check(trip.TripId, [orderId || orderIdDeleteCandidate]);
      if (response && response.length === 0) {
        this.setState({ activeConfirmDialog: DialogConstants.CONFIRM_ADJUST_ACTUAL_VERSION });
      }

      if (response && response.length > 0) {
        this.setState({
          closingConfirmDocuments: response,
          activeConfirmDialog: DialogConstants.ACTIVE_CONFIRM_DIALOG,
        });
      }
    } catch (e) {
      console.log('error', e);
    }
  };

  checkActualVersion = (orderId) => {
    const {
      trip,
      tripService,
      integration,
      integrationVersions,
    } = this.props;

    const { OrderItems } = trip;
    const { orderIdDeleteCandidate } = this.state;

    const isUploaded = isUploadedIntegrationVersion(OrderItems, integrationVersions);

    const orderItem = OrderItems.find((item) => item.Id === (orderId || orderIdDeleteCandidate));

    const isHotelService = orderItem && orderItem.Versions.some((version) => version.ServiceType === 'Hotel');

    const integrationVersion = orderItem
      && orderItem.Versions.some((version) => actualIntegrationVersion(integrationVersions, version.Id));

    const isAirService = orderItem && orderItem.Versions.some((version) => version.ServiceType === 'Air'
      && integration
      && integrationVersion);

    this.setState({ orderIdDeleteCandidate: orderId });

    return tripService.check(trip.TripId, [orderId || orderIdDeleteCandidate]).then((response) => {
      if (response !== null) {
        if (response.length === 0 && !isUploaded && !isAirService) {
          this.setState({ activeDialog: DialogConstants.ACTIVE_DIALOG });
        } else if (response.length === 0 && !isHotelService) {
          this.setState({ activeDialog: DialogConstants.CRITICAL_DIALOG });
        } else if (response.length === 0 && isHotelService) {
          this.setState({ activeDialog: DialogConstants.ACTIVE_DIALOG });
        } else if (response.length > 0 && !isUploaded) {
          this.setState({
            closingDocuments: response,
            activeDialog: DialogConstants.ACTIVE_DIALOG_DANGEROUS,
          });
        } else if (response.length > 0) {
          this.setState({
            closingDocuments: response,
            activeDialog: DialogConstants.ACTIVE_MODAL,
          });
        }
      } else {
        this.setState({ activeDialog: DialogConstants.ACTIVE_DIALOG_CANNOT_DELETE_ACTUAL });
      }
    });
  };

  deleteTrip = () => {
    const {
      trip,
      tripService,
    } = this.props;

    const deleteTrip = () => {
      tripService.moveTrip(trip.TripId, 1265).then((response) => {
        if (response === null) {
          this.setState({
            activeDialog: DialogConstants.ACTIVE_MODAL_CANNOT_DELETE_TRIP,
            waitResDelete: false,
          });
        } else {
          this.setState({
            activeDialog: DialogConstants.ACTIVE_DIALOG_TRIP_DELETED,
            waitResDelete: false,
          });
        }
      });
    };

    this.setState({ waitResDelete: true }, deleteTrip);
  };

  deleteOrders = () => {
    const {
      trip,
      tripService,
    } = this.props;

    const { checkboxes } = this.state;

    const deleteOrders = () => {
      tripService.moveOrders(trip.TripId, 1265, checkboxes).then((response) => {
        if (response === null) {
          this.setState({
            activeDialog: DialogConstants.ACTIVE_DIALOG_CANNOT_DELETE_ORDERS,
            waitResDelete: false,
          });
        } else {
          this.setState({
            activeDialog: DialogConstants.ACTIVE_DIALOG_ORDERS_DELETED,
            waitResDelete: false,
          });
          this.props.reloadPage();
        }
      });
    };

    this.setState({ waitResDelete: true }, deleteOrders);
  };

  deleteOperations = () => {
    const { tripService, reloadPage } = this.props;
    const {
      checkboxesOperations, voucherId, tripItemVersionId,
    } = this.state;

    const deleteOperations = () => {
      tripService.moveOperations(tripItemVersionId, voucherId, checkboxesOperations).then(() => {
        this.setState({ waitResDelete: false });
        reloadPage();
      })
        .catch(() => {
          this.setState({ waitResDelete: false });
        });
    };

    this.setState({ waitResDelete: true }, deleteOperations);
  };

  deleteActualVersion = () => {
    this.toggleWaitResDeleteAndDisabledButton(true);

    const {
      trip,
      tripService,
    } = this.props;

    const { orderIdDeleteCandidate } = this.state;

    const currentOrder = trip.OrderItems.find(({ Id }) => Id === orderIdDeleteCandidate);

    if (currentOrder) {
      const actualVersionId = currentOrder.ActualVersion.Id;

      return tripService.moveActualVersion(trip.TripId, 1265, [orderIdDeleteCandidate], actualVersionId)
        .then((response) => {
          if (!response) {
            return this.setState({ activeDialog: DialogConstants.ACTIVE_DIALOG_CANNOT_DELETE_ACTUAL });
          }

          return this.setState({ activeDialog: DialogConstants.ACTIVE_DIALOG_ACTUAL_VERSION_DELETED });
        })
        .finally(() => {
          this.toggleWaitResDeleteAndDisabledButton(false);
          location.reload();
        });
    }

    return this.toggleWaitResDeleteAndDisabledButton(false);
  };

  moveTrip = () => {
    const {
      trip,
      tripService,
    } = this.props;

    const { selectedCompany } = this.state;

    if (!selectedCompany) return;

    const moveTrip = () => {
      tripService.moveTrip(trip.TripId, selectedCompany).then((response) => {
        if (response !== null) {
          if (response.length === 0) {
            this.setState({
              activeDialog: DialogConstants.ACTIVE_DIALOG_TRIP_MOVED,
              waitResMove: false,
            });
          } else {
            this.setState({
              closingDocuments: response,
              activeDialog: DialogConstants.ACTIVE_DIALOG_TRIP_MOVED_ALERT,
              waitResMove: false,
            });
          }
        } else {
          this.setState({
            activeDialog: DialogConstants.ACTIVE_DIALOG_CANNOT_MOVE_TRIP,
            waitResMove: false,
          });
        }
      });
    };

    this.setState({ waitResMove: true }, moveTrip);
  };

  moveOrders = () => {
    const {
      trip,
      tripService,
    } = this.props;

    const { checkboxes, selectedCompany } = this.state;

    if (!selectedCompany) return;

    this.setState({ activeDialog: '' });

    const moveOrders = () => {
      tripService.moveOrders(trip.TripId, selectedCompany, checkboxes).then((response) => {
        if (response !== null) {
          if (response.length === 0) {
            this.setState({
              activeDialog: DialogConstants.ACTIVE_DIALOG_ORDERS_MOVED,
              waitResMove: false,
            });
          } else {
            this.setState({
              closingDocuments: response,
              activeDialog: DialogConstants.ACTIVE_DIALOG_ORDERS_MOVED_ALERT,
              waitResMove: false,
            });
          }
        } else {
          this.setState({
            activeDialog: DialogConstants.ACTIVE_DIALOG_CANNOT_MOVE_ORDERS,
            waitResMove: false,
          });
        }
      });
    };

    this.setState({ waitResMove: true }, moveOrders);
  };

  moveVouchers = () => {
    const { tripService, reloadPage } = this.props;

    const {
      checkboxesOperations,
      selectedSubAccount,
      isLeaveExcess,
      voucherId,
      tripItemId,
      surcharges,
      selectedTaxiVoucher,
    } = this.state;

    const data = {
      companyId: selectedSubAccount,
      tripItemId,
      voucherId,
      voucherOperationIds: checkboxesOperations,
      isLeaveExcess: surcharges.length && selectedTaxiVoucher.length !== checkboxesOperations.length
        ? isLeaveExcess
        : true,
    };

    const moveVouchers = () => {
      tripService.moveVouchers(data).then(() => {
        this.setState({ waitResMove: false });

        reloadPage();
      })
        .catch(({ body }) => {
          this.setState({
            activeDialog: DIALOGS.CANNOT_MOVE_VOUCHERS_DIALOG,
            resultTextMoveVouchers: body,
            waitResMove: false,
          });
        });
    };

    this.setState({ waitResMove: true }, moveVouchers);
  };

  handleAgreeWithConditions = () => {
    this.setState({ activeDialog: DIALOGS.AGREE_WITH_CONDITIONS });
  };

  disableActive = () => {
    this.setState({ activeDialog: '' });
  };

  disableConfirmActive = () => {
    this.setState({ activeConfirmDialog: '' });
  };

  selectCompany = (companyId) => {
    this.setState({ selectedCompany: companyId });
  };

  addTrip = (newTrip) => this.setState({ cacheTrips: [...this.state.cacheTrips, newTrip] });

  addVersions = (versions, cancelItem) => {
    const { trip } = this.props;

    this.setState((state) => {
      const addToVersion = (version) => {
        version.TripId = trip.TripId;
        if (cancelItem) {
          version.Price = cancelItem.Price;

          version.CompanyId = cancelItem.CompanyId;
        }

        state.versions.push(version);
      };

      if (Array.isArray(versions)) {
        versions.forEach((version) => addToVersion(version));
      } else {
        state.versions = state.versions.filter((v) => v.OrderTripItemId === 0
          || v.OrderTripItemId !== versions.OrderTripItemId);

        addToVersion(versions);
      }

      return state;
    });
  };

  handleChangeIsLeaveExcess = () => {
    const { isLeaveExcess } = this.state;

    this.setState({ isLeaveExcess: !isLeaveExcess });
  };

  handlerAddOrderItem = () => {
    this.setState({ openSubMenu: true });
  };

  handleNewItem = (type) => {
    const newItem = this.props.tripService.addNewItem(type);
    const {
      accountId, tripService, trip: { UserName }, users,
    } = this.props;
    const user = users.find((item) => item.UserName === UserName);

    if (user) {
      tripService.loadMandatoryProject(accountId);
    }

    this.setState({
      openSubMenu: false,
      editItem: null,
      editPartItem: null,
      newItem,
    }, this.scrollDown);
  };

  handleCopyItem = (item) => {
    const { tripService } = this.props;

    const copyItem = tripService.copyItem(item);

    this.setState({ copyItem }, this.scrollDown);
  };

  handleEditItem = (item) => {
    const { tripService } = this.props;
    const { versions, cacheTrips } = this.state;

    const tripToEdit = cacheTrips.find((trip) => trip.Guid === item.Guid);
    const newVersions = versions.filter((version) => version.Guid !== item.Guid);
    const newCacheTrips = cacheTrips.filter((trip) => trip.Guid !== item.Guid);

    const cacheItem = tripService.editItem(tripToEdit);

    this.setState({
      versions: newVersions,
      cacheTrips: newCacheTrips,
      cacheItem,
    }, this.scrollDown);
  };

  handlerAddServiceItem = (item) => {
    this.handleCancelEdit(item);
    this.setState({ newItem: item }, this.scrollDown);
  };

  handlerVoidingModal = (item) => {
    this.setState({ voidingItem: item || null });
  };

  handleChangeSubAccount = ({ value }) => {
    this.setState({ selectedSubAccount: value });
  };

  handlerEditItem = (item) => {
    const { trip: { OrderItems }, integrationVersions } = this.props;
    const checkIntegrationVersion = isUploadedIntegrationVersion(OrderItems, integrationVersions);
    const field = item.Status !== 3 ? TYPES_EDIT.EDIT_ITEM : TYPES_EDIT.EDIT_PART_ITEM;

    let editedItem = item;

    if ((item.ServiceType === SERVICETYPE.HOTEL
      || item.ServiceType === SERVICETYPE.CUSTOM)
    && field === TYPES_EDIT.EDIT_ITEM) {
      const orderItemVersions = OrderItems.find(({ Id }) => item.OrderTripItemId === Id);
      const firstVersion = orderItemVersions.Versions[orderItemVersions.Versions.length - 1];

      editedItem = {
        ...editedItem,
        ReportAmount: firstVersion.ReportAmount,
      };
    }

    this.setState({
      [field]: editedItem,
      newItem: null,
      waitResChange: true,
    }, () => {
      if (item.ServiceType === SERVICETYPE.HOTEL) {
        this.setState({ waitResChange: false });
        this.scrollDown();
      } else if (checkIntegrationVersion) {
        this.setState({
          activeDialog: DIALOGS.DIALOGWIHTINTEGRATION,
          waitResChange: false,
        });
      } else {
        this.scrollDown();
      }
    });
  };

  handlerEditItemWithDialog = (item) => {
    const additionalMessage = this.getAdditionalItemMessage(item.TripItemId);

    if (additionalMessage) {
      return this.setState({
        additionalServiceDialog: {
          ...this.state.additionalServiceDialog,
          show: true,
          labelContinue: DECISIONBUTTONS.LABELS.CHANGEANYWAY,
          handleConfirm: () => {
            this.setState({
              additionalServiceDialog: {
                ...this.state.additionalServiceDialog,
                show: false,
              },
            });
            this.handlerEditItem(item);
          },
        },
      });
    }

    return this.handlerEditItem(item);
  };

  handleAddTaxi = (item) => {
    this.setState({ editItem: item }, () => {
      this.scrollDown();
    });
  };

  handleEditVatVersion = async ({
    item, rate, amount, rackRate,
  }) => {
    const { tripService } = this.props;

    const isAutoCalc = HOTEL_PROVIDER_IS_AUTO_CALC_VAT.includes(item.ProviderName);
    let newAmount = amount;

    if (isAutoCalc) {
      // TODO перенести в сервис
      try {
        newAmount = await tripService.getVatAmount({
          Rate: Number(rate),
          Amount: item.Price, // TODO заменит на Base
        });
      } catch (e) {
        return this.setState({
          showVatErrorDialog: true,
          vatErrorText: VAT_ERROR_STATUS_TEXT.DEFAULT,
        });
      }
    }

    const data = [
      {
        TripItemVersionId: item.Id,
        TripItemId: item.TripItemId,
        RackRate: rackRate,
        VatDetails: rate !== VAT_VALUE_BY_VAT_BLOCK.WITHOUT
          ? [
            {
              Rate: Number(rate),
              Amount: isAutoCalc ? newAmount.Vat : newAmount,
            },
          ]
          : [],
      },
    ];

    return this.setState({ waitResSaveVat: true }, () => this.saveVat(data));
  };

  saveVat = async (data) => {
    const { tripService, trip } = this.props;

    // TODO перенести в сервис
    try {
      const res = await tripService.addVatVersions(data);

      this.setState({
        waitResSaveVat: false,
        showVatErrorDialog: true,
        vatErrorText: getVatErrorText(res[0].Status),
      }, () => {
        if (res[0].Status === VAT_ERROR_STATUS.SUCCESS) {
          tripService.load(trip.AccountId, trip.TripId);
        }
      });
    } catch (e) {
      this.setState({
        waitResSaveVat: false,
        showVatErrorDialog: true,
        vatErrorText: VAT_ERROR_STATUS_TEXT.FAILED,
      });
    }
  };

  closeErrorVatDialog = () => {
    this.setState({ showVatErrorDialog: false });
  };

  handlerCancelItem = (item) => {
    if (item.ProviderName === HOTEL_PROVIDER_VALUE.tripcom) {
      return this.addVersions(defaultDataCancel(item), item);
    }

    const serviceType = this.props.tripService.getServiceType();

    const dialogField = item.ServiceType === serviceType.TRAIN
      ? DIALOGFIELD.CANCEL_TRAIN_DIALOG
      : DIALOGFIELD.CANCEL_DIALOG;

    const cancelItemField = item.ServiceType === serviceType.TRAIN
      ? DIALOGFIELD.CANCEL_TRAIN_ITEM
      : DIALOGFIELD.CANCEL_ITEM;

    return this.setState({
      [dialogField]: true,
      [cancelItemField]: item,
    });
  };

  handlerAutoCancelItem = (item) => {
    const serviceType = this.props.tripService.getServiceType();

    const dialogField =
      (item.ServiceType === serviceType.AIR && DIALOGFIELD.CANCEL_AIR_DIALOG)
      || (item.ServiceType === serviceType.AEROEXPRESS && DIALOGFIELD.CANCEL_AEROEXPRESS_DIALOG);

    const cancelItemField =
      (item.ServiceType === serviceType.AIR && DIALOGFIELD.CANCEL_AIR_ITEM)
      || (item.ServiceType === serviceType.AEROEXPRESS && DIALOGFIELD.CANCEL_AEROEXPRESS_ITEM);

    this.setState({
      [dialogField]: true,
      [cancelItemField]: item,
    });
  };

  handlerChangeTicketStatus = (item) => {
    const serviceType = this.props.tripService.getServiceType();

    const dialogField = item.ServiceType === serviceType.AIR && DIALOGFIELD.CHANGE_TICKET_DIALOG;
    const cancelItemField = item.ServiceType === serviceType.AIR && DIALOGFIELD.CHANGE_TICKET_ITEM;

    this.setState({
      [dialogField]: true,
      [cancelItemField]: item,
    });
  };

  handleManualCancelItem = (item) => {
    this.setState({
      cancelDialog: true,
      cancelItem: item,
    });
  };

  handleCancelAirPenaltyItem = (item) => {
    this.setState({
      airCancelPenaltyForm: true,
      cancelItem: item,
    });
  };

  handleShowViewItem = (viewItem) => () => this.setState({ viewItem },
    () => {
      if (viewItem) {
        this.scrollDown();
      }
    });

  handlerChangeStatus = () => {
    const {
      trip,
      tripService,
      companyId,
      accountId,
    } = this.props;

    const startEdit = () => {
      tripService.startEdit(companyId, trip.TripId)
        .then(() => this.setState({ waitResStartEdit: false }))
        .catch(() => this.setState({ waitResStartEdit: false }));
    };

    tripService.sendTripAnalytic(accountId, ANALYTICS_EVENT_TYPE.TRIP_CHANGE, ANALYTICS_EVENT_SERVICE.BACKOFFICE);
    this.setState({ waitResStartEdit: true }, startEdit);
  };

  toggleSaveMetadataDialog = () => this.setState({ showSaveMetadataDialog: !this.state.showSaveMetadataDialog });

  handleSaveMetadataWithDocumentsSend = () => {
    const {
      tripService, notificationsService: notifier, accountId, trip, users,
    } = this.props;

    const user = users.map((item) => item.Email);

    this.saveTrip(user, true)
      .catch(() => {
        notifier.send({
          level: notifier.levels.get('FAILURE'),
          message: 'Не удалось изменить поездку.',
        });

        throw new Error('error');
      })
      .then(() => tripService.sendDocumentsAfterSaveMetadata(accountId, trip.TripId))
      .then((response) => {
        notifier.send({
          header: response.resultVouchers ? null : 'Ошибка отправки ваучеров',
          level: response.resultVouchers ? notifier.levels.get('SUCCESS') : notifier.levels.get('FAILURE'),
          message: response.resultVouchers ? 'Ваучеры успешно отправлены.' : response.messageVouchers,
        });

        notifier.send({
          header: response.resultInvoices ? null : 'Ошибка отправки счетов',
          level: response.resultInvoices ? notifier.levels.get('SUCCESS') : notifier.levels.get('FAILURE'),
          message: response.resultInvoices ? 'Счета успешно отправлены.' : response.messageInvoices,
        });

        this.setState({ waitResSaveTrip: false });
      });
  };

  handleSaveMetadataWithoutDocumentsSend = () => {
    const user = this.props.users.map((item) => item.Email);

    this.saveTrip(user);
  };

  saveTrip = (users, waitDocuments) => {
    const { versions } = this.state;
    const {
      trip,
      companyId,
      tripService,
      notificationsService: notifier,
    } = this.props;

    const orderTrip = {
      Users: users,
      Versions: versions,
      UserAnalytics: trip.UserAnalytics,
    };

    return tripService.save(companyId, orderTrip, trip.TripId)
      .catch(() => {
        this.setState({ waitResSaveTrip: false, showSaveMetadataDialog: false });

        throw new Error('error');
      })
      .then((res) => {
        if (parseInt(res, 10) > 0) {
          notifier.send({
            level: notifier.levels.get('SUCCESS'),
            message: 'Поездка успешно изменена.',
          });

          this.setState({
            versions: [],
            openDialog: false,
            waitResSaveTrip: false,
          });

          if (waitDocuments) {
            notifier.send({
              level: notifier.levels.get('WARNING'),
              header: 'Не уходите со страницы',
              message: 'Дождитесь сообщений с информацией об отправленных счетах и ваучерах.',
            });
          }
        }
        if ('Errors' in res && res.Errors.length !== 0) {
          const [error] = res.Errors;
          notifier.send({
            level: notifier.levels.get('FAILURE'),
            message: error || 'Не удалось сохранить изменения.',
          });

          this.setState({ waitResSaveTrip: false });
        }
        if ('Errors' in res && res.Errors.length === 0) {
          notifier.send({
            level: notifier.levels.get('SUCCESS'),
            message: 'Поездка успешно изменена.',
          });

          this.setState({
            versions: [],
            openDialog: false,
            waitResSaveTrip: false,
          });
        }

        this.setState({ showSaveMetadataDialog: false }, () => tripService.load(trip.AccountId, trip.TripId));
      });
  };

  handlerConfirmChange = () => {
    const {
      users, trip, tripService,
    } = this.props;
    const { versions } = this.state;

    tripService.defaultCustomFile();

    const user = users.map((item) => item.Email);

    if (trip.Status === TRIPSTATUS.AWAITINGTICKETING) {
      const addNewItem = versions.find((version) => version.OrderTripItemId === 0);
      const isMetaData = trip.OrderItems.some(({ ActualVersion }) => ActualVersion.Status === STATUSCODES.METADATA);

      if (isMetaData) {
        return this.setState({ waitResSaveTrip: true }, () => this.handleSaveMetadataWithDocumentsSend());
      }

      if ((users.length > 1) && addNewItem) {
        return this.handleOpenDialog();
      }

      return this.toggleSaveMetadataDialog();
    } else if (versions.length && trip.Status !== TRIPSTATUS.AWAITINGTICKETING) {
      const addNewItem = versions.find((version) => version.OrderTripItemId === 0);

      if ((users.length > 1) && addNewItem) {
        return this.handleOpenDialog();
      }

      return this.setState({ waitResSaveTrip: true }, () => this.saveTrip(user));
    }

    return this.handleCancelChange(TRIPSTATUS.WAITRESSAVETRIP);
  };

  handleOpenDialog = () => this.setState({ openDialog: true });

  handlerConfirmUsers = (users) => (users.length ? this.saveTrip(users) : null);

  handleCloseDialog = () => {
    if (this.state.openDialog) {
      this.setState({ openDialog: false });
    }
  };

  toogleCancelButton = (field) => this.setState({ [field]: false });

  handleCancelChange = (field) => {
    const {
      trip,
      tripService,
      companyId,
    } = this.props;

    const cancelEdit = () => {
      tripService.cancelEdit(companyId, trip.TripId)
        .then(() => this.toogleCancelButton(field))
        .catch(() => this.toogleCancelButton(field));
    };

    this.setState({
      versions: [],
      [field]: true,
      editItem: null,
    }, cancelEdit);
  };

  handleAddTaxiTrip = async (operationId, voucherId, tripItemVersionId) => {
    const { tripService, notificationsService: notifier } = this.props;

    try {
      this.setState({ loading: true });

      const { Error } = await tripService.addTripToVoucher(tripItemVersionId, voucherId, operationId);

      if (Error) {
        this.setState({
          errorAddTaxiTrip: true,
          messageTaxi: Error.Message,
        });
      } else {
        this.setState({ successAddTaxiTrip: true });
      }

      this.setState({ loading: false });
    } catch (error) {
      notifier.send({
        level: notifier.levels.get('FAILURE'),
        message: LABELS.NOTIFY_ERROR_ADD_TRIP,
      });
    }
  };

  handleConfirmEdit = (item) => {
    const { cancelItem } = this.state;

    this.addVersions(item, cancelItem);
    this.handleCancelEdit();
  };

  handleConfirmPartEdit = (item) => {
    const { cancelItem } = this.state;

    this.addVersions(item, cancelItem);
    this.handleCancelPartEdit();
  };

  handleConfirmNew = (item) => {
    const { cancelItem } = this.state;

    this.addVersions(item, cancelItem);
    this.handleCancelNew();
  };

  handleConfirmCopy = (item) => {
    const { cancelItem } = this.state;

    this.addVersions(item, cancelItem);
    this.handleCancelCopy();
  };

  handleCancelEdit = () => {
    this.setState({
      editItem: null,
      cacheItem: null,
    });
  };

  handleCancelPartEdit = () => {
    this.setState({ editPartItem: null });
  };

  handleCancelNew = () => {
    this.setState({ newItem: null });
  };

  handleCancelCopy = () => {
    this.setState({ copyItem: null });
  };

  handlerClosePenaltyDialog = () => {
    this.setState({
      cancelDialog: false,
      cancelItem: null,
    });
  };

  handlerCloseAirPenaltyDialog = () => {
    this.setState({
      airCancelPenaltyForm: false,
      cancelItem: null,
    });
  };

  handleCloseTrainDialog = () => this.setState({
    cancelTrainDialog: false,
    cancelTrainItem: null,
  });

  handleCloseAirDialog = () => this.setState({
    cancelAirDialog: false,
    cancelAirItem: null,
  });

  handleCloseAeroExpressDialog = () => this.setState({
    cancelAeroExpressDialog: false,
    cancelAeroExpressItem: null,
  });

  handleCloseChangeTicketDialog = () => this.setState({
    changeTicketDialog: false,
    changeTicketItem: null,
  });

  handlerConfirmCancelItem = (item) => {
    const { companyId, tripService } = this.props;

    if (item.ServiceType === SERVICETYPE.TRAIN) {
      const body = {
        baseSum: item.Penalties[0].Penalties,
        commission: item.Penalties[0].Tax,
      };

      tripService.manualTrainCancel(companyId, item.OrderTripItemId, body).then(() => {
        this.handlerClosePenaltyDialog();
        this.props.reloadPage();
      });
    } else {
      const { cancelItem } = this.state;

      this.addVersions(item, cancelItem);
      this.handlerClosePenaltyDialog();
    }
  };

  handlerConfirmAirCancelItem = (item) => {
    const { cancelItem } = this.state;

    this.addVersions(item, cancelItem);
    this.handlerCloseAirPenaltyDialog();
  };

  handleConfirmCancelTrainItem = (msg) => {
    alert(msg);
    this.props.reloadPage();
  };

  handleConfirmCancelAeroExpress = () => {
    this.handleCloseAeroExpressDialog();
    this.setState({ sendConfirmCancelAeroExpressItem: true });
  };

  handleConfirmCancelAirItem = () => {
    this.setState({ sendConfirmCancelAirItem: true });
  };

  renderConfirmCancelAirItem = () => {
    const { successRefund, errorRefund } = this.state;

    const errorMessage = errorRefund || DIALOGSTITLES.RETURNSSENDERR;

    const dialogCancelationText = successRefund ? DIALOGSTITLES.CONFIRMRETURNS : errorMessage;

    return (
      <Dialog onClick={ this.closeConfirmCancelAirItem } width={ MIDDLE }>
        <div className={ styles['user-dialog'] }>
          <p className={ styles['dialog-item'] }>{ dialogCancelationText }</p>
          <div className={ `${styles.row} ${styles.right}` }>
            <Button label={ TRIPBUTTONS.CLOSE } theme={ FLAT } onClick={ this.closeConfirmCancelAirItem } />
          </div>
        </div>
      </Dialog>
    );
  };

  renderConfirmCancelAeroexpressItem = () => {
    const { aeroExpressFail } = this.props.tripService.get();

    const colorErrorText = aeroExpressFail ? styles['error-version'] : '';

    const dialogCancelationText = !aeroExpressFail
      ? DIALOGSTITLES.CONFIRMRETURNS
      : DIALOGSTITLES.TICKET_CANCELLATION_ERROR;

    return (
      <Dialog onClick={ this.closeConfirmCancelAeroExpress } width={ MIDDLE }>
        <div className={ styles['user-dialog'] }>
          <p className={ `${styles['dialog-item']} ${colorErrorText}` }>
            { dialogCancelationText }
          </p>
          <div className={ `${styles.row} ${styles.right}` }>
            <Button label={ TRIPBUTTONS.CLOSE } theme={ FLAT } onClick={ this.closeConfirmCancelAeroExpress } />
          </div>
        </div>
      </Dialog>
    );
  };

  handlerDeleteItem = (item) => {
    const {
      trip,
      companyId,
      tripService,
      moveToCompany,
    } = this.props;

    const orderItemsMetadata = trip.OrderItems.filter((orderMeta) => orderMeta.ActualVersion.Status === 3);
    const orderItemsHidden = trip.OrderItems.filter((orderHid) => orderHid.ActualVersion.Status === 4);
    const orderItems = trip.OrderItems.length - orderItemsHidden.length;

    tripService.deleteMetadata(trip.TripId, item.TripItemId).then(() => {
      if (orderItemsHidden.length > 0) {
        if (orderItemsMetadata.length === 1 && orderItems === 1) {
          moveToCompany(trip.AccountId, companyId);
        } else {
          tripService.updateTrip(companyId, trip.TripId);
        }
      } else if (orderItemsHidden.length === 0) {
        if (trip.OrderItems.length === 1) {
          moveToCompany(trip.AccountId, companyId);
        } else {
          tripService.updateTrip(companyId, trip.TripId);
        }
      }
    });
  };

  handleDownloadFlightCertificate = (
    airItemId, signatureType, isFreeFlightCertificate,
  ) => this.props.tripService.downloadFlightCertificate(airItemId, signatureType, isFreeFlightCertificate);

  handelGetStatusFlightCertificate = (airItemId) => this.props.tripService.getStatusFlightCertificate(airItemId);

  handelBookFlightCertificate = async (airItemId) => {
    const { tripService, reloadPage } = this.props;

    const status = await tripService.bookFlightCertificate(airItemId);

    if (status) reloadPage();

    return status;
  };

  handleOpenDownloadVoucherDialog = ({ TripItemId, ServiceType }) => {
    const {
      companyId,
      tripService,
    } = this.props;

    if (ServiceType === SERVICETYPE.HOTEL) {
      return this.setState({
        downloadVoucherDialog: true,
        tripItemId: TripItemId,
      });
    }

    return tripService.downloadVoucher(companyId, TripItemId);
  };

  handleDownloadVoucher = (locale) => {
    const {
      companyId,
      tripService,
    } = this.props;
    const { tripItemId } = this.state;

    tripService.downloadVoucher(companyId, tripItemId, locale);
  };

  handleDownloadTripApprovalReport = async (tripId) => {
    const {
      tripService,
      notificationsService,
    } = this.props;

    const res = await tripService.downloadTripApprovalReport(tripId);

    if (!res) {
      notificationsService.send({
        level: notificationsService.levels.get(LABELS.WARNING),
        message: LABELS.MESSAGE_INFO,
      });
    }
  };

  handleDownloadVoucherGroupLiving = async (tripItemId) => {
    const {
      companyId,
      tripService,
    } = this.props;

    await tripService.downloadVoucherGroupLiving(companyId, tripItemId);
  };

  handleDownloadBreakfastVoucher = ({ TripItemId }) => {
    const {
      companyId,
      tripService,
    } = this.props;

    tripService.downloadBreakfastVoucher(companyId, TripItemId);
  };

  downloadServiceForm = (tripItemId, serviceId) => {
    const {
      accountId,
      tripService,
    } = this.props;

    tripService.downloadServiceForm(accountId, tripItemId, serviceId);
  };

  handleDownloadOldVoucher = ({ Id }) => {
    const {
      accountId,
      tripService,
    } = this.props;

    tripService.downloadOldVoucher(accountId, Id);
  };

  handleChangeTrainVoucher = ({ TripItemId, ProviderName }) => {
    this.toggleWaitResChange();

    const {
      tripService, companyId, trip: { TripId },
    } = this.props;

    tripService.changeTrainVoucher(TripItemId, ProviderName)
      .then(() => tripService.clearCacheByTripId(companyId, TripId)
        .then(this.toggleWaitResChange)
        .catch(this.toggleWaitResChange))
      .catch(this.toggleWaitResChange); // TODO: добавить NoResult
  };

  handleChangeAeroExpressVoucher = ({ Id }) => {
    this.toggleWaitResChange();

    const { tripService } = this.props;

    tripService.changeAeroExpressVoucher(Id)
      .then(this.toggleWaitResChange)
      .catch(this.toggleWaitResChange);
  };

  handlerSendVoucher = (item, serviceId = null, travellerId = null) => {
    const employees = item ? item.Employees : [];
    const preparedEmployees = employees ? employees.map(({ Email }) => Email).join(', ') : [];

    this.setState({
      sendVoucherDialog: true,
      currentItem: item,
      currentServiceId: serviceId,
      currentTravellerId: travellerId,
      emails: preparedEmployees,
    });
  };

  handlerSendInvoice = () => {
    this.setState({
      sendInvoiceDialog: true,
      email: this.props.trip.UserName,
    });
  };

  handlerTotalTripBill = (format) => {
    const {
      tripService, trip, companyId,
    } = this.props;

    tripService.getTotalTripBill(companyId, trip.TripId, format)
      .then((res) => {
        if (res.FileLink) {
          tripService.getFileTotalTripBill(res.FileLink);
        } else {
          this.setState({
            errorTotalTripBill: true,
            messageTotalTripBill: res.Message,
          });
        }
      });
  };

  handleChangeCheckbox = (itemId) => {
    const newCheckboxesArr = this.state.checkboxes;
    const indexOfItemId = newCheckboxesArr.indexOf(itemId);

    if (indexOfItemId !== -1) {
      newCheckboxesArr.splice(indexOfItemId, 1);
    } else {
      newCheckboxesArr.push(itemId);
    }

    this.setState({ checkboxes: newCheckboxesArr });
  };

  handleCheckAllOperations = (operations, voucherId, tripItemVersionId, tripItemId, companyId) => {
    const { checkAllOperational } = this.state;

    if (!checkAllOperational) {
      return this.setState({
        tripItemVersionId,
        checkboxesOperations: operations.map(({ Id }) => Id),
        operationsInf: operations,
        checkAllOperational: true,
        voucherId,
        tripItemId,
        selectedCompanyId: companyId,
        selectedTaxiVoucher: operations,
      });
    }

    return this.setState({
      tripItemVersionId: null,
      checkboxesOperations: [],
      operationsInf: [],
      checkAllOperational: false,
      voucherId: null,
      tripItemId: 0,
      selectedCompanyId: null,
      selectedTaxiVoucher: operations,
    });
  };

  handleChangeCheckboxOperations = (operations, operation, id, tripItemVersionId, tripItemId, companyId) => {
    const {
      voucherId, checkboxesOperations, operationsInf,
    } = this.state;
    const { Id } = operation;

    if (voucherId && voucherId !== id) {
      return this.setState({
        tripItemVersionId,
        checkboxesOperations: [Id],
        operationsInf: [operation],
        voucherId: id,
        tripItemId,
        selectedCompanyId: companyId,
        selectedTaxiVoucher: operations,
      });
    }

    const newCheckboxesArr = checkboxesOperations;
    const indexOfItemId = newCheckboxesArr.indexOf(Id);

    let newOperationsInf = operationsInf;

    if (indexOfItemId !== -1) {
      newCheckboxesArr.splice(indexOfItemId, 1);
      newOperationsInf = newOperationsInf.filter(({ Id: OperationId }) => OperationId !== Id);
    } else {
      newCheckboxesArr.push(Id);
      newOperationsInf.push(operation);
    }

    return this.setState({
      tripItemVersionId,
      checkboxesOperations: newCheckboxesArr,
      operationsInf: newOperationsInf,
      voucherId: id,
      tripItemId,
      selectedCompanyId: companyId,
      checkAllOperational: operations.length === newOperationsInf.length,
      selectedTaxiVoucher: operations,
    });
  };

  handleSendVersion = (id) => {
    this.toggleLoadingOfSendingIntegration(id);

    const { tripService, trip: { TripId } } = this.props;

    tripService.sendVersionOfIntegration(TripId, id, false)
      .then(() => this.toggleLoadingOfSendingIntegration(''))
      .catch(() => this.toggleLoadingOfSendingIntegration(''));
  };

  handleDownloadVersion = (guid) => this.props.tripService.downloadVersionOfIntegration(guid);

  toggleLoadingOfSendingIntegration = (value) => this.setState({ waitResSendIntegration: value });

  handleChangeEmail = (value) => {
    this.setState({ email: value });
  };

  handleChangeEmails = (value) => {
    this.setState({ emails: value });
  };

  handleToggleTripComments = (value) => this.setState({ tripCommentsDialog: value });

  showSendVouchersDialog = () => {
    this.setState({
      sendVouchersDialog: !this.state.sendVouchersDialog,
      email: this.props.trip.UserName,
    });
  };

  closeResultVouchersDialog = () => {
    this.setState({ sendResultVouchers: !this.state.sendResultVouchers });
  };

  closeConfirmCancelAirItem = () => {
    this.setState({ sendConfirmCancelAirItem: !this.state.sendConfirmCancelAirItem });
    this.props.reloadPage();
  };

  closeConfirmCancelAeroExpress = () => {
    this.setState({ sendConfirmCancelAeroExpressItem: false });
    this.props.reloadPage();
  };

  closeErrorAddTaxiTrip = () => {
    this.setState({
      errorAddTaxiTrip: !this.state.errorAddTaxiTrip,
      messageTaxi: '',
    });
    this.props.reloadPage();
  };

  closeSuccessAddTaxiTrip = () => {
    this.setState({ successAddTaxiTrip: !this.state.successAddTaxi });
    this.props.reloadPage();
  };

  sendVouchers = () => {
    const {
      trip,
      accountId,
      tripService,
    } = this.props;

    const { email } = this.state;
    const sendVouchers = () => {
      tripService.sendVouchers(accountId, trip.TripId, email).then(() => {
        this.setState({
          email: '',
          waitResSendVouchers: false,
          sendVouchersDialog: false,
          sendResultVouchers: true,
          resultVouchersText: DIALOGSTITLES.VOUCHERSSEND,
        });
      })
        .catch(({ body }) => {
          this.setState({
            waitResSendVouchers: false,
            sendVouchersDialog: false,
            sendResultVouchers: true,
            resultVouchersText: body.Message || DIALOGSTITLES.VOUCHERSENDERR,
          });
        });
    };

    this.setState({ waitResSendVouchers: true }, sendVouchers);
  };

  sendVoucher = (locale) => {
    const {
      accountId,
      companyId,
      tripService,
    } = this.props;

    const {
      currentItem, currentServiceId, currentTravellerId, emails,
    } = this.state;
    const employeesEmails = emails.replace(/ /g, '').split(',');

    const sendVoucher = () => {
      const isServiceForm = currentServiceId !== null;

      if (isServiceForm) {
        const email = currentItem.Employees.find(({ Id }) => Id === currentTravellerId).Email;

        tripService.sendServiceForm(accountId, currentItem.TripItemId, currentServiceId, email)
          .then(() => {
            this.setState({
              currentItem: null,
              currentServiceId: null,
              currentTravellerId: null,
              waitResSendVoucher: false,
              sendVoucherDialog: false,
              sendResultVouchers: true,
              resultVouchersText: DIALOGSTITLES.VOUCHERSSEND,
            });
          })
          .catch(() => {
            this.setState({
              waitResSendVoucher: false,
              sendVoucherDialog: false,
              sendResultVouchers: true,
              resultVouchersText: 'Не удалось отправить бланк услуги',
            });
          });

        return;
      }

      tripService.sendVoucher(companyId, currentItem.TripItemId, employeesEmails, locale)
        .then(() => {
          this.setState({
            currentItem: null,
            waitResSendVoucher: false,
            sendVoucherDialog: false,
            sendResultVouchers: true,
            resultVouchersText: DIALOGSTITLES.VOUCHERSSEND,
          });
        })
        .catch(({ body }) => {
          this.setState({
            waitResSendVoucher: false,
            sendVoucherDialog: false,
            sendResultVouchers: true,
            resultVouchersText: body.Message || DIALOGSTITLES.VOUCHERSENDERR,
          });
        });
    };

    this.setState({ waitResSendVoucher: true }, sendVoucher);
  };

  resultSendOldVoucher = (resultVouchersText) => {
    this.toggleOldVoucherDialog();
    this.setState({
      waitResSendVoucher: false,
      sendResultVouchers: true,
      resultVouchersText,
    });
  };

  handleSendOldVoucher = (employees, Id) => {
    const {
      accountId,
      tripService,
    } = this.props;

    const preparedEmployees = employees.map(({ Email }) => Email);

    const sendOldVoucher = () => {
      tripService.sendOldVoucher(accountId, Id, preparedEmployees)
        .then(() => this.resultSendOldVoucher(DIALOGSTITLES.VOUCHERSEND))
        .catch(() => this.resultSendOldVoucher(DIALOGSTITLES.VOUCHERSENDERR));
    };

    this.setState({ waitResSendVoucher: true }, sendOldVoucher);
  };

  handleLegalEntityButton = (tripItemId) => {
    const { tripService: { setLegalDialog, getLegalEntityList } } = this.props;

    this.setState({ tripItemId });

    getLegalEntityList(tripItemId);
    setLegalDialog(true);
  };

  sendInvoice = () => {
    const {
      trip,
      accountId,
      tripService,
    } = this.props;

    const { email } = this.state;

    this.setState({ waitResSendInvoice: true });

    tripService.sendInvoice(accountId, trip.TripId, email)
      .then(() => {
        this.setState({
          currentItem: null,
          waitResSendInvoice: false,
          sendInvoiceDialog: false,
          sendResultInvoice: true,
          resultInvoiceText: DIALOGSTITLES.INVOICESEND,
        });
      })
      .catch(() => {
        this.setState({
          waitResSendInvoice: false,
          sendInvoiceDialog: false,
          sendResultInvoice: true,
          resultInvoiceText: DIALOGSTITLES.INVOICESENDERR,
        });
      });
  };

  closeResultInvoiceDialog = () => {
    this.setState({ sendResultInvoice: !this.state.sendResultInvoice });
  };

  closeInvoiceDialog = () => this.setState({ sendInvoiceDialog: false });

  updateVersionButtons = (id) => this.props.tripService.updateVersionButtons(id);

  handleAirTripFieldValidationAir = (field, value) => this.props.airService.airTripFieldValidationAir(field, value);

  handleChangeTicketNumber = (id, ticketNumber) => this.props.airService.changeTicketNumber(id, ticketNumber);

  handleGetAirlaneTicketClosing = (id) => this.props.airService.getAirlaneTicketClosing(id);

  renderApplication = (trip) => {
    const { event } = this.props;
    const { showApplication } = this.state;
    const { MICEEventName, TripId } = trip;
    const { events } = this.props.tripService.get();

    if (!events.length) {
      return null;
    }

    const label = event === NO_EVENT.FIRST && !!MICEEventName ? MICEEventName : event;
    const isDisabledDelete = label === NO_EVENT.FIRST || label === NO_EVENT.SECOND;

    const onChange = (value) => {
      this.props.onChangeApplication(value, TripId);

      this.setState({ showApplication: false });
    };

    if (showApplication) {
      return (
        <div
          className={ styles.wrapper_application }
        >
          <Select
            theme='border'
            className={ styles.wrapper_app }
            label={ LABELS.EVENT_SELECT }
            items={ events }
            value={ event }
            onChange={ (value) => onChange(value, TripId) }
            overflow
          />
          <button
            className={ styles.button_shell_mar }
            onClick={ () => this.setState({ showApplication: false }) }
          >
            <i className='material-icons'>cancel</i>
          </button>
        </div>
      );
    }

    return (
      <div
        className={ styles.wrapper_application }
      >
        <label>
          { LABELS.EVENT }
        </label>
        <div>
          { label }
        </div>
        <div
          className={ styles.wrapper_button_app }
        >
          <button
            className={ styles.button_shell }
            onClick={ () => this.setState({ showApplication: true }) }
          >
            <i className='material-icons'>edit</i>
          </button>
          <div
            className={ styles.button_app }
          >
            <button
              className={ styles.button_shell }
              onClick={ () => this.props.onDeleteModal() }
              disabled={ isDisabledDelete }
            >
              <i className='material-icons'>close</i>
            </button>
          </div>
        </div>
      </div>
    );
  };

  renderEstimate = (trip) => {
    const { showEditEstimate, stateEstimate } = this.state;
    const { featureFlagsService: { getTotalAmount }, estimate } = this.props;
    const { TripId } = trip;

    if (!getTotalAmount()) {
      return null;
    }

    const valueEstimate = String(estimate) !== VALID_SYMBLOL.ZERO
      ? MoneyFormat.moneyWithDecimal(estimate, true)
      : LABELS.EMPTY;

    if (showEditEstimate) {
      return (
        <div
          className={ `${styles.wrapper_application} ${styles.wrapper_estimate}` }
        >
          <label>
            { LABELS.ESTIMATE }
          </label>
          <Input
            className={ styles.wrapper_input_est }
            value={ stateEstimate }
            onChange={ (value) => this.onChangeStateEstimate(value) }
          />
          <button
            className={ styles.button_shell_mar }
            onClick={ () => this.onChangeEstimate(stateEstimate, TripId) }
          >
            <i className='material-icons'>done</i>
          </button>
          <button
            className={ styles.button_shell_mar }
            onClick={ () => this.onChangeEstimate(estimate, TripId) }
          >
            <i className='material-icons'>cancel</i>
          </button>
        </div>
      );
    }

    return (
      <div className={ `${styles.wrapper_application} ${styles.wrapper_estimate}` }>
        <label>
          { LABELS.ESTIMATE }
        </label>
        <div>
          { valueEstimate }
        </div>
        <div
          className={ styles.wrapper_button_app }
        >
          <button
            className={ styles.button_shell }
            onClick={ () => this.setState({ showEditEstimate: true }) }
          >
            <i className='material-icons'>edit</i>
          </button>
        </div>
      </div>
    );
  };

  renderStatusButton = (status) => {
    const {
      waitResStartEdit, waitResCancelEdit, waitResSaveTrip,
    } = this.state;

    switch (status) {
      case TRIPSTATUS.AWAITINGCONFIRMATION:
        return (
          <div className={ styles.action }>
            <AjaxButton
              label={ TRIPBUTTONS.CONFIRMTRIP }
              theme={ FLAT }
              loading={ waitResSaveTrip }
              onClick={ this.handlerConfirmChange }
            />
            <AjaxButton
              label={ TRIPBUTTONS.CANCELTRIP }
              theme={ FLAT }
              loading={ waitResCancelEdit }
              onClick={ () => this.handleCancelChange(TRIPSTATUS.WAITRESCANCELEDIT) }
            />
          </div>
        );

      case TRIPSTATUS.AWAITINGTICKETING:
        return (
          <div className={ styles.action }>
            <AjaxButton
              label={ TRIPBUTTONS.BOOKTRIP }
              theme={ FLAT }
              loading={ waitResSaveTrip }
              onClick={ this.handlerConfirmChange }
            />
          </div>
        );

      default:
        return (
          <div className={ styles.action }>
            <AjaxButton
              label={ TRIPBUTTONS.CHANGESTATUSTRIP }
              theme={ FLAT }
              loading={ waitResStartEdit }
              onClick={ this.handlerChangeStatus }
            />
          </div>
        );
    }
  };

  renderOrdersButtons = (checkboxes) => {
    if (!checkboxes.length) return null;

    return (
      <div className={ styles.action }>
        <AjaxButton
          label={ TRIPBUTTONS.DELETEORDERS }
          theme={ FLAT }
          loading={ this.state.waitResCheckOrdersDelete }
          onClick={ () => this.checkOrders('Delete') }
        />
        <AjaxButton
          label={ TRIPBUTTONS.REMOVEORDERS }
          theme={ FLAT }
          loading={ this.state.waitResCheckOrdersMove }
          onClick={ () => this.checkOrders('Move') }
        />
      </div>
    );
  };

  renderRemoveTripButton = () => {
    const { trip } = this.props;
    const isTripPaidByPersonalFunds = trip.Labels.includes(VALUES.TAG);

    if (isTripPaidByPersonalFunds) return null;

    return (
      <AjaxButton
        label={ TRIPBUTTONS.REMOVETRIP }
        theme={ FLAT }
        loading={ this.state.waitResCheckTripMove }
        onClick={ () => this.checkTrip('Move') }
      />
    );
  };

  renderTripButtons = (someItemIsTaxi, isUploadedIntegration) => {
    const { tripChange1C, checkboxes } = this.state;

    if (someItemIsTaxi || checkboxes.length || (!tripChange1C && isUploadedIntegration)) {
      return null;
    }

    return (
      <div className={ styles.action }>
        <AjaxButton
          label={ TRIPBUTTONS.DELETETRIP }
          theme={ FLAT }
          loading={ this.state.waitResCheckTripDelete }
          onClick={ () => this.checkTrip('Delete') }
        />
        { this.renderRemoveTripButton() }
      </div>
    );
  };

  prohibitionCompaniesMapper = (prohibitionVouchers, companies, trip) => {
    const idsOfProhitionCompanies = trip.Companies
      .filter((id) => prohibitionVouchers.some((prohibitionCompany) => id === prohibitionCompany));

    const namesOfProhitionCompanies = companies
      .filter(({ id }) => idsOfProhitionCompanies.some((companyId) => id === companyId))
      .map(({ name }) => name)
      .join(', ');

    return namesOfProhitionCompanies;
  };

  renderProhibitionCompaniesMassage = (style = false) => {
    const { prohibitionVouchers } = this.state;
    const { companies, trip } = this.props;

    const bannedcompaniesNames = this.prohibitionCompaniesMapper(prohibitionVouchers, companies, trip);

    if (!bannedcompaniesNames) {
      return null;
    }

    const theBanIsNotForAllCompanies = prohibitionVouchers.length && !style
      ? (
        <div className={ styles.prohibion_massage }>
          <div className={ styles.prohibition_text }>
            { ' ' }
            { LABELS.BANNED_COMPANY_NAMES(bannedcompaniesNames) }
          </div>
          <div className={ styles.prohibition_text }>
            { ' ' }
            { LABELS.PROHIBITION_VOUCHERS }
            { ' ' }
          </div>
        </div>
      )
      : (
        <div className={ styles.prohibion_massage }>
          { LABELS.BANNED_COMPANY_NAMES(bannedcompaniesNames) }
          { ' ' }
          { LABELS.PROHIBITION_VOUCHERS }
        </div>
      );

    return theBanIsNotForAllCompanies;
  };

  renderListOrders = ({
    list, statusBook, integration, integrationVersions, waitResSendIntegration, waitResChangeVoucher, versionButtons,
  }) => {
    const { trip } = this.props;

    if (!list || !list.length) {
      return null;
    }

    const {
      checkboxes,
      checkboxesOperations,
      tripChange1C,
      waitResSaveTrip,
      waitResSaveVat,
      manualTrainCancel,
      prohibitionVouchers,
      companySettings,
      checkAllOperational,
      operationsInf,
      isShowAllVersionDate,
      flagEditTripItemVersion,
    } = this.state;

    const {
      featureFlagsService, tripService, legalAllowEditingValues, reloadPage,
    } = this.props;
    const { getAandaContractorChange } = featureFlagsService;

    const isTripPaidByPersonalFunds = trip.Labels.includes(VALUES.TAG);

    let checkboxDisabled = false;

    if (list.length === 1) {
      checkboxDisabled = true;
    } else if (list.length === 2) {
      list.forEach((order) => {
        order.Versions.forEach((version) => {
          if (version.Status === 3 || version.Status === 4) {
            checkboxDisabled = true;
          }
        });
      });
    }

    const showButtonFlightCertificateInAir = (tripItem, listItems) => {
      if (tripItem.ActualVersion.ServiceType !== SERVICETYPE.AIR) {
        return false;
      }

      return !listItems.some((item) => {
        const { ServiceType, JsonData } = item.ActualVersion;

        if (ServiceType !== SERVICETYPE.FLIGHT_CERTIFICATE) {
          return false;
        }

        return tripItem.Id === JSON.parse(JsonData).AirTripItemId;
      });
    };

    return list.map((item) => renderItem({
      reloadPage,
      onAirTripFieldValidationAir: this.handleAirTripFieldValidationAir,
      onChangeTicketNumber: this.handleChangeTicketNumber,
      onGetAirlaneTicketClosing: this.handleGetAirlaneTicketClosing,
      handlerEditItem: this.handlerEditItemWithDialog,
      handlerCancelItem: this.handlerCancelItem,
      handlerAutoCancelItem: this.handlerAutoCancelItem,
      handlerChangeTicketStatus: this.handlerChangeTicketStatus,
      onManualCancelItem: this.handleManualCancelItem,
      onCancelAirPenaltyItem: this.handleCancelAirPenaltyItem,
      onHandlerAdjustCancellation: this.handlerAdjustCancellation,
      onHandlerAddServiceItem: this.handlerAddServiceItem,
      onHandlerVoidingModal: this.handlerVoidingModal,
      handleShowViewItem: this.handleShowViewItem,
      handleShowEditCC: this.handleShowEditCC,
      handlerDeleteItem: this.handlerDeleteItem,
      onDownloadVoucher: this.handleOpenDownloadVoucherDialog,
      onDownloadVoucherGroupLiving: this.handleDownloadVoucherGroupLiving,
      onDownloadBreakfastVoucher: this.handleDownloadBreakfastVoucher,
      onDownloadFlightCertificate: this.handleDownloadFlightCertificate,
      onGetStatusFlightCertificate: this.handelGetStatusFlightCertificate,
      onBookFlightCertificate: this.handelBookFlightCertificate,
      onDownloadOldVoucher: this.handleDownloadOldVoucher,
      onChangeTrainVoucher: this.handleChangeTrainVoucher,
      onChangeAeroExpressVoucher: this.handleChangeAeroExpressVoucher,
      handlerSendVoucher: this.handlerSendVoucher,
      onSendOldVoucher: this.toggleOldVoucherDialog,
      handlerSendInvoice: this.handlerSendInvoice,
      handleLegalEntityButton: this.handleLegalEntityButton,
      companies: this.props.companies,
      prohibitionVouchers,
      companySettings,
      showButtonFlightCertificateInAir: showButtonFlightCertificateInAir(item, list),
      checkboxes,
      checkboxesOperations,
      tripChange1C,
      manualTrainCancel,
      handleChangeCheckbox: this.handleChangeCheckbox,
      onChangeCheckboxOperations: this.handleChangeCheckboxOperations,
      onCheckAllOperations: this.handleCheckAllOperations,
      onOpenDeleteOperationsDialog: this.handleOpenDeleteOperationsDialog,
      onOpenMoveOperationsDialog: this.handleOpenMoveOperationsDialog,
      checkActualVersion: this.checkActualVersion,
      checkActualVersionFromConfirm: this.checkActualVersionFromConfirm,
      onSendVersion: this.handleSendVersion,
      onDownloadVersion: this.handleDownloadVersion,
      onUpdateVersionButtons: this.updateVersionButtons,
      onAddTaxi: this.handleAddTaxi,
      downloadServiceForm: this.downloadServiceForm,
      sendServiceForm: this.sendServiceForm,
      item,
      list,
      operationsInf,
      statusBook,
      checkboxDisabled,
      integration,
      integrationVersions,
      waitResSendIntegration,
      waitResChangeVoucher,
      versionButtons,
      waitResSaveTrip,
      waitResSaveVat,
      checkAllOperational,
      flagChangeProject: featureFlagsService.get().ChangeProject,
      flagAandaContractorChange: getAandaContractorChange(),
      flagReplaceTaxi: featureFlagsService.get().ReplaceTaxiVoucher,
      onEditVatVersion: this.handleEditVatVersion,
      flagEditTripItemVersion,
      sendVersionTime: tripService.sendVersionTime,
      saveVersionsDateInfo: tripService.saveVersionsDateInfo,
      getStatusEditLegalEntity: tripService.getStatusEditLegalEntity,
      versionsDateInfo: tripService.get().versionsDateInfo,
      tripId: trip.TripId,
      saveVersionDateData: this.saveVersionDateData,
      hiddenAllVersionDate: this.hiddenAllVersionDate,
      isShowAllVersionDate,
      legalAllowEditingValues,
      isTripPaidByPersonalFunds,
    }));
  };

  renderButtonTripComponents = () => !!this.state.tripComments.length && (
    <div className={ styles.button }>
      <Button
        label={ LABELS.TRIP_COMMENTS }
        theme={ FLAT }
        onClick={ () => this.handleToggleTripComments(true) }
      />
    </div>
  );

  renderAdditionalServiceDialog = () => {
    const {
      additionalServiceDialog: {
        show,
        labelContinue,
        handleConfirm,
        handleCancel,
      },
    } = this.state;

    if (!show) return null;

    return (
      <AdditionalServiceDialog
        onClose={ handleCancel }
        labelInfo={ LABELS.ADDITIONAL_INFO }
        onConfirm={ handleConfirm }
        labelContinue={ labelContinue }
      />
    );
  };

  renderTripCommentsDialog = () => {
    const { tripCommentsDialog, tripComments } = this.state;

    if (!tripCommentsDialog) return null;

    return (
      <TripCommentsDialog
        comments={ tripComments }
        onClose={ this.handleToggleTripComments }
      />
    );
  };

  renderTitle = (trip, isAwaitingConfirmation, isProhibitionVoucher) => {
    const { specialBillAccount } = this.props;
    const isTripPaidByPersonalFunds = trip.Labels.includes(VALUES.TAG);

    const sendVouchersButton = (
      <Button
        label={ LABELS.SEND_VOUCHERS }
        theme={ FLAT }
        disabled={ isProhibitionVoucher }
        onClick={ this.showSendVouchersDialog }
      />
    );

    const renderSendInvoiceButton = () => {
      if (isTripPaidByPersonalFunds) return null;

      return (
        <Button
          label={ LABELS.SEND_INVOICE }
          theme={ FLAT }
          onClick={ this.handlerSendInvoice }
        />
      );
    };

    const renderTotalTripBillButton = () => {
      if (isTripPaidByPersonalFunds) return null;

      return (
        <LinkButton
          theme={ COMPONENTS.BUTTON.THEME.OPEN }
          wrapClass={ styles.wrapper_link_btn }
          label={ LABELS.TOTAL_TRIP_BILL }
          onClick={ () => this.handlerTotalTripBill(FILEFORMATS.PDF) }
        >
          <LinkButtonItem
            onClick={ () => this.handlerTotalTripBill(FILEFORMATS.XLSX) }
          >
            { LABELS.TOTAL_TRIP_BILL_XLSX }
          </LinkButtonItem>
        </LinkButton>
      );
    };

    const renderMergeTripsButton = () => {
      if (isTripPaidByPersonalFunds) return null;

      return (
        <Button
          label={ LABELS.MERGE_TRIPS }
          theme={ FLAT }
          onClick={ this.handleChangeMergeTrips(true) }
        />
      );
    };

    const banForAllCompanies = (
      <div>
        { ' ' }
        { LABELS.PROHIBITION_VOUCHERS }
        { ' ' }
      </div>
    );

    const prohibitionMessage = isProhibitionVoucher ? banForAllCompanies : this.renderProhibitionCompaniesMassage();

    const renderAddOrderButton = () => {
      if (isAwaitingConfirmation && !isTripPaidByPersonalFunds) {
        const { openSubMenu } = this.state;
        // const { InsuranceStatus } = trip;

        if (specialBillAccount) {
          return (
            <div
              className={ styles.action }
              ref={ (ref) => {
                this.submenu = ref;
              } }
            >
              <Button label='ДОБАВИТЬ ЗАКАЗ' theme={ FLAT } onClick={ this.handlerAddOrderItem } />
              <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
                <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('Air', e) }>
                  Авиабилет
                </div>
              </div>
            </div>
          );
        }

        // const insuranceButton = InsuranceStatus !== STATUSES_INSURANCE.Insured ? (
        //   <div className={ styles['menu-item'] } onClick={ e => this.handleNewItem('Insurance', e) }>
        //     Страховка
        //   </div>
        // ) : null;

        return (
          <div
            className={ styles.action }
            ref={ (ref) => {
              this.submenu = ref;
            } }
          >
            <Button label='ДОБАВИТЬ ЗАКАЗ' theme={ FLAT } onClick={ this.handlerAddOrderItem } />
            <div className={ `${styles['sub-menu']} ${openSubMenu && styles.open}` }>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('Air', e) }>
                Авиабилет
              </div>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('Hotel', e) }>
                Отель
              </div>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('Transfer', e) }>
                Трансфер
              </div>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('Custom', e) }>
                Кастомная услуга
              </div>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('VipHall', e) }>
                Vip-зал
              </div>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('AeroExpress', e) }>
                Аэроэкспресс
              </div>
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('MICE', e) }>
                Мероприятие
              </div>
              { /* { insuranceButton } */ }
              { /* Скрытый релиз страховок */ }
              <div className={ styles['menu-item'] } onClick={ (e) => this.handleNewItem('Bus', e) }>
                Автобусный билет
              </div>
              { /* <div className={ styles['menu-item'] } onClick={ e => this.handleNewItem('Train', e) }> */ }
              { /* ЖД */ }
              { /* </div> */ }
              { /* Раскомитить когда будет бэк для метаданных по жд */ }
            </div>
          </div>
        );
      }

      return null;
    };

    return (
      <div className={ styles.title }>
        <div className={ styles.col_1 }>
          <div>
            Поездка #
            { trip.TripId }
            { ' ' }
            создана
            { ' ' }
            { formatDate(trip.Date, FORMATDATETIME) }
            { ' ' }
            -
            { ' ' }
            { trip.UserName }
          </div>
          <div onClick={ () => this.handleDownloadTripApprovalReport(trip.TripId) } className={ styles.tripInfoLink }>
            { LABELS.AGREEMENT_INFO }
          </div>
          { this.renderButtonTripComponents() }
        </div>
        <div className={ styles['button-box'] }>
          { prohibitionMessage }
          { sendVouchersButton }
          { renderSendInvoiceButton() }
          { renderTotalTripBillButton() }
          { renderMergeTripsButton() }
        </div>
        { renderAddOrderButton() }
      </div>
    );
  };

  renderSendVoucherDialog = () => {
    const {
      sendVoucherDialog, waitResSendVoucher, emails, currentItem,
    } = this.state;

    if (!sendVoucherDialog) return null;

    return (
      <SendDialog
        email={ emails }
        title={ LABELS.SEND_VOUCHER_ON }
        onSend={ this.sendVoucher }
        onEmailChange={ this.handleChangeEmails }
        loading={ waitResSendVoucher }
        onClose={ () => this.setState({
          sendVoucherDialog: false,
          voucherEmployees: [],
          currentServiceId: null,
          currentTravellerId: null,
        }) }
        showLocaleButtons={ currentItem.ServiceType === SERVICETYPE.HOTEL }
      />
    );
  };

  renderDownloadVoucherDialog = () => {
    const { downloadVoucherDialog, waitResSendVoucher } = this.state;

    if (!downloadVoucherDialog) return null;

    return (
      <DownloadDialog
        title={ LABELS.DOWNLOAD_VOUCHER }
        onDownload={ this.handleDownloadVoucher }
        loading={ waitResSendVoucher }
        onClose={ () => this.setState({
          downloadVoucherDialog: false,
          tripItemId: 0,
        }) }
      />
    );
  };

  renderLegalEntityDialog = () => {
    const {
      isLegalDialog,
      tripService: {
        setLegalDialog,
        sendLegalEntity,
        setLegalValue,
      },
      legalEntity,
      waitPreloader,
      legalSelectValue,
    } = this.props;
    const { tripItemId } = this.state;

    if (!isLegalDialog) return null;

    return (
      <LegalEntityDialog
        waitPreloader={ waitPreloader }
        isLegalDialog={ isLegalDialog }
        setLegalDialog={ setLegalDialog }
        legalEntity={ legalEntity }
        sendLegalEntity={ sendLegalEntity }
        tripItemId={ tripItemId }
        setLegalValue={ setLegalValue }
        legalSelectValue={ legalSelectValue }
      />
    );
  };

  renderErrorAddTaxiTrip = () => {
    const { errorAddTaxiTrip, messageTaxi } = this.state;

    return errorAddTaxiTrip
      ? (
        <Dialog onClick={ this.closeErrorAddTaxiTrip } width={ MIDDLE }>
          <div className={ styles['user-dialog'] }>
            <p className={ styles['dialog-item'] }>{ messageTaxi }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button label={ TRIPBUTTONS.CLOSE } theme={ FLAT } onClick={ this.closeErrorAddTaxiTrip } />
            </div>
          </div>
        </Dialog>
      )
      : null;
  };

  renderErrorTotalTripBill = () => {
    const { errorTotalTripBill, messageTotalTripBill } = this.state;

    return errorTotalTripBill
      ? (
        <Dialog onClick={ this.closeErrorAddTaxiTrip } width={ MIDDLE }>
          <div className={ styles['user-dialog'] }>
            <p className={ styles['dialog-item'] }>{ messageTotalTripBill }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button label={ TRIPBUTTONS.CLOSE } theme={ FLAT } onClick={ this.closeErrorAddTaxiTrip } />
            </div>
          </div>
        </Dialog>
      )
      : null;
  };

  renderSuccessAddTaxiTrip = () => {
    const { successAddTaxiTrip } = this.state;

    return successAddTaxiTrip
      ? (
        <Dialog onClick={ this.closeSuccessAddTaxiTrip } width={ MIDDLE }>
          <div className={ styles['user-dialog'] }>
            <p className={ styles['dialog-item'] }>{ DIALOGSTITLES.SUCCESS_ADD_TAXI }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button label={ TRIPBUTTONS.CLOSE } theme={ FLAT } onClick={ this.closeSuccessAddTaxiTrip } />
            </div>
          </div>
        </Dialog>
      )
      : null;
  };

  renderOldVoucher = ({ Employees, Id }) => {
    if (!Employees.length) {
      return null;
    }

    return (
      <SendVoucher
        waitingResponse={ this.state.waitResSendVoucher }
        employees={ Employees }
        onSend={ () => this.handleSendOldVoucher(Employees, Id) }
        onClose={ this.toggleOldVoucherDialog }
      />
    );
  };

  renderPenaltyDialog = () => {
    const {
      changeRate, tripService, accountService,
    } = this.props;
    const {
      cancelItem, loading, tickets,
    } = this.state;

    return (
      <PenaltyForm
        changeRate={ changeRate }
        cancelLoading={ loading }
        tickets={ tickets }
        getCancelTripInfo={ tripService.getCancelTripInfo }
        cancelCurrencyAmount={ tripService.cancelCurrencyAmount }
        accountService={ accountService }
        item={ cancelItem }
        onChange={ this.handlerConfirmCancelItem }
        toggleDialog={ this.handlerClosePenaltyDialog }
      />
    );
  };

  renderAirPenaltyDialog = () => {
    const { changeRate } = this.props;
    const { cancelItem } = this.state;

    const additionalMessage = this.getAdditionalItemMessage(cancelItem.TripItemId);

    return (
      <AirPenaltyForm
        changeRate={ changeRate }
        item={ cancelItem }
        onChange={ this.handlerConfirmAirCancelItem }
        toggleDialog={ this.handlerCloseAirPenaltyDialog }
        additionalMessage={ additionalMessage }
      />
    );
  };

  handlerConfirmCancelVersionItem = (item) => {
    const { cancelVersionItem } = this.state;

    this.addVersions(item, cancelVersionItem);
    this.handlerCloseVersionPenaltyDialog();
  };

  handlerCloseVersionPenaltyDialog = () => {
    this.setState({
      cancelVersionDialog: false,
      cancelVersionItem: null,
    });
  };

  handlerAdjustCancellation = (item) => {
    this.setState({ cancelVersionItem: item });
  };

  renderPenaltyVersionDialog = () => {
    const { cancelVersionItem } = this.state;

    return (
      <PenaltyVersionForm
        item={ cancelVersionItem }
        onChange={ this.handlerConfirmCancelVersionItem }
        toggleDialog={ this.handlerCloseVersionPenaltyDialog }
      />
    );
  };

  handlerConfirmAdjust = () => {
    this.setState({ cancelVersionDialog: true });
  };

  handleSendСС = (objChangeCC, validate) => {
    const { accountId, trip: { TripId } } = this.props;

    if (validate) {
      return;
    }
    this.props.tripService.сhangeСostСenter(objChangeCC, accountId, TripId);
    this.setState({ flagCC: false, checkingArchivalСС: false });
  };

  handleSelectProject = ({ id }) => {
    const { tripService, projects } = this.props;
    const { mandatoryProject } = this.state;

    const emptyProjects = projects.length
      ? tripService.trainTripFieldValidation(FIELDS.PROJECTID, id)[0]
      : tripService.trainTripFieldValidation(FIELDS.PROJECTID, id)[1];
    const result = mandatoryProject ? emptyProjects : '';

    tripService.changeField(result, `${FIELDS.VALIDATION}.${FIELDS.PROJECTID}`);

    this.setState({ projectId: id });
  };

  handleClear = () => {
    const { projects } = this.props;

    this.setState({
      label: '',
      suggests: projects,
      checkingArchivalСС: false,
    });

    return this.handleSelectProject({ name: '', id: 0 });
  };

  onChangeSuggest = (value) => {
    const { projects } = this.props;

    const onValue = () => {
      if (!value) {
        return projects;
      }

      return this.props.projects.filter((item) => !item.isArchival && item.name.includes(value));
    };

    this.setState({
      suggests: onValue(),
      label: value,
    });
  };

  handleSelect = (item) => {
    const { projects } = this.props;

    this.setState({
      label: item.name,
      suggests: projects,
      checkingArchivalСС: item.isArchival,
    });

    return this.handleSelectProject(item);
  };

  renderWarningLine = (isLabelEmpty) => {
    const { checkingArchivalСС } = this.state;

    if (isLabelEmpty) {
      return <span className={ styles['error-msg'] }>{ ERROR.EMPTY_INPUT }</span>;
    }

    if (checkingArchivalСС) {
      return <span className={ styles['error-msg'] }>{ ERROR.ARCHIVE_COST_CENTER }</span>;
    }

    return null;
  };

  renderItem = ({ name }) => (
    <div className={ styles['autocomplete-item'] }>
      { name }
    </div>
  );

  handleShowEditCC = (flagCC, order) => {
    const currentLabel = foundProjectName(order.ProjectId, this.props.projects);

    this.setState({
      flagCC, label: currentLabel, projectId: order.ProjectId, tripItemId: order.TripItemId,
    }, this.scrollDown);
  };

  getAdditionalItemMessage = (TripItemId) => {
    const { trip: { OrderItems } } = this.props;

    const actualVersionsAdditional = OrderItems
      .filter(({ ActualVersion: { ServiceType } }) => ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE)
      .map(({ ActualVersion }) => ActualVersion);
    const tripHaveAddional = actualVersionsAdditional
      .some(({ JsonData, Status }) => JSON.parse(JsonData).AirTripItemId === TripItemId && Status === 0);

    return tripHaveAddional ? LABELS.ADDITIONAL_INFO : '';
  };

  renderVoidingDialog = () => {
    const {
      tripService, accountId, trip: { TripId }, reloadPage,
    } = this.props;
    const { voidingItem } = this.state;

    if (!voidingItem) return null;

    const additionalMessage = this.getAdditionalItemMessage(voidingItem.TripItemId);

    return voidingItem
      && <VoidingDialog
        item={ voidingItem }
        onClose={ () => this.handlerVoidingModal() }
        tripService={ tripService }
        accountId={ accountId }
        tripId={ TripId }
        reloadPage={ reloadPage }
        additionalMessage={ additionalMessage }
      />;
  };

  renderChangeVersionDateDialog = () => {
    const {
      showChangeVersionDateDialog,
      versionDateData,
    } = this.state;
    const {
      trip: { TripId },
      tripService,
      reloadPage,
    } = this.props;

    if (!showChangeVersionDateDialog) return null;

    return (
      <ChangeVersionDateDialog
        data={ versionDateData }
        tripId={ TripId }
        tripService={ tripService }
        closeChangeVersionDateDialog={ this.closeChangeVersionDateDialog }
        reloadPage={ reloadPage }
      />
    );
  };

  renderVatNotificationDialog = () => {
    const { showVatErrorDialog, vatErrorText } = this.state;

    if (!showVatErrorDialog) return null;

    return (
      <VatErrorDialog text={ vatErrorText } onClose={ this.closeErrorVatDialog } />
    );
  };

  renderPaymentType = () => {
    const {
      trip,
      featureFlagsService,
    } = this.props;

    const paymentType = trip.Labels.includes(VALUES.TAG)
      ? LABELS.PAYMENT_TYPES.CARD
      : LABELS.PAYMENT_TYPES.ACCOUNT;

    if (!featureFlagsService.getAcquiringSettingChange()) return null;

    return (
      <div>
        <label>{ LABELS.PAYMENT_TYPES.NAME }</label>
        { paymentType }
      </div>
    );
  };

  render() {
    const {
      trip,
      accountId,
      companies,
      specialBillAccount,
      users,
      airService,
      tripService,
      companyId,
      projects,
      projectsForNewTrip,
      moveToCompany,
      travelPolicyService,
      changeRate,
      integration,
      integrationVersions,
      mandatoryProject,
      versionButtons,
      analytics,
      tripAnalyticsValueIds,
      setTripAnalytics,
      guidService,
      notificationsService,
      accountService,
      aeroexpressService,
    } = this.props;

    const {
      versionItem,
      newItem,
      editItem,
      cacheItem,
      copyItem,
      viewItem,
      editPartItem,
      cancelDialog,
      airCancelPenaltyForm,
      cancelVersionDialog,
      resultTextMoveVouchers,
      versions,
      openDialog,
      checkboxes,
      activeDialog,
      closingDocuments,
      activeConfirmDialog,
      closingConfirmDocuments,
      sendVouchersDialog,
      oldVoucherDialog,
      sendResultVouchers,
      sendConfirmCancelAirItem,
      sendConfirmCancelAeroExpressItem,
      sendResultInvoice,
      resultVouchersText,
      resultInvoiceText,
      email,
      operationsInf,
      waitResSendVouchers,
      waitResSendInvoice,
      waitResDelete,
      waitResMove,
      waitResChange,
      sendInvoiceDialog,
      showSaveMetadataDialog,
      waitResSendIntegration,
      waitResChangeVoucher,
      disabledButton,
      cancelTrainDialog,
      cancelTrainItem,
      cancelAirItem,
      cancelAeroExpressItem,
      changeTicketItem,
      cancelAirDialog,
      cancelAeroExpressDialog,
      changeTicketDialog,
      prohibitionVouchers,
      loading,
      label,
      suggests,
      flagCC,
      projectId,
      tripItemId,
      isLeaveExcess,
      surcharges,
      selectedSubAccount,
      selectedCompanyId,
      checkAllOperational,
      documentNumber,
      tickets,
    } = this.state;

    const {
      OrderItems = [], Status, Companies, TripId,
    } = trip;

    const isAwaitingConfirmation = Status === 'AwaitingConfirmation';

    const isTripPaidByPersonalFunds = trip.Labels.includes(VALUES.TAG);

    const isProhibitionVoucher = Companies.every((id) => prohibitionVouchers.some((CompanyId) => CompanyId === id));

    const listOpts = {
      list: OrderItems,
      statusBook: Status,
      integration,
      integrationVersions,
      waitResSendIntegration,
      waitResChangeVoucher,
      versionButtons,
    };

    const renderTitleHtml = this.renderTitle(trip, isAwaitingConfirmation, isProhibitionVoucher);
    const renderListOrdersHtml = this.renderListOrders(listOpts);

    let dialogHtml = null;

    if (openDialog) {
      dialogHtml = (
        <Dialog onClick={ this.handleCloseDialog }>
          <DialogForm
            users={ users }
            handlerConfirmUsers={ this.handlerConfirmUsers }
          />
        </Dialog>
      );
    }

    const oldVoucherDialogHtml = oldVoucherDialog && this.renderOldVoucher(versionItem);

    const sendResultVouchersHtml = sendResultVouchers
      ? (
        <Dialog onClick={ this.closeResultVouchersDialog } width={ MIDDLE }>
          <div className={ styles['user-dialog'] }>
            <p className={ styles['dialog-item'] }>{ resultVouchersText }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button label='Закрыть' theme={ FLAT } onClick={ this.closeResultVouchersDialog } />
            </div>
          </div>
        </Dialog>
      )
      : null;

    const sendResultInvoiceHtml = sendResultInvoice
      ? (
        <Dialog onClick={ this.closeResultInvoiceDialog } width={ MIDDLE }>
          <div className={ styles['user-dialog'] }>
            <p className={ styles['dialog-item'] }>{ resultInvoiceText }</p>
            <div className={ `${styles.row} ${styles.right}` }>
              <Button label='Закрыть' theme={ FLAT } onClick={ this.closeResultInvoiceDialog } />
            </div>
          </div>
        </Dialog>
      )
      : null;

    let itemHtml = null;
    if (newItem) {
      itemHtml = renderNew({
        companies,
        projectsForNewTrip,
        mandatoryProject,
        tripService,
        airService,
        aeroexpressService,
        travelPolicyService,
        companyId,
        item: newItem,
        onConfirm: this.handleConfirmNew,
        onCancel: this.handleCancelNew,
        accountId,
        analytics,
        tripAnalyticsValueIds,
        setTripAnalytics,
        addTrip: this.addTrip,
        guidService,
        specialBillAccount,
        TripId,
      });
    } else if (copyItem) {
      itemHtml = renderCopy({
        companies,
        projectsForNewTrip,
        mandatoryProject,
        tripService,
        airService,
        aeroexpressService,
        travelPolicyService,
        companyId,
        item: copyItem,
        onConfirm: this.handleConfirmCopy,
        onCancel: this.handleCancelCopy,
        accountId,
        analytics,
        tripAnalyticsValueIds,
        setTripAnalytics,
        addTrip: this.addTrip,
        guidService,
        specialBillAccount,
        TripId,
      });
    } else if (cacheItem) {
      itemHtml = renderCacheItem({
        companies,
        projectsForNewTrip,
        mandatoryProject,
        tripService,
        airService,
        aeroexpressService,
        travelPolicyService,
        companyId,
        item: cacheItem,
        accountId,
        onConfirm: this.handleConfirmEdit,
        onCancel: this.handleCancelEdit,
        analytics,
        tripAnalyticsValueIds,
        setTripAnalytics,
        addTrip: this.addTrip,
        guidService,
        specialBillAccount,
        TripId,
      });
    }

    const isLabelEmpty = label === '' && mandatoryProject.some((id) => id === companyId);
    const menuStyle = suggests.length >= ITEMS_VISIBLE_LENGTH ? { ...MENU_STYLE, ...SCROLL_STYLE } : MENU_STYLE;
    const someItemIsTaxi = OrderItems
      && OrderItems.some(({ ActualVersion: { ServiceType } }) => ServiceType === SERVICETYPE.TAXI);
    const isUploadedIntegration = isUploadedIntegrationVersion(OrderItems, integrationVersions);
    const statusButtonHtml = this.renderStatusButton(Status);
    const subAccounts = accountService.get().subAccounts;

    const confirmCancelAirItem = sendConfirmCancelAirItem && this.renderConfirmCancelAirItem();
    const confirmCancelAeroexpress = sendConfirmCancelAeroExpressItem && this.renderConfirmCancelAeroexpressItem();

    const clearHtml = !!label.length
      && (
        <button onClick={ this.handleClear } className={ styles.button_clear } disabled={ !companyId }>
          <i className='material-icons'>clear</i>
        </button>
      );

    return (
      <div>
        <div className={ styles.row }>
          <div>
            <div className={ styles.panel }>
              { renderTitleHtml }
              <div className={ styles.form }>
                <div className={ styles.row }>
                  <div className={ styles.status }>
                    <div>
                      <label>
                        { LABELS.CURRENT_STATUS }
                      </label>
                      { trip.StatusRu }
                    </div>
                    { this.renderPaymentType() }
                  </div>
                </div>
                { this.renderApplication(trip) }
                { this.renderEstimate(trip) }
                <div className={ `${styles.row} ${styles.right}` }>
                  { this.renderOrdersButtons(checkboxes) }
                  { this.renderTripButtons(someItemIsTaxi, isUploadedIntegration) }
                  { statusButtonHtml }
                </div>
                <div className={ `${styles.row} ${styles['order-items']}` }>
                  { renderListOrdersHtml }
                </div>
              </div>
            </div>
          </div>
          { renderVersions(versions, this.handleCopyItem, this.handleEditItem, this.checkActualVersion) }
        </div>
        { flagCC
          ? (
            <div className={ `${styles.panel} ${styles.panel_bottom}` }>
              <div className={ `${styles['auto-input']} ${label === '' && isLabelEmpty ? styles['no-validate'] : ''}` }>
                <label>{ LABELS.CHANGING_COST_CENTER }</label>
                <div className={ `${styles['autocomplete-input']} ${styles.wrapper_autocomplete}` }>
                  <Autocomplete
                    menuStyle={ menuStyle }
                    value={ label }
                    items={ suggests }
                    getItemValue={ (item) => item.name }
                    onSelect={ (field, value) => this.handleSelect(value) }
                    renderItem={ (item) => this.renderItem(item) }
                    onChange={ (_event, value) => this.onChangeSuggest(value) }
                  />
                  { clearHtml }
                </div>
                { this.renderWarningLine(isLabelEmpty) }
              </div>
              <div className={ styles.wrapper_btn }>
                <FlatButton
                  label={ LABELS.CANCEL }
                  onClick={ () => this.setState({ flagCC: false }) }
                />
                <FlatButton
                  label={ LABELS.CONFIRM_CHANGES }
                  onClick={ () => this.handleSendСС({ tripItemId, projectId }, isLabelEmpty) }
                  disabled={ !suggests.length || !label }
                />
              </div>
              { this.scrollDown() }
            </div>
          )
          : null }
        <div
          className={ styles.row }
          ref={ (ref) => { this.editPanel = ref; } }
        >
          <div className={ styles.col }>
            { editItem
              ? renderEdit({
                item: editItem,
                accountId,
                onConfirm: this.handleConfirmEdit,
                onAddTrip: this.handleAddTaxiTrip,
                onCancel: this.handleCancelEdit,
                companies,
                tripService,
                airService,
                travelPolicyService,
                projects,
                changeRate,
                analytics,
                tripAnalyticsValueIds,
                setTripAnalytics,
                guidService,
                specialBillAccount,
                notificationsService,
                mandatoryProject,
                loading,
                TripId,
                isTripPaidByPersonalFunds,
              })
              : null }
            { itemHtml }
            { viewItem
              ? renderView(
                viewItem,
                tripService,
                this.handleShowViewItem(null),
              )
              : null }
            { editPartItem
              ? renderPartEdit(
                editPartItem,
                specialBillAccount,
                this.handleConfirmPartEdit,
                this.handleCancelPartEdit,
                tripService,
                airService,
                companies,
                projects,
                travelPolicyService,
                accountId,
                analytics,
                tripAnalyticsValueIds,
                setTripAnalytics,
                changeRate,
                mandatoryProject,
                guidService,
                isTripPaidByPersonalFunds,
              )
              : null }
          </div>
        </div>
        { cancelDialog ? this.renderPenaltyDialog() : null }
        { airCancelPenaltyForm ? this.renderAirPenaltyDialog() : null }
        { cancelVersionDialog ? this.renderPenaltyVersionDialog() : null }
        <ModalDialog show={ cancelTrainDialog } onClose={ this.handleCloseTrainDialog }>
          <TrainCancelForm
            item={ cancelTrainItem }
            cancelLoading={ loading }
            tickets={ tickets }
            companyId={ companyId }
            tripService={ tripService }
            onChange={ this.handleConfirmCancelTrainItem }
            onClose={ this.handleCloseTrainDialog }
          />
        </ModalDialog>
        <ModalDialog
          show={ cancelAirDialog }
          onClose={ this.handleCloseAirDialog }
        >
          <AirCancelForm
            item={ cancelAirItem }
            accountId={ accountId }
            tripService={ tripService }
            onChange={ this.handleConfirmCancelAirItem }
            onClose={ this.handleCloseAirDialog }
          />
        </ModalDialog>
        <ModalDialog
          show={ cancelAeroExpressDialog }
          onClose={ this.handleCloseAeroExpressDialog }
        >
          <AeroexpressCancelForm
            item={ cancelAeroExpressItem }
            accountId={ accountId }
            tripService={ tripService }
            renderConfirm={ this.handleConfirmCancelAeroExpress }
            onClose={ this.handleCloseAeroExpressDialog }
          />
        </ModalDialog>
        <ModalDialog
          show={ changeTicketDialog }
          onClose={ this.handleCloseChangeTicketDialog }
        >
          <ChangeTicketDialog
            item={ changeTicketItem }
            accountId={ accountId }
            tripService={ tripService }
            onChange={ this.handleConfirmCancelAirItem }
            onClose={ this.handleCloseChangeTicketDialog }
          />
        </ModalDialog>
        { this.renderLegalEntityDialog() }
        { this.renderSendVoucherDialog() }
        { this.renderDownloadVoucherDialog() }
        { this.renderSuccessAddTaxiTrip() }
        { this.renderErrorAddTaxiTrip() }
        { this.renderErrorTotalTripBill() }
        { confirmCancelAirItem }
        { confirmCancelAeroexpress }
        {
          sendInvoiceDialog
          && <SendDialog
            email={ email }
            title={ DIALOGSTITLES.SENDINVOICE }
            onClose={ this.closeInvoiceDialog }
            onSend={ this.sendInvoice }
            onEmailChange={ this.handleChangeEmail }
            loading={ waitResSendInvoice }
          />
        }
        {
          sendVouchersDialog
          && <SendDialog
            email={ email }
            title={ DIALOGSTITLES.SENDVOUCHERS }
            onClose={ this.showSendVouchersDialog }
            onSend={ this.sendVouchers }
            onEmailChange={ this.handleChangeEmail }
            loading={ waitResSendVouchers }
          />
        }
        { dialogHtml }
        { sendResultVouchersHtml }
        { sendResultInvoiceHtml }
        <DeleteItemDialogs
          trip={ trip }
          tripService={ tripService }
          companyId={ companyId }
          activeDialog={ activeDialog }
          checkboxes={ checkboxes }
          closingDocuments={ closingDocuments }
          moveToCompany={ moveToCompany }
          operationsInf={ operationsInf }
          deleteTrip={ this.deleteTrip }
          deleteOrders={ this.deleteOrders }
          deleteActualVersion={ this.deleteActualVersion }
          deleteOperations={ this.deleteOperations }
          disableActive={ this.disableActive }
          waitResDelete={ waitResDelete }
          disabledButton={ disabledButton }
        />
        <AdjustDialog
          activeDialog={ activeConfirmDialog }
          closingDocuments={ closingConfirmDocuments }
          confirmAdjust={ this.handlerConfirmAdjust }
          disableActive={ this.disableConfirmActive }
        />
        <MoveItemDialogs
          trip={ trip }
          tripService={ tripService }
          companyId={ companyId }
          activeDialog={ activeDialog }
          checkboxes={ checkboxes }
          closingDocuments={ closingDocuments }
          moveToCompany={ moveToCompany }
          moveTrip={ this.moveTrip }
          moveOrders={ this.moveOrders }
          moveVouchers={ this.moveVouchers }
          disableActive={ this.disableActive }
          selectCompany={ this.selectCompany }
          waitResMove={ waitResMove }
          onClose={ this.handleCancelEdit }
          onNextStep={ this.scrollDown }
          waitResChange={ waitResChange }
          isLeaveExcess={ isLeaveExcess }
          subAccounts={ subAccounts }
          resultTextMoveVouchers={ resultTextMoveVouchers }
          onChangeIsLeaveExcess={ this.handleChangeIsLeaveExcess }
          onChangeSubAccount={ this.handleChangeSubAccount }
          onAgreeWithConditions={ this.handleAgreeWithConditions }
          selectedSubAccount={ selectedSubAccount }
          surcharges={ surcharges }
          selectedCompanyId={ selectedCompanyId }
          checkAllOperational={ checkAllOperational }
          operationsInf={ operationsInf }
          documentNumber={ documentNumber }
        />
        { this.renderChangeVersionDateDialog() }
        {
          showSaveMetadataDialog
          && <SaveMetadataDialog
            isProhibitionVoucher={ isProhibitionVoucher }
            renderProhibitionCompaniesMassage={ this.renderProhibitionCompaniesMassage }
            onSaveWithDocumentsSend={ this.handleSaveMetadataWithDocumentsSend }
            onSaveWithoutDocumentsSend={ this.handleSaveMetadataWithoutDocumentsSend }
            onClose={ this.toggleSaveMetadataDialog }
          />
        }
        { oldVoucherDialogHtml }
        { this.renderVoidingDialog() }
        { this.renderTripCommentsDialog() }
        { this.renderAdditionalServiceDialog() }
        { this.renderVatNotificationDialog() }
        <MergeTrips
          tripService={ this.props.tripService }
          onClose={ this.handleChangeMergeTrips(false) }
          value={ this.state.showMergeTrips }
          accountId={ accountId }
          sourceTripId={ TripId }
        />
      </div>
    );
  }
}
