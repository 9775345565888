import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, RadioButton } from 'sw-ui';

import { formatDate } from '../../../../bi/utils/formatDate';

import {
  DEFAULT_PLANS, FIELDS, TEXT, VAT_VALUE,
} from '../../../../bi/constants/hotels';

import { AddButton } from '../../../../components/AddButton';
import { TYPE_ICON } from '../../../../components/AddButton/consts';
import { FlatButton } from '../../../../components/button';
import Input from '../../../../components/input';
import Suggest from '../../../../components/Suggest';

import { Rate } from './rate';

import styles from '../../styles/index.module.scss';

const LABELS = { SELECT_TARIFF: 'Выберите тариф' };

class Travelline extends Component {
  static propTypes = {
    isAdd: PropTypes.bool.isRequired,
    rates: PropTypes.array.isRequired,
    plans: PropTypes.array.isRequired,
    connected: PropTypes.bool.isRequired,
    hideRates: PropTypes.bool.isRequired,
    types: PropTypes.array.isRequired,
    validation: PropTypes.array.isRequired,
    worksWithVat: PropTypes.bool.isRequired,
    priceInclVat: PropTypes.bool.isRequired,
    bar: PropTypes.bool.isRequired,
    isNoEdit: PropTypes.bool,
    discount: PropTypes.string.isRequired,
    activatedData: PropTypes.string.isRequired,
    setRef: PropTypes.func,
    onAddRate: PropTypes.func.isRequired,
    onAddPlan: PropTypes.func.isRequired,
    onDeleteRate: PropTypes.func.isRequired,
    onChangeField: PropTypes.func.isRequired,
    onDeletePlan: PropTypes.func.isRequired,
    onChangeVat: PropTypes.func.isRequired,
    onChangeBar: PropTypes.func.isRequired,
    onChangeDiscount: PropTypes.func.isRequired,
    onChangeConnected: PropTypes.func.isRequired,
    onChangeHideRates: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isNoEdit: false,
    setRef: () => {},
  };

  constructor(props) {
    super(props);

    this.state = { selectedPlan: { name: '', id: 0 } };
  }

  handleChangeSelectPlan = (value) => {
    this.setState({ selectedPlan: value });
  };

  handleAddPlan = () => {
    this.props.onAddPlan(this.state.selectedPlan);
    this.setState({ selectedPlan: { name: '', id: 0 } });
  };

  renderAddButton = (fn, text, type = TYPE_ICON.ADD) => {
    const { isNoEdit } = this.props;

    return (
      <AddButton
        icon={ type }
        isNoEdit={ isNoEdit }
        text={ isNoEdit ? TEXT.EDIT_INFO : text }
        onClick={ fn }
      />
    );
  };

  renderPlan = ({ name, id }, index) => (
    <div key={ `plan_${id}_${index}` } className={ styles.plan }>
      <div className={ styles.plan_name }>
        { name }
      </div>
      { this.renderAddButton(() => this.props.onDeletePlan(id), TEXT.DELETE_PLAN, TYPE_ICON.CLEAR) }
    </div>
  );

  renderRates = () => {
    const {
      rates, types, validation, isNoEdit, onDeleteRate, onChangeField,
    } = this.props;

    const rate = (item, index) => (
      <Rate
        key={ `rate_${item.Id}_${index}` }
        isNoEdit={ isNoEdit }
        types={ types }
        rate={ item }
        validation={ validation[index] }
        onDeleteRate={ () => onDeleteRate(index) }
        onChangeField={ (field, value) => onChangeField(field, value, index) }
      />
    );

    return (
      <div className={ styles.rates }>
        <div className={ styles.subtitle_travelline }>{ TEXT.SUB_TITLE_RATES.toUpperCase() }</div>
        <div>
          { rates.map(rate) }
        </div>
      </div>
    );
  };

  renderSelect = () => {
    const { selectedPlan } = this.state;
    const { isNoEdit, plans } = this.props;
    const list = DEFAULT_PLANS.filter(({ id }) => !plans.some((item) => id === item.id));

    if (list.length === 0) {
      return null;
    }

    return (
      <div className={ styles.suggest }>
        <Suggest
          clear
          title={ LABELS.SELECT_TARIFF }
          suggests={ list }
          currentLabel={ selectedPlan.name }
          onSelect={ this.handleChangeSelectPlan }
          withScroll
          disabled={ isNoEdit }
        />
      </div>
    );
  };

  renderPlans = () => {
    const { selectedPlan } = this.state;
    const { isNoEdit, plans } = this.props;

    const disabledButton = isNoEdit || selectedPlan.id === 0;

    return (
      <div className={ styles.plans }>
        <div className={ styles.subtitle_travelline }>
          <div className={ styles.subtitle_plan }>
            <div>
              { TEXT.SUB_TITLE_PLANS.toUpperCase() }
            </div>
            { this.renderSelect('') }
            <FlatButton
              onClick={ this.handleAddPlan }
              disabled={ disabledButton }
            >
              <i className={ `material-icons ${styles.icon}` }>add</i>
              { TEXT.ADD_PLAN }
            </FlatButton>
          </div>
        </div>
        { plans.map(this.renderPlan) }
      </div>
    );
  };

  renderButtonAfterHtml = () => {
    const { isNoEdit, onAddRate } = this.props;

    return (
      <div className={ styles.add_rate }>
        <FlatButton onClick={ onAddRate } disabled={ isNoEdit }>
          <i className={ `material-icons ${styles.icon}` }>add</i>
          { TEXT.ADD_RATE }
        </FlatButton>
      </div>
    );
  };

  renderVat = (title, value, field) => {
    const { isNoEdit, onChangeVat } = this.props;

    return (
      <div>
        <div className={ styles.subtitle_travelline }>{ title.toUpperCase() }</div>
        <div className={ styles.rates_vat }>
          <RadioButton
            disabled={ isNoEdit }
            checked={ value }
            onChange={ (val) => onChangeVat(field, val) }
          >
            { VAT_VALUE.YES }
          </RadioButton>
          <RadioButton
            disabled={ isNoEdit }
            checked={ !value }
            onChange={ (val) => onChangeVat(field, !val) }
          >
            { VAT_VALUE.NO }
          </RadioButton>
        </div>
      </div>
    );
  };

  renderDiscount = () => {
    const {
      discount, isNoEdit, onChangeDiscount,
    } = this.props;

    const label = TEXT.DISCOUNT_UNDER_CONTRACT.toUpperCase();

    return (
      <div className={ styles.discount }>
        <div className={ styles.subtitle_travelline }>
          { ' ' }
          { label }
        </div>
        <div className={ styles.discount_vat }>
          <Input
            type='number'
            disabled={ isNoEdit }
            value={ discount }
            field={ FIELDS.DISCOUNT }
            onChange={ (e, field, value) => onChangeDiscount(value) }
          />
        </div>
        <span>%</span>
      </div>
    );
  };

  renderBar = () => {
    const {
      bar, isNoEdit, onChangeBar,
    } = this.props;

    return (
      <div className={ styles.bar }>
        <div className={ styles.subtitle_travelline }>{ TEXT.BAR.toUpperCase() }</div>
        <div>
          <Checkbox
            disabled={ isNoEdit }
            value={ bar }
            onChange={ onChangeBar }
          />
        </div>
      </div>
    );
  };

  renderConnectedTL = () => {
    const {
      connected, isNoEdit, onChangeConnected,
    } = this.props;

    return (
      <div className={ styles.bar }>
        <div className={ styles.subtitle_travelline }>{ TEXT.ACTIVATED }</div>
        <div>
          <Checkbox
            disabled={ isNoEdit }
            value={ connected }
            onChange={ onChangeConnected }
          />
        </div>
      </div>
    );
  };

  renderHideRates = () => {
    const {
      hideRates, isNoEdit, onChangeHideRates,
    } = this.props;

    return (
      <div className={ styles.bar }>
        <div className={ styles.subtitle_travelline }>{ TEXT.HIDE_RATES }</div>
        <div>
          <Checkbox
            disabled={ isNoEdit }
            value={ hideRates }
            onChange={ onChangeHideRates }
          />
        </div>
      </div>
    );
  };

  renderCommon = () => {
    const {
      worksWithVat, priceInclVat, activatedData, isAdd,
    } = this.props;
    const plansHtml = this.renderPlans();

    return (
      <div className={ styles.travelline }>
        { this.renderRates() }
        { this.renderButtonAfterHtml() }
        { plansHtml }
        { this.renderDiscount() }
        { this.renderBar() }
        { this.renderVat(TEXT.SUB_WORKS_WITH_VAT, worksWithVat, FIELDS.WORKS_WITH_VAT) }
        { this.renderVat(TEXT.SUB_PRICE_INCL_VAT, priceInclVat, FIELDS.PRICE_INCL_VAT) }
        { activatedData && this.renderActiveTravelline(TEXT.TRAVELLINE_ACTIVE_TITLE, activatedData) }
        { !isAdd && this.renderConnectedTL() }
        { !isAdd && this.renderHideRates() }
      </div>
    );
  };

  renderActiveTravelline = (title, value) => (
    <div>
      <div className={ styles.subtitle_travelline }>{ `${title} ${formatDate(value)}` }</div>
    </div>
  );

  render() {
    const {
      setRef, rates, onAddRate,
    } = this.props;

    const buttonBeforeHtml = !rates.length && this.renderAddButton(onAddRate, TEXT.ADD_RATE);
    const commonHtml = !!rates.length && this.renderCommon();

    return (
      <div className={ styles.wrapper_travelline } ref={ (ref) => setRef(ref) }>
        <div className={ styles.header }>
          <h2>{ TEXT.TITLE_TRAVELLINE }</h2>
          { buttonBeforeHtml }
        </div>
        { commonHtml }
      </div>
    );
  }
}

export { Travelline };
