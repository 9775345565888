import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, Tabs, TabsItem,
} from 'sw-ui';

import DecisionButtons from '../../../components/DecisionButtons';
import Profile from './profile';
import Employees from './employees';
import Finance from './finance';
import Accounting from './accounting';
import Documents from './documents';
import Trips from './trips';
import Debts from './debts';
import ExpenseReportsPage from './expenseReports';
import HotelBookRequest from './HotelBookRequest';
import { PaidByCard } from './paidByCard';

import COMPONENTS from '../../../bi/constants/components';
import { DECISIONBUTTONS } from '../../../bi/constants/decisionButtons';
import { BOOK_REQUEST } from '../../../bi/constants/hotelBookRequest';

import styles from '../styles/company.module.scss';

const TOOLTIP = 'Учитывайте, операция долгая. Придется подождать, возможно, несколько минут!';

const TAB_LABELS = {
  EXPENSE: 'Авансовые отчеты',
  PAID_BY_CARD: 'Оплачено картой',
  PROFILE: 'Профиль компании',
  EMPLOYEES: 'Сотрудники',
  FINANCE: 'Финансы',
  ACCOUNTING: 'Отчетность',
  DOCUMENTS: 'Закрывающие',
  TRIPS: 'Поездки',
  DEBTS: 'Долги',
};

class Navigation extends Component {
  static propTypes = {
    params: PropTypes.object.isRequired,
    companyService: PropTypes.object.isRequired,
    notificationsService: PropTypes.object.isRequired,
    accountService: PropTypes.object.isRequired,
    airService: PropTypes.object.isRequired,
    tripService: PropTypes.object.isRequired,
    toNewTrip: PropTypes.func.isRequired,
    toUserCartsAndNote: PropTypes.func.isRequired,
    toAnotherTab: PropTypes.func.isRequired,
    featureFlagsService: PropTypes.object.isRequired,
    hotelsService: PropTypes.object.isRequired,
    toDocumentsConstructor: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    const {
      params: { companyId }, companyService, featureFlagsService, hotelsService,
    } = props;
    const { search } = hotelsService.get();

    companyService.setId(companyId);

    this.state = {
      openDialog: false,
      waitResClearCache: false,
      showTooltip: true,
      expenseReportsFeatureFlag: featureFlagsService.getExpenseReports(),
      acquiringSettingChangeFeatureFlag: featureFlagsService.getAcquiringSettingChange(),
      searchHotelsInput: { ...search },
    };
  }

  componentDidMount() {
    const { featureFlagsService, hotelsService } = this.props;

    this.unsubFeatureFlags = featureFlagsService.subscribe(this.updateFeatureFlags);
    this.unsubscribeHotels = hotelsService.subscribe(this.updateHotels);
  }

  componentWillUnmount() {
    if (this.unsubFeatureFlags) { this.unsubFeatureFlags(); }
    if (this.unsubscribeHotels) { this.unsubscribeHotels(); }
  }

  updateHotels = ({ search }) => {
    this.setState({ searchHotelsInput: { ...search } });
  };

  updateFeatureFlags = ({ ExpenseReports, AcquiringSettingChange }) => {
    this.setState({
      expenseReportsFeatureFlag: ExpenseReports,
      acquiringSettingChangeFeatureFlag: AcquiringSettingChange,
    });
  };

  handleOpenDialog = () => this.setState({ openDialog: true });

  handleCloseDialog = () => this.setState({ openDialog: false, waitResClearCache: false });

  handlerClearCacheByCompanyId = () => {
    const { params: { companyId }, companyService } = this.props;

    const clearCache = () => {
      companyService.clearCacheByCompanyId(companyId)
        .then(() => companyService.updateTrips(companyId))
        .then(() => this.handleCloseDialog())
        .catch(() => this.handleCloseDialog());
    };

    this.setState({ waitResClearCache: true }, clearCache);
  };

  toAnotherTab = (tabId, title) => (
    <div className={ styles['tab-link'] } onClick={ () => this.props.toAnotherTab({ tabId }) }>
      { title }
    </div>);

  render() {
    const {
      companyService,
      notificationsService,
      accountService,
      airService,
      toDocumentsConstructor,
      toNewTrip,
      toUserCartsAndNote,
      toAnotherTab,
      params,
      featureFlagsService,
      featureFlagsService: {
        getXmlVisibilityFlag,
        getAccessToUploadEmployees,
        getAccessToExportEmployees,
      },
      hotelsService,
      tripService,
    } = this.props;
    const {
      openDialog,
      waitResClearCache,
      showTooltip,
      expenseReportsFeatureFlag,
      acquiringSettingChangeFeatureFlag,
      searchHotelsInput,
    } = this.state;

    const {
      companyId, tabId, accountId,
    } = params;

    const expenseReportsPage = expenseReportsFeatureFlag
      ? (
        <TabsItem
          id='expense'
          title={ () => this.toAnotherTab('expense', TAB_LABELS.EXPENSE) }
        >
          <ExpenseReportsPage
            companyId={ companyId }
            companyService={ companyService }
          />
        </TabsItem>
      )
      : <div />;

    const paidByCardPage = acquiringSettingChangeFeatureFlag
      ? (
        <TabsItem
          id='paidByCard'
          title={ () => this.toAnotherTab('paidByCard', TAB_LABELS.PAID_BY_CARD) }
        >
          <PaidByCard
            companyId={ companyId }
            accountId={ accountId }
            companyService={ companyService }
          />
        </TabsItem>
      )
      : <div />;

    return (
      <div>
        <Tabs theme='default' activeTabId={ tabId } >
          <TabsItem
            id='profile'
            title={ () => this.toAnotherTab('profile', TAB_LABELS.PROFILE) }
          >
            <Profile
              accountService={ accountService }
              companyService={ companyService }
              featureFlagsService={ featureFlagsService }
              hotelsService={ hotelsService }
              notificationsService={ notificationsService }
              airService={ airService }
              toUserCartsAndNote={ toUserCartsAndNote }
              params={ params }
            />
          </TabsItem>
          <TabsItem
            id='employees'
            title={ () => this.toAnotherTab('employees', TAB_LABELS.EMPLOYEES) }
          >
            <Employees
              companyService={ companyService }
              companyId={ companyId }
              accountId={ accountId }
              accessToUploadEmployees={ getAccessToUploadEmployees }
              accessToExportEmployees={ getAccessToExportEmployees }
            />
          </TabsItem>
          <TabsItem
            id='finance'
            title={ () => this.toAnotherTab('finance', TAB_LABELS.FINANCE) }
          >
            <Finance
              companyId={ companyId }
              accountId={ accountId }
              companyService={ companyService }
              featureFlagsService={ featureFlagsService }
              accountService={ accountService }
            />
          </TabsItem>
          <TabsItem
            id='accounting'
            title={ () => this.toAnotherTab('accounting', TAB_LABELS.ACCOUNTING) }
          >
            <Accounting
              companyId={ companyId }
              accountId={ accountId }
              companyService={ companyService }
              accountService={ accountService }
              notificationsService={ notificationsService }
              toAnotherTab={ toAnotherTab }
              featureFlagsService={ featureFlagsService }
            />
          </TabsItem>
          <TabsItem
            id='documents'
            title={ () => this.toAnotherTab('documents', TAB_LABELS.DOCUMENTS) }
          >
            <Documents
              companyService={ companyService }
              toAnotherTab={ toAnotherTab }
              featureFlagsService={ featureFlagsService }
              companyId={ companyId }
              accountService={ accountService }
              accountId={ accountId }
              onDocumentsConstructor={ () => toDocumentsConstructor() }
            />
          </TabsItem>
          <TabsItem
            id='bookRequest'
            title={ () => this.toAnotherTab(BOOK_REQUEST.ID, BOOK_REQUEST.HOTEL_BOOK_REQUEST) }
          >
            <HotelBookRequest
              companyId={ companyId }
              accountId={ accountId }
              companyService={ companyService }
              hotelsService={ hotelsService }
              searchHotelsInput={ searchHotelsInput }
              notificationService={ notificationsService }
            />
          </TabsItem>
          <TabsItem
            id='drips'
            title={ () => this.toAnotherTab('drips', TAB_LABELS.TRIPS) }
          >
            <Trips
              companyId={ companyId }
              accountId={ accountId }
              companyService={ companyService }
              accountService={ accountService }
              tripService={ tripService }
              onNewTrip={ () => toNewTrip(companyId) }
              handlerClearCache={ this.handleOpenDialog }
              getXmlVisibilityFlag={ getXmlVisibilityFlag }
              featureFlagsService={ featureFlagsService }
            />
          </TabsItem>
          <TabsItem
            id='debts'
            title={ () => this.toAnotherTab('debts', TAB_LABELS.DEBTS) }
          >
            <Debts
              companyId={ companyId }
              companyService={ companyService }
            />
          </TabsItem>
          { paidByCardPage }
          { expenseReportsPage }
        </Tabs>
        { openDialog
          ? (
            <Dialog onClick={ this.handleCloseDialog }>
              <div className={ `${styles.form} ${styles['form-dialog']}` }>
                <span>Вы уверены, что хотите обновить поездки компании в кэше?</span>
                <div className={ `${styles.row} ${styles.action}` }>
                  <DecisionButtons
                    waitingResponse={ waitResClearCache }
                    onSave={ this.handlerClearCacheByCompanyId }
                    onCancel={ this.handleCloseDialog }
                    showTooltip={ showTooltip }
                    tooltip={ TOOLTIP }
                    tooltipPosition={ COMPONENTS.TOOLTIP.POSITION.RIGHT }
                    labelSave={ DECISIONBUTTONS.LABELS.CONFIRM }
                  />
                </div>
              </div>
            </Dialog>
          )
          : null }
      </div>
    );
  }
}

export default Navigation;
