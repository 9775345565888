import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FIELDS, TEXT, CHARGE_VALUE_UNITS, CHARGE_TYPES,
} from '../../../../bi/constants/hotels';

import Input from '../../../../components/input';
import Suggest from '../../../../components/Suggest';
import TextArea from '../../../../components/textarea';
import { FlatButton } from '../../../../components/button';

import ImgInput from '../ImgInput';

import styles from '../../styles/index.module.scss';

const LABELS = {
  PLACEHOLDER: 'Опишите номер',
  REFUND_TIME: 'Дата бесплатной отмены:',
  REFUND_TIME_AFTER: 'за',
  REFUND_TIME_AFTER_HOURS: 'часов до даты заезда',
  CHARGE_VALUE: 'Далее штраф',
  DELETE: 'Удалить рейт',
};

class Rate extends Component {
  static propTypes = {
    rate: PropTypes.object.isRequired,
    validation: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired,
    isNoEdit: PropTypes.bool,
    onChangeField: PropTypes.func.isRequired,
    onDeleteRate: PropTypes.func.isRequired,
  };

  static defaultProps = { isNoEdit: false };

  renderImage = () => {
    const {
      isNoEdit,
      rate: { ImageUrl },
      onChangeField,
    } = this.props;

    return (
      <div className={ styles.item_img }>
        <img className={ styles.img } alt='img' src={ ImageUrl } />
        <div className={ styles.remove_button_img_wrap }>
          <button
            disabled={ isNoEdit }
            className={ `${styles.button} ${styles.button_close}` }
            onClick={ () => onChangeField(FIELDS.RATES_FIELDS.IMAGE_URL, '') }
          >
            <i className='material-icons'>clear</i>
          </button>
        </div>
      </div>
    );
  };

  renderChargeType = () => {
    const {
      isNoEdit,
      rate: { ChargeType },
      onChangeField,
    } = this.props;

    const currentChargeType = CHARGE_TYPES.find(({ id }) => ChargeType === id);

    return (
      <div className={ styles.charge_suggest_second }>
        <Suggest
          withScroll
          clear={ false }
          disabled={ isNoEdit }
          suggests={ CHARGE_TYPES }
          currentLabel={ currentChargeType ? currentChargeType.name : '' }
          onSelect={ (value) => onChangeField(FIELDS.RATES_FIELDS.CHARGE_TYPE, value.id) }
        />
      </div>
    );
  };

  render() {
    const {
      isNoEdit,
      types,
      rate: {
        RateName, GroupTypeId, ImageUrl, Description, RefundTimeLimit, ChargeValue, ChargeValueUnit,
      },
      validation: {
        RateName: validRateName,
        GroupTypeId: validGroupTypeId,
        RefundTimeLimit: validRefundTimeLimit,
        ChargeValue: validChargeValue,
      },
      onDeleteRate,
      onChangeField,
    } = this.props;

    const imageHtml = !!ImageUrl && this.renderImage();
    const currentGroupTypeId = types.find(({ id }) => GroupTypeId === id);
    const currentChargeValueUnit = CHARGE_VALUE_UNITS.find(({ id }) => ChargeValueUnit === id);
    const chargeTypeHtml = ChargeValueUnit === CHARGE_VALUE_UNITS[1].id && this.renderChargeType();

    return (
      <div className={ styles.rate }>
        <div className={ styles.delete_rate }>
          <FlatButton
            disabled={ isNoEdit }
            label={ LABELS.DELETE }
            onClick={ onDeleteRate }
          />
        </div>
        <div>
          <Input
            value={ RateName }
            disabled={ isNoEdit }
            field={ FIELDS.RATES_FIELDS.RATE_NAME }
            label={ TEXT.RATE.RATE_NAME }
            valid={ validRateName }
            onChange={ (e, field, value) => onChangeField(field, value) }
          />
        </div>
        <div>
          <Suggest
            withScroll
            disabled={ isNoEdit }
            title={ TEXT.RATE.GROUP_TYPE_ID }
            valid={ validGroupTypeId }
            suggests={ types }
            currentLabel={ currentGroupTypeId ? currentGroupTypeId.name : '' }
            onSelect={ (value) => onChangeField(FIELDS.RATES_FIELDS.GROUP_TYPE_ID, value.id) }
          />
        </div>
        <div className={ styles.img_input }>
          <ImgInput
            disabled={ isNoEdit }
            onSubmit={ (e, value) => onChangeField(FIELDS.RATES_FIELDS.IMAGE_URL, value) }
          />
          <div className={ styles.rate_img }>{ imageHtml }</div>
        </div>
        <TextArea
          labelTop
          value={ Description }
          placeholder={ LABELS.PLACEHOLDER }
          label={ TEXT.RATE.DESCRIPTION }
          height={ 100 }
          disabled={ isNoEdit }
          className={ styles.rate_text_area }
          onChange={ (value) => onChangeField(FIELDS.RATES_FIELDS.DESCRIPTION, value) }
        />
        <div className={ styles.refund_time }>
          <label className={ styles.refund_label }>{ TEXT.RATE.REFUND_TIME_LIMIT }</label>
          { ' ' }
          { LABELS.REFUND_TIME_AFTER }
          <div className={ styles.refund_input }>
            <Input
              type='number'
              disabled={ isNoEdit }
              value={ RefundTimeLimit }
              field={ FIELDS.RATES_FIELDS.REFUND_TIME_LIMIT }
              valid={ validRefundTimeLimit }
              onChange={ (e, field, value) => onChangeField(field, value) }
            />
          </div>
          { LABELS.REFUND_TIME_AFTER_HOURS }
        </div>
        <div className={ styles.charge_value }>
          <label>{ TEXT.RATE.CHARGE_VALUE }</label>
          <div className={ styles.charge_value_fields }>
            <div>
              <Input
                type='number'
                disabled={ isNoEdit }
                value={ ChargeValue }
                field={ FIELDS.RATES_FIELDS.CHARGE_VALUE }
                valid={ validChargeValue }
                onChange={ (e, field, value) => onChangeField(field, value) }
              />
            </div>
            <div className={ styles.charge_suggest_first }>
              <Suggest
                withScroll
                clear={ false }
                disabled={ isNoEdit }
                suggests={ CHARGE_VALUE_UNITS }
                currentLabel={ currentChargeValueUnit ? currentChargeValueUnit.name : '' }
                onSelect={ (value) => onChangeField(FIELDS.RATES_FIELDS.CHARGE_VALUE_UNIT, value.id) }
              />
            </div>
            { chargeTypeHtml }
          </div>
        </div>
      </div>
    );
  }
}

export { Rate };
