import { INVALID_REQUESTIES_FIELDS } from '../../../constants/profile';

const BIC = /^(([0-9]{9}))?$/;
const ISD = /^(([0-9]{0,7}))?$/;
const EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const INVOICE_NUMBER = /^(([0-9]{20}))?$/;
const CORRESPONDENT_BILL = /^(([0-9]{20}))?$/;

const checkEmpty = (value) => !!value.length;

const formatBIC = (value) => BIC.test(value);

const formatISD = (value) => ISD.test(value);

const formatEMAIl = (value) => EMAIL.test(value);

const formatINVOICENUMBER = (value) => INVOICE_NUMBER.test(value);

const formatCORRESPONDENTBILL = (value) => CORRESPONDENT_BILL.test(value);

const validationObj = {
  bik: {
    fn: [checkEmpty, formatBIC],
    msg: [INVALID_REQUESTIES_FIELDS.BIC, INVALID_REQUESTIES_FIELDS.FORMAT_BIC],
  },
  invoiceNumber: {
    fn: [checkEmpty, formatINVOICENUMBER],
    msg: [INVALID_REQUESTIES_FIELDS.INVOICE_NUMBER, INVALID_REQUESTIES_FIELDS.FORMAT_INVOICE_NUMBER],
  },
  email: {
    fn: [checkEmpty, formatEMAIl],
    msg: [INVALID_REQUESTIES_FIELDS.EMAIL, INVALID_REQUESTIES_FIELDS.FORMAT_EMAIL],
  },
  bankDetails: {
    fn: checkEmpty,
    msg: INVALID_REQUESTIES_FIELDS.BANK_DETAILS,
  },
  correspondentBill: {
    fn: [checkEmpty, formatCORRESPONDENTBILL],
    msg: [INVALID_REQUESTIES_FIELDS.CORRESPONDENT_BILL, INVALID_REQUESTIES_FIELDS.FORMAT_CORRESPONDENT_BILL],
  },
  isd: {
    fn: [formatISD],
    msg: [INVALID_REQUESTIES_FIELDS.FORMAT_ISD],
  },
};

class RequisitesValidator {
  isValid(field, value) {
    const validator = validationObj[field];

    const validationFn = validator.fn;
    let result = '';

    if (Array.isArray(validationFn)) {
      for (let i = 0; i < validationFn.length; i++) {
        if (!validationFn[i](value)) {
          result = validator.msg[i];
          break;
        }
      }
    } else if (!validationFn(value)) {
      result = validator.msg;
    }

    return result;
  }
}

export default RequisitesValidator;
