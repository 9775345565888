import React, { useState } from 'react';
import { clsx } from 'clsx';

import { Link } from '../Link';

import { openNewWindow, redirectToV2 } from '../../../../utils/redirect';

import styles from './styles.module.scss';

interface IMenuItemPros {
  name?: React.ReactNode;
  icon?: React.ReactNode;
  href?: string;
  nested?: Omit<IMenuItemPros, 'isShort'>[];
  isShort?: boolean;
  onRedirect?: (link: string) => void;
}

export const MenuItem = ({
  name = null,
  icon = '__empty',
  href = '',
  nested = [],
  isShort = true,
  onRedirect = redirectToV2,
}: IMenuItemPros) => {
  const [isOpen, setIsOpen] = useState(false);

  const switchSubMenu = () => setIsOpen(!isOpen);

  const nestedExist = nested.length > 0;

  const handleClick = nestedExist ? switchSubMenu : () => onRedirect(href);
  const handleMiddleClick = nestedExist ? switchSubMenu : openNewWindow;

  const renderSwitcher = () => (!nestedExist
    ? null
    : (
      <i
        className={ clsx(`fa fa-caret-${isOpen ? 'up' : 'down'}`, styles.openerIcon) }
        aria-hidden='true'
      />
    ));

  const renderName = () => (isShort
    ? null
    : (
      <div className={ styles.text }>
        <div>
          { name }
        </div>
        { renderSwitcher() }
      </div>
    ));

  const renderNested = () => (!isOpen
    ? null
    : (
      nested.map((item, index) => (
        <MenuItem key={ index } { ...item } isShort={ isShort } />
      ))
    ));

  return (
    <React.Fragment>
      <Link
        href={ href }
        className={ styles.container }
        classNameActive={ styles.containerActive }
        onMiddleClick={ handleMiddleClick }
        onClick={ handleClick }
      >
        <i className='material-icons'>
          { icon }
        </i>
        { renderName() }
      </Link>
      <div>
        { renderNested() }
      </div>
    </React.Fragment>
  );
};
