import ACTIONS from '../../actions';
import Api from '../../api';
import { formatDate } from '../../utils/formatDate';
import { DATE } from '../../../constants/time';

let store = null;

const SENDING_STATUS = new Map([
  [1, 'Не отправлен'],
  [2, 'Отправлен'],
]);

const STATUS_SENT = 'Отправлен';

export default class SendingDiadocService {
  constructor() {
    store = this.getStore('SendingDiadoc');
  }

  get = () => store.getState();

  getPackageDocuments = () => {
    const {
      startDate, endDate, period, status: { selected },
    } = this.get();
    const start = formatDate(startDate, DATE);
    const end = formatDate(endDate, DATE);
    const body = {
      SendingPeriods: period,
      SendingStatuses: selected.length ? selected : [1, 2],
      StartDate: `${start}`,
      EndDate: `${end}`,
    };

    return Api.SendingDiadoc.getPackageDocuments(body)
      .then((res) => store.dispatch({
        type: ACTIONS.SENDINGDIADOC.LOAD_LIST_COMPANY,
        listCompany: res,
        packagesFilter: res,
      }));
  };

  downloadXlsDocument = () => {
    const {
      startDate, endDate, period, status: { selected },
    } = this.get();
    const start = formatDate(startDate, DATE);
    const end = formatDate(endDate, DATE);
    const body = {
      SendingPeriods: period,
      SendingStatuses: selected.length ? selected : [1, 2],
      StartDate: `${start}`,
      EndDate: `${end}`,
    };

    return Api.SendingDiadoc.downloadXlsDocument(body);
  };

  getAllSendingStatus = () => store.dispatch({
    type: ACTIONS.SENDINGDIADOC.GET_STATUS,
    status: SENDING_STATUS,
  });

  changeSelectPeriod = (values) => store.dispatch({
    type: ACTIONS.SENDINGDIADOC.SELECT_PERIOD,
    period: values,
  });

  changeSelectStatus = (values) => store.dispatch({
    type: ACTIONS.SENDINGDIADOC.SELECT_STATUS,
    selected: values,
  });

  changeCheckboxItem = (value, companyId) => {
    store.dispatch({
      type: ACTIONS.SENDINGDIADOC.UPDATE_CHECKBOX,
      value,
      companyId,
    });
  };

  selectAllItemsOnCurrentPage = (value) => store.dispatch({
    type: ACTIONS.SENDINGDIADOC.UPDATE_ALL_CHECKBOX,
    value,
  });

  changeCurrentPage = (currentPage) => {
    if (this.get().allCheckboxValue) {
      this.selectAllItemsOnCurrentPage(true);
    }

    return store.dispatch({
      type: ACTIONS.SENDINGDIADOC.CHANGE_CURRENT_PAGE,
      currentPage,
    });
  };

  changeDate = (field, value) => store.dispatch({
    type: ACTIONS.SENDINGDIADOC.CHANGE_DATE,
    field,
    value,
  });

  resetStore = () => store.dispatch({ type: ACTIONS.SENDINGDIADOC.RESET });

  findSentDocuments = () => this.get().listCompany.find(({ SendingStatus }) => SendingStatus === STATUS_SENT);

  sendDiadoc = () => {
    const data = this.get().source.filter(({ Selected }) => Selected).map(({ Id }) => Id);

    return Api.SendingDiadoc.sendDiadoc(data).then(() => this.getPackageDocuments());
  };

  subscribe = (callback) => store.subscribe(callback);
}

