const BUS_TYPE_LUGGAGE = {
  WITH_LUGGAGE: 'С багажом',
  WITHOUT_LUGGAGE: 'Без багажа',
};

const BUS_TYPE_LUGGAGE_OPTIONS = [
  { label: BUS_TYPE_LUGGAGE.WITH_LUGGAGE, value: 'true' },
  { label: BUS_TYPE_LUGGAGE.WITHOUT_LUGGAGE, value: 'false' },
];

const CODE_OF_STATION = {
  0: 'BUS',
  1: 'AIRPORT',
  2: 'RAILWAY',
};

const TYPE_OF_STATION = {
  AIRPORT: {
    TYPE: 'air',
    VALUE: 'Аэропорт',
    NAME_FOR_SERVICE: 'Airport',
  },
  RAILWAY: {
    TYPE: 'train',
    VALUE: 'Ж.д. вокзал',
    NAME_FOR_SERVICE: 'TrainStation',
  },
  BUS: {
    TYPE: 'bus',
    VALUE: 'Автовокзал',
    NAME_FOR_SERVICE: 'BusStation',
  },
};

const BUS_TYPE_STATION_OPTIONS = [
  { label: TYPE_OF_STATION.BUS.VALUE, value: TYPE_OF_STATION.BUS.NAME_FOR_SERVICE },
  { label: TYPE_OF_STATION.AIRPORT.VALUE, value: TYPE_OF_STATION.AIRPORT.NAME_FOR_SERVICE },
  { label: TYPE_OF_STATION.RAILWAY.VALUE, value: TYPE_OF_STATION.RAILWAY.NAME_FOR_SERVICE },
];

const INVALID_FORM_MESSAGE = 'Проверьте корректность ввода данных';
const LABEL_VAT = 'НДС на цену автобусного билета от поставщика';

export {
  TYPE_OF_STATION,
  INVALID_FORM_MESSAGE,
  BUS_TYPE_LUGGAGE_OPTIONS,
  CODE_OF_STATION,
  BUS_TYPE_STATION_OPTIONS,
  LABEL_VAT,
};
