import React from 'react';
import PropTypes from 'prop-types';

import { getLastMonthInPrepositionalCase, momentObject } from '../../../../../bi/utils/formatDate';

import { SERVICETYPE } from '../../../../../bi/constants/serviceType';

import styles from '../../../styles/trip.module.scss';

const LABELS = {
  ADDITIONAL_SERVICE: (type) => `Дополнительная услуга ${type} `,
  TICKET: 'Авиабилет',
};

const AddServiceTitle = ({ item, TripId }) => {
  const additionalServiceTitleHtml = (type, addServiceItem) => {
    const {
      JsonData, EventDate, Employees,
    } = addServiceItem;
    const routes = JsonData ? JSON.parse(JsonData).Routes : addServiceItem.routes;

    let result = LABELS.ADDITIONAL_SERVICE(type);

    routes.forEach(({ Segments }) => {
      result += `${Segments[0].DepartureCity} - ${Segments[Segments.length - 1].ArrivalCity}, `;
    });

    const date = getLastMonthInPrepositionalCase(momentObject(EventDate));
    result += date;

    result += ` #${TripId}, `;

    Employees.forEach(({
      Surname, Name, Patronymic,
    }) => {
      result += `${Surname} ${Name} ${Patronymic}`;
    });

    return result;
  };

  const renderTitle = () => (
    <h4>
      { item
      && item.ServiceType === SERVICETYPE.AIR_ADDITIONAL_SERVICE
      && additionalServiceTitleHtml(LABELS.TICKET, item) }
    </h4>
  );

  return (
    <div className={ `${styles.row} ${styles['add-service']}` }>
      <div className={ styles.col }>
        { renderTitle() }
      </div>
    </div>
  );
};

AddServiceTitle.propTypes = {
  item: PropTypes.object.isRequired,
  TripId: PropTypes.string.isRequired,
};

export { AddServiceTitle };
