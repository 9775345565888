import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Loading } from 'sw-ui';

import HeaderTableEvents from './components/headerTableEvents';
import TableEvents from './components/tableEvents';

import { SORTING_EVENTS } from '../../../../../../bi/constants/company';

import styles from '../../styles/dialog.module.css';

const LABELS = {
  TITLE: 'Заявки на мероприятия',
  EMPTY: 'Список пуст',
};

const WIDTH = '800px';

const EventApplication = ({
  show,
  onClose,
  onDelete,
  onCalcelled,
  onSort,
  events,
  loading,
}) => {
  const [typeSort, setTypeSort] = useState(SORTING_EVENTS.DATE);

  const handleSorting = (type) => {
    onSort(type);
    setTypeSort(type);
  };

  const renderEmpty = () => (
    <div className={ `${styles.empty} ${styles.loading_margin}` } >
      { LABELS.EMPTY }
    </div>
  );

  const renderTable = () => events && events.length && (
    <div>
      <HeaderTableEvents
        typeSort={ typeSort }
        onSorting={ handleSorting }
      />
      <TableEvents
        events={ events }
        onDelete={ onDelete }
        onCalcelled={ onCalcelled }
      />
    </div>
  );

  const renderLoading = () => (
    <div className={ styles.loading_margin }>
      <Loading size='small' />
    </div>
  );

  const renderEventsContent = events.length ? renderTable() : renderEmpty();
  const renderContent = loading ? renderLoading() : renderEventsContent;

  return (
    <Dialog
      show={ show }
      onClick={ onClose }
      width={ WIDTH }
      showCloseButton
    >
      <div className={ `${styles.dialog} ${styles.width_dialog}` }>
        <div className={ styles.title_wrapper }>
          <h2>{ LABELS.TITLE }</h2>
        </div>
        <div>
          { renderContent }
        </div>
      </div>
    </Dialog>
  );
};

EventApplication.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  events: PropTypes.array,
  onDelete: PropTypes.func.isRequired,
  onCalcelled: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

EventApplication.defaultProps = { events: [] };

export default EventApplication;
