import Api from '../../api';
import ACTIONS from '../../actions';

class AmoCRMService {
  constructor() {
    this.store = this.getStore('AmoCRM');
  }

  get = () => this.store.getState();

  subscribe = (callback) => this.store.subscribe(callback);

  uploadContacts = async (file, tag) => {
    try {
      const payload = await Api.AmoCRM.uploadContacts(file, tag);
      this.store.dispatch({
        type: ACTIONS.AMOCRM.UPLOADCONTACTS,
        payload,
      });
    } catch (error) {
      this.store.dispatch({ type: ACTIONS.AMOCRM.UPLOADCONTACTSFAIL });
    }
  };

  downloadAmoReport = (guid) => Api.AmoCRM.downloadAmoReport(guid);

  getSettingFunnelsTarget = async () => {
    const resFunnelsTarget = await Api.AmoCRM.getSettingFunnelsTarget();

    this.store.dispatch({
      type: ACTIONS.AMOCRM.TARGET_FUNNEL,
      payload: resFunnelsTarget,
    });
  };

  getSettingFunnelsDuplicate = async (targetPipelineId) => {
    const resFunnelsDuplicate = await Api.AmoCRM.getSettingFunnelsDuplicate(targetPipelineId);

    this.store.dispatch({
      type: ACTIONS.AMOCRM.DUPLICATE_FUNNEL,
      payload: resFunnelsDuplicate,
    });
  };

  getSaveSettingFunnels = async (rules) => {
    await Api.AmoCRM.getSaveSettingFunnels(rules);
  };

  uploadFile = async (file) => {
    try {
      const payload = await Api.AmoCRM.uploadFile(file);

      this.store.dispatch({
        type: ACTIONS.AMOCRM.UPLOADFILE,
        payload,
      });
    } catch (error) {
      this.store.dispatch({ type: ACTIONS.AMOCRM.UPLOADFILEFAIL });
    }
  };

  setLoading = (value) => this.store.dispatch({
    type: ACTIONS.AMOCRM.LOADING_CONTACTS,
    payload: value,
  });

  getInfoContacts = async (accountId) => {
    this.setLoading(true);

    try {
      this.store.dispatch({
        type: ACTIONS.AMOCRM.PROCESSING_UPDATE_CONTACTS,
        payload: [],
      });

      const infoContacts = await Api.AmoCRM.getInfoContacts({ accountId });

      this.store.dispatch({
        type: ACTIONS.AMOCRM.UPDATE_CONTACTS,
        payload: infoContacts,
      });
    } catch (err) {
      this.store.dispatch({
        type: ACTIONS.AMOCRM.NO_UPDATE_CONTACTS,
        payload: [],
      });
    }

    this.setLoading(false);
  };

  downloadAmoContacts = async (guid) => {
    try {
      await Api.AmoCRM.downloadAmoContacts(guid);
    } catch (err) {
      this.store.dispatch({ type: ACTIONS.AMOCRM.NO_DOWNLOAD_CONTACTS });
    }
  };

  resetError = () => {
    this.store.dispatch({ type: ACTIONS.AMOCRM.RESETERROR });
  };

  resetStore = () => {
    this.store.dispatch({ type: ACTIONS.AMOCRM.RESET });
  };
}

export default AmoCRMService;
