import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../../../../components/button';

import { TRIPSLABELS, VOUCHER_TEXT } from '../../../../../../bi/constants/trips';

import AnalyticsRows from '../../../analyticsRows';

import styles from '../../../../styles/form.module.scss';

const EmployeeFields = ({
  analytics,
  transfer: { UserAnalytics },
  employeesListForPick,
  tripAnalyticsValueIds,
  validation,
  onSelectAnalytics,
  onIncreaseCountEmployees,
  onDecreaseCountEmployees,
  renderEmployeeSuggest,
  selectCompanyHtml,
  selectDepartmentHtml,
  selectProjectsHtml,
}) => {
  const renderAnalytics = () => (
    <AnalyticsRows
      analytics={ analytics }
      serviceAnalyticsValueIds={ UserAnalytics }
      tripAnalyticsValueIds={ tripAnalyticsValueIds }
      onSelect={ onSelectAnalytics }
      validation={ validation.analytics }
    />
  );

  return (
    <div className={ `${styles.row} ${styles.panel}` }>
      <h4>{ VOUCHER_TEXT.TITLE }</h4>
      <div className={ styles['employee-panel'] }>
        <div className={ styles['employee-panel-title'] }>
          Число сотрудников :
          { ' ' }
          { employeesListForPick.length }
        </div>
        <FlatButton
          label={ TRIPSLABELS.INCREASE }
          disabled={ employeesListForPick.length > 5 }
          onClick={ () => onIncreaseCountEmployees(employeesListForPick.length) }
        />
        <FlatButton
          label={ TRIPSLABELS.DECREASE }
          disabled={ employeesListForPick.length < 2 }
          onClick={ onDecreaseCountEmployees }
        />
      </div>
      <div className={ styles.row }>
        { selectCompanyHtml }
      </div>
      <div className={ styles.row }>
        <div className={ styles['employees-list'] }>
          { employeesListForPick.map(renderEmployeeSuggest) }
        </div>
      </div>
      <div className={ styles.row }>
        { selectDepartmentHtml }
        { selectProjectsHtml }
      </div>
      { renderAnalytics() }
    </div>
  );
};

EmployeeFields.propTypes = {
  analytics: PropTypes.array.isRequired,
  transfer: PropTypes.object.isRequired,
  employeesListForPick: PropTypes.array.isRequired,
  tripAnalyticsValueIds: PropTypes.array.isRequired,
  validation: PropTypes.object.isRequired,
  onSelectAnalytics: PropTypes.func.isRequired,
  onIncreaseCountEmployees: PropTypes.func.isRequired,
  onDecreaseCountEmployees: PropTypes.func.isRequired,
  renderEmployeeSuggest: PropTypes.func.isRequired,
  selectCompanyHtml: PropTypes.object.isRequired,
  selectDepartmentHtml: PropTypes.object.isRequired,
  selectProjectsHtml: PropTypes.object.isRequired,
};

export { EmployeeFields };
