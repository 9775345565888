import React from 'react';
import PropTypes from 'prop-types';

import { FlatButton } from '../../../components/button';

import { VersionTitle } from './version/VersionTitle';
import { VersionInfo } from './version/VersionInfo';

import { SERVICETYPE } from '../../../bi/constants/serviceType';

import styles from '../styles/trip.module.scss';

const LABELS = {
  COPY: 'Копировать',
  EDIT: 'Редактировать',
  ORDER_NUMBER: 'Номер заказа',
  CHANGE_NAME: 'Название изменения',
};

const Version = ({
  item, onClick, onCopyClick, onEditClick,
}) => {
  const {
    Status, ServiceType, OrderTripItemId, Description, JsonData, Id, IsNotCorrect,
  } = item;

  const renderTitle = () => {
    switch (Status) {
      case 0: {
        return <VersionTitle serviceType={ ServiceType } />;
      }

      case 1:
      case 2:
      case 3: {
        return (
          <div className={ styles.row }>
            <div className={ styles['col-1-5'] }>
              <label>{ LABELS.ORDER_NUMBER }</label>
            </div>
            <div className={ styles['col-1-4'] }>
              <label>{ LABELS.CHANGE_NAME }</label>
            </div>
          </div>
        );
      }

      default: return null;
    }
  };

  const renderInfo = () => {
    switch (Status) {
      case 0: {
        const buttonEditHtml = (
          <div className={ styles['col-1-9'] }>
            <FlatButton
              label={ LABELS.EDIT }
              onClick={ () => onEditClick(item) }
            />
          </div>
        );

        const showButtonCopy = ServiceType !== SERVICETYPE.INSURANCE;

        const buttonCopyHtml = showButtonCopy
          ? (
            <div className={ styles['col-1-4'] }>
              <FlatButton
                label={ LABELS.COPY }
                onClick={ () => onCopyClick(item) }
              />
            </div>
          )
          : null;

        return (
          <div className={ styles.row } onClick={ () => onClick(item) }>
            <VersionInfo item={ item } data={ JsonData } serviceType={ ServiceType } />
            { buttonEditHtml }
            { buttonCopyHtml }
          </div>
        );
      }
      case 1:
      case 2:
      case 3: {
        return (
          <div className={ styles.row } onClick={ () => onClick(item) }>
            <div className={ styles['col-1-5'] }>
              #
              { ' ' }
              { OrderTripItemId }
            </div>
            <div className={ styles['col-1-2'] }>
              { Description }
            </div>
          </div>
        );
      }
      case 4: {
        const styleErrorVersion = IsNotCorrect ? styles['error-version'] : '';

        return (
          <div className={ styles.row } onClick={ () => onClick(item) }>
            <div className={ `${styles['col-1-5']} ${styleErrorVersion}` }>
              #
              { ' ' }
              { Id }
            </div>
            <div className={ `${styles['col-1-2']} ${styleErrorVersion}` }>
              { Description }
            </div>
            <div className={ `${styles['col-1-4']} ${styleErrorVersion}` }>
              <FlatButton
                label={ LABELS.EDIT }
                onClick={ () => onEditClick(item) }
              />
            </div>
          </div>
        );
      }
    }

    return null;
  };

  return (
    <div className={ `${styles.form} ${styles.line}` }>
      { renderTitle() }
      { renderInfo() }
    </div>
  );
};

Version.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onCopyClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

Version.defaultProps = { onClick: () => {} };

export default Version;
