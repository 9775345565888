import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'sw-ui';

import MaterialIcon from '../../components/materialIcon';

import styles from './styles/notification.module.scss';

const resultIcons = {
  success: 'check_circle_outline',
  failure: 'block',
  warning: 'error_outline',
};

const Notification = ({ notice, notificationsService }) => {
  const header = notice.header ? (<div className={ styles.header }>{ notice.header }</div>) : null;
  let stopwatch = null;

  return (
    <div
      className={ `${styles.notification} ${styles[notice.level]}` }
      onClick={ () => {
        notificationsService.deletePostponeRemoveTimer(notice.id);
        stopwatch.classList.add(styles.hidden);
      } }
    >
      <div className={ styles.left }>
        <div className={ styles.icon }>
          <MaterialIcon
            icon={ resultIcons[notice.level] }
          />
        </div>
      </div>
      <div className={ styles.center }>
        { header }
        <div className={ styles.content }>{ notice.message }</div>
      </div>
      <div className={ styles.right }>
        <div className={ styles.icon }>
          <MaterialIcon
            icon='close'
            onClick={ () => notificationsService.remove(notice.id) }
          />
        </div>
        <div ref={ (ref) => { stopwatch = ref; } } className={ `sw-tooltip-wrapper ${styles.icon}` }>
          <MaterialIcon
            icon='timer'
          />
          <Tooltip
            position='left'
          >
            Кликните по панели, чтобы отменить скрытие по таймеру
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

Notification.propTypes = {
  notice: PropTypes.object.isRequired,
  notificationsService: PropTypes.object.isRequired,
};

export default Notification;
