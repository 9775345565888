import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './styles/input.module.css';

class Input extends Component {
  static propTypes = {
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    readOnly: PropTypes.bool,
    error: PropTypes.string,
    type: PropTypes.string,
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
  };

  static defaultProps = {
    placeholder: '',
    value: '',
    error: '',
    type: 'text',
    className: '',
    readOnly: false,
    onChange: null,
    onBlur: () => {},
  };

  state = { value: this.props.value };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.value !== nextProps.value) {
      this.setState({ value: nextProps.value });
    }
  }

  handleChange = (e) => {
    const { onChange } = this.props;

    if (onChange) {
      onChange(e.target.value);
    } else {
      this.setState({ value: e.target.value });
    }
  };

  focus = () => {
    this.input.focus();
  };

  render() {
    const {
      className, placeholder, readOnly, error, type, onBlur,
    } = this.props;
    const { value } = this.state;

    let errorHtml = null;
    if (error) {
      errorHtml = (
        <span>
          { error }
        </span>
      );
    }

    return (
      <div
        className={ `${styles.wrap} ${className || ''} ${error ? styles.error : ''}` }
      >
        <input
          ref={ (input) => { this.input = input; } }
          type={ type }
          value={ value }
          placeholder={ placeholder }
          readOnly={ readOnly }
          onChange={ readOnly ? () => {} : this.handleChange }
          onBlur={ readOnly ? () => {} : onBlur }
        />
        { errorHtml }
      </div>
    );
  }
}

export default Input;
