import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Select, Button } from 'sw-ui';

import COMPONENTS from '../../../bi/constants/components';
import { FIELDS } from '../../../bi/constants/approveUsers';

import AjaxButton from '../../../components/ajaxButton';
import Input from '../../../components/input';

import styles from '../styles/approve-users.module.scss';

const LABELS = {
  SMARTWAY: 'Smartway',
  NAME: 'Имя',
  PHONE: 'Телефон',
  CLOSE: 'Отменить',
  ADD: 'Добавить',
};

class AddUserForm extends Component {
  static propTypes = {
    roles: PropTypes.array.isRequired,
    aggregatorIds: PropTypes.array.isRequired,
    onClose: PropTypes.func,
    onAdd: PropTypes.func,
    userService: PropTypes.object,
    error: PropTypes.object,
  };

  static defaultProps = {
    onClose: () => {},
    onAdd: () => {},
    userService: null,
    error: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      user: {
        Name: '',
        Email: '',
        Phone: '',
        Role: '',
        AggregatorId: props.aggregatorIds[0].value,
      },
      select: {
        value: 0,
        label: LABELS.SMARTWAY,
      },
      error: props.error,
      waitingResponse: false,

      validation: {
        Name: '',
        Email: '',
        Phone: '',
      },
      aggregatorIds: props.aggregatorIds,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ error: nextProps.error });
  }

  handleChangeInput = (e, field, value) => {
    this.setState({
      user: {
        ...this.state.user,
        [field]: value,
      },
    });
  };

  handleChangeSelect = (item) => {
    this.setState({
      user: { ...this.state.user },
      select: {
        value: item.value,
        label: item.label,
      },
    });
  };

  handleChangeSelectAggregatorIds = (item) => {
    const { user, aggregatorIds } = this.state;

    this.setState({
      user: {
        ...user,
        AggregatorId: item.value,
      },
      aggregatorIds: aggregatorIds.map((i) => ({
        ...i,
        select: item.value === i.value,
      })),
    });
  };

  handleFocusInput = () => {
    this.setState({ error: null });
  };

  validationOnAdd = () => {
    const { user, select } = this.state;

    const { isValid, validation } = this.validationAdd({ user });

    user.Role = select.label;

    if (isValid) {
      this.setState({ waitingResponse: true }, () => this.props.onAdd(user));

      return null;
    }

    this.setState({ validation: { ...validation } });

    return null;
  };

  validationAdd = ({ user }) => {
    const userService = this.props.userService;

    const name = userService.userFieldValidation(FIELDS.NAME, user.Name);
    const email = userService.userFieldValidation(FIELDS.EMAIL, user.Email);
    const phone = userService.userFieldValidation(FIELDS.PHONE, user.Phone);

    if (name || email || phone) {
      return {
        isValid: false,
        validation: {
          Name: name,
          Email: email,
          Phone: phone,
        },
      };
    }

    return { isValid: true };
  };

  validationInput = (e, field) => {
    const result = this.props.userService.userFieldValidation(field, this.state.user[field]);

    this.setState({
      validation: {
        ...this.state.validation,
        [field]: result,
      },
    });
  };

  render() {
    const {
      user,
      error,
      validation,
      select,
      waitingResponse,
      aggregatorIds,
    } = this.state;

    const foundSelectAggregatorId = aggregatorIds.find((item) => item.select).value;
    const mapperAggregatorIds = aggregatorIds.map(({ value, label }) => ({
      value,
      label,
    }));

    return (
      <div className={ `${styles.form} ${styles.dialog}` }>
        <div className={ `${styles.row} ${styles.title}` }>
          <div className={ styles.col }>Добавление нового сотрудника</div>
        </div>
        <div className={ `${styles.row} ${styles.error} ${error && styles.present}` }>
          <div className={ styles.col }>
            { error }
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS.NAME }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ user.Name }
              label={ LABELS.NAME }
              onChange={ this.handleChangeInput }
              onFocus={ this.handleFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.Name }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS.EMAIL }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ user.Email }
              label={ FIELDS.EMAIL }
              onChange={ this.handleChangeInput }
              onFocus={ this.handleFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.Email }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Input
              field={ FIELDS.PHONE }
              type={ COMPONENTS.INPUT.TYPE.TEXT }
              value={ user.Phone }
              label={ LABELS.PHONE }
              onChange={ this.handleChangeInput }
              onFocus={ this.handleFocusInput }
              onBlur={ this.validationInput }
              valid={ validation.Phone }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Select
              field={ FIELDS.ROLE }
              items={ this.props.roles.map((item, index) => ({
                value: index,
                label: item,
              })) }
              value={ select.value }
              onChange={ this.handleChangeSelect }
            />
          </div>
        </div>
        <div className={ styles.row }>
          <div className={ styles.col }>
            <Select
              field={ FIELDS.AGGREGATOR_IDS }
              items={ mapperAggregatorIds }
              value={ foundSelectAggregatorId }
              onChange={ this.handleChangeSelectAggregatorIds }
            />
          </div>
        </div>
        <div className={ `${styles.row} ${styles.action}` }>
          <Button
            label={ LABELS.CLOSE }
            theme={ COMPONENTS.BUTTON.THEME.FLAT }
            onClick={ () => this.props.onClose() }
          />
          <AjaxButton label={ LABELS.ADD } loading={ waitingResponse } onClick={ this.validationOnAdd } />
        </div>
      </div>
    );
  }
}

export default AddUserForm;
